import React, { useState } from 'react';
import { PathwayGoals } from '../sections';
import PathwayStrategies from '../sections/pathwayStrategies';
import useModal from '../../../hooks/useModal';
import StrategyModal from '../modals/strategyModal';
import { useQuery } from 'react-query';
import { getStrategies } from '../../../service/api';
import { ROLE_SYS_ADMIN } from '../../../resources/roles';
import { ADMIN_STRATEGIES, RESOURCES_BY_SLUG, RESOURCES_BY_TYPE } from '../../../service/queryKeys';
import MeetingTemplates from '../sections/MeetingTemplates';
import MeetingPathways from '../sections/MeetingPathways';

const SysAdminPathwayView = () => {
  const [includeParam, setIncludeParam] = useState<string>();

  const [adminStrategyModal, toggleAdminStrategyModal, setAdminStrategyProps] = useModal((props: any) => (
    <StrategyModal {...props} />
  ));

  const sysAdminStrategiesQuery = useQuery([ADMIN_STRATEGIES, includeParam], () => getStrategies(includeParam), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    keepPreviousData: true,
  });

  return (
    <>
      {adminStrategyModal}
      <PathwayGoals role={ROLE_SYS_ADMIN} strategies={sysAdminStrategiesQuery.data} />
      <PathwayStrategies
        role={ROLE_SYS_ADMIN}
        data={sysAdminStrategiesQuery.data}
        isAdmin
        canDisable
        showDisabled={includeParam === 'disabled'}
        handleShowDisabled={() =>
          includeParam === 'disabled' ? setIncludeParam(undefined) : setIncludeParam('disabled')
        }
        toggle={(title: string, strategy: any, preventCloseOnClickOnMask = true) => {
          setAdminStrategyProps({
            title,
            strategy,
            role: ROLE_SYS_ADMIN,
            size: 'lg',
            preventCloseOnClickOnMask,
          });
          toggleAdminStrategyModal(true);
        }}
      />

      <MeetingTemplates />

      <MeetingPathways />
    </>
  );
};

export default SysAdminPathwayView;
