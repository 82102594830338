import React from 'react';
import Button from "../../global/button/button";
import AuthLayout from '../../layout/auth';

const site = new URLSearchParams(location.search).get('site');

const EseCompleteRegistration = () => {

  return (
    <>

      <AuthLayout site={site} title={ site === 'ar' ? 'التسجيل' : 'Registration'} eseMode={true}>

     <>
      <>
      {site === 'ar' ? (
          <p dir="rtl">
            تهانينا! لقد تم إنشاء حسابك الآن.
            للوصول إلى البرنامج، يرجى النقر على الزر أدناه لتسجيل الدخول.          </p>
      ) : (
        <p>
          Congratulations! Your account has now been created. To access the EBE courses, please login using the button below.
        </p>
      )}


        <Button
          className="w-full mt-5"
          onClick={() => {
            if (site === 'ar') {
              window.location.href = 'https://evidence-based-education-arabic.thinkific.com/users/sign_in';
            }else{
              window.location.href = 'https://evidence-based-education.thinkific.com/users/sign_in';

            }
      }
      }
      >
          {site === 'ar' ? 'تسجيل الدخول' : 'Login' }
      </Button>
     </>
     </>
    </AuthLayout>
    </>

);
};

export default EseCompleteRegistration;
