import React from 'react';
import classnames from 'classnames';
import { TabProps } from '../../../global/tab/tab.types';
import { OptionTypeBase, ValueType } from 'react-select';
import Tab from '../../../global/tab/tab';
import Selector from '../../../form/select/selector';

type Props = {
  background?: 'white' | 'gray';
  showTabs: boolean;
  classes?: OptionTypeBase[];
  activeClasses?: Array<string>;
  handleClassChange?: (value: ValueType<OptionTypeBase, false>) => void;
  children: React.ReactElement;
} & TabProps;

const FeedbackLayout = ({
  background = 'white',
  showTabs,
  classes,
  activeClasses,
  handleClassChange,
  children,
  ...tabs
}: Props): JSX.Element => {
  if (!showTabs && !classes) {
    return children;
  }

  return (
    <>
      <div
        className={`sticky top-0 z-40 border-b-2 flex flex-wrap items-center ${
          background === 'white' ? 'bg-white' : 'bg-gray-100'
        }`}
      >
        {showTabs && <Tab {...(tabs as TabProps)} className={'flex items-center flex-1 md:flex-initial'} />}
        {classes && (
          <div
            className={`flex-1 md:flex-initial ${classnames({
              'py-5': !showTabs,
            })} px-5 my-5 md:my-0 mr-auto md:mr-0 ml-auto`}
            style={{
              minWidth: 200,
            }}
          >
            <Selector
              id={'select_class'}
              label={'Select a class'}
              hideLabel
              isMulti
              isClearable={false}
              options={classes}
              value={activeClasses}
              onChange={handleClassChange}
            />
          </div>
        )}
      </div>
      <div className={'bg-gray-100 p-5'}>{children}</div>
    </>
  );
};

export default FeedbackLayout;
