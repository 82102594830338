import connector from '../connector';
import {
  PostEseRegisterType,
  PostLoginDataType,
  PostOrPutSchoolClassType,
  PostRegisterConfirmationParamsType,
  PostRegisterType,
  PutArchiveSchoolClassType,
} from '../types';
import { DefaultOptionType } from '../../resources/types';
import dayjs from 'dayjs';
import { resourceInterface } from '../../pages/resourcePage/resources/types';
import { ApiResponse } from '../../@types/global';
import { Group } from '../../@types/Entity/Group';
import { AcademicYear } from '../../@types/Entity/AcademicYear';
import { UserType } from '../../pages/users/userManagementPage';
import { RolesType } from '../../resources/roles';
import { Me } from '../../@types/Entity/Me';
import { School } from '../../@types/Entity/School';

export function postLogin(data: PostLoginDataType) {
  return connector.post('/auth/token', data);
}

export function postEmailCheck(email: string) {
  return connector.post('/auth/email-check', { email });
}

export function postValidateToken(token?: string | null, role?: string | null) {
  return connector.post<ApiResponse<School[]>>('/auth/token-validation', { token, role });
}

export function postRegisterUser(data: PostRegisterType) {
  return connector.post('/auth/register', data);
}

export function postCheckSubscription(email: string, role: string) {
  return connector.post('/chargebee/check-subscription', { email, role });
}

export function getCouponDiscount(code: string) {
  return connector.get(`/chargebee/coupon-discount/${code}`);
}

export function postCreateChargebeePortalSession() {
  return connector.post('/api/chargebee/create-portal-session');
}

export function postConfirmRegistration(params: PostRegisterConfirmationParamsType) {
  return connector.post('/auth/register-confirmation', null, { params });
}

export function postResendConfirmRegistrationMail(params: PostRegisterConfirmationParamsType) {
  return connector.post('/auth/register-confirmation-mail', null, { params });
}

export function getPlanTiers(solo?: number) {
  return connector.get('/chargebee/plan-tiers', { params: { solo } });
}

export function getThinkificSSO(element?: string) {
  return connector.get('/api/thinkific/sso', { params: { element } });
}

export function postTypeformResponse(form_id: string, survey_id: string, response_id: string) {
  return connector.post('/typeform/response', { form_id, survey_id, response_id });
}

export function getSchoolClasses() {
  return connector.get('/api/school-class');
}

export function postOrPutSchoolClass(data: PostOrPutSchoolClassType | PutArchiveSchoolClassType) {
  if (data.id) {
    return connector.put('/api/school-class', data);
  }

  return connector.post('/api/school-class', data);
}

export function getMe() {
  return connector.get<ApiResponse<{ me: Me }, { early_access_items: { path: string; label: string }[] }>>('/api/me');
}

export function putMe(data: any) {
  return connector.put('/api/me', data);
}

export function postSendResetPasswordMail(email: string) {
  return connector.post('/auth/reset-password', { email });
}

export function postResetPassword(token: string, password: string) {
  return connector.post('/auth/reset-password/reset/' + token, { password });
}

export function getValidateResetPasswordToken(token: string) {
  return connector.get<ApiResponse<Me>>('/auth/reset-password/reset/' + token);
}

export function getInstrumentOptions() {
  return connector.get('/api/instrument/options');
}

export function getPathwaysElements(includeElement?: '1.2') {
  return connector.get<ApiResponse<GetPathwaysElementsResponseBody>>('/api/development-pathway/elements', {
    params: includeElement ? { include: includeElement } : null,
  });
}

export function getActiveInstruments(type: string) {
  return connector.get('/api/instrument/' + type + '/active');
}

export function getInstrumentPeriods(type: string) {
  return connector.get('/api/instrument/' + type + '/periods');
}

export function postInstrument(data: any) {
  return connector.post('/api/instrument', data);
}

export function putInstrument(data: any) {
  return connector.put('/api/instrument', data);
}

export function deleteInstrument(instrumentId: string) {
  return connector.delete(`/api/instrument/${instrumentId}`);
}

export function putInstrumentDevelopmentPeriod(data: any) {
  return connector.put('/api/instrument/period', data);
}

export function getUsersList({
  order,
  filter,
  show,
  page,
}: {
  order: string;
  filter: string;
  show: string;
  page: number;
}) {
  return connector.get<
    ApiResponse<{
      users: UserType[];
        }>
  >('/api/admin/users', {
    params: { order, filter, show, page },
  });
}

export function putUserGreatTeachingLead(data: any) {
  return connector.put('/api/admin/user/great-teaching-lead', data);
}

export function patchUserRole(data: { user_id: string; role: string }) {
  return connector.patch('/api/admin/user/role', data);
}

export function patchManuallyConfirmEmail(id: string) {
  return connector.patch('/api/admin/user/email-confirmation/' + id);
}

export function putUserStatus(data: any) {
  return connector.put('/api/admin/user/status', data);
}

export function putUsersStatus(data: any) {
  return connector.put('/api/admin/users/status/multi-selection', data);
}

export function removeUserPrivileges(data: any) {
  return connector.put('/api/admin/user/remove-privileges', data);
}

/**
 * Delete User by Sys-admin account
 * @param id
 */
export function deleteUserAccount(id: string) {
  return connector.delete('/api/admin/user/delete-user/' + id);
}

export function getSchoolAssociatedUserOptions(schoolId: number) {
  return connector.get(`/api/admin/school/${schoolId}/users-options`);
}

export function getAssociatedUserOptions(userId: string) {
  return connector.get(`/api/admin/user/${userId}/users-options`);
}

export function postTransferSubscription(data: { owner_id: string; user_id: string }) {
  return connector.post('/api/admin/transfer-subscription', data);
}

export function getCheckSchoolClassNameIsValid(name: string, schoolClassId?: string) {
  return connector.get('/api/school-class/check-name', {
    params: {
      name: name,
      id: schoolClassId,
    },
  });
}

export function removeMatAdminPrivileges(data: { id: string; first_name: string; last_name: string; email: string }) {
  return connector.post(`/api/admin/mat-admin/${data.id}/remove-privileges`, {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
  });
}

export function getEarlyAccessGroups() {
  return connector.get('api/admin/early-access-groups');
}

export function updateUserEarlyAccessGroups(data: { userId: string; groups: string[] }) {
  return connector.put(`api/admin/user/${data.userId}/early-access-groups`, { groups: data.groups });
}

export function updateSchoolEarlyAccessGroups(data: { userId: string; groups: string[] }) {
  return connector.put(`api/admin/user/${data.userId}/school/early-access-groups`, { groups: data.groups });
}

export function postOrPutDevelopmentPeriods(data: any) {
  if (data.id) {
    return connector.put('/api/development-pathway/period', data);
  }

  return connector.post('/api/development-pathway/period', data);
}

export function getDevelopmentPeriods() {
  return connector.get('/api/development-pathway/periods');
}

export function deleteDevelopmentPeriod(id: string) {
  return connector.delete(`/api/development-pathway/period/${id}`);
}

export function getPathwaysHistory() {
  return connector.get('/api/development-pathway/history');
}

export function getDevelopmentCycles(pathwaysId: string) {
  return connector.get(`/api/development-pathway/${pathwaysId}/cycles`);
}

export function getActions(developmentPeriodId: string) {
  return connector.get('/api/development-pathway/action/' + developmentPeriodId);
}

export function postOrPutAction(data: any) {
  if (data.id) {
    return connector.put('/api/development-pathway/action', data);
  }

  return connector.post('/api/development-pathway/action', data);
}

export function getDevelopmentPeriod(developmentPeriodId?: string | null) {
  return connector.get('/api/development-pathway/period' + (developmentPeriodId ? '/' + developmentPeriodId : ''));
}

export function getActionsSteps(developmentPeriodId: string) {
  return connector.get('/api/development-pathway/action/' + developmentPeriodId + '/steps');
}

export function postOrPutAcademicYear(data: any) {
  if (data.id) {
    return connector.put('/api/development-pathway/academic-year', data);
  }

  return connector.post('/api/development-pathway/academic-year', data);
}

export function getAcademicYears() {
  return connector
    .get<ApiResponse<AcademicYear[]>>('/api/development-pathway/academic-year')
    .then((response) => response.data);
}

export function getTeacherMemberGroups(order?: string) {
  return connector
    .get<ApiResponse<Group[]>>('/api/groups', { params: { order } })
    .then((response) => response.data);
}

export function getTeacherMemberMentees(order?: string) {
  return connector
    .get<ApiResponse<Group[]>>('/api/groups', { params: { mentees: '', order } })
    .then((response) => response.data);
}

export function deleteTeacherMemberGroup(groupId: string) {
  return connector.delete('/api/group/' + groupId + '/remove/member');
}

export function getSchoolGroups(include?: string, order?: string, $cohortApply?: boolean) {
  return connector.get<ApiResponse<Group[]>>('/api/groups/school', { params: { include, order, $cohortApply } });
}

export function postTeacherMemberGroup({ groupId, pathwaysId }: { groupId: string; pathwaysId?: string }) {
  return connector.post('/api/group/' + groupId + '/add/member', { pathways_id: pathwaysId });
}

export function postStrategyGroup({ groupId, strategyId }: { groupId: string; strategyId: string }) {
  return connector.post('/api/group/' + groupId + '/add/strategy/' + strategyId);
}

export function postTeacherMembersGroups({
  userIds,
  groups,
}: {
  userIds: Array<string>;
  groups: Array<DefaultOptionType>;
}) {
  return connector.post('/api/group/add/members', {
    groups: groups,
    teacher_ids: userIds,
  });
}

export function getCheckGroupNameIsValid(name: string, groupId?: string) {
  return connector.get('/api/group/check-name', {
    params: {
      name: name,
      id: groupId,
    },
  });
}

export function getMatSchools() {
  return connector.get<
    ApiResponse<
      {
        id: string;
        name: string;
        slots: number;
        coordinator_name: string;
        coordinator_lastname: string;
        coordinator_email: string;
      }[]
    >
  >('/api/feedback/mat-schools');
}

export function createOrUpdateSchools(
  schools: Array<{
    id?: string;
    name: string;
    slots: string;
    coordinator_name: string;
    coordinator_lastname: string;
    coordinator_email: string;
  }>
) {
  return connector.post('/api/admin/schools', { schools });
}

export function updateSchool({ id, name }: { id: number; name: string }) {
  return connector.put(`/api/admin/schools/${id}`, { name });
}

export function getElementsForFeedback() {
  return connector.get('/api/feedback/elements');
}

export function getFeedbackDevelopmentPeriods() {
  return connector.get('/api/feedback/periods');
}

export function getFeedbackPeriod(id?: string, school?: number) {
  return connector.get('/api/feedback/period', { params: { id, school } });
}

export function getFeedbackSchoolClasses(surveyVersion: string, id?: string) {
  return connector.get('/api/feedback/school-classes-options', { params: { id, surveyVersion } });
}

export function postOrPutActivity(data: {
  id?: string | null;
  academic_year_id?: string | null;
  development_period_id?: string | null;
  pathway?: string | null;
  pathway_step?: string | null;
  activity_type?: string | null;
  activity?: string | null;
  hours?: string | null;
  started?: Date | null;
  completed?: Date | null;
  element?: {
    label: string;
    value: string;
    type: string;
  } | null;
  documents?: Array<{ id: string; name: string }>;
  new_documents?: Array<File>;
}) {
  const formData = new FormData();

  if (data.academic_year_id) {
    formData.append('academic_year_id', data.academic_year_id);
  }

  if (data.development_period_id) {
    formData.append('development_period_id', data.development_period_id);
  }
  if (data.pathway) {
    formData.append('pathway', data.pathway);
  }

  if (data.pathway_step) {
    formData.append('pathway_step', data.pathway_step);
  }

  if (data.activity_type) {
    formData.append('activity_type', data.activity_type);
  }
  if (data.activity) {
    formData.append('activity', data.activity);
  }

  if (data.hours) {
    formData.append('hours', data.hours);
  }
  if (data.started) {
    formData.append('started', dayjs(data.started).format('YYYY/MM/DD'));
  }

  if (data.completed) {
    formData.append('completed', dayjs(data.completed).format('YYYY/MM/DD'));
  }

  if (data.element) {
    formData.append('element', data.element.value);
    formData.append('element_type', data.element.type);
  }

  if (data.new_documents) {
    for (const document of data.new_documents) {
      formData.append('new_documents[]', document);
    }
  }

  if (data.documents) {
    for (const document of data.documents) {
      formData.append('documents[]', document.id);
    }
  }

  if (null != data.id) {
    return connector.post(`api/development-pathway/v2/activity/${data.id}`, formData);
  }
  return connector.post('api/development-pathway/v2/activity', formData);
}

export function deleteActivity(activityId: string) {
  return connector.delete(`api/development-pathway/v2/activity/${activityId}`);
}

export function getDashboard(schoolId?: string, school_user_id?: string) {
  return connector.get('/api/dashboard', { params: { schoolId, school_user_id } });
}

export function getNotifications(params: { show?: string; read?: string }) {
  return connector.get('/api/notifications', { params });
}

export function postOrPutNotification(data: any) {
  if (data.id) {
    return connector.put('/api/notifications', data);
  }

  return connector.post('/api/notifications', data);
}

export function patchReadNotifications({ ids, read }: { ids: Array<string>; read: boolean }) {
  return connector.patch('api/notifications/' + (read ? 'read' : 'unread'), { ids });
}

export function getSubjects() {
  return connector.get<ApiResponse<DefaultOptionType>>('/subjects');
}

export function validateUpgradeAccount(data: { role: string; email: string; token?: string }) {
  return connector.post('/upgrade/validate', data);
}

export function upgradeTeacherAccount(data: { email: string; token: string }) {
  return connector.post('/upgrade/teacher', data);
}

export function getAdvisor() {
  return connector.get('/api/hubspot/advisor');
}

export function getDevelopmentPlans() {
  return connector.get('/api/development-pathway/plans');
}

export function postOrPutPlans(data: any) {
  if (data.id) {
    return connector.put('/api/development-pathway/plan', data);
  }
  return connector.post('/api/development-pathway/plan', data);
}

export function getDevelopmentSteps() {
  return connector.get('/api/development-pathway/steps');
}

export function postSteps(data: any) {
  return connector.post('/api/development-pathway/step', data);
}

export function putSteps(data: any) {
  return connector.put('/api/development-pathway/step', data);
}

export function deletePlan(id: string) {
  return connector.delete(`/api/development-pathway/plan/${id}`);
}

export function getSchoolsList(order: string, filter: string, page: number /*, show: string, page: number*/) {
  return connector.get('/api/school/schools', { params: { order, filter, page /*, show*/ } });
}

export function postNote(data: any) {
  return connector.post('/api/school/note', data);
}

export function putNote(data: any) {
  return connector.put('/api/school/note', data);
}

export function deleteNote(id: string) {
  return connector.delete(`/api/school/note/${id}`);
}

export function getNote(schoolId?: string) {
  return connector.get('/api/school/note', { params: { schoolId } });
}

export function postWebinar(data: any) {
  return connector.post('/api/zoom/webinar', data);
}

export function getWebinars() {
  return connector.get('api/zoom/webinars');
}

export function putWebinar(data: any) {
  return connector.put('/api/zoom/webinar', data);
}

export function deleteWebinar(id: string) {
  return connector.delete(`/api/zoom/webinar/${id}`);
}

export function getSignature() {
  return connector.get('/api/zoom/signature');
}

export function postInsetTracking(download_type: string) {
  return connector.post('/api/hubspot/inset/download', { download_type });
}

export function getPosts() {
  return connector.get('/api/community/posts');
}

export function getPost(id: string) {
  return connector.get(`/api/community/post/${id}`);
}

export function postPost(data: any) {
  return connector.post('/api/community/post', data);
}

export function putPost(data: any) {
  return connector.put('/api/community/post', data);
}

export function deletePost(id: string) {
  return connector.delete(`/api/community/post/${id}`);
}

export function getEmdedMetadData(link?: string) {
  return connector.get('/api/community/embed', { params: { link } });
}

export function addResourceContent(data: resourceInterface) {
  const formData = new FormData();
  if (data.id) formData.append('id', data.id);
  formData.append('title', data.title);
  formData.append('type', data.type);
  if (data.name) formData.append('name', data.name);

  if (data.icon) {
    formData.append('icon', data.icon);
  }

  if (data.image_icon) {
    formData.append('image_icon', data.image_icon);
  }

  if (data.short_description) {
    formData.append('short_description', data.short_description);
  }

  if (data.estimated_time) {
    const test = data.estimated_time.toString();
    formData.append('estimated_time', test);
  }

  if (data.pathway_steps) {
    data.pathway_steps.forEach((value) => {
      formData.append('pathway_steps[]', value);
    });
  }

  if (data.keywords) {
    data.keywords.forEach((value) => {
      formData.append('keywords[]', value);
    });
  }

  if (data.goals) {
    data.goals.forEach((value) => {
      formData.append('goals[]', value);
    });
  }

  if (data.parents) {
    data.parents.forEach((value) => {
      formData.append('parents[]', value);
    });
  }

  formData.append('draft', (data.draft as unknown) as string);

  if (data.url) {
    formData.append('url', data.url);
  }

  if (data.urls) {
    data.urls.forEach((value) => {
      formData.append('urls[]', value);
    });
  }

  if (data?.file) {
    formData.append('file', data.file?.file);
  }

  if (data.slug) {
    formData.append('slug', data.slug);
  }

  if (data.dimensions) {
    data.dimensions.forEach((value) => {
      formData.append('dimensions[]', value);
    });
  }

  if (data.elements) {
    data.elements.forEach((value) => {
      formData.append('elements[]', value);
    });
  }

  if (data.author) {
    formData.append('author', JSON.stringify(data.author));
  }

  if (data.content) {
    formData.append('content', data.content);
  }

  if (data.description) {
    formData.append('description', data.description);
  }

  if (data.banner) {
    formData.append('banner', data.banner);
  }

  if (data.instructions) {
    data.instructions.forEach((value) => {
      formData.append('instructions[]', value);
    });
  }

  if (data.suggestions) {
    data.suggestions.forEach((value) => {
      formData.append('suggestions[]', value);
    });
  }

  if (data.children) {
    data.children.forEach((value) => {
      formData.append('children[]', value);
    });
  }

  if (data.link_buttons) {
    data.link_buttons.forEach((value, index: number) => {
      formData.append(
        `link_buttons[${index}]`,
        `{"id": "${value.id}", "name": "${value.value}", "url": "${value.url}", "icon": "${value.icon}"}`
      );
    });
  }

  if (data.subpages) {
    data.subpages.map((value: any) => {
      formData.append(
        `subpages[]`,
        `${value.id ? `"id": "${value.id}", ` : ''}${value.data.map((v: any) => JSON.stringify(v))}`
      );
    });
  }

  // return connector.post('api/resource/', formData);
  return connector.post('api/resource/add', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

export function updateResourceContent(data: resourceInterface) {
  const formData = new FormData();
  formData.append('id', data.id!);
  formData.append('title', data.title);
  formData.append('type', data.type);
  if (data.name) formData.append('name', data.name);

  formData.append('_method', 'put');

  if (data.icon) {
    formData.append('icon', data.icon);
  }

  if (data.image_icon) {
    formData.append('image_icon', data.image_icon);
  }

  if (data.short_description) {
    formData.append('short_description', data.short_description);
  }

  if (data.estimated_time) {
    const test = data.estimated_time.toString();
    formData.append('estimated_time', test);
  }

  if (data.pathway_steps) {
    data.pathway_steps.forEach((value) => {
      formData.append('pathway_steps[]', value);
    });
  }

  if (data.keywords) {
    data.keywords.forEach((value) => {
      formData.append('keywords[]', value);
    });
  }

  if (data.goals) {
    data.goals.forEach((value) => {
      formData.append('goals[]', value);
    });
  }

  if (data.parents) {
    data.parents.forEach((value) => {
      formData.append('parents[]', value);
    });
  }

  formData.append('draft', (data.draft as unknown) as string);

  if (data.url) {
    formData.append('url', data.url);
  }

  if (data.urls) {
    data.urls.forEach((value) => {
      formData.append('urls[]', value);
    });
  }

  if (data?.file) {
    formData.append('file', data.file?.file);
  }

  if (data.slug) {
    formData.append('slug', data.slug);
  }

  if (data.dimensions) {
    data.dimensions.forEach((value) => {
      formData.append('dimensions[]', value);
    });
  }

  if (data.elements) {
    data.elements.forEach((value) => {
      formData.append('elements[]', value);
    });
  }

  if (data.author) {
    formData.append('author', JSON.stringify(data.author));
  }

  if (data.content) {
    formData.append('content', data.content);
  }

  if (data.description) {
    formData.append('description', data.description);
  }

  if (data.banner) {
    formData.append('banner', data.banner);
  }

  if (data.instructions) {
    data.instructions.forEach((value) => {
      formData.append('instructions[]', value);
    });
  }

  if (data.suggestions) {
    data.suggestions.forEach((value) => {
      formData.append('suggestions[]', value);
    });
  }

  if (data.children) {
    data.children.forEach((value) => {
      formData.append('children[]', value);
    });
  }

  if (data.link_buttons) {
    data.link_buttons.forEach((value, index: number) => {
      formData.append(
        `link_buttons[${index}]`,
        `{"id": "${value.id}", "name": "${value.value}", "url": "${value.url}", "icon": "${value.icon}"}`
      );
    });
  }

  if (data.subpages) {
    data.subpages.map((value: any) => {
      formData.append(
        `subpages[]`,
        `${value.id ? `"id": "${value.id}", ` : ''}${value.data.map((v: any) => JSON.stringify(v))}`
      );
    });
  }

  return connector.put('api/resource/edit', formData);
}

export function downloadResourceContent(id: string, name: string) {
  return connector.get(`api/resource/${id}/download`, { responseType: 'blob' }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
    link.remove();
  });
}

export function getResource(id: string) {
  return connector.get(`/api/resource/${id}`);
}

export function getResources() {
  return connector.get('/api/resources');
}

export function getResourcesByType() {
  return connector.get('/api/resourcetype');
}

export function getResourcBySlug(slug: string) {
  return connector.get('/api/resource/slug/' + slug);
}

export function getDraftResources() {
  return connector.get('/api/resources/draft');
}

export function getSelfStudyResources() {
  return connector.get('/api/resources/selfStudy');
}

export function getResourcesFromAlgolia(keywords: string, page: number, hits_page: number, filters: Array<string>) {
  return connector.get('/api/algolia/resources', { params: { keywords, page, hits_page, filters } });
}

export function removeResourceContent(id: string) {
  return connector.delete(`api/resource/${id}`);
}

export function clickResource(id: string) {
  return connector.post(`/api/resource/click/${id}`);
}

export function updateProgressSelfStudy(id: string) {
  return connector.post(`/api/resources/selfStudy/progress/${id}`);
}

export function getNITeacherProgress(groupId: string) {
  return connector.get<
    ApiResponse<
      {
        chapter_sequence: number;
        chapter_name: string;
        completed: boolean;
        url: string;
      }[]
    >
  >(`/api/earlyCareer/teacher/progress/${groupId}`);
}

export function getStcpTeacherProgress() {
  return connector.get<
    ApiResponse<
      {
        id: number;
        chapter_sequence: number;
        chapter_name: string;
        completed: boolean;
        offline_study: boolean;
        url: string;
      }[]
    >
  >('/api/ceo/teacher/progress');
}

export function disableWatchVideo(videoName: string) {
  return connector.post('/api/admin/user/disablevideo/' + videoName);
}

export function postChapterTracking(data: { chapter_id: string; offline_study: boolean }) {
  return connector.post('/api/thinkific/prgoress/offline', data);
}

export function getFeatureFlag() {
  return connector.get('/api/posthog/featureflag');
}

export function getVariants() {
  return connector.get('/api/posthog/variants');
}

export function postRegisterEseUser(data: {
  body: {
    first_name: string;
    last_name: string;
    email: string;
    password: string;
    school: string;
    oracle_erp: number;
    branch: number;
    site: string;
    captcha: string;
  };
}) {
  return connector.post('/auth/ese/register', data.body);
}

export function getEseUserDetail(email: string) {
  return connector.get('/auth/ese/user', { params: { email } });
}

export function updateCurrentElement(data: { element: string }) {
  return connector.put('/api/dashboard/starter/elements', { element: data.element });
}

export * from './veoApi';
export * from './feedbackApi';
export * from './pathwaysApi';
export * from './instrumentsApi';
export * from './userManagementApi';
export * from './organisationMangementApi';

interface GetPathwaysElementsResponseBody {
  elements: {
    label: string;
    options: {
      label: string;
      value: string;
      enabled: boolean;
    }[];
  }[];
}
