import React from 'react';
import Label from '../common/label';
import ErrorFeedback from '../common/error';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

const WysiwigInput = ({
  id,
  label,
  hideLabel = false,
  error,
  required = false,
  readOnly = false,
  disabled = false,
  value,
  placeholder,
  onChange,
}: Props) => {
  return (
    <div>
      {label && <Label label={label} hideLabel={hideLabel} id={id} required={required} />}
      <Editor
        toolbarHidden={readOnly || disabled}
        toolbar={{
          options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
        }}
        editorState={value}
        wrapperClassName={'border border-gray-600 rounded'}
        editorClassName={'p-3'}
        onEditorStateChange={onChange}
        placeholder={placeholder}
      />
      {error && <ErrorFeedback id={id} error={error} />}
    </div>
  );
};

type Props = {
  id: string;
  label: string;
  placeholder?: string;
  hideLabel?: boolean;
  error?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  value?: EditorState;
  onChange?: (editorState: EditorState) => void
}

export default WysiwigInput;
