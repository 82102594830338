import React, { MutableRefObject, useState } from 'react';
import OnboardingBody from './onboardingBody';
import {
  onboardingStepInterface,
  onboardingStepsCoordinator,
  onboardingStepsTeacher,
  OnboardingType,
} from './resources';
import Portal from '../../../global/portal/portal';
import FocusTrap from 'focus-trap-react';
import { MaskStyled } from '../../../global/messages/modal/modal.styled';
import DashboardLayout from '../../../layout/dashboard';
import { useQuery } from 'react-query';
import { ME } from '../../../service/queryKeys';
import { getMe } from '../../../service/api';
import { isLoggedIn } from '../../../service/auth';

const Onboarding = ({ setOnboarding, onboarding, onboardingTracking, setConfetti }: OnboardingType) => {
  const findFirstIncompleteStep = (obj: any, steps: onboardingStepInterface[]) => {
    if (obj) {
      const stepIndex = steps.find((step: onboardingStepInterface) => !obj[step.id])?.index ?? steps.length;
      if (stepIndex != undefined) return stepIndex;
    }
  };

  const { data: user } = useQuery([ME], () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const userData = user?.data.me;

  const onboardingStepsCoordinatorValues = onboardingStepsCoordinator(userData);

  const onboardingStepsTeacherValues = onboardingStepsTeacher(userData);

  const [currentStep, setCurrentStep] = useState<number>(
    findFirstIncompleteStep(onboardingTracking, onboardingStepsCoordinatorValues) ?? 0
  );

  const mask = React.useRef() as MutableRefObject<HTMLDivElement>;

  return (
    <>
      {onboarding != undefined && typeof currentStep === 'number' ? (
        <DashboardLayout>
          <Portal className={'portal-modal'}>
            <FocusTrap
              active
              focusTrapOptions={{
                initialFocus: '#modal-wrapper',
              }}
            >
              <MaskStyled id={'modal-wrapper'} ref={mask} tabIndex={0} data-toggle={'true'}>
                <div className={'w-5/6 mx-auto h-full flex p-8 rounded-md '}>
                  <OnboardingBody
                    version={onboarding}
                    user={userData}
                    steps={
                      onboarding === 'school'
                        ? onboardingStepsCoordinatorValues
                        : onboarding === 'teacher'
                        ? onboardingStepsTeacherValues
                        : []
                    }
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    setOnboarding={setOnboarding}
                    setConfetti={setConfetti}
                  />
                </div>
              </MaskStyled>
            </FocusTrap>
          </Portal>
        </DashboardLayout>
      ) : (
        <></>
      )}
    </>
  );
};

export default Onboarding;
