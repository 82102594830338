import React, { useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import { MyGroupsDetailsTable } from './sections';
import { Section } from '../common';
import classnames from 'classnames';
import { useQuery } from 'react-query';
import { getSchoolGroups, getTeacherMemberGroups } from '../../service/api';
import { MY_GROUPS, SCHOOLS_GROUPS } from '../../service/queryKeys';
import { ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER } from '../../resources/roles';
import { Me } from '../../@types/Entity/Me';

const GroupManagementPage = ({ me }: Props) => {
  const [orderTeacherMembersGroupsParam, setOrderTeacherMembersGroupsParam] = useState<string>();
  const myGroupsQuery = useQuery(
    [MY_GROUPS, orderTeacherMembersGroupsParam],
    () => getTeacherMemberGroups(orderTeacherMembersGroupsParam),
    {
      staleTime: Infinity,
      select: (data) => data.data,
      placeholderData: [],
      keepPreviousData: true,
      enabled: me.role === ROLE_TEACHER,
    }
  );

  const schoolGroupsQuery = useQuery([SCHOOLS_GROUPS], () => getSchoolGroups(), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    keepPreviousData: true,
    enabled: me.role !== ROLE_TEACHER,
  });

  const groupsQuery = me.role === ROLE_TEACHER ? myGroupsQuery : schoolGroupsQuery;

  return (
    <>
      <DashboardLayout title={'My teams'}>
        <Section
          headline={''}
          more={
            0 < groupsQuery.data!.length
              ? `${groupsQuery.data!.length} team${1 !== groupsQuery.data!.length ? 's' : ''}`
              : ''
          }
          className={classnames({
            'field-mb':
              me.great_teaching_lead ||
              me.role === ROLE_SCHOOL_COORDINATOR ||
              me.role === ROLE_ASSISTANT_GT_COORDINATOR,
          })}
        >
          <MyGroupsDetailsTable
            data={groupsQuery.data!}
            setOrder={me.role === ROLE_TEACHER ? setOrderTeacherMembersGroupsParam : undefined}
          />
        </Section>
      </DashboardLayout>
    </>
  );
};

type Props = {
  me: Me;
};

export default GroupManagementPage;
