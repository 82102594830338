import React from 'react';
import { components } from 'react-select';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from "../../../resources/vars";

/**
 * Adds a info message at the top of the MenuList component.
 * Use it like this:
 * MenuList: (props) =>
 * <MessageAtMenuList message={'foo'} {...props} />
 * @param message
 * @param props
 * @constructor
 */
const MessageAtMenuList: React.FC<any> = ({ message, ...props }: any): JSX.Element => {
  return (
    <components.MenuList {...props}>
      <div className={'text-info bg-gray-100 flex items-center justify-center gap-5 my-1 py-4 px-2'}>
        <Icon icon={GLOBAL_ICONS['info']} container={false} elementSize={20}/>
        <i>{message as React.ReactNode}</i>
      </div>
      {props.children}
    </components.MenuList>
  );
};

export default MessageAtMenuList;
