import React from 'react';
import '../../../../style/style.css';
import { findComponentTemplate, transformData } from '../resources/resources';

const Slide = ({ page }: { page: any }) => {
  const data = transformData(page.content[0]).map((p: any) => p);
  const isImageSlide = data.some((item: any) => 'imageSlide' in item);

  const getImageSlideValue = (data: any) => {
    for (const item of data) {
      if ('imageSlide' in item) {
        return item['imageSlide'];
      }
    }
    return null;
  };

  return (
    <div className={`slide-content pb-4 w-full overflow-y-auto text-wrap ${!isImageSlide ? 'scrollbar ' : ''}`}>
      {isImageSlide ? (
        <div style={{ maxHeight: '600px' }} className={' lg:h-full w-full flex'}>
          <img src={getImageSlideValue(data)} className={'object-scale-down h-min w-full mx-auto'} />
        </div>
      ) : (
        <div className={'px-8 pt-8 mx-auto w-full h-full break-normal'}>
          {data.map((p: any, index: number) => {
            const key = Object.keys(p)[0];
            const value = Object.values(p)[0];
            if (p === 'welcome')
              return (
                <div key={index}>
                  <h4 className={'mb-8'}>This could be a pre-defined welcome page.</h4>
                  <p className={'mb-2'}>
                    You could specify a default page, that was not created through the resource page.
                  </p>
                  <p>We could have more than one of those.</p>
                </div>
              );
            else return <div className={''}>{findComponentTemplate(key, value)}</div>;
          })}
        </div>
      )}
    </div>
  );
};

export default Slide;
