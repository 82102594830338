import React, { useState } from 'react';
import { PathwaysGoalsProps } from '../pathwaysPage.types';
import { AddButton, JoinButton } from '../../../global/button/common';
import Button from '../../../global/button/button';
import Empty from '../../../global/empty/empty';
import { SectionInAccordion } from '../components';
import { SliceText } from '../../../global/tooltip/common';
import Tag from '../../../global/tag/tag';
import { LinkStyled } from '../../../global/link/link.styled';
import Accordion from '../../../global/accordion/accordion';
import useModal from '../../../hooks/useModal';
import ShareWithGroupModal from '../modals/shareWithGroup.modal';

const PathwaysGoals = ({ toggle, data, disabled }: PathwaysGoalsProps) => {
  const [strategyRow, setStrategyRow] = useState<number | null>();

  const [sharedWithGroupModal, toggleSharedWithGroupModal, setSharedWithGroupModalProps] = useModal((props: any) => (
    <ShareWithGroupModal {...props} />
  ));

  return data.length === 0 ? (
    <Empty
      element={'strategies'}
      action={
        <>
          <Button disabled={disabled} onClick={() => toggle()} asLink style={{ fontStyle: 'italic', padding: 5 }}>
            clicking here
          </Button>
        </>
      }
    />
  ) : (
    <React.Fragment>
      {sharedWithGroupModal}
      <div className={'field-mb'}>
        <div className={'flex inline gap-5'}>
          <AddButton disabled={disabled} onClick={() => toggle()} label={'Strategy'} />
        </div>
      </div>
      {data?.map((strategy: any, index: number) => (
        <div key={`goal_${index}`}>
          <Accordion
            key={index}
            isDisabled={data.length === 1}
            isOpen={strategyRow === index || data.length === 1}
            handleOpen={() => (strategyRow === index ? setStrategyRow(null) : setStrategyRow(index))}
            title={
              <div className={'flex items-center justify-between w-full'}>
                <div className={'flex items-center'}>
                  <span className={'text-muted'}>Strategy {index + 1} —</span> <b>{strategy.name}</b>
                  {strategy.shared_with !== null && (
                    <div className={'ml-10'}>
                      <Tag style={{ height: 24 }} mainColor={'muted'} content={'Shared'} />
                    </div>
                  )}
                </div>
                <div className={'flex items-center gap-3'}>
                  {strategy.shared_with === null && (
                    <Button
                      disabled={disabled}
                      asLink
                      style={{ padding: 0 }}
                      onClick={() => {
                        setSharedWithGroupModalProps({
                          title: 'Share with group',
                          strategy,
                          groupId: strategy.shared_with?.id,
                        });

                        toggleSharedWithGroupModal(true);
                      }}
                    >
                      Share with group
                    </Button>
                  )}
                  <Button
                    disabled={disabled}
                    asLink
                    style={{ padding: 0 }}
                    onClick={(event) => {
                      event.stopPropagation();
                      toggle('Update strategy', strategy, strategy.shared_with !== null);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            }
          >
            <div className={'md:grid grid-flow-col grid-cols-3 gap-4'}>
              {/* Description */}
              <SectionInAccordion title={'Description'}>
                <SliceText value={strategy.description} />
              </SectionInAccordion>
              {/* Active ingredients (steps) */}
              <SectionInAccordion title={'Working steps'}>
                <ul className={'list-decimal list-inside'}>
                  {strategy.steps.map((step: any, index: number) => (
                    <li key={`ingredient_list--${index}`} className={'my-2'}>
                      {step}
                    </li>
                  ))}
                </ul>
              </SectionInAccordion>
              {/* Examples URLs - Additional information*/}
              <div>
                <SectionInAccordion className={null} title={'Resources'}>
                  <div className={'sm-field-mb'}>
                    {strategy.urls.filter((url: any) => url.link).length !== 0 ? (
                      strategy.urls
                        .filter((url: any) => url.link)
                        .map((url: any, index: number) => {
                          return (
                            <LinkStyled key={`url_list--${index}`} href={url.link} target={'_blank'}>
                              {url.name || url.link}
                            </LinkStyled>
                          );
                        })
                        .reduce((prev: any, curr: any) => [prev, ', ', curr] as any)
                    ) : (
                      <i className={'text-muted'}>No resources</i>
                    )}
                  </div>
                </SectionInAccordion>
                <SectionInAccordion className={null} title={'Additional information'}>
                  {strategy.additional_info ? (
                    <SliceText value={strategy.additional_info} />
                  ) : (
                    <i className={'text-muted'}>No additional information</i>
                  )}
                </SectionInAccordion>
              </div>
            </div>
          </Accordion>
        </div>
      ))}
    </React.Fragment>
  );
};

export default PathwaysGoals;
