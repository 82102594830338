import React, { useEffect, useState } from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getSchoolGroups, postTeacherMemberGroup } from '../../../service/api';
import DefaultTable from '../../../global/table/defaultTable';
import { ArchivedName } from '../../../global/table/components';
import { Section } from '../../common';
import { JOINED_GROUPS, ME, PATHWAYS_V2, SCHOOLS_GROUPS, MY_GROUPS } from '../../../service/queryKeys';
import { Checkbox, Radio } from 'pretty-checkbox-react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';

const JoinGroupModal = ({ toggle, pathwaysId }: ModalToggleProps & {pathwaysId?: string}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(postTeacherMemberGroup, {
    onSuccess: () => {
      toggle(false)
      queryClient.invalidateQueries(PATHWAYS_V2);
      queryClient.invalidateQueries(SCHOOLS_GROUPS);
      queryClient.invalidateQueries(JOINED_GROUPS);
      queryClient.invalidateQueries(MY_GROUPS);
      queryClient.invalidateQueries(ME);
    },
  });

  const [orderSchoolGroupsParam, setOrderSchoolGroupsParam] = useState<string>();
  const schoolGroupsQuery = useQuery(
    [SCHOOLS_GROUPS, undefined, orderSchoolGroupsParam],
    () => getSchoolGroups(undefined, orderSchoolGroupsParam, true),
    {
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const [teamSelected, setTeamSelected] = useState<string>();

  return (
    <Modal>
      <Modal.Body>
        If you can&apos;t see your GTT team here, and want to create one, please contact your Great Teaching
        Coordinator.
        <Section headline={'My teams'} size={'md'} className={'mt-3'}>
          <DefaultTable
            id={'school_groups'}
            showCounter={false}
            apiHandled={true}
            setApiSort={setOrderSchoolGroupsParam}
            columns={[
              { id: 'name', label: 'Group name', isSortable: true },
              { id: 'user_name', label: 'Created by', isSortable: true },
              { id: 'actions', label: 'Actions' },
            ]}
            rows={schoolGroupsQuery.data!.filter((group:any) => group.joined).map((group: any, index: number) => {
              return {
                id: `row_schoolGroup--${index}`,
                cells: [
                  {
                    id: 'name',
                    content: <ArchivedName archived={!group.enabled} title={group.name} item={'group'} />,
                  },
                  {
                    id: 'user_name',
                    content: (
                      <>
                        <p className={'mb-0'}>{group.user_name}</p>
                        <small>{group.user_email}</small>
                      </>
                    ),
                  },
                  {
                    id: 'actions',
                    class: 'actions',
                    content: (
                      <ul className={'actions-list'}>
                        <Radio
                          color={'primary-o'}
                          onChange={() => setTeamSelected(group.id)}
                          checked={group.id === teamSelected}
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check"
                              viewBox="0 0 16 16"
                            >
                              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                            </svg>
                          }
                        />
                      </ul>
                    ),
                  },
                ],
              };
            })}
            noDataMessage={'You haven\'t joined to any team yet.'}
          />
        </Section>
        <Section headline={'Available teams'} size={'md'} className={'mt-3'}>
          <DefaultTable
            id={'school_groups'}
            showCounter={false}
            apiHandled={true}
            setApiSort={setOrderSchoolGroupsParam}
            columns={[
              { id: 'name', label: 'Group name', isSortable: true },
              { id: 'user_name', label: 'Created by', isSortable: true },
              { id: 'actions', label: 'Actions' },
            ]}
            rows={schoolGroupsQuery.data!.filter((group:any) => !group.joined).map((group: any, index: number) => {
              return {
                id: `row_schoolGroup--${index}`,
                cells: [
                  {
                    id: 'name',
                    content: <ArchivedName archived={!group.enabled} title={group.name} item={'group'} />,
                  },
                  {
                    id: 'user_name',
                    content: (
                      <>
                        <p className={'mb-0'}>{group.user_name}</p>
                        <small>{group.user_email}</small>
                      </>
                    ),
                  },
                  {
                    id: 'actions',
                    class: 'actions',
                    content: (
                      <ul className={'actions-list'}>
                        <Radio
                          color={'primary-o'}
                          onChange={() => setTeamSelected(group.id)}
                          checked={group.id === teamSelected}
                          icon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-check"
                              viewBox="0 0 16 16"
                            >
                              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                            </svg>
                          }
                        />
                      </ul>
                    ),
                  },
                ],
              };
            })}
            noDataMessage={'There are no teams to join yet.'}
          />
        </Section>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          label={'Go back'}
          onClick={() => {
            toggle(false);
          }}
        />
        <Modal.ConfirmButton
          id={'selectTeamModal_selectButton'}
          disabled={teamSelected == null}
          label={'Select'}
          onClick={() => mutation.mutate({ groupId: teamSelected!, pathwaysId: pathwaysId })}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default JoinGroupModal;
