import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer, Text } from 'recharts';
import { round } from 'lodash';

const CustomizedLabel = (props: any) => {
  const { x, y, width, value, color, isPdf } = props;

  if (value == null) {
    return <></>;
  }

  let r = width / (value < 10 ? 3 : 2.5);
  let fontSize = width / 3;

  if (isPdf) {
    r = width / 2 > 15 ? 12 : 8;
    fontSize = width / 2 > 15 ? 9 : 7;
  }

  return (
    <g>
      <circle cx={x + width / 2} cy={y} r={r} fill="white" />
      <text x={x + width / 2} y={y} fill={color} textAnchor="middle" dominantBaseline="middle" style={{ fontSize }}>
        {Math.round(value) + '%'}
      </text>
    </g>
  );
};

export const getColorFomLabel = (label: string) => {
  if (label.includes('1.')) {
    return '#E63224';
  }
  if (label.includes('2.')) {
    return '#52b18f';
  }
  if (label.includes('3.')) {
    return '#4a82c3';
  }
  if (label.includes('4.')) {
    return '#594492';
  }
};

const CustomBarWithTarget = (props: any) => {
  const { x, y, width, height, color, dataKey, background, questionDirection, value, isPdf } = props;

  if (value == null) {
    return <></>;
  }

  const pse = props[`pse-${dataKey}`];
  const ci = props[`ci-${dataKey}`];

  const totalHeight = y + height;

  // The 110 value is the 100 (max value) + 10 (the extra space to fit the circle)
  const pseY = totalHeight - (background.height * pse) / 110;
  const ciLY = totalHeight - (null != ci ? (background.height * ci.l) / 110 : 0);
  const ciUY = totalHeight - (null != ci ? (background.height * ci.u) / 110 : 0);

  return (
    <svg>
      {/*Data rectangle*/}
      <rect x={x} y={y} width={width} height={height} stroke="none" fill={color} />
      {/*PSE mark*/}
      {pse && (
        <polygon
          points={`
          ${x + width},${pseY}
          ${x + width + (isPdf ? 8 : 14)},${pseY - (isPdf ? 8 : 14)}
          ${x + width + (isPdf ? 5 : 9)},${pseY}
          ${x + width + (isPdf ? 8 : 14)},${pseY + (isPdf ? 8 : 14)}
        `}
          fill={'#bebebe'}
        />
      )}
      {/*Top horizontal line*/}
      <line x1={x - (isPdf ? 5 : 10)} x2={x + width + (isPdf ? 5 : 10)} y1={y} y2={y} stroke={color} strokeWidth={2} />
      {/*Vertical CI line*/}
      <line
        x1={x - (isPdf ? 5 : 10)}
        x2={x - (isPdf ? 5 : 10)}
        y1={ciLY}
        y2={ciUY}
        stroke={color}
        strokeWidth={isPdf ? 2 : 5}
        strokeLinecap={'round'}
      />
      {/*% Text*/}
      {null != questionDirection && value > 25 && (
        <Text
          x={x + width / 3}
          y={totalHeight - 10}
          fill={'white'}
          width={100}
          style={isPdf ? { fontSize: 12 } : undefined}
          textAnchor={'start'}
          verticalAnchor="middle"
          angle={-90}
        >
          {questionDirection === 1 ? '% Agree' : '% Disagree'}
        </Text>
      )}
    </svg>
  );
};

const CustomizedAxisTick = (props: any) => {
  const { x, y, payload, color, isPdf, dataKey, landscape } = props;

  let fontSize;

  if (isPdf) {
    if (landscape) {
      fontSize = 10;
    } else if (props.chartHeight < 400) {
      fontSize = 10;
    } else {
      fontSize = 12;
    }
  }

  return (
    <Text
      x={x}
      y={isPdf && dataKey !== 'element' ? y - 10 : y}
      fill={color ?? getColorFomLabel(payload.value || '')}
      width={landscape ? 290 : 200}
      angle={-90}
      textAnchor={'end'}
      verticalAnchor="middle"
      style={isPdf ? { fontSize: fontSize } : undefined}
    >
      {payload.value}
    </Text>
  );
};
const CustomXLabel = (props: any) => {
  const { y, width } = props.viewBox;

  if (props.dataKey !== 'element') {
    return <></>;
  }

  return (
    <Text
      x={width / 2}
      y={y + 25}
      fill="#8b8b8b"
      width={width}
      fontWeight={'bold'}
      verticalAnchor="middle"
      style={props.isPdf ? { fontSize: 13 } : undefined}
    >
      Elements
    </Text>
  );
};

const CustomYLabel = (props: any) => {
  const { x, y, height } = props.viewBox;

  return (
    <Text
      x={x + 20}
      y={(height - y) / 2}
      style={props.isPdf ? { fontSize: props.chartHeight < 400 ? 10 : 13 } : undefined}
      fill="#8b8b8b"
      angle={-90}
      fontWeight={'bold'}
      textAnchor={'middle'}
    >
      % Student Endorsement
    </Text>
  );
};

const CustomToolTip = (props: any) => {
  const { active, payload, label, tooltipLabels } = props;

  if (active && payload && payload.length) {
    return (
      <div className={'bg-white p-2 border-2 border-gray-300'}>
        <p className={'mb-2'} style={{ color: getColorFomLabel(label) }}>
          {label}
        </p>
        {payload.map((p: any) => (
          <p key={Math.random()} className={'text-slate-300'}>
            {props?.starterTemplate
              ? round(p.value)
              : `${tooltipLabels ? tooltipLabels[p.dataKey][label] : p.name}: ${round(p.value)}`}
          </p>
        ))}
      </div>
    );
  }

  return null;
};

const CustomBarChart = ({
  id,
  data,
  dataKey,
  starterTemplate = false,
}: {
  id: string;
  data: {
    info?: Array<Record<string, any>>;
    dataKeys?: Array<{ id: string; name: string }>;
    color?: any;
    tooltipLabels?: Record<string, Record<string, string>>;
  };
  dataKey: string;
  starterTemplate?: boolean;
}) => {
  if (!data.info || !data.dataKeys) {
    return null;
  }
  return (
    <ResponsiveContainer
      minWidth={80 * data.info.length * data.dataKeys.length}
      width={data.info.length * data.dataKeys.length < 10 ? '70%' : '100%'}
      height={650}
    >
      <BarChart
        barCategoryGap={20}
        data={data.info}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        barGap={25}
        id={id}
      >
        <CartesianGrid strokeDasharray="3,3" />
        <XAxis
          dataKey={dataKey}
          tickMargin={40}
          height={250}
          interval={0}
          tick={(props) => <CustomizedAxisTick {...props} color={data.color} dataKey={dataKey} />}
          label={(props: any) => <CustomXLabel {...props} dataKey={dataKey} />}
        />
        <YAxis
          domain={[0, 110]}
          ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
          label={(props: any) => <CustomYLabel {...props} dataKey={dataKey} />}
        />
        <Tooltip
          content={(props) => (
            <CustomToolTip {...props} starterTemplate={starterTemplate} tooltipLabels={data.tooltipLabels} />
          )}
        />
        {data.dataKeys!.map((dataKey, index) => (
          <Bar
            key={index}
            dataKey={dataKey.id}
            name={dataKey.name}
            shape={(props) => <CustomBarWithTarget {...props} dataKey={dataKey.id} />}
            isAnimationActive={false}
          >
            <LabelList dataKey={dataKey.id} content={(props) => <CustomizedLabel {...props} />} />
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

// This is component is duplicated because ResponsiveContainer doesn't work with a custom component
export const CustomBarChartPDF = ({
  id,
  data,
  dataKey,
  height = 450,
  width = 600,
  landscape = false,
}: {
  id: string;
  data: {
    info?: Array<Record<string, any>>;
    dataKeys?: Array<{ id: string; name: string }>;
    color?: any;
    tooltipLabels?: Record<string, Record<string, string>>;
  };
  dataKey: string;
  height?: number;
  width?: number;
  landscape?: boolean;
}) => {
  return (
    <BarChart
      width={width ?? 600}
      height={height ?? 450}
      barCategoryGap={height < 400 ? 10 : 6}
      data={data.info}
      margin={{
        top: 0,
        right: 20,
        left: 5,
        bottom: 0,
      }}
      barGap={25}
      id={id}
    >
      <CartesianGrid strokeDasharray="3,3" />
      <XAxis
        dataKey={dataKey}
        tickMargin={40}
        height={height / 2}
        interval={0}
        tick={(props) => (
          <CustomizedAxisTick {...props} color={data.color} chartHeight={height} isPdf={true} landscape={landscape} />
        )}
        label={(props: any) => <CustomXLabel {...props} dataKey={dataKey} isPdf={true} />}
      />
      <YAxis
        style={{ fontSize: 14 }}
        domain={[0, 110]}
        ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
        label={(props: any) => <CustomYLabel {...props} dataKey={dataKey} chartHeight={height} isPdf={true} />}
      />
      <Tooltip content={(props) => <CustomToolTip {...props} tooltipLabels={data.tooltipLabels} />} />
      {data.dataKeys!.map((dataKey, index) => (
        <Bar
          key={index}
          dataKey={dataKey.id}
          name={dataKey.name}
          shape={(props) => <CustomBarWithTarget {...props} dataKey={dataKey.id} isPdf={true} />}
          isAnimationActive={false}
        >
          <LabelList dataKey={dataKey.id} content={(props) => <CustomizedLabel {...props} isPdf={true} />} />
        </Bar>
      ))}
    </BarChart>
  );
};

export type CustomBarChartDataType = {
  values?: Array<Record<string, any>>;
  keys?: Array<string>;
};

export default CustomBarChart;
