import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { updateOnboardingTracking } from '../../../service/api/schoolApi';
import { ME, ONBOARDING_TRACKING } from '../../../service/queryKeys';
import { toast } from 'react-toastify';

const ConfirmationModal = ({ toggle, type }: ModalToggleProps & { type?: any }) => {
  const queryClient = useQueryClient();

  const updateOnboardingTrackingMutation = useMutation(updateOnboardingTracking, {
    onSuccess: () => {
      queryClient.invalidateQueries(ONBOARDING_TRACKING);
      queryClient.invalidateQueries(ME);
    },
  });

  return (
    <Modal>
      <Modal.Body>
        <p>
          Just checking, are you{' '}
          {type === 'teacher' ? 'ready to start using the GTT' : type === 'school' ? 'ready to launch' : ''}?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          label={'No'}
          onClick={() => {
            toggle(false);
          }}
        />
        <Modal.ConfirmButton
          label={'Yes'}
          onClick={() => {
            updateOnboardingTrackingMutation.mutateAsync('dashboard_onboarding_confirm');
            toggle(false);
            toast.success('Congratulations! You finished the onboarding!');
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
