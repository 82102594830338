import React from 'react';
import { toast } from 'react-toastify';
import { FieldArray, Formik } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { StrategyProps } from '../pathwaysPage.types';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import Input from '../../../form/input/input';
import Label from '../../../form/common/label';
import { AddButton } from '../../../global/button/common';
import { DragItem, ListItem } from '../components';
import * as Yup from 'yup';
import Alert from '../../../global/alert/alert';
import Selector from '../../../form/select/selector';
import { DefaultOptionType } from '../../../resources/types';
import { ActionMeta } from 'react-select';
import { useQuery } from 'react-query';
import { getStrategies } from '../../../service/api';
import { EBE_STRATEGIES, SHARED_STRATEGIES } from '../../../service/queryKeys';
import { v4 as uuidv4 } from 'uuid';

type DevelopmentAdminStrategyModalProps = {
  strategy: any;
  mutation: any;
  goal?: string;
  isShared?: boolean;
  team?: { id: string; name: string };
};

export const INITIAL_STRATEGY_STATE: StrategyProps = {
  name: '',
  description: '',
  steps: [''],
  urls: [{ link: '', name: '' }],
  additional_info: '',
};

export const STRATEGY_SCHEMA = Yup.object().shape({
  name: Yup.string().trim().required().max(64),
  description: Yup.string().trim().required().max(200),
  additional_info: Yup.string().trim(),
  steps: Yup.array().min(1).of(Yup.object({
    id: Yup.string(),
    step: Yup.string().trim().required()
  })),
  urls: Yup.array()
    .min(0)
    .of(
      Yup.object().shape({
        name: Yup.string().trim(),
        link: Yup.string().trim(),
      })
    ),
});

const PathwaysStrategyModal = ({
  mutation,
  strategy,
  toggle,
  goal,
  isShared,
  team,
}: ModalToggleProps & DevelopmentAdminStrategyModalProps) => {
  const ebeStrategiesQuery = useQuery(EBE_STRATEGIES, () => getStrategies(undefined, undefined, 'ebe'), {
    staleTime: Infinity,
    select: (data) =>
      Object.values(
        data.data.data.reduce((acc: any, current: any) => {
          (acc[current['goal_id']] = acc[current['goal_id']] || []).push(current);
          return acc;
        }, {})
      ).map((group: any) => {
        return {
          label: group[0].goal_name,
          options: group.map((strategy: any) => {
            return {
              label: strategy.name,
              value: strategy.id,
              ...strategy,
            };
          }),
        };
      }),
    placeholderData: [],
  });

  const sharedStrategiesQuery = useQuery(
    [SHARED_STRATEGIES, team?.id],
    () => getStrategies(undefined, undefined, 'shared', team!.id),
    {
      staleTime: Infinity,
      select: (data) =>
        data.data.data.map((strategy: any) => {
          return {
            label: strategy.name,
            value: strategy.id,
            ...strategy,
          };
        }),
      placeholderData: [],
      enabled: team != null,
    }
  );

  return (
    <Modal>
      <Formik
        initialValues={{
          ...INITIAL_STRATEGY_STATE,
          goal_ids: [],
          goal_id: goal,
          ...strategy,
          steps:
            (strategy?.steps ?? []).length === 0
              ? [
                  {
                    step: '',
                    id: uuidv4(),
                  },
                ]
              : strategy.steps.map((step: string) => ({ step, id: uuidv4() })),
        }}
        validateOnMount={true}
        validationSchema={STRATEGY_SCHEMA}
        onSubmit={(values) => {
          const copy = { ...values };
          copy.steps = copy.steps.map((step: { id: string; step: string }) => step.step);

          mutation.mutateAsync(copy).then(() => {
            if (!values.id) {
              toast.success('Added new strategy');
              return;
            }
            toast.success('Strategy updated');
          });
        }}
      >
        {({ values, handleChange, handleSubmit, isValid, setValues, setFieldValue }) => (
          <DndProvider backend={HTML5Backend}>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                {/* Template */}
                <>
                  <p className={'field-mb'}>Any development goal you set may be used by all teachers in your school.</p>
                  <div className={'n-block-container block-container border-b field-mb pb-8'}>
                    <Alert title={'Strategy templates'}>
                      <p className={'sm-field-mb'}>
                        You can create your own strategy or select a preexisting strategy in order to populate the
                        fields below.
                      </p>
                      <Selector
                        id={'template'}
                        label={'Strategy template'}
                        hideLabel
                        placeholder={'Select a strategy template'}
                        options={ebeStrategiesQuery.data}
                        onChange={(option: DefaultOptionType, action: ActionMeta<any>) => {
                          if (action.action === 'clear') {
                            return;
                          }

                          const preexistingStrategy = { ...option } as any;
                          delete preexistingStrategy.value;
                          delete preexistingStrategy.label;

                          setValues({
                            ...preexistingStrategy,
                            goal_id: goal,
                          });
                        }}
                      />
                    </Alert>
                  </div>
                  {strategy && isShared && (
                    <Alert title="Shared strategy" type="warning" className={'field-mb'}>
                      <p>Any changes made will ripple through to other users in the group.</p>
                    </Alert>
                  )}
                  {!strategy && null != team && (
                    <div className={'field-mb'}>
                      <Selector
                        id={'shared-strategies'}
                        label={'Shared strategy'}
                        placeholder={'Select a strategy'}
                        options={sharedStrategiesQuery.data}
                        onChange={(option: DefaultOptionType, action: ActionMeta<any>) => {
                          if (action.action === 'clear') {
                            setValues({
                              ...INITIAL_STRATEGY_STATE,
                              goal_id: goal,
                            });
                            return;
                          }

                          const sharedStrategy = { ...option } as any;
                          delete sharedStrategy.value;
                          delete sharedStrategy.label;

                          setValues({
                            ...sharedStrategy,
                            goal_id: goal,
                          });
                        }}
                      />
                    </div>
                  )}
                </>
                {/* Strategy Name */}
                <div className={'field-mb'}>
                  <Input
                    required
                    maxLength={64}
                    id={'name'}
                    name={'name'}
                    label={'Strategy name'}
                    placeholder={'Please enter a strategy name'}
                    value={values.name}
                    onChange={handleChange}
                  />
                </div>
                {/* Strategy Description */}
                <div className={'field-mb'}>
                  <Input
                    required
                    maxLength={200}
                    id={'description'}
                    type={'textarea'}
                    name={'description'}
                    label={'Strategy description'}
                    placeholder={'Please enter a strategy description'}
                    value={values.description}
                    onChange={handleChange}
                  />
                </div>
                {/* Active ingredients */}
                <FieldArray
                  name={'steps'}
                  render={(arrayHelpers) => (
                    <div className={'field-mb'}>
                      <div className={'sm-field-mb'}>
                        <Label
                          required
                          label={'Working steps'}
                          id={'ingredients'}
                          hintText={
                            <AddButton
                              isOutline
                              mainColor={'secondary'}
                              label={'Add step'}
                              onClick={() => arrayHelpers.push({ id: uuidv4(), step: '' })}
                            />
                          }
                        />
                      </div>
                      <p className={'italic text-muted sm-field-mb'}>Break down the strategy into actionable steps.</p>
                      {values.steps.map((step: { step: string; id: string }, index: number) => (
                        <DragItem
                          key={step.id}
                          index={index}
                          id={step.id}
                          type={'steps'}
                          removeDisabled={0 === index && values.steps.length <= 1}
                          handleRemove={() => {
                            arrayHelpers.remove(index);
                          }}
                          moveItem={(draggedId, hoverIndex) => {
                            const stepsCopy = [...values.steps];
                            const draggedItem = stepsCopy.find((step) => step.id === draggedId);

                            const itemRemoved = stepsCopy.splice(stepsCopy.indexOf(draggedItem), 1);
                            stepsCopy.splice(hoverIndex, 0, itemRemoved[0]);
                            setFieldValue('steps', stepsCopy);
                          }}
                          findItem={(id) => {
                            const item = values.steps.find((step: { id: string }) => step.id === id);

                            return {id: item.id, index: values.steps.indexOf(item)};
                          }}
                        >
                          <Input
                            required
                            id={`steps[${index}].step`}
                            label={`Step ${index + 1}`}
                            hideLabel
                            placeholder={`Please enter a name for step ${index + 1}`}
                            onChange={handleChange}
                            value={step.step}
                          />
                        </DragItem>
                      ))}
                    </div>
                  )}
                />
                {/* URLs */}
                <FieldArray
                  name={'urls'}
                  render={(arrayHelpers) => (
                    <div className={'field-mb'}>
                      <div className={'sm-field-mb'}>
                        <Label
                          label={'Resources'}
                          id={'urls'}
                          hintText={
                            <AddButton
                              isOutline
                              mainColor={'secondary'}
                              label={'Add URL'}
                              onClick={() => arrayHelpers.push({ name: '', link: '' })}
                            />
                          }
                        />
                      </div>
                      <p className={'italic text-muted sm-field-mb'}>Add links to articles, videos or research.</p>
                      {values.urls.map((url: { name: string; link: string }, index: number) => (
                        <ListItem
                          key={`url_${index}`}
                          index={index}
                          removeDisabled={0 === index && values.urls.length <= 1}
                          handleRemove={() => arrayHelpers.remove(index)}
                        >
                          <div className={'flex gap-2'}>
                            <div style={{ maxWidth: 100 }}>
                              <Input
                                id={`urls[${index}].name`}
                                label={`URL #${index + 1}`}
                                hideLabel
                                placeholder={'Name'}
                                onChange={handleChange}
                                value={values.urls[index].name}
                              />
                            </div>
                            <div className={'flex-grow'}>
                              <Input
                                id={`urls[${index}].link`}
                                label={`URL #${index + 1}`}
                                hideLabel
                                placeholder={'Please enter a URL'}
                                onChange={handleChange}
                                value={values.urls[index].link}
                              />
                            </div>
                          </div>
                        </ListItem>
                      ))}
                    </div>
                  )}
                />
                {/* Additional information */}
                <Input
                  type={'textarea'}
                  id={'additional_info'}
                  name={'additional_info'}
                  label={'Additional information'}
                  placeholder={'Enter any additional information'}
                  value={values.additional_info || ''}
                  onChange={handleChange}
                />
              </Modal.Body>
              <Modal.Footer>
                <Modal.CancelButton onClick={() => toggle(false)} />
                <Modal.ConfirmButton
                  type={'submit'}
                  label={mutation.isLoading ? 'Loading...' : strategy ? 'Update Strategy' : 'Add Strategy'}
                  disabled={!isValid || mutation.isLoading}
                />
              </Modal.Footer>
            </form>
          </DndProvider>
        )}
      </Formik>
    </Modal>
  );
};

export default PathwaysStrategyModal;
