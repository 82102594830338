import React from 'react';
import { FieldProps, LabelProps, SelectProps } from '../common/field.types';
import { SelectStyled, CreatableSelectStyled, AsyncSelectStyled, AsyncCreatableSelectStyled } from './selector.styled';
import { ClearIndicator } from './components';
import { CLASS_PREFIX } from '../../resources/vars';
import Label from '../common/label';
import ErrorFeedback from '../common/error';

interface SelectorGroupProps extends FieldProps, LabelProps, SelectProps {}

const Selector: React.FC<SelectorGroupProps> = React.forwardRef<HTMLElement, SelectorGroupProps>(
  (
    {
      id,
      label,
      type,
      hideLabel,
      error,
      hintText,
      isCreatableSelect,
      isAsync,
      customComponents,
      ...other
    }: SelectorGroupProps,
    ref
  ) => {
    const DEFAULT_COMPONENTS = {
      ClearIndicator: ClearIndicator,
    };

    const GLOBAL_PROPS = {
      innerRef: ref,
      ref: ref,
      classNamePrefix: CLASS_PREFIX,
      inputId: id,
      name: id,
      hideSelectedOptions: false,
      isClearable: true,
      placeholder: 'placeholder' in other ? other.placeholder : 'Please select an item',
      components: Object.assign(DEFAULT_COMPONENTS, customComponents),
      ...(other as SelectProps),
    };

    return (
      <React.Fragment>
        {label && (
          <Label
            label={label}
            hideLabel={hideLabel}
            id={id}
            required={'required' in other}
            hintText={
              other.maxLength ? ((other.value?.label as string)?.length || 0) + '/' + other.maxLength : hintText
            }
          />
        )}
        {isAsync && isCreatableSelect ? (
          <AsyncCreatableSelectStyled {...(GLOBAL_PROPS as any)} />
        ) : isAsync ? (
          <AsyncSelectStyled {...(GLOBAL_PROPS as any)} />
        ) : isCreatableSelect ? (
          <CreatableSelectStyled {...(GLOBAL_PROPS as any)} />
        ) : (
          <SelectStyled {...(GLOBAL_PROPS as any)} />
        )}
        {error && <ErrorFeedback id={id} error={error} />}
      </React.Fragment>
    );
  }
);

Selector.displayName = 'Selector';

Selector.defaultProps = {
  type: 'text',
  hideLabel: false,
  isCreatableSelect: false,
  isAsync: false,
};

export default Selector;

export const YES_OR_NO_SELECTOR_OPTIONS = [
  { label: 'No', value: false },
  { label: 'Yes', value: true },
];
