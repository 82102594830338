import React, { useEffect, useReducer } from 'react';
import { useQuery } from 'react-query';
import { getSummaryFeedbackData } from '../../../service/api';
import CustomBarChart, { getColorFomLabel } from '../components/customBarChart';
import Choice from '../../../form/choice/choice';
import { SUMMARY_DATA } from '../../../service/queryKeys';
import { AxiosError } from 'axios';
import Alert from '../../../global/alert/alert';
import Button from '../../../global/button/button';
import useModal from '../../../hooks/useModal';
import HelpModal from '../modals/HelpModal';
import { VERSION_B } from '../../instrument/sections/instrumentForm';
import { MAX_SELECTIONS } from './TimeFrameTable';
import { validate as isUuid } from 'uuid';

const ALL_OPTION = 'all';

export const processedSummaryData = (summaryChartData?: any, selectors?: any, surveyVersion?: any) => {
  const info: Array<Record<string, any>> = [];

  if (!summaryChartData) {
    return {};
  }

  const dimensionOptions = summaryChartData.options.filter((option: any) => option.dimension != null);
  const dimensionGroups: Record<number, Array<{ id: string; name: string; dimension?: number | null }>> = {};
  const tooltipLabels: Record<string, Record<string, string>> = {};

  if (dimensionOptions.length > 0) {
    dimensionGroups[Math.random()] = [];

    let found;
    for (const dimensionOption of dimensionOptions) {
      found = false;
      for (const group of Object.values(dimensionGroups)) {
        if (group.some((g) => dimensionOption.dimension === g.dimension)) {
          continue;
        }

        group.push(dimensionOption);
        found = true;
      }

      if (!found) {
        dimensionGroups[Math.random()] = [dimensionOption];
      }
    }
  }

  const keys: Array<{ id: string; name: string }> = [];

  Object.entries(summaryChartData.elements)
    .filter((element) => VERSION_B !== surveyVersion || !element[0].includes('1.'))
    .forEach((element: any) => {
      const item: Record<string, any> = {
        element: element[1],
        color: getColorFomLabel(element[1]),
      };

      for (const selectorId of selectors) {
        if (!summaryChartData.data[selectorId]) {
          return;
        }

        const selector = summaryChartData.options.find((i: any) => i.id === selectorId)!;
        let datakey = selector;

        let itemId = selectorId;

        const dimensionGroupsToArray = Object.entries(dimensionGroups);
        if (dimensionGroupsToArray.length > 0) {
          for (const [id, group] of dimensionGroupsToArray) {
            if (group.some((g) => g.id === selectorId)) {
              itemId = id;
              datakey = { id, name: selector.name };
              break;
            }
          }
        }

        if (!keys.some((key) => key.id === itemId)) {
          keys.push(datakey);
        }

        const value = summaryChartData.data[selectorId].data[element[0]];

        if (null !== value) {
          if (!tooltipLabels[itemId]) {
            tooltipLabels[itemId] = {
              [item.element]: selector.name,
            };
          } else {
            tooltipLabels[itemId][item.element] = selector.name;
          }

          const include = selector.dimensions ? selector.dimensions.includes(parseInt(item.element)) : true;

          item[itemId] = include ? value : null;
          item['ci-' + itemId] = include ? summaryChartData.data[selectorId].ci[element[0]] : null;
          item['pse-' + itemId] = include ? summaryChartData.data[selectorId].pse[element[0]] : null;
        }
      }

      info.push(item);
    });

  return { info, dataKeys: keys, tooltipLabels };
};

const SummaryChartSection = ({
  id,
  surveyVersion,
  timeframeSelections,
  schoolId,
  isSchool = false,
}: {
  id?: string;
  surveyVersion?: string;
  timeframeSelections?: Array<string>;
  schoolId?: number;
  isSchool?: boolean;
}) => {
  const [modal, toggleModal, setModalProps] = useModal((props: any) => <HelpModal {...props} />);

  const [selectors, setSelector] = useReducer((state: Array<string>, action: { value: string | Array<string> }) => {
    let values = [...state];

    if (Array.isArray(action.value)) {
      return action.value;
    }

    if (state.includes(action.value)) {
      values = values.filter((value) => value !== action.value && value);
    } else if (values.length <= MAX_SELECTIONS) {
      if (action.value === ALL_OPTION) {
        values = [];
      } else if (isUuid(action.value)) {
        values = values.filter((value) => value !== ALL_OPTION && isUuid(value));
      } else {
        values = values.filter((value) => value !== ALL_OPTION && !isUuid(value));
      }

      values.push(action.value);
    }

    return [...values];
  }, []);

  const { data: summaryChartData, isSuccess, error, isFetching } = useQuery(
    [SUMMARY_DATA, timeframeSelections, schoolId, surveyVersion, isSchool],
    () => getSummaryFeedbackData(timeframeSelections, schoolId, surveyVersion, isSchool, false),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!summaryChartData || summaryChartData.options.length === 0) {
      return;
    }

    if (isSchool) {
      setSelector({
        value: [summaryChartData.options[0].id],
      });
      return;
    }

    setSelector({
      value: summaryChartData.options.map((option) => option.id).filter((option) => option !== ALL_OPTION),
    });
  }, [summaryChartData]);

  if (isFetching) {
    return (
      <Alert type="info" className={'field-mb'}>
        Loading...
      </Alert>
    );
  }

  if ((error as AxiosError)?.response?.status === 403) {
    return (
      <Alert type="info" className={'field-mb'}>
        {(error as AxiosError)?.response?.data.message}
      </Alert>
    );
  }

  const summaryChartDataData = summaryChartData!.data;
  if (isSchool && summaryChartDataData.length === 0) {
    return (
      <Alert type="info" className={'field-mb'}>
        You&apos;ll receive aggregated student feedback once at least 5 teachers have used the surveys
      </Alert>
    );
  }

  return (
    <div className={'overflow-x-auto'}>
      {modal}
      {isSuccess && (
        <>
          <div className={'border-b field-mb pb-8'}>
            <div className={'flex flex-col flex-wrap lg:flex-row lg:items-center gap-4'}>
              {summaryChartData &&
                summaryChartData.options.map((option) => (
                  <>
                    <Choice
                      id={'summary-choices-instrument'}
                      key={option.id}
                      label={option.name}
                      type={'switch'}
                      checked={selectors?.includes(option.id)}
                      onChange={() => setSelector({ value: option.id })}
                      className={'pretty-no-margin'}
                      disabled={selectors.length == 1 && selectors?.includes(option.id)}
                    />
                  </>
                ))}
            </div>
            <Button
              asLink
              mainColor={'info'}
              size={'sm'}
              style={{ padding: 0 }}
              onClick={() => {
                setModalProps({
                  title: 'Understanding your data',
                  size: 'xlg',
                });
                toggleModal(true);
              }}
            >
              Understanding your data
            </Button>
          </div>
          {selectors.length > 0 &&
            selectors.some(
              (selector) => !Array.isArray(summaryChartDataData) && summaryChartDataData[selector] == null
            ) && (
              <Alert className={'mb-2'} type={'info'}>
                You&apos;ll receive aggregated student feedback once at least 5 teachers have used the surveys
              </Alert>
            )}
          {selectors.length > 0 &&
            !selectors.some(
              (selector) => !Array.isArray(summaryChartDataData) && summaryChartDataData[selector] == null
            ) && (
              <CustomBarChart
                data={processedSummaryData(summaryChartData, selectors, surveyVersion)}
                dataKey={'element'}
                id={id ?? 'summaryChartId'}
              />
            )}
        </>
      )}
    </div>
  );
};

export default SummaryChartSection;
