import Modal from '../../../global/messages/modal/modal';
import ModalComponents from '../../../global/messages/modal/modal.components';
import React from 'react';
import { ModalProps } from '../../../global/messages/modal/modal.types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  checkMeetingPathwayTitleExists,
  checkMeetingTemplateTitleExists,
  CreateUpdateMeetingPathwayRequestBody,
  CreateUpdateMeetingTemplateRequestBody,
  getMeetingTemplates,
  postCreateMeetingPathway,
  postCreateMeetingTemplate,
  putUpdateMeetingPathway,
  putUpdateMeetingTemplate,
} from '../../../service/api/groupsApi';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import DragItem from '../../pathway/components/dragItem';
import Input from '../../../form/input/input';
import ErrorFeedback from '../../../form/common/error';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { chain, debounce } from 'lodash';
import { MeetingTemplate } from '../../../@types/Entity/MeetingTemplate';
import { MEETINGS_PATHWAYS, MEETINGS_TEMPLATES } from '../../../service/queryKeys';
import { MeetingPathway } from '../../../@types/Entity/MeetingPathway';
import { Option } from '../../../@types/global';
import Selector from '../../../form/select/selector';

export default function MeetingPathwayFormModal({ toggle, meetingPathwayToEdit }: Props) {
  const queryClient = useQueryClient();

  const getMeetingTemplatesQuery = useQuery([MEETINGS_TEMPLATES], () => getMeetingTemplates());

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues:
      null !== meetingPathwayToEdit
        ? {
            id: meetingPathwayToEdit.id,
            title: meetingPathwayToEdit.title,
            meetingTemplates: meetingPathwayToEdit.meetingTemplates.map((meetingTemplate) => ({
              selected: {
                label: meetingTemplate.title,
                value: meetingTemplate.id,
              },
            })),
          }
        : {
            id: null,
            title: '',
            meetingTemplates: [
              {
                selected: null,
              },
            ],
          },
  });

  const meetingTemplatesFieldArray = useFieldArray({
    name: 'meetingTemplates',
    control: control,
    keyName: 'uniqueId',
  });

  const createUpdateMeetingPathwayMutation = useMutation(
    ({ data }: { data: CreateUpdateMeetingPathwayRequestBody }) => {
      if (null == meetingPathwayToEdit) {
        return postCreateMeetingPathway(data);
      }

      return putUpdateMeetingPathway(meetingPathwayToEdit.id, data);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MEETINGS_PATHWAYS]);
        toggle();
      },
    }
  );

  const selectedMeetingTemplatesIds = chain(watch('meetingTemplates'))
    .filter((option) => null !== option.selected)
    .map((option) => option.selected!.value)
    .value();
  return (
    <Modal open toggle={() => toggle()} title={'Add pathway'} size={'md'}>
      <form
        onSubmit={handleSubmit((values) =>
          createUpdateMeetingPathwayMutation.mutate({
            data: {
              title: values.title,
              meetingTemplates: values.meetingTemplates.map((meetingTemplate) => ({
                id: meetingTemplate.selected!.value,
              })),
            },
          })
        )}
      >
        <ModalComponents.Body>
          <DndProvider backend={HTML5Backend}>
            <div>
              <Input
                {...register('title')}
                id={'pathway_title'}
                label={'Pathway name'}
                placeholder={'Enter a name'}
                error={errors.title?.message}
              />
            </div>

            <div className={'mt-10'}>
              <div className={'flex justify-between mb-4'}>
                <div>
                  <h5>Agenda templates</h5>
                </div>
                <div>
                  <ButtonIcon
                    icon={'Plus'}
                    isFree
                    label={'Add agenda template'}
                    size={'xsm'}
                    onClick={() => meetingTemplatesFieldArray.append({ selected: null })}
                  />
                </div>
              </div>
              {errors.meetingTemplates?.message && <ErrorFeedback error={errors.meetingTemplates?.message} />}
              {meetingTemplatesFieldArray.fields.map((meetingTemplateField, index) => (
                <DragItem
                  key={meetingTemplateField.uniqueId}
                  id={meetingTemplateField.uniqueId}
                  index={index}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = meetingTemplatesFieldArray.fields.findIndex(
                      (field) => draggedId === field.uniqueId
                    );

                    meetingTemplatesFieldArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => ({
                    id: id,
                    index: meetingTemplatesFieldArray.fields.findIndex((field) => id === field.uniqueId),
                  })}
                  removeDisabled={false}
                  handleRemove={() => meetingTemplatesFieldArray.remove(index)}
                  type={'meetingTemplates'}
                >
                  <Controller
                    control={control}
                    name={`meetingTemplates.${index}.selected`}
                    render={({ field }) => (
                      <Selector
                        {...field}
                        id={`meetingTemplate-selector-${meetingTemplateField.uniqueId}`}
                        error={errors.meetingTemplates?.[index]?.selected?.message}
                        options={chain(getMeetingTemplatesQuery.data?.data ?? [])
                          .filter((meetingTemplate) => !selectedMeetingTemplatesIds.includes(meetingTemplate.id))
                          .map((meetingTemplate) => ({ label: meetingTemplate.title, value: meetingTemplate.id }))
                          .value()}
                      />
                    )}
                  />
                </DragItem>
              ))}
            </div>
          </DndProvider>
        </ModalComponents.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton
            type={'submit'}
            disabled={isSubmitting || createUpdateMeetingPathwayMutation.isLoading}
            label={
              isSubmitting || createUpdateMeetingPathwayMutation.isLoading
                ? 'Loading...'
                : null === meetingPathwayToEdit
                ? 'Add pathway'
                : 'Edit pathway'
            }
          />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

const debouncedCheckMeetingPathwayTitleExists = debounce(
  async (title: string, idToIgnore: string | null = null, resolve: (valid: boolean) => void) => {
    try {
      const response = await checkMeetingPathwayTitleExists(title, idToIgnore);

      resolve(false === response.data.exists);
    } catch {
      resolve(false);
    }
  },
  500
);

const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .max(64)
    .required('This field is required.')
    .test({
      message: 'An agenda pathway with this name already exists.',
      test: async (value, context) =>
        new Promise((resolve) => debouncedCheckMeetingPathwayTitleExists(value!, context.parent.id, resolve)),
    }),
  meetingTemplates: Yup.array()
    .min(1, 'The pathway must have at least 1 Agenda Template.')
    .of(
      Yup.object().shape({
        selected: Yup.object().nullable().required('This field is required.'),
      })
    ),
});

interface Props extends Pick<ModalProps, 'toggle'> {
  meetingPathwayToEdit: MeetingPathway | null;
}

interface FormValues {
  id: string | null;
  title: string;
  meetingTemplates: {
    selected: Option | null;
  }[];
}
