import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard';
import { Section } from '../../common';
import VideoSection from '../../dashboard/sections/videoSection';
import { LinkStyled } from '../../../global/link/link.styled';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Tooltip from '../../../global/tooltip/tooltip';
import routeBuilder from '../../../service/routeBuilder';
import { downloadResourceContent, getMe, getResourcBySlug } from '../../../service/api';
import { toast } from 'react-toastify';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-query';
import { ME, RESOURCES_BY_SLUG } from '../../../service/queryKeys';
import '../resources/styles.css';
import { isLoggedIn } from '../../../service/auth';
import { ROLE_TEACHER_FREE } from '../../../resources/roles';

const ElementPage = ({ slug }: { slug: any }) => {
  const getMeQuery = useQuery([ME], () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const me = getMeQuery.data?.data.me ?? null;

  const isFree = me?.role === ROLE_TEACHER_FREE;

  function removeHash() {
    history.pushState('', document.title, window.location.pathname + window.location.search);
  }

  const [element, setElement] = useState<any>(slug ? 'loading' : 'element');

  const { data: textResourceBySlug } = useQuery([RESOURCES_BY_SLUG, slug], () => getResourcBySlug(slug), {
    select: (data) => data.data.data,
    placeholderData: 'fetching',
    onError: () => setElement('error'),
  });

  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);

    setTimeout(() => {
      if (textResourceBySlug && element != 'error') {
        if (textResourceBySlug && textResourceBySlug != 'fetching') setElement(textResourceBySlug);
        else if (textResourceBySlug && textResourceBySlug === 'fetching') setElement('loading');
        else {
          removeHash();
        }
      } else if (element === 'error') setElement('error');
    }, 1000);
  }, [textResourceBySlug]);

  return (
    <>
      <DashboardLayout
        title={
          element === 'loading'
            ? 'Loading...'
            : element === 'error'
            ? 'Error!'
            : `${element?.element[0] ? element?.element[0].slice(0, 3) : ''} ${element?.title}`
        }
        hideTitle={true}
        parent={{ id: 'modelForGreatTeaching', label: 'Model for Great Teaching' }}
      >
        <div className={'flex-1 block-with-sub-container'}>
          {element === 'loading' ? (
            <>Loading...</>
          ) : element === 'error' ? (
            <>There&apos;s been error fetching the resource. Please contact the EBE team.</>
          ) : (
            <>
              <div className={'flex h-48 w-full rounded-t'} id={'banner'} style={{ backgroundColor: element?.banner }}>
                <div className={'my-auto ml-4'}>
                  <h2 className={'text-white font-semibold ml-8'}>
                    {element?.element[0].slice(0, 3)} {element?.title}
                  </h2>
                </div>
                <div className="ml-auto min-w-fit overflow-hidden">
                  <img src={element?.image_icon} className={'ml-auto h-48  aspect-square -mb-8 opacity-75'} />
                </div>
              </div>
              <Section className={'mb-4'}>
                <div className={'m-auto w-1/2'}>
                  {element?.urls[0] && (
                    <div className={'mb-8'}>
                      <VideoSection video={element?.urls[0]} />
                    </div>
                  )}
                  {element.description && (
                    <div className={'flex my-12'}>
                      <p className={'m-auto'}>{element.description}</p>
                    </div>
                  )}
                  {element?.instructions && (
                    <div className={'flex my-8 mx-auto w-3/4'}>
                      <ul className={'list-disc'}>
                        {element.instructions.map((step: string, index: number) => (
                          <li key={index}>{step}</li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <div className={'flex m-4'}>
                    <div className={'w-1/2 m-auto grid grid-cols-2 gap-48'}>
                      {element?.suggestions && <p className={'m-auto'}>{element.suggestions[0]}</p>}
                      {element?.file_name && (
                        <div className={'w-full m-auto flex rounded-md'}>
                          <Tooltip content={'Download file'}>
                            <LinkStyled
                              onClick={() => {
                                downloadResourceContent(element?.id, element?.file_name)
                                  .then(() => {
                                    toast.success('File downloaded');
                                  })
                                  .catch((error: any) => {
                                    toast.error(error.response.data.message ?? 'An error has occurred.');
                                  });
                              }}
                              target={'_blank'}
                            >
                              <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                                <Icon elementSize={40} icon={GLOBAL_ICONS['downloadFile']} />
                              </div>
                            </LinkStyled>
                          </Tooltip>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </Section>

              {(element?.content || element?.suggestions || element?.file_name || element?.urls[1]) && (
                <Section className={'my-8'}>
                  <div className="w-1/2 m-auto">
                    {element?.content && (
                      <Editor
                        toolbarHidden={true}
                        readOnly={true}
                        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(element.content)))}
                      />
                    )}
                    <div className={'flex m-4'}>
                      <div className={'w-1/2 m-auto grid grid-cols-2 gap-48'}>
                        {element?.suggestions && <p className={'m-auto'}>{element.suggestions[0]}</p>}
                        {element?.file_name && (
                          <div className={'w-full m-auto flex rounded-md'}>
                            <Tooltip content={'Download file'}>
                              <LinkStyled
                                onClick={() => {
                                  downloadResourceContent(element?.id, element?.file_name)
                                    .then(() => {
                                      toast.success('File downloaded');
                                    })
                                    .catch((error: any) => {
                                      toast.error(error.response.data.message ?? 'An error has occurred.');
                                    });
                                }}
                                target={'_blank'}
                              >
                                <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                                  <Icon elementSize={40} icon={GLOBAL_ICONS['downloadFile']} />
                                </div>
                              </LinkStyled>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex my-4">
                      {element?.urls[1] && (
                        <div className={'m-auto w-1/2 my-4'}>
                          <VideoSection video={element.urls[1]} />
                        </div>
                      )}
                    </div>
                  </div>
                </Section>
              )}

              {!isFree && (
                <Section className={'mb-4'}>
                  <Tooltip content={'tooltipText'} disabled={!isFree}>
                    <div className={`${isFree && ' pointer-events-none opacity-50'}`}>
                      <div className={'w-1/2 xl:w-full m-auto flex m-4'}>
                        <div
                          className={`grid grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 m-auto gap-8 ${
                            me!.role === ROLE_TEACHER_FREE && 'pointer-events-none'
                          }`}
                        >
                          <Link to={routeBuilder('resourcePage').generate()}>
                            <div
                              className={'bg-gray-200 w-56 p-4 elementCardBoxShadow'}
                              onClick={() => {
                                localStorage.setItem('resourcePageFilters', `${element?.element[0]}`);
                              }}
                            >
                              <div className="flex m-auto h-12">
                                <Icon className={'my-auto ml-auto'} icon={'Book'} elementSize={40} />
                                <p className={'my-auto mr-auto text-xl font-semibold'}>Resources</p>
                              </div>
                            </div>
                          </Link>

                          <Link to={routeBuilder('videoObservation').generate()}>
                            <div className={'bg-gray-200 w-56 p-4 elementCardBoxShadow'}>
                              <div className="flex h-12 m-auto">
                                <Icon className={'my-auto ml-auto'} icon={'Youtube'} elementSize={40} />
                                <p className={'my-auto mr-auto text-xl font-semibold'}>Video feedback</p>
                              </div>
                            </div>
                          </Link>

                          <Link to={routeBuilder('studentSurveys').generate()}>
                            <div className={'bg-gray-200 w-56 p-4 elementCardBoxShadow'}>
                              <div className="flex h-12 m-auto">
                                <Icon className={'my-auto ml-auto'} icon={'AlignMiddle'} elementSize={40} />
                                <p className={'my-auto mr-auto text-xl font-semibold'}>Student surveys</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Tooltip>
                </Section>
              )}

              {/*<Section className={'my-8'}>
                TECHNIQUES
                <div className={'flex my-4'}>
                  <div className={'m-auto flex w-1/2 h-64 rounded-md bg-gray-300'}></div>
                </div>
              </Section>*/}
            </>
          )}
        </div>
      </DashboardLayout>
    </>
  );
};

export default ElementPage;
