import React from 'react';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';
import Input from '../../../../form/input/input';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FOCUS_OPTIONS, PATHWAYS_V3 } from '../../../../service/queryKeys';
import { getFocusOptions, postOrPutV3Pathway } from '../../../../service/api';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import Selector from '../../../../form/select/selector';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

const AddEditV3PathwayModal = ({
  toggle,
  pathway,
  edit,
}: ModalToggleProps & {
  pathway?: V3Pathway;
  edit?: boolean;
}) => {
  const queryClient = useQueryClient();
  const fromInvitation = pathway?.group || pathway?.goals?.some((goal) => goal.from_invitation);

  const { data: options } = useQuery(FOCUS_OPTIONS, () => getFocusOptions(), {
    select: (data) => data.data.data,
    staleTime: Infinity,
  });

  const addV3PathwayMutation = useMutation(postOrPutV3Pathway, {
    onSuccess: () => {
      queryClient.invalidateQueries([PATHWAYS_V3]);
      toast.success(`Development cycle ${edit ? 'updated' : 'added'} successfully`);
      toggle(false);
    },
    onError: (error: AxiosError<{ message: string }>) => {
      toast.error(error.response?.data.message ?? 'An error has occurred.');
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = useForm({
    defaultValues: pathway,
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string()
          .max(64)
          .nullable()
          .test('is-title-required', '', (value) => {
            if (fromInvitation) {
              return true;
            }

            return value != null && value.trim() !== '';
          }),
        focus: Yup.object({ label: Yup.string(), value: Yup.string(), type: Yup.string() }).nullable(),
      })
    ),
  });

  return (
    <Modal>
      <form
        onSubmit={handleSubmit((data) => {
          addV3PathwayMutation.mutate({
            ...data,
            title: data.title,
            focus_type: data.focus?.type,
            focus_element: data.focus?.value,
          });
        })}
      >
        <Modal.Body>
          {!fromInvitation && (
            <div className={'field-mb'}>
              <Input
                {...register('title')}
                required
                label={'Title'}
                id={`title`}
                placeholder={'Title'}
                error={errors.title?.message as string}
              />
            </div>
          )}
          <div className={'field-mb'}>
            <Controller
              control={control}
              render={({ field }) => (
                <Selector
                  {...field}
                  id="focus"
                  label={'Element/Dimension focus'}
                  placeholder={'Select an Element/Dimension focus'}
                  options={options}
                  errorMessage={errors.focus?.message as string}
                />
              )}
              name={'focus'}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton
            label={addV3PathwayMutation.isLoading ? 'Loading...' : 'Save'}
            type={'submit'}
            disabled={addV3PathwayMutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEditV3PathwayModal;
