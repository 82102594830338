import React from 'react';
import { Section } from '../../../common';
import GttReviewImageMobile from '@app/asset/images/great_teaching_inset_page/GTT_Review_Image_Mobile.png';
import GttReviewImage from '@app/asset/images/great_teaching_inset_page/GTT_Review_Image.png';

const InsetPackPage = ({ resource }: { resource?: any }) => {
  return (
    <>
      <div className={'flex-1 block-with-sub-container'}>
        <div className={'relative text-center mb-4'}>
          <img className={'block md:hidden my-4'} src={GttReviewImageMobile} />
          <img className={'hidden md:block my-4'} src={GttReviewImage} />
          <div className={'hidden md:block absolute bottom-2 left-4 lg:bottom-5 lg:left-7'}>
            <p className={'text-md lg:text-2xl xl:text-3xl 2xl:text-4xl'}>
              <strong>{resource?.title}</strong>
            </p>
          </div>
        </div>
        {(resource?.description || resource?.instructions.length > 0) && (
          <Section size={'md'} className={'my-5'}>
            <div className={'flex justify-around items-start flex-col xl:flex-row'}>
              <div className={'grid justify-items-center gap-3 xl:w-1/2 w-full my-2 ml-2 p-2'}>
                <div className={'ml-2 mr-4 pr-2'}>
                  <p>{resource?.description}</p>
                </div>
              </div>
            </div>
            <div
              className={
                'w-full border-t-4 border-l-0 xl:w-1/2 border-gray-100 xl:mt-0 xl:border-t-0 xl:border-l-4 m-2 p-2'
              }
            >
              <div className={'mt-2 ml-8'}>
                <p className={''}>
                  <ul className={'list-decimal'}>
                    {resource?.instructions.map((step: string, index: number) => (
                      <li key={index}>{step}</li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>
          </Section>
        )}
        <h4>Resources</h4>
        <div className={'mt-4 grid grid-cols-1 xl:grid-cols-2 gap-16 mb-8'}>
          {/* {inset_page_resources.map((resource: GTInsetResourcesInterface) => (
            <div key={resource.id} className={'bg-white container-shadow section-container h-full'}>
              <div className={'flex mb-8'}>
                <div className={'w-1/4 my-auto'}>
                  <h5>{resource.title}</h5>
                </div>
                <div className={'ml-auto'}>
                  <Tooltip content={'Download all resources'}>
                    <LinkStyled
                      href={resource.file}
                      download={resource.fileName}
                      target={'_blank'}
                      id={`download_all_${resource.id}`}
                    >
                      <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                        <Icon elementSize={40} icon={GLOBAL_ICONS['download']} />
                      </div>
                    </LinkStyled>
                  </Tooltip>
                </div>
              </div>
              <div>
                {resource.resources.map((r: GreatTeachingInsetInterface) => (
                  <GreatTeachingInsetPageCard key={r.id} resource={r} />
                ))}
              </div>
            </div>
          ))}*/}
          CHILDREN RESOURCES (primary & secondary)
        </div>
        <Section>
          <div className={'grid grid-cols-1 xl:grid-cols-2 gap-2 xl:gap-8 xl:m-4'}>
            {/* {additional_resources.map((resource: GreatTeachingInsetInterface) => (
              <GreatTeachingInsetPageCard key={resource.id} resource={resource} />
            ))}*/}
            CHILDREN RESOURCES (the rest)
          </div>
        </Section>
      </div>
    </>
  );
};
export default InsetPackPage;
