import React from 'react';
import {ModalToggleProps} from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';
import {MatInfoType, useRegistrationContext} from "../../../../state/registration/provider";

const SchoolsConfirmationModal = ({toggle}: ModalToggleProps & SchoolsConfirmationModalType) => {
  const { step3, step4, submitForm } = useRegistrationContext();
  const totalSlots = (step4 as MatInfoType).schools.reduce((acc: number, school: any) => acc + (school.slots || 0), 0);

  return (
    <Modal>
      <Modal.Body>
        <p>You have only allocated {totalSlots} licenses from the {(step3?.teachers ?? 0)} available</p>
        <p><b>Remaining licenses: {(step3?.teachers ?? 0)- totalSlots}</b></p>
        <p>Are you sure you want to continue?</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          label={'Go back'}
          onClick={() => {
            toggle(false);
          }}
        />
        <Modal.ConfirmButton
          label={'Continue'}
          onClick={() => submitForm(() => {
            toggle(false)
          })}
        />
      </Modal.Footer>
    </Modal>
  );
};

type SchoolsConfirmationModalType = {
  handleSubmit: () => Promise<any>;
};

export default SchoolsConfirmationModal;
