import styled, {ThemeProps} from 'styled-components';
import {DEFAULT_THEME} from "../../Theme";
import {IconProps} from "./icon.types";

export const IconStyled = styled.div.attrs({})<IconProps & ThemeProps<any>>`
	width: ${(props) => props.elementSize}px;
	height: ${(props) => props.elementSize}px;
	padding: ${(props) => (props.container ? (props.elementSize || 24) / 4 : 0)}px;
	display: flex;
	flex-shrink: 0;
	svg {
		margin: auto;
		width: 100%;
		height: 100%;
		${(props) =>
  props.color &&
  `fill: ${props.theme.colors[props.color] || props.color}`};
		transition: all ease-in-out 450ms;
	}
`;

IconStyled.defaultProps = {
  elementSize: 24,
  theme: DEFAULT_THEME,
};
