import React from 'react';
import { DevelopmentActivityProps } from '../userManagementPage';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import DefaultTable from '../../../global/table/defaultTable';
import { ColumnType } from '../../../global/table/table.types';
import { capitalize } from 'lodash';

const COLUMNS: Array<ColumnType> = [
  { id: 'term', label: 'Term' },
  { id: 'development_cycle', label: 'Development cycle' },
  { id: 'step', label: 'Step' },
  { id: 'element', label: 'Element' },
  { id: 'activity_type', label: 'Activity type' },
  { id: 'activity', label: 'Activity' },
  { id: 'started', label: 'Started' },
  { id: 'completed', label: 'Completed' },
];

const DevelopmentActivitiesModal = ({
  development_activities,
  toggle,
}: ModalToggleProps & { development_activities?: Array<DevelopmentActivityProps> }) => {
  return (
    <Modal>
      <Modal.Body>
        <DefaultTable
          showCounter={false}
          id={'development_activities'}
          columns={COLUMNS}
          rows={(development_activities ?? []).map((activity) => {
            return {
              id: `activity-row-${activity.id}`,
              cells: [
                {
                  id: 'term',
                  content: <div className={'pl-4'}>{activity.term}</div>,
                },
                {
                  id: 'pathway',
                  content: <div className={'pl-4'}>{1}</div>,
                },
                {
                  id: 'pathway_step',
                  content: (
                    <div className={'pl-4'}>{activity.pathway_step ? capitalize(activity.pathway_step) : null}</div>
                  ),
                },
                {
                  id: 'element',
                  content: <div className={'pl-4'}>{activity.element}</div>,
                },
                {
                  id: 'activity_type',
                  content: <div className={'pl-4'}>{activity.activity_type}</div>,
                },
                {
                  id: 'activity',
                  content: <div className={'pl-4'}>{activity.activity}</div>,
                },
                {
                  id: 'started',
                  content: <div className={'pl-4'}>{activity.start_date}</div>,
                },
                {
                  id: 'completed',
                  content: <div className={'pl-4'}>{activity.completed_at}</div>,
                },
              ],
            };
          })}
          noDataMessage={`This user hasn't completed any activity yet.`}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} label={'Close'} />
      </Modal.Footer>
    </Modal>
  );
};

export default DevelopmentActivitiesModal;
