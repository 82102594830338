import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { toast } from 'react-toastify';
import Input from '../../../form/input/input';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  CALENDAR_MEETINGS_AND_EVENTS,
  EVENTS,
  MAT_SCHOOLS,
  MEETINGS_AND_EVENTS,
  SCHOOLS_GROUPS,
} from '../../../service/queryKeys';
import { Event } from '../../../@types/Entity/Event';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Datepicker from '../../../form/datepicker/datepicker';
import { addOrUpdateEvent } from '../../../service/api/eventsApi';
import dayjs from 'dayjs';
import Selector from '../../../form/select/selector';
import { Me } from '../../../@types/Entity/Me';
import { ASSOCIATED_COLLEGES, ASSOCIATED_COLLEGES_LABELS } from '../../../resources/associateColleges';
import { ROLE_ASSISTANT_GT_COORDINATOR, ROLE_MAT_COORDINATOR, ROLE_SCHOOL_COORDINATOR } from '../../../resources/roles';
import { getMatSchools, getSchoolGroups } from '../../../service/api';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import WysiwigInput from '../../../form/wysiwyg/WysiwigInput';
import { Option } from '../../../@types/global';
import { SelectOptionType } from '../../../service/types';

const ALL_OPTION: { value: 'all'; label: string } = { label: 'ALL', value: 'all' };

const EventsModal = ({
  toggle,
  event,
  me,
}: ModalToggleProps & { me: Me; event?: Omit<Event, 'start_time' | 'end_time' | 'date'> } & {
  start_time: Date;
  end_time: Date;
  date: Date;
}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(addOrUpdateEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries([EVENTS]);
      queryClient.invalidateQueries([MEETINGS_AND_EVENTS]);
      queryClient.invalidateQueries([CALENDAR_MEETINGS_AND_EVENTS]);
      toggle(false);
    },
  });

  const {
    getValues,
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string().trim().required('This is a required field').max(100).nullable(),
        description: Yup.mixed().test('is-description-required', 'This is a required field', (value: EditorState) => {
          return value.getCurrentContent().hasText() && value.getCurrentContent().getPlainText().length > 0;
        }),
        location: Yup.string().trim().required('This is a required field').max(100).nullable(),
        date: Yup.date().required('This is a required field').nullable(),
        start_time: Yup.date().required('This is a required field').nullable(),
        end_time: Yup.date().required('This is a required field').nullable(),
        schools: Yup.array().test('is-schools-required', 'This is a required field', (value) => {
          if (me.role !== ROLE_MAT_COORDINATOR) {
            return true;
          }

          return null != value && value.length !== 0;
        }),
        groups: Yup.array().test('is-groups-required', 'This is a required field', (value) => {
          if (me.role !== ROLE_SCHOOL_COORDINATOR && me.role !== ROLE_ASSISTANT_GT_COORDINATOR) {
            return true;
          }

          return null != value && value.length !== 0;
        }),
        associate_colleges: Yup.array().test('is-associate-colleges-required', 'This is a required field', (value) => {
          return null != value && value.length !== 0;
          return null != value && value.length !== 0;
        }),
      })
    ),
    defaultValues: {
      title: null,
      location: null,
      date: null as Date | null,
      start_time: null as Date | null,
      end_time: null as Date | null,
      ...event,
      description: event?.description
        ? EditorState.createWithContent(convertFromRaw(JSON.parse(event.description)))
        : EditorState.createEmpty(),
      schools:
        event?.schools === ALL_OPTION.value
          ? ([ALL_OPTION] as Option[])
          : (event?.schools ?? []).map((school) => ({
              label: school.name,
              value: school.id,
            })),
      groups:
        event?.groups === ALL_OPTION.value
          ? ([ALL_OPTION] as Option[])
          : (event?.groups ?? []).map((group) => ({
              label: group.name,
              value: group.id,
            })),
      associate_colleges:
        event?.associate_colleges === ALL_OPTION.value
          ? ([ALL_OPTION] as Option[])
          : (event?.associate_colleges ?? []).map((associateCollege) => ({
              label: associateCollege.name,
              value: associateCollege.id,
            })),
    },
  });

  const schoolsQuery = useQuery([MAT_SCHOOLS], getMatSchools, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    enabled: me.role === ROLE_MAT_COORDINATOR,
  });

  const schoolGroupsQuery = useQuery([SCHOOLS_GROUPS], () => getSchoolGroups(), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    enabled: me.role === ROLE_SCHOOL_COORDINATOR || me.role === ROLE_ASSISTANT_GT_COORDINATOR,
  });

  let attendanceOptions: SelectOptionType[] = [ALL_OPTION];
  let attendanceOption: 'schools' | 'groups' | 'associate_colleges' | null = null;

  if (me.role === ROLE_MAT_COORDINATOR) {
    attendanceOption = 'schools';
    attendanceOptions = [
      ...attendanceOptions,
      ...(schoolsQuery.data
        ? schoolsQuery.data.map((school) => ({
            label: school.name,
            value: school.id,
          }))
        : []),
    ];
  } else if (me.role === ROLE_SCHOOL_COORDINATOR || me.role === ROLE_ASSISTANT_GT_COORDINATOR) {
    attendanceOption = 'groups';
    attendanceOptions = [
      ...attendanceOptions,
      ...(schoolGroupsQuery.data
        ? schoolGroupsQuery.data.map((group) => ({
            label: group.name,
            value: group.id,
          }))
        : []),
    ];
  }

  return (
    <Modal>
      <form
        noValidate
        onSubmit={handleSubmit((values) => {
          const schools =
            values.schools.length === 1 && values.schools[0].value === ALL_OPTION.value
              ? ALL_OPTION.value
              : values.schools.map((school) => school.value);
          const groups =
            values.groups.length === 1 && values.groups[0].value === ALL_OPTION.value
              ? ALL_OPTION.value
              : values.groups.map((group) => group.value);
          const associateColleges =
            values.associate_colleges.length === 1 && values.associate_colleges[0].value === ALL_OPTION.value
              ? ALL_OPTION.value
              : values.associate_colleges.map((associateCollege) => associateCollege.value as number);

          mutation
            .mutateAsync({
              id: values.id,
              title: values.title!,
              description: JSON.stringify(convertToRaw(values.description.getCurrentContent())),
              location: values.location!,
              date: dayjs(values.date!).format('YYYY/MM/DD'),
              start_time: dayjs(values.start_time!).format('HH:mm'),
              end_time: dayjs(values.end_time!).format('HH:mm'),
              schools: schools,
              groups: groups,
              associate_colleges: associateColleges,
            })
            .then(() => {
              if (event) {
                toast.success('Event updated successfully.');
                return;
              }

              toast.success('Event added successfully.');
            });
        })}
      >
        <Modal.Body>
          <div className={'field-mb'}>
            <Controller
              name={'date'}
              control={control}
              render={({ field: { onChange, value } }) => (
                <Datepicker
                  required
                  id={'date'}
                  label={'Date'}
                  placeholder={'Date'}
                  onChange={(date: Date) => onChange(date)}
                  selected={value}
                  error={errors.date?.message}
                />
              )}
            />
          </div>
          <div className={'flex gap-1 field-mb'}>
            <time>
              <Controller
                name={'start_time'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Datepicker
                    required
                    id={'start_time'}
                    label={'Start time'}
                    placeholder={'Start time'}
                    onChange={(date: Date) => onChange(date)}
                    selected={value}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="HH:mm"
                    error={errors.start_time?.message}
                  />
                )}
              />
            </time>
            <time>
              <Controller
                name={'end_time'}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Datepicker
                    required
                    id={'end_time'}
                    label={'End time'}
                    placeholder={'End time'}
                    onChange={(date: Date) => onChange(date)}
                    selected={value}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    dateFormat="HH:mm"
                    error={errors.end_time?.message}
                  />
                )}
              />
            </time>
          </div>
          <div className={'field-mb'}>
            <Input
              {...register('location')}
              required
              id="location"
              label="Location"
              placeholder="Please enter an event location"
              error={errors.location?.message}
            />
          </div>
          <div className={'field-mb'}>
            <Input
              {...register('title')}
              required
              id="title"
              label="Title"
              placeholder="Please enter an event title"
              error={errors.title?.message}
            />
          </div>
          <div className={'field-mb'}>
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <WysiwigInput
                  required
                  id={'description'}
                  label={'Description'}
                  value={value}
                  onChange={(editorState) => {
                    onChange(editorState);
                  }}
                  placeholder="Please enter an event short description"
                  error={errors.description?.message}
                />
              )}
              name={'description'}
            />
          </div>
          {attendanceOption && (
            <div className={'field-mb'}>
              <Controller
                name={attendanceOption}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Selector
                    id={'attend'}
                    label={'Who should attend?'}
                    placeholder={'Please select who should attend'}
                    options={attendanceOptions}
                    value={value}
                    onChange={(options: Option[]) => {
                      const foundAll = options.some((option) => option.value === ALL_OPTION.value);

                      if (foundAll) {
                        if (options[options.length - 1].value === ALL_OPTION.value) {
                          onChange([ALL_OPTION]);
                          return;
                        }

                        onChange(options.filter((option) => option.value !== ALL_OPTION.value));
                        return;
                      }

                      onChange(options);
                    }}
                    isMulti
                    required
                  />
                )}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} />
          <Modal.ConfirmButton
            label={mutation.isLoading ? 'Loading...' : event ? 'Edit event' : 'Add event'}
            type="submit"
            disabled={mutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EventsModal;
