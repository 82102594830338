import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import { DEFAULT_THEME } from '../../Theme';
import {AllColorsType, SizeType} from '../../resources/types';
import { readableColor } from 'polished';

type TagStyledProps = {
  $size: SizeType;
  $color: AllColorsType;
  $isPill: boolean;
};

const SIZES: Record<SizeType, number> = {
  'sm': 20,
  'md': 30,
  'lg': 40
}

export const TagStyled = styled('div')<TagStyledProps & ThemeProps<DefaultTheme>>`
  display: inline-flex;
  align-items: center;
  text-align: center;
  gap: 10px;
  font-size: 0.85rem;
  height: ${(props) => SIZES[props.$size]}px;
  ${(props) => props.$isPill && `width: ${SIZES[props.$size]}px;`}
  ${(props) => !props.$isPill && 'padding: 0 15px;'}
  border-radius: ${(props) => props.$isPill ? '999px' : props.theme.borderRadius.app};

  background: ${(props) => props.theme.colors[props.$color]};
  color: ${(props) => readableColor(props.theme.colors[props.$color])};

  span {
    margin: auto;
  }
`;

TagStyled.defaultProps = {
  theme: DEFAULT_THEME,
};
