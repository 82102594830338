import React, { useEffect } from 'react';
import DefaultTable from '../../../global/table/defaultTable';
import { useQuery } from 'react-query';
import { ALL_PATHWAYS } from '../../../service/queryKeys';
import { getAllV2Pathways } from '../../../service/api';
import routeBuilder from '../../../service/routeBuilder';
import { ActionButton } from '../../../global/buttonIcon/common';
import { useNavigate } from 'react-router-dom';
import { ROLE_TEACHER_FREE, RolesType } from '../../../resources/roles';

type PathwayType = {
  id: string | number;
  term?: string;
  started_at: string;
  completed_at: string;
  current_step: string;
  focus_area: string;
  team?: { id: string | number; name: string };
  steps: Array<any>;
  development_period: string | null;
  number: string | null;
  title: string | null;
  type: string;
  is_legacy?: boolean;
  version?: number;
};

const COLUMNS = [
  { id: 'term', label: 'Term' },
  { id: 'pathway', label: 'Development cycle' },
  { id: 'type', label: 'Type' },
  { id: 'element', label: 'Element' },
  { id: 'team', label: 'Team' },
  { id: 'started', label: 'Started' },
  { id: 'completed', label: 'Completed' },
  { id: 'status', label: 'Status' },
  { id: 'actions', label: 'Actions' },
];

const FREE_TEACHER_COLUMNS = [
  { id: 'term', label: 'Term' },
  { id: 'pathway', label: 'Development cycle' },
  { id: 'type', label: 'Type' },
  { id: 'element', label: 'Element' },
  { id: 'team', label: 'Team' },
  { id: 'started', label: 'Started' },
  { id: 'completed', label: 'Completed' },
  { id: 'status', label: 'Status' },
];

const DevelopmentPathwaysTable = ({
  academicYear,
  userRole,
  setHidePathwaysTable,
  hidden,
}: {
  academicYear: string | undefined;
  userRole: RolesType;
  setHidePathwaysTable?: any;
  hidden?: boolean | string | undefined;
}) => {
  const navigate = useNavigate();

  const { data: allPathways, isFetched } = useQuery(
    [ALL_PATHWAYS, academicYear],
    () => getAllV2Pathways(academicYear),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const isFree = userRole === ROLE_TEACHER_FREE;

  useEffect(() => {
    if (hidden != true) {
      if (isFetched && allPathways.length > 0 && isFree) setHidePathwaysTable(true);
    }
  }, [allPathways, isFetched]);

  return (
    <DefaultTable
      id={'development-pathways-table'}
      columns={userRole === ROLE_TEACHER_FREE ? FREE_TEACHER_COLUMNS : COLUMNS}
      showCounter={false}
      rows={allPathways.map((pathway: PathwayType) => {
        if (userRole === ROLE_TEACHER_FREE) {
          return {
            id: `${pathway.id}`,
            cells: [
              { id: 'term', content: pathway?.term },
              { id: 'pathway', content: pathway.title ?? pathway.number },
              { id: 'type', content: pathway.type },
              { id: 'element', content: pathway.focus_area },
              { id: 'team', content: pathway.team?.name },
              { id: 'started', content: pathway.started_at },
              { id: 'completed', content: pathway.completed_at },
              { id: 'status', content: pathway.completed_at ? 'Completed' : 'In progress' },
            ],
          };
        } else
          return {
            id: `${pathway.id}`,
            cells: [
              { id: 'term', content: pathway?.term },
              { id: 'pathway', content: pathway.title ?? pathway.number },
              { id: 'pathway', content: pathway.type },
              { id: 'element', content: pathway.focus_area },
              { id: 'team', content: pathway.team?.name },
              { id: 'started', content: pathway.started_at },
              { id: 'completed', content: pathway.completed_at },
              { id: 'status', content: pathway.completed_at ? 'Completed' : 'In progress' },
              {
                id: 'actions',
                content: (
                  <ActionButton.View
                    label={'View development cycle'}
                    onClick={() => {
                      navigate(routeBuilder('pathways').generate(pathway.id, pathway.is_legacy ? 1 : pathway.version));
                    }}
                  />
                ),
              },
            ],
          };
      })}
    />
  );
};

export default DevelopmentPathwaysTable;
