import React from 'react';
import {Navigate} from 'react-router-dom';
import routeBuilder from './routeBuilder';

type ConditionalRouteProps = { condition: boolean, children: JSX.Element };

const ConditionalRoute = ({ condition, children }: ConditionalRouteProps) => {
  if (!condition) {
    return <Navigate replace to={routeBuilder('home').route}/>;
  }

  return children;
};

export default ConditionalRoute;
