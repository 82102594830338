import React from 'react';
import AddButton from './addButton';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { LinkStyled } from '../../../global/link/link.styled';
import useModal from '../../../hooks/useModal';
import AddResourceModal from '../modals/addResourceModal';
import { downloadResource } from '../../../service/api';
import { useQueryClient } from 'react-query';
import DefaultTable from '../../../global/table/defaultTable';

const AddResources = ({
  disabled = false,
  step,
  team,
  resources,
  description,
  stepPrepare,
}: {
  disabled?: boolean;
  step: string;
  team?: { id: string; name: string };
  description?: string;
  resources?: Array<ResourceType>;
  stepPrepare?: boolean; // Added by piotrwydra for the Prepare step in Pathway page requested by the advisor.
}) => {
  const queryClient = useQueryClient();

  const [modal, toggleModal, setModalProps] = useModal((props: any) => <AddResourceModal step={step} {...props} />, {
    title: stepPrepare ? 'Add Development Plan' : 'Add resource',
  });

  return (
    <div>
      {modal}
      {stepPrepare ? (
        <div className={`flex`}>
          <span className={'font-bold pr-5 py-1'}>Development plan</span>
          <AddButton
            disabled={disabled}
            label={'Development plan'}
            hideLabel
            onClick={() => {
              setModalProps({
                stepPrepare: stepPrepare,
                team,
              });
              toggleModal(true);
            }}
          />
        </div>
      ) : (
        <AddButton
          disabled={disabled}
          label={'Resource'}
          onClick={() => {
            setModalProps({
              stepPrepare: stepPrepare,
              team,
            });
            toggleModal(true);
          }}
        />
      )}
      <i className="text-sm">{description}</i>
      {(resources ?? []).length > 0 && (
        <div className={'pathways-section-content'}>
          <DefaultTable
            id={`resources_table_${step}`}
            showCounter={false}
            apiHandled={true}
            columns={[
              { id: 'name', label: 'Name' },
              { id: 'actions', label: 'Actions' },
            ]}
            rows={(resources ?? []).map((resource) => {
              return {
                id: `row_resource--${resource.id}`,
                cells: [
                  {
                    id: 'name',
                    content: (
                      <LinkStyled
                        href={resource.url ? resource.url : '#'}
                        target={'_blank'}
                        onClick={(e: any) => {
                          if (!resource.url) {
                            e.preventDefault();
                            downloadResource(resource.id, resource.name);
                          }
                        }}
                      >
                        {resource.name}
                      </LinkStyled>
                    ),
                  },
                  {
                    id: 'actions',
                    class: 'actions',
                    content: (
                      <ul className={'actions-list gap-2'}>
                        {team && !resource.shared && (
                          <>
                            {!resource.shared_with_groups ? (
                              <ButtonIcon disabled={disabled} icon={'Share'} label={'Share with team'} />
                            ) : (
                              <ButtonIcon
                                mainColor={'muted'}
                                disabled={disabled}
                                icon={'Share'}
                                label={'Unshare with team'}
                              />
                            )}
                          </>
                        )}
                        <ButtonIcon mainColor={'danger'} disabled={disabled} icon={'Trash'} label={'Delete'} />
                      </ul>
                    ),
                  },
                ],
              };
            })}
          />
        </div>
      )}
    </div>
  );
};

export type ResourceType = {
  id: string;
  type: string;
  name: string;
  url?: string;
  shared: boolean;
  shared_with_groups: boolean;
};

export default AddResources;
