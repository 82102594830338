import React, { useState } from 'react';
import AddButton from '../components/addButton';
import { CycleProps, StrategyProps } from '../pathwaysPage.types';
import useModal from '../../../hooks/useModal';
import Accordion from '../../../global/accordion/accordion';
import Tag from '../../../global/tag/tag';
import { MoreInfo } from '../../../global/tooltip/common';
import Button from '../../../global/button/button';
import { SectionInAccordion } from '../components';
import DefaultTable from '../../../global/table/defaultTable';
import { capitalize } from '../../../helpers/misc';
import { CompleteChoice } from '../../../form/choice/common';
import { DevelopmentCyclesModal } from '../modals';
import classNames from 'classnames';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';

dayjs.extend(customParseFormat);

const PathwaysActionPlanning = ({
  disabled,
  plans,
  strategyOptions,
  description,
}: {
  disabled: boolean;
  plans?: Array<CycleProps>;
  strategyOptions?: Array<Pick<StrategyProps, 'id' | 'name'>>;
  description?: string;
}) => {
  const [accordionOpen, setAccordionOpen] = useState<string | null>();

  const [cyclesModal, toggleCyclesModal, setCyclesProps] = useModal(
    (props: any) => <DevelopmentCyclesModal {...props} strategiesOptions={strategyOptions} />,
    {
      size: 'lg',
    }
  );

  return (
    <>
      <>
        <div className={`flex ${classNames({ 'mb-1': null != plans && plans.length > 0 })}`}>
          <span className={'font-bold pr-5 py-1'}>Action planning</span>
          <AddButton
            disabled={disabled}
            onClick={() => {
              setCyclesProps({
                title: 'Add new action plan',
              });
              toggleCyclesModal(true);
            }}
          />
        </div>
        <i className="text-sm">{description}</i>
      </>
      {cyclesModal}
      {(plans ?? []).map((cycle) => (
        <div key={`cycle_${cycle.id}`} className={'pathways-section-content'}>
          <Accordion
            isOpen={accordionOpen === cycle.id}
            handleOpen={() => {
              setAccordionOpen(accordionOpen === cycle.id ? null : (cycle.id as string));
            }}
            title={
              <div className={'flex items-center justify-between w-full'}>
                <div className={'flex items-center'}>
                  <span>
                    {cycle.start_date}—{cycle.end_date}
                  </span>
                  <div className={'flex items-center gap-2 ml-10'}>
                    {cycle.practice && (
                      <Tag style={{ height: 24 }} mainColor={'muted'} content={'Deliberate practice'} />
                    )}
                    {cycle.notes && <MoreInfo content={cycle.notes} />}
                  </div>
                  <span className={'text-muted italic ml-10'}>
                    {cycle.school_classes.map((schoolClass) => schoolClass.name).join('/')}
                  </span>
                </div>
                <div>
                  <Button
                    disabled={disabled}
                    asLink
                    onClick={(event) => {
                      event.stopPropagation();

                      setCyclesProps({
                        title: 'Edit action plan',
                        cycle: {
                          ...cycle,
                          start_date: dayjs(cycle.start_date, 'DD/MM/YYYY').toDate(),
                          end_date: dayjs(cycle.end_date, 'DD/MM/YYYY').toDate(),
                          strategies: cycle.strategies.map((strategy) => strategy.id),
                          school_classes: cycle.school_classes.map((schoolClass) => schoolClass.id),
                          tasks: cycle.tasks.map((task) => ({
                            ...task,
                            date: task.date ? dayjs(task.date, 'DD/MM/YYYY').toDate() : null,
                          })),
                        },
                      });
                      toggleCyclesModal(true);
                    }}
                    style={{
                      padding: 0,
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            }
          >
            <SectionInAccordion className={'field-mb md:mb-0'} title={'Strategy'}>
              <ul className={'list-decimal list-inside'}>
                {cycle.strategies.map((strategy) => (
                  <li key={`strategy_list--${strategy.id}`} className={'my-2'}>
                    {strategy.name}
                  </li>
                ))}
              </ul>
            </SectionInAccordion>
            <div className={'col-span-2'}>
              <DefaultTable
                id={`cycle_${cycle.id}--tasks`}
                additionalContainerClasses={'max-h-96 border'}
                additionalTableClasses={'table--sticky'}
                itemCounter={{ single: 'task', plural: 'tasks' }}
                columns={[
                  { id: 'date', label: 'Date' },
                  { id: 'task', label: 'Task' },
                  { id: 'type', label: 'Type' },
                  { id: 'status', label: 'Complete' },
                ]}
                rows={
                  !cycle.tasks
                    ? []
                    : cycle.tasks.map((task: any) => {
                        return {
                          id: `task_row--${task.id}`,
                          cells: [
                            { id: 'date', content: task.date },
                            { id: 'task', content: task.name },
                            { id: 'type', content: task.type ? capitalize(task.type as string) : null },
                            {
                              id: 'status',
                              class: 'status',
                              content: (
                                <CompleteChoice id={`task_${task.id}`} disabled={disabled} checked={task.completed} />
                              ),
                            },
                          ],
                        };
                      })
                }
              />
            </div>
          </Accordion>
        </div>
      ))}
    </>
  );
};

export default PathwaysActionPlanning;
