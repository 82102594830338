import connector from '../connector';

export function postOrPutStrategy(data: any) {
  if (data.id) {
    return connector.put(`/api/development-pathway/strategies/${data.id}`, data);
  }

  return connector.post('/api/development-pathway/strategies', data);
}

export function getStrategies(include?: string, goal?: string, show?: string, teamId?: string) {
  return connector.get('/api/development-pathway/strategies', { params: { include, goal, show, teamId } });
}

export function archiveStrategy({ id, enable }: { id: string; enable: boolean }) {
  return connector.put(`/api/development-pathway/strategies/${id}/${enable ? 'archive' : 'unarchive'}`);
}

export function postOrPutDevelopmentGoal(data: any) {
  if (data.id) {
    return connector.put(`/api/development-pathway/goals/${data.id}`, data);
  }

  return connector.post('/api/development-pathway/goals', data);
}

export function getDevelopmentGoals(include?: string, show?: string, developmentPeriodId?: string) {
  return connector.get('/api/development-pathway/goals', { params: { include, show, developmentPeriodId } });
}

export function archiveDevelopmentGoal({ id, enable }: { id: string; enable: boolean }) {
  return connector.put(`/api/development-pathway/goals/${id}/${enable ? 'archive' : 'unarchive'}`);
}

export function shareDevelopmentGoals(goalId: string) {
  return connector.post(`/api/development-pathway/goals/${goalId}/share`);
}
