import React, { useEffect, useState } from 'react';
import { postConfirmRegistration, postResendConfirmRegistrationMail } from '../../../service/api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import routeBuilder from '../../../service/routeBuilder';
import Button from '../../../global/button/button';
import AuthLayout from '../../../layout/auth';
import { useMutation } from 'react-query';
const expires = new URLSearchParams(location.search).get('expires') as string;
const id = new URLSearchParams(location.search).get('id') as string;
const signature = new URLSearchParams(location.search).get('signature') as string;
const token = new URLSearchParams(location.search).get('token') as string;

const ConfirmationPage = () => {
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const resendMailMutation = useMutation(postResendConfirmRegistrationMail, {
    onSuccess: () => {
      navigate(routeBuilder('login').route);
    },
  });

  useEffect(() => {
    postConfirmRegistration({ expires, id, signature, token })
      .then(() => {
        toast.success('Your account has been confirmed.');
        navigate(routeBuilder('login').route);
      })
      .finally(() => navigate(routeBuilder('login').route));
  }, []);

  if (!error) {
    return <></>;
  }

  return (
    <AuthLayout title={'Account activation'}>
      <p>
        It looks like your account activation link has expired, please click below to send yourself a new account
        activation link
      </p>
      <Button
        className={'block w-full mt-5'}
        onClick={() => resendMailMutation.mutate({ expires, id, signature, token })}
      >
        Send new activation email
      </Button>
    </AuthLayout>
  );
};

export default ConfirmationPage;
