import React, { useEffect, useState } from 'react';
import { Section } from '../../common';
import ResourceCard from './resourceCard';
import { useQuery } from 'react-query';
import { ALGOLIA_RESOURCES, DRAFT_RESOURCES } from '../../../service/queryKeys';
import { getDraftResources, getResourcesFromAlgolia, getSelfStudyResources } from '../../../service/api';
import ReactPaginate from 'react-paginate';
import Search from '../../../form/search/search';
import Selector from '../../../form/select/selector';
import { Checkbox } from 'pretty-checkbox-react';
import { dimensionList, elementList, listNumberOfResources } from '../resources/types';
import { LinkStyled } from '../../../global/link/link.styled';
import Icon from '../../../global/icon/icon';
import Tooltip from '../../../global/tooltip/tooltip';
import { useMediaQuery } from 'react-responsive';
import VideoAlert from '../../../guides/components/videoAlert';
import { emulatedElementType } from "@app/frontend/src/routes";

const Algolia = ({
  admin = false,
  editor = false,
  isFree,
  emulatedElement
}: {
  admin?: boolean;
  editor?: boolean;
  isFree?: boolean;
  emulatedElement?: emulatedElementType
}) => {
  const preSetElementFilters = isFree && emulatedElement ? `${emulatedElement.value} ${emulatedElement.label}` : localStorage.getItem('resourcePageFilters');
  localStorage.removeItem('resourcePageFilters');

  const [keywords, setKeywords] = useState<string>('');
  const [page, setPage] = useState<number>(1);
  const [hitsPerPage, setHitsPerPage] = useState<any>(isFree ? 20 : 8);
  const [filters, setFilters] = useState<Array<string>>([]);

  const [guides, setGuides] = useState<boolean>(false);
  const [mfgt, setMfgt] = useState<boolean>(false);
  const [filterMenu, setFilterMenu] = useState<boolean>(true);

  const [filterKeywords, setFilterKeywords] = useState<any>();

  const [dimensionFilters, setDimensionFilters] = useState<Array<string>>(
    preSetElementFilters ? [preSetElementFilters.charAt(0)] : []
  );
  const [elementFilters, setElementFilters] = useState<Array<string>>(
    preSetElementFilters ? [preSetElementFilters] : []
  );

  const { data: selfStudyResources } = useQuery(DRAFT_RESOURCES, getSelfStudyResources, {
    select: (data) => data.data.data,
    placeholderData: [],
    staleTime: Infinity,
  });

  const { data: algoliaResults, refetch } = useQuery(
    [ALGOLIA_RESOURCES, filterKeywords, page, hitsPerPage, filters],
    () => getResourcesFromAlgolia(filterKeywords, page, hitsPerPage, filters),
    {
      select: (data) => data.data,
      placeholderData: [],
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);
  }, [page, hitsPerPage]);

  useEffect(() => {
    refetch();
  }, [filterKeywords, page, hitsPerPage, filters]);

  useEffect(() => {
    if (mfgt) setFilterKeywords(keywords.concat(' mfgt'));
    else {
      setFilterKeywords(keywords);
    }
  }, [mfgt, keywords]);

  useEffect(() => {
    const filtersWithoutGuide = filters.filter((filter: any) => filter != 'type:guide');
    if (guides) setFilters([...filters, 'type:guide']);
    else {
      setFilters(filtersWithoutGuide);
    }
  }, [guides]);

  useEffect(() => {
    const dimensionFilter = dimensionFilters;
    const elementFilter = elementFilters;
    const dimensionsUsedByElements = [...new Set(elementFilter.map((element: any) => element.charAt(0)))];
    const uniqueDimensions = dimensionFilter
      .filter((dimension: any) => !dimensionsUsedByElements.includes(dimension))
      .map((uniqueDimension: any) => `dimension:${uniqueDimension}`);
    const uniqueElements = elementFilter.map((uniqueElement: any) => `element:${uniqueElement}`);
    const uniqueFilter = uniqueDimensions.concat(uniqueElements);
    if (guides) uniqueFilter.push('type:guide');
    if (isFree) setFilters(uniqueElements);
    else setFilters(uniqueFilter);
  }, [dimensionFilters, elementFilters]);

  const mobileScreen = useMediaQuery({ query: `(max-width: 800px)` });

  useEffect(() => {
    if (mobileScreen || isFree) {
      setFilterMenu(false);
    } else setFilterMenu(true);
  }, [mobileScreen]);

  useEffect(() => {
    if (!isFree) {
      const keywordSearch = localStorage.getItem('keywordSearch');
      if (keywordSearch) setKeywords(keywordSearch);
      localStorage.removeItem('keywordSearch');
    }
  }, []);

  const pages: JSX.Element[] = [];
  for (let i = 1; i < 8; i++) {
    pages.push(
      <li className="page-item" key={i}>
        <div className={'border-2 w-9 h-10 mx-auto pt-1'}>
          <Icon elementSize={25} className={'m-auto my-auto'} icon={'Lock'}/>
        </div>
      </li>
    );
  }

  return (
    <>
      {isFree && emulatedElement && (
        <div>
          <VideoAlert
            setVideo={undefined}
            showHideForever={false}
            showHide={false}
            starterTemplate={isFree}
            data={{
              title: '',
              id: 'starter_template_resources',
              description: (
                <>
                  <p className={'mb-4'}>
                    Here is an example of some of the resources available to teachers, aligned to
                    Element {emulatedElement.value} - {emulatedElement.label}.
                  </p>
                  <p>
                    The Great Teaching Toolkit’s resource library puts hundreds of resources at your fingertips. It
                    includes more than 500 blogs, videos, books, podcasts and documents, curated and mapped to the
                    Dimensions and Elements of the Model for Great Teaching.
                  </p>
                </>
              ),
            }}
          />
        </div>
      )}
      <Section>
        <div className={'flex w-full mt-4'}>
          <div>
            <Tooltip content={`${filterMenu ? 'Hide' : 'Show'} filter menu`}>
              <Icon
                icon={filterMenu ? 'FunnelFill' : 'Funnel'}
                elementSize={40}
                className={'mr-4 mt-12 border mb-4 hover:shadow'}
                onClick={() => setFilterMenu(!filterMenu)}
              />
            </Tooltip>
            <div className={`${!filterMenu && 'hidden'} w-2/7 pr-12`}>
              <div className="mb-8">
                <LinkStyled
                  className={isFree ? 'cursor-not-allowed text-gray-400 hover:underline' : ''}
                  onClick={() => {
                    if (!isFree) {
                      setFilters([]);
                      setDimensionFilters([]);
                      setElementFilters([]);
                      setPage(1);
                      setMfgt(false);
                      setGuides(false);
                    }
                  }}
                >
                  Clear filters
                </LinkStyled>
              </div>
              {/*<div className={'flex my-4 cursor-pointer'} onClick={() =>  setGuides(!guides)}>
                <div>
                  <Checkbox
                    id={'guide_filter'}
                    style={{ fontSize: 19 }}
                    color={'primary'}
                    checked={guides}
                  />
                </div>
                <div className={guides ? 'font-bold' : ''}>Guides</div>
              </div>*/}
              <div
                className={`flex my-4 ${isFree ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                onClick={() => !isFree && setMfgt(!mfgt)}
              >
                <div>
                  <Checkbox
                    disabled={isFree}
                    id={'mfgt_filter'}
                    style={{ fontSize: 19 }}
                    color={'primary'}
                    checked={mfgt}
                  />
                </div>
                <div className={mfgt ? 'font-semibold' : ''}>Model for Great Teaching resources</div>
              </div>
              <div className={' my-4'}>
                <span className={'font-semibold mt-4 mb-2 text-xl'}>Dimensions &amp; Elements</span>
                {dimensionList.map((dimension: any, index: number) => {
                  const dimensionNum = dimension.charAt(0);
                  return (
                    <React.Fragment key={index}>
                      <div
                        className={'flex my-4 cursor-pointer'}
                        onClick={() => {
                          /*if (!isFree) {*/
                          dimensionFilters.includes(dimensionNum)
                            ? (setDimensionFilters([
                              ...dimensionFilters.filter((dimensionFilter: any) => {
                                if (dimensionFilter != dimensionNum) return dimensionFilter;
                              }),
                            ]),
                              setElementFilters(
                                elementFilters.filter((elementFilter: any) => elementFilter.charAt(0) != dimensionNum)
                              ))
                            : setDimensionFilters([...dimensionFilters, dimensionNum]);
                          setPage(1);
                          /*}*/
                        }}
                      >
                        <div>
                          <Checkbox
                            disabled={isFree}
                            id={`dimension_filter_${dimension}`}
                            style={{ fontSize: 19 }}
                            color={'primary'}
                            checked={
                              isFree && filters.length > 0
                                ? filters[0].charAt(8) === dimensionNum
                                : dimensionFilters.includes(dimensionNum)
                            }
                          />
                        </div>
                        <div className={`${dimensionFilters?.includes(dimensionNum) && 'font-bold'} flex`}>
                          {dimension}
                        </div>
                      </div>
                      {elementList.map((element: any, index: number) => {
                        const dimensionNumber = element.charAt(0);
                        if (dimensionFilters.includes(dimensionNumber) && dimensionNumber === dimensionNum)
                          return (
                            <div
                              key={index}
                              className={`${
                                elementFilters?.includes(element) && 'font-bold'
                              } ml-8 flex my-2 ml-2 cursor-pointer`}
                              onClick={() => {
                                if (isFree) {
                                  setElementFilters([element]);
                                } else {
                                  elementFilters.includes(element)
                                    ? setElementFilters([
                                      ...elementFilters.filter((elementFilter: any) => {
                                        if (elementFilter != element) return elementFilter;
                                      }),
                                    ])
                                    : setElementFilters([...elementFilters, element]);
                                  setPage(1);
                                }
                              }}
                            >
                              <div>
                                <Checkbox
                                  disabled={isFree}
                                  id={`element_filter_${element}`}
                                  style={{ fontSize: 19 }}
                                  color={'primary'}
                                  checked={elementFilters.includes(element)}
                                />
                              </div>
                              <div>{element}</div>
                            </div>
                          );
                      })}
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={'w-full bg-gray-00'}>
            {!isFree && (
              <div className={' w-3/4'} id={'SearchBox'}>
                <Search
                  className={isFree ? 'cursor-not-allowed' : ''}
                  disabled={isFree}
                  id={'search_resources'}
                  label={'Search resource'}
                  hideLabel={false}
                  placeholder={'Search for resource'}
                  value={keywords}
                  onChange={(event) => {
                    setKeywords(event.target.value);
                  }}
                  onRemove={() => {
                    setKeywords('');
                  }}
                />
              </div>
            )}
            <div className={'w-full mt-20'}>
              {algoliaResults?.data?.result?.hits?.length > 0 ? (
                <>
                  <div className="grid grid-cols-1 2xl:grid-cols-2 2xl:gap-4">
                    <div>
                      {algoliaResults.data.result.hits
                        .slice(0, Math.ceil(algoliaResults?.data?.result?.hits.length / 2))
                        .map((result: any, index: number) => (
                          <ResourceCard admin={admin} editor={editor} resource={result} key={index} />
                        ))}
                    </div>
                    <div>
                       {algoliaResults.data.result.hits
                        .slice(
                          Math.ceil(algoliaResults?.data?.result?.hits.length / 2),
                          algoliaResults?.data?.result?.hits.length
                        )
                        .map((result: any, index: number) => (
                          <ResourceCard admin={admin} editor={editor} key={index} resource={result} />
                        ))}
                    </div>
                  </div>
                </>
              ) : (
                <div className={'mt-4'}>
                  <em>Found no data matching your search.</em>
                </div>
              )}
            </div>
          </div>
        </div>
        <div>
          <div className={'flex mt-12'}>
            <div className={`mx-auto ${isFree ? ' cursor-not-allowed' : ''} `}>
              <div className={`${isFree && 'pointer-events-none  opacity-50'}`}>
                {isFree ? (
                  <div className="mx-auto">
                    <ul className="pagination">
                      <li className="page-item disabled">
                        <a className="page-link " role="button">
                          Previous
                        </a>
                      </li>
                      <li className="page-item active">
                        <a role="button" className="page-link">
                          1
                        </a>
                      </li>
                      {pages}
                      <li className="page-item">
                        <a className="page-link" role="button">
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                ) : (
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    breakLabel="..."
                    containerClassName="pagination"
                    pageClassName="page-item"
                    nextClassName="page-item"
                    breakClassName="page-item"
                    previousClassName="page-item"
                    pageLinkClassName="page-link"
                    previousLinkClassName="page-link"
                    nextLinkClassName="page-link"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                    pageCount={isNaN(algoliaResults?.data?.result?.nbPages) ? 0 : algoliaResults.data.result.nbPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    forcePage={page - 1}
                    onPageChange={({ selected }: { selected: number }) => {
                      setPage(selected + 1);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={'flex'}>
            <div className={'my-4 ml-auto w-1/4'}>
              <div className={`${isFree && 'pointer-events-none cursor-not-allowed opacity-50'}`}>
                <Selector
                  id={'select_show_number_of_resources'}
                  value={listNumberOfResources.find((resource: any) => resource.value === hitsPerPage)}
                  options={listNumberOfResources}
                  onChange={(option: any) => {
                    option == null ? setHitsPerPage(8) : setHitsPerPage(option.value);
                    setPage(1);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};
export default Algolia;
