import connector from '../connector';
import { TimeframeSubOptionType } from '../../pages/feedback/sections/TimeFrameTable';

export function getTimeframeOptions(isSchool: boolean, element?: string | undefined) {
  type SubTimeFrameOptionType = {
    label: string;
    value: string;
    data: Array<
      | {
          id: string;
          school_class: string;
          survey_type: string;
          survey_version: string;
        }
      | {
          id: string;
          name: string;
          start_date: string;
          end_date: string;
        }
    >;
  };
  type TimeframeOptionType = {
    login_user_timeframe?: Array<SubTimeFrameOptionType>;
    emulate_user_timeframe?: Array<SubTimeFrameOptionType>;
  };

  return connector.get<TimeframeOptionType>('api/feedback/timeframes', {
    params: {
      s: isSchool ? 1 : 0,
      element: element,
    },
  });
}

export function getRadarFeedbackData(
  ids?: Array<string>,
  schoolId?: string | number,
  surveyVersion?: string,
  isSchool?: boolean,
  lastInstrument?: boolean
) {
  type RadarFeedbackType = {
    data: {
      elements: Array<string>;
      options: Array<{ id: string; name: string; dimension?: number; responses?: number; teachers?: number; }>;
      data: Record<string, Record<string, number>> | [];
    };
  };

  const params: { dp?: Array<string>; isc?: Array<string> } = {};
  if (ids) {
    if (isSchool) {
      params.dp = ids;
    } else {
      params.isc = ids;
    }
  }

  return connector.get<RadarFeedbackType>('/api/feedback/radar', {
    params: {
      ...params,
      schoolId,
      surveyVersion,
      lastInstrument: lastInstrument ? 1 : 0,
    },
  });
}

export function getSummaryFeedbackData(
  ids?: Array<string>,
  schoolId?: number,
  surveyVersion?: string,
  isSchool?: boolean,
  download?: boolean
) {
  type SummaryFeedbackType = {
    data: {
      elements: Record<string, string>;
      options: Array<{ id: string; name: string; dimension?: number | null; responses?: number; teachers?: number; }>;
      data:
        | Record<
            string,
            {
              data: Record<string, number>;
              ci: Record<string, { u: number; l: number }>;
              pse: Array<Record<string, number>>;
            }
          >
        | [];
    };
  };

  const params: { dp?: Array<string>; isc?: Array<string> } = {};
  if (ids) {
    if (isSchool) {
      params.dp = ids;
    } else {
      params.isc = ids;
    }
  }

  return connector.get<SummaryFeedbackType>('/api/feedback/summary', {
    params: {
      ...params,
      id: schoolId,
      surveyVersion,
      download: download,
    },
  });
}

export function getDimensionsFeedbackData(timeframeSelections?: Array<string>, surveyVersion?: string) {
  type DimensionsFeedbackType = {
    data: {
      questions: Record<string, Array<{ title: string; direction: number }>>;
      options: Array<{ id: string; name: string }>;
      data: Record<
        string,
        Record<
          string,
          Record<
            string,
            {
              data: Record<string, number>;
              ci: Record<string, { u: number; l: number }>;
              pse: Record<string, number>;
            }
          >
        >
      >;
    };
  };

  return connector.get<DimensionsFeedbackType>('/api/feedback/dimensions', {
    params: {
      isc: timeframeSelections,
      surveyVersion,
    },
  });
}

export function getElementsFeedbackData(
  element: string,
  timeframeSelections?: Array<string>,
  isSchool?: boolean,
  emulated?: boolean
) {
  type ElementFeedbackType = {
    data: {
      questions: Record<string, Array<{ title: string; direction: number }>>;
      options: Array<{ id: string; name: string; responses?: number; teachers?: number; }>;
      data: Record<
        string,
        Record<
          string,
          {
            data: Record<string, number>;
            ci: Record<string, { u: number; l: number }>;
            pse: Record<string, number>;
          }
        >
      > | null;
    };
  };

  const params: { dp?: Array<string>; isc?: Array<string> } = {};
  if (timeframeSelections) {
    if (isSchool) {
      params.dp = timeframeSelections;
    } else {
      params.isc = timeframeSelections;
    }
  }

  return connector.get<ElementFeedbackType>('/api/feedback/element', {
    params: { ...params, element, emulated: emulated },
  });
}

export function getSchoolEnvFeedbackData(id?: string | null) {
  type SchoolEnvFeedbackType = {
    data: {
      elements: Record<string, Record<string, Array<Record<string, any> | { question: string; answers: string[] }>>>;
      stacks: Array<{ color: string; id: number }>;
    };
  };

  return connector.get<SchoolEnvFeedbackType>(`/api/feedback/school-env${id != null ? '/' + id : ''}`);
}

export type EnrolmentType = {
  id: number;
  completion_date: string | null;
  course_id: number;
};

export function getCompletedEnrolments() {
  return connector.get<{ data: Array<EnrolmentType> }>('/api/thinkific/enrolments/completed');
}
