import React, { ReactElement, useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, ResponsiveContainer, Text } from 'recharts';
import { debounce, round } from 'lodash';

const CustomToolTip = (props: any) => {
  const { active, payload, label } = props;

  if (active && payload && payload.length) {
    return (
      <div className={'bg-white p-2 border-2 border-gray-300'}>
        <p className={'mb-2'} style={{ color: '#EC6E2E' }}>
          {label}
        </p>
        {payload.map((p: any) => {
          return (
            <p className={'text-slate-300'} key={Math.random()}>
              {p.payload['name-' + p.dataKey]}: {round(p.payload[p.dataKey])} %
            </p>
          );
        })}
      </div>
    );
  }

  return null;
};

const RenderCustomizedLabel = (props: any) => {
  const { x, y, width, value, color, height } = props;

  // We are assuming that 100 is always the max value
  if (round(value.value[0]) === 100 || round(value.value[1]) !== 100) {
    return <></>;
  }

  const measureUnit = height / value[value.dataKey];
  const buttonPosition = y + measureUnit * 100;

  const ci = value.payload.ci;

  const lineY = buttonPosition - measureUnit * value.payload.value;
  const ciLY = buttonPosition - measureUnit * ci?.l;
  const ciUY = buttonPosition - measureUnit * ci?.u;

  return (
    <g>
      <line x1={x - 10} x2={x + width + 10} y1={lineY} y2={lineY} stroke={'#EC6E2E'} strokeWidth={2} />
      {ci && (
        <line x1={x - 10} x2={x - 10} y1={ciLY} y2={ciUY} stroke={'#EC6E2E'} strokeWidth={5} strokeLinecap={'round'} />
      )}
      <circle
        cx={x + width / 2}
        cy={lineY}
        r={width < 100 ? width / 5 : 17}
        fill="white"
        strokeWidth={1}
        stroke={'#EC6E2E'}
      />
      <text
        x={x + width / 2}
        y={lineY}
        fill={color}
        textAnchor="middle"
        dominantBaseline="middle"
        style={{
          fontSize: width < 100 ? width / 8 : 12,
        }}
      >
        {Math.round(value.payload.value) + '%'}
      </text>
    </g>
  );
};

const CustomBarWithTarget = (props: any) => {
  const { x, y, width, height, value, dataKey, isPdf } = props;

  const stackLabel = props['name-' + dataKey];
  const stackColor = props['color-' + dataKey];

  return (
    <svg>
      <rect x={x} y={y} width={width} height={height} stroke="none" fill={stackColor} />
      {null != stackLabel && value[1] - value[0] > 5 && (
        <Text
          x={x + width / 2}
          y={y + height / 2}
          fill={'white'}
          width={80}
          textAnchor={'middle'}
          verticalAnchor="middle"
          style={{
            fontSize: isPdf ? 10 : 15,
          }}
        >
          {stackLabel}
        </Text>
      )}
    </svg>
  );
};

const CustomizedAxisTick = (props: any) => {
  const { x, y, width, payload, color, visibleTicksCount, isPdf } = props;

  return (
    <Text
      x={x}
      y={isPdf ? y - 40 : y}
      style={isPdf ? { fontSize: 10 } : undefined}
      fill={color}
      width={(width / visibleTicksCount) * (isPdf ? 1.5 : 0.9)}
      textAnchor="middle"
      verticalAnchor="start"
    >
      {payload.value}
    </Text>
  );
};

const CustomStackedBarChart = ({
  id,
  data,
  stacks,
  dataKey,
}: {
  id: string;
  data: Array<Record<string, any>>;
  stacks?: Array<{ id: number }>;
  dataKey: string;
}) => {
  if (!data || !stacks) {
    return null;
  }

  const Component = (
    <ResponsiveContainer width={'100%'} height={650}>
      <CustomStackedBarChartComponent id={id} data={data} dataKey={dataKey} stacks={stacks} />
    </ResponsiveContainer>
  );

  const [chart, setChart] = useState<ReactElement | null>(Component);

  //A workaround to fix the axis label tangling in a single row where redimensioning
  useEffect(() => {
    const updateWindowDimensions = debounce(() => {
      setChart(null);
      setTimeout(() => {
        setChart(Component);
      }, 200);
    }, 500);

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return chart;
};

export const CustomStackedBarChartComponent = ({
  id,
  data,
  stacks,
  dataKey,
  width,
  height,
  isPdf,
}: {
  id: string;
  data: Array<Record<string, any>>;
  stacks?: Array<{ id: number }>;
  dataKey: string;
  width?: number;
  height?: number;
  isPdf?: boolean;
}) => (
  <BarChart
    width={width}
    height={height}
    barCategoryGap={20}
    data={data}
    margin={{
      top: isPdf ? 10 : 5,
      right: isPdf ? 0 : 30,
      left: isPdf ? 0 : 20,
      bottom: 5,
    }}
    barGap={25}
    id={id}
  >
    <CartesianGrid strokeDasharray="3,3" />
    <XAxis
      dataKey={dataKey}
      tickMargin={40}
      height={250}
      interval={0}
      tick={(props) => <CustomizedAxisTick {...props} color={'#EC6E2E'} isPdf={isPdf} />}
    />
    <YAxis
      width={isPdf ? 1 : undefined}
      style={isPdf ? { fontSize: 14 } : undefined}
      domain={[0, 110]}
      ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
    />
    <Tooltip content={(props) => <CustomToolTip {...props} />} />
    {stacks!.map((stack, index) => (
      <Bar
        key={index}
        dataKey={stack.id}
        stackId={'stack'}
        shape={(props) => <CustomBarWithTarget {...props} dataKey={stack.id} isPdf={isPdf} />}
        isAnimationActive={false}
      >
        <LabelList
          valueAccessor={(entry: any) => ({
            dataKey: stack.id,
            ...entry,
          })}
          content={RenderCustomizedLabel}
        />
      </Bar>
    ))}
  </BarChart>
);

export type CustomBarChartDataType = {
  values?: Array<Record<string, any>>;
  keys?: Array<string>;
};

export default CustomStackedBarChart;
