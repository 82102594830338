import { LinkHTMLAttributes } from 'react';
import styled, { css, ThemeProps } from 'styled-components';
import { lighten } from 'polished';
import { Link, LinkProps as RouterProps } from 'react-router-dom';
import { DEFAULT_THEME } from '../../Theme';
import { getButtonDisabledStyle, getButtonOutlineStyle, getButtonTextStyle, getMainColor } from '../../helpers/styles';
import { SIZE_HEIGHT } from '../../resources/vars';
import { getBaseButtonStyles, getButtonSize } from '../../helpers/styles';
import { AllColorsType } from '../../resources/types';

const getColors = () => {
  return css`
    text-decoration: underline;
    transition: color ease-in-out 450ms;
    &,
    &:visited {
      color: ${(props) => props.theme.colors.primary};
      &:hover {
        color: ${(props) => lighten(0.2, props.theme.colors.primary as string)};
        text-decoration: none;
      }
    }
  `;
};

export const LinkStyled = styled.a.attrs((props: LinkHTMLAttributes<any> & { target?: string }) => ({
  rel: '_blank' === props.target ? 'noopener noreferrer' : '',
  ...props,
}))<
  LinkHTMLAttributes<HTMLLinkElement> &
    ThemeProps<any> & { asButton?: boolean; asDisabledButton?: boolean; mainColor?: AllColorsType }
>`
  ${(props) => (props.asButton ? getBaseButtonStyles(props) : getColors())}
  ${(props) => props.asButton && 'text-transform: uppercase; max-width: content;'}
  ${(props) =>
    props.asButton && props.asDisabledButton ? getButtonDisabledStyle(props) : getButtonOutlineStyle(props)}
  ${(props) => props.asButton && getButtonSize('sm')}
  &:hover {
    background: ${(props) =>
      props.asButton ? (props.asDisabledButton ? props.theme.colors.light : getMainColor(props)) : 'transparent'};
    cursor: ${(props) => (props.asDisabledButton ? 'default' : 'pointer')};
    color: ${(props) => !props.asButton && lighten(0.2, props.theme.colors.primary as string)};
`;

LinkStyled.defaultProps = {
  theme: DEFAULT_THEME,
};

export const RouterStyled = styled(Link).attrs((props: LinkHTMLAttributes<any> & { target?: string }) => ({
  rel: '_blank' === props.target ? 'noopener noreferrer' : '',
  ...props,
}))<RouterProps & ThemeProps<any>>`
  ${() => getColors()}
  [data-id='icon_container'] {
    display: flex;
    width: ${SIZE_HEIGHT['md']}px;
    height: ${SIZE_HEIGHT['md']}px;
    border-radius: ${(props) => props.theme.borderRadius.app};
    ${(props) => getButtonTextStyle(props)}
    [data-id='icon'] {
      margin: auto;
    }
  }
`;

RouterStyled.defaultProps = {
  theme: DEFAULT_THEME,
};
