import React, { useState } from 'react';
import Empty from '../../../global/empty/empty';
import Button from '../../../global/button/button';
import { AddButton } from '../../../global/button/common';
import Choice from '../../../form/choice/choice';
import { Section } from '../../common';
import { MoreInfo, SliceText } from '../../../global/tooltip/common';
import { LinkStyled } from '../../../global/link/link.styled';
import Accordion from '../../../global/accordion/accordion';
import { ActionButton } from '../../../global/buttonIcon/common';
import { PathwaysGoalsProps } from '../../pathway/pathwaysPage.types';
import { SectionInAccordion } from '../../pathway/components';
import { ArchivedName } from '../../../global/table/components';
import { ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR } from '../../../resources/roles';
import { Strategy } from '../../../@types/Entity/Strategy';
import { useMutation, useQueryClient } from 'react-query';
import { archiveStrategy, postOrPutStrategy } from '../../../service/api';
import { ADMIN_GOALS, ADMIN_STRATEGIES } from '../../../service/queryKeys';

const PathwayStrategies = ({
  canDisable = false,
  isAdmin = false,
  toggle,
  data,
  showDisabled,
  handleShowDisabled,
  role,
}: PathwaysGoalsProps) => {
  const queryClient = useQueryClient();

  const [accordionOpen, setAccordionOpen] = useState<number | null>();

  const archiveStrategiesMutation = useMutation(archiveStrategy, {
    onSuccess: () => {
      queryClient.invalidateQueries(ADMIN_STRATEGIES);
    },
  });

  return (
    <Section
      id={'strategies'}
      headline={
        [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(role as string) ? (
          <div className={'flex inline-flex gap-1'}>School development strategies</div>
        ) : (
          'Development strategies'
        )
      }
      className={'field-mb'}
      more={
        <div className={'flex flex-inline gap-2 justify-end'}>
          {0 < data.length ? `${data.length} ${1 !== data.length ? 'strategies' : 'strategy'}` : ''}
          <MoreInfo
            content={
              <>
                For more on school development strategies, click{' '}
                <LinkStyled
                  href={'https://support.evidencebased.education/en/knowledge/what-are-school-development-strategies'}
                  target={'_blank'}
                >
                  here
                </LinkStyled>
              </>
            }
          />
        </div>
      }
    >
      <>
        {canDisable && (
          <div className={'flex justify-end field-mb'}>
            <Choice
              id={'archived-strategies'}
              label={'View deactivated strategies'}
              type={'switch'}
              checked={showDisabled}
              onChange={handleShowDisabled}
              className={'pretty-no-margin'}
            />
          </div>
        )}
        {data.length === 0 ? (
          <Empty
            who={isAdmin ? 'you do not' : 'your school does not'}
            element={'strategies'}
            action={
              isAdmin && (
                <>
                  <Button
                    onClick={() => toggle('Add a new strategy')}
                    asLink
                    style={{ fontStyle: 'italic', padding: 5 }}
                  >
                    clicking here
                  </Button>
                </>
              )
            }
          />
        ) : (
          <React.Fragment>
            {data?.map((strategy: Strategy, index: number) => (
              <div key={`admin_goal--${index}`}>
                <Accordion
                  isDisabled={1 === data?.length}
                  isOpen={accordionOpen === index || data.length === 1}
                  handleOpen={() => {
                    setAccordionOpen(accordionOpen === index ? null : index);
                  }}
                  title={
                    <div className={'flex items-center justify-between w-full'}>
                      <div className={'flex items-center'}>
                        <ArchivedName
                          archived={!strategy.enabled}
                          title={strategy.name}
                          item={'strategy'}
                          type={'deactivated'}
                        />
                      </div>
                      <div className={'flex items-center gap-3'}>
                        <ActionButton.Edit
                          archived={!strategy.enabled}
                          onClick={() => {
                            toggle('Update Strategy', strategy);
                          }}
                        />
                        <ActionButton.Change
                          archived={!strategy.enabled}
                          state={strategy.enabled as boolean}
                          states={{
                            on: { label: 'Enable', icon: 'enable' },
                            off: { label: 'Deactivate', icon: 'disable' },
                          }}
                          onClick={() => {
                            archiveStrategiesMutation.mutate({ id: strategy.id, enable: !strategy.enabled });
                          }}
                        />
                      </div>
                    </div>
                  }
                >
                  <div className={'md:grid grid-flow-col grid-cols-3 gap-4'}>
                    {/* Description */}
                    <SectionInAccordion title={'Description'}>
                      <SliceText value={strategy.description} />
                    </SectionInAccordion>
                    {/* Active ingredients (steps) */}
                    <SectionInAccordion title={'Working steps'}>
                      <ul className={'list-decimal list-inside'}>
                        {strategy.steps.map((step, index) => (
                          <li key={`ingredient_list--${index}`} className={'my-2'}>
                            {step}
                          </li>
                        ))}
                      </ul>
                    </SectionInAccordion>
                    {/* Examples URLs - Additional information*/}
                    <div>
                      <SectionInAccordion className={null} title={'Resources'}>
                        <div className={'sm-field-mb'}>
                          {strategy.resources.filter((url) => url.link).length !== 0 ? (
                            strategy.resources
                              .filter((url) => url.link)
                              .map((url, index) => {
                                return (
                                  <LinkStyled key={`url_list--${index}`} href={url.link} target={'_blank'}>
                                    {url.name || url.link}
                                  </LinkStyled>
                                );
                              })
                              .reduce((prev, curr) => [prev, ', ', curr] as any)
                          ) : (
                            <i className={'text-muted'}>No resources</i>
                          )}
                        </div>
                      </SectionInAccordion>
                      <SectionInAccordion className={null} title={'Additional information'}>
                        {strategy.additional_info ? (
                          <SliceText value={strategy.additional_info} />
                        ) : (
                          <i className={'text-muted'}>No additional information</i>
                        )}
                      </SectionInAccordion>
                    </div>
                  </div>
                </Accordion>
              </div>
            ))}
          </React.Fragment>
        )}
        <div className={'flex justify-end mt-8'}>
          <AddButton onClick={() => toggle('Add a new strategy')} label={'Strategy'} />
        </div>
      </>
    </Section>
  );
};

export default PathwayStrategies;
