import React, { useState } from 'react';
import MeetingsOverview from '../../greatTeachingTeams/components/MeetingsOverview';
import { Section } from '../../common';
import DashboardChecklist from '../onboarding/dashboardChecklist';
import Button from '../../../global/button/button';
import useModal from '../../../hooks/useModal';
import ConfirmationModal from '../onboarding/confirmationModal';
import { Me } from '../../../@types/Entity/Me';
import { useQuery } from 'react-query';
import { THINKIFIC_SSO } from '../../../service/queryKeys';
import { getThinkificSSO } from '../../../service/api';

const OnboardingDashboard = ({
  type,
  link = '',
  me,
}: {
  type: 'school' | 'teacher' | undefined;
  link?: string;
  me: Me;
}) => {
  const [atLeastOneValueChecked, setAtLeastOneValueChecked] = useState(true);

  const [modal, toggleModal, setModalProps] = useModal((props: any) => <ConfirmationModal {...props} />, {});

  const thinkificSSO =
    type === 'teacher'
      ? useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
          select: (data: any) => data.data.data.link,
          refetchInterval: 120000, //Thinkifc token last for 2 mins,so we refetch the token here every 2 mins
          refetchIntervalInBackground: true,
        })
      : undefined;

  return (
    <>
      {modal}
      {type && (
        <>
          <div className={'block-with-sub-container mb-5'}>
            <MeetingsOverview me={me} onboarding />
          </div>
          <div className={'block-with-sub-container mb-5'}>
            <Section>
              {type === 'school' ? (
                <div className={`mx-auto mb-5`}>
                  <h4>Get your school ready to start the GTT</h4>
                  <p>Check off the tasks as you go; then, when you and your teachers are ready, launch the toolkit!</p>
                </div>
              ) : type === 'teacher' ? (
                <>
                  <div className={`mx-auto mb-5`}>
                    <h4>Getting started with the GTT</h4>
                  </div>
                </>
              ) : (
                <></>
              )}
              <DashboardChecklist
                me={me}
                link={link}
                type={type}
                setAtLeastOneValueChecked={setAtLeastOneValueChecked}
                thinkificLink={
                  thinkificSSO?.data +
                  'https://evidence-based-education.thinkific.com/courses/take/Foundation/lessons/26663010-welcome-to-the-great-teaching-toolkit'
                }
              />
              {type === 'school' ? (
                <div className={`mx-auto mb-5 mt-2`}>
                  <p className={' mx-auto text-center'}>
                    We’ve assigned you a GTT Advisor to help you get the most out of the toolkit. You can discuss your
                    plans with them or email them with questions at any time. They’ll soon be reaching out to you
                    directly by email.
                  </p>
                </div>
              ) : (
                <></>
              )}
              <div className={'block-container flex justify-between'}>
                <div className={'w-2/3 items-center flex'}></div>
                <Button
                  disabled={type === 'school' ? atLeastOneValueChecked : type !== 'teacher'}
                  onClick={() => {
                    setModalProps({ type: type, rounded: true });
                    toggleModal(true);
                  }}
                  className={'mx-auto mt-5 flex'}
                >
                  Ready to{type === 'school' ? ' launch' : ' start'}
                </Button>
              </div>
            </Section>
          </div>
        </>
      )}
    </>
  );
};
export default OnboardingDashboard;
