import React from "react";

type SectionInAccordionProps = {
  title?: string;
  className?: string | null;
  children: React.ReactNode;
}

const SectionInAccordion = ({title, className = 'sm-field-mb md:mb-0', children}: SectionInAccordionProps) => (
  <div className={className || ''}>
    {title && (
      <b className={'block font-display sm-field-mb'}>
        {title}
      </b>
    )}
    {children}
  </div>
)

export default SectionInAccordion;
