import React, { useState } from 'react';
import Section from '../../common/components/section';
import DefaultTable from '../../../global/table/defaultTable';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NIOT_TRACKING, THINKIFIC_SSO } from '../../../service/queryKeys';
import {
  getNITeacherProgress,
  getStcpTeacherProgress,
  getThinkificSSO,
  postChapterTracking,
} from '../../../service/api';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import { LinkStyled } from '../../../global/link/link.styled';
import { Me } from '../../../@types/Entity/Me';
import useModal from '../../../hooks/useModal';
import WarningManualCheckModal from './modals/WarningManualCheckModal';
import { MoreInfo } from '../../../global/tooltip/common';

export default function SelfStudy({ groupId, me, stcp = false }: { stcp?: boolean; groupId: string | null; me: Me }) {
  const niotTrackingQuery = useQuery(
    [NIOT_TRACKING],
    () => (stcp ? getStcpTeacherProgress() : getNITeacherProgress(groupId)),
    {
      select: (data) => data.data.data,
    }
  );

  const thinkificSSO = useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
    select: (data: any) => data.data.data.link,
  });

  const queryClient = useQueryClient();

  const postManuallyUpdateChapter = useMutation(postChapterTracking, {
    onSuccess: () => {
      queryClient.invalidateQueries(NIOT_TRACKING);
    },
  });

  const [
    warningManualCheckModal,
    toggleWarningManualCheckModal,
    setWarningManualCheckModalProps,
  ] = useModal((props: any) => <WarningManualCheckModal {...props} />);

  return (
    <>
      {warningManualCheckModal}
      <Section size={'md'} className={'mt-4'}>
        <div className="flex justify-between">
          <h5>{stcp ? 'School Trust CEO Programme progress' : 'Self study'}</h5>
          <MoreInfo
            content={
              "Your progress will be tracked automatically. If you're working through the course using the pdf version, check each module as you complete it."
            }
          />
        </div>
        <DefaultTable
          additionalContainerClasses={'mt-4 max-h-96'}
          id={`self_study_table`}
          noDataMessage={'There is no data yet.'}
          columns={[
            {
              id: 'module',
              label: stcp ? 'Self-study chapters' : 'Current module',
            },
            {
              id: 'complete',
              label: 'Complete',
            },
          ]}
          rows={(niotTrackingQuery.data ?? []).map((data: any, index: number) => {
            return {
              id: `${stcp ? 'stcp' : 'niot'}_tracking_${index}`,
              cells: [
                {
                  id: 'module',
                  content: me.mentor ? (
                    data.chapter_name
                  ) : (
                    <LinkStyled href={thinkificSSO.isFetched ? thinkificSSO.data + data.url : '#'} target={'_blank'}>
                      {data.chapter_name}
                    </LinkStyled>
                  ),
                },
                {
                  id: 'complete',
                  content: data.completed ? (
                    <Icon
                      className={stcp ? 'cursor-pointer' : ''}
                      icon={GLOBAL_ICONS.completed}
                      color={'success'}
                      container={false}
                      elementSize={20}
                      onClick={
                        stcp
                          ? () => {
                              setWarningManualCheckModalProps({
                                title: 'Manually update course progress',
                                handleSubmit: () =>
                                  postManuallyUpdateChapter.mutate({ chapter_id: data.id, offline_study: false }),
                              });
                              toggleWarningManualCheckModal(true);
                            }
                          : () => {
                              'Do nothing';
                            }
                      }
                    />
                  ) : (
                    <Icon
                      className={stcp ? 'cursor-pointer' : ''}
                      icon={GLOBAL_ICONS.uncompleted}
                      color={'muted'}
                      container={false}
                      elementSize={20}
                      onClick={
                        stcp
                          ? () => {
                              setWarningManualCheckModalProps({
                                title: 'Manually update course progress',
                                handleSubmit: () =>
                                  postManuallyUpdateChapter.mutate({ chapter_id: data.id, offline_study: true }),
                              });
                              toggleWarningManualCheckModal(true);
                            }
                          : () => {
                              'Do nothing';
                            }
                      }
                    />
                  ),
                },
              ],
            };
          })}
          showCounter={false}
        />
      </Section>
    </>
  );
}
