/**
 * Returns aria-describedby value.
 */
export function getAriaDescribedby(id: string): string {
  return `${id}_help`;
}

/**
 * Returns capitalized string.
 */
export function capitalize(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Filters an array by a search value/keys.
 */
export function getSearchItems(items: Record<any, any>[], keys: string[], searchValue: string): Record<any, any>[] {
  let arr = items;

  arr = arr.filter((item) => {
    if (keys.some((key) => item[key].toLowerCase().includes(searchValue.toLowerCase()))) {
      return item;
    }
  });

  return arr;
}

