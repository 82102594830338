import { Section } from '../../common';
import { AddButton } from '../../../global/button/common';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getMeetingTemplates } from '../../../service/api/groupsApi';
import MeetingTemplateFormModal from '../modals/MeetingTemplateFormModal';
import { MeetingTemplate } from '../../../@types/Entity/MeetingTemplate';
import { MEETINGS_TEMPLATES } from '../../../service/queryKeys';
import MeetingTemplateAccordionItem from './MeetingTemplateAccordionItem';
import ConditionalBlock from '../../../global/ConditionalBlock/ConditionalBlock';

export default function MeetingTemplates() {
  const [addMeetingTemplateModalData, setAddMeetingTemplateModalData] = useState({
    isOpen: false,
    meetingPathwayToEdit: null as MeetingTemplate | null,
  });

  const meetingTemplatesQuery = useQuery([MEETINGS_TEMPLATES], () => getMeetingTemplates());

  return (
    <>
      {addMeetingTemplateModalData.isOpen && (
        <MeetingTemplateFormModal
          toggle={() => setAddMeetingTemplateModalData({ isOpen: false, meetingPathwayToEdit: null })}
          meetingTemplateToEdit={addMeetingTemplateModalData.meetingPathwayToEdit}
        />
      )}

      <Section headline={'Agenda templates'} className={'field-mb'}>
        <ConditionalBlock
          isLoading={!meetingTemplatesQuery.isFetched}
          isError={meetingTemplatesQuery.isError}
          render={() => (
            <>
              {0 === meetingTemplatesQuery.data!.data.length && ''}
              {meetingTemplatesQuery.data!.data.map((meetingTemplate) => (
                <MeetingTemplateAccordionItem
                  key={meetingTemplate.id}
                  meetingTemplate={meetingTemplate}
                  onEditClick={() =>
                    setAddMeetingTemplateModalData({ isOpen: true, meetingPathwayToEdit: meetingTemplate })
                  }
                />
              ))}

              <div className={'flex justify-end mt-8'}>
                <AddButton
                  id={'stonly_add_template'}
                  key={'add-template-button'}
                  label={['Agenda template']}
                  onClick={() => setAddMeetingTemplateModalData({ isOpen: true, meetingPathwayToEdit: null })}
                />
              </div>
            </>
          )}
        />
      </Section>
    </>
  );
}
