import React, { useState } from 'react';
import { PathwaysGoalsProps, StrategyProps } from '../pathwaysPage.types';
import Empty from '../../../global/empty/empty';
import Button from '../../../global/button/button';
import { AddButton } from '../../../global/button/common';
import Choice from '../../../form/choice/choice';
import Accordion from '../../../global/accordion/accordion';
import { SectionInAccordion } from '../components';
import { SliceText } from '../../../global/tooltip/common';
import Tag from '../../../global/tag/tag';
import { LinkStyled } from '../../../global/link/link.styled';

const PathwaysAdminGoals = ({
  canDisable = false,
  isAdmin = false,
  toggle,
  mutation,
  data,
  showDisabled,
  handleShowDisabled,
}: PathwaysGoalsProps) => {
  const [accordionOpen, setAccordionOpen] = useState<number | null>();
  const [strategyRow, setStrategyRow] = useState<number | null>();

  return (
    <>
      {(isAdmin || canDisable) && (
        <div className={'flex items-center justify-between field-mb'}>
          {isAdmin && <AddButton onClick={() => toggle('Add a new goal')} label={'Goal'} />}
          {canDisable && (
            <Choice
              id={'archived-goals'}
              label={'View disabled goals'}
              type={'switch'}
              checked={showDisabled}
              onChange={handleShowDisabled}
              className={'pretty-no-margin'}
            />
          )}
        </div>
      )}
      {data.length === 0 ? (
        <Empty
          who={isAdmin ? 'you do not' : 'your school does not'}
          element={'strategies'}
          action={
            isAdmin && (
              <>
                <Button onClick={() => toggle('Add a new strategy')} asLink style={{ fontStyle: 'italic', padding: 5 }}>
                  clicking here
                </Button>
              </>
            )
          }
        />
      ) : (
        <React.Fragment>
          {data?.map((goal: any, index: number) => (
            <div key={`admin_goal--${index}`}>
              <Accordion
                isDisabled={1 === data?.length}
                isOpen={accordionOpen === index || 1 === data?.length}
                handleOpen={() => {
                  if (accordionOpen) {
                    setStrategyRow(null);
                  }

                  setAccordionOpen(accordionOpen === index ? null : index);
                }}
                title={
                  <div className={'flex items-center justify-between w-full'}>
                    <div>
                      <span className={'text-muted'}>Goal {index + 1} —</span> <b>{goal.name}</b>
                    </div>
                    {(canDisable || isAdmin) && (
                      <div className={'flex items-center'}>
                        {canDisable && (
                          <div className={'border-r mr-2 pr-2'}>
                            <Choice
                              id={`status_goal--${index}`}
                              label={'Enable'}
                              checked={goal.enabled}
                              onClick={(event: any) => event.stopPropagation()}
                              onChange={() => mutation.mutate({ id: goal.id, enabled: !goal.enabled })}
                              type={'switch'}
                              className={'pretty-no-margin'}
                            />
                          </div>
                        )}
                        {isAdmin && (
                          <Button
                            asLink
                            style={{ padding: 0 }}
                            onClick={(event) => {
                              event.stopPropagation();
                              toggle('Update Goal', goal);
                            }}
                          >
                            Edit
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                }
              >
                {goal.strategies.map((strategy: StrategyProps, index: number) => (
                  <Accordion
                    key={index}
                    isDisabled={goal.strategies.length === 1}
                    isOpen={strategyRow === index || goal.strategies.length === 1}
                    handleOpen={() => (strategyRow === index ? setStrategyRow(null) : setStrategyRow(index))}
                    title={
                      <div className={'flex items-center justify-between w-full'}>
                        <div>
                          <span className={'text-muted'}>Strategy {index + 1} —</span> <b>{strategy.name}</b>
                        </div>
                      </div>
                    }
                  >
                    <div className={'md:grid grid-flow-col grid-cols-3 gap-4'}>
                      {/* Description */}
                      <SectionInAccordion title={'Description'}>
                        <SliceText value={strategy.description} />
                      </SectionInAccordion>
                      {/* Active ingredients (steps) */}
                      <SectionInAccordion title={'Working steps'}>
                        <ul className={'list-decimal list-inside'}>
                          {strategy.steps.map((step, index) => (
                            <li key={`ingredient_list--${index}`} className={'my-2'}>
                              {step}
                            </li>
                          ))}
                        </ul>
                      </SectionInAccordion>
                      {/* Examples URLs - Additional information*/}
                      <div>
                        <SectionInAccordion className={null} title={'Resources'}>
                          <div className={'sm-field-mb'}>
                            {strategy.urls.filter((url: any) => url.link).length !== 0 ? (
                              strategy.urls
                                .filter((url: any) => url.link)
                                .map((url: any, index: number) => {
                                  return (
                                    <LinkStyled key={`url_list--${index}`} href={url.link} target={'_blank'}>
                                      {url.name || url.link}
                                    </LinkStyled>
                                  );
                                })
                                .reduce((prev: any, curr: any) => [prev, ', ', curr] as any)
                            ) : (
                              <i className={'text-muted'}>No resources</i>
                            )}
                          </div>
                        </SectionInAccordion>
                        <SectionInAccordion className={null} title={'Additional information'}>
                          {strategy.additional_info ? (
                            <SliceText value={strategy.additional_info} />
                          ) : (
                            <i className={'text-muted'}>No additional information</i>
                          )}
                        </SectionInAccordion>
                      </div>
                    </div>
                  </Accordion>
                ))}
              </Accordion>
            </div>
          ))}
        </React.Fragment>
      )}
    </>
  );
};

export default PathwaysAdminGoals;
