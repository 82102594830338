import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Selector from '../../../form/select/selector';
import { MessageAtMenuList } from '../../../form/select/components';
import { useMutation, useQuery } from 'react-query';
import { VIDEO_SHARE_OPTIONS, VIDEO_SHARE_VALUES } from '../../../service/queryKeys';
import { getVideoSharedWithOptions, getVideoShareOptions, putShareVideos } from '../../../service/api';
import { toast } from 'react-toastify';
import { SelectOptionType } from '../../../service/types';

const ShareVideoModal = ({ toggle, videoId }: ModalToggleProps & { videoId: number }) => {
  const { data: options, isFetching: isOptionLoading, isFetched: isOptionFetched } = useQuery(
    VIDEO_SHARE_OPTIONS,
    getVideoShareOptions,
    {
      select: (data) => data.data.data,
    }
  );
  const { data: values, isFetched: isValuesFetched } = useQuery(
    [VIDEO_SHARE_VALUES, videoId],
    () => getVideoSharedWithOptions(videoId),
    {
      select: (data) => data.data.data,
    }
  );

  const shareVideoMutation = useMutation(putShareVideos, {
    onSuccess: () => {
      toggle(false);
      toast.success('Video shared successfully');
    },
  });

  return (
    <Modal>
      <Formik
        initialValues={{
          groups: values?.groups ?? [],
          individuals: values?.individuals ?? [],
          element: values?.element,
        }}
        validationSchema={Yup.object({
          groups: Yup.array().required(),
          individuals: Yup.array(),
          element: Yup.object({
            label: Yup.string(),
            value: Yup.string().required(),
          }),
        })}
        onSubmit={(values) => {
          shareVideoMutation.mutate({
            videoId,
            individuals: values.individuals.map((i: { label: string; value: number }) => i.value),
            groups: values.groups.map((g: { label: string; value: number }) => g.value),
            element: values.element?.value,
          });
        }}
        isInitialValid={values?.element != null}
        enableReinitialize={true}
      >
        {({ values, handleSubmit, isValid, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="mb-8">
                <Selector
                  id={'groups'}
                  label={'Team'}
                  placeholder={'Select teams'}
                  value={values.groups}
                  isMulti
                  onChange={(option: SelectOptionType[]) => setFieldValue('groups', option)}
                  options={options?.groups ?? []}
                  customComponents={
                    !isOptionLoading
                      ? null
                      : {
                          MenuList: function MenuList(menuListProps: any) {
                            return <MessageAtMenuList message={'Loading...'} {...menuListProps} />;
                          },
                        }
                  }
                />
              </div>
              <div className="mb-8">
                <Selector
                  id={'individuals'}
                  label={'Share with individuals'}
                  placeholder={'Select members of your school/organisation'}
                  value={values.individuals}
                  onChange={(option: SelectOptionType[]) => setFieldValue('individuals', option)}
                  options={options?.individuals ?? []}
                  isMulti
                  customComponents={
                    !isOptionLoading
                      ? null
                      : {
                          MenuList: function MenuList(menuListProps: any) {
                            return <MessageAtMenuList message={'Loading...'} {...menuListProps} />;
                          },
                        }
                  }
                />
              </div>
              <div className="mb-8">
                <Selector
                  id={'element'}
                  label={'Select an element for feedback'}
                  required
                  placeholder={'Select an element'}
                  value={values.element}
                  onChange={(option: SelectOptionType) => setFieldValue('element', option)}
                  options={options?.elements ?? []}
                  isClearable={false}
                  customComponents={
                    !isOptionLoading
                      ? null
                      : {
                          MenuList: function MenuList(menuListProps: any) {
                            return <MessageAtMenuList message={'Loading...'} {...menuListProps} />;
                          },
                        }
                  }
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                id={'shareVideoModal_saveButton'}
                type="submit"
                disabled={!isValid || shareVideoMutation.isLoading || !isOptionFetched || !isValuesFetched}
                label={shareVideoMutation.isLoading ? 'Loading...' : 'Share'}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ShareVideoModal;
