import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import AuthLayout from '../../../layout/auth';
import Input from '../../../form/input/input';
import Button from '../../../global/button/button';
import { getValidateResetPasswordToken, postResetPassword } from '../../../service/api';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import routeBuilder from '../../../service/routeBuilder';
import { toast } from 'react-toastify';
import {VALIDATE_RESET_PASSWORD_TOKEN} from "../../../service/queryKeys";

const token = new URLSearchParams(location.search).get('t');

const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const { isSuccess, isError } = useQuery(
    [VALIDATE_RESET_PASSWORD_TOKEN],
    () => getValidateResetPasswordToken(token as string),
    { enabled: token != null }
  );

  if (token == null) {
    navigate(routeBuilder('login').route);
  }

  if (isError) {
    toast.error('Sorry, the password reset link you are using has expired.');
    navigate(routeBuilder('login').route);
  }

  const SCHEMA = Yup.object().shape({
    password: Yup.string()
      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9]).{8,255}$/)
      .required(),
  });

  if (isSuccess) {
    return (
      <AuthLayout title={'Reset password'}>
        <Formik
          initialValues={{ password: '' }}
          isInitialValid={false}
          validationSchema={SCHEMA}
          onSubmit={(values) => {
            postResetPassword(token as string, values.password).then(() => navigate(routeBuilder('login').route));
          }}
        >
          {({ values, handleSubmit, handleChange, isValid }) => (
            <form className={'py-5'} onSubmit={handleSubmit}>
              <div className={'mb-8'}>
                <Input
                  id={'password'}
                  type={'password'}
                  label={'Please specify your new password'}
                  placeholder={'Enter your password'}
                  required
                  value={values.password}
                  onChange={handleChange}
                />
              </div>
              <Button className={'block w-full mt-5'} type={'submit'} disabled={!isValid}>
                Confirm
              </Button>
            </form>
          )}
        </Formik>
      </AuthLayout>
    );
  }

  return <></>;
};

export default ResetPasswordPage;
