import React, { HTMLAttributes } from 'react';
import { TabContentProps, TabProps } from './tab.types';
import { TabButtonStyled } from './tab.styled';

const Tab = ({
  parent,
  items,
  active,
  clickOnTab,
  optionsClassName,
  children,
  ...other
}: TabProps & HTMLAttributes<HTMLDivElement>) => (
  <React.Fragment>
    <div className={'border-b-2 flex items-center'} {...other}>
      {items.map((tab, index) =>
        undefined !== tab.content && null !== tab.content ? (
          <div
            className={optionsClassName}
            key={`tab_${tab}--${index}`}
            aria-labelledby={`button_${tab.value}`}
            aria-describedby={`content_${tab.value}`}
            id={parent && `${parent}_tab${tab.value}`}
          >
            {React.cloneElement(tab.content, {
              onClick: () => clickOnTab(tab.value),
              id: `button_${tab.value}`,
              type: 'button',
              'data-toggle': active === tab.value,
            })}
          </div>
        ) : (
          <div
            key={`tab_${tab}--${index}`}
            aria-labelledby={`button_${tab.value}`}
            aria-describedby={`content_${tab.value}`}
            className={optionsClassName}
            id={parent && `${parent}_tab${tab.value}`}
          >
            <TabButtonStyled
              id={`button_${tab.value}`}
              onClick={() => clickOnTab(tab.value)}
              type={'button'}
              data-toggle={active === tab.value}
            >
              {tab.label}
            </TabButtonStyled>
          </div>
        )
      )}
    </div>
    {children && children}
  </React.Fragment>
);

const Content = ({ id, active, children, ...other }: TabContentProps & Omit<HTMLAttributes<HTMLDivElement>, 'id'>) => (
  <div id={`content_${id}`} className={`animate-fade-in ${id === active ? 'block' : 'hidden'}`} {...other}>
    {children}
  </div>
);

Tab.Content = Content;

export default Tab;
