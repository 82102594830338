import React, { useState } from 'react';
import { Section } from '../../common';
import DefaultTable from '../../../global/table/defaultTable';
import Choice from '../../../form/choice/choice';
import { AddButton } from '../../../global/button/common';
import useModal from '../../../hooks/useModal';
import { DevelopmentGoalModal } from '../modals';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { archiveDevelopmentGoal, getDevelopmentGoals, shareDevelopmentGoals } from '../../../service/api';
import { ActionButton } from '../../../global/buttonIcon/common';
import { toast } from 'react-toastify';
import {
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_SYS_ADMIN,
  RolesType,
} from '../../../resources/roles';
import { ArchivedName } from '../../../global/table/components';
import Tag from '../../../global/tag/tag';
import { ADMIN_GOALS, ADMIN_STRATEGIES } from '../../../service/queryKeys';
import { AdminGoalProps } from '../../pathway/pathwaysPage.types';
import { MoreInfo } from '../../../global/tooltip/common';
import { LinkStyled } from '../../../global/link/link.styled';
import Accordion from '../../../global/accordion/accordion';
import { SectionInAccordion } from '../../pathway/components';
import { Goal } from '../../../@types/Entity/Goal';
import dayjs from 'dayjs';
import { WHOLE_SCHOOL_OPTION } from '../modals/developmentGoalModal';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Button from '../../../global/button/button';

const PathwayGoals = ({ role, strategies }: { role: RolesType; strategies: any[] }) => {
  const queryClient = useQueryClient();

  const [showAll, setShowAll] = useState<string>();
  const [accordionOpen, setAccordionOpen] = useState<number | null>();
  const INCLUDE_DISABLED = 'disabled';

  const updateShow = () => {
    return showAll === INCLUDE_DISABLED ? undefined : INCLUDE_DISABLED;
  };

  const [modal, toggle, setProps] = useModal((props: any) => <DevelopmentGoalModal {...props} />);

  const { data } = useQuery([ADMIN_GOALS, showAll, 'school'], () => getDevelopmentGoals(showAll, 'school'), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    keepPreviousData: true,
  });

  const archiveGoalMutation = useMutation(archiveDevelopmentGoal, {
    onSuccess: () => {
      toggle(false);
      queryClient.invalidateQueries(ADMIN_GOALS);
      queryClient.invalidateQueries(ADMIN_STRATEGIES);
    },
  });

  const shareGoalMutation = useMutation(shareDevelopmentGoals);

  return (
    <>
      {modal}
      <Section
        id={'goals'}
        headline={
          [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(role as string) ? (
            <div className={'flex inline-flex gap-1'}>School development goals</div>
          ) : (
            'Development goals'
          )
        }
        more={
          <div className={'flex flex-inline gap-2 justify-end'}>
            {0 < data?.length ? `${data.length} goal${1 === data.length ? '' : 's'}` : ''}
            <MoreInfo
              content={
                <>
                  School development goals may include any school improvement priorities, for example those identified
                  in a school improvement plan. For more on development goals, click{' '}
                  <LinkStyled
                    href={'https://support.evidencebased.education/en/knowledge/what-are-school-development-goals'}
                    target={'_blank'}
                  >
                    here
                  </LinkStyled>
                </>
              }
            />
          </div>
        }
        className={'field-mb'}
      >
        <div className={'flex items-center justify-end field-mb'}>
          <Choice
            id={'archived-classes'}
            label={'View deactivated goals'}
            type={'switch'}
            checked={showAll === INCLUDE_DISABLED}
            onChange={() => setShowAll(updateShow())}
            className={'pretty-no-margin'}
          />
        </div>
        {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(role as string) && (
          <>
            {data?.length === 0 ? (
              <div className={'text-center text-muted mx-auto'}>
                <Icon
                  icon={GLOBAL_ICONS.empty}
                  elementSize={40}
                  color={'muted'}
                  container={false}
                  className={'mx-auto'}
                />
                <p className={'italic mt-2'}>Hey! It seems you do not have any goals yet.</p>
                <p className={'italic mt-2'}>
                  <Button
                    onClick={() => {
                      setProps({
                        title: 'Add new goal',
                        strategies: strategies,
                        role,
                      });
                      toggle(true);
                    }}
                    asLink
                    style={{ fontStyle: 'italic', padding: 5 }}
                  >
                    Click here
                  </Button>
                  to get started.
                </p>
              </div>
            ) : (
              data?.map((goal: Goal, index: number) => (
                <div key={`admin_goal--${index}`}>
                  <Accordion
                    isOpen={accordionOpen === index}
                    handleOpen={() => {
                      setAccordionOpen(accordionOpen === index ? null : index);
                    }}
                    title={
                      <>
                        <div className={'flex items-center justify-between w-full'}>
                          <div className={'flex items-center justify-between gap-4 w-2/3'}>
                            <b>{goal.name}</b>
                            <div>{(goal.strategies ?? [])?.length > 0 && `Strategy: ${goal.strategies![0].name}`}</div>
                            <div>{goal.element && `${goal.element.label} selected`}</div>
                          </div>
                          <div className={'flex items-center gap-3'}>
                            <ActionButton.Edit
                              disabled={!goal.enabled}
                              archived={!goal.enabled}
                              onClick={(e) => {
                                e.stopPropagation();

                                setProps({
                                  title: 'Edit goal',
                                  description: goal.name,
                                  goal: goal,
                                  role,
                                });
                                toggle(true);
                              }}
                            />
                            <ActionButton.Custom
                              disabled={!goal.enabled}
                              buttonLabel={'Share'}
                              icon={'share'}
                              onClick={(e) => {
                                e.stopPropagation();

                                shareGoalMutation.mutate(goal.id);
                              }}
                            />
                            <ActionButton.Change
                              archived={!goal.enabled}
                              state={goal.enabled as boolean}
                              states={{
                                on: { label: 'Enable', icon: 'enable' },
                                off: { label: 'Deactivate', icon: 'disable' },
                              }}
                              onClick={(e) => {
                                e.stopPropagation();

                                archiveGoalMutation.mutateAsync({ id: goal.id, enable: !goal.enabled }).then(() => {
                                  toast.success('Goal updated');
                                });
                              }}
                            />
                          </div>
                        </div>
                        {(goal.groups ?? [])?.length > 0 &&
                          `Assigned to: ${goal.groups?.map((group) => group.name).join(', ')}`}
                        {goal.whole_school_shared && `Assigned to: ${WHOLE_SCHOOL_OPTION.label}`}
                      </>
                    }
                  >
                    <div className={'md:grid grid-flow-col grid-cols-3 gap-4'}>
                      <SectionInAccordion title={'Action'}>
                        <ul>
                          {goal.actions?.map((action) => (
                            <li key={`action-${action.id}`} className={'my-2'}>
                              {action.title}
                            </li>
                          ))}
                        </ul>
                      </SectionInAccordion>
                      <SectionInAccordion title={'Tags'}>
                        <ul>
                          {goal.actions?.map((action) => (
                            <li key={`tags-${action.id}`} className={'my-2'}>
                              {action.tags.map((tag) => tag.title).join(', ')}
                            </li>
                          ))}
                        </ul>
                      </SectionInAccordion>
                      <SectionInAccordion title={'Due date'}>
                        <ul>
                          {goal.actions?.map((action) => (
                            <li key={`date-${action.id}`} className={'my-2'}>
                              {dayjs(action.dueDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                            </li>
                          ))}
                        </ul>
                      </SectionInAccordion>
                    </div>
                  </Accordion>
                </div>
              ))
            )}
          </>
        )}
        {role === ROLE_SYS_ADMIN && (
          <DefaultTable
            id={'admin_goals'}
            showCounter={false}
            columns={[
              { id: 'name', label: 'Name' },
              { id: 'strategies', label: 'Strategies assigned' },
              { id: 'developmentPeriod', label: 'Term dates' },
              { id: 'actions', label: 'Actions' },
            ]}
            rows={
              0 === data?.length
                ? []
                : data?.map((goal: AdminGoalProps, index: number) => {
                    return {
                      id: `row_goal--${index}`,
                      class: !goal.enabled ? 'archived' : '',
                      cells: [
                        {
                          id: 'name',
                          content: (
                            <ArchivedName archived={!goal.enabled} title={goal.name} item={'goal'} type={'disabled'} />
                          ),
                        },
                        {
                          id: 'strategies',
                          content: goal.strategies?.length ? (
                            <div className={'space-x-4'}>
                              {goal.strategies.map((strategy, strategyIndex) => (
                                <Tag
                                  key={`goal--${index}_strategy--${strategyIndex}`}
                                  content={strategy.name}
                                  className={'inline-block'}
                                />
                              ))}
                            </div>
                          ) : (
                            <i className={'text-muted'}>—</i>
                          ),
                        },
                        {
                          id: 'developmentPeriod',
                          content: goal.development_period?.name,
                        },
                        {
                          id: 'actions',
                          class: 'actions',
                          content: (
                            <ul className={'actions-list'}>
                              <li>
                                <ActionButton.Change
                                  archived={!goal.enabled}
                                  state={goal.enabled as boolean}
                                  states={{
                                    on: { label: 'Enable', icon: 'enable' },
                                    off: { label: 'Deactivate', icon: 'disable' },
                                  }}
                                  onClick={() => {
                                    archiveGoalMutation.mutateAsync({ id: goal.id, enable: !goal.enabled }).then(() => {
                                      toast.success('Goal updated');
                                    });
                                  }}
                                />
                              </li>
                              <li>
                                <ActionButton.Edit
                                  disabled={!goal.enabled}
                                  archived={!goal.enabled}
                                  onClick={() => {
                                    setProps({
                                      title: 'Edit goal',
                                      description: goal.name,
                                      goal: goal,
                                      role,
                                    });
                                    toggle(true);
                                  }}
                                />
                              </li>
                            </ul>
                          ),
                        },
                      ],
                    };
                  })
            }
            noDataMessage={<>Start adding goals clicking on &quot;Add goal&quot; button.</>}
          />
        )}
        <div className={'flex justify-end mt-8'}>
          <AddButton
            label={'Goal'}
            onClick={() => {
              setProps({
                title: 'Add new goal',
                strategies: strategies,
                role,
              });
              toggle(true);
            }}
          />
        </div>
      </Section>
    </>
  );
};

export default PathwayGoals;
