import Modal from '../../../../global/messages/modal/modal';
import ModalComponents from '../../../../global/messages/modal/modal.components';
import { ModalProps } from '../../../../global/messages/modal/modal.types';
import React from 'react';

export default function ArchiveAllModal({ toggle, onConfirm, isLoading }: Props) {
  return (
    <Modal
      open
      toggle={() => toggle()}
      title={'Are you sure?'}
      size={'md'}
    >
      <ModalComponents.Footer>
        <ModalComponents.CancelButton onClick={() => toggle(false)} />
        <ModalComponents.ConfirmButton
          label={isLoading
            ? 'Loading...'
            : 'Confirm'
          }
          type={'button'}
          disabled={isLoading}
          onClick={() => onConfirm()}
        />
      </ModalComponents.Footer>
    </Modal>
  );
}

interface Props extends Pick<ModalProps, 'toggle'> {
  onConfirm: () => void;
  isLoading: boolean;
}
