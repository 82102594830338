import React, { useEffect, useState } from 'react';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Input from '../../../form/input/input';
import Label from '../../../form/common/label';
import { Section } from '../../common';
import Selector from '../../../form/select/selector';
import Button from '../../../global/button/button';
import DOMPurify from 'dompurify';
import { SelectOptionType } from '../../../service/types';
import CommunityPost from '../components/communityPost';
import { stateFromHTML } from 'draft-js-import-html';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EMBED, ME, POSTS } from '../../../service/queryKeys';
import { getEmdedMetadData, getMe, postPost, putPost } from '../../../service/api';
import Choice from '../../../form/choice/choice';
import { toast } from 'react-toastify';
import { LinkStyled } from '../../../global/link/link.styled';
import { ActionButton } from '../../../global/buttonIcon/common';
import Datepicker from '../../../form/datepicker/datepicker';
import dayjs from 'dayjs';
import classnames from 'classnames';

const NewCommunityPost = ({
  editMode,
  postData,
  setCancelEdit,
}: {
  editMode?: boolean;
  postData?: any;
  setCancelEdit?: any;
}) => {
  const queryClient = useQueryClient();

  const addCommunityPost = useMutation(postPost, {
    onSuccess: () => {
      queryClient.invalidateQueries(POSTS);
    },
  });

  const updateCommunityPost = useMutation(putPost, {
    onSuccess: () => {
      queryClient.invalidateQueries(POSTS);
    },
  });

  const { data: me } = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
  });

  const authors: Array<CommunityPostAuthorType> = [
    {
      author_id: 'author1',
      author_role: 'Head of Teaching & Learning',
      author_name: 'C.J. Rauch, EBE',
      author_avatar_url:
        'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/CJ.png',
    },
    {
      author_id: 'author2',
      author_role: 'Director of Education at EBE',
      author_name: 'Stuart Kime',
      author_avatar_url:
        'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/Stuart.png',
    },
    {
      author_id: 'author3',
      author_role: 'GTT Advisor at Evidence Based Education',
      author_name: 'Hannah Bickerdike',
      author_avatar_url:
        'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/Hannah.png',
    },
  ];

  const SCHEMA = Yup.object().shape({
    title: Yup.string().required(),
    author_name: Yup.string().required(),
    author_avatar_url: Yup.string().required(),
    author_role: Yup.string().required(),
  });

  const sanitizeContent = (content: any) =>
    content &&
    DOMPurify.sanitize(
      content
        .replaceAll(/<ul>/g, '<ul class="list-disc ml-8">')
        .replaceAll(/<ol type="1">/g, '<ol class="list-decimal ml-8">')
        .replaceAll(/<ol type="a">/g, '<ol class="list-disc ml-8">')
        .replaceAll(/<img/g, '<img class="max-w-sm max-h-96"'),
      { ALLOWED_ATTR: ['class', 'src', 'href', 'target', 'rel', 'style'] }
    );

  type MyObjectType = {
    [key: string]: string;
  };

  const contentState = stateFromHTML(postData?.content);

  const isDraft = editMode ? postData.draft : false;

  const [previewState, setPreviewState] = useState<boolean>(false);
  const [editEmbed, setEditEmbed] = useState<boolean>(false);
  const [embedLinkError, setEmbedLinkError] = useState<boolean>(false);

  const [embedType, setEmbedType] = useState<string>('');
  const [embedLink, setEmbedLink] = useState<string>('');
  const [fetch, setFetch] = useState<string>('');
  const [embed, setEmbed] = useState<MyObjectType>({});

  const [editorState, setEditorState] = useState<EditorState | any>(() =>
    editMode ? EditorState.createWithContent(contentState) : EditorState.createEmpty()
  );
  const [convertedContent, setConvertedContent] = useState<any>(null);

  const { data: fetchedData, refetch, isFetching } = useQuery([EMBED, fetch], () => getEmdedMetadData(fetch), {
    select: (data) => data.data.data,
    placeholderData: null,
    keepPreviousData: false,
    enabled: !!fetch,
  });

  useEffect(() => {
    let isMounted = true;
    if (fetch) refetch();
    return () => {
      isMounted = false;
    };
  }, [fetch]);

  useEffect(() => {
    let isMounted = true;
    if (fetchedData)
      setEmbed({
        author: fetchedData?.author ?? '',
        created_date: fetchedData?.created_at ?? '',
        description: fetchedData?.description ?? '',
        image_url: fetchedData?.image_url ?? '',
        provider_image: fetchedData?.provider_image_url ?? '',
        provider_name: fetchedData?.provider_name ?? '',
        title: fetchedData?.title ?? '',
        type: embedType ?? '',
        url: embedLink ?? '',
      });
    return () => {
      isMounted = false;
    };
  }, [fetchedData]);

  useEffect(() => {
    const currentContentAsHTML = convertToHTML({
      entityToHTML: (entity, originalText) => {
        if (entity.type === 'IMAGE') {
          return `<img src="${entity.data.src}" />`;
        }
        if (entity.type === 'LINK') {
          return `<a style="color: #cd5413" class='underline' href="${entity.data.url}" target="_blank" rel="noopener noreferrer">link</a>`;
        }
        return originalText;
      },
    })(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }, [editorState]);

  const embedOptions: Array<{ label: string; value: string }> = [
    { label: 'Blog', value: 'blog' },
    { label: 'Video', value: 'video' },
  ];

  const handleInputChange = (key: string, value: string) => {
    setEmbed((prevState: any) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const countCharacters = (text: string) => {
    let cont = text;
    cont = cont?.replace(/<[^>]*>/g, ' ');
    cont = cont?.replace(/\s+/g, ' ');
    cont = cont?.trim();
    const n = cont?.length;
    return <p className={classnames({ 'text-red-500': n > 1000 })}>{n}/1000</p>;
  };

  return (
    <>
      <Section headline={'Add new community post'}>
        <Formik
          validationSchema={SCHEMA}
          initialValues={{
            id: editMode ? postData.id : '',
            title: editMode ? postData.title : '',
            author_id: me ? me.id : '',
            author_name: editMode ? postData.author_name : '',
            author_avatar_url: editMode ? postData.author_avatar_url : '',
            author_role: editMode ? postData.author_role : '',
            content: editMode ? postData.content : editorState,
            created_at: editMode ? postData.created_at : '',
            draft: editMode ? postData.draft : false,
            embeds: editMode ? (postData.embeds != null ? postData.embeds : []) : [],
            attachments: editMode ? (postData.attachments != null ? postData.attachments : []) : [],
            tags: editMode ? postData.tags : [],
          }}
          isInitialValid={editMode}
          onSubmit={(data) => {
            editMode
              ? (updateCommunityPost
                  .mutateAsync(data)
                  .then(() => toast.success('Post updated'))
                  .catch((error) => {
                    toast.error(error.response.data.message ?? 'An error has occurred.');
                  }),
                setCancelEdit(true))
              : (addCommunityPost
                  .mutateAsync(data)
                  .then(() => toast.success(data.draft ? 'Post saved' : 'Post added'))
                  .catch((error) => {
                    toast.error(error.response.data.message ?? 'An error has occurred.');
                  }),
                setCancelEdit(false));
          }}
        >
          {({ values, setFieldValue, handleSubmit, isValid, isSubmitting }) => (
            <form className={'py-5'} onSubmit={handleSubmit}>
              <div className={'field-mb'}>
                {editMode && (
                  <div className="flex">
                    <span className="my-auto ml-auto bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-red-400">
                      Edit mode
                    </span>
                    <Button size={'lg'} className={'ml-4'} onClick={() => setCancelEdit(true)}>
                      Cancel edit
                    </Button>
                  </div>
                )}
                <div className={'field-mb'}>
                  <Selector
                    label={'Author'}
                    id={'community_post_author_input'}
                    type={'text'}
                    placeholder={'Author'}
                    required
                    options={authors.map((author: any) => ({ label: author.author_name, value: author.author_name }))}
                    value={editMode ? { label: values.author_name, value: values.author_name } : undefined}
                    onChange={(options: any) => {
                      let authorInfo;
                      options == null
                        ? (setFieldValue('author_name', ''),
                          setFieldValue('author_avatar_url', ''),
                          setFieldValue('author_role', ''))
                        : ((authorInfo = authors.find(
                            (author: CommunityPostAuthorType) => author.author_name === options.value
                          )),
                          setFieldValue('author_name', authorInfo?.author_name),
                          setFieldValue('author_avatar_url', authorInfo?.author_avatar_url),
                          setFieldValue('author_role', authorInfo?.author_role));
                    }}
                    isClearable={false}
                  />
                </div>
                <div className={'field-mb'}>
                  <Input
                    maxLength={125}
                    label={'Title'}
                    id={'community_post_title_input'}
                    type={'text'}
                    placeholder={'Title'}
                    required
                    value={values.title}
                    onChange={(option) => {
                      setFieldValue('title', option.target.value);
                    }}
                  />
                </div>
                <div className={'field-mb min-h-fit'}>
                  <Label
                    id={'community_post_content_input_label'}
                    label={'Content'}
                    required={true}
                    hintText={countCharacters(sanitizeContent(convertedContent))}
                  />
                  <span className={'text-sm text-warning'}>
                    WARNING! Please don&apos;t use the{' '}
                    <u>
                      <em>Code</em>
                    </u>{' '}
                    option in the dropdown below!
                  </span>
                  <Editor
                    wrapperStyle={{ minHeight: '500px', maxHeight: '500px', backgroundColor: 'white' }}
                    wrapperClassName={'border border-black rounded'}
                    toolbarClassName={'m-4'}
                    editorStyle={{ minHeight: '400px', maxHeight: '400px' }}
                    editorClassName={'m-4'}
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    placeholder={'Content'}
                    toolbar={{
                      options: ['inline', 'blockType', 'list', 'image', 'link', 'emoji'],
                      inline: {
                        options: ['bold', 'italic', 'underline', 'monospace'],
                        bold: { className: 'bordered-option-classname' },
                        italic: { className: 'bordered-option-classname' },
                        underline: { className: 'bordered-option-classname' },
                        code: { className: 'bordered-option-classname' },
                      },
                      link: {
                        options: ['link'],
                      },
                    }}
                  />
                </div>
                <div className={'field-mb'}>
                  <Label id={'community_post_tags_input_label'} label={'Tags'} />
                  <Selector
                    id={'community_post_tags_input'}
                    placeholder={'Add tag(s)'}
                    value={values.tags?.map((value: any) => {
                      {
                        return {
                          label: value,
                          value,
                        };
                      }
                    })}
                    onChange={(options: any) =>
                      setFieldValue(
                        'tags',
                        options == null ? [] : options.map((option: SelectOptionType) => option.value)
                      )
                    }
                    isCreatableSelect
                    isMulti
                    isClearable
                  />
                </div>
                {!editEmbed && (
                  <div className={'field-mb'}>
                    <Selector
                      placeholder={'Select the embed type'}
                      label={'Add a new embed'}
                      id={'community_post_add_embed_link'}
                      value={embedOptions.find((option) => option.value === embedType) ?? null}
                      options={embedOptions}
                      onChange={(options: any) => {
                        options == null ? setEmbedType('') : setEmbedType(options.value);
                      }}
                      isClearable
                    />
                  </div>
                )}
                {embedType != '' && (
                  <div className={'field-mb'}>
                    <Input
                      label={'Embed link'}
                      id={'community_post_embed_input'}
                      type={'text'}
                      placeholder={'Embed link'}
                      value={embedLink}
                      onChange={(option) => {
                        setEmbedLinkError(false);
                        option.target.value == null
                          ? setEmbedLink('')
                          : values.embeds.map((embed: any) => embed.url).includes(option.target.value)
                          ? (setEmbedLinkError(true), setEmbedLink(option.target.value))
                          : setEmbedLink(option.target.value);
                      }}
                    />
                    {embedLinkError && <p className={'my-4 text-red-500'}>This link has already been added</p>}
                    <Button
                      disabled={
                        embedLinkError ||
                        embedLink === '' ||
                        Object.values(embed).filter((value: any) => value != undefined).length > 2
                      }
                      className={'m-2'}
                      onClick={() => {
                        setFetch(embedLink);
                      }}
                    >
                      {Object.values(embed).filter((value: any) => value != undefined)?.length > 2
                        ? 'Fetched'
                        : isFetching
                        ? 'Fetching...'
                        : 'Fetch data'}
                    </Button>
                    <>
                      {/*TODO change 0 to 1 once working*/}
                      <Button
                        disabled={Object.values(embed).filter((value: any) => value != undefined)?.length < 3}
                        className={'m-2'}
                        onClick={() => {
                          const newArray = [...values.embeds];
                          newArray.push(embed);
                          setFieldValue('embeds', newArray);
                          setEmbedType(''), setEmbedLink(''), setEmbed({}), setFetch('');
                        }}
                      >
                        Save embed
                      </Button>
                      <Button
                        className={'m-2'}
                        onClick={() => {
                          setEmbedType(''), setEmbedLink(''), setEmbed({}), setFetch('');
                        }}
                      >
                        Cancel
                      </Button>
                    </>
                  </div>
                )}
                {values.embeds?.length > 0 && (
                  <div className={'field-mb'}>
                    <Label id={'community_post_embeds'} label={'Your embedded links'} />
                    <div className={'mt-4'}>
                      {values.embeds.map((valueEmbed: any, key: number) => {
                        return (
                          <React.Fragment key={key}>
                            <div className={'grid grid-cols-3'}>
                              <div className={'col-span-2'}>
                                <LinkStyled href={valueEmbed.url} target={'_blank'}>
                                  <p className={'break-words'}>{valueEmbed.url}</p>
                                </LinkStyled>
                              </div>
                              <div className={'flex'}>
                                <>
                                  <ActionButton.Edit
                                    disabled={embedType != '' || (editEmbed && embed.url != valueEmbed.url)}
                                    onClick={() => {
                                      setEditEmbed(!editEmbed);
                                      setEmbed(valueEmbed);
                                    }}
                                  />
                                  <ActionButton.Remove
                                    disabled={embedType != '' || editEmbed}
                                    onClick={() => {
                                      setFieldValue(
                                        'embeds',
                                        values.embeds.filter((o: any) => o.url !== valueEmbed.url)
                                      );
                                    }}
                                  />
                                </>
                              </div>
                            </div>
                            {editEmbed && embed.url === valueEmbed.url && (
                              <div>
                                {Object.entries(embed).map(([key, value]: [string, string]) => {
                                  if (key === 'created_date')
                                    return (
                                      <div className={'gap-2 my-4'} key={key}>
                                        <Datepicker
                                          label={key}
                                          id={`community_post_embed_${key}`}
                                          selected={dayjs(value ?? undefined).toDate()}
                                          value={dayjs(value ?? undefined).format('DD/MM/YYYY')}
                                          onChange={(date: Date) => {
                                            handleInputChange(key, dayjs(date).format('YYYY/MM/DD'));
                                          }}
                                        />
                                      </div>
                                    );
                                  else if (key === 'type')
                                    return (
                                      <div className="gap-2 my-4" key={key}>
                                        <Selector
                                          label={key}
                                          id={`community_post_embed_${key}`}
                                          value={embedOptions.find((option) => option.value === value) ?? undefined}
                                          options={embedOptions}
                                          onChange={(options: any) => {
                                            handleInputChange(key, options.value);
                                          }}
                                        />
                                      </div>
                                    );
                                  else if (['created_at', 'id'].includes(key)) return;
                                  else
                                    return (
                                      <div className={'gap-2 my-4'} key={key}>
                                        <Input
                                          maxLength={key === 'title' ? 110 : key === 'description' ? 150 : undefined}
                                          label={key}
                                          id={`community_post_embed_${key}`}
                                          type={key === 'description' ? 'textarea' : 'text'}
                                          value={value ?? undefined}
                                          onChange={(event) => {
                                            handleInputChange(key, event.target.value);
                                          }}
                                        />
                                      </div>
                                    );
                                })}
                                <div className={'flex'}>
                                  <div className={'m-auto'}>
                                    <Button
                                      className={'m-2'}
                                      onClick={() => {
                                        setFieldValue(`embeds.${key}`, embed);
                                        setEditEmbed(false);
                                      }}
                                    >
                                      Update values
                                    </Button>
                                    <Button
                                      className={'m-2'}
                                      onClick={() => {
                                        setEditEmbed(false);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              {(!editMode || isDraft) && (
                <div className={'field-mb'}>
                  <Choice
                    type={'checkbox'}
                    id={'community_post_save_as_draft'}
                    name={'Save as draft'}
                    label={'Save as draft'}
                    checked={values.draft}
                    onChange={() => setFieldValue('draft', !values.draft)}
                  />
                </div>
              )}
              <div className={'flex'}>
                <div className={'m-auto'}>
                  <Button
                    disabled={!isValid || sanitizeContent(convertedContent)?.length < 8}
                    onClick={() => setPreviewState(!previewState)}
                    className={'m-2'}
                  >
                    Preview
                  </Button>

                  <Button
                    disabled={!isValid || sanitizeContent(convertedContent)?.length < 8 || editEmbed}
                    className={'m-2'}
                    type="submit"
                    onClick={() => {
                      setPreviewState(false);
                      setFieldValue('content', sanitizeContent(convertedContent));
                      values.embeds.length === 0 && setFieldValue('embeds', null);
                      values.attachments.length === 0 && setFieldValue('attachments', null);
                    }}
                  >
                    {editMode && !isDraft
                      ? 'Save changes'
                      : (!editMode && values.draft) || (editMode && isDraft && values.draft)
                      ? 'Save as a draft'
                      : 'Publish'}
                  </Button>
                  {editMode && (
                    <Button size={'lg'} className={'m-2'} onClick={() => setCancelEdit(true)}>
                      Cancel edit
                    </Button>
                  )}
                </div>
              </div>
              {previewState && (
                <div className={`xl:w-3/4 2xl:w-2/3 m-auto mt-24 ${editMode && 'w-full 2xl:w-full'}`}>
                  <CommunityPost
                    post={{
                      id: `${Math.random()}`,
                      title: values.title,
                      author_name: values.author_name,
                      author_avatar_url: values.author_avatar_url,
                      author_role: values.author_role,
                      content: sanitizeContent(convertedContent),
                      created_at: new Date().toJSON(),
                      embeds: values.embeds.length > 0 ? values.embeds : [],
                      attachments: values.attachments.length > 0 ? values.attachments : [],
                      tags: values.tags?.length > 0 ? values.tags : [],
                    }}
                  />
                </div>
              )}
            </form>
          )}
        </Formik>
      </Section>
    </>
  );
};

export type CommunityPostAuthorType = {
  author_id?: string;
  author_avatar_url: string;
  author_name: string;
  author_role: string;
};
export default NewCommunityPost;
