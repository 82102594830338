import { ColumnType } from '../../global/table/table.types';

export interface WebinarType {
  id: string;
  title: string;
  url: string;
  webinar_id: string;
  passcode: string;
  started_at: Date;
  description?: string;
  roles?: Array<string>;
  enabled?: boolean;
}

export const WEBINAR_TABLE_COLUMNS: ColumnType[] = [
  { id: 'title', label: 'Title', isSortable: true },
  { id: 'url', label: 'Link' },
  { id: 'webinar_id', label: 'Webinar Id' },
  { id: 'started_at', label: 'Date time', isSortable: true },
  { id: 'description', label: 'Description' },
  { id: 'actions', label: 'Actions' },
];

export interface AddWebinarProps {
  webinar?: WebinarType;
  update?: boolean;
}
