import React, { useEffect, useRef, useState } from 'react';
import Alert from '../../../global/alert/alert';
import Button from '../../../global/button/button';
import Input from '../../../form/input/input';
import Icon from '../../../global/icon/icon';
import SubscriptionPrice from './components/SubscriptionPrice';
import * as Yup from 'yup';
import { ROLE_MAT_COORDINATOR, ROLE_SCHOOL_COORDINATOR } from '../../../resources/roles';
import { Step3Type, useRegistrationContext } from '../../../state/registration/provider';
import { useQuery } from 'react-query';
import { getCouponDiscount, getPlanTiers, postCheckSubscription } from '../../../service/api';
import { CardComponent, CardCVV, CardExpiry, CardNumber } from '@chargebee/chargebee-js-react-wrapper';
import { getBaseChargebeeStyle } from '../../../helpers/styles';
import { round } from 'lodash';
import { COUNTRIES } from '../../../resources/countries';
import { GLOBAL_ICONS, PAYMENT_OPTIONS } from '../../../resources/vars';
import Selector from '../../../form/select/selector';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import { useNavigate } from 'react-router-dom';
import routeBuilder from '../../../service/routeBuilder';
import { CHECK_SUBSCRIPTION, PLAN_TIERS } from '../../../service/queryKeys';
import { LinkStyled } from '../../../global/link/link.styled';
import { toast } from 'react-toastify';
import { postcodeValidator, postcodeValidatorExistsForCountry } from 'postcode-validator';
import Datepicker from '../../../form/datepicker/datepicker';
import dayjs from 'dayjs';
import ReCAPTCHA from 'react-google-recaptcha';
import { ROLES_WITHOUT_ADDITIONAL_INFO } from './RegistrationPage';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormObserver from './FormObserver';

const Step3 = () => {
  type CouponType = {
    code: string;
    discount: null | number;
    type: string;
    error: string;
  };

  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);
    setStep('step4', { captcha: null });
  }, []);

  const navigate = useNavigate();
  const windowTyped: any = window;
  const {
    step1,
    step2,
    step3,
    step4,
    initialPage,
    cleanState,
    previousPage,
    nextPage,
    setState,
    submitForm,
    setStep,
  } = useRegistrationContext();
  const [step3Values] = useState<Step3Type>({ ...(step3 as Step3Type) });
  const [isLoading, setIsLoading] = useState(false)

  const cardRef = useRef<any>();
  const [coupon, setCoupon] = useState<CouponType>({ code: '', discount: null, type: '', error: '' });

  const [clickPay, setClickPay] = useState(false);

  const subscription = useQuery([CHECK_SUBSCRIPTION, step2!.email, step1!.role!], () =>
    postCheckSubscription(step2!.email, step1!.role!)
  );

  const planTiers = useQuery(PLAN_TIERS, () => getPlanTiers(Number(step1?.role !== ROLE_SCHOOL_COORDINATOR)), {
    select: (data) => data.data.data,
  });

  const getPrice = (numUsers: number, coupon?: CouponType) => {
    if (!planTiers.isFetched) {
      return 0;
    }

    let price;

    if (planTiers.data?.tiers) {
      const tier = planTiers.data?.tiers.find(
        (tier: any) => numUsers >= tier.starting_unit && (tier.ending_unit ? numUsers <= tier.ending_unit : true)
      );

      price = tier.price;
    } else {
      price = planTiers.data?.price;
    }

    if (!coupon) {
      return price / 100;
    }

    if ('percentage' === coupon.type) {
      const percentageDiscounted = (price * (coupon.discount ?? 0)) / 100;

      return (price - percentageDiscounted) / 100;
    }

    return (price - (coupon.discount ?? 0)) / 100;
  };

  const SCHEMA = Yup.object().shape({
    teachers: Yup.number()
      .nullable()
      .test('is-teachers-required', 'This field is required', (value) => {
        if (ROLE_MAT_COORDINATOR !== step1?.role && ROLE_SCHOOL_COORDINATOR !== step1?.role) {
          return true;
        }

        return null != value;
      })
      .max(200, 'Please contact EBE directly for a subscription greater than 200 teachers.'),
    coupon_code: Yup.string().nullable(),
    payment: Yup.string().required(),
    first_name: Yup.string().required().label('First name'),
    email: Yup.string().email().max(180).required().label('Email'),
    last_name: Yup.string().required().label('Last name'),
    address_1: Yup.string().required().label('Billing address'),
    address_2: Yup.string().label('Address'),
    city: Yup.string().label('City'),
    country: Yup.string().required().label('Country'),
    postcode: Yup.string()
      .label('Postcode')
      .required()
      .test('is-postcode-valid', 'Invalid postcode', (value, testContext) => {
        if (!testContext.parent.country || !postcodeValidatorExistsForCountry(testContext.parent.country)) {
          return true;
        }

        return postcodeValidator(value ?? '', testContext.parent.country);
      }),
    start_date: Yup.string().nullable(),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(SCHEMA),
    defaultValues: {
      teachers: step3Values?.teachers ?? null,
      coupon_code: step3Values?.coupon_code ?? null,
      payment: step3Values?.payment ?? '',
      first_name: step3Values?.first_name ?? '',
      last_name: step3Values?.last_name ?? '',
      email: step3Values?.email ?? '',
      address_1: step3Values?.address_1 ?? '',
      address_2: step3Values?.address_2 ?? '',
      city: step3Values?.city ?? '',
      country: step3Values?.country ?? '',
      postcode: step3Values?.postcode ?? '',
      start_date: step3Values?.start_date ?? null,
    },
  });

  useEffect(() => {
    windowTyped.Chargebee.init({
      site: process.env.REACT_APP_CHARGEBEE_SITE,
      publishableKey: process.env.REACT_APP_PUBLISHABLE_KEY,
    });
  }, []);

  if (subscription.isLoading) {
    return (
      <div>
        <div className={'flex p-5'}>
          <div className={'m-auto'}>
            <Icon
              icon={'ArrowRepeat'}
              className={'animate-spin text-primary mx-auto mb-5'}
              elementSize={40}
              container={false}
            />
            <div className={'w-6/12 italic text-center text-muted mx-auto'}>
              <p>
                Please wait while we verify if your email is associated to a valid Great Teaching Toolkit subscription.
              </p>
              <p className={'font-bold'}>This can take a few seconds.</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (null != subscription.data?.data.data.max_teachers) {
    return (
      <div>
        <div className={'animate-fade-in'}>
          <div className={'mb-5'}>
            <Alert title={`That's great!`} type={'success'}>
              We have successfully verified your email account is associated to a valid Great Teaching Toolkit
              subscription.
            </Alert>
          </div>
          <p className={'mb-5'}>Continue your registration process clicking on the &apos;Continue&apos; button.</p>
          {ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) && (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
              onChange={(token) => setStep('step4', { captcha: token })}
              onErrored={() => setStep('step4', { captcha: null })}
              onExpired={() => setStep('step4', { captcha: null })}
            />
          )}
          <div className={`md:grid grid-flow-col ${initialPage < 3 ? 'grid-cols-2' : 'grid-cols-1'} grid-rows-1 gap-5`}>
            {initialPage < 3 && (
              <Button className={'mt-5'} type="button" onClick={() => previousPage()}>
                Back
              </Button>
            )}
            <Button
              disabled={ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) && !step4?.captcha}
              className={'mt-5'}
              onClick={() => {
                if (ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role)) {
                  submitForm(undefined, { step3: { teachers: subscription.data?.data.data.max_teachers as number } });
                  return;
                }

                setState({
                  step3: { teachers: subscription.data?.data.data.max_teachers as number },
                  currentPage: 4,
                });
              }}
            >
              Continue
            </Button>
          </div>
          {ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) && (
            <div className={'pt-10'}>
              <Alert type={'info'}>
                Having trouble completing this form? Please{' '}
                <LinkStyled href={'mailto:support@evidencebased.education?subject=Subject'} target={'_blank'}>
                  contact us here
                </LinkStyled>{' '}
                to help!
              </Alert>
            </div>
          )}
        </div>
      </div>
    );
  }

  if (ROLE_MAT_COORDINATOR === step1?.role) {
    return (
      <div>
        <div className={'animate-fade-in'}>
          <div className={'mb-5'}>
            <Alert type={'info'}>
              MAT-level subscriptions are organised by EBE directly. Please{' '}
              <LinkStyled href="mailto:enquiries@evidencebased.education?subject=GTT MAT/group enquiry">
                contact EBE
              </LinkStyled>{' '}
              for a bespoke quote for your MAT.
            </Alert>
          </div>
          <Button
            className={'w-full my-5'}
            onClick={() => {
              cleanState();
              navigate(routeBuilder('login').route);
            }}
          >
            Return to login page
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={'animate-fade-in'}>
        <form className={'py-5'} onSubmit={handleSubmit((values) => {
          setIsLoading(true)
          setClickPay(true);
          setTimeout(() => setClickPay(false), 2500);
          if (PAYMENT_OPTIONS.CARD_OPTION === values.payment) {
            cardRef?.current
              ?.tokenize()
              .then((data: any) => {
                if (ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role)) {
                  submitForm(() => setIsLoading(false), { step3: { card_token: data.token } });
                  return;
                }

                setState({
                  step3: {
                    ...values,
                    card_token: data.token,
                  },
                  currentPage: 4,
                });
              })
              .finally(() => setIsLoading(false));
          } else {
            if (ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role)) {
              submitForm(() => setIsLoading(false));
              return;
            }

            nextPage();
          }
        })}>
          <FormObserver step={'step3'} values={getValues() as Step3Type}/>
          <h3 className={'field-mb'}>Subscription details</h3>
          {ROLE_SCHOOL_COORDINATOR === step1?.role && (
            <>
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type={'number'}
                      id={'teachers'}
                      label={'Number of teachers'}
                      placeholder={'Please enter the number of teacher users'}
                      required
                      value={field.value ?? ''}
                      min={1}
                      error={errors.teachers?.message}
                    />
                  )}
                  name={`teachers`}
                />
              </div>
            </>
          )}
          <div className={'field-mb'}>
            <Controller
              control={control}
              render={({ field }) => (
                <Datepicker
                  required
                  id={'start_date'}
                  label={'Subscription start date'}
                  placeholder={'Please select a date'}
                  selected={dayjs(field.value ?? undefined).toDate()}
                  minDate={new Date()}
                  value={dayjs(field.value ?? undefined).format('DD/MM/YYYY')}
                  onChange={(date: Date) => {
                    if (dayjs(date).format('YYYY/MM/DD') === dayjs().format('YYYY/MM/DD')) {
                      field.onChange(null);
                    }

                    field.onChange(dayjs(date).format('YYYY/MM/DD'));
                  }}
                  error={errors.start_date?.message}
                />
              )}
              name={'start_date'}
            />
          </div>
          <h3 className={'field-mb'}>Payment options</h3>
          <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
            <div className={'field-mb'}>
              <Input
                id={'coupon'}
                label={'Coupon'}
                placeholder={"Coupon's code"}
                value={coupon.code ?? ''}
                onChange={(e) => {
                  setCoupon({ ...coupon, code: e.target.value });
                }}
                error={coupon.error}
              />
            </div>
            <div className={'field-mb'}>
              <Button
                disabled={0 === coupon.code.trim().length}
                type="button"
                className="mt-5"
                onClick={() => {
                  getCouponDiscount(coupon.code)
                    .then((response) => {
                      toast.success('Coupon code successfully added.');
                      const discount = response.data.data.discount;

                      setCoupon({ ...coupon, discount: discount.value, type: discount.type, error: '' });
                      setValue('coupon_code', coupon.code);
                    })
                    .catch(() => {
                      setValue('coupon_code', null);
                      setCoupon({
                        ...coupon,
                        discount: null,
                        type: '',
                        error: 'Coupon code not recognised, please try again',
                      });
                    });
                }}
              >
                Apply coupon
              </Button>
            </div>
          </div>
          <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
            <div className={'field-mb'}>
              <SubscriptionPrice
                type={'total'}
                value={getPrice(watch('teachers') || 1, coupon)}
                discountReference={null !== coupon.discount ? getPrice(watch('teachers') || 1) : undefined}
              />
            </div>
            {ROLE_SCHOOL_COORDINATOR === step1?.role && watch('teachers') && (
              <div className={'field-mb'}>
                <SubscriptionPrice
                  type={'teacher'}
                  value={round(getPrice(watch('teachers') || 1, coupon) / (watch('teachers') || 1), 2)}
                  discountReference={
                    null !== coupon.discount
                      ? round(getPrice(watch('teachers') || 1) / (watch('teachers') || 1), 2)
                      : undefined
                  }
                />
              </div>
            )}
          </div>
          <div className={'field-mb'}>
            <Controller
              control={control}
              render={({ field }) => (
                <RadioGroupOnBlock
                  idRole={step1?.role}
                  id={'payment'}
                  label={'Payment options'}
                  hideLabel
                  options={[
                    { value: 'card', label: 'Pay by card', icon: GLOBAL_ICONS.card },
                    { value: 'invoice', label: 'Pay by invoice', icon: GLOBAL_ICONS.invoice },
                  ]}
                  handleChange={(value) => field.onChange(value)}
                  optionChecked={field.value}
                />
              )}
              name={`payment`}
            />
          </div>
          {watch('payment') && (
            <div className={'animate-fade-in py-5'}>
              {PAYMENT_OPTIONS.INVOICE_OPTION === watch('payment') && (
                <div className={'field-mb'}>
                  <Alert>
                    After creating a new subscription using this option, the contact you provide will be sent an
                    invoice.
                  </Alert>
                </div>
              )}
              <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
                <div className={'field-mb'}>
                  <Input
                    {...register('first_name')}
                    id={'first_name'}
                    label={'Billing Contact First Name'}
                    placeholder={'Please enter your first name'}
                    required
                    error={errors.first_name?.message}
                  />
                </div>
                <div className={'field-mb'}>
                  <Input
                    {...register('last_name')}
                    id={'last_name'}
                    label={'Billing Contact Last Name'}
                    placeholder={'Please enter your last name'}
                    required
                    error={errors.last_name?.message}
                  />
                </div>
              </div>
              <div className={'field-mb'}>
                <Input
                  {...register('email')}
                  id="email"
                  type={'email'}
                  label={'Email'}
                  placeholder={'Please enter billing email'}
                  required
                  error={errors.email?.message}
                />
              </div>
              <div className={'field-mb'}>
                <div className={'sm-field-mb'}>
                  <Input
                    {...register('address_1')}
                    id={'address_1'}
                    label={'Billing Address'}
                    placeholder={'Please enter your address'}
                    required
                    error={errors.address_1?.message}
                  />
                </div>
                <div>
                  <Input
                    {...register('address_2')}
                    id={'address_2'}
                    label={'Address'}
                    hideLabel
                    placeholder={'(Optional line for address)'}
                    error={errors.address_2?.message}
                  />
                </div>
              </div>
              <div className={'field-mb'}>
                <Input
                  {...register('city')}
                  id={'city'}
                  label={'City'}
                  placeholder={'Please enter your city'}
                  error={errors.city?.message}
                />
              </div>
              <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
                <div className={'field-mb'}>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Selector
                        {...field}
                        id={'country'}
                        label={'Country'}
                        placeholder={'Please select a country'}
                        required
                        value={COUNTRIES.find((country) => country.value === field.value)}
                        options={COUNTRIES}
                        onChange={(option: any) => field.onChange(option.value)}
                        isClearable={false}
                        error={errors.country?.message}
                      />
                    )}
                    name={'country'}
                  />
                </div>
                <div className={'field-mb'}>
                  <Input
                    {...register('postcode')}
                    id={'postcode'}
                    label={'Postcode'}
                    placeholder={'Please enter your postcode'}
                    required
                    error={errors.postcode?.message}
                  />
                </div>
              </div>
              {PAYMENT_OPTIONS.CARD_OPTION === watch('payment') && (
                <div className={'bg-gray-100 pt-8 pb-4 px-5 field-mb'}>
                  <CardComponent ref={cardRef} styles={getBaseChargebeeStyle()}>
                    <div className={'chargebee-field sm-field-mb'}>
                      <CardNumber className={'chargebee-input'}/>
                    </div>
                    <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
                      <div className={'chargebee-field sm-field-mb'}>
                        <CardExpiry className={'chargebee-input'}/>
                      </div>
                      <div className={'chargebee-field sm-field-mb'}>
                        <CardCVV className={'chargebee-input'}/>
                      </div>
                    </div>
                  </CardComponent>
                </div>
              )}
              {'card' === watch('payment') && (
                <div className={'flex '}>
                  <div className="m-auto">
                    <SubscriptionPrice
                      type={'inclVat'}
                      value={
                        step2!.country === 'GB'
                          ? round(1.2 * getPrice(watch('teachers') || 1, coupon), 2)
                          : getPrice(watch('teachers') || 1, coupon)
                      }
                      discountReference={null !== coupon.discount ? getPrice(watch('teachers') || 1) : undefined}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
          {ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) && (
            <>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
                onChange={(token) => setStep('step4', { captcha: token })}
                onErrored={() => setStep('step4', { captcha: null })}
                onExpired={() => setStep('step4', { captcha: null })}
              />
            </>
          )}
          <div
            className={`md:grid grid-flow-col ${initialPage < 3 ? 'grid-cols-2' : 'grid-cols-1'} grid-rows-1 gap-5`}
          >
            {initialPage < 3 && (
              <Button className={'mt-5'} disabled={initialPage >= 3} type="button" onClick={() => previousPage()}>
                Back
              </Button>
            )}
            <Button
              id={`${step1?.role && step1?.role + '_'}submit_${watch('payment')}`}
              disabled={
                clickPay ||
                isLoading ||
                isSubmitting ||
                (ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) && (step4?.captcha ?? '').trim() === '')
              }
              type="submit"
              className="mt-5"
            >
              {isSubmitting || clickPay || isLoading
                ? 'Loading...'
                : 'card' === watch('payment') && step1?.role === ROLE_SCHOOL_COORDINATOR
                  ? 'Pay'
                  : 'invoice' === watch('payment')
                    ? 'Pay by invoice'
                    : 'Continue'}
            </Button>
          </div>
          {ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) && (
            <div className={'pt-10'}>
              <Alert type={'info'}>
                Having trouble completing this form? Please{' '}
                <LinkStyled href={'mailto:support@evidencebased.education?subject=Subject'} target={'_blank'}>
                  contact us here
                </LinkStyled>{' '}
                to help!
              </Alert>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default Step3;
