import React, { useRef, useState } from 'react';
import { capitalize } from '../../helpers/misc';
import Icon from '../../global/icon/icon';
import CaseStudyCard from './caseStudyCard';
import { case_studies, CaseStudyInterface } from '../../resources/caseStudies';

type TabType = 'customerStories';

const ImageCarousel = () => {
  const sliderId = 'carousel_slider';

  const tabId = (tab: TabType) => {
    return `${tab}__default`;
  };

  const container = useRef<HTMLDivElement>(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [draggingAt, setDraggingAt] = useState<number | undefined>(undefined);

  const handleClickOnArrow = (direction: 'left' | 'right'): void => {
    if (container.current) {
      const offset = (direction === 'left' ? -1 : 1) * 200;
      container.current.scrollLeft += offset;
    }
  };

  const scrolling = (): void => {
    if (container.current) {
      const { scrollLeft, clientWidth, scrollWidth } = container.current;

      if (scrollLeft <= 0) {
        setAtStart(true);
      } else {
        setAtStart(false);
      }

      if (scrollLeft + clientWidth >= scrollWidth) {
        setAtEnd(true);
      } else {
        setAtEnd(false);
      }
    }
  };

  const getNavBtn = (direction: 'left' | 'right', size: 'sm' | 'md' = 'md') => {
    const styles = (disabled: boolean) => {
      return disabled ? 'opacity-25 cursor-auto' : 'opacity-75 hover:opacity-100';
    };
    return (
      <button
        type={'button'}
        aria-label={capitalize(direction)}
        onClick={() => handleClickOnArrow(direction)}
        disabled={direction === 'left' ? atStart : atEnd}
        className={direction === 'left' ? styles(atStart) : styles(atEnd)}
      >
        <Icon
          icon={direction === 'left' ? 'ChevronLeft' : 'ChevronRight'}
          elementSize={size === 'md' ? 75 : 40}
          color={'muted'}
        />
      </button>
    );
  };

  return (
    <>
      <h6>How other schools are using the GTT?</h6>
      <div className={'flex xl:hidden justify-between'}>
        <div className={'lg:w-full flex items-center justify-between'}>
          {getNavBtn('left', 'sm')}
          {getNavBtn('right', 'sm')}
        </div>
      </div>
      <div className={'flex items-center'}>
        <div className={'hidden xl:block'}>{getNavBtn('left')}</div>
        <div className={'relative flex-1 overflow-x-auto'}>
          <div className={'border'}>
            <div
              ref={container}
              id={sliderId}
              className={'transition overflow-x-scroll md:mx-5 py-10'}
              style={{
                cursor: dragging ? 'grabbing' : 'pointer',
              }}
              onScroll={() => {
                scrolling();
              }}
              onMouseDown={(event) => {
                if (container.current) {
                  setDragging(true);
                  setDraggingAt(event.pageX - container.current.offsetLeft);
                }
              }}
              onMouseLeave={() => {
                setDragging(false);
              }}
              onMouseUp={() => {
                setDragging(false);
              }}
              onMouseMove={(event) => {
                if (dragging && draggingAt && container.current) {
                  event.preventDefault();
                  const speed = 0.05; // increment this to move faster
                  const x = event.pageX - container.current.offsetLeft;
                  const move = (x - draggingAt) * speed;
                  container.current.scrollLeft -= move;
                }
              }}
            >
              <div className={'flex items-top gap-2 md:gap-5'}>
                <div id={tabId('customerStories')} className={'flex items-top'}>
                  {case_studies.map((case_study: CaseStudyInterface) => (
                    <CaseStudyCard key={case_study.id} caseStudy={case_study} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'hidden xl:block'}>{getNavBtn('right')}</div>
      </div>
    </>
  );
};

export default ImageCarousel;
