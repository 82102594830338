import React from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { StepperProps } from './stepper.types';
import Icon from '../icon/icon';

const ICON_SIZE = 40;

const Stepper: React.FC<StepperProps> = ({ steps, activeStep }: StepperProps) => {
  return (
    <div className={'bg-gray-100 block-container n-block-container'}>
      <div className={'flex flex-row items-start'}>
        {steps.map((step, i) => (
          <div key={`step_${step.index}`} className={'flex-grow relative text-center'}>
            {0 !== i && (
              <StepConnector $size={ICON_SIZE}>
                <Line $isDone={activeStep >= step.index} />
              </StepConnector>
            )}
            <div className={'flex flex-col items-center'}>
              <Icon
                icon={activeStep === step.index ? 'RecordCircle' : activeStep > step.index ? 'CheckCircle' : 'Circle'}
                elementSize={ICON_SIZE}
                className={`mx-auto ${activeStep >= step.index ? 'text-primary' : 'text-muted'}`}
              />
              <p className={`text-sm leading-tight ${classnames({ 'font-bold': activeStep === step.index })}`}>
                {step.title}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const StepConnector = styled.div<{ $size: number }>`
  top: ${(props) => props.$size / 2}px;
  left: calc(-50% + ${(props) => props.$size}px);
  right: calc(50% + ${(props) => props.$size}px);
  position: absolute;
  flex: 1 1 auto;
`;

const Line = styled.div<{ $isDone: boolean }>`
  display: block;
  border-top-style: solid;
  border-radius: 1px;
  border-top-width: 3px;
  border-color: ${(props) => (props.$isDone ? props.theme.colors.primary : '')};
`;

Stepper.displayName = 'Stepper';

export default Stepper;
