import React, { useState } from 'react';
import { Section } from '../../common';
import Accordion from '../../../global/accordion/accordion';
import { useQuery } from 'react-query';
import { MAT_SCHOOLS } from '../../../service/queryKeys';
import { getMatSchools } from '../../../service/api';
import MatSchoolSummary from '../sections/matSchoolSummary';

const MatDashboard = () => {
  const [accordionActive, setAccordionActive] = useState(0);

  const { isLoading, data: matSchoolIds } = useQuery(MAT_SCHOOLS, getMatSchools, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  return (
    <>
      <Section className={'field-mb'}>
        {isLoading
          ? 'Loading...'
          : matSchoolIds?.map((school: any, index: number) => (
              <div key={index}>
                <Accordion
                  title={school.name}
                  isOpen={index === accordionActive}
                  handleOpen={() => setAccordionActive(index)}
                >
                  <MatSchoolSummary schoolId={school.id} />
                </Accordion>
              </div>
            ))}
      </Section>
    </>
  );
};

export default MatDashboard;
