import React, { useState } from 'react';
import { CycleProps } from '../pathwaysPage.types';
import { Section } from '../../common';
import { AddButton } from '../../../global/button/common';
import Button from '../../../global/button/button';
import Empty from '../../../global/empty/empty';
import Accordion from '../../../global/accordion/accordion';
import { MoreInfo } from '../../../global/tooltip/common';
import { SectionInAccordion } from '../components';
import Tag from '../../../global/tag/tag';
import DefaultTable from '../../../global/table/defaultTable';
import useModal from '../../../hooks/useModal';
import { DevelopmentCyclesModal, TaskModal } from '../modals';
import { capitalize } from '../../../helpers/misc';
import { ActionButton } from '../../../global/buttonIcon/common';
import { useQuery } from 'react-query';
import { getDevelopmentCycles } from '../../../service/api';
import { CompleteChoice } from '../../../form/choice/common';
import { CYCLES } from '../../../service/queryKeys';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

const PathwaysCycles = ({
  periodId,
  strategiesOptions,
  disabled,
}: {
  periodId: string;
  strategiesOptions: any;
  disabled?: boolean;
}) => {
  const [accordionOpen, setAccordionOpen] = useState<number | null>();
  const [taskModal, toggleTaskModal, setTaskModalProps] = useModal((props: any) => <TaskModal {...props} />);

  const { data } = useQuery([CYCLES, periodId], () => getDevelopmentCycles(periodId), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    keepPreviousData: true,
  });

  const [cyclesModal, toggleCyclesModal, setCyclesProps] = useModal((props: any) => (
    <DevelopmentCyclesModal {...props} />
  ));

  const toggle = (title = 'Add new cycle', cycle?: any) => {
    setCyclesProps({
      title,
      cycle,
      periodId,
      strategiesOptions,
    });
    toggleCyclesModal(true);
  };

  return (
    <Section
      id={'cycles'}
      headline={'Development Cycles'}
      more={0 < data.length ? `${data.length} cycle${1 !== data.length ? 's' : ''}` : ''}
      className={'field-mb'}
    >
      {cyclesModal}
      {taskModal}
      {data.length === 0 ? (
        <Empty
          element={'development cycles'}
          action={
            <>
              <Button disabled={disabled} onClick={() => toggle()} asLink style={{ fontStyle: 'italic', padding: 5 }}>
                clicking here
              </Button>
            </>
          }
        />
      ) : (
        <React.Fragment>
          <div className={'field-mb'}>
            <AddButton disabled={disabled} onClick={() => toggle()} label={'Cycle'} />
          </div>
          {data?.map((cycle: CycleProps, index: number) => (
            <div key={`cycle_${cycle.id}`}>
              <Accordion
                isOpen={accordionOpen === index}
                handleOpen={() => {
                  if (accordionOpen === index) {
                    setAccordionOpen(null);
                  } else {
                    setAccordionOpen(index);
                  }
                }}
                title={
                  <div className={'flex items-center justify-between w-full'}>
                    <div className={'flex items-center'}>
                      <span>
                        {cycle.start_date}—{cycle.end_date}
                      </span>
                      <div className={'flex items-center gap-2 ml-10'}>
                        {cycle.practice && (
                          <Tag style={{ height: 24 }} mainColor={'muted'} content={'Deliberate practice'} />
                        )}
                        {cycle.notes && <MoreInfo content={cycle.notes} />}
                      </div>
                      <span className={'text-muted italic ml-10'}>
                        {cycle.school_classes.map((schoolClass) => schoolClass.name).join('/')}
                      </span>
                    </div>
                    <div>
                      <Button
                        disabled={disabled}
                        asLink
                        onClick={(event) => {
                          event.stopPropagation();

                          toggle('Edit cycle', {
                            ...cycle,
                            start_date: dayjs(cycle.start_date, 'DD/MM/YYYY').toDate(),
                            end_date: dayjs(cycle.end_date, 'DD/MM/YYYY').toDate(),
                            strategies: cycle.strategies.map((strategy) => strategy.id),
                            school_classes: cycle.school_classes.map((schoolClass) => schoolClass.id),
                          });
                        }}
                        style={{
                          padding: 0,
                        }}
                      >
                        Edit
                      </Button>
                    </div>
                  </div>
                }
              >
                <div className={'flex items-center justify-end field-mb'}>
                  <AddButton
                    disabled={disabled}
                    isOutline
                    mainColor={'secondary'}
                    label={'Task'}
                    onClick={() => {
                      toggleTaskModal(true);
                      setTaskModalProps({
                        title: 'Add new task',
                        description: 'Add task for this cycle',
                        cycle,
                      });
                    }}
                  />
                </div>
                <div className={'md:grid grid-flow-col grid-cols-3 gap-4'}>
                  <SectionInAccordion className={'field-mb md:mb-0'} title={'Strategy'}>
                    <ul className={'list-decimal list-inside'}>
                      {cycle.strategies.map((strategy) => (
                        <li key={`strategy_list--${strategy.id}`} className={'my-2'}>
                          {strategy.name}
                        </li>
                      ))}
                    </ul>
                  </SectionInAccordion>
                  <div className={'col-span-2'}>
                    <DefaultTable
                      id={`cycle_${cycle.id}--tasks`}
                      additionalContainerClasses={'max-h-96 border'}
                      additionalTableClasses={'table--sticky'}
                      itemCounter={{ single: 'task', plural: 'tasks' }}
                      noDataMessage={
                        <>
                          <Button
                            disabled={disabled}
                            onClick={() => {
                              toggleTaskModal(true);
                              setTaskModalProps({
                                title: 'Add new task',
                                description: 'Add task for this cycle',
                                cycle,
                              });
                            }}
                            asLink
                            style={{ fontStyle: 'italic', padding: 5 }}
                          >
                            Click here
                          </Button>{' '}
                          in order to start adding tasks.
                        </>
                      }
                      columns={[
                        { id: 'date', label: 'Date' },
                        { id: 'task', label: 'Task' },
                        { id: 'pie', label: 'PIE' },
                        { id: 'status', label: 'Complete' },
                        { id: 'actions', label: 'Actions' },
                      ]}
                      rows={
                        !cycle.tasks
                          ? []
                          : cycle.tasks.map((task: any, index) => {
                              return {
                                id: `task_row--${index}`,
                                cells: [
                                  { id: 'date', content: task.date },
                                  { id: 'task', content: task.name },
                                  { id: 'pie', content: task.pie ? capitalize(task.pie as string) : null },
                                  {
                                    id: 'status',
                                    class: 'status',
                                    content: (
                                      <CompleteChoice
                                        id={`task_${index}`}
                                        disabled={disabled}
                                        checked={task.completed}
                                      />
                                    ),
                                  },
                                  {
                                    id: 'actions',
                                    class: 'actions',
                                    content: (
                                      <ul className={'actions-list'}>
                                        <li>
                                          <ActionButton.Edit
                                            disabled={disabled}
                                            archived={false}
                                            onClick={() => {
                                              setTaskModalProps({
                                                title: 'Update task',
                                                description: 'Update task for this cycle',
                                                task: {
                                                  ...task,
                                                  date: dayjs(task.date, 'DD/MM/YYYY').toDate(),
                                                },
                                                cycle,
                                              });
                                              toggleTaskModal(true);
                                            }}
                                          />
                                        </li>
                                        <li>
                                          <ActionButton.Remove disabled={disabled} className={'m-auto'} />
                                        </li>
                                      </ul>
                                    ),
                                  },
                                ],
                              };
                            })
                      }
                    />
                  </div>
                </div>
              </Accordion>
            </div>
          ))}
        </React.Fragment>
      )}
    </Section>
  );
};

export default PathwaysCycles;
