import React from 'react';
import Icon from '../../../global/icon/icon';
import { IconType } from '../../../global/icon/icon.types';

type IconCellType = 'class';

type IconCellProps = {
  icon?: IconCellType;
  label: React.ReactNode;
};

const ICONS: Record<'class', IconType> = {
  class: 'PeopleFill',
};

const IconCell = ({ icon = 'class', label }: IconCellProps) => (
  <div className={'flex items-center gap-3'}>
    <Icon icon={ICONS[icon]} color={'primary'} container={false} elementSize={15} />
    <div>{label}</div>
  </div>
);

IconCell.displayName = 'IconCell';

export default IconCell;
