import React from 'react';
import { MARGIN_LEFT_TEXT } from '../../../layout/pdf';
import { View } from '@react-pdf/renderer';
import { PdfViewMetricsType } from '../resources/resources';

const PdfViewMetrics = ({ content, customMarginBottom, customMarginTop, customHeight }: PdfViewMetricsType) => {
  return (
    <>
      <View
        style={{
          marginBottom: customMarginBottom ? customMarginBottom : '8px',
          marginTop: customMarginTop ? customMarginTop : '12px',
          marginLeft: MARGIN_LEFT_TEXT,
          width: '62%',
          height: customHeight ? customHeight : '225px',
          alignSelf: 'center',
          padding: '10px',
          border: '2px',
          borderRadius: '5px',
          borderColor: 'grey',
        }}
      >
        {content}
      </View>
    </>
  );
};

export default PdfViewMetrics;
