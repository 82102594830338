import { useDrag, useDrop } from 'react-dnd';
import React, { memo } from 'react';
import { ListItem } from './index';

type CardProps = {
  id: string;
  index: number;
  moveItem: (draggedId: string, hoverIndex: number) => void;
  findItem: (id: string) => { index: number };
  children: React.ReactNode;
  removeDisabled: boolean;
  handleRemove: () => void;
  type: string;
};

type Item = {
  id: string;
  originalIndex: number;
};

const DragItem = memo(function DragItem({
  id,
  index,
  moveItem,
  children,
  removeDisabled,
  handleRemove,
  type,
  findItem,
}: CardProps) {
  const originalIndex = findItem(id).index;

  const [{ isDragging }, drag] = useDrag(() => ({
      type: type,
      item: { id, originalIndex },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveItem(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveItem]
  );

  const [, drop] = useDrop(() => ({
      accept: type,
      hover({ id: draggedId }: Item) {
        if (draggedId !== id) {
          const { index: overIndex } = findItem(id);
          moveItem(draggedId, overIndex);
        }
      },
    }),
    [findItem, moveItem]
  );

  const opacity = isDragging ? 0 : 1;
  return (
    <div ref={(node) => drag(drop(node))} style={{ opacity }}>
      <ListItem draggable index={index} indexNumber={index} removeDisabled={removeDisabled} handleRemove={handleRemove}>
        {children}
      </ListItem>
    </div>
  );
});

export default DragItem;
