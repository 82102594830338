import React from 'react';
import DefaultTable from '../../../global/table/defaultTable';
import { developmentActivitiesColumns } from '../resources/resources';

const DevelopmentActivitiesTable = ({ data }: { data: any }) => {
  if (data)
    return (
      <DefaultTable
        id={'development-activities-table'}
        columns={developmentActivitiesColumns}
        showCounter={false}
        rows={data.map((data: any) => {
          return {
            id: `${data?.id}`,
            cells: [
              { id: 'type', content: data?.type },
              { id: 'first_name', content: data?.first_name },
              { id: 'last_name', content: data?.last_name },
              { id: 'group_name', content: data?.group_name },
              { id: 'element', content: data?.element },
              { id: 'activity_type', content: data?.activity_type },
              { id: 'activity', content: data?.activity },
              { id: 'start_date', content: data?.start_date },
              { id: 'completed_date', content: data?.completed_date },
            ],
          };
        })}
      />
    );
  else return <>No data!</>;
};

export default DevelopmentActivitiesTable;
