import React, { useState } from 'react';
import Button from '../../../global/button/button';
import AddNotes from '../components/addNotes';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PATHWAYS_V2_STEP } from '../../../service/queryKeys';
import { PREPARE_STEP } from '../views/PathwayV2View';
import { getV2PathwaysStep } from '../../../service/api';
import { Wysiwyg } from '../components/WysiwigEditor';
import V2PathwayStrategies from './V2PathwayStrategies';
import AddResources from '../components/addResources';
import AddReference from '../components/AddReference';
import { VIDEO_OBSERVATION_REFERENCE_TYPE } from '../modals/AddReferenceModal';

const PathwaysPrepareTab = ({
  disabled,
  startDisabled,
  team,
  completed,
  started,
  saveStep,
}: {
  disabled: boolean;
  startDisabled: boolean;
  team?: { id: string; name: string };
  completed: boolean;
  started: boolean;
  saveStep: (data?: { completed_at?: string; challenge?: string; notes?: string }, callback?: () => void) => void;
}) => {
  const { id: pathwaysId } = useParams();

  const { data: pathwaysStep } = useQuery(
    [PATHWAYS_V2_STEP, PREPARE_STEP, pathwaysId],
    () => getV2PathwaysStep(PREPARE_STEP, pathwaysId!),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
    }
  );

  const [editMode, setEditMode] = useState(!completed);

  disabled = disabled || !editMode;

  return (
    <div>
      <div className={'flex flex-col divide-y'}>
        <div className={'font-bold flex pathways-section gap-2 justify-between'}>
          <span className={'w-5/6'}>
            In this step, you can select and adapt a development strategy to use to improve your development focus. You
            may use a pre-existing strategy, one that your coordinator has set up for the whole school, or enter your
            own details.
          </span>
          <Button
            id={`prepare_${completed ? 'edit' : 'start'}_step`}
            size={'md'}
            onClick={() => {
              if (completed) {
                setEditMode(true);
                return;
              }

              saveStep();
            }}
            disabled={startDisabled || (started ? editMode : !editMode)}
          >
            {completed ? 'Edit' : 'Start'} step
          </Button>
        </div>
        <div className={'pathways-section'}>
          <span className={'font-bold py-1 mr-5'}>Development focus:</span>
          <Wysiwyg readOnly={true} value={pathwaysStep?.challenge} />
        </div>
        <div className={'pathways-section'}>
          <AddReference
            disabled={disabled}
            step={PREPARE_STEP}
            options={[VIDEO_OBSERVATION_REFERENCE_TYPE]}
            references={pathwaysStep?.references}
          />
        </div>
        <div className={'pathways-section'}>
          <AddNotes
            disabled={disabled}
            notes={pathwaysStep?.notes}
            save={(notes, callback) => {
              saveStep({ notes }, callback);
            }}
            description={'What adaptations will you need to make the strategy appropriate for your context?'}
          />
        </div>
        <div className={'pathways-section'}>
          <AddResources
            disabled={disabled}
            step={PREPARE_STEP}
            resources={pathwaysStep?.resources}
            team={team}
            description={'Add the development plan you have been working on.'}
            stepPrepare={true}
          />
        </div>
        <div className={'pathways-section'}>
          <V2PathwayStrategies
            team={team}
            disabled={disabled}
            strategies={pathwaysStep?.strategies}
            description={
              'What strategy (strategies) will you select and adapt to address the your development focus? Enter details in the pop-up window.'
            }
          />
        </div>
      </div>
      <div className={'pathways-section w-full text-right'}>
        <Button
          id={'prepareCompleteButton'}
          size={'sm'}
          onClick={() =>
            saveStep({ completed_at: dayjs().format('YYYY/MM/DD') }, () => {
              setEditMode(false);
            })
          }
          disabled={disabled}
        >
          Complete
        </Button>
      </div>
    </div>
  );
};

export default PathwaysPrepareTab;
