import React from 'react';
import { components } from 'react-select';

/**
 * Labels the option as disabled.
 * Use it like this:
 * Option: (props) =>
 * <OptionDisabled {...props} />
 * @param props
 * @constructor
 */
const OptionDisabled = ({ ...props }: any): JSX.Element => {
  return (
    <components.Option {...props}>
      <div className={'flex justify-between'}>
        <div>{props.children}</div>
        <div>{props.isDisabled && '— Disabled'}</div>
      </div>
    </components.Option>
  );
};

export default OptionDisabled;
