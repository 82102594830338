import React from 'react';
import { Section } from '../../common';
import ResourceCard from '../../algolia/components/resourceCard';
import { useQuery } from 'react-query';
import { DRAFT_RESOURCES } from '../../../service/queryKeys';
import { getDraftResources } from '../../../service/api';

const DraftResources = ({ editor = false }: { editor?: boolean }) => {
  const { data: draftResources } = useQuery(DRAFT_RESOURCES, getDraftResources, {
    select: (data) => data.data.data,
    placeholderData: [],
    staleTime: Infinity,
  });

  return (
    <>
      <Section>
        <div>
          <div className={'flex w-full mt-4 gap-8'}>
            <div className={' '}>
              <h4>Unpublished resources:</h4>
              {draftResources && draftResources?.length < 1 ? (
                <>There are no unpublished resources</>
              ) : (
                draftResources?.map((resource: any, index: number) => (
                  <ResourceCard draft admin={true} editor={editor} key={index} resource={resource} preview />
                ))
              )}
            </div>
          </div>
        </div>
      </Section>
    </>
  );
};
export default DraftResources;
