import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import GT_School_EBE_Badge from "@app/asset/images/GT_School_EBE_Badge.png";

const BadgeModal = ({ toggle }: ModalToggleProps) => {

  return (
    <Modal>
      <Modal.Body>
        <img
          src={GT_School_EBE_Badge}
          alt="gtt_ebe_school_badge"
          className={'mx-auto my-auto w-full h-full'}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          label={'Close'}
          onClick={() => {
            toggle(false);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default BadgeModal;
