import Modal from '../../../../global/messages/modal/modal.components';
import ModalComponents from '../../../../global/messages/modal/modal.components';
import React from 'react';
import { ModalProps } from '../../../../global/messages/modal/modal.types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Input from '../../../../form/input/input';
import { AddButton } from '../../../../global/button/common';
import Datepicker from '../../../../form/datepicker/datepicker';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import Selector from '../../../../form/select/selector';
import ButtonIcon from '../../../../global/buttonIcon/buttonIcon';
import { Option } from '../../../../@types/global';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PATHWAYS_V3, V3_PATHWAY_ACTION_TAGS } from '../../../../service/queryKeys';
import { Action } from '../../../../@types/Entity/Action';
import { getV3PathwayActionTags, handleV3PathwayActions } from '../../../../service/api';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { toast } from 'react-toastify';

dayjs.extend(customParseFormat);

export default function V3ActionsModal({ pathway, toggle }: Props) {
  const queryClient = useQueryClient();

  const V3ActionsMutation = useMutation(handleV3PathwayActions, {
    onSuccess: () => {
      toast.success('Action added successfully');
      queryClient.invalidateQueries([PATHWAYS_V3, pathway.id]);
      queryClient.invalidateQueries([V3_PATHWAY_ACTION_TAGS, pathway.id]);
      toggle(false);
    },
  });

  const tagsQuery = useQuery([V3_PATHWAY_ACTION_TAGS, pathway.id], () => getV3PathwayActionTags(pathway.id), {
    select: (data) => data.data.data,
    staleTime: Infinity,
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        actions: Yup.array().of(
          Yup.object().shape({
            title: Yup.string().nullable().required('This field is required'),
            tags: Yup.array(),
            dueDate: Yup.string().nullable().required('This field is required'),
          })
        ),
      })
    ),
    defaultValues: {
      actions: pathway.actions.map((action) => ({
        id: action.id as null | string,
        title: action.title,
        tags: action.tags.map((tag) => ({ label: tag.title, value: tag.id })) as Option[],
        dueDate: action.dueDate as null | string,
      })),
    },
  });

  const actionsFieldArray = useFieldArray({
    control: control,
    name: 'actions',
  });

  return (
    <Modal>
      <form
        onSubmit={handleSubmit((values) => {
          V3ActionsMutation.mutate({
            pathwayId: pathway.id,
            actions: values.actions.map((action) => ({
              id: action.id,
              title: action.title,
              tags: action.tags.map((tagOption) => ({
                id: tagOption.__isNew__ ? null : tagOption.value,
                title: tagOption.label,
              })),
              dueDate: action.dueDate!,
            })),
          });
        })}
      >
        <Modal.Body>
          <div className={'flex justify-end mb-2'}>
            <AddButton
              label={'Add action'}
              isOutline
              mainColor={'muted'}
              size={'xsm'}
              onClick={() => actionsFieldArray.append({ id: null, title: '', tags: [], dueDate: null })}
            />
          </div>
          {actionsFieldArray.fields.map((field, index) => (
            <div key={Math.random()} className={'mb-4 flex'}>
              <div className={'flex-1'}>
                <div className={'flex'}>
                  <div className={'flex-1 border p-2'}>
                    <Input
                      {...register(`actions.${index}.title`)}
                      id={`action_${field.id}_title`}
                      placeholder={'Action'}
                      error={errors.actions?.[index]?.title?.message}
                    />
                  </div>
                </div>
                <div className={'flex'}>
                  <div className={'flex-1 border p-2'}>
                    <Controller
                      control={control}
                      name={`actions.${index}.tags`}
                      render={({ field: { onChange, value } }) => (
                        <Selector
                          id={`action_${field.id}_tags`}
                          placeholder={'Tags'}
                          isMulti
                          isCreatableSelect
                          options={tagsQuery.data?.map((tag) => ({ label: tag.title, value: tag.id }))}
                          onChange={(value: Option[]) => onChange(value)}
                          value={value}
                          error={errors.actions?.[index]?.tags?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={'flex-1 border p-2'}>
                    <Controller
                      control={control}
                      name={`actions.${index}.dueDate`}
                      render={({ field: { onChange, value } }) => (
                        <Datepicker
                          id={`action_${field.id}_dueDate`}
                          label={'Due date'}
                          hideLabel
                          placeholder={'Due date'}
                          onChange={(date: Date) => onChange(dayjs(date).format('YYYY-MM-DD'))}
                          selected={null !== value ? dayjs(value, 'YYYY-MM-DD').toDate() : null}
                          error={errors.actions?.[index]?.dueDate?.message}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>

              <ButtonIcon
                icon={'Trash'}
                size={'xsm'}
                isFree
                mainColor={'muted'}
                label={'Remove'}
                onClick={() => actionsFieldArray.remove(index)}
              />
            </div>
          ))}
        </Modal.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton label={'Save'} type={'submit'} disabled={V3ActionsMutation.isLoading} />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

interface Props extends Pick<ModalProps, 'toggle'> {
  pathway: V3Pathway;
  actions: Action[];
}
