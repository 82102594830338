import React from "react";
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import ReactDatePicker from 'react-datepicker';
import { FieldProps, LabelProps } from "../common/field.types";
import { DatepickerStyled } from "./datepicker.styled";
import Label from "../common/label";
import ErrorFeedback from "../common/error";
import Icon from "../../global/icon/icon";
import { SIZE_ICON, GLOBAL_ICONS, CLASS_PREFIX } from "../../resources/vars";

type Ref = ReactDatePicker;

interface DatepickerProps extends FieldProps, LabelProps, Omit<ReactDatePickerProps, 'id'> {
  /**
   * Input's placeholder used in placeholderText component's prop.
   */
  placeholder?: string;
}

const Datepicker = React.forwardRef<Ref, DatepickerProps>((
  {
    id,
    label,
    hideLabel,
    hintText,
    icon = GLOBAL_ICONS['calendar'],
    error,
    placeholder,
    ...other
  }: DatepickerProps, ref) => (
  <React.Fragment>
    <Label label={label} hideLabel={hideLabel} id={id} required={'required' in other} hintText={hintText}/>
    <div className={'relative'}>
      <div className={'flex absolute z-30 inset-y-0 left-0'}>
        <Icon
          icon={icon}
          container={false}
          elementSize={SIZE_ICON['sm']}
          color={'primary'}
          className={'my-auto mx-3'}
        />
      </div>
      <DatepickerStyled>
        <DatePicker
          autoComplete="off"
          ref={ref}
          id={id}
          name={id}
          className={`${CLASS_PREFIX}--datepicker_input`}
          placeholderText={placeholder}
          todayButton="Today"
          showPopperArrow={false}
          dateFormat={'dd/MM/yyyy'}
          {...(other as ReactDatePickerProps)}
        />
      </DatepickerStyled>
    </div>
    {error && <ErrorFeedback id={id} error={error}/>}
  </React.Fragment>
))

Datepicker.displayName = 'Datepicker';

export default Datepicker;
