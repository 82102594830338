import React from 'react';
import { dimensions, DimensionType } from '../resources/resources';

const ElementSquare = ({
  value,
  type,
  title,
  starterTemplate,
}: {
  value: any;
  type?: string;
  title?: string;
  starterTemplate?: boolean;
}) => {
  return (
    <div className={title ? 'font-bold inline-flex gap-1' : ''} key={Math.random()}>
      <div
        className={`p-2 flex ${
          starterTemplate ? ' h-12 w-12 text-2xl ' : ' h-8 w-8 '
        }justify-center items-center rounded`}
        style={{
          backgroundColor: `${
            dimensions.find((dimension: DimensionType) => dimension.number.toString() === value.charAt(0))?.colour
          }`,
        }}
      >
        <p className={'text-white font-semibold'}>{value}</p>
      </div>
      {type === 'dimension' ? title : title?.split(' ').slice(1).join(' ')}
    </div>
  );
};

export default ElementSquare;
