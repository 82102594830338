import { SizeType, SystemIconType } from './types';
import { IconType } from '../global/icon/icon.types';

export const CLASS_PREFIX = 'gtt';
export const SIZE_HEIGHT: Omit<Record<SizeType, number>, 'xsm'> = {
  sm: 35,
  md: 40,
  lg: 50,
};
export const SIZE_ICON: Omit<Record<SizeType, number>, 'xsm'> = {
  sm: 15,
  md: 20,
  lg: 24,
};
export const PAYMENT_OPTIONS: Record<any, string> = {
  CARD_OPTION: 'card',
  INVOICE_OPTION: 'invoice',
};
export const GLOBAL_ICONS: Record<SystemIconType, IconType> = {
  drag: 'GripVertical',
  empty: 'FolderX',
  open: 'ChevronDown',
  close: 'X',
  card: 'CreditCard',
  invoice: 'Receipt',
  billing: 'ChatSquareDots',
  surveys: 'ClipboardCheck',
  observation: 'Eye',
  primary: 'BrightnessAltLowFill',
  secondary: 'BrightnessAltHighFill',
  plan: 'JournalText',
  implement: 'JournalAlbum',
  evaluate: 'JournalCheck',
  create: 'PlusSquareDotted',
  edit: 'Pencil',
  copy: 'Stickies',
  view: 'BoxArrowUpRight',
  remove: 'Trash',
  archive: 'Archive',
  unarchive: 'ArchiveFill',
  archived: 'Folder',
  enable: 'CheckSquare',
  disable: 'SlashSquare',
  disabled: 'X',
  deactivated: 'PersonX',
  completed: 'CheckCircleFill',
  uncompleted: 'Circle',
  tagged: 'TagFill',
  admin: 'StarFill',
  info: 'InfoSquareFill',
  infoNoFill: 'InfoSquare',
  more: 'ThreeDots',
  calendar: 'Calendar',
  help: 'QuestionCircle',
  logout: 'BoxArrowRight',
  user: 'PersonFill',
  teacher: 'BriefcaseFill',
  student: 'PeopleFill',
  school: 'BriefcaseFill',
  group: 'GridFill',
  join: 'ArrowRightCircle',
  text: 'FileText',
  video: 'FilePlay',
  notifications: 'BellFill',
  envelope: 'Envelope',
  upgrade: 'ArrowUpSquare',
  subscriptions: 'PencilSquare',
  dials: 'Sliders',
  chat: 'ChatRight',
  downloadFile: 'FileEarmarkArrowDown',
  download: 'Download',
  playButton: 'PlayBtn',
  checklist: 'CardChecklist',
  share: 'Share',
  favourite: 'BookmarkHeart',
  favouriteFill: 'BookmarkHeartFill',
  warning: 'ExclamationTriangle',
  save: 'Save',
  book: 'Book',
};
