import React from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import HelpImage from '@app/asset/images/SEL_understand_your_data.png';

const HelpModal = () => {
  return (
    <Modal>
      <Modal.Body>
        <img src={HelpImage} alt={'Understand your data'} width={'100%'} height={'auto'} />
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;
