import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import Button from '../../../global/button/button';

const CopySignupInstructionModal = ({
  newSchools,
  link,
  toggle,
}: ModalToggleProps & {
  newSchools: Array<string>;
  link: string;
}) => {
  return (
    <Modal>
      <Modal.Body>
        <div className={'my-4'}>
          You added {newSchools.length > 1 ? `new schools` : `a new school`}:
          <ul>
            {newSchools.map((school: any) => (
              <li key={Math.random()}>
                <strong className={'italic'}>{school}</strong>
              </li>
            ))}
          </ul>
        </div>
        <p className={'my-2'}>
          {' '}
          Please make sure you copy the signup instructions below and send them to the designated coordinator
          {newSchools.length > 1 ? 's' : ''}
        </p>
        <div className={'flex'}>
          <CopyToClipboard
            text={`<div>
                    <p>As part of our CPD plans for this year, we have access to the Great Teaching Toolkit.</p>
                    <p>You have been assigned as a Coordinator for your school, so here's what you need to do to get started:</p>
                    <ul>
                        <li><strong>Step 1:</strong> Please click on this <a href=${link} target={'_blank'}>link</a> to set up your school's account.</li>
                        <li><strong>Step 2:</strong> Once you have created your account, you'll see a pop-up guide that'll show you around the GTT platform. This guide will also show you how to access the link to share with colleagues so that they can all register with your school and get started.</li>
                    </ul>
                </div>`}
            onCopy={() => toast.success('Registration instructions have been copied')}
            options={{ format: 'text/html' }}
          >
            <Button className={'m-auto mt-4'} isOutline size={'sm'}>
              Copy signup instructions
            </Button>
          </CopyToClipboard>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} />
      </Modal.Footer>
    </Modal>
  );
};

export default CopySignupInstructionModal;
