import Section from '../../common/components/section';
import React from 'react';
import { useQuery } from 'react-query';
import { getMeetingsByGroup } from '../../../service/api/groupsApi';
import { GROUP_MEETINGS } from '../../../service/queryKeys';
import MeetingItem from './MeetingItem';
import { Me } from '../../../@types/Entity/Me';
import dayjs from 'dayjs';
import { TEAMS_MODE_CONTEXT_TEAMS_MODES } from '../../../contexts/TeamsContext/TeamsModeContext';

export default function MeetingsList({ me, group, selectedAcademicYear, teamsMode, isFree }: Props) {
  const getMeetingsByGroupQuery = useQuery([GROUP_MEETINGS], () => getMeetingsByGroup(group));

  const sortedMeetings =
    getMeetingsByGroupQuery.data?.data.sort((a, b) => dayjs(a.scheduledAt).unix() - dayjs(b.scheduledAt).unix()) ?? [];

  const sortedFilteredMeetings = sortedMeetings.filter((meeting) => {
    const scheduledAt = dayjs(meeting.scheduledAt);

    if (
      null !== selectedAcademicYear &&
      !(selectedAcademicYear.original.startDate <= scheduledAt && selectedAcademicYear.original.endDate >= scheduledAt)
    ) {
      return false;
    }

    if (meeting.isArchived) {
      return false;
    }

    return true;
  });

  return (
    <Section size={'md'} className={'mt-5'}>
      <h5>Meetings</h5>

      <div className={'mt-4'}>
        {0 === sortedFilteredMeetings.length && <>This team has no meetings yet.</>}

        {(isFree ? sortedMeetings : sortedFilteredMeetings).map((meeting, index) => (
          <MeetingItem index={index} key={meeting.id} me={me} meeting={meeting} group={group} teamsMode={teamsMode} />
        ))}
      </div>
    </Section>
  );
}

interface Props {
  me: Me | null;
  group: string;
  selectedAcademicYear: Record<string, any>;
  teamsMode: string;
  isFree?: boolean;
}
