import React from 'react';
import DashboardLayout from '../layout/dashboard';
import Alert from '../global/alert/alert';
import SchoolEnvironmentPage from "../pages/instrument/SchoolEnvironmentPage";

const EarlyAccessPage = ({ item }: { item: string }) => {
  switch (item) {
    default:
      return (
        <DashboardLayout>
          <Alert type="info" className={'field-mb'}>
            Sorry, there was an error with the configuration of the early access to this page.
          </Alert>
        </DashboardLayout>
      );
  }
};

export default EarlyAccessPage;
