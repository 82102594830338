import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import { AllColorsType, SizeType } from '../../resources/types';
import { getFocusStyle, getMainColor, getTransparentButtonStyle } from '../../helpers/styles';
import { DEFAULT_THEME } from '../../Theme';
import { SIZE_HEIGHT } from '../../resources/vars';
import { DEFAULT_TRANSITION_DURATION, DEFAULT_TRANSITION_FUNC } from '../transitions/transitions.types';
import { darken } from 'polished';

export const HamburgerButtonStyled = styled('button')<
  { mainColor: AllColorsType; $size: SizeType; $transparent?: boolean } & ThemeProps<DefaultTheme>
>`
  ${getTransparentButtonStyle()}
  border-radius: ${(props) => props.theme.borderRadius.app};
  width: ${(props) => SIZE_HEIGHT[props.$size]}px;
  height: ${(props) => SIZE_HEIGHT[props.$size]}px;
  display: flex;
  transition: all ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC};
  ${(props) =>
    !props.$transparent &&
    `
    &:focus {
    ${getFocusStyle(getMainColor(props))}
  }
    &:hover {
    background: ${props.theme.colors.light};
  }
  `}
`;

HamburgerButtonStyled.defaultProps = {
  theme: DEFAULT_THEME,
};

export const ChevronHamburgerStyled = styled('div')<
  { mainColor: AllColorsType; $size: SizeType; $toggled: boolean } & ThemeProps<DefaultTheme>
>`
  position: relative;
  width: 50%;
  height: 50%;
  margin: auto;

  &:before,
  &:after {
    position: absolute;
    top: ${(props) => (props.$toggled ? '35%' : 'auto')};
    bottom: 0;
    content: '';
    height: 50%;
    width: ${(props) => SIZE_HEIGHT[props.$size] / 15}px;
    border-radius: 0.25rem;
    background: ${(props) => props.theme.colors[props.mainColor]};
    transition: all ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC};
  }

  &:before {
    left: ${(props) => SIZE_HEIGHT[props.$size] / 15}px;
    transform: ${(props) => (props.$toggled ? 'rotate(45deg)' : 'rotate(-45deg)')};
    transform-origin: top left;
    ${(props) => props.$toggled && 'margin-left: 50%;'}
  }

  &:after {
    right: ${(props) => SIZE_HEIGHT[props.$size] / 15}px;
    transform: ${(props) => (props.$toggled ? 'rotate(-45deg)' : 'rotate(45deg)')};
    transform-origin: top right;
    ${(props) => props.$toggled && 'margin-right: 50%;'}
  }
`;

ChevronHamburgerStyled.defaultProps = {
  theme: DEFAULT_THEME,
};

export const MenuHamburgerStyled = styled('div')<
  { mainColor: AllColorsType; $size: SizeType; $toggled: boolean } & ThemeProps<DefaultTheme>
>`
  --base-size: ${(props) => SIZE_HEIGHT[props.$size]}px;
  --component-size: calc(var(--base-size) / 2);
  --bar-height: calc(var(--component-size) / 6);
  --bar-position: calc(var(--bar-height) / -2);
  position: relative;
  margin: auto;
  display: flex;
  width: calc(var(--component-size));
  height: calc(var(--component-size));
  cursor: pointer;
  [data-id='hamburger--bar'] {
    width: 100%;
    transform: rotate(0);
    margin: auto;
    transition: all ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC};
    &,
    &:before,
    &:after {
      border-radius: 9999px;
      height: var(--bar-height);
      background-color: ${(props) => props.theme.colors[props.mainColor]};
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 50%;
      margin: auto;
      transform: rotate(0);
      transition: background-color ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC},
        top ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC},
        left ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC},
        transform ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC};
    }
    &:before {
      top: calc(var(--component-size) / -3);
      left: 0;
    }
    &:after {
      top: calc(var(--component-size) / 3);
      left: 50%;
    }
    &.toggled {
      background-color: transparent;
      height: 0;
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
      &:before,
      &:after {
        top: var(--bar-position);
        left: calc(var(--component-size) / 4);
        transition: background-color ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC},
          top ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC},
          left ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC},
          transform ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC};
      }
    }
  }
  &:hover [data-id='hamburger--bar'] {
    &,
    &:before,
    &:after {
      background-color: ${(props) => darken(0.05, props.theme.colors[props.mainColor])};
    }
  }
`;

MenuHamburgerStyled.defaultProps = {
  theme: DEFAULT_THEME,
};
