import React, { useEffect, useState } from 'react';
import { GLOBAL_ICONS, SIZE_ICON } from '../../resources/vars';
import {
  ROLE_SYS_ADMIN,
  ALL_ROLES,
  RolesType,
  ROLE_LABELS,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLES,
} from '../../resources/roles';
import { ColumnType } from '../../global/table/table.types';
import DashboardLayout from '../../layout/dashboard';
import useModal from '../../hooks/useModal';
import DefaultTable from '../../global/table/defaultTable';
import { ActionButton } from '../../global/buttonIcon/common';
import { ArchivedName } from '../../global/table/components';
import { useQuery } from 'react-query';
import { getMe, getSchoolsList } from '../../service/api';
import { Section } from '../common';
import { ALL_SCHOOLS, ME } from '../../service/queryKeys';
import { MoreInfo } from '../../global/tooltip/common';
import { ViewSubscriptionInfoModal } from '../users/modals';
import SchoolMoreInfoModal from './modals/schoolMoreInfoModal';
import Icon from '../../global/icon/icon';
import { COUNTRIES } from '../../resources/countries';
import Tag from '../../global/tag/tag';
import Search from '../../form/search/search';
import Selector from '../../form/select/selector';

const accountInfo = <MoreInfo content={<>Registered Accounts / Account Subscriptions</>} />;

type RoleType = typeof ROLE_SYS_ADMIN;

type UserColumnType = ColumnType & { forRole?: RoleType[] };

export type UserType = {
  id: string;
  first_name: string;
  last_name: string;
  country: string;
  email: string;
  organisation: string;
  role: RolesType;
  active: boolean;
  accounts_subscription?: number | null;
  accounts_registered: number | null;
  enrollment: number;
  created_at: Date | null;
  notes: any;
  foundation: number | null;
  foundation_two: number | null;
  gtt_profile: number | null;
  element_focus: number | null;
  completed_course: number | null;
  development_goal: number | null;
};

const SchoolManagement = ({ role }: { role: RoleType }): JSX.Element => {
  const [searchValues, setSearchValues] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchCountry, setSearchCountry] = useState('');

  const [order, setOrder] = useState('');
  const [page, setPage] = useState(1);

  useEffect(() => {
    refetch();
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [order, searchValue]);

  const [moreInfoModal, toggleMoreInfoModal, setMoreInfoModalProps] = useModal((props: any) => (
    <SchoolMoreInfoModal {...props} />
  ));

  const [viewSubscriptionModal, toggleViewSubscriptionModal, setViewSubscriptionModalProps] = useModal((props: any) => (
    <ViewSubscriptionInfoModal {...props} />
  ));

  const { data, refetch, isFetching } = useQuery(
    [ALL_SCHOOLS, order, searchValue, page],
    () => getSchoolsList(order, searchValue, page),
    {
      select: (data) => data.data,
      placeholderData: [],
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const COLUMNS: UserColumnType[] = [
    { id: 'organisation', label: 'School name', isSortable: true },
    { id: 'first_name', label: 'First name', isSortable: true },
    { id: 'last_name', label: 'Last name', isSortable: true },
    { id: 'country', label: 'Country', isSortable: true },
    { id: 'email', label: 'Email', isSortable: true },
    { id: 'role', label: 'Role', isSortable: true },
    { id: 'registered_accounts', label: `Registered accounts`, isSortable: true, additionalLabel: accountInfo },
    { id: 'enrollment', label: ' Enrollment' },
    { id: 'foundation_pt1', label: 'Foundation part 1' },
    { id: 'foundation_pt2', label: 'Foundation part 2' },
    { id: 'create_at', label: 'Created at' },
    { id: 'more', label: 'More' },
  ];

  const me = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
  });

  return (
    <React.Fragment>
      {moreInfoModal}
      {viewSubscriptionModal}
      {/* Content */}
      <DashboardLayout
        title={'School Management'}
        subtitle={
          <Tag
            className={'mt-3'}
            mainColor={'info'}
            content={`${ROLE_LABELS[role]}${
              [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(role)
                ? ` - ${me.data.organisation}`
                : ''
            }`}
          />
        }
      >
        <Section>
          <div className={'flex flex-wrap justify-between items-center mb-5'}>
            <div className={'lg:flex lg:flex-row row-auto w-full gap-4 mb-8'}>
              <div className={'lg:w-1/2 w-full mb-3 lg:mb-0'}>
                <Search
                  id={'search_user'}
                  label={'Search User / Organisation'}
                  hideLabel={false}
                  placeholder={'Search for name, email or organisation'}
                  value={searchValues}
                  onChange={(event) => {
                    if (event.target.value.length > 0) {
                      setSearchValues(event.target.value);
                      setSearchValue(event.target.value + '/' + searchCountry);
                    } else if (event.target.value.length < 1 && searchCountry) {
                      setSearchValue('/' + searchCountry);
                      setSearchValues('');
                    } else {
                      setSearchValue('');
                      setSearchValues('');
                    }
                  }}
                  onRemove={() => {
                    if (searchCountry) {
                      setSearchValue('/' + searchCountry);
                    } else {
                      setSearchValue('');
                    }
                    setSearchValues('');
                  }}
                />
              </div>
              <div className={'lg:w-1/4 w-full mb-3 lg:mb-0'}>
                <Selector
                  id={'country'}
                  label={'Select Country'}
                  placeholder={'Please select a country'}
                  value={COUNTRIES.find((country) => country.value === searchCountry)}
                  options={COUNTRIES}
                  onChange={(option: any) => {
                    if (option != null) {
                      setSearchCountry(option?.value);
                      setSearchValue(searchValues + '/' + option?.value);
                    } else {
                      setSearchCountry('');
                      setSearchValue(searchValues + '/');
                    }
                  }}
                  isClearable={true}
                />
              </div>
            </div>
          </div>
          <DefaultTable
            apiHandled={true}
            setApiSort={setOrder}
            id={'schoolManagement'}
            itemCounter={{
              single: 'coordinator',
              plural: 'coordinators',
            }}
            loading={isFetching}
            pagination={{
              currentPage: page,
              onPageChange: ({ selected }: { selected: number }) => setPage(selected + 1),
              total: data?.meta?.total,
              maxPage: data?.meta?.max_page,
            }}
            columns={COLUMNS.filter((col) => {
              if (!col.forRole || (col.forRole && col.forRole.some((colRole) => colRole === role))) {
                return col;
              }
            })}
            filteredBySearch={!!searchValue}
            rows={data?.data?.users?.map((user: any) => {
              return {
                id: `${user.id}`,
                cells: [
                  { id: 'organisation', content: user.organisation },
                  { id: 'fist_name', content: user.first_name },
                  { id: 'last_name', content: user.last_name },
                  {
                    id: 'country',
                    content: COUNTRIES.find((country) => country.value === user.country)?.label,
                  },
                  { id: 'email', content: user.email },
                  { id: 'role', content: ALL_ROLES.find((role) => role.value === user.role)?.label },
                  {
                    id: 'registered_accounts',
                    content: `${user.number_of_teachers + 1} / ${user.slots}`,
                  },
                  {
                    id: 'enrollment',
                    content:
                      user.slots > 0 ? `${Math.round(((user.number_of_teachers + 1) / user.slots) * 100)} %` : '0 %',
                    class: Math.round(((user.number_of_teachers + 1) / user.slots) * 100) < 50 ? ' bg-red-400' : '',
                  },
                  { id: 'foundation_pt1', content: `${user.dails.onboarding} %` },
                  { id: 'foundation_pt2', content: `${user.dails.foundation} %` },
                  { id: 'create_at', content: new Date(user.created_at).toLocaleDateString() },
                  {
                    id: 'more',
                    class: 'actions',
                    content: (
                      <>
                        <ul className={'actions-list'}>
                          <li>
                            <ActionButton.View
                              onClick={() => {
                                setViewSubscriptionModalProps({
                                  user: { ...user },
                                  title: 'View subscription info',
                                  description: (
                                    <ArchivedName
                                      title={`${user.first_name} ${user.last_name} - ${
                                        ROLES.filter((role) => role.value === user.role)[0].label
                                      }`}
                                      item={'user'}
                                    />
                                  ),
                                  preventCloseOnClickOnMask: true,
                                });
                                toggleViewSubscriptionModal(true);
                              }}
                            />
                          </li>
                          <li>
                            <ActionButton.More
                              label={
                                <ul className={'more-actions-list'}>
                                  <li>
                                    <button
                                      onClick={() => {
                                        setMoreInfoModalProps({
                                          user: { ...user },
                                          title: 'Notes',
                                          data: user,
                                          size: 'lg',
                                          moreInfo: 'notes',
                                          userName: me.data,
                                        });
                                        toggleMoreInfoModal(true);
                                      }}
                                    >
                                      <span>
                                        <strong>Notes</strong>
                                      </span>{' '}
                                      <Icon
                                        icon={GLOBAL_ICONS.copy}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                  <li>
                                    <button
                                      onClick={() => {
                                        setMoreInfoModalProps({
                                          user: { ...user },
                                          title: 'Dials',
                                          data: user,
                                          size: 'lg',
                                          moreInfo: 'dials',
                                        });
                                        toggleMoreInfoModal(true);
                                      }}
                                    >
                                      <span>
                                        <strong>Dials</strong>
                                      </span>{' '}
                                      <Icon
                                        icon={GLOBAL_ICONS.dials}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                  <li>
                                    <a href={'mailto:' + user.email + '?body='}>
                                      <button>
                                        <span>
                                          <strong>Contact</strong>
                                        </span>{' '}
                                        <Icon
                                          icon={GLOBAL_ICONS.envelope}
                                          elementSize={SIZE_ICON['sm']}
                                          color={'primary'}
                                          container={false}
                                          className={'ml-3'}
                                        />
                                      </button>
                                    </a>
                                  </li>
                                </ul>
                              }
                            />
                          </li>
                        </ul>
                      </>
                    ),
                  },
                ],
              };
            })}
            noDataMessage={'No users to manage'}
          />
        </Section>
      </DashboardLayout>
    </React.Fragment>
  );
};

export default SchoolManagement;
