import React from 'react';

export default function ConditionalBlock({ isLoading, isError, render }: Props) {
  if (isLoading) {
    return null;
  }

  if (isError) {
    return null;
  }

  return <>{render()}</>;
}

interface Props {
  isLoading?: boolean;
  isError?: boolean;

  render: () => React.ReactNode;
}
