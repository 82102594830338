// GTT PROFILE FEEDBACK PAGE DATA

import { emulatedElementType } from '@app/frontend/src/routes';
import { listOfElements } from '../pages/dashboard/resources/resources';
import { DefaultOptionType } from './types';

export const gttProfileRadarData = (element: emulatedElementType) => {
  const values = [
    { '125': 82, element: '1.1' },
    { '125': 79, element: '1.3' },
    { '125': 75, element: '1.4' },
    { '125': 84, element: '2.1' },
    { '125': 76, element: '2.2' },
    { '125': 86, element: '2.3' },
    { '125': 82, element: '2.4' },
    { '125': 75, element: '3.1' },
    { '125': 76, element: '3.2' },
    { '125': 73, element: '3.3' },
    { '125': 83, element: '4.1' },
    { '125': 85, element: '4.2' },
    { '125': 84, element: '4.3' },
    { '125': 83, element: '4.4' },
    { '125': 86, element: '4.5' },
    { '125': 86, element: '4.6' },
  ];

  return {
    values: values.map((item) => {
      if (item.element === element.value) {
        return { ...item, '125': 53 };
      }
      return item;
    }),
    keys: [{ id: 125, name: 'Great Teaching Profile', dimension: null }],
    colors: ['#ee7324'],
  };
};

export const gttProfileBarData = (element: emulatedElementType) => {
  const originalInfo = [
    {
      element: '1.1 Deep and fluent knowledge',
      color: '#E63224',
      '0.10861720066237579': 82,
      'ci-0.10861720066237579': {
        u: 76,
        l: 88,
      },
      'pse-0.10861720066237579': 74,
    },
    {
      element: '1.3 Relevant curriculum tasks',
      color: '#E63224',
      '0.10861720066237579': 79,
      'ci-0.10861720066237579': {
        u: 73,
        l: 85,
      },
      'pse-0.10861720066237579': 72,
    },
    {
      element: '1.4 Strategies and misconceptions',
      color: '#E63224',
      '0.10861720066237579': 75,
      'ci-0.10861720066237579': {
        u: 69,
        l: 81,
      },
      'pse-0.10861720066237579': 77,
    },
    {
      element: '2.1 Teacher-student relationships',
      color: '#52b18f',
      '0.10861720066237579': 84,
      'ci-0.10861720066237579': {
        u: 78,
        l: 90,
      },
      'pse-0.10861720066237579': 78,
    },
    {
      element: '2.2 Student-student relationships',
      color: '#52b18f',
      '0.10861720066237579': 76,
      'ci-0.10861720066237579': {
        u: 70,
        l: 82,
      },
      'pse-0.10861720066237579': 72,
    },
    {
      element: '2.3 Learner motivation',
      color: '#52b18f',
      '0.10861720066237579': 86,
      'ci-0.10861720066237579': {
        u: 80,
        l: 92,
      },
      'pse-0.10861720066237579': 78,
    },
    {
      element: '2.4 Climate of high expectations',
      color: '#52b18f',
      '0.10861720066237579': 82,
      'ci-0.10861720066237579': {
        u: 76,
        l: 88,
      },
      'pse-0.10861720066237579': 76,
    },
    {
      element: '3.1 Managing time and resources',
      color: '#4a82c3',
      '0.10861720066237579': 75,
      'ci-0.10861720066237579': {
        u: 69,
        l: 81,
      },
      'pse-0.10861720066237579': 70,
    },
    {
      element: '3.2 Rules and consequences',
      color: '#4a82c3',
      '0.10861720066237579': 76,
      'ci-0.10861720066237579': {
        u: 70,
        l: 82,
      },
      'pse-0.10861720066237579': 72,
    },
    {
      element: '3.3 Managing disruptive behaviour',
      color: '#4a82c3',
      '0.10861720066237579': 73,
      'ci-0.10861720066237579': {
        u: 67,
        l: 79,
      },
      'pse-0.10861720066237579': 79,
    },
    {
      element: '4.1 Structuring',
      color: '#594492',
      '0.10861720066237579': 83,
      'ci-0.10861720066237579': {
        u: 77,
        l: 89,
      },
      'pse-0.10861720066237579': 71.64,
    },
    {
      element: '4.2 Explaining',
      color: '#594492',
      '0.10861720066237579': 85,
      'ci-0.10861720066237579': {
        u: 79,
        l: 91,
      },
      'pse-0.10861720066237579': 71.46,
    },
    {
      element: '4.3 Questioning',
      color: '#594492',
      '0.10861720066237579': 84,
      'ci-0.10861720066237579': {
        u: 78,
        l: 90,
      },
      'pse-0.10861720066237579': 73.96,
    },
    {
      element: '4.4 Interacting',
      color: '#594492',
      '0.10861720066237579': 83,
      'ci-0.10861720066237579': {
        u: 77,
        l: 89,
      },
      'pse-0.10861720066237579': 74.91,
    },
    {
      element: '4.5 Embedding',
      color: '#594492',
      '0.10861720066237579': 86,
      'ci-0.10861720066237579': {
        u: 80.71,
        l: 91.95,
      },
      'pse-0.10861720066237579': 71.8,
    },
    {
      element: '4.6 Activating',
      color: '#594492',
      '0.10861720066237579': 86,
      'ci-0.10861720066237579': {
        u: 80.71,
        l: 91.95,
      },
      'pse-0.10861720066237579': 65.69,
    },
  ];
  const searchString = `${element.value} ${element.label}`;
  const updatedInfo = originalInfo.map((item) => {
    if (item.element === searchString) {
      return {
        ...item,
        '0.10861720066237579': 53,
        'ci-0.10861720066237579': {
          u: 47,
          l: 59,
        },
        'pse-0.10861720066237579': 73.96,
      };
    }
    return item;
  });

  return {
    info: updatedInfo,
    dataKeys: [
      {
        id: '0.10861720066237579',
        name: '9 Omega (Dimension 4 - Version D - 08/02/2024)',
      },
    ],
    tooltipLabels: {
      '0.10861720066237579': {
        '4.1 Structuring': '',
        '4.2 Explaining': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
        '4.3 Questioning': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
        '4.4 Interacting': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
        '4.5 Embedding': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
        '4.6 Activating': '9 Omega (Dimension 4 - Version D - 08/02/2024)',
      },
    },
  };
};

export const gttSurveyQuestions = {
  '1.1': [
    {
      title: 'I sometimes feel inspired by my teacher’s knowledge of this subject',
      direction: 1,
    },
    {
      title: 'My teacher helps us to see the links between lessons and real-world events or news',
      direction: 1,
    },
    {
      title: 'My teacher knows about things which are not in the textbook, Powerpoint or worksheets',
      direction: 1,
    },
    {
      title: 'I feel confident that my teacher has very deep knowledge about the subject he or she is teaching',
      direction: 1,
    },
    {
      title: 'My teacher is able to answer most of my questions without having to think too hard about the answer',
      direction: 1,
    },
    {
      title: 'My teacher sometimes makes mistakes, gets confused or teaches us wrong things',
      direction: -1,
    },
    {
      title: 'My teacher sometimes doesn’t know the answer to my questions',
      direction: -1,
    },
  ],
  '1.3': [
    {
      title: 'My teacher’s examples really help us to understand new ideas better',
      direction: 1,
    },
    {
      title: 'Sometimes, the examples my teacher uses are related to daily life or another subject',
      direction: 1,
    },
    {
      title: 'When I need to correct something specific, my teacher always has an activity to help me',
      direction: 1,
    },
    {
      title: 'My teacher has several good ways to explain each topic that we cover in this class',
      direction: 1,
    },
    {
      title: "If I don't understand something, my teacher explains it another way",
      direction: 1,
    },
    {
      title: 'My teacher often explains how an activity will help us improve our skills and knowledge',
      direction: 1,
    },
    {
      title: 'The activities we do help me to understand the topic we are working on',
      direction: 1,
    },
    {
      title: 'The assessments we do help me to understand my strengths and weaknesses in each topic',
      direction: 1,
    },
    {
      title: 'My teacher has plenty of examples to illustrate the ideas he or she is trying to teach us',
      direction: 1,
    },
  ],
  '1.4': [
    {
      title:
        'Sometimes it feels like my teacher knows so much that he or she doesn’t realise how the work feels for us',
      direction: -1,
    },
    {
      title: 'Before we do a task, my teacher tells us about common mistakes and misunderstandings',
      direction: 1,
    },
    {
      title: 'Sometimes my teacher shows us worked examples to help us identify common mistakes',
      direction: 1,
    },
    {
      title: 'I think my teacher knows what we will find most difficult with new work',
      direction: 1,
    },
    {
      title: 'My teacher always seems to know which bits of a new topic we will find hard',
      direction: 1,
    },
    {
      title: 'Sometimes it feels like my teacher over-estimates our knowledge and understanding',
      direction: -1,
    },
  ],
  '2.1': [
    {
      title: 'My teacher understands and supports pupils who are different',
      direction: 1,
    },
    {
      title: 'My teacher is friendly and easy to talk to',
      direction: 1,
    },
    {
      title: 'My teacher cares about all pupils in our class, not just the clever or well-behaved ones',
      direction: 1,
    },
    {
      title: 'My teacher respects our ideas and suggestions',
      direction: 1,
    },
    {
      title: 'My teacher respects who we are as people',
      direction: 1,
    },
    {
      title: 'We respect our teacher, even if we do not always like him or her',
      direction: 1,
    },
    {
      title: 'I can be myself and know that my teacher accepts me',
      direction: 1,
    },
  ],
  '2.2': [
    {
      title: 'Pupils in this class are sometimes aggressive, bullying or disrespectful towards each other',
      direction: -1,
    },
    {
      title: 'In this class, pupils treat each other with respect',
      direction: 1,
    },
    {
      title: 'In this class, pupils really help each other',
      direction: 1,
    },
    {
      title: 'When one pupil speaks in this class, everyone else listens carefully',
      direction: 1,
    },
    {
      title: 'There are pupils in this class who can sometimes be bullies',
      direction: -1,
    },
    {
      title: 'Pupils often laugh at or make fun of other pupils in this class',
      direction: -1,
    },
  ],
  '2.3': [
    {
      title: 'In this class, everybody wants to do well',
      direction: 1,
    },
    {
      title: 'Our teacher makes us believe we can succeed in this class',
      direction: 1,
    },
    {
      title: 'It is really important to me to do well in this class',
      direction: 1,
    },
    {
      title: 'My teacher helps pupils to feel confident in this class',
      direction: 1,
    },
    {
      title: 'I am determined to work hard in this class',
      direction: 1,
    },
    {
      title: 'Even when the work is hard, we still make an effort',
      direction: 1,
    },
  ],
  '2.4': [
    {
      title: 'My teacher believes that we can all succeed',
      direction: 1,
    },
    {
      title: 'My teacher expects us to succeed and does not let us give up until we have',
      direction: 1,
    },
    {
      title: 'If pupils in this class don’t know an answer, we still feel happy to guess',
      direction: 1,
    },
    {
      title: 'My teacher is patient when we make mistakes in class',
      direction: 1,
    },
    {
      title: 'My teacher sometimes says: ‘This is good, but I know you can do better’',
      direction: 1,
    },
    {
      title: 'Pupils in this class are often afraid to get an answer wrong',
      direction: -1,
    },
    {
      title: 'My teacher is determined that we all understand every topic',
      direction: 1,
    },
  ],
  '3.1': [
    {
      title: 'There is constant loud talking in class',
      direction: -1,
    },
    {
      title:
        'At the beginning of a lesson, our teacher sometimes has to wait for a long time until the class calms down',
      direction: -1,
    },
    {
      title: 'It is often loud and out of control during lessons',
      direction: -1,
    },
    {
      title: 'The equipment in this class is always ready to use so we can start learning quickly',
      direction: 1,
    },
    {
      title: "Our class stays busy and doesn't waste time",
      direction: 1,
    },
    {
      title: 'In class it usually takes a very long time until everyone is ready to work',
      direction: -1,
    },
    {
      title: 'Sometimes time is wasted in class',
      direction: -1,
    },
  ],
  '3.2': [
    {
      title: 'We all know the rules in this class',
      direction: 1,
    },
    {
      title: 'In this class it is sometimes unfair because some pupils get more chances than others',
      direction: -1,
    },
    {
      title: 'In this class, some pupils seem to be allowed to do things that others are not',
      direction: -1,
    },
    {
      title: 'Class rules seem to change from day to day: what was allowed one day is not the next',
      direction: -1,
    },
    {
      title: 'We are aware of what happens when we break the rules',
      direction: 1,
    },
    {
      title: 'Pupils often break the rules and get away with it',
      direction: -1,
    },
    {
      title: 'My teacher can be very harsh on some pupils, more forgiving with others',
      direction: -1,
    },
  ],
  '3.3': [
    {
      title: 'Even when he or she is busy with something else, our teacher knows if pupils are misbehaving',
      direction: 1,
    },
    {
      title: 'My teacher makes sure that we pay attention',
      direction: 1,
    },
    {
      title: 'My teacher knows exactly what is going on in the classroom',
      direction: 1,
    },
    {
      title: 'In this class little problems cause big disruptions',
      direction: -1,
    },
    {
      title: 'When pupils misbehave, our teacher is very good at dealing with it calmly',
      direction: 1,
    },
    {
      title: 'My teacher sorts out any disruption but does not make a big deal of it',
      direction: 1,
    },
  ],
  '4.1': [
    {
      title: 'For more complicated tasks, my teacher has taught us helpful steps for us to follow',
      direction: 1,
    },
    {
      title: 'At the beginning of the lesson my teacher recaps what we have done previously',
      direction: 1,
    },
    {
      title: 'My teacher helps us to understand why what we are learning in class is important',
      direction: 1,
    },
    {
      title: 'My teacher helps us to understand how each lesson fits into the bigger picture',
      direction: 1,
    },
    {
      title: 'My teacher summarises the most important information at the end of the lesson',
      direction: 1,
    },
    {
      title: 'My teacher shows us how we are making progress through a topic',
      direction: 1,
    },
    {
      title: 'After my teacher has explained a new idea we are usually ready to try an example',
      direction: 1,
    },
    {
      title: 'In this class, our work is just the right level of difficulty',
      direction: 1,
    },
  ],
  '4.2': [
    {
      title: 'My teacher often explains too much at once for us',
      direction: -1,
    },
    {
      title: 'My teacher will explain something until we understand',
      direction: 1,
    },
    {
      title: 'We are always interested when the teacher is explaining',
      direction: 1,
    },
    {
      title: 'My teacher is able to make new ideas interesting',
      direction: 1,
    },
    {
      title: 'My teacher explains difficult things clearly',
      direction: 1,
    },
    {
      title: 'We enjoy listening to my teacher',
      direction: 1,
    },
    {
      title: 'When the teacher is explaining, he/she uses really good examples to help us understand',
      direction: 1,
    },
    {
      title:
        'Before teaching a new topic or idea, my teacher checks that all pupils understand and remember the previous work that it builds on',
      direction: 1,
    },
  ],
  '4.3': [
    {
      title: 'When we do tests they give the teacher a pretty good idea of what we have learnt',
      direction: 1,
    },
    {
      title: 'My teacher checks to make sure we understand what he or she is teaching us',
      direction: 1,
    },
    {
      title: 'My teacher thinks we understand even when we do not',
      direction: -1,
    },
    {
      title: 'My teacher asks pupils to explain more about answers they give',
      direction: 1,
    },
    {
      title: 'In this class, the teacher makes all pupils answer questions',
      direction: 1,
    },
    {
      title: 'My teacher wants us to explain our answers — why we think what we think',
      direction: 1,
    },
    {
      title: 'My teacher gives us time to explain our ideas',
      direction: 1,
    },
  ],
  '4.4': [
    {
      title: 'The comments that we get on our work in this class help us understand how to improve',
      direction: 1,
    },
    {
      title: 'Even if we haven’t done well, the feedback from our teacher is encouraging',
      direction: 1,
    },
    {
      title: 'We understand the feedback we get from our teacher',
      direction: 1,
    },
    {
      title: 'The feedback we get from our teacher tells us what to do to get better',
      direction: 1,
    },
    {
      title: 'We get helpful comments to let us know what we did wrong in our work',
      direction: 1,
    },
    {
      title: 'My teacher regularly shows me how I can improve',
      direction: 1,
    },
  ],
  '4.5': [
    {
      title: 'Our teacher regularly asks us to practise remembering things we have learned',
      direction: 1,
    },
    {
      title: 'There are some basic ideas or skills in this class which my teacher really wants us to remember',
      direction: 1,
    },
    {
      title: 'Our teacher helps us to use learning from one subject or topic in another one',
      direction: 1,
    },
    {
      title: 'Our teacher helps us to remember ideas from previous lessons',
      direction: 1,
    },
    {
      title: 'The practice we do helps new ideas or topics make more sense',
      direction: 1,
    },
    {
      title: 'In class we practise and repeat certain things to help us remember them very well',
      direction: 1,
    },
    {
      title: 'Sometimes we return to a topic several days or several weeks later',
      direction: 1,
    },
  ],
  '4.6': [
    {
      title: 'My teacher encourages us to work independently',
      direction: 1,
    },
    {
      title: 'In this class, we are learning to do more by ourselves over time',
      direction: 1,
    },
    {
      title: 'My teacher gives us the opportunity to explore new topics independently',
      direction: 1,
    },
    {
      title: 'My teacher shows us how to plan our work',
      direction: 1,
    },
    {
      title: 'My teacher likes us to check for mistakes in our own work',
      direction: 1,
    },
    {
      title: 'In this class we use checklists or strategies to help us improve our work',
      direction: 1,
    },
    {
      title: 'When we are stuck, our teacher encourages us to work out the answer without help',
      direction: 1,
    },
  ],
};

export const gttProfileDimensionData = {
  questions: gttSurveyQuestions,
  options: [
    {
      name: ' ',
      id: 7897,
    },
  ],
  data: {
    '1': {
      '1.4': {
        '7897': {
          data: {
            'My teacher always seems to know which bits of a new topic we will find hard': 48,
            'Sometimes it feels like my teacher knows so much that he or she doesn’t realise how the work feels for us': 55,
            'Sometimes it feels like my teacher over-estimates our knowledge and understanding': 49,
            'I think my teacher knows what we will find most difficult with new work': 51,
            'Sometimes my teacher shows us worked examples to help us identify common mistakes': 60,
            'Before we do a task, my teacher tells us about common mistakes and misunderstandings': 59,
          },
          ci: {
            'My teacher always seems to know which bits of a new topic we will find hard': {
              u: 53,
              l: 43,
            },
            'Sometimes it feels like my teacher knows so much that he or she doesn’t realise how the work feels for us': {
              u: 60,
              l: 50,
            },
            'Sometimes it feels like my teacher over-estimates our knowledge and understanding': {
              u: 54,
              l: 44,
            },
            'I think my teacher knows what we will find most difficult with new work': {
              u: 56,
              l: 46,
            },
            'Sometimes my teacher shows us worked examples to help us identify common mistakes': {
              u: 65,
              l: 55,
            },
            'Before we do a task, my teacher tells us about common mistakes and misunderstandings': {
              u: 64,
              l: 54,
            },
          },
          pse: {
            'My teacher always seems to know which bits of a new topic we will find hard': 67.08,
            'Sometimes it feels like my teacher knows so much that he or she doesn’t realise how the work feels for us': 73.47,
            'Sometimes it feels like my teacher over-estimates our knowledge and understanding': 70.82,
            'I think my teacher knows what we will find most difficult with new work': 71.12,
            'Sometimes my teacher shows us worked examples to help us identify common mistakes': 73.76,
            'Before we do a task, my teacher tells us about common mistakes and misunderstandings': 69.02,
          },
        },
      },
      '1.3': {
        '7897': {
          data: {
            'The assessments we do help me to understand my strengths and weaknesses in each topic': 57,
            'The activities we do help me to understand the topic we are working on': 58,
            'My teacher often explains how an activity will help us improve our skills and knowledge': 46,
            'When I need to correct something specific, my teacher always has an activity to help me': 45,
            'Sometimes, the examples my teacher uses are related to daily life or another subject': 59,
            'My teacher’s examples really help us to understand new ideas better': 50,
            'My teacher has plenty of examples to illustrate the ideas he or she is trying to teach us': 50,
            'My teacher has several good ways to explain each topic that we cover in this class': 60,
            "If I don't understand something, my teacher explains it another way": 58,
          },
          ci: {
            'The assessments we do help me to understand my strengths and weaknesses in each topic': {
              u: 62,
              l: 52,
            },
            'The activities we do help me to understand the topic we are working on': {
              u: 63,
              l: 53,
            },
            'My teacher often explains how an activity will help us improve our skills and knowledge': {
              u: 51,
              l: 41,
            },
            'When I need to correct something specific, my teacher always has an activity to help me': {
              u: 50,
              l: 40,
            },
            'Sometimes, the examples my teacher uses are related to daily life or another subject': {
              u: 64,
              l: 54,
            },
            'My teacher’s examples really help us to understand new ideas better': {
              u: 55,
              l: 45,
            },
            'My teacher has plenty of examples to illustrate the ideas he or she is trying to teach us': {
              u: 55,
              l: 45,
            },
            'My teacher has several good ways to explain each topic that we cover in this class': {
              u: 65,
              l: 55,
            },
            "If I don't understand something, my teacher explains it another way": {
              u: 63,
              l: 53,
            },
          },
          pse: {
            'The assessments we do help me to understand my strengths and weaknesses in each topic': 77.14,
            'The activities we do help me to understand the topic we are working on': 84.26,
            'My teacher often explains how an activity will help us improve our skills and knowledge': 71.7,
            'When I need to correct something specific, my teacher always has an activity to help me': 55.32,
            'Sometimes, the examples my teacher uses are related to daily life or another subject': 67.14,
            'My teacher’s examples really help us to understand new ideas better': 79.66,
            'My teacher has plenty of examples to illustrate the ideas he or she is trying to teach us': 79.89,
            'My teacher has several good ways to explain each topic that we cover in this class': 77.9,
            "If I don't understand something, my teacher explains it another way": 78.66,
          },
        },
      },
      '1.1': {
        '7897': {
          data: {
            'My teacher sometimes makes mistakes, gets confused or teaches us wrong things': 55,
            'I sometimes feel inspired by my teacher’s knowledge of this subject': 49,
            'I feel confident that my teacher has very deep knowledge about the subject he or she is teaching': 50,
            'My teacher helps us to see the links between lessons and real-world events or news': 58,
            'My teacher knows about things which are not in the textbook, Powerpoint or worksheets': 60,
            'My teacher sometimes doesn’t know the answer to my questions': 57,
            'My teacher is able to answer most of my questions without having to think too hard about the answer': 61,
          },
          ci: {
            'My teacher sometimes makes mistakes, gets confused or teaches us wrong things': {
              u: 60,
              l: 50,
            },
            'I sometimes feel inspired by my teacher’s knowledge of this subject': {
              u: 54,
              l: 44,
            },
            'I feel confident that my teacher has very deep knowledge about the subject he or she is teaching': {
              u: 55,
              l: 45,
            },
            'My teacher helps us to see the links between lessons and real-world events or news': {
              u: 63,
              l: 53,
            },
            'My teacher knows about things which are not in the textbook, Powerpoint or worksheets': {
              u: 65,
              l: 55,
            },
            'My teacher sometimes doesn’t know the answer to my questions': {
              u: 62,
              l: 52,
            },
            'My teacher is able to answer most of my questions without having to think too hard about the answer': {
              u: 66,
              l: 56,
            },
          },
          pse: {
            'My teacher sometimes makes mistakes, gets confused or teaches us wrong things': 82.32,
            'I sometimes feel inspired by my teacher’s knowledge of this subject': 59.61,
            'I feel confident that my teacher has very deep knowledge about the subject he or she is teaching': 90.75,
            'My teacher helps us to see the links between lessons and real-world events or news': 73.42,
            'My teacher knows about things which are not in the textbook, Powerpoint or worksheets': 84.04,
            'My teacher sometimes doesn’t know the answer to my questions': 83.22,
            'My teacher is able to answer most of my questions without having to think too hard about the answer': 90.19,
          },
        },
      },
    },
    '2': {
      '2.4': {
        '7897': {
          data: {
            'Pupils in this class are often afraid to get an answer wrong': 49,
            'My teacher sometimes says: ‘This is good, but I know you can do better’': 53,
            'My teacher is patient when we make mistakes in class': 58,
            'If pupils in this class don’t know an answer, we still feel happy to guess': 60,
            'My teacher expects us to succeed and does not let us give up until we have': 50,
            'My teacher is determined that we all understand every topic': 61,
            'My teacher believes that we can all succeed': 55,
          },
          ci: {
            'Pupils in this class are often afraid to get an answer wrong': {
              u: 54,
              l: 44,
            },
            'My teacher sometimes says: ‘This is good, but I know you can do better’': {
              u: 58,
              l: 48,
            },
            'My teacher is patient when we make mistakes in class': {
              u: 63,
              l: 53,
            },
            'If pupils in this class don’t know an answer, we still feel happy to guess': {
              u: 65,
              l: 55,
            },
            'My teacher expects us to succeed and does not let us give up until we have': {
              u: 55,
              l: 45,
            },
            'My teacher is determined that we all understand every topic': {
              u: 66,
              l: 56,
            },
            'My teacher believes that we can all succeed': {
              u: 60,
              l: 50,
            },
          },
          pse: {
            'Pupils in this class are often afraid to get an answer wrong': 67.43,
            'My teacher sometimes says: ‘This is good, but I know you can do better’': 67.86,
            'My teacher is patient when we make mistakes in class': 78.77,
            'If pupils in this class don’t know an answer, we still feel happy to guess': 66.34,
            'My teacher expects us to succeed and does not let us give up until we have': 71.22,
            'My teacher is determined that we all understand every topic': 75.09,
            'My teacher believes that we can all succeed': 83.57,
          },
        },
      },
      '2.3': {
        '7897': {
          data: {
            'In this class, everybody wants to do well': 51,
            'Even when the work is hard, we still make an effort': 59,
            'I am determined to work hard in this class': 57,
            'My teacher helps pupils to feel confident in this class': 45,
            'It is really important to me to do well in this class': 61,
            'Our teacher makes us believe we can succeed in this class': 50,
          },
          ci: {
            'In this class, everybody wants to do well': {
              u: 56,
              l: 46,
            },
            'Even when the work is hard, we still make an effort': {
              u: 64,
              l: 54,
            },
            'I am determined to work hard in this class': {
              u: 62,
              l: 52,
            },
            'My teacher helps pupils to feel confident in this class': {
              u: 50,
              l: 40,
            },
            'It is really important to me to do well in this class': {
              u: 66,
              l: 56,
            },
            'Our teacher makes us believe we can succeed in this class': {
              u: 55,
              l: 45,
            },
          },
          pse: {
            'In this class, everybody wants to do well': 64.59,
            'Even when the work is hard, we still make an effort': 87.31,
            'I am determined to work hard in this class': 84.26,
            'My teacher helps pupils to feel confident in this class': 78.73,
            'It is really important to me to do well in this class': 84.84,
            'Our teacher makes us believe we can succeed in this class': 82.73,
          },
        },
      },
      '2.2': {
        '7897': {
          data: {
            'There are pupils in this class who can sometimes be bullies': 48,
            'Pupils in this class are sometimes aggressive, bullying or disrespectful towards each other': 45,
            'Pupils often laugh at or make fun of other pupils in this class': 59,
            'When one pupil speaks in this class, everyone else listens carefully': 49,
            'In this class, pupils really help each other': 52,
            'In this class, pupils treat each other with respect': 55,
          },
          ci: {
            'There are pupils in this class who can sometimes be bullies': {
              u: 53,
              l: 43,
            },
            'Pupils in this class are sometimes aggressive, bullying or disrespectful towards each other': {
              u: 50,
              l: 40,
            },
            'Pupils often laugh at or make fun of other pupils in this class': {
              u: 64,
              l: 54,
            },
            'When one pupil speaks in this class, everyone else listens carefully': {
              u: 54,
              l: 44,
            },
            'In this class, pupils really help each other': {
              u: 57,
              l: 47,
            },
            'In this class, pupils treat each other with respect': {
              u: 60,
              l: 50,
            },
          },
          pse: {
            'There are pupils in this class who can sometimes be bullies': 79.37,
            'Pupils in this class are sometimes aggressive, bullying or disrespectful towards each other': 82.5,
            'Pupils often laugh at or make fun of other pupils in this class': 75.4,
            'When one pupil speaks in this class, everyone else listens carefully': 54.55,
            'In this class, pupils really help each other': 64.28,
            'In this class, pupils treat each other with respect': 68.44,
          },
        },
      },
      '2.1': {
        '7897': {
          data: {
            'We respect our teacher, even if we do not always like him or her': 59,
            'My teacher cares about all pupils in our class, not just the clever or well-behaved ones': 55,
            'My teacher is friendly and easy to talk to': 58,
            'My teacher understands and supports pupils who are different': 61,
            'I can be myself and know that my teacher accepts me': 55,
            'My teacher respects our ideas and suggestions': 50,
            'My teacher respects who we are as people': 57,
          },
          ci: {
            'We respect our teacher, even if we do not always like him or her': {
              u: 64,
              l: 54,
            },
            'My teacher cares about all pupils in our class, not just the clever or well-behaved ones': {
              u: 60,
              l: 50,
            },
            'My teacher is friendly and easy to talk to': {
              u: 63,
              l: 53,
            },
            'My teacher understands and supports pupils who are different': {
              u: 66,
              l: 56,
            },
            'I can be myself and know that my teacher accepts me': {
              u: 60,
              l: 50,
            },
            'My teacher respects our ideas and suggestions': {
              u: 55,
              l: 45,
            },
            'My teacher respects who we are as people': {
              u: 62,
              l: 52,
            },
          },
          pse: {
            'We respect our teacher, even if we do not always like him or her': 83.67,
            'My teacher cares about all pupils in our class, not just the clever or well-behaved ones': 81.44,
            'My teacher is friendly and easy to talk to': 82.85,
            'My teacher understands and supports pupils who are different': 80.65,
            'I can be myself and know that my teacher accepts me': 77.53,
            'My teacher respects our ideas and suggestions': 84.24,
            'My teacher respects who we are as people': 83.59,
          },
        },
      },
    },
    '3': {
      '3.3': {
        '7897': {
          data: {
            'My teacher sorts out any disruption but does not make a big deal of it': 58,
            'Even when he or she is busy with something else, our teacher knows if pupils are misbehaving': 52,
            'When pupils misbehave, our teacher is very good at dealing with it calmly': 50,
            'My teacher makes sure that we pay attention': 55,
            'In this class little problems cause big disruptions': 45,
            'My teacher knows exactly what is going on in the classroom': 54,
          },
          ci: {
            'My teacher sorts out any disruption but does not make a big deal of it': {
              u: 63,
              l: 53,
            },
            'Even when he or she is busy with something else, our teacher knows if pupils are misbehaving': {
              u: 57,
              l: 47,
            },
            'When pupils misbehave, our teacher is very good at dealing with it calmly': {
              u: 55,
              l: 45,
            },
            'My teacher makes sure that we pay attention': {
              u: 60,
              l: 50,
            },
            'In this class little problems cause big disruptions': {
              u: 50,
              l: 40,
            },
            'My teacher knows exactly what is going on in the classroom': {
              u: 59,
              l: 49,
            },
          },
          pse: {
            'My teacher sorts out any disruption but does not make a big deal of it': 66.9,
            'Even when he or she is busy with something else, our teacher knows if pupils are misbehaving': 67.15,
            'When pupils misbehave, our teacher is very good at dealing with it calmly': 74.67,
            'My teacher makes sure that we pay attention': 83.1,
            'In this class little problems cause big disruptions': 72.22,
            'My teacher knows exactly what is going on in the classroom': 65.65,
          },
        },
      },
      '3.2': {
        '7897': {
          data: {
            'In this class it is sometimes unfair because some pupils get more chances than others': 57,
            'My teacher can be very harsh on some pupils, more forgiving with others': 48,
            'Pupils often break the rules and get away with it': 56,
            'Class rules seem to change from day to day: what was allowed one day is not the next': 50,
            'In this class, some pupils seem to be allowed to do things that others are not': 54,
            'We are aware of what happens when we break the rules': 55,
            'We all know the rules in this class': 52,
          },
          ci: {
            'In this class it is sometimes unfair because some pupils get more chances than others': {
              u: 62,
              l: 52,
            },
            'My teacher can be very harsh on some pupils, more forgiving with others': {
              u: 53,
              l: 43,
            },
            'Pupils often break the rules and get away with it': {
              u: 61,
              l: 51,
            },
            'Class rules seem to change from day to day: what was allowed one day is not the next': {
              u: 55,
              l: 45,
            },
            'In this class, some pupils seem to be allowed to do things that others are not': {
              u: 59,
              l: 49,
            },
            'We are aware of what happens when we break the rules': {
              u: 60,
              l: 50,
            },
            'We all know the rules in this class': {
              u: 57,
              l: 47,
            },
          },
          pse: {
            'In this class it is sometimes unfair because some pupils get more chances than others': 81.41,
            'My teacher can be very harsh on some pupils, more forgiving with others': 82.13,
            'Pupils often break the rules and get away with it': 83.53,
            'Class rules seem to change from day to day: what was allowed one day is not the next': 86.64,
            'In this class, some pupils seem to be allowed to do things that others are not': 79.32,
            'We are aware of what happens when we break the rules': 79.08,
            'We all know the rules in this class': 81.8,
          },
        },
      },
      '3.1': {
        '7897': {
          data: {
            'The equipment in this class is always ready to use so we can start learning quickly': 61,
            "Our class stays busy and doesn't waste time": 58,
            'In class it usually takes a very long time until everyone is ready to work': 46,
            'Sometimes time is wasted in class': 55,
            'There is constant loud talking in class': 49,
            'At the beginning of a lesson, our teacher sometimes has to wait for a long time until the class calms down': 48,
            'It is often loud and out of control during lessons': 57,
          },
          ci: {
            'The equipment in this class is always ready to use so we can start learning quickly': {
              u: 66,
              l: 56,
            },
            "Our class stays busy and doesn't waste time": {
              u: 63,
              l: 53,
            },
            'In class it usually takes a very long time until everyone is ready to work': {
              u: 51,
              l: 41,
            },
            'Sometimes time is wasted in class': {
              u: 60,
              l: 50,
            },
            'There is constant loud talking in class': {
              u: 54,
              l: 44,
            },
            'At the beginning of a lesson, our teacher sometimes has to wait for a long time until the class calms down': {
              u: 53,
              l: 43,
            },
            'It is often loud and out of control during lessons': {
              u: 62,
              l: 52,
            },
          },
          pse: {
            'The equipment in this class is always ready to use so we can start learning quickly': 74.08,
            "Our class stays busy and doesn't waste time": 51.52,
            'In class it usually takes a very long time until everyone is ready to work': 80.9,
            'Sometimes time is wasted in class': 62.67,
            'There is constant loud talking in class': 76.03,
            'At the beginning of a lesson, our teacher sometimes has to wait for a long time until the class calms down': 71.73,
            'It is often loud and out of control during lessons': 76.34,
          },
        },
      },
    },
    '4': {
      '4.6': {
        '7897': {
          data: {
            'In this class, we are learning to do more by ourselves over time': 52,
            'My teacher gives us the opportunity to explore new topics independently': 61,
            'My teacher shows us how to plan our work': 45,
            'My teacher likes us to check for mistakes in our own work': 58,
            'In this class we use checklists or strategies to help us improve our work': 50,
            'When we are stuck, our teacher encourages us to work out the answer without help': 56,
            'My teacher encourages us to work independently': 49,
          },
          ci: {
            'In this class, we are learning to do more by ourselves over time': {
              u: 57,
              l: 47,
            },
            'My teacher gives us the opportunity to explore new topics independently': {
              u: 66,
              l: 56,
            },
            'My teacher shows us how to plan our work': {
              u: 50,
              l: 40,
            },
            'My teacher likes us to check for mistakes in our own work': {
              u: 63,
              l: 53,
            },
            'In this class we use checklists or strategies to help us improve our work': {
              u: 55,
              l: 45,
            },
            'When we are stuck, our teacher encourages us to work out the answer without help': {
              u: 61,
              l: 51,
            },
            'My teacher encourages us to work independently': {
              u: 54,
              l: 44,
            },
          },
          pse: {
            'In this class, we are learning to do more by ourselves over time': 72.34,
            'My teacher gives us the opportunity to explore new topics independently': 63.66,
            'My teacher shows us how to plan our work': 70.4,
            'My teacher likes us to check for mistakes in our own work': 69.17,
            'In this class we use checklists or strategies to help us improve our work': 50.09,
            'When we are stuck, our teacher encourages us to work out the answer without help': 62.19,
            'My teacher encourages us to work independently': 71.95,
          },
        },
      },
      '4.5': {
        '7897': {
          data: {
            'There are some basic ideas or skills in this class which my teacher really wants us to remember': 57,
            'Our teacher helps us to use learning from one subject or topic in another one': 50,
            'Our teacher helps us to remember ideas from previous lessons': 53,
            'The practice we do helps new ideas or topics make more sense': 61,
            'In class we practise and repeat certain things to help us remember them very well': 45,
            'Sometimes we return to a topic several days or several weeks later': 52,
            'Our teacher regularly asks us to practise remembering things we have learned': 58,
          },
          ci: {
            'There are some basic ideas or skills in this class which my teacher really wants us to remember': {
              u: 62,
              l: 52,
            },
            'Our teacher helps us to use learning from one subject or topic in another one': {
              u: 55,
              l: 45,
            },
            'Our teacher helps us to remember ideas from previous lessons': {
              u: 58,
              l: 48,
            },
            'The practice we do helps new ideas or topics make more sense': {
              u: 66,
              l: 56,
            },
            'In class we practise and repeat certain things to help us remember them very well': {
              u: 50,
              l: 40,
            },
            'Sometimes we return to a topic several days or several weeks later': {
              u: 57,
              l: 47,
            },
            'Our teacher regularly asks us to practise remembering things we have learned': {
              u: 63,
              l: 53,
            },
          },
          pse: {
            'There are some basic ideas or skills in this class which my teacher really wants us to remember': 81.84,
            'Our teacher helps us to use learning from one subject or topic in another one': 68.36,
            'Our teacher helps us to remember ideas from previous lessons': 77,
            'The practice we do helps new ideas or topics make more sense': 77.75,
            'In class we practise and repeat certain things to help us remember them very well': 73.69,
            'Sometimes we return to a topic several days or several weeks later': 50.62,
            'Our teacher regularly asks us to practise remembering things we have learned': 73.37,
          },
        },
      },
      '4.4': {
        '7897': {
          data: {
            'Even if we haven’t done well, the feedback from our teacher is encouraging': 51,
            'We understand the feedback we get from our teacher': 59,
            'The feedback we get from our teacher tells us what to do to get better': 48,
            'The comments that we get on our work in this class help us understand how to improve': 54,
            'We get helpful comments to let us know what we did wrong in our work': 55,
            'My teacher regularly shows me how I can improve': 60,
          },
          ci: {
            'Even if we haven’t done well, the feedback from our teacher is encouraging': {
              u: 56,
              l: 46,
            },
            'We understand the feedback we get from our teacher': {
              u: 64,
              l: 54,
            },
            'The feedback we get from our teacher tells us what to do to get better': {
              u: 53,
              l: 43,
            },
            'The comments that we get on our work in this class help us understand how to improve': {
              u: 59,
              l: 49,
            },
            'We get helpful comments to let us know what we did wrong in our work': {
              u: 60,
              l: 50,
            },
            'My teacher regularly shows me how I can improve': {
              u: 65,
              l: 55,
            },
          },
          pse: {
            'Even if we haven’t done well, the feedback from our teacher is encouraging': 73.99,
            'We understand the feedback we get from our teacher': 77.33,
            'The feedback we get from our teacher tells us what to do to get better': 77.84,
            'The comments that we get on our work in this class help us understand how to improve': 76.73,
            'We get helpful comments to let us know what we did wrong in our work': 76.79,
            'My teacher regularly shows me how I can improve': 66.82,
          },
        },
      },
      '4.3': {
        '7897': {
          data: {
            'In this class, the teacher makes all pupils answer questions': 51,
            'My teacher checks to make sure we understand what he or she is teaching us': 48,
            'My teacher thinks we understand even when we do not': 56,
            'When we do tests they give the teacher a pretty good idea of what we have learnt': 55,
            'My teacher gives us time to explain our ideas': 53,
            'My teacher wants us to explain our answers — why we think what we think': 58,
            'My teacher asks pupils to explain more about answers they give': 54,
          },
          ci: {
            'In this class, the teacher makes all pupils answer questions': {
              u: 56,
              l: 46,
            },
            'My teacher checks to make sure we understand what he or she is teaching us': {
              u: 53,
              l: 43,
            },
            'My teacher thinks we understand even when we do not': {
              u: 61,
              l: 51,
            },
            'When we do tests they give the teacher a pretty good idea of what we have learnt': {
              u: 60,
              l: 50,
            },
            'My teacher gives us time to explain our ideas': {
              u: 58,
              l: 48,
            },
            'My teacher wants us to explain our answers — why we think what we think': {
              u: 63,
              l: 53,
            },
            'My teacher asks pupils to explain more about answers they give': {
              u: 59,
              l: 49,
            },
          },
          pse: {
            'In this class, the teacher makes all pupils answer questions': 60.77,
            'My teacher checks to make sure we understand what he or she is teaching us': 76.31,
            'My teacher thinks we understand even when we do not': 71.97,
            'When we do tests they give the teacher a pretty good idea of what we have learnt': 74.29,
            'My teacher gives us time to explain our ideas': 79.54,
            'My teacher wants us to explain our answers — why we think what we think': 79.31,
            'My teacher asks pupils to explain more about answers they give': 74.73,
          },
        },
      },
      '4.2': {
        '7897': {
          data: {
            'Before teaching a new topic or idea, my teacher checks that all pupils understand and remember the previous work that it builds on': 50,
            'When the teacher is explaining, he/she uses really good examples to help us understand': 55,
            'We enjoy listening to my teacher': 52,
            'My teacher explains difficult things clearly': 51,
            'My teacher is able to make new ideas interesting': 56,
            'We are always interested when the teacher is explaining': 50,
            'My teacher often explains too much at once for us': 45,
            'My teacher will explain something until we understand': 60,
          },
          ci: {
            'Before teaching a new topic or idea, my teacher checks that all pupils understand and remember the previous work that it builds on': {
              u: 55,
              l: 45,
            },
            'When the teacher is explaining, he/she uses really good examples to help us understand': {
              u: 60,
              l: 50,
            },
            'We enjoy listening to my teacher': {
              u: 57,
              l: 47,
            },
            'My teacher explains difficult things clearly': {
              u: 56,
              l: 46,
            },
            'My teacher is able to make new ideas interesting': {
              u: 61,
              l: 51,
            },
            'We are always interested when the teacher is explaining': {
              u: 55,
              l: 45,
            },
            'My teacher often explains too much at once for us': {
              u: 50,
              l: 40,
            },
            'My teacher will explain something until we understand': {
              u: 65,
              l: 55,
            },
          },
          pse: {
            'Before teaching a new topic or idea, my teacher checks that all pupils understand and remember the previous work that it builds on': 67.07,
            'When the teacher is explaining, he/she uses really good examples to help us understand': 78.51,
            'We enjoy listening to my teacher': 67.8,
            'My teacher explains difficult things clearly': 76.76,
            'My teacher is able to make new ideas interesting': 70.94,
            'We are always interested when the teacher is explaining': 58.14,
            'My teacher often explains too much at once for us': 73.41,
            'My teacher will explain something until we understand': 78.67,
          },
        },
      },
      '4.1': {
        '7897': {
          data: {
            'In this class, our work is just the right level of difficulty': 55,
            'For more complicated tasks, my teacher has taught us helpful steps for us to follow': 56,
            'After my teacher has explained a new idea we are usually ready to try an example': 54,
            'My teacher shows us how we are making progress through a topic': 55,
            'My teacher summarises the most important information at the end of the lesson': 52,
            'My teacher helps us to understand how each lesson fits into the bigger picture': 54,
            'My teacher helps us to understand why what we are learning in class is important': 60,
            'At the beginning of the lesson my teacher recaps what we have done previously': 50,
          },
          ci: {
            'In this class, our work is just the right level of difficulty': {
              u: 60,
              l: 50,
            },
            'For more complicated tasks, my teacher has taught us helpful steps for us to follow': {
              u: 61,
              l: 51,
            },
            'After my teacher has explained a new idea we are usually ready to try an example': {
              u: 59,
              l: 49,
            },
            'My teacher shows us how we are making progress through a topic': {
              u: 60,
              l: 50,
            },
            'My teacher summarises the most important information at the end of the lesson': {
              u: 57,
              l: 47,
            },
            'My teacher helps us to understand how each lesson fits into the bigger picture': {
              u: 59,
              l: 49,
            },
            'My teacher helps us to understand why what we are learning in class is important': {
              u: 65,
              l: 55,
            },
            'At the beginning of the lesson my teacher recaps what we have done previously': {
              u: 55,
              l: 45,
            },
          },
          pse: {
            'In this class, our work is just the right level of difficulty': 72.24,
            'For more complicated tasks, my teacher has taught us helpful steps for us to follow': 77.99,
            'After my teacher has explained a new idea we are usually ready to try an example': 79.23,
            'My teacher shows us how we are making progress through a topic': 68.67,
            'My teacher summarises the most important information at the end of the lesson': 59.66,
            'My teacher helps us to understand how each lesson fits into the bigger picture': 69.2,
            'My teacher helps us to understand why what we are learning in class is important': 74.85,
            'At the beginning of the lesson my teacher recaps what we have done previously': 71.26,
          },
        },
      },
    },
  },
};

// DOWNLOAD FEEDBACK DIMENSION
export const downloadGttProfileDimensionData = (element: emulatedElementType) => {
  const initialDataSet = gttSurveyQuestions[element.value];
  const elementColor =
    element.value.charAt(0) === '1'
      ? '#E63224'
      : element.value.charAt(0) === '2'
      ? '#52b18f'
      : element.value.charAt(0) === '3'
      ? '#4a82c3'
      : element.value.charAt(0) === '4'
      ? '#594492'
      : undefined;
  const values = [79, 79, 89, 84, 84, 89, 89];
  const pseValues = [68.37, 82.45, 79.31, 79.54, 74.29, 71.97, 76.31];

  const theData = initialDataSet.map((question: any, index: number) => {
    const object = {
      '7897': values[index],
      question: initialDataSet[index].title,
      questionDirection: 1,
      color: elementColor,
      'ci-7897': {
        u: values[index] + 12 > 100 ? 100 : values[index] + 12,
        l: values[index] - 12,
      },
      'pse-7897': pseValues[index],
    };
    return object;
  });

  return {
    info: theData,
    dataKeys: [
      {
        name: `9 Omega (Dimension ${element.value.charAt(0)} - Version D - 08/02/2024)`,
        id: 7897,
      },
    ],
    color: elementColor,
  };
};

// PASSPORT PAGE DATA - USING THE gttProfileRadarData NOW

export const courseNames = [
  'Element 4.1: Structuring',
  'Element 4.2: Explaining',
  'Element 4.3: Questioning',
  'Element 4.4: Interacting',
  'Element 4.5: Embedding',
  'Element 4.6: Activating',
];

// TEACHERS PAGE DATA

const elementGroups = {
  '1': [
    'Team 1.1 Deep and fluent knowledge',
    'Team 1.2 Curriculum sequencing',
    'Team 1.3 Relevant curriculum tasks',
    'Team 1.4 Strategies and misconceptions',
  ],
  '2': [
    'Team 2.1 Teacher-student relationships',
    'Team 2.2 Student-student relationships',
    'Team 2.3 Learner motivation',
    'Team 2.4 Climate of high expectations',
  ],
  '3': [
    'Team 3.1 Managing time and resources',
    'Team 3.2 Rules and consequences',
    'Team 3.3 Managing disruptive behaviour',
  ],
  '4': [
    'Team 4.1 Structuring',
    'Team 4.2 Explaining',
    'Team 4.3 Questioning',
    'Team 4.4 Interacting',
    'Team 4.5 Embedding',
    'Team 4.6 Activating',
  ],
};

export const teachersTemplateData = (element: emulatedElementType) => {
  const elementPrefix = element.value.charAt(0);
  const selectedGroups = elementGroups[elementPrefix];

  const teacherNames = [
    { first_name: 'Benjamin', last_name: 'Davies', email: 'benjamin.davies@gtt.demo' },
    { first_name: 'Emily', last_name: 'Clarke', email: 'emily.clarke@gtt.demo' },
    { first_name: 'Ahmed', last_name: 'Rahman', email: 'ahmed.rahman@gtt.demo' },
    { first_name: 'Catherine', last_name: 'Thompson', email: 'catherine.thompson@gtt.demo' },
    { first_name: 'David', last_name: 'Roberts', email: 'david.roberts@gtt.demo' },
    { first_name: 'Rebecca', last_name: 'Hughes', email: 'rebecca.hughes@gtt.demo' },
    { first_name: 'Jonathan', last_name: 'Wilson', email: 'jonathan.wilson@gtt.demo' },
    { first_name: 'Sunita', last_name: 'Gupta', email: 'sunita.gupta@gtt.demo' },
    { first_name: 'Elizabeth', last_name: 'Brown', email: 'elizabeth.brown@gtt.demo' },
    { first_name: 'Charlotte', last_name: 'Taylor', email: 'charlotte.taylor@gtt.demo' },
    { first_name: 'Matthew', last_name: 'Evans', email: 'matthew.evans@gtt.demo' },
    { first_name: 'Victoria', last_name: 'Phillips', email: 'victoria.phillips@gtt.demo' },
    { first_name: 'Daniel', last_name: 'Harris', email: 'daniel.harris@gtt.demo' },
    { first_name: 'Aisha', last_name: 'Khan', email: 'aisha.khan@gtt.demo' },
    { first_name: 'Jennifer', last_name: 'Carter', email: 'jennifer.carter@gtt.demo' },
    { first_name: 'Andrew', last_name: 'Johnson', email: 'andrew.johnson@gtt.demo' },
    { first_name: 'Sarah', last_name: 'King', email: 'sarah.king@gtt.demo' },
    { first_name: 'Christopher', last_name: 'Wright', email: 'christopher.wright@gtt.demo' },
    { first_name: 'Lauren', last_name: 'Morgan', email: 'lauren.morgan@gtt.demo' },
    { first_name: 'James', last_name: 'Robinson', email: 'james.robinson@gtt.demo' },
    { first_name: 'Michael', last_name: 'White', email: 'michael.white@gtt.demo' },
    { first_name: 'Mei', last_name: 'Wong', email: 'mei.wong@gtt.demo' },
    { first_name: 'Sophie', last_name: 'Mitchell', email: 'sophie.mitchell@gtt.demo' },
    { first_name: 'Peter', last_name: 'Turner', email: 'peter.turner@gtt.demo' },
    { first_name: 'Natalie', last_name: 'Scott', email: 'natalie.scott@gtt.demo' },
    { first_name: 'Stephen', last_name: 'Hall', email: 'stephen.hall@gtt.demo' },
    { first_name: 'Olivia', last_name: 'Davis', email: 'olivia.davis@gtt.demo' },
    { first_name: 'Nicholas', last_name: 'Clark', email: 'nicholas.clark@gtt.demo' },
    { first_name: 'Jamal', last_name: 'Patel', email: 'jamal.patel@gtt.demo' },
    { first_name: 'Hannah', last_name: 'Allen', email: 'hannah.allen@gtt.demo' },
    { first_name: 'John', last_name: 'Doe', email: 'john.doe@gtt.demo' },
    { first_name: 'Lucy', last_name: 'Smith', email: 'lucy.smith@gtt.demo' },
    { first_name: 'George', last_name: 'Baker', email: 'george.baker@gtt.demo' },
    { first_name: 'Alice', last_name: 'Adams', email: 'alice.adams@gtt.demo' },
    { first_name: 'Sam', last_name: 'Wilson', email: 'sam.wilson@gtt.demo' },
    { first_name: 'Mia', last_name: 'Thomson', email: 'mia.thomson@gtt.demo' },
  ];

  const teachers = teacherNames.map((teacher, index) => {
    const groupIndex = Math.floor(index / (teacherNames.length / selectedGroups.length)) % selectedGroups.length;
    return {
      id: `teacher-${index + 1}`,
      confirmed: 'confirmed',
      first_name: teacher.first_name,
      last_name: teacher.last_name,
      email: teacher.email,
      active: true,
      great_teaching_lead: false,
      organisation: 'school_demo',
      role: 'ROLE_TEACHER',
      bulk_subscription_id: `bulk-${index + 1}`,
      upgraded: false,
      element: undefined,
      current_pathway_step: undefined,
      groups: [selectedGroups[groupIndex]],
      slots: undefined,
      subscription_type: undefined,
      school_has_mentoring: undefined,
      mentor: undefined,
      development_activities: undefined,
    };
  });

  return {
    data: {
      users: teachers,
    },
    meta: {
      total: teachers.length,
      max_page: 250,
    },
  };
};

// Development activities

export const findElements = (element: emulatedElementType) => {
  const groupPrefix = element.value.split('.')[0];
  return listOfElements
    .filter((el: DefaultOptionType) => el.value.startsWith(groupPrefix + '.'))
    .map((el: DefaultOptionType) => el.value);
};

export const starterTemplateDevelopmentActivities = (element: emulatedElementType) => {
  return {
    data: {
      teachers: 30,
      school: 'School Name', //CHANGE THAT TO DYNAMIC organisation_name
      foundation_course: {
        completed: 80,
        onboarding: 100,
      },
      survey: {
        average_completed_survey: 80,
        number_survey_instances: 20,
        number_completed_survey: 6,
        number_survey_responses: 440,
      },
      teacher_courses: {
        start: 25,
        complete: 18,
        average_completed: 72,
      },
      lead_programme_courses: {
        start: 5,
        complete: 3,
        average_completed: 60,
      },
      element: {
        start_elements: 100,
        elements: findElements(element),
      },
      development_cycle_completed: 0,
      time_cost: {
        hours: 342,
        cost: 145692,
      },
    },
  };
};

// VIDEO FEEDBACK DATA

export const starterTemplateVideoFeedbackData = {
  '1': {
    '1.1': {
      id: 11286,
      name: '1.1 Deep and fluent knowledge',
      colour: '#e63224',
      tags: [
        {
          id: 25096,
          name: 'The teacher demonstrates a nuanced understanding of the subject content',
        },
        {
          id: 25097,
          name: 'The teacher can respond promptly and accurately to student questions',
        },
        {
          id: 25098,
          name: 'The teacher is easily able to reformulate explanations',
        },
        {
          id: 25099,
          name: 'Links are established to other subjects and real-life contexts',
        },
        {
          id: 25100,
          name: 'The lesson moves beyond the printed materials and textbook',
        },
      ],
    },
    '1.2': {
      id: 11287,
      name: '1.2 Curriculum sequencing',
      colour: '#e63224',
      tags: [
        {
          id: 25101,
          name: 'The teacher demonstrates awareness of the prior learning required',
        },
        {
          id: 25102,
          name: 'There is a gradual progression from simplicity to complexity through the lesson',
        },
        {
          id: 25103,
          name: 'The lesson is contextualised within a wider sequence of learning and purpose',
        },
        {
          id: 25104,
          name: 'The teacher makes reference to what the next stages in learning will be',
        },
      ],
    },
    '1.3': {
      id: 11288,
      name: '1.3 Relevant curriculum tasks',
      colour: '#e63224',
      tags: [
        {
          id: 25105,
          name: 'The activities are well suited to the material being covered',
        },
        {
          id: 25106,
          name: 'The teacher explains the purpose of activities with reference to objective',
        },
        {
          id: 25107,
          name: 'Assessment shows students where they are in relation to objective',
        },
        {
          id: 25108,
          name: 'The teacher is able to explain a single concept or idea in different ways',
        },
        {
          id: 25109,
          name: 'Examples used are relevant and demonstrative of teacher knowledge',
        },
        {
          id: 25110,
          name: 'Where appropriate, the teacher supports explanation with an analogy',
        },
      ],
    },
    '1.4': {
      id: 11289,
      name: '1.4 Strategies and misconceptions',
      colour: '#e63224',
      tags: [
        {
          id: 25111,
          name: 'The teacher demonstrates awareness of potential misconceptions',
        },
        {
          id: 25112,
          name: 'The teacher addresses common misconceptions explicitly',
        },
        {
          id: 25113,
          name: 'Instruction includes discussion of strategies to better remember new content',
        },
        {
          id: 25114,
          name: 'The lesson is well-pitched to build on prior knowledge',
        },
      ],
    },
  },
  '2': {
    '2.1': {
      id: 11273,
      name: '2.1 Teacher-student relationships',
      colour: '#00ab87',
      tags: [
        {
          id: 25013,
          name: 'Teacher-student interactions are largely positive and successful',
        },
        {
          id: 25014,
          name: 'In both actions and use of language, the teacher shows deep respect',
        },
        {
          id: 25015,
          name: 'The teacher is understanding of all students in the classroom',
        },
        {
          id: 25016,
          name: 'The teacher demonstrates genuine warmth and care towards all students',
        },
        {
          id: 25017,
          name: 'Language is framed positively; negativity is avoided',
        },
        {
          id: 25018,
          name: 'The teacher adapts language to meet individual student needs',
        },
        {
          id: 25019,
          name: 'The teacher is conscious of students’ cultural and/or religious beliefs',
        },
      ],
    },
    '2.2': {
      id: 11274,
      name: '2.2 Student-student relationships',
      colour: '#00ab87',
      tags: [
        {
          id: 25020,
          name: 'Student-student interactions are largely positive and successful',
        },
        {
          id: 25021,
          name: 'Students show respect for each other in their language and actions',
        },
        {
          id: 25022,
          name: 'Students trust each other and feel comfortable in the classroom',
        },
        {
          id: 25023,
          name: 'Where appropriate, students work well together in pursuit of a shared goal',
        },
        {
          id: 25024,
          name: 'tudents are willing to and capable of helping each other learn',
        },
        {
          id: 25025,
          name: 'The teacher encourages students to be kind to each other and caring',
        },
      ],
    },
    '2.3': {
      id: 11275,
      name: '2.3 Learner motivation',
      colour: '#00ab87',
      tags: [
        {
          id: 25026,
          name: 'Overall, students are eager and willing to engage with learning',
        },
        {
          id: 25027,
          name: 'The teacher encourages students to recognise their achievements',
        },
        {
          id: 25028,
          name: 'The teacher provides opportunity for students to develop autonomy',
        },
        {
          id: 25029,
          name: 'The teacher articulates the application of learning to real life scenarios',
        },
      ],
    },
    '2.4': {
      id: 11276,
      name: '2.4 Climate of high expectations',
      colour: '#00ab87',
      tags: [
        {
          id: 25030,
          name: 'The teacher sets very high standards of work and behaviour',
        },
        {
          id: 25031,
          name: 'It appears that the teacher believes in the potential for success of all students',
        },
        {
          id: 25032,
          name: 'The teacher does not lower their standards for any subgroup of students',
        },
        {
          id: 25033,
          name: 'The teacher sets ambitious yet achievable goals for students',
        },
        {
          id: 25034,
          name: 'Students are willing to suggest answers even when there is a high level of doubt',
        },
        {
          id: 25035,
          name: 'The teacher encourages students to recognise their own role in success/failure',
        },
      ],
    },
  },
  '3': {
    '3.1': {
      id: 12473,
      name: '3.1 Managing time and resources',
      colour: '#0071b9',
      tags: [
        {
          id: 27969,
          name: 'The lesson starts punctually and in an orderly manner',
        },
        {
          id: 27970,
          name: 'The teacher always communicates their instructions clearly',
        },
        {
          id: 27971,
          name: 'Transitions are smooth and well-rehearsed; no learning time is lost',
        },
        {
          id: 27972,
          name: 'Resources used in class support learning and are readily available',
        },
        {
          id: 27973,
          name: 'Students are actively engaged in learning until the end of the lesson',
        },
        {
          id: 27974,
          name: 'Students are actively engaged in learning until the end of the lesson',
        },
        {
          id: 27975,
          name: 'The teacher ensures there little time is lost to off-task behaviour',
        },
      ],
    },
    '3.2': {
      id: 12474,
      name: '3.2 Rules and consequences',
      colour: '#0071b9',
      tags: [
        {
          id: 27976,
          name: 'Students all know, understand and can verbalise what is expected of them',
        },
        {
          id: 27977,
          name: 'The teacher communicates expectations clearly and checks for understanding',
        },
        {
          id: 27978,
          name: 'The teacher ensures students understand what happens if they do not behave',
        },
        {
          id: 27979,
          name: 'Both praise and consequences are given fairly and proportionately',
        },
      ],
    },
    '3.3': {
      id: 12475,
      name: '3.3 Managing disruptive behaviour',
      colour: '#0071b9',
      tags: [
        {
          id: 27980,
          name: 'The teacher knows what is happening in the classroom (no blind spots)',
        },
        {
          id: 27981,
          name: 'Misbehaviour is managed promptly and discreetly',
        },
        {
          id: 27982,
          name: 'The teacher recognises potential problems before they become real problems',
        },
        {
          id: 27983,
          name: 'The teacher has strategies to signal their awareness of issues to students',
        },
        {
          id: 27984,
          name: 'Where students are making the right decisions, this is acknowledged',
        },
        {
          id: 27985,
          name: 'There is little disruption to learning caused by poor behaviour',
        },
        {
          id: 27986,
          name: 'When responding to poor behaviour, teacher’s voice is firm, but no shouting',
        },
      ],
    },
  },
  '4': {
    '4.1': {
      id: 11280,
      name: '4.1 Structuring',
      colour: '#5d3f92',
      tags: [
        {
          id: 25054,
          name: 'The lesson has a logical flow that moves from simple to complex',
        },
        {
          id: 25055,
          name: 'The teacher verbalises the relevance of tasks/activities to lesson outcomes',
        },
        {
          id: 25056,
          name: 'Students are told how lesson outcomes relate to a wider structure (ie units)',
        },
        {
          id: 25057,
          name: 'The lesson progression is clearly articulated by the teacher',
        },
        {
          id: 25058,
          name: 'Activities/tasks are well designed to support all learners’ needs',
        },
        {
          id: 25059,
          name: 'Scaffolding is used judiciously; gradually retracted as knowledge is secured',
        },
        {
          id: 25060,
          name: 'The teacher checks whether prerequisite knowledge is readily available before teaching a new concept',
        },
        {
          id: 25061,
          name: 'Teaching provides occasion for students to think deeply and connect ideas',
        },
      ],
    },
    '4.2': {
      id: 11281,
      name: '4.2 Explaining',
      colour: '#5d3f92',
      tags: [
        {
          id: 25062,
          name: 'New ideas are presented clearly, concisely and in small steps',
        },
        {
          id: 25063,
          name: 'The teacher gives explanations which are ‘just right’ in depth and length',
        },
        {
          id: 25064,
          name: 'The teacher carefully chooses language which is easily understandable',
        },
        {
          id: 25065,
          name: 'The teacher connects new ideas to accessible prior knowledge',
        },
        {
          id: 25066,
          name: 'The teacher uses examples to supporting understanding of new ideas',
        },
        {
          id: 25067,
          name: 'Explanation of new content is supported by effective modelling',
        },
        {
          id: 25068,
          name: 'Where appropriate, the teacher demonstrates new methods and skills',
        },
      ],
    },
    '4.3': {
      id: 11282,
      name: '4.3 Questioning',
      colour: '#5d3f92',
      tags: [
        {
          id: 25069,
          name: 'Adequate thinking time is allowed to students when posing questions',
        },
        {
          id: 25070,
          name: 'The design/format of questions is appropriate for the content being taught',
        },
        {
          id: 25071,
          name: 'Questions asked prompt students to think deeply and develop understanding',
        },
        {
          id: 25072,
          name: 'Questions asked promote rich dialogue which moves learning forward',
        },
        {
          id: 25073,
          name: 'The teacher asks questions which assess if new ideas have been understood',
        },
        {
          id: 25074,
          name: 'The teacher regularly elicits question responses from all students',
        },
        {
          id: 25075,
          name: 'Students are asked to justify or explain their answers, or others’ answers',
        },
      ],
    },
    '4.4': {
      id: 11283,
      name: '4.4 Interacting',
      colour: '#5d3f92',
      tags: [
        {
          id: 25076,
          name: 'The teacher appraises and acknowledges all answers given',
        },
        {
          id: 25077,
          name: 'The teacher adapts teaching based on student responses to questions',
        },
        {
          id: 25078,
          name: 'Students are supported to recognise strengths and areas for improvement',
        },
        {
          id: 25079,
          name: 'The teacher provides manageable amounts of feedback',
        },
        {
          id: 25080,
          name: 'Students are encouraged to interact with feedback received',
        },
        {
          id: 25081,
          name: 'The feedback provided by the teacher is focussed on how to improve',
        },
        {
          id: 25082,
          name: 'The teacher provides feedback which is encouraging and inspiring',
        },
      ],
    },
    '4.5': {
      id: 11284,
      name: '4.5 Embedding',
      colour: '#5d3f92',
      tags: [
        {
          id: 25083,
          name: 'The teacher prioritises retention of the most crucial knowledge',
        },
        {
          id: 25084,
          name: 'Where appropriate, the teacher allows for extended practice',
        },
        {
          id: 25085,
          name: 'The teacher provides an appropriate level of support/guidance during practice',
        },
        {
          id: 25086,
          name: 'The teacher provides opportunity for overlearning of key subject knowledge',
        },
        {
          id: 25087,
          name: 'Previously learnt material is revisited to prevent forgetting',
        },
        {
          id: 25088,
          name: 'The teacher prompts students to transfer learning into new contexts',
        },
      ],
    },
    '4.6': {
      id: 11285,
      name: '4.6 Activating',
      colour: '#5d3f92',
      tags: [
        {
          id: 25089,
          name: 'The teacher verbalises their thinking and planning as a model',
        },
        {
          id: 25090,
          name: 'There is conversation about how to learn between teacher and students',
        },
        {
          id: 25091,
          name: 'The teacher explicitly supports planning – in a subject specific context',
        },
        {
          id: 25092,
          name: 'The teacher explicitly supports the development of student self-regulation',
        },
        {
          id: 25093,
          name: 'Students are encouraged and taught to monitor their own learning',
        },
        {
          id: 25094,
          name: 'The teacher provides opportunity and structure for students to evaluate their own work',
        },
        {
          id: 25095,
          name: 'The teacher demonstrates a commitment to students becoming increasingly independent over time',
        },
      ],
    },
  },
};
