import React from 'react';
import classnames from 'classnames';
import { DefaultOptionType } from '../../resources/types';
import { ChoiceGroupProps } from './radioGroup';
import { ChoiceInBlockProps, ChoiceProps } from '../common/field.types';
import Icon from '../../global/icon/icon';
import Choice from './choice';
import Label from '../common/label';
import { MoreInfo } from '../../global/tooltip/common';

type OptionType = DefaultOptionType & ChoiceInBlockProps;

interface CheckboxGroupOnBlockProps extends Omit<ChoiceGroupProps, 'options' | 'isGroupRequired' | 'optionChecked'> {
  /**
   * Handles change.
   * @param value
   */
  handleChange(value: string): void;
  /**
   * Overrides ChoiceGroupProps' options.
   */
  options: OptionType[];
  /**
   * Sets grid's classes.
   */
  gridClasses?: string;
  /**
   * Options that has been checked.
   */
  optionsChecked: string[];
}

const CheckboxGroupOnBlock = ({
  id,
  label,
  hideLabel = false,
  options,
  optionsChecked,
  handleChange,
  gridClasses = '',
  required,
  ...other
}: CheckboxGroupOnBlockProps): JSX.Element => {
  return (
    <React.Fragment>
      <Label label={label} id={id} required={required} hideLabel={hideLabel} />
      <div className={`grid grid-cols-1 md:grid-cols-${options.length} gap-2 ${gridClasses}`}>
        {options.map((option) => (
          <div
            key={`${id}_${option.value}`}
            className={`relative flex flex-col h-full transition-border duration-500 ease-in-out bg-gray-100 rounded-md border-2
          ${classnames({ 'cursor-pointer hover:border-primary': !other.disabled })}
          ${classnames({ 'border-primary': optionsChecked.includes(option.value) })} p-5`}
            onClick={() => {
              if (!other.disabled) {
                handleChange(option.value);
              }
            }}
          >
            <div className={'flex items-center justify-between h-full'}>
              <div
                className={`flex flex-grow items-center ${classnames({
                  'text-primary': optionsChecked.includes(option.value),
                })}`}
              >
                {option.icon && <Icon icon={option.icon} container={false} className={'mr-3'} />}
                <div>
                  <div className={'font-bold leading-tight'}>{option.label}</div>
                  {option.description && <div className={'text-sm'}>{option.description}</div>}
                </div>
                {option.tooltipMessage && (
                  <div className={'ml-3'}>
                    <MoreInfo content={option.tooltipMessage} />
                  </div>
                )}
              </div>
              <Choice
                type={'checkbox'}
                id={`${id}_${option.value}`}
                name={id}
                label={option.label}
                hideLabel
                checked={optionsChecked.includes(option.value)}
                onChange={() => null}
                className={'pretty-no-margin'}
                icon={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-check"
                    viewBox="0 0 16 16"
                  >
                    <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                  </svg>
                }
                animation={'tada'}
                {...(other as ChoiceProps)}
              />
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

CheckboxGroupOnBlock.displayName = 'CheckboxGroupOnBlock';

export default CheckboxGroupOnBlock;
