import React, { useState } from 'react';
import { Formik } from 'formik';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Selector from '../../../form/select/selector';
import { SelectOptionType } from '../../../service/types';
import Modal from '../../../global/messages/modal/modal.components';
import {
  getAllV2Pathways,
  getCompletedEnrolments,
  getDevelopmentActivities,
  getRadarFeedbackData,
} from '../../../service/api';
import { useQuery } from 'react-query';
import { ALL_PATHWAYS } from '../../../service/queryKeys';
import { BadgeType, transformPassPortBadge } from '../components/usePassportBadges';
import { Document, Image, Page, pdf, Text, View } from '@react-pdf/renderer';
import PDFLayout, { MARGIN_LEFT_TEXT, MARGIN_TOP_PAGE, PDF_STYLES } from '../../../layout/pdf';
import PDFChart from '../../feedback/PDFChart';
import { CustomRadarChartPDF } from '../../feedback/components/customRadarChart';
import { processedRadarData } from '../../feedback/sections/radarChartSection';
import Pdf from '../../../layout/pdf';
import { saveAs } from 'file-saver';
import { svgToImage } from '../../feedback/imageFromSvg';
import { Checkbox } from 'pretty-checkbox-react';
import { capitalize } from '../../../helpers/misc';
import { ActivityType } from '../components/developmentActivitiesTable';

const PdfBadge = ({ badge, type }: { badge: BadgeType; type: 'course' | 'program' }) => (
  <View
    style={{
      display: 'flex',
      flexDirection: 'row',
      width: type === 'course' ? '50%' : undefined,
      borderLeft: type === 'program' ? '1px solid #c3ccda' : undefined,
    }}
  >
    <Image style={{ width: 75 }} src={svgToImage(badge.image, 720, 720)}/>
    <View>
      <Text
        style={{
          ...PDF_STYLES.headline,
          maxWidth: 100,
          fontSize: 10,
          color: !badge.date ? '#9f9f9f' : undefined,
          marginTop: 10,
        }}
      >
        {badge.name}
      </Text>
      <Text
        style={{
          fontSize: 10,
        }}
      >
        {badge.date}
      </Text>
    </View>
  </View>
);

const DownloadPassportModal = ({
  activeBadges,
  toggle
}: ModalToggleProps & { classToEdit: Record<any, any>; mutation: any, activeBadges?: boolean }) => {
  const [loading, setLoading] = useState(false);

  const handleDownload = async (pathways: Array<{ label: string; value: string }>, includeWithout: boolean) => {
    setLoading(true);

    try {
      const pdfData = await Promise.all([
        getRadarFeedbackData(undefined, undefined, undefined, undefined, true).catch(() => null),
        getCompletedEnrolments(),
        getDevelopmentActivities(
          undefined,
          pathways.map((p) => p.value),
          includeWithout
        ),
      ]);

      const badges = transformPassPortBadge(pdfData[1]!.data.data);

      const activities: Array<[string, Array<ActivityType>]> = Object.entries(
        pdfData[2].data.data.reduce((acc: Record<string, Array<ActivityType>>, curr: ActivityType) => {
          (acc[curr.pathway ?? ''] = acc[curr.pathway ?? ''] || []).push(curr);
          return acc;
        }, {})
      );

      const blob = await pdf(
        <Document>
          {(activeBadges || pdfData[0] != null) &&
            (<Page size="A4" style={{ ...PDF_STYLES.page }} key={Math.random()}>
              <PDFLayout.Header/>
              {pdfData[0] && (
                <>
                  <Text
                    style={{
                      ...PDF_STYLES.headline,
                      marginLeft: MARGIN_LEFT_TEXT,
                      marginTop: 0,
                    }}
                  >
                    {pdfData[0].data.data.options.map((o) => o.name).join(' / ')}
                  </Text>
                  <View style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                    <PDFChart>
                      <CustomRadarChartPDF
                        id={'radar_passport'}
                        data={processedRadarData(
                          pdfData[0].data.data,
                          pdfData[0].data.data.options.map((o) => o.id)
                        )}
                        width={275}
                        height={275}
                      />
                    </PDFChart>
                  </View>
                  <Text style={{ ...PDF_STYLES.headline, textAlign: 'center', marginBottom: 20 }}>
                    Elements of Great Teaching
                  </Text>
                </>
              )}
              {activeBadges &&
                  <View style={{ width: '100%', display: 'flex', flexDirection: 'row', margin: 10, marginTop: 20 }}>
                      <View style={{ width: '66%', flexWrap: 'wrap', display: 'flex', flexDirection: 'row' }}>
                        {badges[0].map((course: BadgeType) =>
                          <>
                            <PdfBadge key={Math.random()} badge={course} type={'course'}/>
                          </>
                        )}
                      </View>
                      <View style={{ width: '33%' }}>
                        {badges[1].map((program: BadgeType) =>
                          <>
                            <PdfBadge key={Math.random()} badge={program} type={'program'}/>
                          </>
                        )}
                      </View>
                  </View>
              }
            </Page>)
          }
          <Page orientation={'landscape'} style={{ padding: MARGIN_TOP_PAGE, paddingBottom: 50 }}>
            {pdfData[0] != null || !activeBadges && <PDFLayout.Header/>}
            {activities.map(([pathway, pathwayActivities]) => (
              <React.Fragment key={Math.random()}>
                <Text style={{ ...PDF_STYLES.headline, marginBottom: 10 }}>
                  {pathway === ''
                    ? 'Activities without associated development cycles'
                    : pathways.find((p) => p.value === pathway)!.label}
                </Text>
                <Pdf.Table
                  tableStyles={{ margin: 0, marginBottom: MARGIN_TOP_PAGE, width: '100%' }}
                  headers={[
                    { value: 'Term', textAlign: 'center', fontSize: 10, width: '20%' },
                    { value: 'Cycle', textAlign: 'center', fontSize: 10, width: '5%' },
                    { value: 'Step', textAlign: 'center', fontSize: 10, width: '7%' },
                    { value: 'Element', textAlign: 'center', fontSize: 10, width: '10%' },
                    { value: 'Activity type', textAlign: 'center', fontSize: 10, width: '16%' },
                    { value: 'Activity', textAlign: 'center', fontSize: 10, width: '16%' },
                    { value: 'Hours', textAlign: 'center', fontSize: 10, width: '5%' },
                    { value: 'Started', textAlign: 'center', fontSize: 10, width: '7%' },
                    { value: 'Completed', textAlign: 'center', fontSize: 10, width: '7%' },
                    { value: 'Status', textAlign: 'center', fontSize: 10, width: '7%' },
                  ]}
                  rows={pathwayActivities.map((pathwayActivity) => [
                    { value: pathwayActivity.term },
                    { value: pathwayActivity.pathway_number },
                    { value: pathwayActivity.pathway_step_type ? capitalize(pathwayActivity.pathway_step_type) : null },
                    { value: pathwayActivity.element?.label },
                    { value: pathwayActivity.activity_type },
                    { value: pathwayActivity.activity },
                    { value: pathwayActivity.hours },
                    { value: pathwayActivity.started },
                    { value: pathwayActivity.completed },
                    { value: pathwayActivity.completed == null ? 'In Progress' : 'Completed' },
                  ])}
                />
              </React.Fragment>
            ))}
          </Page>
        </Document>
      ).toBlob();

      saveAs(blob, 'cpd-passport');
      toggle(false)
    } finally {
      setLoading(false);
    }
  };

  const { data: pathways } = useQuery([ALL_PATHWAYS, null, 'v2'], () => getAllV2Pathways(undefined, 'v2'), {
    staleTime: Infinity,
    select: (data) =>
      data.data.data.map((pathway: any) => {
        return { label: 'Development cycle ' + pathway.number, value: pathway.id, pathway: pathway };
      }),
    placeholderData: [],
  });

  return (
    <Formik
      initialValues={{
        pathways: [],
        includeWithout: false,
      }}
      enableReinitialize
      onSubmit={(values) => {
        handleDownload(values.pathways, values.includeWithout);
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <Modal>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="mb-8">
                <Selector
                  id="pathways"
                  label="Development cycles"
                  placeholder="Please select a development cycle"
                  required
                  options={pathways}
                  isClearable={false}
                  value={values.pathways}
                  isMulti
                  onChange={(option: SelectOptionType | null) => setFieldValue('pathways', option)}
                />
              </div>
              <div className="mb-8">
                <Checkbox
                  color={'primary-o'}
                  onChange={() => {
                    setFieldValue('includeWithout', !values.includeWithout);
                  }}
                  checked={values.includeWithout}
                  icon={
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                    </svg>
                  }
                >
                  <strong>Include activities that aren&apos;t associated with a development cycle</strong>
                </Checkbox>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)}/>
              <Modal.ConfirmButton
                type="submit"
                disabled={loading || values.pathways.length === 0}
                label={'Download'}
              />
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default DownloadPassportModal;
