import React from 'react';
import { toast } from 'react-toastify';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';

const WarningManualCheckModal = ({ toggle, handleSubmit }: ModalToggleProps & WarningManualCheckModalType) => {
  return (
    <Modal>
      <Modal.Body>
        <p className={'mb-2'}>You&apos;re about to confirm that you have completed this self-study module.</p>
        <p>Only do this if you&apos;re working with the PDF version.</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={() => {
            toggle(false);
          }}
        />
        <Modal.ConfirmButton
          onClick={() => {
            handleSubmit();
            toast.success('Course progress updated');
            toggle(false);
            /*.then(() => {
              toggle(false);
              toast.success('This zoom has been removed successfully.');
            })*/
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

WarningManualCheckModal.displayName = 'WarningManualCheckModal';

type WarningManualCheckModalType = {
  handleSubmit: any;
};

export default WarningManualCheckModal;
