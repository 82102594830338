import React from 'react';
import JsxParser from 'react-jsx-parser';
import { LinkStyled, RouterStyled } from '../../global/link/link.styled';
import { useQuery } from 'react-query';
import { ME, THINKIFIC_SSO } from '../../service/queryKeys';
import { getMe, getThinkificSSO } from '../../service/api';
import { LINKS, THINKIFIC_LINKS } from '../../service/links';
import { ROLE_TEACHER_FREE } from '../../resources/roles';

const NotificationHandler = ({ str }: { str: string }) => {
  const { data: thinkificSSOLink, isFetched } = useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
    select: (data: any) => data.data.data.link,
  });

  const { data: me } = useQuery(ME, () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
  });

  const parameters = {
    ':courses': `<LinkStyled href={courses} target={'_blank'}>Courses</LinkStyled>`,
    ':foundation_course_link': `<LinkStyled href={foundationCourseLink} target={'_blank'}>Foundation Course</LinkStyled>`,
    ':foundation_free_first_lesson': `<LinkStyled href={foundationCourseFreeFirstLessonLink} target={'_blank'}>Foundation Course</LinkStyled>`,
    ':class_management_link': `<RouterStyled to={'/class-management'}>Classes</RouterStyled>`,
    ':development_pathways_link': `<RouterStyled to={'/pathways'}>Development Pathway</RouterStyled>`,
    ':organisation_management_link_free': `<RouterStyled to={'/organisation-management'}>Setup</RouterStyled>`,
    ':organisation_management_link': `<RouterStyled to={'/organisation-management'}>School planner</RouterStyled>`,
    ':add_development_periods_link': `<RouterStyled to={'/organisation-management'}>Add Development Periods</RouterStyled>`,
    ':organisation_management_link_this_page': `<RouterStyled to={'/organisation-management'}>this page</RouterStyled>`,
    ':knowledge_base_link': `<LinkStyled href={knowledgeBaseLink} target={'_blank'}>here</LinkStyled>`,
    ':delivery_options_link': `<LinkStyled href={deliveryOptionsLink} target={'_blank'}>here</LinkStyled>`,
    ':user_management_link': `<RouterStyled to={'/user-management'}>User management </RouterStyled>`,
    ':baseline_link_here': `<RouterStyled to={'/student-surveys'}>here</RouterStyled>`,
    ':baseline_link_feedback_tools': `<RouterStyled to={'/feedback'}>Feedback tools</RouterStyled>`,
    ':baseline_link_gtt_profile': `<RouterStyled to={'/feedback'}>GTT Profile</RouterStyled>`,
    ':elements_link_here': `<RouterStyled to={'/student-surveys'}>here</RouterStyled>`,
    ':baseline_feedback_link_here': `<RouterStyled to={'/feedback'}>here</RouterStyled>`,
    ':baseline_school_feedback_link_here': `<RouterStyled to={'/school-feedback'}>here</RouterStyled>`,
    ':baseline_feedback_link_gtt_profile': `<RouterStyled to={'/feedback'}>GTT Profile</RouterStyled>`,
    ':element_feedback_link_here': `<RouterStyled to={'/feedback'}>here</RouterStyled>`,
    ':share_gtt_link_share_this': `<LinkStyled href={'mailto:?subject=Have a look at this! &body=I have just signed up for a Great Teaching Toolkit starter account, with samples of the Toolkit courses as well as free student surveys. You can sign up here: https://toolkit.greatteaching.com/register?r=teacher-free'} target={'_blank'}>share this</LinkStyled>`,
    ':subscribe_to_gtt_link': `<LinkStyled href={subscribeToGTTLink} target={'_blank'}>Find out more and subscribe to the Great Teaching Toolkit!</LinkStyled>`,
  };

  const regex = new RegExp(Object.keys(parameters).join('|'), 'g');

  const notification = str.replace(regex, (matched) => {
    return parameters[matched as keyof typeof parameters] as string;
  });

  return (
    <JsxParser
      bindings={{
        courses: isFetched ? thinkificSSOLink + THINKIFIC_LINKS['courses'] : '#',
        foundationCourseLink: isFetched
          ? thinkificSSOLink + THINKIFIC_LINKS[me.role === ROLE_TEACHER_FREE ? 'foundation_free' : 'foundation']
          : '#',
        foundationCourseFreeFirstLessonLink: isFetched
          ? thinkificSSOLink + THINKIFIC_LINKS['foundation_free_first_lesson']
          : '#',
        knowledgeBaseLink: LINKS['knowledgeBase'],
        deliveryOptionsLink: LINKS['deliveryOptions'],
        subscribeToGTTLink: LINKS['subscribeToGTTLink'],
      }}
      components={{ RouterStyled, LinkStyled }}
      jsx={notification}
    />
  );
};

export default NotificationHandler;
