import React, { useRef, useState } from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import { useQuery } from 'react-query';
import { getDevelopmentGoals } from '../../../service/api';
import { EBE_GOALS } from '../../../service/queryKeys';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';
import Selector from '../../../form/select/selector';
import { DEVELOPMENT_GOAL_VALUE, STEP_TITLES } from '../pathwaysPage.types';

const GoalActionModal = ({
  toggle,
  mutation,
  academicYearId,
  goal,
}: ModalToggleProps & {
  mutation: any;
  academicYearId: string;
  goal: any;
}) => {
  const [goalSelected, setGoalSelected] = useState<any>(goal);
  const selectRef = useRef<any>();

  const { data } = useQuery(EBE_GOALS, () => getDevelopmentGoals(undefined, 'ebe'), {
    staleTime: Infinity,
    select: (data) =>
      data.data.data.map((goal: any) => {
        return {
          label: goal.name,
          value: goal.id,
          ...goal,
        };
      }),
    placeholderData: [],
    enabled: goal == null,
  });

  return (
    <Modal>
      <Modal.Body>
        <Selector
          maxLength={200}
          onFocus={() => {
            if (goalSelected) {
              selectRef.current.state.inputValue = goalSelected.label;
            }
          }}
          onBlur={() => {
            selectRef.current.select.select.blur();
          }}
          ref={selectRef}
          id={DEVELOPMENT_GOAL_VALUE}
          className={'sm-field-mb'}
          label={STEP_TITLES[DEVELOPMENT_GOAL_VALUE]}
          hideLabel
          placeholder={'...'}
          onChange={(option: any) => {
            setGoalSelected(
              goal == null
                ? option
                : {
                    ...goal,
                    label: option.label,
                  }
            );
          }}
          isClearable={false}
          isSearchable={true}
          isCreatableSelect={true}
          options={data}
          value={goalSelected}
          customComponents={{
            MenuList: function MenuList(menuListProps) {
              return (
                <MessageAtMenuList message={'Add your own goal by populating the field above.'} {...menuListProps} />
              );
            },
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
        <Modal.ConfirmButton
          type={'button'}
          label={mutation.isLoading ? 'Loading...' : 'Confirm'}
          disabled={!goalSelected || goalSelected.label.length > 200}
          onClick={() =>
            mutation
              .mutateAsync({
                name: DEVELOPMENT_GOAL_VALUE,
                academic_year_id: academicYearId,
                goal: {
                  id: goalSelected['__isNew__'] ? null : goalSelected.value,
                  name: goalSelected.label,
                },
              })
              .then(() => toggle(false))
          }
        />
      </Modal.Footer>
    </Modal>
  );
};

export default GoalActionModal;
