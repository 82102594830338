import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';

const ResetPasswordModal = ({ toggle, email }: ModalToggleProps & { email: string }) => {
  return (
    <Modal>
      <Modal.Body>
        <p className={'mb-3'}>
          We have sent a password reset link to <b>{email}</b>
        </p>
        <p>
          {
            "If you can't find the email in your inbox then please check your spam folder. Otherwise contact EBE for further assistance."
          }
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ConfirmButton label={'Ok'} onClick={() => toggle(false)} />
      </Modal.Footer>
    </Modal>
  );
};

export default ResetPasswordModal;
