import React from 'react';
import { toast } from 'react-toastify';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';

const DeleteNoteModal = ({note, toggle, handleSubmit}: ModalToggleProps & any) => {
  return (
    <Modal>
      <Modal.Body>
        <p>Are you sure you want to remove note <em>{note.name}</em>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={() => {
            toggle(false);
          }}
        />
        <Modal.ConfirmButton
          onClick={() => {
            handleSubmit()
              .then(() => {
                toggle(false);
                toast.success('This Note has been removed successfully.');
              })
              .catch((error: any) => {
                toast.error(error.response.data.message ?? 'An error has occurred.');
              });
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

DeleteNoteModal.displayName = 'DeleteConfirmModal';

export default DeleteNoteModal;
