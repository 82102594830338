import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

interface PortalProps {
  /**
   * Add class to portal container.
   */
  className?: string;
  /**
   * Overrides portal tag.
   * <div> by default.
   */
  tag?: string;
  /**
   * Overrides portal target.
   * document.body by default.
   */
  target?: Node;
  /**
   * Portal content.
   */
  children: React.ReactNode;
}

const Portal = ({ children, className = '', tag = 'div', target = document.body }: PortalProps) => {
  const portal = React.useMemo(() => document.createElement(tag), []);

  if (className) {
    const CLASS_LIST = className.split(' ');
    CLASS_LIST.map((str) => {
      portal.classList.add(str);
    });
  }

  useEffect(() => {
    target.appendChild(portal);
    return () => {
      target.removeChild(portal);
    };
  }, [portal, className, target]);

  return ReactDOM.createPortal(children, portal);
};

export default Portal;
