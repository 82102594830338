export const ROLE_SYS_ADMIN = 'ROLE_SYS_ADMIN';
export const ROLE_TEACHER = 'ROLE_TEACHER';
export const ROLE_MAT_COORDINATOR = 'ROLE_MAT_COORDINATOR';
export const ROLE_SCHOOL_COORDINATOR = 'ROLE_SCHOOL_COORDINATOR';
export const ROLE_TEACHER_FREE = 'ROLE_TEACHER_FREE';
export const ROLE_ASSISTANT_GT_COORDINATOR = 'ROLE_ASSISTANT_GT_COORDINATOR';
export const CURRICULUM_AREA_LEADER = 'Curriculum area leader';
export const OTHER_MIDDLE_LEADER = 'Other middle leader';
export const SCHOOL_SENIOR_LEADER = 'School senior leader';
export const HEADTEACHER_PRINCIPLE = 'Headteacher/principle';

export type RolesType =
  | typeof ROLE_SYS_ADMIN
  | typeof ROLE_TEACHER
  | typeof ROLE_MAT_COORDINATOR
  | typeof ROLE_SCHOOL_COORDINATOR
  | typeof ROLE_TEACHER_FREE
  | typeof ROLE_ASSISTANT_GT_COORDINATOR;

export const RoleDescriptions = [
  CURRICULUM_AREA_LEADER,
  OTHER_MIDDLE_LEADER,
  SCHOOL_SENIOR_LEADER,
  HEADTEACHER_PRINCIPLE,
];

export const ROLE_LABELS = {
  [ROLE_SYS_ADMIN]: 'System admin',
  [ROLE_MAT_COORDINATOR]: 'Group admin',
  [ROLE_SCHOOL_COORDINATOR]: 'Great Teaching Coordinator',
  [ROLE_TEACHER]: 'Teacher',
  [ROLE_TEACHER_FREE]: 'GTT Starter',
  [ROLE_ASSISTANT_GT_COORDINATOR]: 'Assistant GT Coordinator',
};

export const ROUTE_ROLE_MAT_COORDINATOR = 'mat';
export const ROUTE_ROLE_SCHOOL_COORDINATOR = 'school';
export const ROUTE_ROLE_TEACHER = 'teacher';
export const ROUTE_ROLE_TEACHER_FREE = 'teacher-free';

export const SUBSCRIPTION_TYPE_BULK = 'bulk';
export const SUBSCRIPTION_TYPE_PAID = 'paid';

export const ROLES = [
  {
    label: ROLE_LABELS[ROLE_MAT_COORDINATOR],
    value: ROLE_MAT_COORDINATOR,
  },
  {
    label: ROLE_LABELS[ROLE_SCHOOL_COORDINATOR],
    value: ROLE_SCHOOL_COORDINATOR,
  },
  {
    label: ROLE_LABELS[ROLE_TEACHER],
    value: ROLE_TEACHER,
  },
  {
    label: ROLE_LABELS[ROLE_TEACHER_FREE],
    value: ROLE_TEACHER_FREE,
  },
];

export const transformRoleParamToApi = (role: string | null) => {
  switch (role) {
    case ROUTE_ROLE_MAT_COORDINATOR:
      return ROLE_MAT_COORDINATOR;
    case ROUTE_ROLE_SCHOOL_COORDINATOR:
      return ROLE_SCHOOL_COORDINATOR;
    case ROUTE_ROLE_TEACHER:
      return ROLE_TEACHER;
    case ROUTE_ROLE_TEACHER_FREE:
      return ROLE_TEACHER_FREE;
    default:
      return null;
  }
};

export const transformRoleApiToLinkParam = (role: string | null) => {
  switch (role) {
    case ROLE_MAT_COORDINATOR:
      return ROUTE_ROLE_SCHOOL_COORDINATOR;
    case ROLE_SCHOOL_COORDINATOR:
      return ROUTE_ROLE_TEACHER;
    default:
      return null;
  }
};

export const ALL_ROLES = [
  { value: 'ROLE_SYS_ADMIN', label: 'System admin' },
  { value: 'ROLE_MAT_COORDINATOR', label: 'Group admin' },
  { value: 'ROLE_SCHOOL_COORDINATOR', label: 'Great Teaching Coordinator' },
  { value: 'ROLE_TEACHER', label: 'Teacher' },
  { value: 'ROLE_TEACHER_FREE', label: 'GTT Starter' },
  { value: 'ROLE_ASSISTANT_GT_COORDINATOR', label: 'Assistant GT Coordinator' },
];

export const MAT_STATIC_PICTURE_USER = { email: 'jobs@evidencebased.education', schoolId: 146 };
