import React, { useState } from 'react';
import { ColumnType } from '../../../global/table/table.types';
import { AddButton } from '../../../global/button/common';
import Choice from '../../../form/choice/choice';
import DefaultTable from '../../../global/table/defaultTable';
import { ArchivedName } from '../../../global/table/components';
import { ActionButton } from '../../../global/buttonIcon/common';
import Tag from '../../../global/tag/tag';
import { Group } from '../../../@types/Entity/Group';
import CreateUpdateGroupModal from '../modals/CreateUpdateGroupModal';
import { useMutation, useQueryClient } from 'react-query';
import { postOrPutGroup, PostOrPutGroupData } from '../../../service/api/groupsApi';
import { SCHOOLS_GROUPS, USER_MANAGEMENT_LIST } from '../../../service/queryKeys';

const COLUMNS: ColumnType[] = [
  { id: 'name', label: 'Great teaching team name', isSortable: true },
  { id: 'user_name', label: 'Created by', isSortable: true },
  { id: 'members_count', label: 'No. teachers', isSortable: true },
  { id: 'actions', label: 'Actions' },
];

const CREATE_UPDATE_GROUP_MODAL_INITIAL_DATA = { isOpen: false, groupToUpdate: null };

const SchoolGroupsDetailsTable = ({
  data,
  showDisabled,
  handleShowDisabled,
  setOrder,
}: {
  data: Group[];
  showDisabled: boolean;
  handleShowDisabled: () => void;
  setOrder: (s?: string) => void;
}) => {
  const queryClient = useQueryClient();

  const [createUpdateGroupModalData, setCreateUpdateGroupModalData] = useState<{
    isOpen: boolean;
    groupToUpdate: Group | null;
  }>(CREATE_UPDATE_GROUP_MODAL_INITIAL_DATA);

  const postOrPutGroupMutation = useMutation(({ data }: { data: PostOrPutGroupData }) => postOrPutGroup(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(SCHOOLS_GROUPS);
      queryClient.invalidateQueries(USER_MANAGEMENT_LIST);
    },
  });

  return (
    <>
      {createUpdateGroupModalData.isOpen && (
        <CreateUpdateGroupModal
          toggle={() => setCreateUpdateGroupModalData(CREATE_UPDATE_GROUP_MODAL_INITIAL_DATA)}
          groupToUpdate={createUpdateGroupModalData.groupToUpdate}
        />
      )}

      <div className={'flex items-center justify-between field-mb'}>
        <AddButton
          label={'Team'}
          onClick={() => setCreateUpdateGroupModalData({ isOpen: true, groupToUpdate: null })}
        />
        <Choice
          id={'archived-group'}
          onChange={handleShowDisabled}
          checked={showDisabled}
          label={'View archived teams'}
          type={'switch'}
        />
      </div>
      <DefaultTable
        id={'school_groups'}
        showCounter={false}
        apiHandled={true}
        setApiSort={setOrder}
        columns={COLUMNS}
        rows={
          0 === (data?.length ?? 0)
            ? []
            : data.map((group, index) => {
                return {
                  id: `row_schoolGroup--${index}`,
                  cells: [
                    {
                      id: 'name',
                      content: (
                        <>
                          {group.cohort && <Tag className={'mr-5'} content={'Cohort'} />}
                          <ArchivedName archived={!group.enabled} title={group.name} item={'group'} />
                        </>
                      ),
                    },
                    {
                      id: 'user_name',
                      content: (
                        <React.Fragment>
                          <p className={'mb-0'}>{group.user.fullName}</p>
                          <small>{group.user.email}</small>
                        </React.Fragment>
                      ),
                    },
                    { id: 'members_count', content: group.membersCount },
                    {
                      id: 'actions',
                      class: 'actions',
                      content: (
                        <ul className={'actions-list'}>
                          <li>
                            <ActionButton.Change
                              archived={!group.enabled}
                              state={group.enabled}
                              states={{
                                on: { label: 'Enable', icon: 'enable' },
                                off: { label: 'Disable', icon: 'disable' },
                              }}
                              onClick={() =>
                                postOrPutGroupMutation.mutate({ data: { id: group.id, enabled: !group.enabled } })
                              }
                            />
                          </li>
                          <li>
                            <ActionButton.Edit
                              archived={!group.enabled}
                              onClick={() => setCreateUpdateGroupModalData({ isOpen: true, groupToUpdate: group })}
                            />
                          </li>
                        </ul>
                      ),
                    },
                  ],
                };
              })
        }
        noDataMessage={'Start adding teams clicking on "TEAM" button.'}
      />
    </>
  );
};

export default SchoolGroupsDetailsTable;
