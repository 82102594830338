import React from 'react';
import { ElementCardProps } from '../resources/resources';
import routeBuilder from '../../../service/routeBuilder';
import { Link } from 'react-router-dom';
import '../resources/styles.css';

const ElementCard = ({ dimension, element }: ElementCardProps) => {
  return (
    <>
      <div className={'page-container mt-12'}>
        <div className={'bg-white m-auto elementCardBoxShadow w-80 h-40'}>
          <Link className={'m-auto'} to={`${routeBuilder('modelForGreatTeaching').generate()}#${element?.slug}`}>
            <div className="flex place-content-center w-80 h-40 m-auto">
              <div
                className={`m-auto p-2 flex h-12 w-12 justify-center items-center`}
                style={{ backgroundColor: `${dimension?.colour}` }}
              >
                <h1 className={'text-white'}>{element?.elements[0].substring(2, 3)}</h1>
              </div>
              <div className="ml-2 w-40 m-auto">
                <div className="font-bold">{element?.title}</div>
                <div className={'justify-right'}></div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default ElementCard;
