import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getMatSchools, getMe } from '../../../service/api';
import { Document, Image, Page, pdf } from '@react-pdf/renderer';
import { chartToImage } from '../imageFromSvg';
import { saveAs } from 'file-saver';
import DashboardLayout from '../../../layout/dashboard';
import { Section } from '../../common';
import Accordion from '../../../global/accordion/accordion';
import PDFLayout, { PDF_STYLES } from '../../../layout/pdf';
import { MAT_SCHOOLS} from '../../../service/queryKeys';
import SummaryChartSectionWithTabs from '../sections/summaryChartSectionWithTabs';
import { VERSION_B, VERSION_C_D } from '../../instrument/sections/instrumentForm';
import SideTab from "../../../typeform/SideTab";

const MatFeedbackView = () => {
  const [accordionActive, setAccordionActive] = useState(0);
  const [loading, setLoading] = useState(false);

  const { data } = useQuery(MAT_SCHOOLS, () => getMatSchools(), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    keepPreviousData: true,
    placeholderData: [],
  });

  const handleDownload = async () => {
    setLoading(true);

    try {
      const selector = 'summaryChartId-';
      const blob = await pdf(
        <Document>
          <Page size="A4" style={PDF_STYLES.page}>
            <PDFLayout.Header />
            {Array.from(document.querySelectorAll(`svg[id^=${selector}]`)).map((element) => {
              const school = element.id.split('-')[1];
              const surveyVersion = element.id.split('-')[2];
              return (
                <PDFLayout.Section
                  key={school}
                  headline={school}
                  additionalText={surveyVersion ? (surveyVersion === VERSION_B ? VERSION_B : VERSION_C_D) : null}
                >
                  <Image
                    style={{
                      width: '100%',
                      ...PDF_STYLES.image,
                    }}
                    src={() => chartToImage(element as HTMLElement, 1500, 650) as any}
                  />
                </PDFLayout.Section>
              );
            })}
          </Page>
        </Document>
      ).toBlob();

      saveAs(blob, 'school-feedback');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout
      title={'GTT Profile feedback page'}
      // pageAction={
      //   <Button disabled={loading} onClick={handleDownload}>
      //     {loading ? 'Loading...' : 'Download'}
      //   </Button>
      // }
    >
      <Section className={'field-mb'}>
        <SideTab
          formId = {'nFVnOLfm'}
        />
        {data.map((school: any, index: number) => (
          <div key={school.id}>
            <Accordion
              title={school.name}
              isOpen={index === accordionActive}
              handleOpen={() => setAccordionActive(index)}
            >
              <SummaryChartSectionWithTabs school={school} />
            </Accordion>
          </div>
        ))}
      </Section>
    </DashboardLayout>
  );
};
export default MatFeedbackView;
