import React, { useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import { AddButton } from '../../global/button/common';
import useModal from '../../hooks/useModal';
import AddWebinarModal from './modals/addWebinar';
import { Section } from '../common';
import DefaultTable from '../../global/table/defaultTable';
import { WEBINAR_TABLE_COLUMNS, WebinarType } from './webinarPage.types';
import dayjs from 'dayjs';
import { ActionButton } from '../../global/buttonIcon/common';
import DeleteWebinarConfirmModal from './modals/deleteWebinar';
import { LinkStyled } from '../../global/link/link.styled';
import Button from '../../global/button/button';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { WEBINARS } from '../../service/queryKeys';
import { deleteWebinar, getWebinars } from '../../service/api';

const WebinarManagementPage = () => {
  const queryClient = useQueryClient();

  const { data } = useQuery(WEBINARS, () => getWebinars(), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    keepPreviousData: true,
  });

  const removeWebinar = useMutation(deleteWebinar, {
    onSuccess: () => {
      queryClient.invalidateQueries(WEBINARS);
    },
  });

  const [show, setShow] = useState(false);

  const [addWebinarModal, toggleWebinarModal, setWebinarModalProps] = useModal((props: any) => (
    <AddWebinarModal {...props} />
  ));
  const [
    confirmDeleteWebinarModal,
    toggleConfirmDeleteWebinarModal,
    setConfirmDeleteWebinarModalProps,
  ] = useModal((props: any) => <DeleteWebinarConfirmModal {...props} />);

  return (
    <React.Fragment>
      {addWebinarModal}
      {confirmDeleteWebinarModal}
      <DashboardLayout
        title={'Zoom Management'}
        pageAction={
          <AddButton
            label={'Add Webinar'}
            onClick={() => {
              setWebinarModalProps({ title: 'Add new Webinar' });
              toggleWebinarModal(true);
            }}
          />
        }
      >
        <Section>
          <Button className={'mb-4'} disabled={data?.length < 1} onClick={() => setShow(!show)}>
            {show ? 'Hide Webinar links' : 'Show Webinar links'}
          </Button>
          <DefaultTable
            id={'webinarTable'}
            columns={WEBINAR_TABLE_COLUMNS}
            rows={data?.map((webinar: WebinarType) => {
              return {
                id: webinar.title,
                cells: [
                  { id: 'title', content: webinar.title },
                  {
                    id: 'link',
                    content: (
                      <>
                        <LinkStyled href={webinar.url} target={'_blank'}>
                          {show ? webinar.url : 'Link'}
                        </LinkStyled>
                      </>
                    ),
                  },
                  { id: 'webinar_id', content: webinar.webinar_id },
                  { id: 'dateTime', content: dayjs(webinar.started_at).format('YYYY-MM-DD HH:mm') },
                  { id: 'description', content: webinar.description },
                  {
                    id: 'actions',
                    class: 'actions',
                    content: (
                      <ul className={'actions-list'}>
                        <li>
                          <ActionButton.Edit
                            onClick={() => {
                              setWebinarModalProps({
                                title: 'Edit Webinar',
                                webinar: webinar,
                                update: true,
                              });
                              toggleWebinarModal(true);
                            }}
                          />
                        </li>
                        <li>
                          <ActionButton.Remove
                            onClick={() => {
                              setConfirmDeleteWebinarModalProps({
                                title: 'Delete Webinar',
                                handleSubmit: () => removeWebinar.mutateAsync(webinar.id),
                              });
                              toggleConfirmDeleteWebinarModal(true);
                            }}
                          />
                        </li>
                      </ul>
                    ),
                  },
                ],
              };
            })}
          ></DefaultTable>
        </Section>
      </DashboardLayout>
    </React.Fragment>
  );
};
export default WebinarManagementPage;
