import React, { MouseEventHandler } from 'react';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';

const AddButton = ({
  label,
  disabled = false,
  onClick,
  hideLabel,
  required,
  create,
  ...props
}: {
  label?: string;
  onClick?: MouseEventHandler;
  disabled?: boolean;
  hideLabel?: boolean;
  required?: boolean;
  create?: boolean;
}): JSX.Element => (
  <div {...props} className={'flex'}>
    {!hideLabel && label && (
      <span className={'w-24 font-bold py-1'}>
        {label}
        {required && <span className={'text-danger'}>*</span>}
      </span>
    )}
    <ButtonIcon
      style={{ color: 'white', background: disabled ? '#e5e7eb' : '' }}
      label={`${create ? 'Create' : 'Add'} ${label ?? 'Response'}`}
      onClick={onClick}
      icon={'Plus'}
      color={''}
      disabled={disabled}
    />
  </div>
);

export default AddButton;
