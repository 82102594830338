import React, { useState } from 'react';
import DefaultTable from '../../../global/table/defaultTable';
import { ActionButton } from '../../../global/buttonIcon/common';
import { Event } from '../../../@types/Entity/Event';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteEvent, getEvents } from '../../../service/api/eventsApi';
import { EVENTS } from '../../../service/queryKeys';
import Choice from '../../../form/choice/choice';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';

const EventsTable = ({ editAction }: { editAction: (event: Event) => void }) => {
    const queryClient = useQueryClient();
    const [showPreviousEvents, setShowPreviousEvents] = useState(false);

    const eventsQuery = useQuery([EVENTS, showPreviousEvents], () => getEvents(showPreviousEvents), {
        staleTime: Infinity,
        select: (data) => data.data.data,
    });

    const mutation = useMutation(deleteEvent, {
        onSuccess: () => {
            queryClient.invalidateQueries([EVENTS]);
        },
    });

    return (
        <>
            <div className={'flex justify-end items-center mb-5'}>
                <Choice
                    id={'past-events-toggle'}
                    label={'View past events'}
                    type={'switch'}
                    checked={showPreviousEvents}
                    onChange={() => setShowPreviousEvents((state) => !state)}
                    className={'pretty-no-margin'}
                />
            </div>
            <DefaultTable
                id="events-table"
                showCounter={false}
                columns={[
                    { id: 'title', label: 'Title' },
                    { id: 'description', label: 'Description' },
                    { id: 'start_time', label: 'Start time' },
                    { id: 'end_time', label: 'End time' },
                    { id: 'actions', label: 'Actions' },
                ]}
                rows={
                    0 === (eventsQuery.data ?? []).length
                        ? []
                        : (eventsQuery.data ?? []).map((event) => {
                            return {
                                id: `row_event--${event.id}`,
                                cells: [
                                    { id: 'title', content: event.title },
                                    {
                                        id: 'description',
                                        content: (
                                            event.description ? <Editor
                                                readOnly={true}
                                                toolbarHidden={true}
                                                editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(event.description)))}
                                            /> : null
                                        ),
                                    },
                                    { id: 'start_time', content: `${event.date} - ${event.start_time}` },
                                    { id: 'end_time', content: `${event.date} - ${event.end_time}` },
                                    {
                                        id: 'actions',
                                        class: 'actions',
                                        content: (
                                            <ul className={'actions-list'}>
                                                <li>
                                                    <ActionButton.Edit onClick={() => editAction(event)} />
                                                </li>
                                                <li>
                                                    <ActionButton.Remove onClick={() => mutation.mutate(event.id)} />
                                                </li>
                                            </ul>
                                        ),
                                    },
                                ],
                            };
                        })
                }
                noDataMessage={eventsQuery.isFetching ? 'Loading...' : 'Start adding events.'}
            />
        </>
    );
};

export default EventsTable;
