import React from 'react';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { Section } from '../../../common';
import AddButton from '../../components/addButton';
import useModal from '../../../../hooks/useModal';
import { LinkStyled } from '../../../../global/link/link.styled';
import { useMutation, useQueryClient } from 'react-query';
import { downloadV3PathwayResource, shareV3PathwayResource } from '../../../../service/api';
import { PATHWAYS_V3 } from '../../../../service/queryKeys';
import V3ResourceModal from '../../modals/v3/V3ResourceModal';
import ButtonIcon from '../../../../global/buttonIcon/buttonIcon';
import { toast } from 'react-toastify';
import DefaultTable from '../../../../global/table/defaultTable';
import DeleteV3ResourceModal from '../../modals/v3/DeleteV3ResourceModal';
import Empty from '../../../../global/empty/empty';

const V3ResourcesSection = ({
  pathway,
  isDisabled,
  isFree,
}: {
  pathway: V3Pathway;
  isDisabled: boolean;
  isFree?: boolean;
}) => {
  const [resourceModal, toggleResourceModal, setResourceModalProps] = useModal((props: any) => (
    <V3ResourceModal {...props} />
  ));

  const [deleteResourceModal, toggleDeleteResourceModal, setDeleteResourceModalProps] = useModal((props: any) => (
    <DeleteV3ResourceModal {...props} />
  ));

  const queryClient = useQueryClient();
  const shareV3ResourceMutation = useMutation(shareV3PathwayResource, {
    onSuccess: () => {
      queryClient.invalidateQueries([PATHWAYS_V3, pathway.id]);
    },
  });

  return (
    <div>
      <div className={'flex justify-between mb-2'}>
        <span className={'font-bold pr-5 py-1'}>Resources</span>
        {!isFree && (
          <AddButton
            onClick={() => {
              setResourceModalProps({
                title: 'Add resource',
                pathway: pathway,
                preventCloseOnClickOnMask: true,
              });

              toggleResourceModal(true);
            }}
            label={'resources'}
            hideLabel
            disabled={isDisabled}
          />
        )}
      </div>
      <Section>
        {pathway.resources.length === 0 ? (
          <Empty element={'resources'} />
        ) : (
          <DefaultTable
            id={`resources_table`}
            showCounter={false}
            apiHandled={true}
            columns={[
              { id: 'name', label: 'Name' },
              { id: 'actions', label: 'Actions' },
            ]}
            rows={pathway.resources.map((resource) => {
              return {
                id: `row_resource--${resource.id}`,
                cells: [
                  {
                    id: 'name',
                    content: (
                      <LinkStyled
                        href={resource.link ? resource.link : '#'}
                        target={'_blank'}
                        onClick={(e: any) => {
                          if (!resource.link) {
                            e.preventDefault();
                            downloadV3PathwayResource(pathway.id, resource.id, resource.name);
                          }
                        }}
                      >
                        {resource.name}
                      </LinkStyled>
                    ),
                  },
                  {
                    id: 'actions',
                    class: 'actions',
                    content: isFree ? (
                      <></>
                    ) : (
                      <ul className={'actions-list gap-2'}>
                        {pathway.group && (
                          <>
                            {!resource.shared ? (
                              <ButtonIcon
                                icon={'Share'}
                                label={'Share with team'}
                                onClick={() => {
                                  shareV3ResourceMutation
                                    .mutateAsync({
                                      pathwayId: pathway.id,
                                      resourceId: resource.id,
                                      share: true,
                                    })
                                    .then(() => {
                                      toast.success('This resource is now available to your team members');
                                    });
                                }}
                                disabled={isDisabled}
                              />
                            ) : (
                              <ButtonIcon
                                mainColor={'muted'}
                                icon={'Share'}
                                label={'Unshare with team'}
                                onClick={() => {
                                  shareV3ResourceMutation
                                    .mutateAsync({
                                      pathwayId: pathway.id,
                                      resourceId: resource.id,
                                      share: false,
                                    })
                                    .then(() => {
                                      toast.success('This resource is no longer available as a shared resource');
                                    });
                                }}
                                disabled={isDisabled}
                              />
                            )}
                          </>
                        )}
                        <ButtonIcon
                          mainColor={'danger'}
                          icon={'Trash'}
                          label={'Delete'}
                          onClick={() => {
                            setDeleteResourceModalProps({
                              title: 'Delete resource',
                              description: resource.name,
                              resource: resource,
                              pathwayId: pathway.id,
                            });

                            toggleDeleteResourceModal(true);
                          }}
                          disabled={isDisabled}
                        />
                      </ul>
                    ),
                  },
                ],
              };
            })}
          />
        )}
      </Section>
      {resourceModal}
      {deleteResourceModal}
    </div>
  );
};

export default V3ResourcesSection;
