import React, { useState } from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import { useQuery } from 'react-query';
import { getPathwaysElements } from '../../../service/api';
import { PATHWAYS_ELEMENTS } from '../../../service/queryKeys';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Selector from '../../../form/select/selector';
import { DECIDE_FOCUS_VALUE, STEP_TITLES } from '../pathwaysPage.types';

const ElementActionModal = ({
  toggle,
  mutation,
  academicYearId,
  element,
}: ModalToggleProps & {
  mutation: any;
  academicYearId: string;
  element?: any;
}) => {
  const [elementSelected, setElementSelected] = useState<any>(element);

  const { isFetched, data } = useQuery(PATHWAYS_ELEMENTS, () => getPathwaysElements(), {
    select: (data) => data.data.data.elements,
    placeholderData: [],
    staleTime: Infinity,
  });

  return (
    <Modal>
      <Modal.Body>
        <Selector
          id={DECIDE_FOCUS_VALUE}
          className={'sm-field-mb'}
          label={STEP_TITLES[DECIDE_FOCUS_VALUE]}
          hideLabel
          value={elementSelected}
          placeholder={'...'}
          onChange={(option: any) => setElementSelected(option)}
          isClearable={false}
          options={isFetched ? data : []}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
        <Modal.ConfirmButton
          type={'button'}
          label={mutation.isLoading ? 'Loading...' : 'Confirm'}
          disabled={!elementSelected}
          onClick={() =>
            mutation
              .mutateAsync({
                name: DECIDE_FOCUS_VALUE,
                academic_year_id: academicYearId,
                element: elementSelected.value,
              })
              .then(() => toggle(false))
          }
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ElementActionModal;
