import React, { useState } from 'react';
import Empty from '../../../global/empty/empty';
import Button from '../../../global/button/button';
import { AddButton } from '../../../global/button/common';
import { Section } from '../../common';
import { MoreInfo } from '../../../global/tooltip/common';
import { LinkStyled } from '../../../global/link/link.styled';
import Accordion from '../../../global/accordion/accordion';
import { ActionButton } from '../../../global/buttonIcon/common';
import { PathwaysPlansProps, PlanProps } from '../../pathway/pathwaysPage.types';
import { SectionInAccordion } from '../../pathway/components';
import DefaultTable from '../../../global/table/defaultTable';
import useModal from '../../../hooks/useModal';
import StepsModal from '../modals/stepsModal';
import PlansModal from '../modals/plansModal';
import TeamsModal from '../modals/teamsModal';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PLANS } from '../../../service/queryKeys';
import DeletePlanConfirmModal from '../modals/DeletePlanConfirmModal';
import { deletePlan, getDevelopmentPlans } from '../../../service/api';
import dayjs from 'dayjs';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS, SIZE_ICON } from '../../../resources/vars';
import { ButtonIconStyled } from '../../../global/buttonIcon/buttonIcon.styled';

const PathwayPlans = ({ isAdmin = false }: PathwaysPlansProps) => {
  const [accordionOpen, setAccordionOpen] = useState<number | null>();
  const [nestedAccordionOpen, setNestedAccordionOpen] = useState<number | null>();

  const [stepsModal, toggleStepsModal, setStepsModalProps] = useModal((props: any) => <StepsModal {...props} />);
  const [planModal, togglePlanModal, setPlanModalProps] = useModal((props: any) => <PlansModal {...props} />);
  const [teamsModal, toggleTeamsModal, setTeamsModalProps] = useModal((props: any) => <TeamsModal {...props} />);
  const [deletePlanModal, toggleDeletePlanModal, setDeletePlanModalProps] = useModal((props: any) => (
    <DeletePlanConfirmModal {...props} />
  ));

  const togglePlan = (title: string, plan?: any, size?: string, description: any = null) => {
    setPlanModalProps({
      title,
      plan,
      size,
      description,
    });
    togglePlanModal(true);
  };

  const queryClient = useQueryClient();

  const deletePlanMutation = useMutation(deletePlan, {
    onSuccess: () => {
      queryClient.invalidateQueries(PLANS);
    },
  });

  const { data } = useQuery(PLANS, () => getDevelopmentPlans(), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    keepPreviousData: true,
  });

  const toggleSteps = (
    title: string,
    description: string,
    plan: any,
    timeframe: any,
    steps?: any,
    update?: boolean,
    size?: string,
    preventCloseOnClickOnMask = true
  ) => {
    setStepsModalProps({
      title,
      description,
      plan,
      timeframe,
      steps,
      update,
      size,
      preventCloseOnClickOnMask,
    });
    toggleStepsModal(true);
  };

  const toggleTeams = (title = 'Assign teams', plan?: any, description: any = null, update = false) => {
    setTeamsModalProps({
      title,
      plan,
      description,
      update,
    });
    toggleTeamsModal(true);
  };

  return (
    <Section
      className={'field-mb'}
      id={'plans'}
      headline={<div className={'flex inline-flex gap-1'}>GTT Implementation plans</div>}
      more={
        <div className={'flex flex-inline gap-2 justify-end'}>
          {0 < data?.length ? `${data?.length} ${1 !== data?.length ? 'plans' : 'plan'}` : ''}
          <MoreInfo
            content={
              <>
                For more on school development plans, click{' '}
                <LinkStyled
                  href={
                    'https://support.evidencebased.education/en/knowledge/how-should-i-implement-the-gtt-in-my-school-or-college'
                  }
                  target={'_blank'}
                >
                  here
                </LinkStyled>
              </>
            }
          />
        </div>
      }
    >
      {planModal}
      {teamsModal}
      {stepsModal}
      {deletePlanModal}
      <>
        {data?.length === 0 ? (
          <div className={'text-center text-muted mx-auto'}>
            <Icon icon={GLOBAL_ICONS.empty} elementSize={40} color={'muted'} container={false} className={'mx-auto'} />
            <p className={'italic mt-2'}>
              You haven&apos;t created a plan for implementing the GTT in your school yet.
            </p>
            <p className={'italic mt-2'}>
              <Button
                onClick={() => togglePlan('Add Plan', {}, 'md', null)}
                asLink
                style={{ fontStyle: 'italic', padding: 5 }}
              >
                Click here
              </Button>
              to get started.
            </p>
          </div>
        ) : (
          <>
            {data?.map((plan: PlanProps, index: number) => (
              <div key={`plan_${plan.id}`}>
                <Accordion
                  isOpen={accordionOpen === index}
                  handleOpen={() => {
                    if (accordionOpen === index) {
                      /*When closing the parent Accordion, all the children will be closed too.*/
                      setAccordionOpen(null);
                      setNestedAccordionOpen(null);
                    } else {
                      setNestedAccordionOpen(null);
                      setAccordionOpen(index);
                    }
                  }}
                  title={
                    <div className={'flex items-center justify-between w-full'}>
                      <div className={'flex items-center'}>
                        <span className={'text-muted break-words'}>{plan.name}</span>
                      </div>
                      {isAdmin && (
                        <div className={'flex items-center gap-3'}>
                          {/*Assign a team Button*/}
                          <Button
                            asLink
                            style={{ padding: 0 }}
                            onClick={() => {
                              toggleTeams(
                                'Assign to all teachers or a team',
                                {
                                  ...plan,
                                  developmentPeriods: plan.developmentPeriods.map((timeframe: any) => timeframe.id),
                                  teams: plan.teams.map((team: any) => team.id),
                                },
                                `Assign ${plan.name}`,
                                plan.teams.length > 0
                              );
                            }}
                          >
                            Assign to teachers
                          </Button>
                          {/*Edit Plan Button*/}
                          <ActionButton.Edit
                            onClick={() => {
                              togglePlan(
                                'Update plan',
                                {
                                  ...plan,
                                  developmentPeriods: plan.developmentPeriods.map((timeframe: any) => timeframe.id),
                                  teams: plan.teams.map((team: any) => team.id),
                                },
                                'md',
                                `Update plan ${plan.name}`
                              );
                            }}
                          />
                          {/*Delete Plan Button*/}
                          <ActionButton.Remove
                            onClick={() => {
                              setDeletePlanModalProps({
                                handleSubmit: () => deletePlanMutation.mutateAsync(plan.id),
                                title: 'Delete Plan',
                                plan,
                              });
                              toggleDeletePlanModal(true);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  }
                >
                  {/*{Plan description}*/}
                  <SectionInAccordion className={'mb-4'} title={'Description'}>
                    <ul className={'list-decimal list-inside'}>{plan.description}</ul>
                  </SectionInAccordion>
                  {/*{Assigned teams}*/}
                  {isAdmin && plan.showToAll ? (
                    <SectionInAccordion className={'mb-8'} title={'Assigned teachers'}>
                      <p className={'list-decimal list-inside'}>All teachers</p>
                    </SectionInAccordion>
                  ) : plan.teams.length > 0 ? (
                    <SectionInAccordion className={'mb-8'} title={'Assigned teachers'}>
                      <p className={'list-decimal list-inside'}>
                        {plan.teams.map((team: any, index: any, number: any) => {
                          if (index + 1 === number.length) {
                            return (
                              <li key={`team_${team.id}`} className={'inline-block'}>
                                {team.name}
                              </li>
                            );
                          } else {
                            return (
                              <li key={`team_${team.id}`} className={'inline-block'}>
                                {team.name},&nbsp;{' '}
                              </li>
                            );
                          }
                        })}
                      </p>
                    </SectionInAccordion>
                  ) : (
                    <SectionInAccordion className={'mb-8'} title={'Assigned teachers'}>
                      <p className={'list-decimal list-inside'}>-</p>
                    </SectionInAccordion>
                  )}
                  {plan.developmentPeriods.map((timeframe: any, index: number) => (
                    <div key={`timeframe_${timeframe.id}`}>
                      <Accordion
                        isOpen={nestedAccordionOpen === index}
                        handleOpen={() => {
                          if (nestedAccordionOpen === index) {
                            setNestedAccordionOpen(null);
                          } else {
                            setNestedAccordionOpen(index);
                          }
                        }}
                        title={
                          <div className={'flex items-center justify-between w-full'}>
                            <div className={'flex items-center'}>
                              <span className={'text-muted italic ml-10'}>{timeframe.name}</span>
                            </div>
                          </div>
                        }
                      >
                        <div className={'col-span-2'}>
                          <DefaultTable
                            id={`timeframe_${timeframe.id}--steps`}
                            additionalContainerClasses={'max-h-96 border '}
                            additionalTableClasses={''}
                            itemCounter={{ single: 'step', plural: 'steps' }}
                            noDataMessage={
                              isAdmin ? (
                                <>
                                  <Button
                                    onClick={() => {
                                      toggleStepsModal(true);
                                      setStepsModalProps({
                                        title: `Add steps to ${plan.name}`,
                                        description: `${timeframe.name}`,
                                        size: 'lg',
                                        plan,
                                        timeframe,
                                        steps: timeframe.steps,
                                      });
                                    }}
                                    asLink
                                    style={{ fontStyle: 'italic', padding: 5 }}
                                  >
                                    Click here
                                  </Button>{' '}
                                  in order to start adding steps.
                                </>
                              ) : (
                                <Empty who={'your school does not'} element={'steps for this plan'} />
                              )
                            }
                            columns={[
                              { id: 'pathwayStep', label: 'Development cycle step' },
                              { id: 'method', label: 'Method' },
                              { id: 'activityType', label: 'Activity type' },
                              { id: 'start_date', label: 'Start date' },
                              { id: 'end_date', label: 'End date' },
                            ]}
                            rows={
                              !timeframe.steps
                                ? []
                                : timeframe.steps.map((step: any) => {
                                    return {
                                      id: `step_row--${index}`,
                                      cells: [
                                        { id: 'pathwayStep', content: step.pathwayStep },
                                        {
                                          id: 'method',
                                          content:
                                            step.method?.length > 0 ? (
                                              step.method.map((method: any, index: any, number: any) => {
                                                if (index + 1 != number.length) {
                                                  return (
                                                    <li key={`method_${index}`} className={'inline-block'}>
                                                      {method},&nbsp;
                                                    </li>
                                                  );
                                                } else {
                                                  return (
                                                    <li key={`method_${index}`} className={'inline-block'}>
                                                      {method}
                                                    </li>
                                                  );
                                                }
                                              })
                                            ) : (
                                              <>-</>
                                            ),
                                        },
                                        {
                                          id: 'activityType',
                                          content:
                                            step.activityType?.length > 0 ? (
                                              step.activityType.map((method: any, index: any, number: any) => {
                                                if (index + 1 != number.length) {
                                                  return (
                                                    <li key={`activityType${index}`} className={'inline-block'}>
                                                      {method},&nbsp;
                                                    </li>
                                                  );
                                                } else {
                                                  return (
                                                    <li key={`activityType${index}`} className={'inline-block'}>
                                                      {method}
                                                    </li>
                                                  );
                                                }
                                              })
                                            ) : (
                                              <>-</>
                                            ),
                                        },
                                        { id: 'start_date', content: step.start_date },
                                        { id: 'end_date', content: step.end_date },
                                      ],
                                    };
                                  })
                            }
                          />
                        </div>
                        {isAdmin && (
                          <div className={'flex items-center justify-end field-mb space-x-4'}>
                            <AddButton
                              isOutline
                              mainColor={'secondary'}
                              label={'EDIT STEPS'}
                              disabled={timeframe.steps?.length < 1}
                              onClick={() => {
                                toggleSteps(
                                  `Edit steps in ${plan.name}`,
                                  timeframe.name,
                                  plan,
                                  timeframe,
                                  {
                                    steps: timeframe.steps.map((step: any) => ({
                                      id: step.id,
                                      pathwayStep: step.pathwayStep,
                                      method: step.method,
                                      activityType: step.activityType,
                                      start_date: dayjs(step.start_date, 'DD/MM/YYYY').toDate(),
                                      end_date: dayjs(step.end_date, 'DD/MM/YYYY').toDate(),
                                    })),
                                  },
                                  true,
                                  'lg'
                                );
                              }}
                            />
                            <AddButton
                              isOutline
                              mainColor={'secondary'}
                              label={'ADD STEPS'}
                              onClick={() => {
                                toggleSteps(
                                  `Add steps to ${plan.name}`,
                                  timeframe.name,
                                  plan,
                                  timeframe,
                                  {},
                                  false,
                                  'lg'
                                );
                              }}
                            />
                          </div>
                        )}
                      </Accordion>
                    </div>
                  ))}
                </Accordion>
              </div>
            ))}
          </>
        )}
        {isAdmin && (
          <div className={'flex justify-end mt-8 items-center'}>
            <ButtonIconStyled id={'stonly_4'} size={'md'} mainColor={'secondary'} isFree={true} className={'mr-4'}>
              <Icon elementSize={SIZE_ICON['md']} icon={GLOBAL_ICONS['help']} container={false} />
            </ButtonIconStyled>

            <AddButton
              onClick={() => {
                togglePlan('Add plan', {}, 'md', null);
              }}
              label={'Plan'}
            />
          </div>
        )}
      </>
    </Section>
  );
};

export default PathwayPlans;
