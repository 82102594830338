import { InputHTMLAttributes, LabelHTMLAttributes } from 'react';
import styled, { ThemeProps } from 'styled-components';
import { InputProps, LabelProps } from './field.types';
import { getBaseFieldStyle, getFocusStyle, getMainColor } from '../../helpers/styles';
import { DEFAULT_THEME } from '../../Theme';

export const InputStyled = styled('input').attrs((props: InputHTMLAttributes<any>) => ({
  ...props,
}))<InputProps & ThemeProps<any> & { $hasIcon: boolean; $hasComponent: boolean; as?: any }>`
  ${(props) => getBaseFieldStyle(props, props.$hasIcon, props.$hasComponent, 'textarea' === props?.as || false)}
  &:focus {
    border-color: ${(props) => props.theme.colors.primary};
    ${(props) => getFocusStyle(getMainColor(props))}
  }
`;

InputStyled.defaultProps = {
  theme: DEFAULT_THEME,
};

export const LabelStyled = styled.label.attrs((props: LabelHTMLAttributes<any>) => ({
  ...props,
}))<LabelProps>`
  font-weight: bold;
  display: block;
  margin-bottom: ${(props) => (props.description ? '0' : '0.5rem')};
`;
