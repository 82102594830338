import React from 'react';

type SummarySectionProps = {
  chart: React.ReactNode;
  status: React.ReactNode;
};

const SummarySection = ({ chart, status }: SummarySectionProps) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div className={'flex w-full lg:w-2/4 border-b lg:border-b-0 lg:border-r mb-8 lg:mb-0 pr-0 lg:pr-4'}>{chart}</div>
      <div className={'w-full lg:w-2/4 pl-0 lg:pl-4'}>{status}</div>
    </div>
  );
};

export default SummarySection;
