import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import Button from '../../../global/button/button';
import { testDataOfSomeIcons } from '../resources/types';
import Icon from '../../../global/icon/icon';

const IconListModal = ({ toggle, setIcon }: ModalToggleProps & { setIcon: any }) => {
  return (
    <Modal>
      <Modal.Body>
        <div className={'flex flex-wrap'}>
          {testDataOfSomeIcons.map((icon: any) => (
            <Icon
              key={Math.random()}
              onClick={() => {
                setIcon(icon);
                toggle(false);
              }}
              elementSize={50}
              className={'m-4'}
              icon={icon}
              container={false}
            />
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => toggle(false)} className={'m-2'}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default IconListModal;
