import React, { useState } from 'react';
import Scrollspy from 'react-scrollspy';
import { ScrollSpyProps } from './scrollSpy.types';
import { DEFAULT_TRANSITION_DURATION, DEFAULT_TRANSITION_FUNC } from '../transitions/transitions.types';

const HEIGHT = 2.5;

const ScrollSpy = ({ options }: ScrollSpyProps): any => {
  const [index, setIndex] = useState(0);

  return (
    <div className={'sticky top-10'}>
      <div
        className={'absolute border-l-2 border-primary transition duration-300 ease-in-out'}
        style={{
          height: `${HEIGHT}rem`,
          top: `${HEIGHT * index}rem`,
          transition: `top ${DEFAULT_TRANSITION_DURATION}ms ${DEFAULT_TRANSITION_FUNC}`,
        }}
      />
      <Scrollspy
        rootEl={'#root'}
        items={options.map((option) => {
          return option.value;
        })}
        currentClassName="is-current"
        onUpdate={(event) => {
          const item_index = options.findIndex((option) => option.value === event?.id);
          if (item_index >= 0) {
            setIndex(item_index);
          }
        }}
      >
        {options.map((option) => (
          <li key={`scrollspy_${option.value}`}>
            <a
              href={`#${option.value}`}
              className={'hover:bg-white hover:bg-opacity-50 block border-l-2 border-white text-left pl-5'}
              style={{
                height: `${HEIGHT}rem`,
                lineHeight: `${HEIGHT}rem`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {option.label}
            </a>
          </li>
        ))}
      </Scrollspy>
    </div>
  );
};

ScrollSpy.displayName = 'ScrollSpy';

export default ScrollSpy;
