import React from 'react';
import { UserType } from '../../users/userManagementPage';
import { Section } from '../../common';
import { LinkStyled } from '../../../global/link/link.styled';

const TeacherFreeDeleteForm = () => {
  return (
    <Section headline={'Delete my Starter Account'}>
          <div className={'field-mb'}>
            <p>To delete your Starter Account, simply <LinkStyled href={'mailto:support@evidencebased.education?subject=Starter Account Deletion Request' } target={'_blank'}>contact us here</LinkStyled> and we will do the rest!</p>
            <p>Do bear in mind that once we have done this, you will not be able to access any of your saved information, survey data or course progress, though; if you were to come back to the Great Teaching Toolkit, you’d have to start from scratch.</p>
          </div>
    </Section>
  );
};

export default TeacherFreeDeleteForm;
