import React from 'react';
import classnames from 'classnames';
import Icon from '../../icon/icon';
import Tooltip from '../../tooltip/tooltip';

type FilterProps = {
  title: string;
  onClick?: () => void;
  content: any;
  active: boolean;
  disabled: boolean;
};

const Filter = ({ title, onClick = () => null, content, active, disabled }: FilterProps) => {
  return (
    <Tooltip
      content={<div className={'py-1 px-2'}>{content}</div>}
      theme={'light-border'}
      trigger={'click'}
      placement={'bottom-end'}
    >
      <button
        disabled={disabled}
        className={`${classnames({ 'opacity-25 cursor-default': disabled })} relative py-1 px-2`}
        onClick={onClick}
        title={!disabled ? `Filter by ${title}` : ''}
      >
        {active && (
          <div className={'absolute top-1 right-1'}>
            <Icon icon={'CircleFill'} color={'danger'} elementSize={8} container={false} />
          </div>
        )}
        <Icon icon={'FilterRight'} container={false} elementSize={20} />
      </button>
    </Tooltip>
  );
};

Filter.displayName = 'Filter';

export default Filter;
