import React from 'react';
import { HamburgerProps } from './hamburger.types';
import { HamburgerButtonStyled, ChevronHamburgerStyled } from './hamburger.styled';

const ChevronHamburger = ({
  handleClick,
  mainColor = 'primary',
  size = 'md',
  toggled = false,
  as = 'button',
  transparent = false,
  ...other
}: HamburgerProps): JSX.Element => (
  <HamburgerButtonStyled
    as={as}
    aria-pressed={toggled}
    tabIndex={as === 'button' ? 0 : undefined}
    onClick={() => {
      if (handleClick) {
        handleClick();
      }
    }}
    mainColor={mainColor}
    $size={size}
    $transparent={transparent}
  >
    <ChevronHamburgerStyled
      aria-hidden={'true'}
      mainColor={mainColor}
      $size={size}
      $toggled={toggled}
      {...(other as any)}
    />
  </HamburgerButtonStyled>
);

export default ChevronHamburger;
