import React, {useEffect, useState} from 'react';
import SummaryChartSection from './summaryChartSection';
import FeedbackLayout from '../components/feedbackLayout';
import { useQuery } from 'react-query';
import { FEEDBACK_PERIOD } from '../../../service/queryKeys';
import { getFeedbackPeriod } from '../../../service/api';
import Alert from '../../../global/alert/alert';

type Props = {
  school: {
    id: number;
    name: string;
  };
};

const SummaryChartSectionWithTabs = ({ school }: Props): JSX.Element => {
  const [activeVersion, setActiveVersion] = useState<string>();

  const {isFetched, data: feedbackPeriodData} = useQuery([FEEDBACK_PERIOD, school.id], () => getFeedbackPeriod(undefined, school.id), {
    staleTime: Infinity,
    select: (data) => data.data.data,
  });

  useEffect(() => {
    if (isFetched && feedbackPeriodData?.surveyVersions.length > 0) {
      setActiveVersion(feedbackPeriodData.surveyVersions[0].value);
    }
  }, [isFetched]);

  if (!isFetched) {
    return <></>;
  }

  if (!activeVersion) {
    return (
      <Alert type="info" className={'field-mb'}>
        There are no completed surveys for the current term.
      </Alert>
    );
  }

  return (
    <FeedbackLayout
      background={'gray'}
      showTabs={isFetched ? feedbackPeriodData?.surveyVersions.length > 1 : false}
      items={isFetched ? feedbackPeriodData?.surveyVersions : []}
      active={activeVersion}
      clickOnTab={(value: string) => setActiveVersion(value)}
    >
      <SummaryChartSection
        schoolId={school.id}
        id={`summaryChartId-${school.name}-${activeVersion}`}
        surveyVersion={activeVersion}
      />
    </FeedbackLayout>
  );
};

export default SummaryChartSectionWithTabs;
