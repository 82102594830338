import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import Tab from '../../global/tab/tab';
import MyVideosTab from './sections/myVideosTab';
import Search from '../../form/search/search';
import { AddButton } from '../../global/button/common';
import SharedVideosTab from './sections/sharedVideosTab';
import { useQuery } from 'react-query';
import { VIDEO_LIBRARY } from '../../service/queryKeys';
import { getVideos } from '../../service/api';
import useModal from '../../hooks/useModal';
import UploadVideoModal from './modals/UploadVideoModal';
import { Section } from '../common';
import Alert from '../../global/alert/alert';
import { LinkStyled } from '../../global/link/link.styled';
import ConsentParagraphDoc from '../../resources/docs/Consent_paragraph.docx';
import DraftConsentFormDoc from '../../resources/docs/Draft Consent form - Teaching and Learning.docx';
import SideTab from '../../typeform/SideTab';
import { useNavigate } from 'react-router-dom';
import routeBuilder from '../../service/routeBuilder';

const MY_VIDEOS_TAB = 1;
const SHARED_VIDEOS_TAB = 2;

export const TABS = [
  {
    value: MY_VIDEOS_TAB,
    label: 'My videos',
  },
  {
    value: SHARED_VIDEOS_TAB,
    label: 'Shared videos',
  },
];

const VideoObservationPage = ({ userId, isFree }: { userId: string; isFree?: boolean }) => {
  const [activeTab, setActiveTab] = useState<string | number>(MY_VIDEOS_TAB);
  const [searchValue, setSearchValue] = useState<string>('');

  const { data: videos, isFetching, isFetched } = useQuery(
    [VIDEO_LIBRARY, activeTab, searchValue],
    () => getVideos(SHARED_VIDEOS_TAB === activeTab, searchValue),
    {
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (isFree && videos?.length > 0) {
      const videoId = videos && videos.length > 0 ? videos[0].id : undefined;
      navigate(routeBuilder('videoPlayer').generate(videoId));
    }
  }, [videos]);

  const [uploadVideoModal, toggleUploadVideoModal] = useModal((props: any) => <UploadVideoModal {...props} />, {
    title: 'Upload video',
    size: 'lg',
    preventCloseOnClickOnMask: true,
  });

  if (isFree)
    return (
      <DashboardLayout title={'Video Feedback'}>
        <></>
      </DashboardLayout>
    );

  return (
    <DashboardLayout title={'Video Feedback'}>
      <Alert type={'info'} className={'field-mb'}>
        <p>
          Our video feedback tool is powered by VEO. VEO hosts and processes all data uploaded in this area. As outlined
          in our EULA, by continuing to use the video tool, you express your consent to VEO&apos;s{' '}
          <LinkStyled target={'_blank'} href={'https://veo.co.uk/terms-of-service/'}>
            Terms of Service
          </LinkStyled>
          . You can read more about their data security measures{' '}
          <LinkStyled target={'_blank'} href={'https://veo.co.uk/security'}>
            here
          </LinkStyled>
          .
        </p>
        <br />
        <p>
          We recommend, if at all possible, not filming the faces of students, so as to mitigate any potential
          safeguarding risks. However, if you need to, you must have consent of those being filmed, or their
          parent/guardian.
        </p>
        <br />
        <p>
          Your school or college may already have a relevant safeguarding policy in place, but should you need support
          with a template consent form, you can access some draft text about your use of the GTT and VEO{' '}
          <LinkStyled href={ConsentParagraphDoc} target={'_blank'}>
            here
          </LinkStyled>
          , or a separate consent letter template{' '}
          <LinkStyled href={DraftConsentFormDoc} target={'_blank'}>
            here
          </LinkStyled>
          .
        </p>
      </Alert>
      {uploadVideoModal}
      <div className={'field-mb'}>
        <SideTab formId={'VTI7A3Yr'} />
        <Section size={null}>
          <Tab
            items={TABS}
            active={activeTab}
            clickOnTab={(value) => {
              setActiveTab(value);
            }}
            parent={'videoObservationPage'}
          >
            <div className={'bg-gray-100 p-2'}>
              <div className={'md:flex md:justify-between pb-5 gap-5'}>
                <Search
                  id={'search_video'}
                  hideLabel
                  placeholder={'Search Videos'}
                  value={searchValue}
                  onChange={(event) => setSearchValue(event.target.value)}
                  onRemove={() => setSearchValue('')}
                />
                <div className={'flex justify-end items-center'}>
                  <AddButton
                    id={'videoObservationPage_uploadVideoButton'}
                    label={'Upload video'}
                    onClick={() => toggleUploadVideoModal(true)}
                  />
                </div>
              </div>
              <Section size={null}>
                <div className={'section-container'}>
                  {!isFetched && isFetching ? (
                    <span className={'text-muted flex justify-center'}>Loading...</span>
                  ) : isFetched && !isFetching && (videos ?? []).length > 0 ? (
                    <>
                      <Tab.Content id={MY_VIDEOS_TAB} active={activeTab}>
                        <MyVideosTab videos={videos} userId={userId} />
                      </Tab.Content>
                      <Tab.Content id={SHARED_VIDEOS_TAB} active={activeTab}>
                        <SharedVideosTab videos={videos} userId={userId} />
                      </Tab.Content>
                    </>
                  ) : (
                    <p className={'text-md italic text-center'}>No videos</p>
                  )}
                </div>
              </Section>
            </div>
          </Tab>
        </Section>
      </div>
    </DashboardLayout>
  );
};

export type VideoType = {
  id: number;
  title: string;
  created_at: string;
  thumbnail_path: string;
  created_by: {
    id: string | number;
    name: string;
  };
  tags_num?: number;
  highlights: {
    shared?: true;
    feedback?: true;
  };
  shared_with?: Array<{
    id: number | string;
    name: string;
    type: string;
  }>;
};

export default VideoObservationPage;
