import React from 'react';
import classnames from 'classnames';
import EBE_LOGO from '@app/asset/images/EBE_Logo-white.png';
import EBE_LOGO_DARK from '@app/asset/images/EBE_Logo-dark.png';
import { LinkStyled } from '../../global/link/link.styled';
import EulaPdf from '../../resources/pdfs/2022-06_End_User_Licence_Agreement_-_GTT.pdf';

type FooterContentType = {
  /**
   * Prints logo.
   */
  showLogo?: boolean;
  /**
   * Prints logo at copyright line.
   */
  showLogoAtCopyright?: boolean;
  /**
   * Sets dark background.
   */
  dark?: boolean;
};

const FooterContent = ({
  showLogo = true,
  showLogoAtCopyright = true,
  dark = true,
}: FooterContentType): JSX.Element => {
  const START_YEAR = '2021';
  const THIS_YEAR = new Date().getFullYear();

  return (
    <React.Fragment>
      {showLogo && (
        <div className={'block-container'}>
          <div className={'w-60 mx-auto'}>
            <a href={'https://www.evidencebased.education'} target={'_blank'} rel="noopener noreferrer">
              <img src={EBE_LOGO} alt={'Evidence Based Education'} width={'100%'} height={'auto'} />
            </a>
          </div>
        </div>
      )}
      <div className={`block-container ${classnames({ 'text-gray-200 bg-gray-600': dark })}`}>
        <div className={'sm:flex items-center justify-center text-sm'}>
          <div className={'flex items-center justify-center'}>
            Copyright © {START_YEAR !== THIS_YEAR.toString() && `${START_YEAR}-`}
            {THIS_YEAR}{' '}
            {showLogoAtCopyright && (
              <div className={'ml-2'} style={{ width: 150 }}>
                <a href={'https://www.evidencebased.education'} target={'_blank'} rel="noopener noreferrer">
                  <img src={EBE_LOGO_DARK} alt={'Evidence Based Education'} width={'100%'} height={'auto'} />
                </a>
              </div>
            )}
          </div>
          <div className={'text-center sm:ml-2'}>
            <LinkStyled href={'https://evidencebased.education/privacy-policy'} target={'_blank'}>
              Privacy Policy
            </LinkStyled>{' '}
            |{' '}
            <LinkStyled href={EulaPdf} target={'_blank'}>
              End User License Agreement
            </LinkStyled>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FooterContent;
