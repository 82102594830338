import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from "react-query";
import { PATHWAYS_V2, PATHWAYS_V2_STEP } from "../../../service/queryKeys";
import { completePathways } from "../../../service/api";

const CompletePathwaysModal = ({ toggle }: ModalToggleProps) => {
  const queryClient = useQueryClient();
  const { mutate: completePathwaysMutate, isLoading } = useMutation(completePathways, {
    onSuccess: () => {
      toggle(false)
      setTimeout(() => {
        queryClient.invalidateQueries(PATHWAYS_V2_STEP)
      }, 1000)
      queryClient.invalidateQueries(PATHWAYS_V2)
    },
  });

  return (
    <Modal>
      <Modal.Body>
        <p className={'mb-4'}>
          Are you sure that you want to continue and reset this development cycle? Once you have completed it, you
          won&apos;t be able to edit it.
        </p>
        <p>
          You can view completed development cycles in your CPD passport.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)}/>
        <Modal.ConfirmButton
          onClick={() => {
            completePathwaysMutate()
          }}
          type={'button'}
          label={isLoading ? 'Loading...' : 'Continue & Reset'}
          disabled={isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default CompletePathwaysModal;
