import React from 'react';
import { ModalProps } from '../../../global/messages/modal/modal.types';
import ModalComponents from '../../../global/messages/modal/modal.components';
import Input from '../../../form/input/input';
import * as Yup from 'yup';
import { debounce } from 'lodash';
import { getCheckGroupNameIsValid } from '../../../service/api';
import { Checkbox } from 'pretty-checkbox-react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { postOrPutGroup, PostOrPutGroupData } from '../../../service/api/groupsApi';
import { SCHOOL_TEACHERS, SCHOOLS_GROUPS, USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import Modal from '../../../global/messages/modal/modal';
import { ApiResponse, Option } from '../../../@types/global';
import { Group } from '../../../@types/Entity/Group';
import Selector from '../../../form/select/selector';
import { TEAMS_MODE_CONTEXT_TEAMS_MODES } from '../../../contexts/TeamsContext/TeamsModeContext';
import { User } from '../../../@types/Entity/User';
import { getSchoolTeachers, getSchoolTeachersWithoutMentee } from '../../../service/api/schoolApi';

const getCheckGroupNameIsValidDebounced = debounce(async (value, idToIgnore, resolve) => {
  try {
    const response = await getCheckGroupNameIsValid(value as string, idToIgnore);
    resolve(response.data.data.valid);
  } catch (error) {
    resolve(false);
  }
}, 500);

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required()
    .max(64)
    .trim()
    .test(
      'exists',
      'You already have a team with this name, please specify a different team name.',
      async (value, input) =>
        new Promise((resolve) => getCheckGroupNameIsValidDebounced(value, input.parent?.id ?? null, resolve))
    ),
  cohort: Yup.bool(),
});

export default function CreateUpdateGroupModal({ toggle, groupToUpdate }: Props) {
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    register,
    control,

    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues: {
      name: '',
      cohort: false,
    },
    values:
      null != groupToUpdate
        ? {
            id: groupToUpdate.id,
            name: groupToUpdate.name,
            cohort: groupToUpdate.cohort,
            members: groupToUpdate.members.map((member) => ({ label: member.fullName, value: member.id })),
          }
        : undefined,
  });

  const getSchoolTeachersQuery = useQuery<ApiResponse<User[]>>(
    [SCHOOL_TEACHERS, { mode: TEAMS_MODE_CONTEXT_TEAMS_MODES.greatTeachingTeams }],
    () => getSchoolTeachers()
  );

  const postOrPutGroupMutation = useMutation(({ data }: { data: PostOrPutGroupData }) => postOrPutGroup(data), {
    onSuccess: () => {
      queryClient.invalidateQueries(SCHOOLS_GROUPS);
      queryClient.invalidateQueries(USER_MANAGEMENT_LIST);
      toggle();
    },
  });

  return (
    <Modal open toggle={() => toggle()} preventCloseOnClickOnMask>
      <form
        onSubmit={handleSubmit((values) =>
          postOrPutGroupMutation.mutate({
            data: {
              id: values.id,
              name: values.name,
              cohort: values.cohort,
              members: values.members?.map((member) => member.value),
            },
          })
        )}
      >
        <ModalComponents.Body>
          <div className={'field-mb'}>
            <Input
              {...register('name')}
              required
              id={'name'}
              label={'Team Name'}
              placeholder={'Please enter a name for this team'}
              error={errors.name?.message}
            />
          </div>
          <div className={'field-mb'}>
            <Checkbox {...register('cohort')} color={'primary'} id={'cohort'}>
              <strong>This is a cohort of teachers</strong>
            </Checkbox>
          </div>
          <div className={'field-mb'}>
            <Controller
              control={control}
              name={'members'}
              render={({ field: { onChange, ...field } }) => (
                <Selector
                  {...field}
                  id={'members'}
                  label={'Team members'}
                  error={errors.members?.message}
                  isMulti
                  options={
                    getSchoolTeachersQuery.data?.data.map((teacher) => ({
                      label: teacher.fullName,
                      value: teacher.id,
                    })) ?? []
                  }
                  onChange={(value: Option) => onChange(value)}
                  isLoading={!getSchoolTeachersQuery.isFetched || getSchoolTeachersQuery.isFetching}
                  disabled={!getSchoolTeachersQuery.isFetched || getSchoolTeachersQuery.isFetching}
                />
              )}
            />
          </div>
        </ModalComponents.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton
            label={
              isSubmitting || postOrPutGroupMutation.isLoading ? 'Loading...' : groupToUpdate ? 'Edit team' : 'Add team'
            }
            type={'submit'}
            disabled={isSubmitting || postOrPutGroupMutation.isLoading}
          />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

interface Props extends Pick<ModalProps, 'toggle'> {
  groupToUpdate: Group | null;
}

interface FormValues {
  id?: string;
  name: string;
  cohort: boolean;
  members: Option[];
}
