import { LinkStyled } from '../link/link.styled';
import React from 'react';
import { CaseStudyInterface } from '../../resources/caseStudies';

const CaseStudyCard = ({ caseStudy }: { caseStudy: CaseStudyInterface }) => {
  return (
    <>
      <div key={caseStudy.id} className="mx-24 flex-shrink-0 flex items-center justify-center">
        <div className={'w-64 mt-0 h-full'}>
          <img src={caseStudy.image} className={'rounded-full w-32 h-32 m-auto mt-0'}/>
          <p className={'text-center mt-8 mb-2 font-bold text-base'}>{caseStudy.person}</p>
          <p className={`text-center font-light text-sm mb-1 h-6`}>{caseStudy.title}</p>
          <p className={`text-center font-light text-sm mb-4 h-6`}>{caseStudy.organisation}</p>
          <p className={'text-center my-2 text-base italic h-32'}>&quot;{caseStudy.quote}&quot;</p>
          <div className={'flex items-center'}>
            <p className={'m-auto mt-4'}>
              Read the full case study&nbsp;
              <LinkStyled href={caseStudy.link} target={'_blank'}>
                here
              </LinkStyled>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
export default CaseStudyCard;
