import * as Yup from 'yup';
import { debounce } from 'lodash';
import { postEmailCheck } from '../service/api';

export const emailValidationDebounced = debounce(async (value, resolve) => {
  try {
    const response = await postEmailCheck(value as string);
    resolve(response.data.data.valid);
  } catch (error) {
    resolve(false);
  }
}, 500);

export const FIRST_NAME_VALIDATION = Yup.string().trim().max(50).required('First name is a required field');
export const LAST_NAME_VALIDATION = Yup.string().trim().max(32).required('Last name is a required field');

export const EMAIL_VALIDATION = Yup.string()
  .trim()
  .email()
  .max(180)
  .required()
  .test(
    'is-email-taken',
    'email already taken',
    (value) => new Promise((resolve) => emailValidationDebounced(value, resolve))
  );

export const ESE_EMAIL_VALIDATION = Yup.string()
  .trim()
  .email()
  .max(180)
  .required()
  .test(
    'is-email-taken',
    'email already taken',
     (value) => new Promise((resolve) => emailValidationDebounced(value, resolve))
  );

const SCHOOL_ADMIN_EMAIL_VALIDATION = Yup.string().trim().email().max(180).required(); // TODO Add test()
export const PASSWORD_VALIDATION = Yup.string()
  .min(8)
  .max(255)
  .matches(/^(?=.*[a-zA-Z])(?=.*[0-9]).{8,255}$/, 'Password must include characters and numbers')
  .required();

export const NEW_SCHOOL_ADMIN = Yup.object().shape({
  email: SCHOOL_ADMIN_EMAIL_VALIDATION,
});
