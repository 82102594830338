import React from 'react';
import Icon from '../../../../global/icon/icon';
import VideoSection from '../../../dashboard/sections/videoSection';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';
import { useMediaQuery } from 'react-responsive';
import { Section } from '../../../common';
import { Link } from 'react-router-dom';

const ResourceTextPage = ({ resource }: { resource?: any }) => {
  const mobileScreen = useMediaQuery({ query: `(max-width: 1000px)` });

  return (
    <>
      <div className={'mx-8 bg-white rounded-t'}>
        <div style={{ minHeight: '150px' }} className={'flex gap-2 w-full border rounded-t'}>
          <div className={'hidden sm:block m-4 flex rounded-md'}>
            <div className={'m-auto'}>
              {resource?.image_icon && (
                <img className={'w-48 h-48 object-cover rounded-sm'} src={resource.image_icon} />
              )}
              {resource?.icon && <Icon elementSize={mobileScreen ? 100 : 192} icon={resource.icon} />}
            </div>
          </div>
          <div className={'my-4 flex rounded-md m-auto sm:m-0'}>
            <h1 className="px-4 m-auto">{resource?.title}</h1>
          </div>
        </div>
        <div className={'mt-8'}>
          {resource?.urls?.length > 0 && (
            <div className={'m-auto w-1/2 my-4'}>
              <VideoSection video={resource.urls[0]} />
            </div>
          )}
          <div className={'flex'}>
            <div className={'w-1/2 m-auto'}>
              {resource?.content && (
                <Section className={'mb-4 mt-8'}>
                  <Editor
                    toolbarHidden={true}
                    readOnly={true}
                    editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(resource?.content)))}
                  />
                </Section>
              )}
            </div>
          </div>
          {resource?.link_buttons && resource.link_buttons?.length > 0 && (
            <Section className={'mb-4 mt-4'}>
              <div className={'w-1/2 xl:w-full m-auto flex m-4'}>
                <div
                  className={`grid grid-cols-1 xl:grid-cols-${
                    resource.link_buttons.length === 1 ? '1' : '2'
                  } 2xl:grid-cols-${
                    resource.link_buttons.length === 1 ? '1' : resource.link_buttons.length === 2 ? '2' : '3'
                  } m-auto gap-8`}
                >
                  {resource.link_buttons.map((linkButton: any, index: number) => (
                    <Link key={index} to={linkButton.url} target={'_blank'}>
                      <div className={'bg-gray-200 w-56 p-4 elementCardBoxShadow'}>
                        <div className="flex m-auto h-12">
                          {linkButton.icon && (
                            <Icon className={'my-auto ml-auto'} icon={linkButton.icon} elementSize={40} />
                          )}
                          {linkButton.value && (
                            <p className={'my-auto mr-auto text-xl font-semibold'}>{linkButton.value}</p>
                          )}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Section>
          )}
          {resource?.resourceButtons && resource.resourceButtons?.length > 0 && (
            <Section className={'mb-4 mt-4'}>
              <div className={'w-1/2 xl:w-full m-auto flex m-4'}>
                <div
                  className={`grid grid-cols-1 xl:grid-cols-${
                    resource.resourceButtons.length === 1 ? '1' : '2'
                  } 2xl:grid-cols-${
                    resource.resourceButtons.length === 1 ? '1' : resource.resourceButtons.length === 2 ? '2' : '3'
                  } m-auto gap-8`}
                >
                  {resource.resourceButtons.map((linkButton: any, index: number) => (
                    <Link key={index} to={linkButton.url} target={'_blank'}>
                      <div className={'bg-gray-200 w-56 p-4 elementCardBoxShadow'}>
                        <div className="flex m-auto h-12">
                          {linkButton.icon && (
                            <Icon className={'my-auto ml-auto'} icon={linkButton.icon} elementSize={40} />
                          )}
                          {linkButton.value && (
                            <p className={'my-auto mr-auto text-xl font-semibold'}>{linkButton.value}</p>
                          )}
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </Section>
          )}
        </div>
      </div>
    </>
  );
};
export default ResourceTextPage;
