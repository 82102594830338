import React from 'react';
import {
  ResponsiveContainer,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  Tooltip,
  Legend,
  Text,
} from 'recharts';

export type CustomRadarChartDataType = {
  values?: Array<Record<string, any>>;
  keys?: Array<{ id: string; name: string }>;
  colors?: Array<string>;
};

const CustomLabel = (props: any) => {
  const { cx, cy } = props.viewBox;
  return (
    <Text
      x={cx + 20}
      y={cy - 15}
      style={props.isPdf ? { fontSize: props.chartWidth < 300 ? 7 : 10 } : undefined}
      fontWeight={'bold'}
      fill="#8b8b8b"
      dominantBaseline="central"
    >
      % Student Endorsement
    </Text>
  );
};

const CustomRadarChart = ({
  id,
  data,
  dashboard,
}: {
  id: string;
  data: CustomRadarChartDataType;
  dashboard?: boolean;
}) => {
  return (
    <>
      <ResponsiveContainer
        height={650}
        width={'100%'}
        aspect={dashboard ? 1 + (0.2 + 0.025 * (data.keys?.length ?? 1)) : undefined}
      >
        <RadarChart id={id} data={data.values}>
          <PolarGrid />
          <PolarAngleAxis dataKey="element" />
          <PolarRadiusAxis angle={0} domain={[0, 100]} label={(props: any) => <CustomLabel {...props} />} />
          {data.keys &&
            data.colors &&
            data?.keys.map((key, index) => (
              <Radar
                key={key.id}
                isAnimationActive={false}
                name={key.name}
                dataKey={key.id}
                stroke={data.colors![index]}
                fill={data.colors![index]}
                fillOpacity={0.6}
              />
            ))}
          <Tooltip />
          <Legend
            layout={'centric'}
            align={'left'}
            verticalAlign={'top'}
            wrapperStyle={dashboard ? { position: 'initial' } : undefined}
          />
        </RadarChart>
      </ResponsiveContainer>
      <h5 className={'flex justify-center'}>Elements of Great Teaching</h5>
    </>
  );
};

// This is component is duplicated because ResponsiveContainer doesn't work with a custom component
export const CustomRadarChartPDF = ({
  id,
  data,
  width = 300,
  height = 300,
}: {
  id: string;
  data: CustomRadarChartDataType;
  width?: number;
  height?: number;
}) => {
  return (
    <RadarChart id={id} data={data.values} width={width} height={height}>
      <PolarGrid />
      <PolarAngleAxis style={{ fontSize: width < 300 ? 12 : 14 }} dataKey="element" />
      <PolarRadiusAxis
        style={{ fontSize: 10 }}
        angle={0}
        domain={[0, 100]}
        label={(props: any) => <CustomLabel {...props} isPdf={true} chartWidth={width} />}
      />
      {data.keys &&
        data.colors &&
        data?.keys.map((key, index) => (
          <Radar
            key={key.id}
            isAnimationActive={false}
            name={key.name}
            dataKey={key.id}
            stroke={data.colors![index]}
            fill={data.colors![index]}
            fillOpacity={0.6}
          />
        ))}
      <Tooltip />
      <Legend layout={'centric'} align={'left'} verticalAlign={'top'} />
    </RadarChart>
  );
};

export default CustomRadarChart;
