import React from 'react';
import Dropzone from 'react-dropzone';
import ErrorFeedback from '../common/error';
import Icon from '../../global/icon/icon';

const DragAndDropFile = ({ file, files, setFile, error, text }: DragAndDropFileType) => {
  const onDrop = (dropFiles: File[]) => {
    if (files) {
      setFile([...files, ...dropFiles]);
      return;
    }

    if (dropFiles) {
      setFile(dropFiles[0]);
    }
  };

  return (
    <>
      <Dropzone onDrop={onDrop} multiple={false}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {files?.map((file, index) => (
                <div className="selected-file flex inline gap-2" key={Math.random()}>
                  {file.name}{' '}
                  <div
                    className={'bg-primary h-6 rounded'}
                    onClick={(e) => {
                      e.stopPropagation();

                      setFile(files?.filter((_, i) => i !== index));
                    }}
                  >
                    <Icon icon={'X'} container={false} />
                  </div>
                </div>
              ))}
              {file && <div className="selected-file">{file.name}</div>}
              {file == null &&
                (files ?? []).length === 0 &&
                (text ? text : 'Drag and drop videos here, or click to select a video file')}
            </div>
          </section>
        )}
      </Dropzone>
      {error && <ErrorFeedback error={error} />}
    </>
  );
};

type DragAndDropFileType = {
  isMulti?: boolean;
  file?: File | null;
  files?: Array<File>;
  setFile: (file: File | Array<File>) => void;
  error?: string | null;
  text?: string;
};

export default DragAndDropFile;
