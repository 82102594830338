import React from 'react';
import { toast } from 'react-toastify';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { deleteTeacherMemberGroup } from '../../../service/api';
import { ME, SCHOOLS_GROUPS, MY_GROUPS } from '../../../service/queryKeys';

const ConfirmLeaveGroupModal = ({ groupId, toggle }: ModalToggleProps & { groupId: string }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(deleteTeacherMemberGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(MY_GROUPS);
      queryClient.invalidateQueries(SCHOOLS_GROUPS);
      queryClient.invalidateQueries(ME);
      toggle(false);
      toast.success('You have left this great teaching team');
    },
  });

  return (
    <Modal>
      <Modal.Body>
        <p className={'font-bold sm-field-mb'}>Are you sure you want to leave this team?</p>
        <p>You can rejoin this team in the Development Cycles area.</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} />
        <Modal.ConfirmButton label={'Leave'} onClick={() => mutation.mutate(groupId)} />
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmLeaveGroupModal;
