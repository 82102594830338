import React from 'react';

import GTT_FOUNDATION_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_Foundation_grey.svg';
import GTT_FOUNDATION_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_Foundation.svg';
import BADGE_4_1_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_4.1_grey.svg';
import BADGE_4_1_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_4.1.svg';
import BADGE_4_2_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_4.2_grey.svg';
import BADGE_4_2_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_4.2.svg';
import BADGE_4_3_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_4.3_grey.svg';
import BADGE_4_3_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_4.3.svg';
import BADGE_4_4_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_4.4_grey.svg';
import BADGE_4_4_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_4.4.svg';
import BADGE_4_5_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_4.5_grey.svg';
import BADGE_4_5_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_4.5.svg';
import BADGE_4_6_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_4.6_grey.svg';
import BADGE_4_6_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_4.6.svg';
import BADGE_DIMENSION_2_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_D2_grey.svg';
import BADGE_DIMENSION_2_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_D2.svg';
import BADGE_DIMENSION_3_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_D3_grey.svg';
import BADGE_DIMENSION_3_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_D3.svg';
import BADGE_ASSESSMENT_LEAD_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_ALP_grey.svg';
import BADGE_ASSESSMENT_LEAD_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_ALP.svg';
import BADGE_BEHAVIOUR_AND_CULTURE_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_BCP_grey.svg';
import BADGE_BEHAVIOUR_AND_CULTURE_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_BCP.svg';
import BADGE_SCIENCE_OF_LEARNING_GREY from '@app/asset/images/gtt_courses_badges/grey/GTT_SOLP_grey.svg';
import BADGE_SCIENCE_OF_LEARNING_COLOR from '@app/asset/images/gtt_courses_badges/color/GTT_SOLP.svg';

import { EnrolmentType, getCompletedEnrolments } from '../../../service/api';
import { useQuery } from 'react-query';
import { COMPLETED_ENROLMENTS } from '../../../service/queryKeys';

export type BadgeType = {
  id: number;
  date: string | null;
  image: string;
  name: string;
};

const COLORED_BADGES_IMAGE_URLS: Record<number, any> = {
  1430703: GTT_FOUNDATION_COLOR,
  1451403: BADGE_DIMENSION_2_COLOR,
  1451405: BADGE_DIMENSION_3_COLOR,
  1451287: BADGE_4_1_COLOR,
  1441088: BADGE_4_2_COLOR,
  1451282: BADGE_4_3_COLOR,
  1451255: BADGE_4_4_COLOR,
  1451429: BADGE_4_5_COLOR,
  2101112: BADGE_4_6_COLOR,
  1475588: BADGE_ASSESSMENT_LEAD_COLOR,
  1475599: BADGE_BEHAVIOUR_AND_CULTURE_COLOR,
  1408720: BADGE_SCIENCE_OF_LEARNING_COLOR,
};

const GREY_BADGES = [
  [
    {
      id: 1430703,
      date: null,
      image: GTT_FOUNDATION_GREY,
      name: 'Foundation: An evidence-based approach to Great Teaching',
    },
    {
      id: 1451403,
      date: null,
      image: BADGE_DIMENSION_2_GREY,
      name: 'Dimension 2: Creating a supportive environment',
    },
    {
      id: 1451405,
      date: null,
      image: BADGE_DIMENSION_3_GREY,
      name: 'Dimension 3: Maximising opportunity to learn',
    },
    {
      id: 1451287,
      date: null,
      image: BADGE_4_1_GREY,
      name: 'Element 4.1: Structuring',
    },
    {
      id: 1441088,
      date: null,
      image: BADGE_4_2_GREY,
      name: 'Element 4.2: Explaining',
    },
    {
      id: 1451282,
      date: null,
      image: BADGE_4_3_GREY,
      name: 'Element 4.3: Questioning',
    },
    {
      id: 1451255,
      date: null,
      image: BADGE_4_4_GREY,
      name: 'Element 4.4: Interacting',
    },
    {
      id: 1451429,
      date: null,
      image: BADGE_4_5_GREY,
      name: 'Element 4.5: Embedding',
    },
    {
      id: 2101112,
      date: null,
      image: BADGE_4_6_GREY,
      name: 'Element 4.6: Activating',
    },
  ],
  [
    {
      id: 1475588,
      date: null,
      image: BADGE_ASSESSMENT_LEAD_GREY,
      name: 'The Assessment Lead Programme',
    },
    {
      id: 1475599,
      date: null,
      image: BADGE_BEHAVIOUR_AND_CULTURE_GREY,
      name: 'The Science of Learning Programme',
    },
    {
      id: 1408720,
      date: null,
      image: BADGE_SCIENCE_OF_LEARNING_GREY,
      name: 'The Behaviour and Culture Programme',
    },
  ],
];

export const transformPassPortBadge = (completedEnrolments?: Array<EnrolmentType>) => {
  const courses: Array<BadgeType> = GREY_BADGES[0];
  const programs: Array<BadgeType> = GREY_BADGES[1];

  for (const course of courses) {
    const foundCourse = completedEnrolments?.find((enrolment: EnrolmentType) => {
      return enrolment.course_id === course.id;
    });

    if (undefined !== foundCourse) {
      course.date = foundCourse.completion_date;
      course.image = COLORED_BADGES_IMAGE_URLS?.[course.id] ?? course.image;
    }
  }

  for (const program of programs) {
    const foundProgram = completedEnrolments?.find((enrolment: EnrolmentType) => {
      return enrolment.course_id === program.id;
    });

    if (undefined !== foundProgram) {
      program.date = foundProgram.completion_date;
      program.image = COLORED_BADGES_IMAGE_URLS?.[program.id] ?? program.image;
    }
  }

  return [courses, programs];
};

const usePassportBadges = () => {
  const { data: completedEnrolments } = useQuery(COMPLETED_ENROLMENTS, getCompletedEnrolments, {
    staleTime: Infinity,
    select: (data) => data?.data?.data ?? [],
    placeholderData: [],
  });

  const [courses, programs] = transformPassPortBadge(completedEnrolments);

  return [courses, programs];
};

export default usePassportBadges;
