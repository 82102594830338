import * as Yup from 'yup';
import { ROLE_TEACHER, ROLE_TEACHER_FREE } from '../../../../../resources/roles';
import React, { useRef, useState } from 'react';
import Alert from '../../../../../global/alert/alert';
import { TeacherInfoType, useRegistrationContext } from '../../../../../state/registration/provider';
import Input from '../../../../../form/input/input';
import RadioGroup from '../../../../../form/choice/radioGroup';
import Selector from '../../../../../form/select/selector';
import { DefaultOptionType } from '../../../../../resources/types';
import MessageAtMenuList from '../../../../../form/select/components/messageAtMenuList';
import { YEARS } from '../../../../../resources/years';
import { SelectOptionType } from '../../../../../service/types';
import ReCAPTCHA from 'react-google-recaptcha';
import Button from '../../../../../global/button/button';
import { useQuery } from 'react-query';
import { SUBJECTS } from '../../../../../service/queryKeys';
import { getSubjects } from '../../../../../service/api';
import { LinkStyled } from '../../../../../global/link/link.styled';
import useOnScreen from '../../../../../hooks/useOnScreen';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormObserver from '../../FormObserver';

export const ROLE_OPTIONS_OTHER = 'Other';
export const ROLE_OPTIONS = [
  { value: 'Class teacher', label: 'Class teacher' },
  { value: 'Curriculum area leader', label: 'Curriculum area leader' },
  { value: 'Other middle leader', label: 'Other middle leader' },
  { value: 'School senior leader', label: 'School senior leader' },
  { value: 'Headteacher/Principal', label: 'Headteacher/Principal' },
  { value: ROLE_OPTIONS_OTHER, label: 'Other' },
];

export const STAFF_NUMBER = [
  { value: '1-10', label: '1-10' },
  { value: '11-20', label: '11-20' },
  { value: '21-50', label: '21-50' },
  { value: '51-100', label: '51-100' },
  { value: '101+', label: '101+' },
];

const TeacherForm = () => {
  const { step1, step3, step4, previousPage, skipStep3, submitForm } = useRegistrationContext();
  const [step4Values] = useState<TeacherInfoType>({ ...(step4 as TeacherInfoType) });

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  setTimeout(() => setShowErrorMessage(true), 12000);

  const { data: subjects } = useQuery(SUBJECTS, getSubjects, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    enabled: step1?.role === ROLE_TEACHER || step1?.role === ROLE_TEACHER_FREE,
    placeholderData: [],
  });

  const SCHEMA = Yup.object().shape({
    role_description: Yup.string().trim().required().label('Role'),
    role_type: Yup.string().trim().required().label('Role type description'),
    role_other_type: Yup.string().when('role_type', {
      is: ROLE_OPTIONS_OTHER,
      then: Yup.string().trim().required().label('This field')
    }),
    subjects: Yup.array().when('role_type', (roleType: string) => {
      if (roleType !== ROLE_OPTIONS_OTHER) {
        return Yup.array().min(1).label('Subjects');
      }

      return Yup.array().nullable();
    }),
    mentoring: Yup.boolean()
      .nullable()
      .when('role_type', (roleType: string) => {
        if (roleType !== ROLE_OPTIONS_OTHER) {
          return Yup.boolean().required().label('Mentoring');
        }

        return Yup.boolean().nullable();
      }),
    experience: Yup.number()
      .nullable()
      .when('role_type', (roleType: string) => {
        if (roleType !== ROLE_OPTIONS_OTHER) {
          return Yup.number().min(0).required().label('Experience');
        }

        return Yup.number().nullable();
      }),
    year: Yup.number().when('role_type', (roleType: string) => {
      if (roleType !== ROLE_OPTIONS_OTHER) {
        return Yup.number().required().label('Year');
      }

      return Yup.number().nullable();
    }),
    qualifications: Yup.string()
      .trim()
      .when('role_type', (roleType: string) => {
        if (roleType !== ROLE_OPTIONS_OTHER) {
          return Yup.string().required().label('Qualifications');
        }

        return Yup.string().nullable();
      }),
    staff_number: Yup.string().when('role_type', (roleType: string) => {
      if (roleType !== ROLE_OPTIONS_OTHER && roleType !== ROLE_OPTIONS[0].value && step1?.role === ROLE_TEACHER_FREE) {
        return Yup.string().required().label('Staff number');
      }

      return Yup.string().nullable();
    }),
    captcha: Yup.string().required().nullable(),
  });

  const initialFormValues = [
    ['role_description', '', 'Your role'],
    ['role_type', '', 'Which of these best describes your role type?'],
    ['role_other_type', '', 'Please add other option'],
    ['subjects', [], 'Please select the subjects you teach'],
    ['mentoring', null, 'Does your role currently include formally mentoring or supporting other teachers?'],
    ['experience', null, "How many complete years' experience as a teacher do you have?"],
    ['year', '', 'When did you qualify as a teacher?'],
    ['qualifications', '', 'What teaching or related qualifications do you have?'],
    ['staff_number', '', 'How many teaching staff are there at your school/college?'],
    ['captcha', null, "I'm not a robot"],
  ];

  const formValidation = (values: any) => {
    const errorFields: Array<string> = [];
    const entries: any = Object.entries(values);

    initialFormValues.map((value: any, index: number) => {
      if (['role_description', 'role_type', 'captcha'].includes(value[0]) && value[1] === entries[index][1]) {
        errorFields.push(value[2]);
      }
      if (value[0] === 'role_other_type' && values.role_type === 'Other' && value[1] === entries[index][1]) {
        errorFields.push(value[2]);
      }
      if (
        step1?.role === ROLE_TEACHER_FREE &&
        !['Other', 'Class teacher'].includes(values.role_type) &&
        value[0] === 'staff_number' &&
        value[1] === entries[index][1]
      ) {
        errorFields.push(value[2]);
      }
      if (
        ![
          'role_description',
          'role_type',
          'role_other_type',
          'staff_number',
          'captcha',
          'subjects',
          'experience',
        ].includes(value[0]) &&
        values.role_type !== ROLE_OPTIONS_OTHER &&
        value[1] === entries[index][1]
      ) {
        errorFields.push(value[2]);
      }
      if (
        value[0] === 'experience' &&
        values.role_type !== ROLE_OPTIONS_OTHER &&
        (entries[index][1] === null || entries[index][1] === '')
      ) {
        errorFields.push(value[2]);
      }
      if (value[0] === 'subjects' && values.role_type !== ROLE_OPTIONS_OTHER && entries[index][1].length === 0) {
        errorFields.push(value[2]);
      }
    });
    return errorFields;
  };

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    getValues,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(SCHEMA),
    defaultValues: {
      role_description: step4Values?.role_description ?? '',
      role_type: step4Values?.role_type ?? '',
      role_other_type: step4Values?.role_other_type ?? '',
      subjects: step4Values?.subjects ?? [],
      mentoring: step4Values?.mentoring ?? null,
      experience: step4Values?.experience ?? null,
      year: step4Values?.year ?? null,
      qualifications: step4Values?.qualifications ?? '',
      staff_number: step4Values?.staff_number ?? null,
      captcha: step4Values?.captcha ?? null,
    },
  });

  return (
    <>
      <Alert title={'Please note'} className={'my-5'}>
        <p className={'mb-2'}>
          Any information you provide will be used for research purposes to improve the future GTT experience for
          teachers and for contextualising feedback we can provide to you.
        </p>
        <p>
          It will be treated confidentially in accordance with EBE&apos;s Research and Development Ethics Approval
          Protocol and the GDPR provision of the EBE privacy policy.
        </p>
      </Alert>
      <form
        className={'py-5'}
        onSubmit={handleSubmit(() => {
          setIsLoading(true)
          submitForm(() => setIsLoading(false))
        })}
      >
        <FormObserver step={'step4'} values={getValues()}/>
        <div className={'mb-8'}>
          <Input
            {...register('role_description')}
            id={'role_description'}
            label={'Your role'}
            placeholder={'Please describe your role'}
            required
            error={errors.role_description?.message}
          />
        </div>
        <div className={'mb-8'}>
          <Controller
            control={control}
            render={({ field }) => (
              <RadioGroup
                id={'role_type'}
                label={'Which of these best describes your role type?'}
                options={ROLE_OPTIONS}
                isGroupRequired
                optionChecked={field.value}
                onChange={(e: any) => {
                  if (ROLE_OPTIONS_OTHER !== e.target.value) {
                    setValue('role_other_type', '');
                  }
                  field.onChange(e);
                }}
                error={errors.role_type?.message}
              />
            )}
            name={'role_type'}
          />
          {watch('role_type') === ROLE_OPTIONS_OTHER && (
            <div className={'animate-fade-in mt-5'}>
              <Input
                {...register('role_other_type')}
                id={'role_other_type'}
                label={'Other role type'}
                placeholder={'Please add other option'}
                hideLabel
                autoFocus
                error={errors.role_other_type?.message}
              />
            </div>
          )}
        </div>
        {watch('role_type') !== ROLE_OPTIONS_OTHER && (
          <>
            <div className={'mb-8'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Selector
                    {...field}
                    id={'subjects'}
                    label={'Please select the subjects you teach. If you teach primary students select “Primary”'}
                    placeholder={'Please select the subjects you teach'}
                    required
                    options={subjects}
                    isMulti
                    value={field.value.map((subject: string) => {
                      return { label: subject, value: subject };
                    })}
                    onChange={(options: DefaultOptionType[]) => {
                      field.onChange(options == null ? [] : options.map((option) => option.value));
                    }}
                    isClearable={false}
                    isCreatableSelect
                    customComponents={{
                      MenuList: function MenuList(menuListProps) {
                        return (
                          <MessageAtMenuList
                            message={"Don't see your subject? Add it by populating the field above."}
                            {...menuListProps}
                          />
                        );
                      },
                    }}
                    error={errors.subjects?.message}
                  />
                )}
                name={'subjects'}
              />
            </div>
            <div className={'mb-8'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <RadioGroup
                    id={'mentoring'}
                    label={'Does your role currently include formally mentoring or supporting other teachers?'}
                    options={[
                      { value: true, label: 'Yes' },
                      { value: false, label: 'No' },
                    ]}
                    isGroupRequired
                    optionChecked={field.value}
                    onChange={(e: any) => field.onChange(e.target.value === 'true')}
                    error={errors.mentoring?.message}
                  />
                )}
                name={'mentoring'}
              />
            </div>
            <div className={'mb-8'}>
              <Input
                {...register('experience', { valueAsNumber: true })}
                id={'experience'}
                type={'number'}
                label={"How many complete years' experience as a teacher do you have?"}
                placeholder={'For example, 7'}
                required
                min={0}
                error={errors.experience?.message}
              />
            </div>
            <div className={'mb-8'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Selector
                    {...field}
                    id={'year'}
                    label={'When did you qualify as a teacher?'}
                    placeholder={'Please select a year'}
                    required
                    options={YEARS}
                    value={field.value != null ? { label: field.value, value: field.value } : null}
                    onChange={(option: SelectOptionType<string>) => field.onChange(option != null ? option.value : null)}
                    isClearable={false}
                    error={errors.year?.message}
                  />
                )}
                name={'year'}
              />
            </div>
            <div className={'mb-8'}>
              <Input
                {...register('qualifications')}
                id={'qualifications'}
                label={'What teaching or related qualifications do you have?'}
                placeholder={'Please enter your qualification information'}
                required
                error={errors.qualifications?.message}
              />
            </div>
            {watch('role_type') !== 'Class teacher' && step1?.role === ROLE_TEACHER_FREE && (
              <div className={'mb-8'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      id={'staff_number'}
                      label={'How many teaching staff are there at your school/college?'}
                      placeholder={'Please select an option'}
                      required
                      options={STAFF_NUMBER}
                      value={
                        field.value != null
                          ? {
                            label: field.value,
                            value: field.value,
                          }
                          : null
                      }
                      onChange={(option: SelectOptionType<string>) =>
                        field.onChange(option != null ? option.value : null)
                      }
                      isClearable={false}
                      error={errors.staff_number?.message}
                    />
                  )}
                  name={'staff_number'}
                />
              </div>
            )}
          </>
        )}
        <Controller
          control={control}
          render={({ field }) => (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
              onChange={(token) => field.onChange(token)}
              onErrored={() => field.onChange(null)}
              onExpired={() => field.onChange(null)}
            />
          )}
          name={'captcha'}
        />
        <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-5'}>
          <Button className={'mt-5'} type="button" onClick={() => previousPage(skipStep3 ? 2 : 3)}>
            Back
          </Button>
          <Button
            className={'mt-5'}
            id={`${step1?.role && step1?.role + '_'}page3_submit`}
            type="submit"
            disabled={isSubmitting || isLoading}
          >
            {isSubmitting || isLoading ? 'Loading...' : <>Submit{'card' === step3?.payment && ' and pay'}</>}
          </Button>
        </div>
        <div ref={ref}>
          {showErrorMessage && !isValid && isVisible && (
            <div className={'mt-8 text-red-500'}>
              There is an error or missing information in the following fields:
              <ul className={'list-disc'}>
                {formValidation(watch())?.map((value: any) => (
                  <li key={Math.random()}>
                    <span className={'font-bold italic'}>{value}</span>;
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className={'pt-10'}>
          <Alert type={'info'}>
            Having trouble completing this form? Please{' '}
            <LinkStyled href={'mailto:support@evidencebased.education?subject=Subject'} target={'_blank'}>
              contact us here
            </LinkStyled>{' '}
            to help!
          </Alert>
        </div>
      </form>
    </>
  );
};

export default TeacherForm;
