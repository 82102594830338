import React, { useState } from 'react';
import { ActionButton } from "../../../global/buttonIcon/common";
import { AddButton } from "../../../global/button/common";
import { Formik } from "formik";
import Modal from "../../../global/messages/modal/modal.components";
import Input from "../../../form/input/input";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteNote, getNote, postNote, putNote } from "../../../service/api";
import { ALL_SCHOOLS, SCHOOL_NOTES } from "../../../service/queryKeys";
import useModal from "../../../hooks/useModal";
import DeleteNoteModal from "./deleteNoteModal";
import { toast } from "react-toastify";

const NotesModal = ({ school_id, user, userName }: any) => {

  const newNote = {
    id: '',
    note: '',
    writeBy: '',
  }

  const [modal, setModal] = useState('display_notes');
  const [note, setNote] = useState(newNote);

  const queryClient = useQueryClient();

  const { data: notes } = useQuery([SCHOOL_NOTES, school_id], () => getNote(school_id), {
    select: (data) => data.data.data,
    staleTime: Infinity,
  });

  const postNoteMutation = useMutation(postNote, {
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_SCHOOLS);
      queryClient.invalidateQueries(SCHOOL_NOTES);
    },
  });

  const putNoteMutation = useMutation(putNote, {
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_SCHOOLS);
      queryClient.invalidateQueries(SCHOOL_NOTES);
    },
  });

  const deleteNoteMutation = useMutation(deleteNote, {
    onSuccess: () => {
      queryClient.invalidateQueries(ALL_SCHOOLS);
      queryClient.invalidateQueries(SCHOOL_NOTES);
    },
  });

  const [deleteNoteModal, toggleDeleteNoteModal, setDeleteNoteModalProps] = useModal((props: any) => (
    <DeleteNoteModal {...props} />
  ));
  return (
    <>
      {deleteNoteModal}
      {modal === 'display_notes' &&
          <>
            {notes?.length > 0 ?
              notes.map((note: any) =>
                <React.Fragment key={note.id}>
                  {note.writeBy} - {new Date(note.created_at).toLocaleDateString()}
                  <div className={'border-2 m-4'}>
                    <div className={'p-2'}>
                      {note.note}
                    </div>
                    <div className={'flex justify-end mx-4 gap-2 my-2'}>
                      <ActionButton.Edit
                        onClick={() => {
                          setModal('add_or_edit_note');
                          setNote(note);
                        }
                        }
                      />
                      <ActionButton.Remove
                        onClick={() => {
                          setDeleteNoteModalProps({
                            handleSubmit: () => deleteNoteMutation.mutateAsync(note.id),
                            title: 'Delete Note',
                            note
                          });
                          toggleDeleteNoteModal(true);
                        }}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )
              : <>No notes</>
            }
              <Modal.Footer>
                  <AddButton
                      key={'new-note'}
                      onClick={() => {
                        setModal('add_or_edit_note');
                        setNote(newNote)
                      }}
                      label={'Add note'}
                  />
              </Modal.Footer>
          </>
      }
      {modal === 'add_or_edit_note' &&
          <>
              <Formik
                  initialValues={{
                    id: note.id != '' ? note.id : '',
                    note: note.note != '' ? note.note : '',
                    user_id: user.id !='' ? user.id :'',
                  }}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    if (note.id === '') {
                      postNoteMutation.mutateAsync(values).then(() => {
                        toast.success('Added new note');
                        return;
                      })
                    } else {
                      putNoteMutation.mutateAsync(values).then(() => {
                        toast.success('Updated note');
                        return;
                      })
                    }
                    setModal('display_notes')
                  }
                  }
              >
                {({ values, handleChange, handleSubmit, isValid, setFieldValue }) => (
                  <form onSubmit={handleSubmit}>
                    <Modal.Body>
                      <div className={'field-mb'}>
                        <Input
                          required
                          id={'new_note'}
                          type={'textarea'}
                          name={'new_note'}
                          label={'Note'}
                          placeholder={'Please enter a note'}
                          value={values?.note}
                          onChange={(option: any) => {
                            setFieldValue(`note`, option.target.value)
                          }}
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <Modal.CancelButton onClick={() => setModal('display_notes')}/>
                      <Modal.ConfirmButton
                        disabled={values.note.length < 1}
                        type={'submit'}
                        onClick={() => {
                          setFieldValue(`writeBy`, note.writeBy != '' ? note.writeBy : userName);
                        }}
                      />
                    </Modal.Footer>
                  </form>
                )}
              </Formik>
          </>
      }
    </>
  )
    ;
};

export default NotesModal;
