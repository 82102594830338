import React from 'react';
import Tooltip from '../tooltip';
import Icon from '../../icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import { TooltipProps } from '../tooltip.types';

const MoreInfo = ({ content }: TooltipProps) => (
  <Tooltip content={content} theme={'light-border'}>
    <Icon icon={GLOBAL_ICONS.info} container={false} color={'primary'} />
  </Tooltip>
);

MoreInfo.displayName = 'MoreIcon';

export default MoreInfo;
