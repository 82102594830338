import React from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import { Formik } from 'formik';
import Selector from '../../../form/select/selector';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getTeacherMemberGroups, postStrategyGroup } from '../../../service/api';
import * as Yup from 'yup';
import { JOINED_GROUPS, PATHWAYS_V2_STEP, TEACHER_STRATEGIES } from '../../../service/queryKeys';
import { PREPARE_STEP } from "../views/PathwayV2View";

const ShareWithGroupModal = ({ toggle, strategy, groupId }: ModalToggleProps & { strategy: any; groupId: string }) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(postStrategyGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(TEACHER_STRATEGIES);
      queryClient.invalidateQueries([PATHWAYS_V2_STEP, PREPARE_STEP]);
      toggle(false);
    },
  });

  const joinedGroupsQuery = useQuery(
    [ JOINED_GROUPS ],
    () => getTeacherMemberGroups(),
    {
      staleTime: Infinity,
      select: (data) => data.data.map((group: any) => ({ label: group.name, value: group.id })),
      placeholderData: [],
    },
  );

  return (
    <Modal>
      <Formik
        validationSchema={Yup.object().shape({
          group: Yup.string().required(),
        })}
        initialValues={{
          group: groupId ? groupId : null,
        }}
        onSubmit={(values) => {
          mutation.mutate({ groupId: values.group!, strategyId: strategy.id });
        }}
      >
        {({ values, handleSubmit, setFieldValue, isValid }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Selector
                required
                isClearable={false}
                id={'group'}
                label={'Group'}
                placeholder={'Select group'}
                value={joinedGroupsQuery.data!.find((g: any) => g.value === values.group)}
                onChange={(option: any) => setFieldValue('group', option.value)}
                options={joinedGroupsQuery.data}
                customComponents={
                  0 !== joinedGroupsQuery.data!.length
                    ? null
                    : {
                        MenuList: function MenuList(menuListProps: any) {
                          return <MessageAtMenuList message={'Loading...'} {...menuListProps} />;
                        },
                      }
                }
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                type={'submit'}
                label={mutation.isLoading ? 'Loading...' : 'Share'}
                disabled={!isValid || mutation.isLoading}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default ShareWithGroupModal;
