export const filterTabs = {
  time: [
    {
      value: 1,
      label: 'Academic year & terms',
    },
    {
      value: 2,
      label: 'Date range',
    },
  ],
  users: [
    {
      value: 1,
      label: 'Users',
    },
    {
      value: 2,
      label: 'Teams',
    },
  ],
  activities: [
    {
      value: 1,
      label: 'Activities',
    },
  ],
};

export const activityTypes = [
  { value: 'Development goal', label: 'Development goal' },
  { value: 'Continue with development cycle', label: 'Continue with development cycle' },
  { value: 'Classroom observation', label: 'Classroom observation' },
  { value: 'Action plan created', label: 'Action plan created' },
  { value: 'Video Observation', label: 'Video Feedback' },
  { value: 'Development Strategy created', label: 'Development Strategy created' },
  { value: 'GTT Course', label: 'GTT Course' },
  { value: 'Element identified', label: 'Element identified' },
  { value: 'Student survey', label: 'Student survey' },
  { value: 'Development cycle step', label: 'Development cycle step' },
];

export const developmentActivitiesColumns = [
  { id: 'type', label: 'Development cycle' },
  { id: 'first_name', label: 'First name' },
  { id: 'last_name', label: 'Last name' },
  { id: 'group_name', label: 'Team name' },
  { id: 'element', label: 'Element' },
  { id: 'activity_type', label: 'Activity type' },
  { id: 'activity', label: 'Activity' },
  { id: 'start_date', label: 'Start date' },
  { id: 'completed_date', label: 'Completed date' },
];

export type PdfTextMetricsType = {
  text: string;
  customMarginBottom?: string;
  customFontSize?: string;
  customWidth?: string;
  customTextAlign?: 'center' | 'left' | 'right' | 'justify' | undefined;
  customMarginLeft?: string;
  customMarginTop?: string;
  customFontFamily?: string;
};

export type PdfViewMetricsType = {
  content: any;
  customMarginBottom?: string;
  customMarginTop?: string;
  customHeight?: string;
};
