import React from 'react';

const MoreInfoTooltip = (
  {
    instrument,
    period,
  }: {
    instrument: string;
    period?: string;
  }) => (
  <React.Fragment>
    <p>More info about this survey:</p>
    <ul>
      <li>
        <b>Instrument type:</b> {instrument}
      </li>
      {period && (
        <li>
          <b>Term:</b> {period}
        </li>
      )}
    </ul>
  </React.Fragment>
);

MoreInfoTooltip.displayName = 'MoreInfoTooltip';

export default MoreInfoTooltip;
