import React, { useEffect } from 'react';
import PathwaysAcademicYears from '../../organisation/sections/pathwaysAcademicYears';
import Button from '../../../global/button/button';
import { PeriodProps } from '../../pathway/pathwaysPage.types';
import { PathwaysPeriods } from '../../organisation/sections';
import useModal from '../../../hooks/useModal';
import { DevelopmentPeriodsModal } from '../../organisation/modals';
import { useQuery } from 'react-query';
import { DEVELOPMENT_PERIODS } from '../../../service/queryKeys';
import { getDevelopmentPeriods } from '../../../service/api';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { OnboardingBodyContentInterface, implementationOptions } from './resources';
import LogoDark from '@app/asset/images/Great_Teaching_Toolkit_Logo_Dark.png';

const OnboardingBodyContent = ({
  type,
  data,
  user,
  setShowSkipButton,
  setShowContinueButton,
  reason,
  setReason,
  copied,
  setCopied,
  version,
}: OnboardingBodyContentInterface) => {
  const developmentPeriods =
    version === 'school'
      ? useQuery(DEVELOPMENT_PERIODS, getDevelopmentPeriods, {
          staleTime: Infinity,
          select: (data) => data.data.data,
          placeholderData: [],
          keepPreviousData: true,
        })
      : undefined;

  const [periodsModal, togglePeriodsModal, setPeriodsProps] = useModal((props: any) => (
    <DevelopmentPeriodsModal className={'w-100'} {...props} />
  ));

  useEffect(() => {
    if (['welcomeTeacher', 'completeTeacher'].includes(type)) {
      setShowContinueButton(true);
    }
    if (['welcome', 'academic_year', 'terms', 'complete'].includes(type)) {
      setShowSkipButton(false);
      if (type === 'welcome') {
        setShowContinueButton(true);
      } else if (type === 'academic_year' && data) setShowContinueButton(data?.length > 0);
      else if (type === 'terms') setShowContinueButton(developmentPeriods?.data.length > 0);
      else if (type === 'complete') {
        setShowContinueButton(true);
      }
    } else if (type === 'implementation_intention') {
      if (reason) {
        setShowContinueButton(true);
        setShowSkipButton(false);
      } else {
        setShowContinueButton(false);
        setShowSkipButton(true);
      }
    } else if (type === 'registration') {
      if (copied) {
        setShowContinueButton(true);
        setShowSkipButton(false);
      } else {
        setShowContinueButton(false);
        setShowSkipButton(true);
      }
    }
  }, [data, developmentPeriods, type, reason, copied]);

  return (
    <>
      {periodsModal}
      <div
        className={`overflow-y-auto ${['academic_year', 'completeTeacher'].includes(type) ? 'pb-12' : 'pb-0'}`}
        style={{ height: '85%' }}
      >
        {['complete', 'welcome', 'welcomeTeacher'].includes(type) ? (
          <>
            <div className={'w-full'}>
              <img className={'h-64 mt-8 mx-auto object-scale-down'} src={LogoDark} alt={'GTT Image'} />
            </div>
          </>
        ) : type === 'academic_year' ? (
          <div className={'h-full'}>{data ? <PathwaysAcademicYears onboarding data={data} /> : <></>}</div>
        ) : type === 'terms' ? (
          <div className={'h-full'}>
            {data ? (
              <PathwaysPeriods
                onboarding
                academicYears={data ?? []}
                toggle={(period?: PeriodProps) => {
                  setPeriodsProps({
                    title: `${period ? 'Update term dates' : 'Add new term'}`,
                    description: ``,
                    period,
                  });
                  togglePeriodsModal(true);
                }}
              />
            ) : (
              <></>
            )}
          </div>
        ) : type === 'implementation_intention' ? (
          <div className="mt-2">
            <div className={'mb-4'}>
              <RadioGroupOnBlock
                required
                id={'implementationReason'}
                options={implementationOptions}
                handleChange={(value: string) => {
                  setReason(value);
                }}
                optionChecked={reason ? reason : undefined}
              />
            </div>
            <div className="bg-light bg-opacity-50 px-5 py-6 rounded-sm text-center">
              We’ve assigned you a GTT Advisor to help you get the most out of the toolkit. You can discuss your plans
              with them or email them with questions at any time. They’ll soon be reaching out to you directly by email.
            </div>
          </div>
        ) : type === 'registration' ? (
          <div className={'items-center grid grid-rows-2'}>
            <div className={'mt-20 mx-auto'}>
              <CopyToClipboard
                text={`<div>
                    <p>This year, our CPD plans will involve the Great Teaching Toolkit – an online platform we can use to work together to further develop our teaching practice.</p>
                    <p>Here's what you need to do to access the platform:</p>
                    <ul>
                        <li>
                        <strong>Step 1:</strong> Please click on this <a href=${user?.link} target={'_blank'}>link</a> to create your account.</li>
                        <li><strong>Step 2:</strong> Once you have created your account, you'll be able to log in <a href="https://toolkit.greatteaching.com/login" target={'_blank'}>here</a>. We recommend saving this email for future reference.</li>
                    </ul>
                </div> `}
                onCopy={() => {
                  setCopied(true);
                  toast.success('Registration instructions have been copied');
                }}
                options={{ format: 'text/html' }}
              >
                <Button id={'stonly_copy_link'} isOutline size={'sm'}>
                  Copy registration instructions
                </Button>
              </CopyToClipboard>
            </div>
            <div className={'mt-2 w-2/3 2xl:w-2/3 mx-auto flex justify-center'}>
              <p className={'mx-auto italic justify-center text-center'}>
                (if you want to look around inside the toolkit first, you can grab this registration link from your
                dashboard at any time)
              </p>
            </div>
          </div>
        ) : type === 'completeTeacher' ? (
          <>
            <div className={'w-full flex mt-8'}>
              <div className="m-auto w-full md:w-3/4 text-center">
                <p>
                  The GTT offers you an evidence-based pathway to adaptive expertise where small, consistent efforts can
                  lead to profound classroom transformations.
                </p>
                <p className={'mt-4'}>
                  Take some time to learn about how this works and then meet with your colleagues to figure out a plan
                  that works for you.
                </p>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default OnboardingBodyContent;
