import React, { useEffect, useRef, useState } from 'react';
import RadarChartSection, { ALL_OPTION, processedRadarData } from './sections/radarChartSection';
import SummaryChartSection, { processedSummaryData } from './sections/summaryChartSection';
import { Document, Page, pdf, View, Text } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import DashboardLayout from '../../layout/dashboard';
import { Section } from '../common';
import PDFLayout, { MARGIN_LEFT_TEXT, MARGIN_TOP_PAGE, PDF_STYLES } from '../../layout/pdf';
import FeedbackLayout from './components/feedbackLayout';
import { VERSION_B, VERSION_C, VERSION_C_D, VERSION_D, VERSION_E } from '../instrument/sections/instrumentForm';
import TimeFrameTable, {
  TIMEFRAME_NO_OPTION,
  TimeframeOptionType,
  TimeframeSubOptionType,
} from './sections/TimeFrameTable';
import Button from '../../global/button/button';
import { getElementsFeedbackData, getRadarFeedbackData, getSummaryFeedbackData } from '../../service/api';
import PDFChart from './PDFChart';
import { CustomBarChartPDF } from './components/customBarChart';
import { CustomRadarChartPDF } from './components/customRadarChart';
import Tippy from '@tippyjs/react';
import ElementChartSection, { processedElementData } from './sections/elementChartSection';
import SideTab from '../../typeform/SideTab';

const SchoolFeedbackPage = () => {
  const [loading, setLoading] = useState(false);
  const pdfRadarLegend = useRef([]);
  const [activeVersion, setActiveVersion] = useState<string>();

  // Timeframe
  const [timeframeOption, setTimeFrameOption] = useState<TimeframeOptionType>(TIMEFRAME_NO_OPTION);
  const [timeframeSelections, setTimeFrameOptionSelections] = useState<Array<TimeframeSubOptionType>>([]);

  const [tabs, setTabs] = useState<string[]>([]);

  const [numberOfTeachersAndResponses, setNumberOfTeachersAndResponses] = useState<any>();

  useEffect(() => {
    //Refresh tabs
    const currentTabs: string[] = [];
    for (const selection of timeframeSelections) {
      // If it has already all 3 options (version B, C/D and E)
      if (currentTabs.length >= 3) {
        break;
      }

      if (
        !currentTabs.includes(VERSION_C_D) &&
        (selection.survey_versions?.includes(VERSION_C) || selection.survey_versions?.includes(VERSION_D))
      ) {
        currentTabs.push(VERSION_C_D);
      }

      if (!currentTabs.includes(VERSION_B) && selection.survey_versions?.includes(VERSION_B)) {
        currentTabs.push(VERSION_B);
      }

      if (!currentTabs.includes(VERSION_E) && selection.survey_versions?.includes(VERSION_E)) {
        currentTabs.push(VERSION_E);
      }
    }

    currentTabs.sort();

    setTabs(currentTabs);
    setActiveVersion(currentTabs[0]);
  }, [timeframeSelections]);

  const getFilteredIds = (element?: string) => {
    let filteredSelections: Array<TimeframeSubOptionType> = [];

    if (element) {
      filteredSelections = timeframeSelections.filter((s) => s.elements && s.elements.includes(element));
    } else {
      filteredSelections = timeframeSelections.filter((s) => {
        if (activeVersion === VERSION_C_D) {
          return s.survey_versions?.includes(VERSION_C) || s.survey_versions?.includes(VERSION_D);
        }

        return s.survey_versions?.includes(activeVersion!);
      });
    }

    return filteredSelections.map((s) => s.id);
  };

  const handleDownload = async () => {
    setLoading(true);

    const bTimeframeIds = timeframeSelections.filter((s) => s.survey_versions?.includes(VERSION_B)).map((s) => s.id);
    const cdTimeframeIds = timeframeSelections
      .filter((s) => s.survey_versions?.includes(VERSION_C) || s.survey_versions?.includes(VERSION_D))
      .map((s) => s.id);
    const eTimeframeIds = timeframeSelections.filter((s) => s.survey_versions?.includes(VERSION_E)).map((s) => s.id);
    const elementTimeframe = timeframeSelections.reduce<Record<string, any>>((acc, curr) => {
      if (curr.elements == null) {
        return acc;
      }

      for (const element of curr.elements) {
        (acc[element] = acc[element] || []).push(curr.id);
      }
      return acc;
    }, {});

    try {
      const chartsData = await Promise.all([
        bTimeframeIds.length > 0 ? getRadarFeedbackData(bTimeframeIds, undefined, VERSION_B, true) : null,
        bTimeframeIds.length > 0 ? getSummaryFeedbackData(bTimeframeIds, undefined, VERSION_B, true) : null,
        cdTimeframeIds.length > 0 ? getRadarFeedbackData(cdTimeframeIds, undefined, VERSION_C_D, true) : null,
        cdTimeframeIds.length > 0 ? getSummaryFeedbackData(cdTimeframeIds, undefined, VERSION_C_D, true) : null,
        eTimeframeIds.length > 0 ? getRadarFeedbackData(eTimeframeIds, undefined, VERSION_E, true) : null,
        eTimeframeIds.length > 0 ? getSummaryFeedbackData(eTimeframeIds, undefined, VERSION_E, true) : null,
        Promise.all(
          Object.entries(elementTimeframe).map(([element, timeFrameIds]) =>
            getElementsFeedbackData(element, timeFrameIds, true)
          )
        ),
      ]);

      const bOptions = chartsData[0]?.data.data.options ?? [];
      const cdOptions = chartsData[2]?.data.data.options ?? [];
      const eOptions = chartsData[4]?.data.data.options ?? [];

      let page = 0;

      const blob = await pdf(
        <Document>
          {bOptions.map((option) => {
            if (
              Array.isArray(chartsData[0]!.data.data.data) ||
              chartsData[0]!.data.data.data[option.id].length === 0 ||
              Array.isArray(chartsData[1]!.data.data.data) ||
              chartsData[1]!.data.data.data[option.id] === null ||
              option.id === ALL_OPTION
            ) {
              return;
            }

            page++;

            return (
              <Page size="A4" style={{ ...PDF_STYLES.page }} key={Math.random()}>
                {page === 1 && <PDFLayout.Header />}
                <Text
                  style={{
                    ...PDF_STYLES.headline,
                    marginLeft: MARGIN_LEFT_TEXT,
                    marginTop: page !== 1 ? MARGIN_TOP_PAGE : 0,
                  }}
                >
                  {VERSION_B}
                </Text>
                <Text style={{ ...PDF_STYLES.headline, marginLeft: MARGIN_LEFT_TEXT }}>{option.name}</Text>
                {option.teachers && option.teachers > 0 && option.responses && option.responses > 0 ? (
                  <>
                    <Text style={{ fontSize: '10px', marginLeft: MARGIN_LEFT_TEXT }}>
                      {option.teachers} teachers contributed to this feedback
                    </Text>
                    <Text style={{ fontSize: '10px', marginLeft: MARGIN_LEFT_TEXT }}>
                      {option.responses} students submitted a response
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                <View style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                  <PDFChart>
                    <CustomRadarChartPDF
                      id={`radar_${option.id}`}
                      data={processedRadarData(chartsData[0]!.data.data, [option.id])}
                      width={page === 1 ? 205 : 275}
                      height={page === 1 ? 205 : 275}
                    />
                  </PDFChart>
                </View>
                <Text style={{ ...PDF_STYLES.headline, textAlign: 'center', marginBottom: 20 }}>
                  Elements of Great Teaching
                </Text>
                <PDFChart>
                  <CustomBarChartPDF
                    data={processedSummaryData(chartsData[1]!.data.data, [option.id], VERSION_B)}
                    dataKey={'element'}
                    id={`summary_${option.id}`}
                    height={page === 1 ? 440 : 450}
                  />
                </PDFChart>
              </Page>
            );
          })}
          {cdOptions.map((option) => {
            if (
              Array.isArray(chartsData[2]!.data.data.data) ||
              chartsData[2]!.data.data.data[option.id].length === 0 ||
              Array.isArray(chartsData[3]!.data.data.data) ||
              chartsData[3]!.data.data.data[option.id] === null ||
              option.id === ALL_OPTION
            ) {
              return;
            }

            page++;

            return (
              <Page size="A4" style={PDF_STYLES.page} key={Math.random()}>
                {page === 1 && <PDFLayout.Header />}
                <Text
                  style={{
                    ...PDF_STYLES.headline,
                    marginLeft: MARGIN_LEFT_TEXT,
                    marginTop: page !== 1 ? MARGIN_TOP_PAGE : 0,
                  }}
                >
                  {VERSION_C_D}
                </Text>
                <Text style={{ ...PDF_STYLES.headline, marginLeft: MARGIN_LEFT_TEXT }}>{option.name}</Text>
                {option.teachers && option.teachers > 0 && option.responses && option.responses > 0 ? (
                  <>
                    <Text style={{ fontSize: '10px', marginLeft: MARGIN_LEFT_TEXT }}>
                      {option.teachers} teachers contributed to this feedback
                    </Text>
                    <Text style={{ fontSize: '10px', marginLeft: MARGIN_LEFT_TEXT }}>
                      {option.responses} students submitted a response
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                <View style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                  <PDFChart>
                    <CustomRadarChartPDF
                      id={`radar_${option.id}`}
                      data={processedRadarData(chartsData[2]!.data.data, [option.id])}
                      width={page === 1 ? 205 : 275}
                      height={page === 1 ? 205 : 275}
                    />
                  </PDFChart>
                </View>
                <Text style={{ ...PDF_STYLES.headline, textAlign: 'center', marginBottom: 20 }}>
                  Elements of Great Teaching
                </Text>
                <PDFChart>
                  <CustomBarChartPDF
                    data={processedSummaryData(chartsData[3]!.data.data, [option.id], VERSION_C_D)}
                    dataKey={'element'}
                    id={`summary_${option.id}`}
                    height={page === 1 ? 425 : 450}
                  />
                </PDFChart>
              </Page>
            );
          })}
          {eOptions.map((option) => {
            if (
              Array.isArray(chartsData[4]!.data.data.data) ||
              chartsData[4]!.data.data.data[option.id].length === 0 ||
              Array.isArray(chartsData[5]!.data.data.data) ||
              chartsData[5]!.data.data.data[option.id] === null ||
              option.id === ALL_OPTION
            ) {
              return;
            }

            page++;

            return (
              <Page size="A4" style={PDF_STYLES.page} key={Math.random()}>
                {page === 1 && <PDFLayout.Header />}
                <Text
                  style={{
                    ...PDF_STYLES.headline,
                    marginLeft: MARGIN_LEFT_TEXT,
                    marginTop: page !== 1 ? MARGIN_TOP_PAGE : 0,
                  }}
                >
                  {VERSION_E}
                </Text>
                <Text style={{ ...PDF_STYLES.headline, marginLeft: MARGIN_LEFT_TEXT }}>{option.name}</Text>
                {option.teachers && option.teachers > 0 && option.responses && option.responses > 0 ? (
                  <>
                    <Text style={{ fontSize: '10px', marginLeft: MARGIN_LEFT_TEXT }}>
                      {option.teachers} teachers contributed to this feedback
                    </Text>
                    <Text style={{ fontSize: '10px', marginLeft: MARGIN_LEFT_TEXT }}>
                      {option.responses} students submitted a response
                    </Text>
                  </>
                ) : (
                  <></>
                )}
                <View style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                  <PDFChart>
                    <CustomRadarChartPDF
                      id={`radar_${option.id}`}
                      data={processedRadarData(chartsData[4]!.data.data, [option.id])}
                      width={page === 1 ? 205 : 275}
                      height={page === 1 ? 205 : 275}
                    />
                  </PDFChart>
                </View>
                <Text style={{ ...PDF_STYLES.headline, textAlign: 'center', marginBottom: 20 }}>
                  Elements of Great Teaching
                </Text>
                <PDFChart>
                  <CustomBarChartPDF
                    data={processedSummaryData(chartsData[5]!.data.data, [option.id], VERSION_E)}
                    dataKey={'element'}
                    id={`summary_${option.id}`}
                    height={page === 1 ? 425 : 450}
                  />
                </PDFChart>
              </Page>
            );
          })}
          {Object.entries(elementTimeframe).map(([element], index) => {
            return chartsData[6][index].data.data.options.map((option) => {
              if (
                chartsData[6][index].data.data.data == null ||
                chartsData[6][index].data.data.data![element][option.id] == null ||
                option.id === ALL_OPTION
              ) {
                return;
              }

              page++;

              return (
                <Page size="A4" style={PDF_STYLES.page} orientation={'landscape'} key={Math.random()}>
                  {page === 1 && <PDFLayout.Header />}
                  <View wrap={false}>
                    <Text
                      style={{
                        ...PDF_STYLES.headline,
                        marginLeft: MARGIN_LEFT_TEXT,
                        marginTop: MARGIN_TOP_PAGE,
                      }}
                    >
                      {option.name} - {element}
                    </Text>
                    {option.teachers && option.teachers > 0 && option.responses && option.responses > 0 ? (
                      <>
                        <Text style={{ fontSize: '10px', marginLeft: MARGIN_LEFT_TEXT }}>
                          {option.teachers} teachers contributed to this feedback
                        </Text>
                        <Text style={{ marginBottom: '10px', fontSize: '10px', marginLeft: MARGIN_LEFT_TEXT }}>
                          {option.responses} students submitted a response
                        </Text>
                      </>
                    ) : (
                      <></>
                    )}
                    <PDFChart>
                      <CustomBarChartPDF
                        data={processedElementData(chartsData[6][index].data.data, element, [option.id])}
                        dataKey={'question'}
                        id={`elementChartId-${element}`}
                        width={850}
                        height={600}
                        landscape
                      />
                    </PDFChart>
                  </View>
                </Page>
              );
            });
          })}
        </Document>
      ).toBlob();

      saveAs(blob, 'school-feedback');
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout
      title={'GTT Profile feedback page'}
      pageAction={
        <Tippy
          disabled={timeframeSelections.length > 0}
          content={<>Select your survey to generate a downloadable PDF</>}
          theme={'translucent'}
          interactive
          interactiveBorder={10}
          animation={'shift-away'}
          allowHTML
          appendTo={() => document.body}
        >
          <div>
            <SideTab formId={'CxBEcd9G'} />
            <Button
              disabled={loading || timeframeSelections.length === 0}
              onClick={handleDownload}
              addOutline={loading || timeframeSelections.length === 0}
            >
              {loading ? 'Loading...' : 'Download'}
            </Button>
          </div>
        </Tippy>
      }
    >
      <TimeFrameTable
        isSchool
        option={timeframeOption}
        setOption={setTimeFrameOption}
        confirmSelections={setTimeFrameOptionSelections}
      />
      {timeframeSelections.length > 0 && (
        <FeedbackLayout
          showTabs={tabs.length > 1}
          items={tabs.map((tab) => ({ label: tab, value: tab }))}
          active={activeVersion ?? null}
          clickOnTab={(value: string) => setActiveVersion(value)}
        >
          <>
            <Section
              headline={
                <>
                  <p>{`GTT Profile ${timeframeOption.label}`}</p>
                  <p>{`${timeframeOption.start_date} - ${timeframeOption.end_date}`}</p>
                  {numberOfTeachersAndResponses && numberOfTeachersAndResponses.teachers > 0 ? (
                    <>
                      <div className={'mt-1 text-sm italic'}>
                        {numberOfTeachersAndResponses.teachers} teachers contributed to this feedback
                      </div>
                      <div className={'text-sm italic'}>
                        {numberOfTeachersAndResponses.responses} students submitted a response
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              }
              size={'md'}
              className={'field-mb'}
            >
              <RadarChartSection
                pdfLegend={pdfRadarLegend}
                surveyVersion={activeVersion}
                timeframeSelections={getFilteredIds()}
                isSchool
                setNumberOfTeachersAndResponses={setNumberOfTeachersAndResponses}
              />
            </Section>
            <Section headline={'Breakdown GTT Profile'} size={'md'}>
              <SummaryChartSection surveyVersion={activeVersion} timeframeSelections={getFilteredIds()} isSchool />
            </Section>
            {activeVersion === VERSION_C_D &&
              timeframeSelections
                .reduce<Array<string>>((acc, curr) => {
                  let tempAcc = [...acc];

                  if (curr.elements) {
                    tempAcc.push(...curr.elements);
                  }

                  tempAcc = [...new Set(tempAcc)].sort();

                  return tempAcc;
                }, [])
                .map((element) => (
                  <ElementChartSection
                    key={Math.random()}
                    timeframeSelections={getFilteredIds(element)}
                    element={element}
                    isSchool
                  />
                ))}
          </>
        </FeedbackLayout>
      )}
    </DashboardLayout>
  );
};

export default SchoolFeedbackPage;
