import React from 'react';
import {Formik} from 'formik';
import {UserType} from '../userManagementPage';
import {ModalToggleProps} from '../../../global/messages/modal/modal.types';
import {ReviewUserTable} from '../components';
import {useMutation, useQuery, useQueryClient} from 'react-query';
import {getSchoolAssociatedUserOptions, removeUserPrivileges} from '../../../service/api';
import {toast} from 'react-toastify';
import Modal from '../../../global/messages/modal/modal.components';
import {SCHOOL_ASSOCIATED_USER_OPTIONS, USER_MANAGEMENT_LIST} from '../../../service/queryKeys';
import Selector from "../../../form/select/selector";
import {SelectOptionType} from "../../../service/types";
import * as Yup from "yup";

const AdminStateConfirmModal = ({user, toggle}: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();

  const removePrivilegesMutation = useMutation(removeUserPrivileges, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      queryClient.invalidateQueries([SCHOOL_ASSOCIATED_USER_OPTIONS]);
      toggle(false);
      toast.success('Removed privileges successfully');
    },
  });

  const {
    data: users,
    isLoading
  } = useQuery([SCHOOL_ASSOCIATED_USER_OPTIONS, user.school_id], () => getSchoolAssociatedUserOptions(user.school_id as number), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  return (
    <Formik
      initialValues={{
        user_id: null,
      }}
      validationSchema={Yup.object().shape({
        user_id: Yup.string().required(),
      })}
      validateOnMount
      onSubmit={(values) => {
        removePrivilegesMutation.mutate({user_id: values.user_id, school_user_id: user.id});
      }}
    >
      {({isValid, handleSubmit, values, setFieldValue}) => (
        <Modal>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <p className={'sm-field-mb'}>
                You are about to remove the great teaching coordinator privileges from this user account.
              </p>
              <div className={'sm-field-mb'}>
                <ReviewUserTable user={user}/>
              </div>
              <p className={'sm-field-mb'}>
                Please select another user from this school that you would like to transfer GT Coordinator privileges
                to.
              </p>
              <Selector
                id={'user'}
                label={'User'}
                placeholder={'Please select an user associated with this school'}
                required
                value={values.user_id != null ? users.find((user: SelectOptionType) => user.value === values.user_id) : null}
                options={users}
                isLoading={isLoading}
                onChange={(option: any) => setFieldValue('user_id', option.value)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)}/>
              <Modal.ConfirmButton
                type={'submit'} disabled={!isValid || removePrivilegesMutation.isLoading}
                label={removePrivilegesMutation.isLoading ? 'Loading...' : 'Confirm'}/>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default AdminStateConfirmModal;
