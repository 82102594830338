import React, { useState } from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EARLY_ACCESS_GROUPS_OPTIONS, USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import { getEarlyAccessGroups, updateSchoolEarlyAccessGroups, updateUserEarlyAccessGroups } from '../../../service/api';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import { UserType } from '../userManagementPage';
import { ROLE_TEACHER } from '../../../resources/roles';
import { toast } from 'react-toastify';

const AssignEarlyAccessGroupsModal = ({ toggle, user }: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();

  const { data: groups, isFetching: isLoadingGroups, isError } = useQuery(
    EARLY_ACCESS_GROUPS_OPTIONS,
    getEarlyAccessGroups,
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const { mutate: updateUser, isLoading: updateUserIsLoading } = useMutation(updateUserEarlyAccessGroups, {
    onSuccess: () => {
      toast.success('Early access groups updated');
      queryClient.invalidateQueries(USER_MANAGEMENT_LIST);
      toggle(false);
    },
  });

  const { mutate: updateSchool, isLoading: updateSchoolIsLoading } = useMutation(updateSchoolEarlyAccessGroups, {
    onSuccess: () => {
      toast.success('Early access groups updated');
      queryClient.invalidateQueries(USER_MANAGEMENT_LIST);
      toggle(false);
    },
  });

  const [selectedGroups, setSelectedGroups] = useState<string[]>(user?.early_access_groups ?? []);

  const isLoading = updateUserIsLoading || updateSchoolIsLoading;

  return (
    <Modal>
      <Modal.Body>
        <p className={'sm-field-mb'}>Early access groups:</p>
        {isLoadingGroups ? (
          <div className={'block text-center text-muted'}>Loading...</div>
        ) : groups?.length === 0 || isError ? (
          <div className={'block text-center text-muted'}>There are no groups set yet.</div>
        ) : (
          groups?.map((group: string) => (
            <div className={'flex-grow'} key={group}>
              <RadioGroupOnBlock
                required
                id={group}
                options={[{ value: group, label: group }]}
                handleChange={(value: string) => {
                  if (selectedGroups.includes(value)) {
                    setSelectedGroups(selectedGroups.filter((group) => group !== value));
                    return;
                  }

                  setSelectedGroups([...selectedGroups, value]);
                }}
                optionChecked={selectedGroups.includes(group) ? group : null}
              />
              <br />
            </div>
          ))
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} />
        <Modal.ConfirmButton
          onClick={() => updateUser({ userId: user.id, groups: selectedGroups })}
          label={isLoading ? 'Loading...' : 'Update for this user'}
          disabled={isLoading || isError}
        />
        {user.role === ROLE_TEACHER && (
          <Modal.ConfirmButton
            onClick={() => updateSchool({ userId: user.id, groups: selectedGroups })}
            label={isLoading ? 'Loading...' : 'Update for all users in this school/college'}
            disabled={isLoading}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AssignEarlyAccessGroupsModal;
