import React, { useState } from 'react';
import Tab from '../../../global/tab/tab';
import DevelopmentPathwaysTable from './developmentPathwaysTable';
import DevelopmentActivitiesTable from './developmentActivitiesTable';
import { Section } from '../../common';
import { RolesType } from '../../../resources/roles';

const DEVELOPMENT_PATHWAYS_TAB = 1;
const DEVELOPMENT_ACTIVITIES_TAB = 2;

export const TABS = [
  {
    value: DEVELOPMENT_PATHWAYS_TAB,
    label: 'Development cycles',
  },
  {
    value: DEVELOPMENT_ACTIVITIES_TAB,
    label: 'Development activities',
  },
];

const CPDPassportTable = ({
  academicYear,
  userRole,
  setHidePathwaysTable,
  setHideActivitiesTable,
  hidden,
}: {
  academicYear?: string;
  userRole: RolesType;
  setHidePathwaysTable?: any;
  setHideActivitiesTable?: any;
  hidden?: boolean | string | undefined;
}) => {
  const [activeTab, setActiveTab] = useState<string | number>(DEVELOPMENT_PATHWAYS_TAB);

  return (
    <Tab
      items={TABS}
      active={activeTab}
      clickOnTab={(value) => {
        setActiveTab(value);
      }}
    >
      <div className={'mt-2'}>
        <Tab.Content id={DEVELOPMENT_PATHWAYS_TAB} active={activeTab}>
          <Section size={'md'}>
            <DevelopmentPathwaysTable
              academicYear={academicYear}
              userRole={userRole}
              setHidePathwaysTable={setHidePathwaysTable}
              hidden={hidden}
            />
          </Section>
        </Tab.Content>
        <Tab.Content id={DEVELOPMENT_ACTIVITIES_TAB} active={activeTab}>
          <Section size={'md'}>
            <DevelopmentActivitiesTable
              academicYear={academicYear}
              userRole={userRole}
              setHideActivitiesTable={setHideActivitiesTable}
              hidden={hidden}
            />
          </Section>
        </Tab.Content>
      </div>
    </Tab>
  );
};

export default CPDPassportTable;
