import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { PATHWAYS_V3 } from '../../../service/queryKeys';
import { getV3Pathways } from '../../../service/api';
import V3PathwayItem from '../components/v3/V3PathwayItem';
import { V3Pathway } from '../../../@types/Entity/V3Pathway';
import Choice from '../../../form/choice/choice';
import useModal from '../../../hooks/useModal';
import DeleteV3PathwayModal from '../modals/v3/DeleteV3PathwayModal';
import Empty from '../../../global/empty/empty';

const PathwayV3View = ({ openEditModal }: PathwayV3ViewType) => {
  const [showArchived, setShowArchived] = useState(false);

  const [modal, toggleModal, setModalProps] = useModal((props: any) => <DeleteV3PathwayModal {...props} />, {
    title: 'Delete development cycle',
  });

  const v3Pathways = useQuery([PATHWAYS_V3, showArchived], () => getV3Pathways({ archived: +showArchived }), {
    select: (data) => data.data.data,
    staleTime: Infinity,
  });

  return (
    <>
      <div className={'block-with-sub-container'}>
        <div className={'flex justify-end items-center mb-5'}>
          <Choice
            id={'archived-classes'}
            label={'View archived development cycles'}
            type={'switch'}
            checked={showArchived}
            onChange={() => setShowArchived((state) => !state)}
            className={'pretty-no-margin'}
          />
        </div>
        {v3Pathways.data?.length === 0 ? (
          <div className={'mb-2'}>
            <Empty who={'you do not'} element={'development cycles'} />
          </div>
        ) : (
          v3Pathways.data?.map((pathway) => (
            <V3PathwayItem
              key={pathway.id}
              pathway={pathway}
              openEditModal={() => openEditModal(pathway)}
              openDeleteModal={() => {
                setModalProps({ pathway, description: pathway.title });
                toggleModal(true);
              }}
            />
          ))
        )}
      </div>
      {modal}
    </>
  );
};

type PathwayV3ViewType = {
  openEditModal: (pathway: V3Pathway) => void;
};

export default PathwayV3View;
