import React from 'react';
import VideoSection from '../../../dashboard/sections/videoSection';
import Icon from '../../../../global/icon/icon';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import Button from '../../../../global/button/button';
import { useQuery } from 'react-query';
import { THINKIFIC_SSO } from '../../../../service/queryKeys';
import { getThinkificSSO } from '../../../../service/api';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

const CourseInfoPage = ({ resource }: { resource?: any }) => {
  const thinkificSSO = useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
    select: (data: any) => data.data.data.link,
    refetchInterval: 120000, //2 min
    refetchIntervalInBackground: true,
  });

  const course = `${thinkificSSO.data}${resource?.course_link}`;

  const mobileScreen = useMediaQuery({ query: `(max-width: 1000px)` });

  const manipulateColor = (hexColor: string, action: 'fontColor' | 'darken', factor = 0.8) => {
    const convertHexToRGB = (hex: string) => ({
      r: parseInt(hex.substring(1, 3), 16),
      g: parseInt(hex.substring(3, 5), 16),
      b: parseInt(hex.substring(5, 7), 16),
    });

    const convertRGBToHex = (r: any, g: any, b: any) =>
      `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;

    const calculateBrightness = (r: any, g: any, b: any) => Math.round((r * 299 + g * 587 + b * 114) / 1000);

    const { r, g, b } = convertHexToRGB(hexColor);
    const brightness = calculateBrightness(r, g, b);

    if (action === 'fontColor') {
      return brightness > 125 ? 'black' : 'white';
    } else if (action === 'darken') {
      const darkenedR = Math.max(0, Math.round(r * factor));
      const darkenedG = Math.max(0, Math.round(g * factor));
      const darkenedB = Math.max(0, Math.round(b * factor));

      return convertRGBToHex(darkenedR, darkenedG, darkenedB);
    }

    throw new Error('Invalid action parameter. Use "fontColor" or "darken".');
  };

  return (
    <>
      <div className={'mx-8 bg-white rounded-t'}>
        <div style={{ minHeight: '150px' }} className={'flex gap-2 w-full border rounded-t'}>
          <div className={'hidden sm:block m-4 flex rounded-md'}>
            <div className={'m-auto'}>
              {resource?.image_icon && (
                <img className={'w-48 h-48 object-cover rounded-sm'} src={resource.image_icon} />
              )}
              {resource?.icon && <Icon elementSize={mobileScreen ? 100 : 192} icon={resource.icon} />}
            </div>
          </div>
          <div className={'my-4 flex rounded-md m-auto sm:m-0'}>
            <h1 className="px-4 m-auto">{resource?.title}</h1>
          </div>
        </div>
        <div className={'mt-8'}>
          {resource?.urls?.length > 0 && (
            <div className={'m-auto w-1/2 my-4'}>
              <VideoSection video={resource.urls[0]} />
            </div>
          )}
          <div className={'flex'}>
            <div className={'w-1/2 m-auto'}>
              {resource?.content && (
                <Editor
                  toolbarHidden={true}
                  readOnly={true}
                  editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(resource?.content)))}
                />
              )}
            </div>
          </div>
          <div className="mb-4 pb-8">
            <div className="border-4 rounded-md m-auto w-1/2 lg:w-1/3 2xl:w-1/4 my-8 min-w-min">
              <div className={'mb-2'}>
                <div>
                  {(resource?.image_icon || resource?.icon) && (
                    <div className={'flex rounded-md'}>
                      <div className={'my-4 mx-auto'}>
                        {resource?.image_icon && (
                          <img className={'w-40 h-40 object-cover rounded-sm'} src={resource.image_icon} />
                        )}
                        {resource?.icon && <Icon elementSize={mobileScreen ? 50 : 100} icon={resource.icon} />}
                      </div>
                    </div>
                  )}
                </div>
                <div className={'mb-8'}>
                  <p className={'mx-auto w-max text-lg font-semibold'}>{resource?.title}</p>
                </div>
              </div>
              <div className={'flex mb-4 mx-4'}>
                <div className={`xl:mt-4 m-auto min-w-min `}>
                  <Link to={course} target={'_blank'}>
                    <Button
                      className={'w-full border-2 hover:opacity-100 opacity-80'}
                      style={{
                        backgroundColor: `${resource?.banner ?? 'FFFFFF'}`,
                        color: manipulateColor(resource?.banner ?? 'FFFFFF', 'fontColor'),
                        borderColor: manipulateColor(resource?.banner ?? 'FFFFFF', 'darken'),
                      }}
                    >
                      Start
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <div className={'w-1/3 mx-auto pb-1'}>
            <ResourceCard resource={resource} classes={'border bg-gray-200'}/>
          </div>*/}
        </div>
      </div>
    </>
  );
};
export default CourseInfoPage;
