import React from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Selector from '../../../form/select/selector';
import { FILE_RESOURCE_TYPE, RESOURCE_TYPES, SHARED_RESOURCE_TYPE, WEBSITE_RESOURCE_TYPE } from '../pathwaysPage.types';
import Input from '../../../form/input/input';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import DragAndDropFile from '../../../form/dragAndDrop/DragAndDropFile';
import { SelectOptionType } from '../../../service/types';
import { useQuery } from 'react-query';
import { SHARED_RESOURCES } from '../../../service/queryKeys';
import { getSharedResources } from '../../../service/api';

const AddResourceModal = ({
  toggle,
  step,
  team,
  stepPrepare,
}: ModalToggleProps & { step: string; team?: any; stepPrepare?: boolean }) => {
  const { data: sharedResource } = useQuery(
    [SHARED_RESOURCES, step, team?.id],
    () => getSharedResources(step, team!.id),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
      placeholderData: [],
      enabled: null != team,
    }
  );

  return (
    <Modal>
      <Formik
        initialValues={{
          type: stepPrepare ? 'file' : null,
          shared: null,
          name: null,
          url: null,
          file: null,
        }}
        validationSchema={Yup.object({
          type: Yup.string().nullable().required(),
          name: Yup.string()
            .nullable()
            .when('type', (type: string, schema: AnySchema) => {
              if (WEBSITE_RESOURCE_TYPE === type) {
                return Yup.string().required();
              }

              return schema;
            }),
          url: Yup.string()
            .nullable()
            .when('type', (type: string, schema: AnySchema) => {
              if (WEBSITE_RESOURCE_TYPE === type) {
                return Yup.string().required();
              }

              return schema;
            }),
          file: Yup.mixed()
            .nullable()
            .test('file-required', 'The file is required.', (resource, { parent }) => {
              if (parent.type !== FILE_RESOURCE_TYPE) {
                return true;
              }

              return resource !== null;
            })
            .test('file-size', 'The file you are attempting to upload exceed 6mb.', (resource) => {
              //6MB
              if (resource?.size >= 6 * 1000 * 1000) {
                return false;
              }

              return true;
            }),
          shared: Yup.object()
            .nullable()
            .when('type', (type: string, schema: AnySchema) => {
              if (SHARED_RESOURCE_TYPE === type) {
                return Yup.object().required();
              }

              return schema;
            }),
        })}
        validateOnMount={true}
        onSubmit={() => {
          /* Deprecated form */
        }}
      >
        {({ values, handleSubmit, setFieldValue, isValid, touched, errors, setFieldTouched }) => (
          <form className={'py-5'} onSubmit={handleSubmit}>
            <Modal.Body>
              {!stepPrepare && (
                <div className={'flex items-center gap-4 sm-field-mb'}>
                  <div className={'flex-grow mb-5'}>
                    <RadioGroupOnBlock
                      id={'modal-resource-type-selector'}
                      hideLabel
                      optionChecked={values.type}
                      options={RESOURCE_TYPES.filter((type) => {
                        return team != null ? true : type.value != SHARED_RESOURCE_TYPE;
                      })}
                      handleChange={(value) => {
                        setFieldValue('type', value);
                        setFieldValue('shared', null);
                        setFieldValue('name', null);
                        setFieldValue('url', null);
                        setFieldValue('file', null);
                      }}
                    />
                  </div>
                </div>
              )}
              {WEBSITE_RESOURCE_TYPE === values.type && (
                <>
                  <Input
                    className={'field-mb'}
                    label={'Website Name'}
                    id={'modal-resource-name-input'}
                    value={values.name ?? ''}
                    onChange={(event: any) => setFieldValue('name', event.target.value)}
                  />
                  <Input
                    className={'field-mb'}
                    label={'Website URL'}
                    id={'modal-resource-url-input'}
                    value={values.url ?? ''}
                    onChange={(event: any) => setFieldValue('url', event.target.value)}
                  />
                </>
              )}
              {FILE_RESOURCE_TYPE === values.type && (
                <DragAndDropFile
                  file={values.file}
                  setFile={(file) => {
                    setFieldValue('file', file);
                    setFieldTouched('file', true);
                  }}
                  error={touched.file ? errors.file : ''}
                  text={'Click or drag and drop to add a file'}
                />
              )}
              {SHARED_RESOURCE_TYPE === values.type && (
                <Selector
                  label={'Shared resource'}
                  id={'modal-shared-resources-selector'}
                  options={sharedResource}
                  value={values.shared}
                  onChange={(value: SelectOptionType) => setFieldValue('shared', value)}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
              <Modal.ConfirmButton
                type={'submit'}
                label={stepPrepare ? 'Add Development Plan' : 'Add Resource'}
                disabled={true}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddResourceModal;
