import React from 'react';
import WysiwygEditor from "./WysiwigEditor";

const AddChallenges = ({
  disabled = false,
  notes,
  save,
}: { disabled?: boolean, notes?: string, save: (notes: string, callback?: () => void) => void }) => {
  return (
    <WysiwygEditor
      disabled={disabled}
      save={save}
      value={notes}
      label={'Development focus'}
    />
  )
};

export default AddChallenges;
