import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MATS_SCHOOLS } from '../../../service/queryKeys';
import { getMatsSchools, preventMatSchoolCancellation } from '../../../service/api';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { UserType } from '../userManagementPage';
import useModal from '../../../hooks/useModal';
import { toast } from 'react-toastify';
import Alert from '../../../global/alert/alert';
import { AxiosError } from 'axios';
import Choice from '../../../form/choice/choice';
import classNames from 'classnames';

const PreventAutoMATSchoolSubscriptionModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => {
  const [confirmationModal, toggleConfirmationModal, setConfirmationModalProps] = useModal(
    (props: any) => <ConfirmationModal {...props} onSuccess={() => toggle(false)} />,
    {
      title: 'Cancel group (MAT) subscription',
    }
  );

  const { data: schools } = useQuery([MATS_SCHOOLS, user.id], () => getMatsSchools(user.id), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  return (
    <>
      {confirmationModal}
      <Modal>
        <Formik<{ matId: string; schools: { name: string; id: number }[] }>
          initialValues={{
            matId: user.id,
            schools: [],
          }}
          validationSchema={Yup.object().shape({
            matId: Yup.string().required(),
            schools: Yup.array()
              .of(
                Yup.object({
                  name: Yup.string(),
                  id: Yup.string(),
                })
              )
              .min(1),
          })}
          validateOnMount={true}
          onSubmit={({ schools }) => {
            setConfirmationModalProps({
              mat: { id: user!.id, name: user!.organisation },
              schools,
            });
            toggleConfirmationModal(true);
          }}
        >
          {({ isValid, handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <p className="mb-2">Select the schools that will have a subscription after the MAT cancels.</p>
                <p className="mb-8">Unselected schools will be <b>downgraded</b> after cancellation.</p>
                {schools?.map((school) => {
                  const isChecked = values.schools.some((s) => s.id === school.id);

                  return (
                    <div
                      key={Math.random()}
                      className={`bg-gray-100 p-5 border-2 field-mb rounded ${classNames({
                        'border-primary': isChecked || school.preventAutoDowngrade,
                      })}`}
                    >
                      <Choice
                        id={`${school.id}-${school.name}`}
                        label={<>{school.name}</>}
                        checked={school.preventAutoDowngrade || isChecked}
                        onChange={() => {
                          if (school.preventAutoDowngrade) {
                            return;
                          }

                          if (isChecked) {
                            setFieldValue(
                              'schools',
                              values.schools.filter((s) => s.id !== school.id)
                            );
                            return;
                          }

                          setFieldValue('schools', [...values.schools, school]);
                        }}
                      />
                    </div>
                  );
                })}
              </Modal.Body>
              <Modal.Footer>
                <Modal.CancelButton onClick={() => toggle(false)} label={'Close'} />
                <Modal.ConfirmButton label={'Select'} disabled={!isValid} type={'submit'} />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const ConfirmationModal = ({
  mat,
  schools,
  toggle,
  onSuccess,
}: ModalToggleProps & {
  mat: { name: string; id: string };
  schools: { name: string; id: number }[];
  onSuccess: () => void;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(preventMatSchoolCancellation, {
    onSuccess: () => {
      queryClient.invalidateQueries([MATS_SCHOOLS, mat.id]);
      toast.success('School subscription successfully created');
      onSuccess();
      toggle(false);
    },
    onError: () => {
      toast.error('An error has occurred');
    },
  });

  return (
    <Modal>
      <Modal.Body>
        <p className={'sm-field-mb'}>
          The group (MAT) subscription has ended then the following schools will have an individual school subscription
          set up which commences immediately after the group (MAT) subscription ends.
        </p>
        <ul>
          {schools.map((school) => (
            <li key={Math.random()}>
              <b>{school.name}</b>
            </li>
          ))}
        </ul>
        {mutation.error && (
          <Alert type={'danger'}>
            {(mutation.error as AxiosError)!.response?.data.message ?? 'An unexpected error has occurred'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} label={'Close'} />
        <Modal.ConfirmButton
          onClick={() => mutation.mutate({ schoolIds: schools.map((school) => school.id), matId: mat.id })}
          label={mutation.isLoading ? 'Loading...' : 'Confirm'}
          disabled={mutation.isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default PreventAutoMATSchoolSubscriptionModal;
