import React, { useState } from 'react';
import Button from '../../../global/button/button';
import AddResources from '../components/addResources';
import GenerateFeedback from '../components/generateFeedback';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PATHWAYS_V2_STEP } from '../../../service/queryKeys';
import { INTEGRATE_STEP } from '../views/PathwayV2View';
import { getV2PathwaysStep } from '../../../service/api';
import dayjs from 'dayjs';
import PathwayActionPlanning from './PathwayActionPlanning';
import AddReference from '../components/AddReference';
import { STUDENT_SURVEY_REFERENCE_TYPE, VIDEO_OBSERVATION_REFERENCE_TYPE } from '../modals/AddReferenceModal';

const PathwaysIntegrateTab = ({
  disabled,
  startDisabled,
  team,
  completed,
  started,
  saveStep,
}: {
  disabled: boolean;
  startDisabled: boolean;
  team?: { id: string; name: string };
  completed: boolean;
  started: boolean;
  saveStep: (data?: { completed_at?: string; challenge?: string; notes?: string }, callback?: () => void) => void;
}) => {
  const { id: pathwaysId } = useParams();

  const { data: pathwaysStep } = useQuery(
    [PATHWAYS_V2_STEP, INTEGRATE_STEP, pathwaysId],
    () => getV2PathwaysStep(INTEGRATE_STEP, pathwaysId!),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
    }
  );

  const [editMode, setEditMode] = useState(!completed);

  disabled = disabled || !editMode;

  return (
    <div>
      <div className={'flex flex-col divide-y'}>
        <div className={'font-bold flex pathways-section gap-2 justify-between'}>
          <span className={'w-5/6'}>
            In this step, you can create action plans for how to integrate your development strategy into your classroom
            practice. After weekly or fortnightly cycles of planning, implementing and evaluating (PIE), you might also
            generate feedback to help understand how well you’re integrating the strategy.
          </span>
          <Button
            id={`integrate_${completed ? 'edit' : 'start'}_step`}
            size={'md'}
            onClick={() => {
              if (completed) {
                setEditMode(true);
                return;
              }

              saveStep();
            }}
            disabled={startDisabled || (started ? editMode : !editMode)}
          >
            {completed ? 'Edit' : 'Start'} step
          </Button>
        </div>
        <div className={'pathways-section'}>
          <PathwayActionPlanning
            disabled={disabled}
            plans={pathwaysStep?.plans}
            strategyOptions={pathwaysStep?.strategies}
            description={
              'Complete a plan of the tasks you will need to try out the strategy in your classroom. You can complete multiple action plans if you wish to do more than one PIE cycle.'
            }
          />
        </div>
        <div className={'pathways-section'}>
          <GenerateFeedback disabled={disabled} />
        </div>
        <div className={'pathways-section'}>
          <AddReference
            disabled={disabled}
            step={INTEGRATE_STEP}
            options={[STUDENT_SURVEY_REFERENCE_TYPE, VIDEO_OBSERVATION_REFERENCE_TYPE]}
            references={pathwaysStep?.references}
          />
        </div>
        <div className={'pathways-section'}>
          <AddResources
            disabled={disabled}
            step={INTEGRATE_STEP}
            resources={pathwaysStep?.resources}
            team={team}
            description={'Add any useful resources you encounter while trying out your chosen strategy.'}
          />
        </div>
      </div>
      <div className={'pathways-section w-full text-right'}>
        <Button
          id={'integrateCompleteButton'}
          size={'sm'}
          onClick={() =>
            saveStep({ completed_at: dayjs().format('YYYY/MM/DD') }, () => {
              setEditMode(false);
            })
          }
          disabled={disabled}
        >
          Complete
        </Button>
      </div>
    </div>
  );
};

export default PathwaysIntegrateTab;
