import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import DefaultTable from '../../../global/table/defaultTable';
import Modal from '../../../global/messages/modal/modal.components';

type CreationConfirmModalType = {
  values: any;
  handleSubmit: () => void;
};

const CreationConfirmModal = ({
  values = {},
  handleSubmit,
  toggle,
}: ModalToggleProps & CreationConfirmModalType): JSX.Element => {
  return (
    <Modal>
      <Modal.Body>
        <p className={'mb-3'}>
          {!values.element
            ? 'You are about to create a GTT Profile survey.'
            : `You are about to create a survey for ${values.element}.`}
        </p>
        <p className={'mb-3'}>Survey links will now be generated for:</p>
        <DefaultTable
          id={'new_baseline'}
          showCounter={false}
          columns={[
            { id: 'type', label: 'Type' },
            { id: 'items', label: 'Items' },
          ]}
          rows={[
            {
              id: 'classes',
              cells: [
                { id: 'type', content: <strong>Classes</strong> },
                {
                  id: 'items',
                  content: values.school_classes
                    ? values.school_classes
                        .map((schoolClass: any) => {
                          return schoolClass.label;
                        })
                        .join()
                    : '',
                },
              ],
            },
          ]}
        />
        <p className={'mt-3'}>
          <strong>Are you sure you want to continue?</strong>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} />
        <Modal.ConfirmButton onClick={handleSubmit} label={'Continue'} />
      </Modal.Footer>
    </Modal>
  );
};

CreationConfirmModal.displayName = 'CreationConfirmModal';

export default CreationConfirmModal;
