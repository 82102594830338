import React, { useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import CPDPassportSection from './sections/cpdPassportSection';
import RadarChartSection, { processedRadarData } from '../feedback/sections/radarChartSection';
import routeBuilder from '../../service/routeBuilder';
import { RouterStyled } from '../../global/link/link.styled';
import { Section } from '../common';
import Badge from './components/badge';
import usePassportBadges, { BadgeType } from './components/usePassportBadges';
import { ROLE_TEACHER_FREE, RolesType } from '../../resources/roles';
import Button from '../../global/button/button';
import useModal from '../../hooks/useModal';
import DownloadPassportModal from './modals/DownloadPassportModal';
import SideTab from '../../typeform/SideTab';
import ColourBadgeD2 from '@app/asset/images/gtt_courses_badges/color/GTT_D2.svg';
import ColourBadgeD3 from '@app/asset/images/gtt_courses_badges/color/GTT_D3.svg';
import ColourBadge41 from '@app/asset/images/gtt_courses_badges/color/GTT_4.1.svg';
import ColourBadge42 from '@app/asset/images/gtt_courses_badges/color/GTT_4.2.svg';
import ColourBadge43 from '@app/asset/images/gtt_courses_badges/color/GTT_4.3.svg';
import ColourBadge44 from '@app/asset/images/gtt_courses_badges/color/GTT_4.4.svg';
import ColourBadge45 from '@app/asset/images/gtt_courses_badges/color/GTT_4.5.svg';
import ColourBadge46 from '@app/asset/images/gtt_courses_badges/color/GTT_4.6.svg';
import ColourBadgeGTT_Foundation from '@app/asset/images/gtt_courses_badges/color/GTT_Foundation.svg';
import CustomRadarChart from '../feedback/components/customRadarChart';
import { courseNames, gttProfileRadarData } from '../../resources/starterTemplateData';
import VideoAlert from '../../guides/components/videoAlert';
import { emulatedElementType } from '@app/frontend/src/routes';

const activeBadgesSet = (courses: any, programs: any) => {
  const badges = courses.concat(programs).filter((badge: any) => badge.date != null);
  if (badges.length > 0) {
    return true;
  } else {
    return false;
  }
};

const CPDPassportPage = ({
  userRole,
  emulatedElement,
}: {
  userRole: RolesType;
  emulatedElement?: emulatedElementType;
}) => {
  const [courses, programs] = usePassportBadges();

  const [show, setShow] = useState<boolean | string | undefined>('undefined');

  const [showChart, setShowChart] = useState();
  const [downloadModal, toggleDownloadModal] = useModal(
    (props: any) => <DownloadPassportModal activeBadges={activeBadgesSet(courses, programs)} {...props} />,
    {
      title: 'Download passport',
    }
  );

  const isFree = userRole === ROLE_TEACHER_FREE;

  return (
    <DashboardLayout
      title={'Passport'}
      pageAction={isFree ? <></> : <Button onClick={() => toggleDownloadModal(true)}>Download</Button>}
    >
      {downloadModal}
      {isFree && (
        <div>
          <VideoAlert
            setVideo={undefined}
            showHideForever={false}
            showHide={false}
            starterTemplate={isFree}
            data={{
              title: '',
              id: 'starter_template_passport',
              description: (
                <>
                  <p className={'mb-4'}>
                    Here is an example of a teacher’s professional development ‘Passport’, having chosen to explore
                    Element {emulatedElement.value} - {emulatedElement.label}.
                  </p>
                  <p>
                    The professional development Passport acts as a log for all of your professional development while
                    using the Great Teaching Toolkit. You can add additional PD to your log too, and download it as a
                    PDF.
                  </p>
                </>
              ),
            }}
          />
        </div>
      )}
      <div className={'flex-1 block-with-sub-container'} style={{}}>
        <SideTab formId={'E7jKF9T9'} />
        <Section size={'md'}>
          {isFree && showChart ? (
            <CustomRadarChart data={gttProfileRadarData(emulatedElement)} id={'emulatedStarterChart'} />
          ) : (
            <RadarChartSection
              lastInstrument={true}
              printChoices={false}
              freeAndNoChart={setShowChart}
              alertMessage={
                <div>
                  When you complete a full GTT profile, it will be shown here. If you&apos;d like to set up a student
                  survey to generate your profile now, click{' '}
                  <RouterStyled to={routeBuilder('studentSurveys').route}>here</RouterStyled>
                </div>
              }
              isFree
            />
          )}
        </Section>
        <Section size={'md'} className={'mt-5'}>
          <div className={'flex justify-around items-start flex-col lg:flex-row'}>
            <div className={'grid justify-items-center gap-3 grid-cols-2 2xl:grid-cols-3 2xl:gap-1 lg:w-2/3 w-full'}>
              {courses.map((course: BadgeType) => {
                const finishedCoursesOrPrograms =
                  courses.some((course) => course.date !== null) || programs.some((course) => course.date !== null);
                const courseName = `Element ${emulatedElement.value}: ${emulatedElement.label}`;

                const isDimensionTwo = emulatedElement.value.charAt(0) === '2';
                const isDimensionThree = emulatedElement.value.charAt(0) === '3';

                if (!finishedCoursesOrPrograms) {
                  if (isFree && isDimensionTwo && 'Dimension 2: Creating a supportive environment' === course.name) {
                    return (
                      <Badge key={'course-' + course.id} name={course.name} date={course.date} image={ColourBadgeD2} />
                    );
                  }
                  if (isFree && isDimensionThree && 'Dimension 3: Maximising opportunity to learn' === course.name) {
                    return (
                      <Badge key={'course-' + course.id} name={course.name} date={course.date} image={ColourBadgeD3} />
                    );
                  }
                  if (isFree && course.name === 'Foundation: An evidence-based approach to Great Teaching')
                    return (
                      <Badge
                        key={'course-' + course.id}
                        name={course.name}
                        date={course.date}
                        image={ColourBadgeGTT_Foundation}
                      />
                    );
                  if (
                    isFree &&
                    courseNames.includes(course.name) &&
                    courseNames.includes(courseName) &&
                    courseName === course.name
                  )
                    return (
                      <Badge
                        key={'course-' + course.id}
                        name={course.name}
                        date={course.date}
                        image={
                          emulatedElement.value === '4.1'
                            ? ColourBadge41
                            : emulatedElement.value === '4.2'
                            ? ColourBadge42
                            : emulatedElement.value === '4.3'
                            ? ColourBadge43
                            : emulatedElement.value === '4.4'
                            ? ColourBadge44
                            : emulatedElement.value === '4.5'
                            ? ColourBadge45
                            : emulatedElement.value === '4.6'
                            ? ColourBadge46
                            : undefined
                        }
                      />
                    );
                  else
                    return (
                      <Badge key={'course-' + course.id} name={course.name} date={course.date} image={course.image} />
                    );
                } else
                  return (
                    <Badge key={'course-' + course.id} name={course.name} date={course.date} image={course.image} />
                  );
              })}
            </div>
            <div
              className={
                'grid justify-items-center gap-3 grid-cols-2 w-full mt-3 pt-3 ml-0 pl-0 border-t-4 border-l-0 lg:grid-cols-1 lg:w-1/3 border-gray-100 lg:mt-0 lg:pt-0 lg:ml-3 lg:pl-3 lg:border-t-0 lg:border-l-4'
              }
            >
              {programs.map((program: BadgeType) => {
                return (
                  <Badge key={'program-' + program.id} name={program.name} date={program.date} image={program.image} />
                );
              })}
            </div>
          </div>
        </Section>
      </div>
      {!isFree || (isFree && [true, 'undefined'].includes(show)) ? (
        <div className={`mt-10 ${show === 'undefined' && isFree ? 'hidden' : ''}`}>
          <CPDPassportSection userRole={userRole} setShow={setShow} hidden={show} />
        </div>
      ) : (
        <></>
      )}
    </DashboardLayout>
  );
};

export default CPDPassportPage;
