import React from 'react';
import { toast } from 'react-toastify';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';

const AllCompleteConfirmModal = ({ toggle, handleSubmit }: ModalToggleProps & CompleteConfirmModalType) => (
  <Modal>
    <Modal.Body>
      <p>
        The survey will be marked as complete and you will no longer be able to access the associated survey links.
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Modal.CancelButton
        onClick={() => {
          toggle(false);
        }}
      />
      <Modal.ConfirmButton
        onClick={() => {
          handleSubmit().then(() => {
            toggle(false);
            toast.success('All elements have been marked as complete');
          });
        }}
      />
    </Modal.Footer>
  </Modal>
);

AllCompleteConfirmModal.displayName = 'AllCompleteConfirmModal';

type CompleteConfirmModalType = {
  handleSubmit: () => Promise<any>;
};

export default AllCompleteConfirmModal;
