import React from 'react';
import Button from "../../../global/button/button";
import routeBuilder from "../../../service/routeBuilder";
import { useNavigate } from "react-router-dom";

const GenerateFeedback = ({ disabled = false, description }: { disabled?: boolean, description?: string }) => {
  const navigate = useNavigate();

  return (
    <>
      <div className={'flex lg:space-x-48 space-x-5'}>
        <div className={'font-bold'}>
          Generate feedback
        </div>
        <div className={'flex space-x-3'}>
          <Button
            size={'sm'}
            style={{ textTransform: "none", color: 'white' }}
            onClick={() => {
              navigate(routeBuilder('studentSurveys').route)
            }}
            disabled={disabled}
          >
            Student survey
          </Button>
          <Button
            size={'sm'}
            style={{ textTransform: "none", color: 'white' }}
            onClick={() => {
              navigate(routeBuilder('videoObservation').route)
            }}
            disabled={disabled}
          >
            Video observation
          </Button>
        </div>
      </div>
      <i className="text-sm">{description}</i>
    </>
  );

};

export default GenerateFeedback;
