import React from 'react';
import { toast } from 'react-toastify';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';

const DeletePeriodConfirmModal = ({toggle, handleSubmit}: ModalToggleProps & DeleteConfirmModalType) => {
  return (
    <Modal>
      <Modal.Body>
        <p>Are you sure you want to remove this term?</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={() => {
            toggle(false);
          }}
        />
        <Modal.ConfirmButton
          onClick={() => {
            handleSubmit()
              .then(() => {
                toggle(false);
                toast.success('This term has been removed successfully.');
              })
              .catch((error) => {
                toast.error(error.response.data.message ?? 'An error has occurred.');
              });
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

DeletePeriodConfirmModal.displayName = 'DeleteConfirmModal';

type DeleteConfirmModalType = {
  handleSubmit: () => Promise<any>;
};

export default DeletePeriodConfirmModal;
