import React, { HTMLAttributes } from 'react';
import { AlertProps } from './alert.types';
import { AlertStyled } from './alert.styled';
import Icon from '../icon/icon';

const Alert: React.FC<AlertProps> = React.forwardRef<HTMLAttributes<HTMLDivElement>, AlertProps>(
  ({ type, title, action, children, disableIcon, ...other }: AlertProps, ref) => {
    const ICONS: any = {
      info: 'QuestionCircleFill',
      danger: 'ExclamationTriangleFill',
      warning: 'ExclamationTriangleFill',
      success: 'CheckCircleFill',
    };

    return (
      <AlertStyled ref={ref as any} type={type} {...(other as HTMLAttributes<any>)}>
        <div className={'flex items-center gap-5'}>
          <Icon icon={ICONS[type || 'info']} elementSize={40} data-id={'icon'} />
          <div className={'flex-grow'}>
            {title ? <h6 data-id={'title'}>{title}</h6> : children as any}
          </div>
          {action && action}
        </div>
        {title && <div style={{marginLeft: '60px'}}>{children as any}</div>}
      </AlertStyled>
    );
  }
);

Alert.displayName = 'Alert';

Alert.defaultProps = {
  type: 'info',
};

export default Alert;
