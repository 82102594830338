import React, { createContext, useReducer } from 'react';
import createActions, { RegistrationActionType } from './actions';
import reducer from './reducer';
import { School } from '../../@types/Entity/School';

export const NO_LINK = 'no-link';
export const MAX_PAGE = 5;

export const initialState = {
  initialPage: 1,
  currentPage: 1,
  step1: undefined, // Role definition
  step2: undefined, // Main info & subscription
  step3: undefined, // Subscription validation flag
  step4: undefined, // Finish registration process flag (Only used to print step 5)
  skipStep3: false,
  setStep: () => null,
  setState: () => null,
  cleanState: () => null,
  previousPage: () => null,
  nextPage: () => null,
  setInitialPage: () => null,
  setLink: () => null,
  submitForm: () => null,
};

const context = createContext<RegisterContextType>(initialState);

const RegistrationProvider = ({ children }: ProviderProps) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const actions = createActions(state, dispatch);

  return <context.Provider value={{ ...state, ...actions }}>{children}</context.Provider>;
};

export type StepType = 'step1' | 'step2' | 'step3' | 'step4';

export type RegistrationStateType = {
  initialPage: number;
  currentPage: number;
  step1?: Step1Type | null;
  step2?: Step2Type | null;
  step3?: Step3Type | null;
  step4?: Step4Type | null;
  skipStep3: boolean;
  link?: string;
};

export type Step1Type = {
  role?: string | null;
  token?: string | null;
};

export type Step2Type = {
  first_name: string;
  last_name: string;
  organisation: string;
  school?: School;
  country?: string;
  timezone?: string;
  email: string;
  confirm_email?: string; //Removed when sending to api
  password: string;
  confirm_password?: string; //Removed when sending to api
  blocked?: Record<string, boolean>; //Removed when sending to api
  gtt_source?: string;
  gtt_source_other?: string; //Removed when sending to api
  terms_and_conditions?: boolean; //Removed when sending to api
} & Partial<SchoolInfoType>;

export type Step3Type = {
  card_token?: string;

  coupon_code?: string | null;
  teachers: number | null;
  payment: string;
  first_name: string;
  last_name: string;
  email: string;
  address_1: string;
  address_2?: string;
  city?: string;
  country: string;
  postcode: string;
  start_date: string | null;
  joining_acer_school: boolean;
};

export type ExistingStep3Type = {
  teachers?: number;
};

export type Step4Type = MatInfoType | TeacherInfoType | { captcha: string | null };

export type MatInfoType = {
  schools: Array<SchoolType>;
  captcha: string | null;
};

export type SchoolInfoType = {
  role_description: string;
  age_range: Array<string>;
  category_1: string;
  category_2: string;
  category_3: string;
  socioeconomic_composition: string;
};

export type TeacherInfoType = {
  role_description: string;
  role_type?: string;
  role_other_type?: string; //Removed when sending to api
  experience: number | null;
  mentoring: boolean | null;
  subjects: Array<string>;
  year: string | null;
  qualifications: string;
  staff_number: string | null;
  captcha: string | null;
};

type SchoolType = {
  name: string;
  slots: string | number;
  coordinator_name: string;
  coordinator_lastname: string;
  coordinator_email: string;
};

export type RegisterContextType = RegistrationStateType & RegistrationActionType;

export type ProviderProps = {
  children: React.ReactNode;
};

const useRegistrationContext = () => React.useContext(context);

export { RegistrationProvider, useRegistrationContext };
