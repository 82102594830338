import React from 'react';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { PATHWAYS_V3 } from '../../../../service/queryKeys';
import { deleteV3Pathway } from '../../../../service/api';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { toast } from 'react-toastify';

const DeleteV3PathwayModal = ({ toggle, pathway }: ModalToggleProps & { pathway: V3Pathway }) => {
  const queryClient = useQueryClient();

  const deleteV3PathwayMutation = useMutation(deleteV3Pathway, {
    onSuccess: () => {
      queryClient.invalidateQueries([PATHWAYS_V3]);
      toast.success('Development cycle removed successfully ');
      toggle(false);
    },
  });

  return (
    <Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          deleteV3PathwayMutation.mutate(pathway.id);
        }}
      >
        <Modal.Body>
          Are you sure you would like to delete this Development cycle? This action cannot be undone
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton
            label={deleteV3PathwayMutation.isLoading ? 'Loading...' : 'Delete'}
            type={'submit'}
            disabled={deleteV3PathwayMutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteV3PathwayModal;
