import React, { useState } from 'react';
import Accordion from '../../../global/accordion/accordion';
import { MeetingTemplate } from '../../../@types/Entity/MeetingTemplate';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { LinkStyled } from '../../../global/link/link.styled';

export default function MeetingTemplateAccordionItem({ meetingTemplate, onEditClick }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Accordion
      title={
        <div className="flex justify-between">
          <div>{meetingTemplate.title}</div>
          <div>
            <ButtonIcon
              size={'xsm'}
              icon={'Pencil'}
              isFree
              label={'Edit'}
              onClick={(e) => {
                e.stopPropagation();

                onEditClick();
              }}
            />
          </div>
        </div>
      }
      isOpen={isOpen}
      handleOpen={() => setIsOpen((prev) => !prev)}
    >
      <div className={'grid grid-cols-3'}>
        <div>
          <span className={'font-bold'}>Prerequisites</span>
          <ul className={'list-disc list-inside'}>
            {meetingTemplate.prerequisites.map((prerequisite, index) => (
              <li key={index}>{prerequisite.title}</li>
            ))}
          </ul>
        </div>

        <div>
          <span className={'font-bold'}>Agenda</span>
          <ul className={'list-decimal list-inside'}>
            {meetingTemplate.agendaItems.map((agendaItem, index) => (
              <li key={index}>{agendaItem.title}</li>
            ))}
          </ul>
        </div>

        <div>
          <span className={'font-bold'}>Resources</span>
          <ul className={'list-disc list-inside'}>
            {meetingTemplate.resources.map((resource, index) => (
              <li key={index}>
                <LinkStyled href={resource.url} target={'_blank'}>
                  {resource.title}
                </LinkStyled>
              </li>
            ))}
          </ul>
        </div>

        <div>{/* Empty div just to have 4 columns */}</div>
      </div>
      <div className="grid grid-cols-3 mx-auto">
        {meetingTemplate?.purpose && (
          <div className={'mt-4'}>
            <span className={'font-bold'}>Purpose</span>
            <ul className={'list-disc list-inside'}>{meetingTemplate?.purpose}</ul>
          </div>
        )}
        {meetingTemplate?.purpose && (
          <div className={'mt-4'}>
            <span className={'font-bold'}>Type</span>
            <ul className={'list-disc list-inside'}>{meetingTemplate?.type}</ul>
          </div>
        )}
      </div>
    </Accordion>
  );
}

interface Props {
  meetingTemplate: MeetingTemplate;
  onEditClick: () => void;
}
