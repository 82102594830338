import React, { useState } from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { DeleteConfirmModalType, ElementInterface, ELEMENTS } from '../resources/types';
import Input from '../../../form/input/input';
import Selector from '../../../form/select/selector';
import Button from '../../../global/button/button';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';

const dimensions = [
  { label: '1. Understanding the content', value: 1 },
  { label: '2. Creating a supportive environment', value: 2 },
  { label: '3. Maximising opportunity to learn', value: 3 },
  { label: '4. Activating hard thinking', value: 4 },
];

const filterElements = (dimension: number) => {
  const array = ELEMENTS.filter((element: ElementInterface) => dimension.toString() == element.dimension_number);
  return array.map((element: ElementInterface) => {
    return {
      label: `${element.dimension_number}.${element.element_number}. ${element.element_name}`,
      value: `${element.dimension_number}.${element.element_number}`,
    };
  });
};

const GenerateRedirectLinkModal = ({ toggle }: ModalToggleProps & DeleteConfirmModalType) => {
  const [keywords, setKeywords] = useState<string>('');
  const [dimension, setDimension] = useState<any>();
  const [element, setElement] = useState<any>();

  const [link, setLink] = useState('');

  const linkGenerator = (dimension: any, element: any) => {
    let link = 'https://preview.toolkit.greatteaching.com/directTo=resource-page;';

    if (keywords) link += `&keywordSearch=${keywords.replaceAll(' ', '%20')};`;
    if (dimension && !element)
      link += `&resourcePageFilters=${dimensions
        .find((dim: any) => dim.value === dimension)
        ?.label.replaceAll(' ', '%20')};`;
    else if (dimension && element)
      link += `&resourcePageFilters=${element}%20${ELEMENTS.find(
        (el: any) => el.elementId === element
      )?.element_name.replaceAll(' ', '%20')};`;

    return setLink(link);
  };

  return (
    <Modal>
      <Modal.Body>
        <Input
          id={'keywords'}
          hideLabel={false}
          placeholder={'Keywords'}
          value={keywords}
          onChange={(event) => {
            setKeywords(event.target.value);
          }}
        />
        <div className={'mt-4'}>
          <Selector
            id={'dimensions_input'}
            placeholder={'Add dimension(s)'}
            value={dimensions.find((option) => option.value === dimension) ?? undefined}
            onChange={(option: any) => {
              if (option.value) {
                setDimension(option.value);
              } else setDimension(undefined);
            }}
            options={dimensions}
            isClearable={false}
          />
        </div>
        {dimension && (
          <div className={'mt-4'}>
            <Selector
              id={'element_input'}
              placeholder={'Add element(s)'}
              value={filterElements(dimension).find((option) => option.value === element) ?? undefined}
              onChange={(option: any) => {
                if (option.value) {
                  setElement(option.value);
                } else setElement(undefined);
              }}
              options={filterElements(dimension)}
              isClearable={false}
            />
          </div>
        )}
        <div className="flex">
          <Button className={'mx-auto mt-4'} onClick={() => linkGenerator(dimension, element)}>
            Generate link
          </Button>
        </div>
        {link.length > 0 && (
          <>
            <div className={'grid'}>
              <Input className={'mt-4 mb-8'} id={'link'} value={link} />
              <div className={'mt-4 flex mx-auto'}>
                <CopyToClipboard text={link} onCopy={() => toast.success('Link copied')}>
                  <Button className={'m-auto mt-4'} isOutline size={'sm'}>
                    Copy link
                  </Button>
                </CopyToClipboard>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={() => {
            toggle(false);
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

GenerateRedirectLinkModal.displayName = 'GenerateRedirectLink';

export default GenerateRedirectLinkModal;
