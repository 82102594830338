import React, { MutableRefObject } from 'react';
import TagIcon, { TagType } from './TagIcon';
import classnames from 'classnames';
import { LinkStyled } from '../../../global/link/link.styled';

const TagSet = ({
  tagSet,
  userId,
  videoLength,
  onClick,
  isSession = false,
  removeTag,
  toggleCommentModal,
  onEdit,
}: {
  tagSet: TagSetType;
  userId?: string;
  videoLength: number;
  videoElement: MutableRefObject<any>;
  onClick?: (tag: TagType) => void;
  isSession?: boolean;
  removeTag?: (id: number | string) => void;
  toggleCommentModal?: (id: number | string, comment?: string) => void;
  onEdit?: (tagSet: TagSetType) => void;
}) => {
  return (
    <div
      className={`h-20 p-2 relative ${classnames({ 'hover:opacity-80': !isSession })} ${!isSession && !tagSet.tag_set && !tagSet.created_by && 'mb-2'}`}
      style={{ backgroundColor: '#4f5658' }}
    >
      <div
        className={classnames({ 'flex justify-between': (tagSet.created_by?.id === userId && !isSession) || (!isSession && !tagSet.tag_set && !tagSet.created_by) })}>
        <span className={'text-muted pl-2'} style={{ color: 'white' }}>
          {tagSet.tag_set && tagSet.created_by && `${tagSet.tag_set.name} by ${tagSet.created_by.name}`}
          {!isSession && !tagSet.tag_set && !tagSet.created_by && 'Feedback summary'}
        </span>
        {!isSession && tagSet.created_by?.id === userId && (
          <LinkStyled
            className={'pr-5'}
            style={{ color: 'white' }}
            onClick={(e: any) => {
              e.preventDefault();
              if (null != onEdit) {
                onEdit(tagSet);
              }
            }}
          >
            edit
          </LinkStyled>
        )}
      </div>
      <svg height={'100%'} width={'100%'} className={'pr-2'}>
        <rect x="0" y="15" width="100%" height="5" fill={'#9b9f9f'}/>
      </svg>
      {tagSet.tags.map((tag) => (
        <TagIcon
          showToolTip={true}
          name={tag.icon.name}
          colour={tag.icon.colour}
          comment={tag.comment}
          key={tag.id}
          className={'absolute cursor-pointer'}
          style={{ left: `${(tag.time * 100) / videoLength}%`, top: '50%', right: 0, bottom: 0 }}
          isSession={isSession}
          tagName={tag.name}
          onClick={() => {
            if (null != onClick) {
              onClick(tag);
            }
          }}
          removeTag={() => {
            if (null != removeTag) {
              removeTag(tag.id!);
            }
          }}
          toggleCommentModal={() => {
            if (null != toggleCommentModal) {
              toggleCommentModal(tag.id!, tag.comment);
            }
          }}
        />
      ))}
    </div>
  );
};

export type TagSetType = {
  id?: number;
  tag_set?: {
    id: number;
    name: string;
  };
  created_by?: {
    id: number;
    name: string;
  };
  created_at?: string;
  tags: Array<TagType>;
};

export default TagSet;
