import React from 'react';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { deleteV3PathwayStrategy } from '../../../../service/api';
import { PATHWAYS_V3 } from '../../../../service/queryKeys';
import { Strategy } from '../../../../@types/Entity/Strategy';
import { toast } from 'react-toastify';

const DeleteV3StrategyModal = ({
  toggle,
  strategy,
  pathwayId,
}: ModalToggleProps & { strategy: Strategy; pathwayId: string }) => {
  const queryClient = useQueryClient();

  const deleteV3StrategyMutation = useMutation(deleteV3PathwayStrategy, {
    onSuccess: () => {
      toast.success('Strategy removed');
      queryClient.invalidateQueries([PATHWAYS_V3, pathwayId]);
      toggle(false);
    },
  });

  return (
    <Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          deleteV3StrategyMutation.mutate({ pathwayId: pathwayId, strategyId: strategy.id });
        }}
      >
        <Modal.Body>Are you sure you would like to delete this strategy? This action cannot be undone</Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton
            label={deleteV3StrategyMutation.isLoading ? 'Loading...' : 'Delete'}
            type={'submit'}
            disabled={deleteV3StrategyMutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteV3StrategyModal;
