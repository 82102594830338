import React, { useEffect, useState } from 'react';
import CourseInfoPage from './textResourcePages/courseInfoPage';
import TextResourcesDefaultPage from './textResourcePages/defaultPage';
import GuidePage from './textResourcePages/guidePage';
import InsetPackPage from './textResourcePages/insetPackPage';
import PosterCollectionPage from './textResourcePages/posterCollectionPage';
import TechniqueInfoPage from './textResourcePages/techniqueInfoPage';
import DashboardLayout from '../../../layout/dashboard';
import { useQuery } from 'react-query';
import { RESOURCES_BY_SLUG } from '../../../service/queryKeys';
import { getResourcBySlug } from '../../../service/api';
import ResourceTextPage from './textResourcePages/resourceTextPage';
import SelfStudyPage from './textResourcePages/selfStudy/selfStudyPage';

const TextResourcePage = ({ slug }: { slug: string }) => {
  const { data: textResourceBySlug } = useQuery([RESOURCES_BY_SLUG, slug], () => getResourcBySlug(slug), {
    select: (data) => data.data.data,
    placeholderData: null,
  });

  useEffect(() => {
    setTimeout(() => {
      if (textResourceBySlug) setView(textResourceBySlug?.type);
      else setView('error');
    }, 1000);
  }, [textResourceBySlug]);

  const [view, setView] = useState<any>('loading');
  const [fullPage, setFullPage] = useState(false);
  const [theme, setTheme] = useState('light');

  return (
    <>
      {fullPage ? (
        <div className={'flex flex-1 h-full w-full'}>
          <SelfStudyPage
            setFullPage={setFullPage}
            fullPage
            resource={textResourceBySlug}
            theme={theme}
            setTheme={setTheme}
          />
        </div>
      ) : (
        <DashboardLayout
          parent={{ id: 'resourcePage', label: 'Resources' }}
          hideTitle={true}
          title={view === 'loading' ? 'Loading...' : textResourceBySlug?.title}
        >
          {view === 'loading' ? (
            <>Fetching the resource...</>
          ) : textResourceBySlug && view ? (
            textResourceBySlug.type === 'course_info' ? (
              <CourseInfoPage resource={textResourceBySlug} />
            ) : textResourceBySlug.type === 'guide' ? (
              <GuidePage resource={textResourceBySlug} />
            ) : textResourceBySlug.type === 'inset_pack' ? (
              <InsetPackPage resource={textResourceBySlug} />
            ) : textResourceBySlug.type === 'poster_collection' ? (
              <PosterCollectionPage resource={textResourceBySlug} />
            ) : textResourceBySlug.type === 'self_study' ? (
              <SelfStudyPage
                setFullPage={setFullPage}
                resource={textResourceBySlug}
                theme={theme}
                setTheme={setTheme}
              />
            ) : textResourceBySlug.type === 'technique_info' ? (
              <TechniqueInfoPage resource={textResourceBySlug} />
            ) : textResourceBySlug.type === 'template' ? (
              <ResourceTextPage resource={textResourceBySlug} />
            ) : (
              <>
                Resource&apos;s type not found. Here&apos;s some info{' '}
                <TextResourcesDefaultPage resource={textResourceBySlug} />
              </>
            )
          ) : view === 'error' ? (
            <>There&apos;s been error fetching the resource. Please contact the EBE team.</>
          ) : (
            <>Unexpected Error!!!</>
          )}
        </DashboardLayout>
      )}
    </>
  );
};
export default TextResourcePage;
