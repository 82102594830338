import React from 'react';
import Icon from '../icon/icon';
import { EmptyProps } from './empty.types';
import { GLOBAL_ICONS } from '../../resources/vars';

const Empty = ({ who = 'you do not', element, action }: EmptyProps): JSX.Element => (
  <div className={'text-center text-muted mx-auto'}>
    <Icon icon={GLOBAL_ICONS.empty} elementSize={40} color={'muted'} container={false} className={'mx-auto'} />
    <p className={'italic mt-2'}>{`Hey! It seems ${who} have any ${element} yet.`}</p>
    {action && (
      <p className={'italic mt-2'}>
        Start adding {element} {action}.
      </p>
    )}
  </div>
);

Empty.displayName = 'Empty';

export default Empty;
