import React, { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ButtonIconProps } from '../buttonIcon.types';
import { TableCopyProps, TableStateProps } from '../../table/table.types';
import ButtonIcon from '../buttonIcon';
import { GLOBAL_ICONS } from '../../../resources/vars';

type ActionButtonProps = {
  archived?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

type ActionTypes =
  | 'close'
  | 'edit'
  | 'copy'
  | 'create'
  | 'remove'
  | 'more'
  | 'view'
  | 'enable'
  | 'disable'
  | 'archive'
  | 'unarchive';

const ActionButton = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <React.Fragment>{children}</React.Fragment>
);

const ActionButtonComponent = ({
  icon,
  buttonLabel,
  archived = false,
  onClick,
  uniqueColor,
  ...other
}: ActionButtonProps &
  Pick<ButtonIconProps, 'tippyProps'> & {
    buttonLabel: string | React.ReactNode;
    icon: ActionTypes | keyof typeof GLOBAL_ICONS;
    uniqueColor?: string;
  } & ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <ButtonIcon
    label={buttonLabel}
    icon={GLOBAL_ICONS[icon]}
    isFree
    mainColor={uniqueColor ? uniqueColor : archived ? 'muted' : 'primary'}
    onClick={(event) => {
      if (onClick) {
        onClick(event);
      } else {
        event.preventDefault();
      }
    }}
    {...(other as Omit<ButtonIconProps, 'label' | 'icon'>)}
  />
);

// CLOSE BUTTON
const Close = ({ onClick, ...other }: ActionButtonProps) => (
  <ActionButtonComponent icon={'close'} buttonLabel={'Close'} onClick={onClick} {...other} />
);

// EDIT BUTTON
const Edit = ({ archived = false, onClick, ...other }: ActionButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => (
  <ActionButtonComponent icon={'edit'} buttonLabel={'Edit'} archived={archived} onClick={onClick} {...other} />
);

// COPY BUTTON
const Copy = ({
  label,
  text,
  onCopy,
  ...other
}: TableCopyProps & Pick<ButtonIconProps, 'mainColor' | 'size' | 'label'>) => (
  <CopyToClipboard text={text} onCopy={onCopy}>
    <ActionButtonComponent icon={'copy'} buttonLabel={label} {...other} />
  </CopyToClipboard>
);

// CREATE BUTTON
const Create = ({
  archived = false,
  onClick,
  ...other
}: ActionButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => (
  <ActionButtonComponent
    icon={'create'}
    buttonLabel={'Create a new item'}
    archived={archived}
    onClick={onClick}
    {...other}
  />
);

// REMOVE BUTTON
const Remove = ({ onClick, ...other }: ActionButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => (
  <ActionButtonComponent icon={'remove'} buttonLabel={'Remove'} onClick={onClick} {...other} />
);

// MORE BUTTON
const More = ({
  archived,
  label,
  uniqueColor,
  ...other
}: ActionButtonProps & {
  label: React.ReactNode;
  disabled?: boolean;
  uniqueColor?: string;
}) => (
  <ActionButtonComponent
    tippyProps={{
      theme: 'light-border',
      placement: 'bottom-end',
    }}
    icon={'more'}
    buttonLabel={label}
    archived={archived}
    uniqueColor={uniqueColor ? uniqueColor : ''}
    {...other}
  />
);

// VIEW BUTTON
const View = ({
  archived = false,
  label = 'View subscription info',
  onClick,
  ...other
}: ActionButtonProps & ButtonHTMLAttributes<HTMLButtonElement> & { label?: string }) => (
  <ActionButtonComponent icon={'view'} buttonLabel={label} onClick={onClick} {...other} />
);

// CHANGE BUTTON
const Change = ({
  state,
  states,
  archived,
  onClick,
}: ActionButtonProps & Omit<TableStateProps, 'onClick'> & ButtonHTMLAttributes<HTMLButtonElement>) => (
  <ActionButtonComponent
    icon={states[state ? 'off' : 'on'].icon as 'enable' | 'disable' | 'archive' | 'unarchive'}
    buttonLabel={states[state ? 'off' : 'on'].label}
    archived={archived}
    onClick={onClick}
  />
);

ActionButton.Close = Close;
ActionButton.Edit = Edit;
ActionButton.Copy = Copy;
ActionButton.Create = Create;
ActionButton.Remove = Remove;
ActionButton.More = More;
ActionButton.View = View;
ActionButton.Change = Change;
ActionButton.Custom = ActionButtonComponent;

export default ActionButton;
