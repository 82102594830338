import React, { InputHTMLAttributes } from 'react';
import classnames from 'classnames';
import { Checkbox, Radio, Switch } from 'pretty-checkbox-react';
import { FieldProps, LabelProps, ChoiceProps } from '../common/field.types';
import ErrorFeedback from '../common/error';
import { getAriaDescribedby } from '../../helpers/misc';

interface ChoiceGroupProps extends Omit<FieldProps, 'icon'>, LabelProps, ChoiceProps {}

const Choice: React.FC<ChoiceGroupProps & InputHTMLAttributes<any>> = React.forwardRef<
  HTMLInputElement,
  ChoiceGroupProps
>(({ id, label, type, hideLabel, error, hintText, ...other }: ChoiceGroupProps & InputHTMLAttributes<any>, ref) => {
  const GLOBAL_PROPS = {
    ref: ref,
    id: id,
    value: id,
    color: 'primary-o',
    animation: 'smooth',
    hasFocus: true,
    'aria-describedby': getAriaDescribedby(id),
    ...(other as ChoiceProps),
  };

  const getLabel = (
    <label htmlFor={id} className={`${classnames({ 'sr-only ': hideLabel })}ml-2`}>
      {label}
    </label>
  );

  return (
    <React.Fragment>
      <div className={'flex'}>
        <div>
          {'checkbox' === type && <Checkbox shape={'curve'} {...(GLOBAL_PROPS as any)} />}
          {'radio' === type && <Radio {...(GLOBAL_PROPS as any)} />}
          {'switch' === type && <Switch {...(GLOBAL_PROPS as any)} color={'primary'} />}
        </div>
        {label && getLabel}
      </div>
      {hintText && <div className={'text-muted text-sm mt-2'}>{hintText}</div>}
      {error && <ErrorFeedback id={id} error={error} />}
    </React.Fragment>
  );
});

Choice.displayName = 'Choice';

Choice.defaultProps = {
  type: 'checkbox',
  hideLabel: false,
};

export default Choice;
