import React from 'react';

const StepTab = ({ step, index }: any) => {

    const today = new Date().getTime();
    const dayInMsec = 86400000;

    const formattingDate = (date: any) => {
      const dateParts = date.split("/");
      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).getTime();
    }

    const todayIncluded = (currentDate: any, stepStartDate: any, stepEndDate: any) => {
      if (currentDate >= formattingDate(stepStartDate) && currentDate <= (formattingDate(stepEndDate) + dayInMsec))
        return true
      else return false
    }

    return (
      <>
        <div id={"stepID"}
             style={{ minHeight: '180px' }}
             className={`${todayIncluded(today, step.start_date, step.end_date) ? 'border-primary' : ''} align-middle flex flex-col bg-gray-100 rounded-md border-2 transition-border p-5 duration-500 ease-in-out`}>
          <div className={'flex items-center justify-between gap-2'}>
            <div className={'flex flex-grow items-center'}>
              <div className={'w-full'}>
                <div className={'flex w-full justify-between mb-2'}>
                  <div className={'font-bold leading-tight mb-1'}>{step.pathwayStep}</div>
                  <div
                    className="relative w-4 h-4 bg-transparent rounded-full border-2 flex justify-center items-center text-center p-3"> {index}
                  </div>
                </div>
                <div className={'mb-2'}>
                  {step.method?.length > 0 &&
                    (<>
                      <li className={'text-sm inline-block'}>
                        {step.method.length > 1 ? <>Methods:&nbsp;</> : <>Method:&nbsp;</>}
                      </li>
                      {step.method.map((method: any, index: any, number: any) => {
                        if (index + 1 != number.length) {
                          return (
                            <li key={`method_${index}`} className={'text-sm inline-block'}>
                              {method},&nbsp;
                            </li>
                          );
                        } else {
                          return (
                            <li key={`method_${index}`} className={'text-sm inline-block'}>
                              {method}
                            </li>
                          );
                        }
                      })}
                    </>)
                  }
                </div>
                <div className={'mb-2'}>
                  {step.activityType?.length > 0 && (<>
                    <li className={'text-sm inline-block'}>
                      {step.method.length > 1 ? <>Activity types:&nbsp;</> : <>Activity type:&nbsp;</>}
                    </li>
                    {step.activityType.map((method: any, index: any, number: any) => {
                      if (index + 1 != number.length) {
                        return (
                          <li key={`activityType${index}`} className={'text-sm inline-block'}>
                            {method},&nbsp;
                          </li>
                        );
                      } else {
                        return (
                          <li key={`activityType${index}`} className={'text-sm inline-block'}>
                            {method}
                          </li>
                        );
                      }
                    })}</>)
                  }
                </div>
                <div>
                  <li className={'text-sm inline-block'}>Date: {step.start_date} - {step.end_date}</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
;

export default StepTab;
