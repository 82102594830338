import React from 'react';
import { PathwaysHeaderProps } from '../pathwaysPage.types';
import Tag from '../../../global/tag/tag';
import { Section } from '../../common';

const PathwaysHeader = ({ id, isCompleted, element, name, steps }: PathwaysHeaderProps) => (
  <Section id={id} size={'md'} className={'field-mb'}>
    {name && <Tag content={name} mainColor={'light'} className={'sm-field-mb'} />}
    <h3 className={'md:flex items-center'}>
      {steps}
      {element && (
        <span className={'ml-5 md:ml-auto'}>
          <span className={'inline md:hidden mr-5 md:mr-0'}>-</span>
          {element}
        </span>
      )}
    </h3>
    <div className={'italic text-muted'}>{isCompleted ? 'completed' : 'in progress'}</div>
  </Section>
);

export default PathwaysHeader;
