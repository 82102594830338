import React from 'react';
import classnames from 'classnames';
import { HamburgerProps } from './hamburger.types';
import { HamburgerButtonStyled, MenuHamburgerStyled } from './hamburger.styled';

const MenuHamburger = ({
  handleClick,
  mainColor = 'primary',
  size = 'md',
  toggled = false,
  ...other
}: HamburgerProps) => (
  <HamburgerButtonStyled
    onClick={() => handleClick && handleClick()}
    aria-pressed={toggled}
    tabIndex={0}
    mainColor={mainColor}
    $size={size}
    $transparent
  >
    <MenuHamburgerStyled aria-hidden={'true'} mainColor={mainColor} $size={size} $toggled={toggled} {...(other as any)}>
      <div data-id={'hamburger--bar'} className={classnames({ toggled: toggled })} />
    </MenuHamburgerStyled>
  </HamburgerButtonStyled>
);

export default MenuHamburger;
