import React from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import Icon from '../../../global/icon/icon';
import Button from '../../../global/button/button';
import posthog from 'posthog-js';

const MaintenanceModal = ({
  errorEmail,
}: {
  errorEmail?: string;
}) => {
  return (
    <Modal preventClose>
      <Modal.Body>
        <div className={'px-12 pb-12'}>
          <h1 className={'flex'}>
            <p className={'mx-auto'}>
              Site Maintenance
            </p>
          </h1>
            <div className={'flex my-12'}>
              <>
                <Icon className={'mx-auto'} icon={'Wrench'} elementSize={100} />
                <Icon className={'mx-auto'} icon={'Gear'} elementSize={100} />
                <Icon className={'mx-auto'} icon={'Screwdriver'} elementSize={100} />
              </>
            </div>
          <div className={'flex'}>
            <h4 className={'mx-auto mb-12'}>
              We sincerely apologise for the inconvenience
            </h4>
          </div>
          <div className={''}>
            <h6 className={'mx-auto text-center'}>
              Our webhost is currently undergoing a scheduled maintenance and
              upgrades, but will return shortly.
            </h6>
          <h6 className={'mt-6 mx-auto text-center'}>Thank you for your patience.</h6>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default MaintenanceModal;
