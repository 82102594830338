import React, { useRef } from 'react';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';
import Input from '../../../../form/input/input';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { addUpdateV3PathwayGoal, getV3Pathway, getV3PathwayGoalsTemplates } from '../../../../service/api';
import Selector from '../../../../form/select/selector';
import { Goal } from '../../../../@types/Entity/Goal';
import MessageAtMenuList from '../../../../form/select/components/messageAtMenuList';
import Select from 'react-select';
import { PATHWAYS_V3, V3_TEMPLATE_GOALS } from '../../../../service/queryKeys';
import { GOAL_STATUS, GOAL_STATUS_COLORS } from '../../sections/v3/V3GoalsSection';
import { toast } from 'react-toastify';

const V3GoalModal = ({
  toggle,
  goal,
  pathwayId,
  onlyStatus,
}: ModalToggleProps & {
  goal?: Goal;
  pathwayId?: string;
  onlyStatus?: boolean;
}) => {
  const selectRef = useRef<any>();
  const queryClient = useQueryClient();

  const GOAL_STATUS_LABELS = {
    [GOAL_STATUS.notStarted]: 'Not started',
    [GOAL_STATUS.behind]: 'Behind',
    [GOAL_STATUS.archived]: 'Archived',
    [GOAL_STATUS.inProgress]: 'In progress',
  };

  const addV3GoalMutation = useMutation(addUpdateV3PathwayGoal, {
    onSuccess: () => {
      toast.success(`Goal ${goal?.id ? 'edited' : 'added'}`);
      toggle(false);
      queryClient.invalidateQueries([PATHWAYS_V3, pathwayId]);
    },
  });

  const goalTemplates = useQuery([V3_TEMPLATE_GOALS, pathwayId], () => getV3PathwayGoalsTemplates(pathwayId!), {
    select: (data) => data.data.data,
    staleTime: Infinity,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      ...goal,
      title: goal?.name
        ? {
            label: goal.name,
            value: goal.name,
          }
        : null,
      status: goal?.status
        ? {
            label: GOAL_STATUS_LABELS[goal.status],
            value: goal.status,
          }
        : null,
    },
    resolver: yupResolver(
      Yup.object().shape({
        number: Yup.number()
          .min(1)
          .nullable()
          .test('is-number-required', '', (value) => {
            if (goal?.from_invitation) {
              return true;
            }

            return value != null && value > 0;
          }),
        title: Yup.object({
          value: Yup.string().max(200).trim(),
        }).required(),
        description: Yup.string().trim().nullable(),
      })
    ),
  });

  return (
    <Modal>
      <form
        onSubmit={handleSubmit((data) => {
          addV3GoalMutation.mutate({
            id: data.id,
            number: data.number,
            name: data.title?.value,
            description: data.description,
            pathwayId: pathwayId,
            status: data.status?.value,
          });
        })}
      >
        <Modal.Body>
          {onlyStatus ? (
            <div className={'field-mb'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Selector
                    {...field}
                    required
                    id="status"
                    label={'Status'}
                    placeholder={'Select a status'}
                    options={Object.keys(GOAL_STATUS_COLORS).map((key) => ({
                      label: GOAL_STATUS_LABELS[key],
                      value: key,
                    }))}
                    errorMessage={errors.status?.message as string}
                    isClearable={false}
                  />
                )}
                name={'status'}
              />
            </div>
          ) : (
            <>
              <div className={'field-mb'}>
                <Input
                  {...register('number')}
                  required
                  label={'Number'}
                  id={`number`}
                  placeholder={'Insert a number'}
                  error={errors.number?.message as string}
                />
              </div>
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      isCreatableSelect
                      required
                      ref={(e: Select) => {
                        field.ref(e);
                        selectRef.current = e;
                      }}
                      onFocus={() => {
                        if (watch().title) {
                          selectRef.current.state.inputValue = watch().title?.value ?? '';
                        }
                      }}
                      id="title"
                      label={'Title'}
                      placeholder={'Select or insert a title'}
                      options={goalTemplates.data?.map((goal) => ({
                        label: goal.name,
                        value: goal.name,
                        description: goal.description,
                      }))}
                      errorMessage={errors.title?.message as string}
                      onChange={(e: any) => {
                        field.onChange(e);

                        if (e?.description) {
                          setValue('description', e.description);
                        }

                        // To force onFocus execution
                        selectRef.current.blur();
                      }}
                      customComponents={{
                        MenuList: function MenuList(menuListProps) {
                          return (
                            <MessageAtMenuList
                              message={"Don't see your goal? Add it by populating the field above."}
                              {...menuListProps}
                            />
                          );
                        },
                      }}
                    />
                  )}
                  name={'title'}
                />
              </div>
              <div className={'field-mb'}>
                <Input
                  type={'textarea'}
                  {...register('description')}
                  label={'Description'}
                  id={`description`}
                  hideCharacterCount
                  placeholder={'Insert a description'}
                  error={errors.description?.message as string}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton label={'Save'} type={'submit'} disabled={addV3GoalMutation.isLoading} />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default V3GoalModal;
