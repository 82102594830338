import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import { ROLE_TEACHER_FREE } from '../../resources/roles';
import FreePage from '@app/asset/images/Pathways.png';
import PathwayV3View from './views/PathwayV3View';
import { AddButton } from '../../global/button/common';
import AddEditV3PathwayModal from './modals/v3/AddEditV3PathwayModal';
import useModal from '../../hooks/useModal';
import { V3Pathway } from '../../@types/Entity/V3Pathway';
import VideoAlert from '../../guides/components/videoAlert';
import { useQuery } from 'react-query';
import { ME, PATHWAYS_V3 } from '../../service/queryKeys';
import { getMe, getV3Pathways } from '../../service/api';
import { isLoggedIn } from '../../service/auth';
import routeBuilder from '../../service/routeBuilder';
import { useNavigate } from 'react-router-dom';
import { emulatedElementType } from '@app/frontend/src/routes';

const PathwaysV3Page = ({ role, emulatedElement }: { role: string; emulatedElement?: emulatedElementType }) => {
  const [modal, toggleModal, setModalProps] = useModal((props: any) => <AddEditV3PathwayModal {...props} />, {
    title: 'Add Development cycle',
  });

  const getMeQuery = useQuery([ME], () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const me = getMeQuery.data?.data.me ?? null;

  const [showAlert, setShowAlert] = useState<boolean>(!me!.show_video.includes('development_cycles_header'));

  const v3Pathways =
    me?.role === ROLE_TEACHER_FREE && emulatedElement
      ? useQuery(
          [PATHWAYS_V3],
          () =>
            getV3Pathways({
              archived: +false,
              element: emulatedElement.value,
            }),
          { select: (data) => data.data.data, staleTime: Infinity }
        )
      : undefined;

  const navigate = useNavigate();

  useEffect(() => {
    if (me?.role === ROLE_TEACHER_FREE && v3Pathways) {
      const pathwayId = v3Pathways?.data && v3Pathways.data.length > 0 ? v3Pathways.data[0].id : undefined;
      navigate(routeBuilder('pathways').generate(pathwayId, 3));
    }
  }, [v3Pathways]);

  if (role === ROLE_TEACHER_FREE) {
    return (
      <DashboardLayout title={'Workspace'}>
        <></>
      </DashboardLayout>
    );
  }

  return (
    <>
      <DashboardLayout
        title={'Development cycles'}
        pageAction={
          <AddButton
            label={'Create Development cycle'}
            onClick={() => {
              setModalProps({});

              toggleModal(true);
            }}
          />
        }
      >
        {showAlert && (
          <div>
            <VideoAlert
              setVideo={setShowAlert}
              showHideForever
              data={{
                title: 'Development cycles',
                id: 'development_cycles_header',
                description: (
                  <>
                    <p className={'mb-2'}>
                      Development cycles are a workspace for you to record your goals, decisions and actions as you work
                      on an element of Great Teaching.
                    </p>
                    <p>
                      If you are working in a team, accept the invite to access your workspace. If you are working as an
                      individual, click the orange &quot;+ Development cycle&quot; button to create your workspace.
                    </p>
                  </>
                ),
              }}
              userId={me!.id}
            />
          </div>
        )}
        <PathwayV3View
          openEditModal={(pathway: V3Pathway) => {
            setModalProps({ pathway, edit: true, title: `${pathway ? 'Edit' : 'Add'} Development cycle` });

            toggleModal(true);
          }}
        />
      </DashboardLayout>
      {modal}
    </>
  );
};

export default PathwaysV3Page;
