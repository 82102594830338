import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Button from '../../../global/button/button';
import Input from '../../../form/input/input';
import { upgradeTeacherAccount, validateUpgradeAccount } from '../../../service/api';
import AuthLayout from '../../../layout/auth';
import useModal from '../../../hooks/useModal';
import UpgradeModal from './modals/UpgradeModal';
import { Step2Type, useRegistrationContext } from '../../../state/registration/provider';
import { useMutation } from 'react-query';
import routeBuilder from '../../../service/routeBuilder';
import { useNavigate } from 'react-router-dom';
import { ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER } from '../../../resources/roles';

const UpgradePage = ({ token, role, skipUpgrade }: { token: string; role: string; skipUpgrade: () => void }) => {
  const { setStep, step2 } = useRegistrationContext();

  const SCHEMA = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  const [isLoading, setLoading] = useState(false);
  const [upgradeModal, toggleUpgradeModal, setUpgradeProps] = useModal((props: any) => <UpgradeModal {...props} />);
  const [upgradeSuccess, setUpgradeSuccess] = useState(false);

  const upgradeTeacherAccountMutation = useMutation(upgradeTeacherAccount, {
    onSuccess: () => {
      setUpgradeSuccess(true);
    },
  });

  if (upgradeSuccess) {
    return <UpgradeConfirmation />;
  }

  return (
    <AuthLayout title="Enter your email address">
      {upgradeModal}
      <Formik
        validationSchema={SCHEMA}
        initialValues={{ email: '' }}
        isInitialValid={false}
        onSubmit={({ email }) => {
          setLoading(true);
          validateUpgradeAccount({ email, token, role })
            .then((response) => {
              const data = response.data.data;
              if (data.exists) {
                if (data.valid) {
                  switch (role) {
                    case ROLE_TEACHER:
                      setUpgradeProps({
                        text: `This will join your account to ${data.school} - any active surveys (and associated survey responses) will be marked as complete.`,
                        handleSubmit: () => upgradeTeacherAccountMutation.mutate({ email, token }),
                        valid: true,
                      });
                      break;
                    case ROLE_SCHOOL_COORDINATOR:
                      /*Added an error message*/
                      setUpgradeProps({
                        text: `This will upgrade your account to GTT Coordinator and join your account to ${data.mat} - any
                        active surveys (and associated responses) will be marked as complete`,
                        handleSubmit: () => {
                          setStep('step2', { ...step2, email, blocked: { email: true } } as Step2Type);
                          skipUpgrade();
                        },
                        valid: true,
                      });
                      break;
                  }

                  toggleUpgradeModal(true);

                  return;
                }

                setUpgradeProps({
                  text: `This email address is already being used and cannot be linked to ${data.school ?? data.mat}`,
                });

                toggleUpgradeModal(true);
                return;
              }

              setStep('step2', { ...step2, email, blocked: { email: true } } as Step2Type);
              skipUpgrade();
            })
            .catch(() => {
              setUpgradeProps({
                text: `We have been unable to create your account at this time. This is either because your
                school or college's registration link has been changed, or all of your licences have been
                used already. Please contact your Coordinator, to ensure that you have the correct link,
                and that your team still has enough licences for you to sign up.`,
              });
              toggleUpgradeModal(true);
            })
            .finally(() => setLoading(false));
        }}
      >
        {({ values, handleChange, handleSubmit, isValid }) => (
          <form className={'py-5'} onSubmit={handleSubmit}>
            <div className={'field-mb'}>
              <Input
                id="email"
                type={'email'}
                label={'Email'}
                placeholder={'Please enter your email'}
                required
                value={values.email}
                onChange={handleChange}
              />
            </div>
            <Button id="submit" type="submit" className={'block w-full mt-5'} disabled={!isValid || isLoading}>
              {!isLoading ? 'Continue' : 'Loading...'}
            </Button>
          </form>
        )}
      </Formik>
    </AuthLayout>
  );
};

const UpgradeConfirmation = () => {
  const navigate = useNavigate();

  return (
    <AuthLayout title="Account upgrade">
      <p>Your account has been successfully upgraded! Click below to login and get started.</p>
      <Button id="submit" className={'block w-full mt-5'} onClick={() => navigate(routeBuilder('login').route)}>
        Go to Login
      </Button>
    </AuthLayout>
  );
};

export default UpgradePage;
