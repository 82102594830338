import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { ESE_USERS } from '../../service/queryKeys';
import {
  getEseUserDetail,
  postRegisterEseUser
} from '../../service/api';
import AuthLayout from '../../layout/auth';
import Input from '../../form/input/input';
import Button from '../../global/button/button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  FIRST_NAME_VALIDATION,
  LAST_NAME_VALIDATION,
  PASSWORD_VALIDATION,
} from '../../resources/schemas';
import ReCAPTCHA from 'react-google-recaptcha';
import Alert from '../../global/alert/alert';
import { debounce } from 'lodash';
import { LinkStyled } from "../../global/link/link.styled";

const SITE = 'ar';

const EseRegistrationArabicSite = () => {

  const [searchValue, setSearchValue] = useState<string>('');

  const teachers = useQuery(
    [
      ESE_USERS,
      searchValue,
    ],
    () =>
      getEseUserDetail(
        searchValue,
      ),
    {
      select: (data) => data.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      placeholderData: [],
    }
  );

  const registrationMutation = useMutation(postRegisterEseUser, {
    onSuccess: () => {
      window.location.href = `/ese-complete-registration?site=${SITE}`;
    },
  });

  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowMessage(searchValue !== '')
    }, 12000);
  }, [searchValue]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        first_name: FIRST_NAME_VALIDATION,
        last_name: LAST_NAME_VALIDATION,
        password: PASSWORD_VALIDATION,
        confirm_password: Yup.string().oneOf([Yup.ref('password')], 'كلمة السر يجب ان تكون متطابقة'),
        school: Yup.string().trim().max(255).required(),
        oracle_erp: Yup.number().required(),

        captcha: Yup.string().required().nullable(),
      })
    ),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
      school: '',
      oracle_erp: '',
      branch: '',
      captcha: null as string | null,
    },
  });

  useEffect(() => {
    if (!teachers?.data?.data) {
      return;
    }
    setValue('first_name', teachers?.data?.data?.first_name || '')
    setValue('last_name', teachers?.data?.data?.last_name || '')
    setValue('school', teachers?.data?.data?.school_name || '')
    setValue('oracle_erp', teachers?.data?.data?.erp || '')
    setValue('branch', teachers?.data?.data?.branch || '')

  }, [teachers]);

  return (
    <AuthLayout site={SITE} title={'التسجيل'} eseMode={true}>
      <form
        className={'py-5'}
        onSubmit={handleSubmit((data) => {
          registrationMutation.mutate({
            body: {
              first_name: data.first_name!,
              last_name: data.last_name!,
              email: data.email,
              password: data.password,
              school: data.school,
              oracle_erp: data.oracle_erp,
              branch: data.branch,
              site: SITE,
              captcha: data.captcha!,
            },
          });
        })}
      >
        <Alert type="info" className={'field-mb'} dir="rtl">
          قم بإنشاء حسابك للوصول إلى الدورات وبدء التعلم مع التعليم القائم على الأدلة. الرجاء الأخذ في الاعتبار بأنه يمكن إنشاء الحساب فقط باستخدام عنوان البريد إلكتروني الخاص بمؤسسة الإمارات للتعليم المدرسي.
        </Alert>

        <div className={'field-mb field-mb grid grid-cols-1 gap-y-2'} dir="rtl">
          <Input
            {...register('email')}
            id="email"
            dir="rtl"
            label={'الإيميل'}
            placeholder={`الرجاء إدخال الإيميل`}
            required
            onChange={(event) => setSearchValue(event.target.value)}
            error={errors.last_name?.message}
          />
          <div className={'p-4'}>
            {searchValue !== '' && showMessage && !teachers?.data?.data  && (
            <Alert type={'info'}>
              للأسف، لم يتم تأكيد بريدك الإلكتروني، نأمل منك استخدام بريدك الإلكتروني الخاص بمؤسسة ألإمارات التعليمية أو التواصل معنا عن طريق هذا  {' '}
              <LinkStyled href={'mailto:ese@evidencebased.education?subject=Subject'} target={'_blank'}>
                الرابط
              </LinkStyled>{' '}
             للمساعدة!
            </Alert>
          )}
            {teachers?.data?.data?.registered && (
              <Alert type={'info'}>
                       يبدو أن هناك حسابًا مسجَّلًا بالفعل بهذا العنوان البريدي الإلكتروني. إذا كان لديك حساب بالفعل، يُرجى تسجيل الدخول  {' '}
                <LinkStyled href={'https://evidence-based-education-arabic.thinkific.com/users/sign_in'} target={'_blank'}>
                  هنا.
                </LinkStyled>{' '}
                 يرجى مراسلة الدعم الفني عبر    {' '}
                <LinkStyled href={'mailto:ese@evidencebased.education?subject=Subject'} target={'_blank'}>
                  البريد الإلكتروني
                </LinkStyled>{' '}
                إذا كنت بحاجة إلى مساعدة.
              </Alert>
            )}
        </div>
        </div>
        <div className={'mb-8'} dir="rtl">
          <Input
            {...register('password')}
            id={'password'}
            type={'password'}
            label={'كلمة السر'}
            placeholder={' الرجاء أدخل كلمة السر الخاصة بك'}
            required
            error={errors.password?.message}
            site={SITE}
          />
        </div>
        <div className={'field-mb'} dir="rtl">
          <Input
            {...register('confirm_password')}
            id="confirm_password"
            type={'password'}
            label="تأكيد كلمة السر"
            placeholder={'الرجاء تاكيد كلمة السر'}
            required
            error={errors.confirm_password?.message}
            site={SITE}
          />
        </div>
        <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
          <div className={'field-mb'} dir="rtl">
            <Input
              {...register('last_name')}
              id="last_name"
              dir="rtl"
              label="اللقب"
              required
            />
          </div>
          <div className={'field-mb'} dir="rtl">
            <Input
              {...register('first_name')}
              id="first_name"
              dir="rtl"
              label="الاسم الاول"
              required
            />
          </div>
        </div>

        <div className={'field-mb'} dir="rtl">
          <Input
            {...register('school')}
            id="school"
            dir="rtl"
            label={'المدرسة'}
            disabled={true}
            required
          />
        </div>
        <div className={'mb-8'} dir="rtl">
          <Input
            {...register('oracle_erp')}
            id={'oracle_erp'}
            dir="rtl"
            label={'الرقم الوظيفي'}
            disabled={true}
            required
          />
        </div>
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
          onChange={(token) => setValue('captcha', token)}
          onErrored={() => setValue('captcha', null)}
          onExpired={() => setValue('captcha', null)}
        />
        <Button
          className={'block w-full mt-5'}
          type={'submit'}
          disabled={ !teachers?.data?.data || teachers?.data?.data?.registered || postRegisterEseUser.isLoading}
        >
          سجل
        </Button>
      </form>
    </AuthLayout>
  );
};

export default EseRegistrationArabicSite;
