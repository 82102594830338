import React from 'react';
import Datepicker from '../datepicker';
import { ReactDatePickerProps } from 'react-datepicker';

type DateRangePickerProps = {
  /**
   * Start date.
   */
  start: Date | null;
  /**
   * End date.
   */
  end: Date | null;
  /**
   * Handles start date change.
   * @param date
   */
  onStartChange(date: Date): void;
  /**
   * Handles end date change.
   * @param date
   */
  onEndChange(date: Date): void;
  columnMode?: boolean;
};

const DateRangePicker = ({
  start = null,
  end = null,
  onStartChange,
  onEndChange,
  columnMode = false,
  ...other
}: DateRangePickerProps & Omit<ReactDatePickerProps, 'onChange'>) => (
  <div className={` ${columnMode ? 'grid grid-cols-1' : 'md:grid grid-flow-col  grid-cols-2 grid-rows-1 gap-2'}`}>
    {/* Start date */}
    <div className={`${columnMode ? 'mb-2' : 'field-mb'}`}>
      <Datepicker
        id={'start_date'}
        label={'Start date'}
        placeholder={'Please select a start date'}
        selected={start}
        value={start?.toLocaleDateString()}
        onChange={onStartChange}
        // Range
        selectsStart
        startDate={start}
        endDate={end}
        {...other}
      />
    </div>
    {/* End date */}
    <div className={`${columnMode ? 'mb-2' : 'field-mb'}`}>
      <Datepicker
        id={'end_date'}
        label={'End date'}
        placeholder={'Please select an end date'}
        selected={end}
        value={end?.toLocaleDateString()}
        onChange={onEndChange}
        // Range
        selectsEnd
        startDate={start}
        endDate={end}
        minDate={start}
        {...other}
      />
    </div>
  </div>
);

export default DateRangePicker;
