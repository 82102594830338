import React from 'react';
import Tippy from '@tippyjs/react';
import { TooltipProps } from './tooltip.types';

const Tooltip: React.FC<TooltipProps> = ({ content, children, placement, ...other }: TooltipProps) => {
  return (
    <Tippy
      content={<div>{content}</div>}
      theme={'translucent'}
      interactive
      placement={placement ? placement : 'top'}
      interactiveBorder={10}
      animation={'shift-away'}
      allowHTML
      appendTo={() => document.body}
      {...(other as TooltipProps)}
    >
      {children as any}
    </Tippy>
  );
};

Tooltip.displayName = 'Tooltip';

export default Tooltip;
