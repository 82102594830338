import React from 'react';
import ReactDom from 'react-dom/server';
import { ClipPath, Defs, G, Path, Rect, Svg, Text, Tspan, Line, Circle, Polygon } from '@react-pdf/renderer';
import { DomElement } from 'htmlparser2';

const formatStringToCamelCase = (str: string) => {
  const splitted = str.split('-');
  if (splitted.length === 1) return splitted[0];
  return (
    splitted[0] +
    splitted
      .slice(1)
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join('')
  );
};

export const getStyleObjectFromString = (str: string | null) => {
  const style: any = {};
  if (!str) return {};

  str.split(';').forEach((el) => {
    const [property, value] = el.split(':');
    if (!property) return;
    const formattedProperty = formatStringToCamelCase(property.trim());
    style[formattedProperty] = value.trim();
  });

  return style;
};

function parseIntAttributes(attr: string | null) {
  if (!attr) return null;
  if (attr.includes('px')) return attr;

  return Number(attr);
}

function convertToPdfSvg(node: DomElement, index: number) {
  let Component: React.ElementType;

  const componentProps: Record<string, any> = {};

  for (const name of node.getAttributeNames()) {
    if (name === 'style') {
      componentProps.style = {
        ...componentProps.style,
        ...getStyleObjectFromString(node.getAttribute(name))
      };
    } else if (name.includes('-')) {
      if (name !== 'font-size') {
        componentProps[formatStringToCamelCase(name)] = node.getAttribute(name);
      }
    } else if (name === 'dy') {
      const parent = node.parentElement;
      const parentY = parseFloat(parent.getAttribute('y'));

      let nodeY = 0;
      let i = 0;
      for (const child of Array.from(parent.children)) {
        nodeY += parseFloat((child as DomElement).getAttribute(name).replace('em', ''));

        if (i >= index) {
          break;
        }

        i++;
      }

      componentProps.y = 16 * nodeY + parentY;
    } else if (name === 'transform') {
      const transformValues = node.getAttribute(name).substring(
        node.getAttribute(name).indexOf("(") + 1,
        node.getAttribute(name).lastIndexOf(")")
      ).split(', ');

      componentProps.style = {
        ...componentProps.style,
        transform: `rotate(${transformValues[0]})`,
        transformOriginX: `${transformValues[1]}`,
        transformOriginY: `${transformValues[2]}`,
      };
    } else {
      componentProps[name] = node.getAttribute(name);
    }
  }

  switch (node.tagName?.toUpperCase()) {
    case 'SVG':
      Component = Svg;

      break;
    case 'RECT':
      Component = Rect;

      break;
    case 'LINE':
      Component = Line;

      break;
    case 'CIRCLE':
      Component = Circle;

      break;
    case 'CLIPPATH':
      Component = ClipPath;

      break;
    case 'DEFS':
      Component = Defs;

      break;
    case 'G':
      Component = G;

      break;
    case 'TEXT':
      Component = Text;

      break;
    case 'PATH':
      Component = Path;

      break;
    case 'TSPAN':
      Component = Tspan;

      break;
    case 'POLYGON':
      Component = Polygon;

      break;
    default:
      throw new Error(`Unsupported type ${node.tagName}`);
  }

  if (null != node.children && 0 < node.children.length) {
    return (
      <Component {...componentProps} key={Math.random()}>
        {Array.from(node.children).map((node, index) => convertToPdfSvg(node, index))}
      </Component>
    );
  }
  return (
    <Component {...componentProps} key={Math.random()}>
      {node.innerHTML}
    </Component>
  );
}

const PDFChart = ({ children }: any) => {
  const svgString = ReactDom.renderToStaticMarkup(children);

  const doc = new DOMParser().parseFromString(svgString, 'text/xml');

  const x = convertToPdfSvg(doc.firstChild!.firstChild, 0);

  return x;
};

export default PDFChart;
