import React, { useState } from 'react';
import { SliceText } from '../../../global/tooltip/common';
import { LinkStyled } from '../../../global/link/link.styled';
import Accordion from '../../../global/accordion/accordion';
import { SectionInAccordion } from '../../pathway/components';
import AddButton from '../components/addButton';
import { StrategyProps } from '../pathwaysPage.types';
import useModal from '../../../hooks/useModal';
import PathwaysStrategyModal from '../modals/PathwaysStrategyModal';
import { useMutation, useQueryClient } from 'react-query';
import { PATHWAYS_V2_STEP, TEACHER_STRATEGIES } from '../../../service/queryKeys';
import { handleV2PathwaysStrategy, postStrategyGroup } from '../../../service/api';
import Tag from '../../../global/tag/tag';
import Button from '../../../global/button/button';
import classNames from 'classnames';
import { INTEGRATE_STEP, PREPARE_STEP } from '../views/PathwayV2View';
import { toast } from 'react-toastify';

const V2PathwayStrategies = ({
  disabled,
  strategies,
  team,
  description,
}: {
  disabled: boolean;
  strategies?: Array<StrategyProps>;
  team?: { id: string; name: string };
  description?: string;
}) => {
  const [accordionOpen, setAccordionOpen] = useState<string | null>();

  const queryClient = useQueryClient();
  const strategyMutation = useMutation(handleV2PathwaysStrategy, {
    onSuccess: () => {
      queryClient.invalidateQueries([PATHWAYS_V2_STEP, INTEGRATE_STEP]);
      queryClient.invalidateQueries([PATHWAYS_V2_STEP, PREPARE_STEP]);
      toggleStrategyModal(false);
    },
  });

  const shareStrategyMutation = useMutation(postStrategyGroup, {
    onSuccess: () => {
      queryClient.invalidateQueries(TEACHER_STRATEGIES);
      queryClient.invalidateQueries([PATHWAYS_V2_STEP, PREPARE_STEP]);
    },
  });

  const [strategyModal, toggleStrategyModal, setStrategyProps] = useModal(
    (props: any) => <PathwaysStrategyModal {...props} mutation={strategyMutation} team={team} />,
    {
      size: 'lg',
    }
  );

  return (
    <>
      <div className={`flex ${classNames({ 'mb-1': null != strategies && strategies.length > 0 })}`}>
        <span className={'font-bold pr-5 py-1'}>Development strategies</span>
        <AddButton
          disabled={disabled}
          onClick={() => {
            setStrategyProps({
              title: 'Add Strategy',
              preventCloseOnClickOnMask: true,
            });
            toggleStrategyModal(true);
          }}
        />
      </div>
      <i className="text-sm">{description}</i>
      {strategyModal}
      {(strategies ?? []).map((strategy, index) => (
        <div key={`strategy--${strategy.id}`} className={'pathways-section-content'}>
          <Accordion
            isOpen={accordionOpen === strategy.id}
            handleOpen={() => {
              setAccordionOpen(accordionOpen === strategy.id ? null : strategy.id);
            }}
            title={
              <div className={'flex items-center justify-between w-full'}>
                <div className={'flex items-center'}>
                  <span className={'text-muted'}>Strategy {index + 1} —</span> <b>{strategy.name}</b>
                  {strategy.shared_with !== null && (
                    <div className={'ml-10'}>
                      <Tag style={{ height: 24 }} mainColor={'muted'} content={'Shared'} />
                    </div>
                  )}
                </div>
                <div className={'flex items-center gap-3'}>
                  {(strategy.shared_with == null || strategy.shared_with.length === 0) && (
                    <Button
                      disabled={disabled}
                      asLink
                      style={{ padding: 0 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        shareStrategyMutation.mutateAsync({ groupId: team!.id, strategyId: strategy.id! }).then(() => {
                          toast.success('This strategy is now available to your team members');
                        });
                      }}
                    >
                      Share with team
                    </Button>
                  )}
                  <Button
                    disabled={disabled}
                    asLink
                    style={{ padding: 0 }}
                    onClick={(e) => {
                      e.stopPropagation();

                      setStrategyProps({
                        title: 'Update strategy',
                        strategy,
                        isShared: strategy.shared_with != null,
                        preventCloseOnClickOnMask: true,
                      });

                      toggleStrategyModal(true);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            }
          >
            <div className={'md:grid grid-flow-col grid-cols-3 gap-4'}>
              {/* Description */}
              <SectionInAccordion title={'Description'}>
                <SliceText value={strategy.description} />
              </SectionInAccordion>
              {/* Active ingredients (steps) */}
              <SectionInAccordion title={'Working steps'}>
                <ul className={'list-decimal list-inside'}>
                  {strategy.steps.map((step: any, index: number) => (
                    <li key={`ingredient_list--${index}`} className={'my-2'}>
                      {step}
                    </li>
                  ))}
                </ul>
              </SectionInAccordion>
              {/* Examples URLs - Additional information*/}
              <div>
                <SectionInAccordion className={null} title={'Resources'}>
                  <div className={'sm-field-mb'}>
                    {strategy.urls.filter((url) => url.link).length !== 0 ? (
                      strategy.urls
                        .filter((url) => url.link)
                        .map((url, index) => {
                          return (
                            <LinkStyled key={`url_list--${index}`} href={url.link} target={'_blank'}>
                              {url.name || url.link}
                            </LinkStyled>
                          );
                        })
                        .reduce((prev, curr) => [prev, ', ', curr] as any)
                    ) : (
                      <i className={'text-muted'}>No resources</i>
                    )}
                  </div>
                </SectionInAccordion>
                <SectionInAccordion className={null} title={'Additional information'}>
                  {strategy.additional_info ? (
                    <SliceText value={strategy.additional_info} />
                  ) : (
                    <i className={'text-muted'}>No additional information</i>
                  )}
                </SectionInAccordion>
              </div>
            </div>
          </Accordion>
        </div>
      ))}
    </>
  );
};

export default V2PathwayStrategies;
