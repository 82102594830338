import React, { useState } from 'react';
import Button from '../../../global/button/button';
import AddNotes from '../components/addNotes';
import AddResources from '../components/addResources';
import GenerateFeedback from '../components/generateFeedback';
import AddChallenges from '../components/addChallenges';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PATHWAYS_V2_STEP } from '../../../service/queryKeys';
import { FOCUS_STEP } from '../views/PathwayV2View';
import { getV2PathwaysStep } from '../../../service/api';
import dayjs from 'dayjs';
import AddReference from '../components/AddReference';
import { STUDENT_SURVEY_REFERENCE_TYPE, VIDEO_OBSERVATION_REFERENCE_TYPE } from '../modals/AddReferenceModal';

const PathwaysFocusTab = ({
  disabled,
  startDisabled,
  completed,
  started,
  team,
  saveStep,
}: {
  disabled: boolean;
  startDisabled: boolean;
  completed: boolean;
  started: boolean;
  team?: { id: string; name: string };
  saveStep: (data?: { completed_at?: string; challenge?: string; notes?: string }, callback?: () => void) => void;
}) => {
  const { id: pathwaysId } = useParams();

  const { data: pathwaysStep } = useQuery(
    [PATHWAYS_V2_STEP, FOCUS_STEP, pathwaysId],
    () => getV2PathwaysStep(FOCUS_STEP, pathwaysId!),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
    }
  );

  const [editMode, setEditMode] = useState(!completed);

  disabled = disabled || !editMode;

  return (
    <div>
      <div className={'flex flex-col divide-y'}>
        <div className={'font-bold flex pathways-section gap-2 justify-between'}>
          <span className={'w-5/6'}>
            In this step, based on your growing understanding of your chosen element, you can focus in on a specific
            aspect of your teaching that you want to improve. To inform your decision, you might collect more specific
            feedback (e.g., using an element survey). When you’re ready, enter your development focus and complete this
            step.
          </span>
          <Button
            id={`focus_${completed ? 'edit' : 'start'}_step`}
            size={'md'}
            onClick={() => {
              if (completed) {
                setEditMode(true);
                return;
              }

              saveStep();
            }}
            disabled={startDisabled || (started ? editMode : !editMode)}
          >
            {completed ? 'Edit' : 'Start'} step
          </Button>
        </div>
        <div className={'pathways-section'}>
          <GenerateFeedback disabled={disabled} />
        </div>
        <div className={'pathways-section'}>
          <AddReference
            disabled={disabled}
            step={FOCUS_STEP}
            options={[STUDENT_SURVEY_REFERENCE_TYPE, VIDEO_OBSERVATION_REFERENCE_TYPE]}
            references={pathwaysStep?.references}
          />
        </div>
        <div className={'pathways-section'}>
          <AddNotes
            disabled={disabled}
            notes={pathwaysStep?.notes}
            save={(notes, callback) => {
              saveStep({ notes }, callback);
            }}
            description={
              'What specific areas of your practice might you focus on? What does your teaching practice look like in this element?'
            }
          />
        </div>
        <div className={'pathways-section'}>
          <AddResources
            disabled={disabled}
            step={FOCUS_STEP}
            resources={pathwaysStep?.resources}
            team={team}
            description={'Add relevant resources (e.g. articles, blog posts, podcasts).'}
          />
        </div>
        <div className={'pathways-section'}>
          <AddChallenges
            disabled={disabled}
            notes={pathwaysStep?.challenge}
            save={(challenge, callback) => {
              saveStep({ challenge }, callback);
            }}
          />
        </div>
      </div>
      <div className={'pathways-section w-full text-right'}>
        <Button
          id={'focusCompleteButton'}
          size={'sm'}
          onClick={() =>
            saveStep({ completed_at: dayjs().format('YYYY/MM/DD') }, () => {
              setEditMode(false);
            })
          }
          disabled={disabled}
        >
          Complete
        </Button>
      </div>
    </div>
  );
};

export default PathwaysFocusTab;
