import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../form/input/input';
import React, { useState } from 'react';
import { AddWebinarProps } from '../webinarPage.types';
import Datepicker from '../../../form/datepicker/datepicker';
import { useMutation, useQueryClient } from 'react-query';
import { postWebinar, putWebinar } from '../../../service/api';
import { WEBINARS } from '../../../service/queryKeys';
import { toast } from 'react-toastify';
import Selector from '../../../form/select/selector';
import { ALL_ROLES } from '../../../resources/roles';
import { SelectOptionType } from '../../../service/types';
import { Checkbox } from 'pretty-checkbox-react';
import dayjs from 'dayjs';

const AddWebinarModal = ({ toggle, webinar, update }: ModalToggleProps & AddWebinarProps) => {
  const queryClient = useQueryClient();

  const addWebinar = useMutation(postWebinar, {
    onSuccess: () => {
      queryClient.invalidateQueries(WEBINARS);
      toggle(false);
    },
  });

  const updateWebinar = useMutation(putWebinar, {
    onSuccess: () => {
      queryClient.invalidateQueries(WEBINARS);
      toggle(false);
    },
  });

  const [dateString, setDateString] = useState<string | null>(
    webinar?.started_at ? dayjs(new Date(webinar?.started_at)).format('YYYY-MM-DD') : null
  );
  const [timeString, setTimeString] = useState<string | null>(
    webinar?.started_at ? dayjs(new Date(webinar?.started_at)).format('HH:mm') : null
  );

  return (
    <Modal>
      <Formik
        initialValues={{
          id: '',
          title: '',
          url: '',
          webinar_id: '',
          passcode: '',
          started_at: null,
          description: '',
          roles: webinar?.roles ?? [],
          ...webinar,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required().max(100),
          url: Yup.string().required().max(100),
          webinar_id: Yup.string().required().min(5),
          passcode: Yup.string().required().max(20),
          started_at: Yup.date().required(),
          description: Yup.string().required().max(1000),
          roles: Yup.array().required().min(1),
        })}
        isInitialValid={false}
        onSubmit={(values) => {
          if (update) {
            updateWebinar.mutateAsync(values).then(() => {
              toast.success('Updated zoom');
              return;
            });
          } else {
            addWebinar.mutateAsync(values).then(() => {
              toast.success('Added zoom');
              return;
            });
          }
        }}
      >
        {({ values, handleChange, handleSubmit, isValid, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="mb-8">
                <div className={'field-mb'}>
                  <Input
                    label={'Title'}
                    id={'title'}
                    type={'text'}
                    placeholder={values.title && values.title != '' ? values.title : 'Add zoom title'}
                    required
                    value={values.title}
                    onChange={handleChange}
                  />
                </div>
                <div className={'field-mb'}>
                  <Input
                    label={'Link'}
                    id={'url'}
                    type={'text'}
                    placeholder={values.url && values.url != '' ? values.url : 'Add zoom link'}
                    required
                    value={values.url}
                    onChange={handleChange}
                  />
                </div>
                <div className={'field-mb'}>
                  <Input
                    label={'Webinar ID'}
                    id={'webinar_id'}
                    type={'text'}
                    placeholder={
                      values.webinar_id && values.webinar_id != '' ? values.webinar_id : 'Add zoom webinar id'
                    }
                    required
                    value={values.webinar_id.replaceAll(' ', '')}
                    onChange={handleChange}
                  />
                </div>
                <div className={'field-mb'}>
                  <Input
                    label={'Passcode'}
                    id={'passcode'}
                    type={'text'}
                    placeholder={
                      values.passcode && values.passcode != '' ? values.passcode : 'Add zoom webinar passcode'
                    }
                    required
                    value={values.passcode}
                    onChange={handleChange}
                  />
                </div>
                <div className={'field-mb'}>
                  <Datepicker
                    selected={dateString ? new Date(dateString) : new Date()}
                    placeholder={'Add date'}
                    label={'Date'}
                    id={'date'}
                    value={dateString ?? ''}
                    required
                    dateFormat={'YYYY-MM-DD'}
                    onChange={(date: Date) => {
                      const tempDate = dayjs(new Date(date)).format('YYYY-MM-DD');
                      setDateString(tempDate);
                      timeString && setFieldValue('started_at', new Date(tempDate + ' ' + timeString));
                    }}
                  ></Datepicker>
                </div>
                <div className={'field-mb'}>
                  <Datepicker
                    selected={values.started_at ? new Date(values.started_at) : new Date()}
                    placeholder={'Add time'}
                    required
                    label={'Time'}
                    id={'time'}
                    value={timeString ?? ''}
                    onChange={(date: Date) => {
                      const tempTime = dayjs(new Date(date)).format('HH:mm');
                      setTimeString(tempTime);
                      dateString && setFieldValue('started_at', new Date(dateString + ' ' + tempTime));
                    }}
                    showTimeSelect
                    showTimeSelectOnly
                    timeFormat="HH:mm"
                  />
                </div>
                <div className={'field-mb'}>
                  <Input
                    label={'Description'}
                    id={'description'}
                    type={'textarea'}
                    placeholder={values.description ?? 'Add zoom description'}
                    required
                    value={values.description}
                    onChange={handleChange}
                  />
                </div>
                <div className={'field-mb'}>
                  <Selector
                    required
                    className={'mb-2'}
                    isMulti
                    id={`roles`}
                    label={`Assign user roles`}
                    placeholder={`Please assign user roles`}
                    value={
                      values.roles.length < 1
                        ? []
                        : values.roles.map((role: any) => {
                            const userRoles = ALL_ROLES.find(
                              (userRole: { value: string; label: string }) => userRole.value === role
                            );
                            return {
                              label: userRoles?.label,
                              value: userRoles?.value,
                            };
                          })
                    }
                    options={ALL_ROLES}
                    onChange={(options: any) =>
                      setFieldValue(
                        `roles`,
                        options == null ? [] : options.map((option: SelectOptionType) => option.value)
                      )
                    }
                    isClearable={true}
                  />
                  <div className={'field-mb'}>
                    <Checkbox
                      color={'primary'}
                      id={'cohort'}
                      value={values.roles}
                      checked={values.roles.length === ALL_ROLES.length}
                      onChange={() =>
                        values.roles.length === ALL_ROLES.length
                          ? setFieldValue('roles', [])
                          : setFieldValue(
                              'roles',
                              ALL_ROLES.map((role) => role.value)
                            )
                      }
                    >
                      <strong>Assign all roles</strong>
                    </Checkbox>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                id={'addWebinar'}
                type="submit"
                disabled={!isValid}
                label={update ? 'Update' : 'Add'}
                onClick={() => setFieldValue('webinar_id', values.webinar_id.replaceAll(/\s/g, ''))}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddWebinarModal;
