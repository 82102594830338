import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled, { ThemeProps, DefaultTheme } from 'styled-components';
import { DEFAULT_THEME } from '../../Theme';
import { SelectProps } from '../common/field.types';
import { getBaseSelectorStyle } from '../../helpers/styles';
import Async from 'react-select/async';
import AsyncCreatable from 'react-select/async-creatable';

export const SelectStyled = styled(Select).attrs(() => ({}))<SelectProps & ThemeProps<DefaultTheme>>`
  ${(props) => getBaseSelectorStyle(props)}
`;

SelectStyled.defaultProps = {
  theme: DEFAULT_THEME,
};

export const CreatableSelectStyled = styled(CreatableSelect).attrs(() => ({}))<SelectProps & ThemeProps<DefaultTheme>>`
  ${(props) => getBaseSelectorStyle(props)}
`;

CreatableSelectStyled.defaultProps = {
  theme: DEFAULT_THEME,
};

export const AsyncSelectStyled = styled(Async).attrs(() => ({}))<SelectProps & ThemeProps<DefaultTheme>>`
  ${(props) => getBaseSelectorStyle(props)}
`;

AsyncSelectStyled.defaultProps = {
  theme: DEFAULT_THEME,
};

export const AsyncCreatableSelectStyled = styled(AsyncCreatable).attrs(() => ({}))<
  SelectProps & ThemeProps<DefaultTheme>
>`
  ${(props) => getBaseSelectorStyle(props)}
`;

AsyncCreatableSelectStyled.defaultProps = {
  theme: DEFAULT_THEME,
};
