import React, { useEffect, useState } from 'react';
import { useRegistrationContext } from '../../../state/registration/provider';
import Step1 from './Step1';
import Step2 from './Step2';
import Step4 from './Step4';
import {
  ROLE_SCHOOL_COORDINATOR,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
  transformRoleParamToApi,
} from '../../../resources/roles';
import AuthLayout from '../../../layout/auth';
import Stepper from '../../../global/stepper/stepper';
import Step3 from './Step3';
import Step5 from './Step5';
import UpgradePage from './UpgradePage';
import { UserType } from '../../users/userManagementPage';
import { ROLE_OPTIONS, ROLE_OPTIONS_OTHER } from './sections/step4/TeacherForm';
import { Me } from '../../../@types/Entity/Me';

const token = new URLSearchParams(location.search).get('t');
const role = new URLSearchParams(location.search).get('r');
const upgrade = new URLSearchParams(location.search).get('u');

const STEPS = [
  {
    index: 1,
    title: (
      <>
        Select
        <br />
        role
      </>
    ),
  },
  {
    index: 2,
    title: (
      <>
        Account
        <br />
        details
      </>
    ),
  },
  {
    index: 3,
    title: (
      <>
        Subscription
        <br />
        details
      </>
    ),
  },
  {
    index: 4,
    title: (
      <>
        Additional
        <br />
        info
      </>
    ),
  },
];

export const ROLES_WITHOUT_ADDITIONAL_INFO: Array<string | null | undefined> = [ROLE_SCHOOL_COORDINATOR];

const RegistrationPage = ({ me }: { me?: Me }) => {
  const { setStep, step1, currentPage, setInitialPage, setState } = useRegistrationContext();

  useEffect(() => {
    setStep('step1', { token: token, role: transformRoleParamToApi(role) });

    if (transformRoleParamToApi(role)) {
      if (upgrade && me) {
        setState({
          step2: {
            first_name: me.first_name,
            last_name: me.last_name,
            organisation: me.organisation,
            email: me.email,
          },
          step4: {
            role_description: me.role_description,
            role_type: ROLE_OPTIONS.some(
              (option) => option.value !== ROLE_OPTIONS_OTHER && option.value === me.role_type
            )
              ? me.role_type
              : ROLE_OPTIONS_OTHER,
            role_other_type: ROLE_OPTIONS.some(
              (option) => option.value !== ROLE_OPTIONS_OTHER && option.value === me.role_type
            )
              ? ''
              : me.role_type,
            experience: me.experience,
            mentoring: me.mentoring,
            subjects: me.subjects,
            year: me.year,
            qualifications: me.qualifications,
          },
          initialPage: 3,
          currentPage: 3,
        });
        return;
      }

      setInitialPage(2);
    }
  }, []);

  const [upgradeSkipped, skipUpgrade] = useState(false);

  if (
    !upgradeSkipped &&
    token &&
    (ROLE_TEACHER === transformRoleParamToApi(role) || ROLE_SCHOOL_COORDINATOR === transformRoleParamToApi(role))
  ) {
    return <UpgradePage token={token} role={transformRoleParamToApi(role)!} skipUpgrade={() => skipUpgrade(true)} />;
  }

  return (
    <AuthLayout
      title={
        step1?.role === ROLE_TEACHER_FREE
          ? 'Create your free GTT Starter Account'
          : step1?.role === ROLE_SCHOOL_COORDINATOR
          ? 'Register your school/college'
          : step1?.role === ROLE_TEACHER && !token
          ? 'Individual Great Teaching Toolkit Account'
          : 'Register'
      }
    >
      <div>
        <Stepper
          steps={STEPS.filter((step) => {
            switch (step.index) {
              case 1:
                return !role;
              case 3:
                return !token && step1?.role !== ROLE_TEACHER_FREE;
              case 4:
                return !ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role);
              default:
                return true;
            }
          })}
          activeStep={currentPage}
        />
      </div>
      {currentPage === 1 ? (
        <Step1 />
      ) : currentPage === 2 ? (
        <Step2 />
      ) : currentPage === 3 ? (
        <Step3 />
      ) : currentPage === 4 ? (
        <Step4 />
      ) : (
        <Step5 />
      )}
    </AuthLayout>
  );
};

export default RegistrationPage;
