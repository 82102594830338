import React from 'react';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { removeV3PathwayLog } from '../../../../service/api';
import { PATHWAYS_V3 } from '../../../../service/queryKeys';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { PathwayLog } from '../../../../@types/Entity/PathwayLog';
import { toast } from 'react-toastify';

const DeleteV3PathwayLogModal = ({
  toggle,
  log,
  pathway,
}: ModalToggleProps & { log: PathwayLog; pathway: V3Pathway }) => {
  const queryClient = useQueryClient();

  const deleteV3PathwayLogMutation = useMutation(removeV3PathwayLog, {
    onSuccess: () => {
      toast.success('Timeline event removed');
      queryClient.invalidateQueries([PATHWAYS_V3, pathway.id]);
      toggle(false);
    },
  });

  return (
    <Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          deleteV3PathwayLogMutation.mutate({ pathwayId: pathway.id, logId: log.id });
        }}
      >
        <Modal.Body>
          Are you sure you would like to delete this timeline element? This action cannot be undone
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton
            label={deleteV3PathwayLogMutation.isLoading ? 'Loading...' : 'Delete'}
            type={'submit'}
            disabled={deleteV3PathwayLogMutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteV3PathwayLogModal;
