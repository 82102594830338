import React from 'react';
import { useQuery } from 'react-query';
import { getMe } from '../../service/api';
import DashboardLayout from '../../layout/dashboard';
import Tag from '../../global/tag/tag';
import { SchoolDetailsForm } from './sections';
import { ME } from '../../service/queryKeys';

const EditSchoolPage = () => {
  const me = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
    placeholderData: {},
  });

  return (
    <DashboardLayout
      title={'Edit school details'}
      subtitle={<Tag className={'mt-3'} mainColor={'info'} content={`${me.data.organisation}`} />}
    >
      <SchoolDetailsForm
        school={{
          fsm: me.data.fsm,
          sen: me.data.sen,
          ehcp: me.data.ehcp,
          eal: me.data.eal,
          category_2: me.data.category_2,
        }}
      />
    </DashboardLayout>
  );
};

export default EditSchoolPage;
