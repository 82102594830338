import Axios from 'axios';
import { isLoggedIn, logout } from './auth';

const connector = Axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

connector.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    const impersonatedUser = localStorage.getItem('switch-user');

    if (token) {
      config.headers!['Authorization'] = 'Bearer ' + token;
    }

    if (impersonatedUser) {
      config.headers!['X-Switch-User'] = impersonatedUser;
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

connector.interceptors.response.use(
  (response) => response,
  (error) => {
    if (isLoggedIn() && 401 === error.response?.status && '/auth/token' !== error.config.url) {
      logout();
    }

    return Promise.reject(error);
  }
);

export default connector;
