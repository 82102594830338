export type SectionType = 'header' | 'stepper' | 'log' | 'periods' | 'admin_goals' | 'goals' | 'cycles';
export type StepsType =
  | 'baseline_measure'
  | 'decide_focus'
  | 'element_measure'
  | 'professional_learning'
  | 'development_goal';
export type CycleTaskType = 'plan' | 'implement' | 'evaluate';

export interface IngredientProps {
  /**
   * Ingredient step value / position.
   */
  value: number;
  /**
   * Ingredient step label / name.
   */
  label: string;
}

export interface URLProps {
  /**
   * URL path.
   */
  link: string;
  /**
   * URL short name.
   */
  name: string;
}

export interface CycleTaskProps {
  /**
   * Task id.
   */
  id: number | string;
  /**
   * Task date.
   */
  date: Date | null;
  /**
   * Task name.
   */
  name: string;
  /**
   * Task type.
   */
  type: CycleTaskType | undefined;
  /**
   * If task is completed.
   */
  completed: boolean;
}

export interface PeriodProps {
  id?: string;
  /**
   * Development period name.
   */
  name: string;
  /**
   * Development period start date.
   */
  start_date: Date | null;
  /**
   * Development period end date.
   */
  end_date: Date | null;
}

export interface AcademicYearProps {
  id?: string;
  name: string;
  start_date: Date | null;
  end_date: Date | null;
}

export interface AdminGoalProps {
  id: string;
  /**
   * Goal name.
   */
  name: string;
  /**
   * If goal is enabled.
   */
  enabled?: boolean;
  /**
   * Strategies assigned to goal.
   */
  strategies?: StrategyProps[];

  development_period?: any;
}

export interface StrategyProps {
  id?: string;
  name: string;
  description: string;
  steps: string[];
  urls: URLProps[];
  additional_info: string | null;
  enabled?: boolean;
  shared_with?: Array<{ id: string; name: string }>;
}

export interface CycleProps {
  /**
   * Cycle id.
   */
  id: string | number;
  /**
   * Cycle's start date.
   */
  start_date: string;
  /**
   * Cycle's end date.
   */
  end_date: string;
  /**
   * Deliberate practice?
   */
  practice: boolean;
  /**
   * Cycle notes.
   */
  notes?: string | null;
  /**
   * List of strategies to focus on.
   */
  strategies: StrategyProps[];

  school_classes: {
    id: string;
    name: string;
  }[];

  /**
   * List of tasks.
   */
  tasks: CycleTaskProps[];
}

export interface PlanProps {
  /**
   * Plan id.
   */
  id: string /* | number*/;
  /**
   * Plan name.
   */
  name: string;
  /**
   * Plan description.
   */
  description: string;
  /**
   * Plan timeframes.
   */
  developmentPeriods: {
    id: string;
    name: string;
  }[];
  /**
   * Plan teams.
   */
  teams: any[];
  showToAll: boolean;
}

export interface PathwayTeamProps {
  /**
   * Section id.
   */
  id?: SectionType | undefined;
  /**
   * Teams
   */
  teams: any[];
}

export interface PathwaysSectionProps {
  /**
   * Section id.
   */
  id?: SectionType | undefined;
}

export interface PathwaysHeaderProps extends PathwaysSectionProps {
  /**
   * Defines if the process is completed or not.
   */
  isCompleted: boolean;
  /**
   * Element to focus.
   */
  element: string | null;

  name: string;

  steps?: string;
}

export interface PathwaysStepperProps extends PathwaysSectionProps {
  /**
   * Sets the active step.
   */
  steps: Record<StepsType | 'completed_steps', boolean | number>;
  periodId: string;
  element?: {
    title: string;
    value: string;
  };
  mutation: any;
  disabled?: boolean;
  goal: any;
}

export interface PathwaysSysAdminGoalsProps {
  /**
   * Goals data.
   */
  data: AdminGoalProps[];
}

export interface PathwaysGoalsProps {
  /**
   * Goals data.
   */
  data: any[];
  /**
   * Defines if the view is for school admins.
   */
  isAdmin?: boolean;
  /**
   * Defines if the item can be disabled.
   */
  canDisable?: boolean;

  toggle?: any;

  developmentPeriodId?: string;
  showDisabled?: boolean;
  handleShowDisabled?: () => void;
  disabled?: boolean;
  currentGoal?: string;
  role?: string;
}

export interface PathwaysPlansProps {
  /**
   * Goals data.
   */
  data?: any[];
  role?: string;
  isAdmin?: boolean;
  canDisable?: boolean;
  toggle?: any;
  mutation?: any;
  developmentPeriodId?: string;
  timeframes?: any[];
}

export interface PathwaysPlanProps {
  /**
   * Plans data.
   */
  data: any[];
  /**
   * Defines if the view is for school admins.
   */
  isAdmin?: boolean;
  /**
   * Defines if the item can be disabled.
   */
  canDisable?: boolean;

  toggle?: any;

  mutation?: any;

  developmentPeriodId?: string;
  showDisabled?: boolean;
  handleShowDisabled?: () => void;
  disabled?: boolean;
  currentGoal?: string;
  role?: string;
}

export interface ResourceProps {
  id: number;
  name: string;
  url: string;
  type: 'website' | 'file';
  shared: boolean;
}

export const BASELINE_MEASURE_VALUE = 'baseline_measure';
export const DECIDE_FOCUS_VALUE = 'decide_focus';
export const ELEMENT_MEASURE_VALUE = 'element_measure';
export const PROFESSIONAL_LEARNING_VALUE = 'professional_learning';
export const DEVELOPMENT_GOAL_VALUE = 'development_goal';

export const STEP_TITLES = {
  [BASELINE_MEASURE_VALUE]: 'GTT Profile',
  [DECIDE_FOCUS_VALUE]: 'Identify focus',
  [ELEMENT_MEASURE_VALUE]: 'Element feedback',
  [PROFESSIONAL_LEARNING_VALUE]: 'Courses',
  [DEVELOPMENT_GOAL_VALUE]: 'Development Goal',
};

export const STEPS: { value: StepsType; title: string; description: string }[] = [
  {
    value: BASELINE_MEASURE_VALUE,
    title: STEP_TITLES[BASELINE_MEASURE_VALUE],
    description: 'Create/update your profile.',
  },
  {
    value: DECIDE_FOCUS_VALUE,
    title: STEP_TITLES[DECIDE_FOCUS_VALUE],
    description: 'Select an element of Great Teaching.',
  },
  {
    value: ELEMENT_MEASURE_VALUE,
    title: STEP_TITLES[ELEMENT_MEASURE_VALUE],
    description: 'Gain further insights into your chosen element.',
  },
  {
    value: PROFESSIONAL_LEARNING_VALUE,
    title: STEP_TITLES[PROFESSIONAL_LEARNING_VALUE],
    description: 'Explore your chosen element and prepare for cycles of improvement.',
  },
  {
    value: DEVELOPMENT_GOAL_VALUE,
    title: STEP_TITLES[DEVELOPMENT_GOAL_VALUE],
    description: 'Your specific aim in this development period.',
  },
];

export const WEBSITE_RESOURCE_TYPE = 'website';
export const FILE_RESOURCE_TYPE = 'file';
export const SHARED_RESOURCE_TYPE = 'shared';

export const RESOURCE_TYPES = [
  { label: 'Website', value: WEBSITE_RESOURCE_TYPE },
  { label: 'File', value: FILE_RESOURCE_TYPE },
  { label: 'Shared', value: SHARED_RESOURCE_TYPE },
];

export const NOTE_CUSTOM_TIMELINE_TYPE = 'note';
export const FILE_CUSTOM_TIMELINE_TYPE = 'file';
export const FEEDBACK_CUSTOM_TIMELINE_TYPE = 'feedback';

export const CUSTOM_TIMELINE_TYPES = [
  { label: 'Note', value: NOTE_CUSTOM_TIMELINE_TYPE },
  { label: 'File', value: FILE_CUSTOM_TIMELINE_TYPE },
  { label: 'Add feedback', value: FEEDBACK_CUSTOM_TIMELINE_TYPE },
];
