import React from 'react';
import { Section } from '../../common';
import ZoomJoin from './zoomJoin';
import dayjs from 'dayjs';

const DashboardWebinar = ({ webinarData, name, email }: { webinarData: any; name: string; email: string }) => (
  <Section
    className={'field-mb'}
    headline={
      <>
        <p>{webinarData.title}</p>
        <p className={'text-sm'}>This webinar will start at: {dayjs(webinarData.started_at).format('HH:mm')}</p>
      </>
    }
  >
    <ZoomJoin name={name} email={email} startTime={webinarData.started_at} link={webinarData.url} />
  </Section>
);

export default DashboardWebinar;
