import React, { HTMLAttributes } from 'react';
import { BadgeProps } from './badge.types';
import { BadgeCounterStyled, BadgeStyled } from './badge.styled';

type Ref = HTMLDivElement;

interface Props extends BadgeProps, Omit<HTMLAttributes<Ref>, 'children'> {}

const Badge = React.forwardRef<Ref, Props>(
  (
    {
      counter = 0,
      max = 99,
      showCounter = true,
      showZero = false,
      showPulse = false,
      color = 'primary',
      size = 'md',
      children,
      ...other
    }: Props,
    ref
  ): JSX.Element => (
    <BadgeStyled ref={ref} {...(other as HTMLAttributes<Ref>)}>
      {children}
      {(showZero || counter > 0) && (
        <BadgeCounterStyled $color={color} $size={size} $showCounter={showCounter} $showPulse={showPulse}>
          {showCounter && (max && counter && counter > max ? `${max}+` : counter)}
        </BadgeCounterStyled>
      )}
    </BadgeStyled>
  )
);

Badge.displayName = 'Badge';

export default Badge;
