import React from 'react';
import moment from 'moment';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Tooltip from '../../../global/tooltip/tooltip';
import { ActionButton } from '../../../global/buttonIcon/common';
import dayjs from 'dayjs';
import { useMutation, useQueryClient } from 'react-query';
import { deletePost } from '../../../service/api';
import { POSTS } from '../../../service/queryKeys';
import ConfirmDeleteCommunityPostModal from '../modals/confirmDeleteCommunityPostModal';
import useModal from '../../../hooks/useModal';

const CommunityPost = ({
  post,
  editorMode,
  adminMode,
  setEditPost,
  setCancelEdit,
}: {
  post: CommunityPostType;
  editorMode?: boolean;
  adminMode?: boolean;
  setEditPost?: any;
  setCancelEdit?: any;
}) => {
  const timeAgo = moment.utc(post.created_at).local().startOf('seconds').fromNow();

  const queryClient = useQueryClient();

  const removeCommunityPost = useMutation(deletePost, {
    onSuccess: () => {
      queryClient.invalidateQueries(POSTS);
    },
  });

  const [confirmDeleteModal, toggleConfirmDeleteModal, setConfirmDeleteModalProps] = useModal((props: any) => (
    <ConfirmDeleteCommunityPostModal {...props} />
  ));

  return (
    <div className="flex border p-1 md:py-3 md:px-5 mb-3 bg-white sm:rounded-lg ">
      {confirmDeleteModal}
      <div className="flex items-center space-x-2 w-full">
        <div className="flex-1 py-3 px-2 w-full">
          {adminMode && post.draft && (
            <span className="mt-4 bg-yellow-100 text-yellow-800 text-md font-medium mr-2 px-2.5 py-0.5 rounded border border-yellow-400">
              Unpublished
            </span>
          )}
          <div className="flex flex-1 space-x-4 items-center overflow-hidden pb-5">
            <span className="inline-block relative flex-shrink-0">
              <img
                className="bg-surface-50 inline-block object-cover object-center rounded-full h-12 w-12"
                height="48"
                width="48"
                src={post.author_avatar_url}
                alt={post.author_name}
              />
            </span>
            <div className="overflow-hidden flex-grow -mb-1">
              <div className="truncate w-full flex items-center">
                <div className="flex space-x-2">
                  <p className={'text-base font-semibold truncate'}>{post.author_name}</p>
                </div>
                {(adminMode || editorMode) && (
                  <div className={'ml-auto'}>
                    {adminMode && (
                      <span className="bg-red-100 text-red-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded border border-red-400">
                        Admin Mode
                      </span>
                    )}
                    <div className={'flex mb-4'}>
                      <>
                        <ActionButton.Edit
                          disabled={!editorMode}
                          onClick={() => {
                            setEditPost(post);
                            setCancelEdit(false);
                          }}
                        />
                        <ActionButton.Remove
                          disabled={!editorMode}
                          onClick={() => {
                            setConfirmDeleteModalProps({
                              handleSubmit: () => removeCommunityPost.mutateAsync(post.id!),
                            });
                            toggleConfirmDeleteModal(true);
                          }}
                        />
                      </>
                    </div>
                  </div>
                )}
              </div>
              <div className={'text-sm mt-1'}>
                <time dateTime={post.created_at} title={new Date(post.created_at).toLocaleString()}>
                  {timeAgo}
                </time>
                {post.author_role && <span> &#8226; {post.author_role}</span>}
              </div>
            </div>
          </div>
          <div>
            {post?.tags && post.tags.length > 0 && (
              <span className={'text-xs'}>
                {post.tags.map((tag: any) => (
                  <span
                    key={Math.random()}
                    className={'font-semibold tracking-wide bg-yellow-600 text-white mr-2 px-2.5 py-0.5 rounded-full'}
                  >
                    #{tag}{' '}
                  </span>
                ))}
              </span>
            )}
            <div className="w-full break-words mb-8 mt-4">
              <p className={'my-2 text-2xl font-semibold'}>{post.title}</p>
              {post.content && <div dangerouslySetInnerHTML={{ __html: post.content }}></div>}
            </div>
          </div>
          {post.attachments &&
            post.attachments.length > 0 &&
            post.attachments.map((attachment: any) => (
              <div key={Math.random()} className={'mb-8 w-full h-full border-2'}>
                <div className={'flex gap-2 m-4'}>
                  {attachment.extension === 'pdf' && (
                    <img
                      className={'m-auto mx-4'}
                      src={
                        'https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/833px-PDF_file_icon.svg.png'
                      }
                      width={34}
                      height={34}
                      alt={'attachment'}
                    />
                  )}
                  <div className={'mb-2 font-medium'}>
                    <p>{attachment.name}</p>
                    <p>{Math.round((attachment.size / 1000000 + Number.EPSILON) * 100) / 100} MB</p>
                  </div>
                  <div className={'mr-8 ml-auto flex'}>
                    <Tooltip content={'Preview'}>
                      <>
                        <a className={'m-auto'} href={attachment.url} target={'_blank'} rel="noopener noreferrer">
                          <Icon elementSize={40} icon={GLOBAL_ICONS['observation']} />
                        </a>{' '}
                      </>
                    </Tooltip>
                    <Tooltip content={'Download'}>
                      <>
                        <a
                          className={'m-auto'}
                          href={attachment.download_url}
                          target={'_blank'}
                          rel="noopener noreferrer"
                        >
                          <Icon elementSize={40} icon={GLOBAL_ICONS['download']} />
                        </a>{' '}
                      </>
                    </Tooltip>
                  </div>
                </div>
              </div>
            ))}
          {post.embeds &&
            post.embeds.length > 0 &&
            post.embeds.map((embed: any) => {
              return (
                <React.Fragment key={embed.id ?? Math.random()}>
                  <a href={embed.url} target={'_blank'} rel="noreferrer">
                    <div className={'mb-8 p-1 md:p-2 lg:p-3 xl:p-4 flex flex-row w-full max-w-full border '}>
                      <div className={'min-w-min min-h-min'}>
                        <img
                          className={'object-cover w-44 h-44'}
                          src={
                            embed.image_url
                              ? embed.image_url
                              : embed.type === 'blog'
                              ? 'https://images.unsplash.com/photo-1499750310107-5fef28a66643?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8YmxvZ3xlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=500&q=60'
                              : embed.type === 'video'
                              ? 'https://media.istockphoto.com/id/1212476803/video/play-icon-like-on-player.jpg?s=640x640&k=20&c=tKj6ZBiZ2oHSUi6sD5vasSOKt2T-ltLmy1ARwY6km5o='
                              : 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930'
                          }
                          alt={embed.title}
                        />
                      </div>
                      <div
                        className={
                          'py-0 flex flex-1 items-center px-1 md:px-2 lg:px-3 xl:px-4 overflow-hidden box-border break-normal'
                        }
                      >
                        <div className="flex flex-col w-full max-w-full">
                          {embed.title && (
                            <div className={'xl:max-h-max mb-1.5 font-semibold overflow-hidden text-ellipsis'}>
                              {embed.title}
                            </div>
                          )}
                          {embed.description && (
                            <div
                              className={
                                'max-h-12 text-base mb-1.5 overflow-hidden text-ellipsis whitespace-normal line-clamp-2'
                              }
                            >
                              {embed.description}
                            </div>
                          )}
                          <div>
                            <span className={'font-light inline-flex items-baseline gap-2 text-sm'}>
                              {embed.provider_image && (
                                <img src={embed.provider_image} width={12} height={12} alt={'provider_name'} />
                              )}
                              <span>
                                {embed?.provider_name && embed.provider_name}
                                {embed?.author ? (embed?.provider_name ? ` / ${embed.author}` : embed.author) : ''}
                                {embed?.created_date
                                  ? embed?.author || embed?.provider_name
                                    ? ` / ${dayjs(embed.created_date).format('DD MMM YYYY')}`
                                    : dayjs(embed.created_date).format('DD MMM YYYY')
                                  : ''}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export type CommunityPostType = {
  id?: string;
  title: string;
  author_name: string;
  author_avatar_url: string;
  author_role: string;
  content: string;
  created_at: string;
  embeds?: Array<CommunityEmbedType>;
  attachments?: Array<CommunityAttachmentType>;
  tags?: Array<CommunityTagType>;
  draft?: boolean;
};

export type CommunityEmbedType = {
  id?: string;
  type: string;
  description?: string;
  provider_name?: string;
  provider_image?: string;
  title?: string;
  url: string;
  image_url?: string;
  author?: string;
  created_at?: string;
};

export type CommunityAttachmentType = {
  id?: string;
  name?: string;
  size?: number;
  extension?: string;
  url?: string;
  download_url?: string;
};

export type CommunityTagType = {
  id?: string;
  name?: string;
};
export default CommunityPost;
