import { ButtonHTMLAttributes } from 'react';
import styled, { css, ThemeProps } from 'styled-components';
import { DEFAULT_THEME } from '../../Theme';
import { ButtonProps } from './button.types';
import {
  getBaseButtonStyles,
  getButtonDisabledStyle,
  getButtonFillStyle,
  getButtonOutlineStyle,
  getButtonSize,
  getButtonTextStyle,
} from '../../helpers/styles';
import { SizeType } from '../../resources/types';

const getColors = (props: ButtonProps & ThemeProps<any> & ButtonHTMLAttributes<HTMLButtonElement>) => {
  if (props.disabled) {
    return getButtonDisabledStyle(props);
  } else if (props.asLink) {
    return getButtonTextStyle(props);
  } else if (props.isOutline) {
    return getButtonOutlineStyle(props);
  } else {
    return getButtonFillStyle(props);
  }
};

export const ButtonStyled = styled.button.attrs((props: ButtonHTMLAttributes<any>) => ({
  type: props.type || 'button',
  ...props,
}))<ButtonProps & ThemeProps<any> & ButtonHTMLAttributes<HTMLButtonElement>>`
  ${(props) => getBaseButtonStyles(props)}
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  ${(props) => !props.asLink && `text-transform: uppercase;`}
  ${(props) => getColors(props)}
  ${(props) => getButtonSize(props.size as SizeType, props.height)}
`;

ButtonStyled.defaultProps = {
  theme: DEFAULT_THEME,
};
