import React from 'react';

const TextResourcesDefaultPage = ({ resource }: { resource: any }) => {
  const entries = Object.entries(resource);

  return (
    <>
      This is all the info regarding your text resource:
      {entries.map((entry: any, index: number) => (
        <div key={index}>
          {`${entry[0]}`}: {`${entry[1]}`}
        </div>
      ))}
    </>
  );
};
export default TextResourcesDefaultPage;
