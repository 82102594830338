import React from 'react';
import Icon from '../../../global/icon/icon';

const Comment = ({
  comment,
  onEdit,
  onDelete,
  userId
}: {
  comment: CommentType;
  onEdit: (id: number, message: string) => void;
  onDelete: (id: number) => void;
  userId: string
}) => {
  return (
    <div className={'border-b-2 pb-3 mt-5'}>
      <div className={'flex justify-between'}>
        <div className={'inline-flex gap-2'}>
          <span className={'text-muted'}>
            Commented by {comment.created_by.name} - {comment.created_at}
          </span>
          {comment.created_by.id === userId && (
            <Icon
              tooltipText={'Edit'}
              onClick={() => onEdit(comment.id, comment.message)}
              icon={'Pencil'}
              color={'grey'}
              elementSize={20}
              className={'cursor-pointer'}
              style={{ padding: 0, margin: 0 }}
            />
          )}
        </div>
        {comment.created_by.id === userId && (
          <Icon
            tooltipText={'Delete'}
            onClick={() => onDelete(comment.id)}
            icon={'X'}
            color={'grey'}
            elementSize={20}
            className={'cursor-pointer'}
            style={{ padding: 0, margin: 0 }}
          />
        )}
      </div>
      <p className={'font-bold'}>{comment.message}</p>
    </div>
  );
};

export type CommentType = {
  id: number;
  message: string;
  created_by: {
    id: number | string;
    name: string;
  };
  created_at: string;
};

export default Comment;
