import { createRef } from 'react'
import PropTypes from 'prop-types'
import {Sidetab } from '@typeform/embed-react'
import React from 'react';
import { useQuery } from "react-query";
import { ME } from "../service/queryKeys";
import { getMe } from "../service/api";

const SideTab = ({formId} : PropTypes) => {

  const { data: me } = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
  });

  const sidetabRef = createRef()

  return (
    <div>
      <Sidetab
        id={formId}
        hidden={{
          user_id : me.id,
          name: me.first_name,
        }}
        ref={sidetabRef}
        medium="demo-test"
        buttonText="Send us feedback"
        buttonColor="#ffa366"
      />
    </div>
  )
}

export default SideTab;


type PropTypes = {
  formId: string;
};
