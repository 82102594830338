import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { getDimensionsFeedbackData } from '../../../service/api';
import CustomBarChart, { getColorFomLabel } from '../components/customBarChart';
import Choice from '../../../form/choice/choice';
import Accordion from '../../../global/accordion/accordion';
import { DIMENSIONS_NAMES } from '../../../resources/dimensions';
import { DIMENSIONS_DATA } from '../../../service/queryKeys';
import { AxiosError } from 'axios';
import Alert from '../../../global/alert/alert';
import useModal from '../../../hooks/useModal';
import HelpModal from '../modals/HelpModal';
import Button from '../../../global/button/button';
import { VERSION_B } from '../../instrument/sections/instrumentForm';

const ALL_OPTION = 'all';

export const processedDimensionData = (
  data: {
    questions: Record<string, Array<{ title: string; direction: number }>>;
    options: Array<{ id: string; name: string }>;
    data: Record<
      string,
      Record<
        string,
        Record<
          string,
          {
            data: Record<string, number>;
            ci: Record<string, { u: number; l: number }>;
            pse?: Record<string, number>;
          }
        >
      >
    >;
  },
  dimension: number,
  selectors: Array<string>,
  element?: string
) => {
  const info: Array<Record<string, any>> = [];
  const keys: Array<{id: string, name: string}> = [];

  let questions: Array<{ title: string; direction: number; element?: string }>;

  if (element) {
    questions = data.questions[element]!;
  } else {
    questions = Object.entries(data.questions).reduce((acc: Array<any>, curr: Record<number, any>) => {
      if (!curr[0].includes(`${dimension}.`)) {
        return acc;
      }

      for (const item of curr[1]) {
        acc.push({
          ...item,
          element: curr[0],
        });
      }

      return acc;
    }, [])!;
  }

  for (const question of questions) {
    const questionElement = element ?? question.element!;

    const item: Record<string, any> = {
      question: `${!element ? question.element : ''} ${question.title}`,
      questionDirection: question.direction,
      color: getColorFomLabel(questionElement),
    };

    for (const selectorId of selectors) {
      if (!(data.data[dimension][questionElement] && data.data[dimension][questionElement][selectorId])) {
        continue;
      }

      if (!keys.some((key) => key.id === selectorId)) {
        const selector = (data?.options ?? []).find((i) => i.id === selectorId)!;
        keys.push(selector);
      }

      item[selectorId] = data.data[dimension][questionElement][selectorId].data[question.title] ?? 0;
      item['ci-' + selectorId] = data.data[dimension][questionElement][selectorId].ci[question.title] ?? {
        u: 0,
        l: 0,
      };

      if (data.data[dimension][questionElement][selectorId]?.pse) {
        item['pse-' + selectorId] = data.data[dimension][questionElement][selectorId].pse![question.title] ?? 0;
      }
    }

    info.push(item);
  }

  return { info, dataKeys: keys, color: info[0].color };
};

const DimensionsChartSection = ({
  surveyVersion,
  timeframeSelections,
}: {
  surveyVersion?: string;
  timeframeSelections?: Array<string>;
}) => {
  const [modal, toggleModal, setModalProps] = useModal((props: any) => <HelpModal {...props} />);
  const [accordionActive, setAccordionActive] = useState(0);

  const [selectors, setSelector] = useReducer((state: any, action: any) => {
    let values = [...state];

    if (Array.isArray(action.value)) {
      return action.value;
    }

    if (state.includes(action.value)) {
      values = values.filter((value) => value !== action.value && value);
    } else if (values.length <= 3) {
      if (action.value === ALL_OPTION) {
        values = [];
      } else {
        values = values.filter((value) => value !== ALL_OPTION);
      }

      values.push(action.value);
    }

    return [...values];
  }, []);

  const { data: dimensionsChartData, error, isFetching } = useQuery(
    [DIMENSIONS_DATA, timeframeSelections, surveyVersion],
    () => getDimensionsFeedbackData(timeframeSelections, surveyVersion),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!dimensionsChartData) {
      return;
    }

    setSelector({
      value: dimensionsChartData.options.map((option) => option.id).filter((option) => option !== ALL_OPTION),
    });
  }, [dimensionsChartData]);

  if (isFetching) {
    return (
      <Alert type="info" className={'field-mb'}>
        Loading...
      </Alert>
    );
  }

  return (
    <>
      {(error as AxiosError)?.response?.status === 403 ? (
        <Alert type="info" className={'field-mb'}>
          {(error as AxiosError)?.response?.data.message}
        </Alert>
      ) : (
        <div>
          {modal}
          <div className={'border-b field-mb pb-8'}>
            <div className={'flex flex-col lg:flex-row lg:items-center gap-4'}>
              {dimensionsChartData &&
                dimensionsChartData.options.map((option) => (
                  <Choice
                    id={'summary-choices-option'}
                    key={option.id}
                    label={option.name}
                    type={'switch'}
                    checked={selectors?.includes(option.id)}
                    onChange={() => setSelector({ value: option.id })}
                    className={'pretty-no-margin'}
                  />
                ))}
            </div>
            <Button
              asLink
              mainColor={'info'}
              size={'sm'}
              style={{ padding: 0 }}
              onClick={() => {
                setModalProps({
                  title: 'Understanding your data',
                  size: 'xlg',
                });
                toggleModal(true);
              }}
            >
              Understanding your data
            </Button>
          </div>
          {dimensionsChartData &&
            Object.entries(dimensionsChartData.data)
              .filter((dimension: Record<number, any>) => Object.values(dimension[1]).length > 0)
              .map((dimension: Record<number, any>, index: number) => (
                <div key={`dimension_chart--${index}`}>
                  <Accordion
                    title={
                      <div>
                        <span className={'text-muted'}>Dimension {dimension[0]} —</span>
                        <b>{DIMENSIONS_NAMES[dimension[0] as 1 | 2 | 3 | 4]}</b>
                      </div>
                    }
                    isOpen={index === accordionActive}
                    handleOpen={() => setAccordionActive(index)}
                  >
                    {surveyVersion === VERSION_B ? (
                      <div key={`element--${index}`} className={'overflow-x-auto field-mb'}>
                        <CustomBarChart
                          data={processedDimensionData(dimensionsChartData, dimension[0], selectors)}
                          dataKey={'question'}
                          id={`dimensionChartId-${dimension[0]}-`}
                        />
                      </div>
                    ) : (
                      Object.entries(dimension[1])
                        .sort((a, b) => (a[0] > b[0] ? 1 : -1))
                        .map((element: Record<number, any>, index) => (
                          <div key={`element--${index}`} className={'overflow-x-auto field-mb'}>
                            <h5 className={'field-mb'}>{element[0]}</h5>
                            <CustomBarChart
                              data={processedDimensionData(dimensionsChartData, dimension[0], selectors, element[0])}
                              dataKey={'question'}
                              id={`dimensionChartId-${dimension[0]}-${element[0]}`}
                            />
                          </div>
                        ))
                    )}
                  </Accordion>
                </div>
              ))}
        </div>
      )}
    </>
  );
};

export default DimensionsChartSection;
