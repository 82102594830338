import { ToastContainer } from 'react-toastify';
import styled, {css, DefaultTheme, ThemeProps} from 'styled-components';
import {DEFAULT_THEME} from "../../../Theme";
import 'react-toastify/dist/ReactToastify.min.css';

import { readableColor } from 'polished';

const getColors = (props: ThemeProps<DefaultTheme>, type: string) => {
  return css`
		background: ${(props) => props.theme.colors[type]};
		&,
		svg {
			color: ${(props) => readableColor(props.theme.colors[type])};
		}
	`;
};

export const ToastStyled = styled(ToastContainer).attrs((props: any) => ({
  ...props,
}))<ThemeProps<DefaultTheme>>`
	.Toastify__toast--info {
		${(props) => getColors(props, 'info')}
	}
	.Toastify__toast--error {
		${(props) => getColors(props, 'danger')}
	}
	.Toastify__toast--warning {
		${(props) => getColors(props, 'warning')}
	}
	.Toastify__toast--success {
		${(props) => getColors(props, 'success')}
	}
`;

ToastStyled.defaultProps = {
  theme: DEFAULT_THEME,
};
