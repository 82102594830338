import React, { useState } from 'react';
import DashboardLayout from '../../../layout/dashboard';
import NewCommunityPost from './newPost';
import { Section } from '../../common';
import Button from '../../../global/button/button';
import CommunityNewsfeed from '../communityNewsfeed';

const CommunityDashboard = ({}) => {
  const [newPost, setNewPost] = useState<boolean>(false);
  const [viewPosts, setViewPosts] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [editPost, setEditPost] = useState('');

  const [cancelEditPost, setCancelEditPost] = useState(false);

  return (
    <React.Fragment>
      <DashboardLayout title={'Community dashboard'}>
        <Section className={'mb-4'} headline={'Community dashboard'}>
          <div className={'flex'}>
            <div className={'m-auto'}>
              <Button disabled={viewPosts} onClick={() => setNewPost(!newPost)} className={'m-2'}>
                {newPost ? 'Cancel' : 'Add new post'}
              </Button>
              <Button
                disabled={newPost}
                className={'m-2'}
                onClick={() => {
                  setViewPosts(!viewPosts);
                  setEditMode(false);
                }}
              >
                {viewPosts ? 'Hide ' : 'View '} posts
              </Button>
              <Button
                disabled={!viewPosts}
                onClick={() => {
                  setEditMode(!editMode);
                  setEditPost('');
                }}
                className={'m-2'}
              >
                {editMode ? 'Disable ' : 'Enable '}Editor mode
              </Button>
            </div>
          </div>
        </Section>
        {newPost && <NewCommunityPost setCancelEdit={setNewPost} />}
        {viewPosts && (
          <div className={'xl:flex gap-8'}>
            <CommunityNewsfeed
              width={'lg'}
              adminMode
              editorMode={editMode}
              setEditPost={setEditPost}
              setCancelEdit={setCancelEditPost}
            />
            {editMode && editPost != '' && !cancelEditPost && (
              <div className={'w-full xl:w-1/2 mt-4 xl:mt-0'}>
                <NewCommunityPost editMode postData={editPost} setCancelEdit={setCancelEditPost} />
              </div>
            )}
          </div>
        )}
      </DashboardLayout>
    </React.Fragment>
  );
};

export default CommunityDashboard;
