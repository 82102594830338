import { useEffect } from 'react';
import {
  Step1Type,
  Step2Type,
  Step3Type,
  Step4Type,
  StepType,
  useRegistrationContext,
} from '../../../state/registration/provider';

// The point of this component is just to send the Formik values tu the Registration Context so
// when the user press the "Back" button all values are not removed
const FormObserver = ({ step, values }: { step: StepType, values: Step1Type | Step2Type | Step3Type | Step4Type }) => {
  const { step1, step2, step3, step4, setStep, setState } = useRegistrationContext();

  useEffect(() => {
    switch (step) {
      case 'step1':
        // When the step1 is updated it cleans the rest of step to not generate conflicts
        const step1Values = { ...values } as Step1Type;
        setState({
          step1: {
            ...step1,
            role: step1Values.role,
          },
          step2: step1?.role === step1Values?.role ? step2 : undefined,
          step3: step1?.role === step1Values?.role ? step3 : undefined,
          step4: step1?.role === step1Values?.role ? step4 : undefined,
        });
        break;
      case 'step2':
        const step2Values = { ...values } as Step2Type;
        setStep('step2', step2Values);
        break;
      case 'step3':
        const step3Values = { ...values } as Step3Type;
        setStep('step3', step3Values);
        break;
      case 'step4':
        const step4Values = { ...values } as Step4Type;
        setStep('step4', step4Values);
        break;
    }
  }, [JSON.stringify(values)]);

  return null;
};

export default FormObserver;
