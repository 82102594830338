import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { DefaultOptionType } from '../../../resources/types';
import Selector from '../../../form/select/selector';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { MATS, USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import { addSchoolToMat, getMats } from '../../../service/api';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { UserType } from '../userManagementPage';
import useModal from '../../../hooks/useModal';
import { toast } from 'react-toastify';
import Alert from '../../../global/alert/alert';
import { AxiosError } from 'axios';

const JoinSchoolToMatModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => {
  const [confirmationModal, toggleConfirmationModal, setConfirmationProps] = useModal(
    (props: any) => <ConfirmationModal {...props} onSuccess={() => toggle(false)} />,
    {
      title: 'Add school to existing group (MAT)',
    }
  );

  const { data: matOptions } = useQuery([MATS], getMats, {
    staleTime: Infinity,
    select: (data) =>
      data.data.data.map((mat) => {
        return { label: mat.organisation, value: mat.id, organisation: mat.organisation };
      }),
    placeholderData: [],
  });

  return (
    <>
      {confirmationModal}
      <Modal>
        <Formik<{ mat: (DefaultOptionType & { organisation: string }) | null; schoolId?: number }>
          initialValues={{
            mat: null,
            schoolId: user.school_id,
          }}
          validationSchema={Yup.object().shape({
            mat: Yup.object().required(),
            schoolId: Yup.number().nullable().required(),
          })}
          validateOnMount={true}
          onSubmit={({ mat, schoolId }) => {
            setConfirmationProps({
              mat: { id: mat!.value, name: mat!.organisation },
              school: { id: schoolId, name: user.organisation },
            });
            toggleConfirmationModal(true);
          }}
        >
          {({ isValid, handleSubmit, values, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Selector
                  id={'mat'}
                  label={'Group (MAT)'}
                  placeholder={'Please select a group'}
                  required
                  options={matOptions}
                  value={values.mat}
                  onChange={(option: DefaultOptionType) => setFieldValue('mat', option)}
                  isClearable={false}
                />
              </Modal.Body>
              <Modal.Footer>
                <Modal.CancelButton onClick={() => toggle(false)} label={'Close'} />
                <Modal.ConfirmButton label={'Select'} disabled={!isValid} type={'submit'} />
              </Modal.Footer>
            </form>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const ConfirmationModal = ({
  mat,
  school,
  toggle,
  onSuccess,
}: ModalToggleProps & {
  mat: { name: string; id: string };
  school: { name: string; id: number };
  onSuccess: () => void;
}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(addSchoolToMat, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toast.success('School was successfully added to the group');
      onSuccess();
      toggle(false);
    },
    onError: () => {
      toast.error('An error has occurred');
    },
  });

  return (
    <Modal>
      <Modal.Body>
        <p className={'font-bold sm-field-mb'}>
          Are you sure you would like to add {school.name} to {mat.name}
        </p>
        {mutation.error && (
          <Alert type={'danger'}>
            {(mutation.error as AxiosError)!.response?.data.message ?? 'An unexpected error has occurred'}
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} label={'Close'} />
        <Modal.ConfirmButton
          onClick={() => mutation.mutate({ schoolId: school.id, matId: mat.id })}
          label={mutation.isLoading ? 'Loading...' : 'Confirm'}
          disabled={mutation.isLoading}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default JoinSchoolToMatModal;
