import { TransitionProps } from 'react-transition-group/Transition';
import { CSSProperties } from 'react';

export interface TransitionsProps extends Omit<TransitionProps, any> {
  /**
   * If the transition is trigger.
   */
  when: boolean;
  /**
   * Transition content.
   */
  children: any;
}

export type StatusType = 'entering' | 'entered' | 'exiting' | 'exited';

export type TransitionStylesType = Record<StatusType, CSSProperties>;

export const DEFAULT_TRANSITION_DURATION = 450;

export const DEFAULT_TRANSITION_FUNC = 'cubic-bezier(0.250, 0.460, 0.450, 0.940)';
