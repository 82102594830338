export const YEAR_GROUPS = [
  {
    label: 'Year 1',
    value: 'Year 1',
  },
  {
    label: 'Year 2',
    value: 'Year 2',
  },
  {
    label: 'Year 3',
    value: 'Year 3',
  },
  {
    label: 'Year 4',
    value: 'Year 4',
  },
  {
    label: 'Year 5',
    value: 'Year 5',
  },
  {
    label: 'Year 6',
    value: 'Year 6',
  },
  {
    label: 'Year 7',
    value: 'Year 7',
  },
  {
    label: 'Year 8',
    value: 'Year 8',
  },
  {
    label: 'Year 9',
    value: 'Year 9',
  },
  {
    label: 'Year 10',
    value: 'Year 10',
  },
  {
    label: 'Year 11',
    value: 'Year 11',
  },
  {
    label: 'Year 12',
    value: 'Year 12',
  },
  {
    label: 'Year 13',
    value: 'Year 13',
  },
];
