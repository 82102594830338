import React, { useState } from 'react';
import Button from '../../../global/button/button';
import AddNotes from '../components/addNotes';
import SelectFocus from '../components/selectFocus';
import AddResources from '../components/addResources';
import GenerateFeedback from '../components/generateFeedback';
import { useQuery } from 'react-query';
import { PATHWAYS_V2_STEP } from '../../../service/queryKeys';
import { getV2PathwaysStep } from '../../../service/api';
import { useParams } from 'react-router-dom';
import { IDENTIFY_STEP } from '../views/PathwayV2View';
import AddReference from '../components/AddReference';
import { STUDENT_SURVEY_REFERENCE_TYPE } from '../modals/AddReferenceModal';

const PathwaysIdentifyTab = ({
  disabled,
  startDisabled,
  team,
  saveStep,
  completed,
  started,
}: {
  disabled: boolean;
  startDisabled: boolean;
  team?: { id: string; name: string };
  saveStep: (data?: { focus_element?: string; focus_type?: string; notes?: string }, callback?: () => void) => void;
  completed: boolean;
  started: boolean;
}) => {
  const { id: pathwaysId } = useParams();

  const { data: pathwaysStep } = useQuery(
    [PATHWAYS_V2_STEP, IDENTIFY_STEP, pathwaysId],
    () => getV2PathwaysStep(IDENTIFY_STEP, pathwaysId!),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
    }
  );

  const [editMode, setEditMode] = useState(!completed);

  disabled = disabled || !editMode;

  return (
    <div className={'flex flex-col divide-y'}>
      <div className={'font-bold flex pathways-section justify-between'}>
        <span className={'w-5/6'}>
          In this step, you can identify the area of Great Teaching you want to improve during this development cycle.
          To help you decide, you can generate some feedback about your current teaching practice, you might also seek
          advice from colleagues. When you’re ready, select your element and continue on to the next step.
        </span>
        <Button
          id={`identify_${completed ? 'edit' : 'start'}_step`}
          size={'md'}
          onClick={() => {
            if (completed) {
              setEditMode(true);
              return;
            }

            saveStep();
          }}
          disabled={startDisabled || (started ? editMode : !editMode)}
        >
          {completed ? 'Edit' : 'Start'} step
        </Button>
      </div>
      <div className={'pathways-section'}>
        <GenerateFeedback
          disabled={disabled}
          description={'Generate some feedback about your current teaching practice.'}
        />
      </div>
      <div className={'pathways-section'}>
        <AddReference
          disabled={disabled}
          step={IDENTIFY_STEP}
          options={[STUDENT_SURVEY_REFERENCE_TYPE]}
          references={pathwaysStep?.references}
        />
      </div>
      <div className={'pathways-section'}>
        <AddNotes
          disabled={disabled}
          notes={pathwaysStep?.notes}
          description={
            'You can make a note of your decision-making process here, the factors you considered. These notes are for your future reference only.'
          }
          save={(notes, callback) => {
            saveStep({ notes }, callback);
          }}
        />
      </div>
      <div className={'pathways-section'}>
        <AddResources
          disabled={disabled}
          step={IDENTIFY_STEP}
          resources={pathwaysStep?.resources}
          team={team}
          description={
            'Add relevant resources that might help you identify an area for development (e.g., meeting notes, school policies).'
          }
        />
      </div>
      <div className={'pathways-section'}>
        <SelectFocus
          disabled={disabled}
          save={(data: { focus_element: string; focus_type: string }, callback) => {
            saveStep(data, () => {
              if (callback) {
                callback();
              }

              setEditMode(false);
            });
          }}
        />
      </div>
    </div>
  );
};

export default PathwaysIdentifyTab;
