import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import Input from '../../../form/input/input';
import * as Yup from 'yup';
import Selector from '../../../form/select/selector';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getDevelopmentPeriods, postOrPutPlans } from '../../../service/api';
import { DEVELOPMENT_PERIODS, PLANS } from '../../../service/queryKeys';

/*Props passed from the parent component*/
type DevelopmentAdminPlanModalProps = {
  plan: any;
};

/*Validation schema for Formik*/
const SCHEMA = Yup.object().shape({
  name: Yup.string().trim().required().max(64),
  description: Yup.string().trim().required().max(200),
  developmentPeriods: Yup.array(),
});

/*Plan modal, props - plan data and toggle*/
const PlansModal = ({ plan, toggle }: ModalToggleProps & DevelopmentAdminPlanModalProps) => {
  const moveItem = useCallback((dragIndex, hoverIndex) => {
    // TODO - switch dragIndex with hoverIndex
    // Not sure how to do this without a state
  }, []);

  /*Post or Put Plans Mutation*/
  const queryClient = useQueryClient();
  const planMutation = useMutation(postOrPutPlans, {
    onSuccess: () => {
      queryClient.invalidateQueries(PLANS);
      toggle(false);
    },
  });

  /*Get Development Periods mutation to display the list in the selector in modal*/
  const developmentPeriodsQuery = useQuery(DEVELOPMENT_PERIODS, getDevelopmentPeriods, {
    staleTime: Infinity,
    select: (data) =>
      data.data.data.map((period: any) => {
        return {
          label: period.name,
          value: period.id,
        };
      }),
    placeholderData: [],
    keepPreviousData: true,
    enabled: true,
  });
  return (
    <Modal>
      {/*Initial values is fields you will be filling and ...plan to get existing object*/}
      <Formik
        initialValues={{
          name: '',
          description: '',
          developmentPeriods: [],
          ...plan,
        }}
        validateOnMount={true}
        validationSchema={SCHEMA}
        onSubmit={(values) => {
          planMutation.mutateAsync(values).then(() => {
            if (!values.id) {
              toast.success('Added new plan');
              return;
            }
            toast.success('Plan updated');
          });
        }}
      >
        {({ values, handleChange, handleSubmit, isValid, setFieldValue }) => (
          <DndProvider backend={HTML5Backend}>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                {/* Plan Title */}
                <div className={'field-mb'}>
                  <Input
                    required
                    maxLength={64}
                    id={'name'}
                    name={'name'}
                    label={'Plan name'}
                    placeholder={'Please enter a plan name'}
                    value={values.name || ''}
                    onChange={handleChange}
                  />
                </div>
                {/* Plan Description */}
                <div className={'field-mb'}>
                  <Input
                    required
                    maxLength={200}
                    id={'description'}
                    type={'textarea'}
                    name={'description'}
                    label={'Plan description'}
                    placeholder={'Please enter a plan description'}
                    value={values.description}
                    onChange={handleChange}
                  />
                </div>
                {/* Plan terms */}
                <Selector
                  isMulti
                  className={'mb-2'}
                  required
                  id={`developmentPeriods`}
                  label={`Terms`}
                  placeholder={`Please select one or more terms`}
                  value={values.developmentPeriods.map((id: string) =>
                    developmentPeriodsQuery.data.find((g: any) => g.value === id)
                  )}
                  options={developmentPeriodsQuery.data}
                  onChange={(options: any) => {
                    setFieldValue(
                      'developmentPeriods',
                      options.map((option: any) => option.value)
                    );
                  }}
                />
              </Modal.Body>
              <Modal.Footer>
                <Modal.CancelButton onClick={() => toggle(false)}/>
                <Modal.ConfirmButton
                  disabled={!(isValid && values.developmentPeriods.length > 0)}
                  type={'submit'}
                  label={planMutation.isLoading ? 'Loading...' : plan.id ? 'Update Plan' : 'Add Plan'}
                />
              </Modal.Footer>
            </form>
          </DndProvider>
        )}
      </Formik>
    </Modal>
  );
};

export default PlansModal;
