import { createContext } from 'react';

export default createContext<Context>({ teamsMode: 'greatTeachingTeams' });

export const TEAMS_MODE_CONTEXT_TEAMS_MODES = {
  greatTeachingTeams: 'greatTeachingTeams',
  mentoringTeams: 'mentoringTeams',
} as const;

interface Context {
  teamsMode: 'greatTeachingTeams' | 'mentoringTeams';
}
