import React from 'react';
import DashboardLayout from '../../layout/dashboard';
import { ROLE_TEACHER_FREE } from '../../resources/roles';
import FreePage from '@app/asset/images/Pathways.png';
import PathwayV2View from './views/PathwayV2View';

const PathwaysV2Page = ({role}: {role: string}) => {
  if (role === ROLE_TEACHER_FREE) {
    return (
      <DashboardLayout
        isMockedPage={true}
        mockedAlert={
          'This is an example development cycle. Development cycles are a feature of the paid GTT subscription that guide your professional development journey and allow you to record your thoughts and progress.'
        }
      >
        <img src={FreePage} alt={'Pathways'} width={'100%'} height={'auto'} />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout title={'Development cycles'}>
      <PathwayV2View />
    </DashboardLayout>
  );
};

export default PathwaysV2Page;
