import axios from 'axios';

export const STATUS = {
  OPEN: 0,
  PROPOSED_TO_OPEN: 1,
  OPEN_PROPOSED_TO_CLOSE: 2,
  CLOSED: 3,
};

export function getSchoolVerifySchools(input?: string) {
  return axios.get<
    {
      urn: string;
      la: string;
      name: string;
      postcode: string;
      fullInfo: string;
      status: string;
    }[]
  >(
    `https://api.school-verify.aircury.com/api/schools/${input}?status=${STATUS.OPEN},${STATUS.OPEN_PROPOSED_TO_CLOSE}`,
    {
      headers: {
        Authorization: process.env.REACT_APP_SCHOOL_VERIFY_TOKEN,
      },
    }
  );
}
