import React, { useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import PathwaysV3WorkspaceView from './views/pathwaysV3WorkspaceView';
import { useQuery } from 'react-query';
import { ME } from '../../service/queryKeys';
import { getMe } from '../../service/api';
import { isLoggedIn } from '../../service/auth';
import VideoAlert from '../../guides/components/videoAlert';
import { ROLE_TEACHER_FREE } from '../../resources/roles';
import Button from '../../global/button/button';

const pathwaysV3WorkspacePage = () => {
  const getMeQuery = useQuery([ME], () => getMe(), {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const me = getMeQuery.data?.data.me ?? null;

  const isFree = me?.role === ROLE_TEACHER_FREE;

  const [showAlert, setShowAlert] = useState<boolean>(!me!.show_video.includes('workspace_header'));

  return (
    <DashboardLayout
      parent={{ id: 'pathways', label: 'Development cycles' }}
      title={'Workspace'}
      /*pageAction={isFree ? <Button>Download</Button> : undefined}*/
    >
      {isFree && (
        <div>
          <VideoAlert
            setVideo={undefined}
            showHideForever={false}
            showHide={false}
            starterTemplate={isFree}
            data={{
              title: '',
              id: 'starter_template_dev_cycles',
              description: (
                <>
                  <p className={'mb-4'}>
                    Here is an example of a teacher&apos;s development cycle. They are working towards reducing their
                    learner&apos;s use of passive study techniques and promoting more active thinking.
                  </p>
                  <p className={'mb-4'}>
                    Development cycles are a private workspace for teachers to record their goals, decisions and actions
                    as they work on an element of Great Teaching.
                  </p>
                </>
              ),
            }}
            userId={me!.id}
          />
        </div>
      )}
      {showAlert && !isFree && (
        <div className="xl:w-4/5 xl:pr-12">
          <VideoAlert
            setVideo={setShowAlert}
            showHideForever
            data={{
              title: 'Development cycle workspace',
              id: 'workspace_header',
              description: (
                <>
                  <p className={'mb-2'}>Welcome to your workspace! </p>
                  <p>
                    You can use this page to record your progress as you work on an element of Great Teaching. This
                    workspace is for your own development – it&rsquo;s private to you.
                  </p>
                </>
              ),
            }}
            userId={me!.id}
          />
        </div>
      )}
      <PathwaysV3WorkspaceView isFree={isFree} />
    </DashboardLayout>
  );
};

export default pathwaysV3WorkspacePage;
