import React, { useEffect, useState } from 'react';
import Button from '../../global/button/button';
import DefaultVideoThumbnail from '@app/asset/images/DefaultVideoThumbnail.png';
import { Checkbox } from 'pretty-checkbox-react';
import useModal from '../../hooks/useModal';
import VideoModal from '../../pages/instrument/modals/videoModal';
import { useMutation, useQueryClient } from 'react-query';
import { disableWatchVideo } from '../../service/api';
import { ME } from '../../service/queryKeys';

const VideoAlert = ({
  data,
  setVideo,
  showHideForever = false,
  userId,
  classes,
  showHide = true,
  starterTemplate = false,
  showVideo = false,
  customButtonMessage,
  customVideoTitle,
}: {
  data: any;
  setVideo: any;
  showHideForever?: boolean;
  userId?: string;
  classes?: string;
  showHide?: boolean;
  starterTemplate?: boolean;
  showVideo?: boolean;
  customButtonMessage?: string;
  customVideoTitle?: string;
}) => {
  const [hide, setHide] = useState<boolean>(false);
  const [hideForever, setHideForever] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const hideVideoForever = useMutation(disableWatchVideo, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME);
    },
  });

  useEffect(() => {
    if (hide && hideForever) {
      hideVideoForever.mutateAsync(data.id);
      setVideo(false);
    } else if (hide && !hideForever) {
      setVideo(false);
    }
  }, [hide]);

  const [videoModal, toggleVideoModal] = useModal(
    (props: any) => <VideoModal videoLink={data.videoLink} {...props} />,
    {
      title: customVideoTitle ? customVideoTitle : 'Watch the video',
      size: 'lg',
    }
  );

  return (
    <>
      {videoModal}
      <div
        style={{ backgroundColor: '#e7eef2', borderColor: '#1177b0' }}
        className={`border rounded p-4 mb-8 gap-8 mb-8 flex flex-column w-2/3 mx-auto ml-0 ${
          showVideo ? 'w-full ' : ''
        } ${classes ? classes : ''}`}
      >
        {showVideo && (
          <div
            className={'min-w-44 w-full xl:w-1/4 aspect-video cursor-pointer'}
            onClick={() => toggleVideoModal(true)}
          >
            <img
              src={DefaultVideoThumbnail}
              alt="Thumbnail"
              className={'border rounded'}
              style={{ borderColor: '#68a4c5' }}
            />
          </div>
        )}
        <div className={showVideo ? 'w-5/6' : ''}>
          <div className={'w-full mb-4'}>
            <div className={'font-bold mb-2'}>{data.title}</div>
            <div className={`font-light ${starterTemplate ? '' : 'text-sm'}`}>{data.description}</div>
          </div>
          <div className={`${starterTemplate ? '' : 'ml-4'} lg:gap-4 lg:flex`}>
            {showHideForever && (
              <div>
                <Checkbox
                  className={'lg:mt-4 my-4'}
                  onChange={() => setHideForever(!hideForever)}
                  style={{ fontSize: 15 }}
                  color={'primary'}
                >
                  Don&apos;t show again
                </Checkbox>
              </div>
            )}
            {showHide && (
              <Button
                onClick={() => setHide(true)}
                className={`text-sm font-bold ${showVideo ? 'ml-auto' : ''}`}
                mainColor={'white'}
                size={'sm'}
              >
                {customButtonMessage ? (
                  customButtonMessage
                ) : (
                  <>
                    Got it<span className="font-serif text-md">!</span>
                  </>
                )}
              </Button>
            )}
            {starterTemplate && (
              <a
                href={'https://share.hsforms.com/1x1hXVC-mTTeY2hLEE6aoWA1eppz'}
                target={'_blank'}
                rel="noopener noreferrer"
              >
                <Button
                  onClick={() => setHide(true)}
                  className={`text-sm font-bold ${showVideo ? 'ml-auto mt-2 ' : ''}`}
                  mainColor={'white'}
                  size={'sm'}
                >
                  {' '}
                  I&apos;d like to know more
                </Button>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoAlert;
