import React from 'react';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';

const UpgradeModal = ({ toggle, handleSubmit , text, valid }: ModalToggleProps & UpgradeModalModalType) => {
  return (
    <Modal>
      <Modal.Body>
        <p>{text}</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          label={'Go back'}
          onClick={() => {
            toggle(false);
          }}
        />
        {valid && (
          <Modal.ConfirmButton
            label={'Continue'}
            onClick={handleSubmit}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
};

UpgradeModal.displayName = 'UpgradeModal';

type UpgradeModalModalType = {
  handleSubmit: () => Promise<any>;
  text: string;
  valid?: boolean;
  email: string;
};

export default UpgradeModal;
