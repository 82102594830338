import React from 'react';
import AddButton from './addButton';
import useModal from '../../../hooks/useModal';
import AddReferenceModal from '../modals/AddReferenceModal';
import { processedSummaryData } from '../../feedback/sections/summaryChartSection';
import CustomBarChart from '../../feedback/components/customBarChart';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { processedElementData } from '../../feedback/sections/elementChartSection';
import routeBuilder from '../../../service/routeBuilder';

const AddReference = ({
  disabled = false,
  step,
  options,
  references,
}: {
  disabled?: boolean;
  step: string;
  options: Array<string>;
  references?: Array<ReferenceType>;
}) => {
  const [modal, toggleModal] = useModal(
    (props: any) => <AddReferenceModal step={step} {...props} options={options} />,
    {
      title: 'Add reference',
    }
  );

  return (
    <>
      <div className={'flex'}>
        {modal}
        <AddButton disabled={disabled} label={'Reference'} onClick={() => toggleModal(true)} />
      </div>
      {(references ?? []).map((reference) => {
        if (reference.type === 'chart') {
          return (
            <div className={'w-full mt-5 overflow-x-auto p-5 border-gray-100 border-2'} key={Math.random()}>
              <div className={'flex justify-between mb-5'}>
                <span className={'font-bold'}>{reference.data.options[0].name}</span>
                <ButtonIcon
                  disabled={disabled}
                  mainColor={'muted'}
                  isOutline
                  size={'xsm'}
                  disableTooltip={true}
                  icon={'X'}
                />
              </div>
              <CustomBarChart
                data={
                  reference.chart_element
                    ? processedElementData(reference.data, reference.chart_element, [reference.data.options[0].id])
                    : processedSummaryData(reference.data, [reference.data.options[0].id], reference.survey_version)
                }
                dataKey={reference.chart_element ? 'question' : 'element'}
                id={`summary_${reference.id}`}
              />
            </div>
          );
        }

        if (reference.type === 'video') {
          return (
            <div className={'w-full sm:w-96 mt-5 p-5 border-gray-100 border-2'} key={Math.random()}>
              <div className={'mb-5'}>
                <div className={'flex justify-between'}>
                  <span className={'font-bold'}>{reference.data.title}</span> <br />
                  <ButtonIcon
                    disabled={disabled}
                    mainColor={'muted'}
                    isOutline
                    size={'xsm'}
                    disableTooltip={true}
                    icon={'X'}
                  />
                </div>
                <span className={'text-muted'}>
                  {reference.data.author} - {reference.data.created_at}
                </span>
              </div>
              <img
                src={reference.data.thumbnail_path}
                alt={reference.data.title}
                className={'w-full cursor-pointer'}
                onClick={() => {
                  window.open(
                    `${window.location.origin}${routeBuilder('videoPlayer').generate(reference.data.id)}`,
                    '_blank'
                  );
                }}
              />
            </div>
          );
        }
      })}
    </>
  );
};

export type ReferenceType = {
  id: string;
  type: 'chart' | 'video';
  data?: any;
  survey_version?: string;
  chart_element?: string;
};

export default AddReference;
