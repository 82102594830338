import React, { useState } from 'react';
import { PathwaysAdminGoals, PathwaysCycles, PathwaysGoals, PathwaysLog } from '../sections';
import { Section } from '../../common';
import Tab from '../../../global/tab/tab';
import Tag from '../../../global/tag/tag';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getDevelopmentGoals,
  getDevelopmentPeriod,
  getStrategies,
  postOrPutStrategy,
} from '../../../service/api';
import useModal from '../../../hooks/useModal';
import PathwayStrategyModal from '../modals/PathwaysStrategyModal';
import { ROLE_TEACHER } from '../../../resources/roles';
import { JoinButton } from '../../../global/button/common';
import Alert from '../../../global/alert/alert';
import JoinGroupModal from '../modals/joinGroup.modal';
import {
  PATHWAYS_PERIOD,
  ADMIN_GOALS,
  CYCLE_STRATEGIES,
  CYCLES,
  TEACHER_STRATEGIES,
} from '../../../service/queryKeys';
import {useParams} from "react-router-dom";

const PathwayView = ({ me }: { me: any }) => {
  const [activeTab, setActiveTab] = useState<string | number>(1);
  const queryClient = useQueryClient();
  const {id} = useParams()

  const PathwaysPeriodQuery = useQuery(
    [PATHWAYS_PERIOD, id],
    () => getDevelopmentPeriod(id),
    {
      select: (data) => data.data.data,
      placeholderData: {},
      staleTime: Infinity,
    }
  );

  const strategiesCycleQuery = useQuery(
    CYCLE_STRATEGIES,
    () => getStrategies(undefined, PathwaysPeriodQuery.data.goal?.id, 'cycle'),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
      enabled: PathwaysPeriodQuery.isFetched,
    }
  );

  const strategiesQuery = useQuery(
    TEACHER_STRATEGIES,
    () => getStrategies(undefined, PathwaysPeriodQuery.data.goal?.id),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
      enabled: PathwaysPeriodQuery.isFetched && PathwaysPeriodQuery.data?.goal?.id != null,
    }
  );

  const adminGoalsQuery = useQuery(
    [ADMIN_GOALS, undefined, 'school', PathwaysPeriodQuery?.data?.id],
    () => getDevelopmentGoals(undefined, 'school', PathwaysPeriodQuery?.data.id as string),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
      keepPreviousData: true,
      enabled: PathwaysPeriodQuery?.data?.id != null,
    }
  );

  const [strategyModal, toggleStrategyModal, setStrategyProps] = useModal((props: any) => <PathwayStrategyModal {...props} />);

  const strategyMutation = useMutation(postOrPutStrategy, {
    onSuccess: () => {
      queryClient.invalidateQueries(TEACHER_STRATEGIES);
      queryClient.invalidateQueries(CYCLE_STRATEGIES);
      queryClient.invalidateQueries(CYCLES);
      toggleStrategyModal(false);
    },
  });

  const [joinGroupModal, toggleJoinGroupModal, setJoinGroupModalProps] = useModal((props: any) => (
    <JoinGroupModal {...props} />
  ));

  if (!PathwaysPeriodQuery.isFetched || PathwaysPeriodQuery?.error) {
    return (
      <Alert type={'warning'} className={'mb-8'}>
        <p>There is no academic year set for the current date.</p>
      </Alert>
    );
  }

  return (
    <>
      {joinGroupModal}
      {strategyModal}
      <PathwaysLog
        periodId={PathwaysPeriodQuery?.data.id as string}
        data={PathwaysPeriodQuery.data}
        disabled={true}
        isCompleted={PathwaysPeriodQuery.data.completed}
      />
      {null != PathwaysPeriodQuery.data.goal && (
        <>
          <div className={'field-mb'}>
            <Section
              id={'goals'}
              size={null}
              headline={`Development Goal: ${PathwaysPeriodQuery.data.goal.name}`}
              more={
                <JoinButton
                  disabled={true}
                  onClick={() => {
                    setJoinGroupModalProps({
                      title: 'Join great teaching team',
                    });

                    toggleJoinGroupModal(true);
                  }}
                  label={'JOIN TEAM'}
                />
              }
            >
              <Tab
                items={[
                  {
                    value: 1,
                    label: (
                      <div>
                        Your strategies{' '}
                        <Tag size={'sm'} mainColor={'secondary'} isPill content={`${strategiesQuery.data.length}`} />
                      </div>
                    ),
                  },
                  {
                    value: 2,
                    label: (
                      <div>
                        School goals{' '}
                        <Tag size={'sm'} mainColor={'secondary'} isPill content={`${adminGoalsQuery.data.length}`} />
                      </div>
                    ),
                  },
                ].filter((item) => (me.subscription_type === 'bulk' ? true : item.value === 1))}
                active={activeTab}
                clickOnTab={(value) => setActiveTab(value)}
              >
                <Tab.Content id={1} active={activeTab}>
                  {/* Teacher Goals */}
                  <div className={'section-container'}>
                    <PathwaysGoals
                      data={strategiesQuery.data}
                      toggle={(title = 'Add new strategy', strategy?: any, isShared?: boolean) => {
                        setStrategyProps({
                          title,
                          isAdmin: false,
                          mutation: strategyMutation,
                          role: ROLE_TEACHER,
                          strategy,
                          goal: PathwaysPeriodQuery.data.goal?.id,
                          isShared,
                          isGroupMember: me.group_members > 0,
                          size: 'lg',
                        });
                        toggleStrategyModal(true);
                      }}
                      disabled={true}
                    />
                  </div>
                </Tab.Content>
                {me.subscription_type === 'bulk' && (
                  <Tab.Content id={2} active={activeTab}>
                    {/* School Goals */}
                    <div className={'section-container'}>
                      <PathwaysAdminGoals
                        data={adminGoalsQuery.data}
                      />
                    </div>
                  </Tab.Content>
                )}
              </Tab>
            </Section>
          </div>
          <PathwaysCycles
            periodId={PathwaysPeriodQuery?.data.id as string}
            strategiesOptions={strategiesCycleQuery.data}
            disabled={true}
          />
        </>
      )}
    </>
  );
};

export default PathwayView;
