import React from 'react';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Widget } from '@typeform/embed-react';
import { LinkStyled } from '../../../../../../global/link/link.styled';
import VideoSection from '../../../../../dashboard/sections/videoSection';

export const findComponentTemplate = (type: string, data: any) => {
  switch (type) {
    case 'title':
      return <h1 className={'my-2 text-left'}>{data}</h1>;
    case 'subtitle':
      return <p className={'text-2xl my-1 py-0.5 text-left'}>{data}</p>;
    case 'content':
      return (
        <>
          <p className={'my-1 py-2 break-normal text-left'}>{data}</p>
        </>
      );
    case 'estimated_time':
      return <p className={'italic'}>{data}</p>;
    case 'wysiwyg':
      return (
        <div className={'break-normal'}>
          <Editor
            toolbarHidden={true}
            readOnly={true}
            editorState={EditorState.createWithContent(convertFromRaw(data))}
          />
        </div>
      );
    case 'description':
      return <p>{data}</p>;
    case 'typeform':
      return (
        <div style={{ height: '350px' }} className={'mx-auto'}>
          <Widget className={'h-full'} id={data} />
        </div>
      );
    case 'link':
      const key = Object.keys(data)[0];
      const value = Object.values(data)[0];
      return (
        <p>
          <LinkStyled href={typeof value === 'string' ? value : ''}>{key}</LinkStyled>
        </p>
      );
    case 'video':
      return <VideoSection video={data} />;
    default:
      return null;
  }
};

export const transformData = (inputData: string) => {
  const wrappedData = `[${inputData}]`;
  const parsedData = JSON.parse(wrappedData);
  const transformedData = parsedData.map((item: any) => {
    if (item.wysiwyg) {
      return {
        ...item,
        wysiwyg: JSON.parse(item.wysiwyg),
      };
    }
    return item;
  });
  return transformedData;
};

export const darkThemeColor = '#4b5563';
export const darkThemeBorderColor = '#4b5563';
export const darkThemeTextColor = '#ffffff';

export const lightThemeColor = '#f1f0f0';
export const lightThemeBorderColor = '#4b5563';
export const lightThemeTextColor = '#4b5563';
