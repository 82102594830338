import React from "react";
import {logout} from "../../service/auth";
import { useQueryClient } from "react-query";

const LogoutPage = () => {

  logout();

  return <></>;
};

export default LogoutPage;
