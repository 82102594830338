import React from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Selector from '../../../form/select/selector';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { SelectOptionType } from '../../../service/types';
import { useQuery } from 'react-query';
import { REFERENCE_OPTIONS } from '../../../service/queryKeys';
import { getReferenceOptions } from '../../../service/api';

const AddResourceModal = ({ toggle, step, options }: ModalToggleProps & { step: string; options: Array<string> }) => {
  const { data: surveys } = useQuery(
    [REFERENCE_OPTIONS, STUDENT_SURVEY_REFERENCE_TYPE],
    () => getReferenceOptions(STUDENT_SURVEY_REFERENCE_TYPE),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
      placeholderData: [],
    }
  );

  const { data: videos } = useQuery(
    [REFERENCE_OPTIONS, VIDEO_OBSERVATION_REFERENCE_TYPE],
    () => getReferenceOptions(VIDEO_OBSERVATION_REFERENCE_TYPE),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
      placeholderData: [],
    }
  );

  return (
    <Modal>
      <Formik<{
        type: string | null;
        survey: { label: string; value: number; elements?: Array<{ label: string; value: string }> } | null;
        element: { label: string; value: string } | null;
        video: { label: string; value: number } | null;
      }>
        initialValues={{
          type: options.length === 1 ? options[0] : null,
          survey: null,
          element: null,
          video: null,
        }}
        validationSchema={Yup.object({
          type: Yup.string().nullable().required(),
          survey: Yup.object()
            .nullable()
            .when('type', (type: string, schema: AnySchema) => {
              if (STUDENT_SURVEY_REFERENCE_TYPE === type) {
                return Yup.object().required();
              }

              return schema;
            }),
          video: Yup.object()
            .nullable()
            .when('type', (type: string, schema: AnySchema) => {
              if (VIDEO_OBSERVATION_REFERENCE_TYPE === type) {
                return Yup.object().required();
              }

              return schema;
            }),
        })}
        isInitialValid={false}
        onSubmit={() => {
          /* Deprecated form */
        }}
      >
        {({ values, handleSubmit, setFieldValue }) => (
          <form className={'py-5'} onSubmit={handleSubmit}>
            <Modal.Body>
              {options.length > 1 && (
                <div className={'flex items-center gap-4 sm-field-mb'}>
                  <div className={'flex-grow mb-5'}>
                    <RadioGroupOnBlock
                      id={'modal-reference-type-selector'}
                      hideLabel
                      optionChecked={values.type}
                      options={REFERENCE_TYPES}
                      handleChange={(value) => {
                        setFieldValue('type', value);
                        setFieldValue('survey', null);
                        setFieldValue('element', null);
                        setFieldValue('video', null);
                      }}
                    />
                  </div>
                </div>
              )}
              {STUDENT_SURVEY_REFERENCE_TYPE === values.type && (
                <>
                  <div className={'field-mb'}>
                    <Selector
                      label={'Survey instances'}
                      id={'modal-survey-selector'}
                      options={surveys}
                      value={values.survey}
                      onChange={(value: SelectOptionType) => setFieldValue('survey', value)}
                    />
                  </div>
                  {values.survey && values.survey.elements && values.survey.elements.length > 0 && (
                    <Selector
                      label={'Element'}
                      id={'modal-element-selector'}
                      options={values.survey.elements}
                      value={values.element === null ? { label: 'All elements', value: '' } : values.element}
                      onChange={(value: SelectOptionType) => setFieldValue('element', value)}
                      isClearable={values.element !== null}
                    />
                  )}
                </>
              )}
              {VIDEO_OBSERVATION_REFERENCE_TYPE === values.type && (
                <Selector
                  label={'Video'}
                  id={'modal-video-selector'}
                  options={videos}
                  value={values.video}
                  onChange={(value: SelectOptionType) => setFieldValue('video', value)}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
              <Modal.ConfirmButton type={'submit'} label={'Add Reference'} disabled={true} />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export const STUDENT_SURVEY_REFERENCE_TYPE = 'student_survey';
export const VIDEO_OBSERVATION_REFERENCE_TYPE = 'video';

const REFERENCE_TYPES = [
  { label: 'Student survey', value: STUDENT_SURVEY_REFERENCE_TYPE },
  { label: 'Video feedback', value: VIDEO_OBSERVATION_REFERENCE_TYPE },
];

export default AddResourceModal;
