import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Input from '../../../form/input/input';
import * as Yup from 'yup';
import { useMutation, useQueryClient } from 'react-query';
import { postOrPutNotification } from '../../../service/api';
import { GLOBAL_NOTIFICATIONS } from '../../../service/queryKeys';
import { DateRangePicker } from '../../../form/datepicker/common';
import { NotificationType } from '../globalNotificationsManagementPage';

const AddGlobalNotificationModal = ({
  toggle,
  notification,
}: ModalToggleProps & { notification?: Omit<NotificationType, 'start_date' | 'end_date'> } & {
  start_date: Date;
  end_date: Date;
}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(postOrPutNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries(GLOBAL_NOTIFICATIONS);
      toggle(false);
    },
  });

  return (
    <Modal>
      <Formik
        initialValues={{
          title: '',
          message: '',
          start_date: null,
          end_date: null,
          ...notification,
        }}
        isInitialValid={notification != null}
        validationSchema={Yup.object().shape({
          title: Yup.string().trim().required().max(50),
          message: Yup.string().trim().required().max(280),
          start_date: Yup.date().required(),
          end_date: Yup.date().required(),
        })}
        onSubmit={(values) => {
          mutation.mutateAsync(values).then(() => {
            if (notification) {
              toast.success('Notification updated successfully.');
              return;
            }

            toast.success('Notification added successfully.');
          });
        }}
      >
        {({ values, handleChange, handleSubmit, isValid, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className={'field-mb'}>
                <Input
                  required
                  id="title"
                  label="Title"
                  placeholder="Please enter a notification title"
                  value={values.title}
                  onChange={handleChange}
                />
              </div>
              <div className={'field-mb'}>
                <Input
                  type="textarea"
                  required
                  id="message"
                  label="Message"
                  placeholder="Please enter a notification message"
                  value={values.message}
                  onChange={handleChange}
                />
              </div>
              <DateRangePicker
                required
                start={values.start_date}
                end={values.end_date}
                onStartChange={(date) => setFieldValue('start_date', date)}
                onEndChange={(date) => setFieldValue('end_date', date)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                label={mutation.isLoading ? 'Loading...' : notification ? 'Edit notification' : 'Add notification'}
                type="submit"
                disabled={!isValid || mutation.isLoading}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddGlobalNotificationModal;
