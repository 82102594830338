import React from 'react';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { removeV3PathwayResource } from '../../../../service/api';
import { PATHWAYS_V3 } from '../../../../service/queryKeys';
import { Resource } from '../../../../@types/Entity/Resource';
import { toast } from 'react-toastify';

const DeleteV3ResourceModal = ({
  toggle,
  resource,
  pathwayId,
}: ModalToggleProps & { resource: Resource; pathwayId: string }) => {
  const queryClient = useQueryClient();

  const deleteV3ResourceMutation = useMutation(removeV3PathwayResource, {
    onSuccess: () => {
      toast.success('Resource removed successfully');
      queryClient.invalidateQueries([PATHWAYS_V3, pathwayId]);
      toggle(false);
    },
  });

  return (
    <Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          deleteV3ResourceMutation.mutate({ pathwayId: pathwayId, resourceId: resource.id });
        }}
      >
        <Modal.Body>Are you sure you would like to delete this resource? This action cannot be undone</Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton
            label={deleteV3ResourceMutation.isLoading ? 'Loading...' : 'Delete'}
            type={'submit'}
            disabled={deleteV3ResourceMutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteV3ResourceModal;
