import React from 'react';

const PosterCollectionPage = ({ resource }: { resource?: any }) => {
  return (
    <>
      <div className={'w-full'}>No page</div>
    </>
  );
};
export default PosterCollectionPage;
