import React from 'react';
import { ROLE_MAT_COORDINATOR, ROLE_TEACHER_FREE, RolesType } from '../../resources/roles';
import MatFeedbackView from './views/MatFeedbackView';
import UserFeedbackView from './views/UserFeedbackView';
import { emulatedElementType } from "@app/frontend/src/routes";

const FeedbackPage = ({ userRole, emulatedElement }: { userRole: RolesType, emulatedElement?: emulatedElementType }) => {
  if (userRole === ROLE_MAT_COORDINATOR) {
    return <MatFeedbackView />;
  }

  return <UserFeedbackView isFree={userRole === ROLE_TEACHER_FREE} emulatedElement={emulatedElement}/>;
};

export default FeedbackPage;
