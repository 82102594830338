import React from 'react';
import Modal from '../../../../global/messages/modal/modal.components';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import {
  CUSTOM_TIMELINE_TYPES,
  FEEDBACK_CUSTOM_TIMELINE_TYPE,
  FILE_CUSTOM_TIMELINE_TYPE,
  NOTE_CUSTOM_TIMELINE_TYPE,
} from '../../pathwaysPage.types';
import RadioGroupOnBlock from '../../../../form/choice/radioGroupOnBlock';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import { SelectOptionType } from '../../../../service/types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { STUDENT_SURVEY_REFERENCE_TYPE, VIDEO_OBSERVATION_REFERENCE_TYPE } from '../AddReferenceModal';
import Selector from '../../../../form/select/selector';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PATHWAYS_V3, REFERENCE_OPTIONS } from '../../../../service/queryKeys';
import { getReferenceOptions, handleV3PathwayLog } from '../../../../service/api';
import Input from '../../../../form/input/input';
import { PathwayLog } from '../../../../@types/Entity/PathwayLog';
import DragAndDropFile from '../../../../form/dragAndDrop/DragAndDropFile';
import { toast } from 'react-toastify';

const V3PathwayLogModal = ({ toggle, pathway, log }: ModalToggleProps & { pathway: V3Pathway; log?: PathwayLog }) => {
  const queryClient = useQueryClient();
  const pathwayLogsMutation = useMutation(handleV3PathwayLog, {
    onSuccess: () => {
      toast.success(`Timeline ${log ? 'edited' : 'added'}`);
      toggle(false);
      queryClient.invalidateQueries([PATHWAYS_V3, pathway.id]);
    },
  });

  const { data: surveys } = useQuery(
    [REFERENCE_OPTIONS, STUDENT_SURVEY_REFERENCE_TYPE],
    () => getReferenceOptions(STUDENT_SURVEY_REFERENCE_TYPE),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
      placeholderData: [],
    }
  );

  const { data: videos } = useQuery(
    [REFERENCE_OPTIONS, VIDEO_OBSERVATION_REFERENCE_TYPE],
    () => getReferenceOptions(VIDEO_OBSERVATION_REFERENCE_TYPE),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
      placeholderData: [],
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      type: log?.notes
        ? NOTE_CUSTOM_TIMELINE_TYPE
        : log?.file
        ? FILE_CUSTOM_TIMELINE_TYPE
        : log?.reference
        ? FEEDBACK_CUSTOM_TIMELINE_TYPE
        : '',
      subType: '',
      title: log?.notes ? log.title : '',
      note: log?.notes ? EditorState.createWithContent(convertFromRaw(JSON.parse(log.notes))) : undefined,
      file: null as File | null,
      survey: null as (SelectOptionType & { elements: SelectOptionType[] }) | null,
      element: null as SelectOptionType | null,
      video: null as SelectOptionType | null,
    },
    resolver: yupResolver(
      Yup.object({
        type: Yup.string().nullable().required(),
        subType: Yup.string()
          .nullable()
          .when('type', (type: string, schema: AnySchema) => {
            if (FEEDBACK_CUSTOM_TIMELINE_TYPE === type) {
              return Yup.string().required();
            }

            return schema;
          }),
        title: Yup.string()
          .nullable()
          .when('type', (type: string, schema: AnySchema) => {
            if (NOTE_CUSTOM_TIMELINE_TYPE === type) {
              return Yup.string().required().max(180);
            }

            return schema;
          }),
        note: Yup.object()
          .nullable()
          .when('type', (type: string, schema: AnySchema) => {
            if (NOTE_CUSTOM_TIMELINE_TYPE === type) {
              return Yup.object().required();
            }

            return schema;
          }),
        file: Yup.mixed()
          .nullable()
          .test('file-required', 'The file is required.', (file, { parent }) => {
            if (parent.type !== FILE_CUSTOM_TIMELINE_TYPE) {
              return true;
            }

            return file !== null;
          })
          .test('file-size', 'The file you are attempting to upload exceed 6mb.', (file) => {
            //6MB
            if (file?.size >= 6 * 1000 * 1000) {
              return false;
            }

            return true;
          }),
        survey: Yup.object()
          .nullable()
          .when('subType', (subType: string, schema: AnySchema) => {
            if (STUDENT_SURVEY_REFERENCE_TYPE === subType) {
              return Yup.object().required();
            }

            return schema;
          }),
        video: Yup.object()
          .nullable()
          .when('subType', (subType: string, schema: AnySchema) => {
            if (VIDEO_OBSERVATION_REFERENCE_TYPE === subType) {
              return Yup.object().required();
            }

            return schema;
          }),
      })
    ),
  });

  return (
    <Modal>
      <form
        className={'py-5'}
        onSubmit={handleSubmit((values) => {
          pathwayLogsMutation.mutate({
            id: log?.id,
            pathwayId: pathway.id,
            title: values.title,
            notes: values.note ? JSON.stringify(convertToRaw(values.note.getCurrentContent())) : undefined,
            file: values.file,
            video: values.video?.value as number,
            isc: values.survey?.value as number,
            element: values.element?.value as string,
          });
        })}
      >
        <Modal.Body>
          <div className={'flex items-center gap-4 sm-field-mb'}>
            <div className={'flex-grow mb-5'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <RadioGroupOnBlock
                    {...field}
                    id={'modal-reference-type-selector'}
                    hideLabel
                    optionChecked={watch('type')}
                    options={CUSTOM_TIMELINE_TYPES}
                    handleChange={(value) => {
                      field.onChange(value);

                      setValue('subType', '');
                      setValue('note', undefined);
                      setValue('file', null);
                      setValue('survey', null);
                      setValue('element', null);
                      setValue('video', null);
                    }}
                  />
                )}
                name={'type'}
              />
            </div>
          </div>
          {NOTE_CUSTOM_TIMELINE_TYPE === watch('type') && (
            <>
              <div className={'field-mb'}>
                <Input
                  {...register('title')}
                  required
                  label={'Title'}
                  id={`number`}
                  placeholder={'Insert a title'}
                  error={errors.title?.message}
                />
              </div>
              <Editor
                toolbar={{
                  options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                  },
                }}
                editorState={watch('note')}
                wrapperClassName={`pathways-section-content`}
                editorClassName={'p-3'}
                onEditorStateChange={(value) => setValue('note', value)}
              />
            </>
          )}
          {FILE_CUSTOM_TIMELINE_TYPE === watch('type') && (
            <DragAndDropFile
              file={watch('file')}
              setFile={(file) => {
                setValue('file', file as File);
              }}
              error={errors.file?.message}
              text={'Click or drag and drop to add a file'}
            />
          )}
          {FEEDBACK_CUSTOM_TIMELINE_TYPE === watch('type') && (
            <>
              <div className={'flex items-center gap-4 sm-field-mb'}>
                <div className={'flex-grow mb-5'}>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <RadioGroupOnBlock
                        {...field}
                        id={'modal-reference-subtype-selector'}
                        hideLabel
                        optionChecked={watch('subType')}
                        options={REFERENCE_TYPES}
                        handleChange={(value) => {
                          field.onChange(value);

                          setValue('survey', null);
                          setValue('video', null);
                        }}
                      />
                    )}
                    name={'subType'}
                  />
                </div>
              </div>
              {STUDENT_SURVEY_REFERENCE_TYPE === watch('subType') && (
                <>
                  <div className={'field-mb'}>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Selector
                          {...field}
                          label={'Survey instances'}
                          id={'modal-survey-selector'}
                          options={surveys}
                        />
                      )}
                      name={'survey'}
                    />
                  </div>
                  {(watch('survey')?.elements ?? [])?.length > 0 && (
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Selector
                          {...field}
                          label={'Element'}
                          id={'modal-element-selector'}
                          options={watch('survey')?.elements}
                          value={watch('element') === null ? { label: 'All elements', value: '' } : watch('element')}
                          isClearable={watch('element') !== null}
                        />
                      )}
                      name={'element'}
                    />
                  )}
                </>
              )}
              {VIDEO_OBSERVATION_REFERENCE_TYPE === watch('subType') && (
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector {...field} label={'Video'} id={'modal-video-selector'} options={videos} />
                  )}
                  name={'video'}
                />
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton
            type={'submit'}
            label={pathwayLogsMutation.isLoading ? 'Loading...' : log ? 'Edit timeline' : 'Add Timeline'}
            disabled={pathwayLogsMutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const REFERENCE_TYPES = [
  { label: 'Student survey', value: STUDENT_SURVEY_REFERENCE_TYPE },
  { label: 'Video observation', value: VIDEO_OBSERVATION_REFERENCE_TYPE },
];

export default V3PathwayLogModal;
