import React from 'react';
import VideoSection from '../../../dashboard/sections/videoSection';
import GttReviewImageMobile from '@app/asset/images/great_teaching_inset_page/GTT_Review_Image_Mobile.png';
import GttReviewImage from '@app/asset/images/great_teaching_inset_page/GTT_Review_Image.png';
import { Section } from '../../../common';
import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw, EditorState } from 'draft-js';
import { checkIfContentExists } from '../../../resourcePage/resources/types';

const TechniqueInfoPage = ({ resource }: { resource?: any }) => {
  return (
    <>
      <div className={'flex-1 block-with-sub-container'}>
        <div className={'relative text-center mb-4'}>
          <img className={'block md:hidden my-4'} src={GttReviewImageMobile} />
          <img className={'hidden md:block my-4'} src={GttReviewImage} />
          <div className={'hidden md:block absolute bottom-2 left-4 lg:bottom-5 lg:left-7'}>
            <p className={'text-md lg:text-2xl xl:text-3xl 2xl:text-4xl'}>
              <strong>{resource?.title}</strong>
            </p>
          </div>
        </div>
        {(resource?.urls.length > 0 || checkIfContentExists(resource?.content)) && (
          <Section>
            {resource?.urls[0] && (
              <div className={'mx-auto w-1/2 mb-8'}>
                <VideoSection video={resource.urls[0]} />
              </div>
            )}
            {checkIfContentExists(resource?.content) && (
              <div className={'flex'}>
                <div className={'w-1/2 m-auto min-h-min'}>
                  <div className={'my-4 min-h-min'}>
                    {resource?.content && (
                      <Editor
                        toolbarHidden={true}
                        readOnly={true}
                        editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(resource.content)))}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </Section>
        )}

        <Section className={'mt-8'}>
          <div className={'grid grid-cols-1 {/*xl:grid-cols-2*/} gap-2 xl:gap-8 xl:m-4'}>
            {/*{additional_resources.map((resource: GreatTeachingInsetInterface) => (
              <GreatTeachingInsetPageCard key={resource?.id} resource={resource}/>
            ))}*/}
            <div className="m-auto">CHILDREN RESOURCES</div>
          </div>
        </Section>

        {(resource?.urls.length > 1 || resource?.instructions.length > 0 || resource?.description) && (
          <Section className={'mt-8'}>
            <div className="w-1/2 m-auto">
              {resource?.urls[1] && (
                <div className={'mx-auto w-1/2 mb-8'}>
                  <VideoSection video={resource.urls[1]} />
                </div>
              )}
              {resource?.instructions.length > 0 && (
                <div className={'mt-2 ml-2'}>
                  <ul className={'list-disc ml-8'}>
                    {resource?.instructions.map((step: string, index: number) => (
                      <li key={index}>{step}</li>
                    ))}
                  </ul>
                </div>
              )}
              {resource?.description && (
                <div className={'flex mt-12'}>
                  <p className={'m-auto'}>{resource?.description}</p>
                </div>
              )}
            </div>
          </Section>
        )}

        <div className={'w-1/3 mt-8 mx-auto pb-1'}>
          CHILDREN RESOURCE (technique card)
          {/*
          <ResourceCard resource={resource} classes={'border bg-gray-200'}/>
          */}
        </div>
      </div>
    </>
  );
};
export default TechniqueInfoPage;
