import React from 'react';
import { Props as SelectProps, components, IndicatorProps } from 'react-select';
import { GLOBAL_ICONS } from '../../../resources/vars';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';

const ClearIndicator: React.FC<SelectProps> = ({ ...props }: any) => {
  return (
    <components.ClearIndicator {...props}>
      <ButtonIcon label={'Clear'} icon={GLOBAL_ICONS.close} isOutline isFree size={'sm'} />
    </components.ClearIndicator>
  );
};

export default ClearIndicator;
