import React from 'react';
import classnames from 'classnames';
import DashboardLayout from '../../layout/dashboard';
import {
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
} from '../../resources/roles';
import { AccountDetailsForm, PasswordUpdateForm, TeacherDetailsForm } from './sections';
import TeacherFreeDeleteForm from './sections/teacherFreeDeleteForm';
import { Me } from '../../@types/Entity/Me';

const EditUserPage = ({ me }: { me: Me }) => {
  //School users see same view as the teachers
  const IS_TEACHER = [ROLE_TEACHER, ROLE_TEACHER_FREE, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
    me.role
  );

  return (
    <DashboardLayout title={'Edit my details'}>
      <div className={'field-mb'}>
        <AccountDetailsForm user={me} />
      </div>
      <div className={classnames({ 'field-mb': IS_TEACHER })}>
        <PasswordUpdateForm />
      </div>
      <div className={'field-mb'}>{IS_TEACHER && <TeacherDetailsForm user={me} />}</div>
      {ROLE_TEACHER_FREE === me.role && <TeacherFreeDeleteForm />}
    </DashboardLayout>
  );
};

export default EditUserPage;
