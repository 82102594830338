import React from 'react';
import { PathwaysPeriods } from '../sections';
import useModal from '../../../hooks/useModal';
import { DevelopmentPeriodsModal } from '../modals';
import { useQuery } from 'react-query';
import { getAcademicYears } from '../../../service/api';
import PathwaysAcademicYears from '../sections/pathwaysAcademicYears';
import { ACADEMIC_YEARS } from '../../../service/queryKeys';
import { PeriodProps } from '../../pathway/pathwaysPage.types';

const IndependentTeacherView = ({ me }: { me: any }) => {
  const [periodsModal, togglePeriodsModal, setPeriodsProps] = useModal((props: any) => (
    <DevelopmentPeriodsModal {...props} />
  ));

  const academicYearsQuery = useQuery(
    [ ACADEMIC_YEARS ],
    () => getAcademicYears(),
    {
      staleTime: Infinity,
      select: (data) => data.data,
      placeholderData: [],
    },
  );

  return (
    <>
      <PathwaysAcademicYears data={academicYearsQuery.data!} />
      {periodsModal}
      <PathwaysPeriods
        academicYears={academicYearsQuery.data!}
        toggle={(period?: PeriodProps) => {
          setPeriodsProps({
            title: `${period ? 'Update term dates' : 'Add new term'}`,
            description: `For ${me.organisation}`,
            period,
            single: true,
          });
          togglePeriodsModal(true);
        }}
      />
    </>
  );
};

export default IndependentTeacherView;
