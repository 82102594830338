export const dimensionList = [
  '1. Understanding the content',
  '2. Creating a supportive environment',
  '3. Maximising opportunity to learn',
  '4. Activating hard thinking',
];
export const elementList = [
  '1.1 Deep and fluent knowledge',
  '1.2 Curriculum sequencing',
  '1.3 Relevant curriculum tasks',
  '1.4 Strategies and misconceptions',
  '2.1 Teacher-student relationships',
  '2.2 Student-student relationships',
  '2.3 Learner motivation',
  '2.4 Climate of high expectations',
  '3.1 Managing time and resources',
  '3.2 Rules and consequences',
  '3.3 Managing disruptive behaviour',
  '4.1 Structuring',
  '4.2 Explaining',
  '4.3 Questioning',
  '4.4 Interacting',
  '4.5 Embedding',
  '4.6 Activating',
];

export interface ResourceCardInterface {
  resource?: any;
  admin?: boolean;
  editor?: boolean;
  draft?: boolean;
  classes?: string;
  preview?: boolean;
  uniqueKey?: any;
}

export const listNumberOfResources = [
  { label: 'Show 4 resources', value: 4 },
  { label: 'Show 8 resources', value: 8 },
  { label: 'Show 12 resources', value: 12 },
  { label: 'Show 16 resources', value: 16 },
];
