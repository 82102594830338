import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { useQuery } from 'react-query';
import { RESOURCE } from '../../../service/queryKeys';
import { getResource } from '../../../service/api';
import NewResource from '../admin/newResource';
import Button from '../../../global/button/button';

const EditResource = ({ id, toggle }: ModalToggleProps & { id: string }) => {
  const { data: resource, isFetched } = useQuery([RESOURCE, id], () => getResource(id), {
    select: (data) => data.data.data,
    placeholderData: {},
    staleTime: Infinity,
  });

  return (
    <Modal>
      <Modal.Body>{isFetched && <NewResource editMode resource={resource} closeModal={toggle}/>}</Modal.Body>
      <Modal.Footer>
        <Button onClick={() => toggle(false)} className={'m-2'}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditResource;
