import React from 'react';
import Progress from "../../../global/progress/progress";
import { useQuery } from "react-query";
import { DASHBOARD } from "../../../service/queryKeys";
import { getDashboard } from "../../../service/api";

const SchoolDialsModal = ({user}: any) => {

  const { data } = useQuery([DASHBOARD, user.id], () => getDashboard(undefined, user.id), {
    select: (data) => data.data.data,
    staleTime: Infinity,
  });

  const PROGRESS_STYLES = {
    minWidth: 350,
  };

  return (
    <>
      <div className={'grid gap-2 grid-cols-1 sm:grid-cols-2 mb-4 '}>
        <div style={PROGRESS_STYLES}>
          <Progress progress={data?.gtt_profile} label="GTT Profile"
                    description="have created a GTT profile"/>
        </div>
        <div style={PROGRESS_STYLES}>
          <Progress
            progress={data?.element_focus}
            label="Element Focus"
            description="have decided upon an element of teaching to improve"
          />
        </div>
      </div>
      <div className={'grid gap-2 grid-cols-1 sm:grid-cols-2 mb-4'}>
        <div style={PROGRESS_STYLES}>
          <Progress
            progress={data?.started_course}
            label="Started course"
            description="have started a teacher course"
          />
        </div>
        <div style={PROGRESS_STYLES}>
          <Progress
            progress={data?.completed_course}
            label="Completed Course"
            description="have completed a teacher course"
          />
        </div>
      </div>
    </>
  )
    ;
};

export default SchoolDialsModal;
