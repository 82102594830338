import { ButtonHTMLAttributes } from 'react';
import styled, { css, ThemeProps } from 'styled-components';
import { ButtonIconProps } from './buttonIcon.types';
import { SizeType } from '../../resources/types';
import {
  getButtonDisabledStyle,
  getButtonFillStyle,
  getButtonOutlineStyle,
  getButtonTextStyle,
  getFocusStyle,
  getMainColor,
} from '../../helpers/styles';
import { DEFAULT_THEME } from '../../Theme';
import { SIZE_HEIGHT } from '../../resources/vars';

const getColors = (props: ButtonIconProps & ThemeProps<any> & ButtonHTMLAttributes<HTMLButtonElement>) => {
  if (props.disabled) {
    return getButtonDisabledStyle(props);
  } else if (props.isFree) {
    return getButtonTextStyle(props);
  } else if (props.isOutline) {
    return getButtonOutlineStyle(props);
  } else {
    return getButtonFillStyle(props);
  }
};

const getSize = (size: SizeType) => {
  return css`
    width: ${SIZE_HEIGHT[size]}px;
    height: ${SIZE_HEIGHT[size]}px;
  `;
};

export const ButtonIconStyled = styled('button').attrs((props: ButtonIconProps & ButtonHTMLAttributes<any>) => ({
  type: props.type || 'button',
  'aria-label': props.label,
  ...props,
}))<ButtonIconProps & ThemeProps<any> & ButtonHTMLAttributes<HTMLButtonElement>>`
  appearance: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) => (props.isCircle ? '9999px' : props.theme.borderRadius.app)};
  border: 2px solid transparent;
  box-shadow: none;
  transition: background ease-in-out 0.1s;

  ${(props) => getColors(props)}
  ${(props) => getSize(props.size as SizeType)}

  &:focus,
	&[aria-pressed='true'] {
    ${(props) => getFocusStyle(getMainColor(props))}
  }
`;

ButtonIconStyled.defaultProps = {
  theme: DEFAULT_THEME,
};
