import React from 'react';
import DefaultTable from '../../../global/table/defaultTable';
import { ActionButton } from '../../../global/buttonIcon/common';
import { NotificationType } from '../globalNotificationsManagementPage';

const GlobalNotificationsTable = ({
  data,
  editAction,
}: {
  data: NotificationType[];
  editAction: (notification: NotificationType) => void;
}) => {
  return (
    <React.Fragment>
      <DefaultTable
        id="global-notifications"
        showCounter={false}
        columns={[
          { id: 'title', label: 'Title' },
          { id: 'message', label: 'Message' },
          { id: 'start_date', label: 'Start Date' },
          { id: 'end_date', label: 'End Date' },
          { id: 'actions', label: 'Actions' },
        ]}
        rows={
          0 === data?.length
            ? []
            : data?.map((notification, index: number) => {
                return {
                  id: `row_goal--${index}`,
                  cells: [
                    { id: 'title', content: notification.title },
                    { id: 'message', content: notification.message },
                    { id: 'start_date', content: notification.start_date },
                    { id: 'end_date', content: notification.end_date },
                    {
                      id: 'actions',
                      class: 'actions',
                      content: (
                        <ul className={'actions-list'}>
                          <li>
                            <ActionButton.Edit onClick={() => editAction(notification)} />
                          </li>
                        </ul>
                      ),
                    },
                  ],
                };
              })
        }
        noDataMessage={<>Start adding notifications clicking on &quot;Add notification&quot; button.</>}
      />
    </React.Fragment>
  );
};

export default GlobalNotificationsTable;
