import React from 'react';
import Modal from '../../../../global/messages/modal/modal.components';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Selector from '../../../../form/select/selector';
import {
  FILE_RESOURCE_TYPE,
  RESOURCE_TYPES,
  SHARED_RESOURCE_TYPE,
  WEBSITE_RESOURCE_TYPE,
} from '../../pathwaysPage.types';
import Input from '../../../../form/input/input';
import RadioGroupOnBlock from '../../../../form/choice/radioGroupOnBlock';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import DragAndDropFile from '../../../../form/dragAndDrop/DragAndDropFile';
import { SelectOptionType } from '../../../../service/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PATHWAYS_V3, V3_SHARED_RESOURCES } from '../../../../service/queryKeys';
import { addV3PathwayResource, getV3PathwaySharedResources } from '../../../../service/api';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { toast } from 'react-toastify';

const V3ResourceModal = ({ toggle, pathway }: ModalToggleProps & { pathway: V3Pathway }) => {
  const queryClient = useQueryClient();

  const sharedResourcesQuery = useQuery(
    [V3_SHARED_RESOURCES, pathway.id],
    () => getV3PathwaySharedResources(pathway.id),
    {
      staleTime: Infinity,
      enabled: !!pathway.group,
    }
  );

  const resourceMutation = useMutation(addV3PathwayResource, {
    onSuccess: () => {
      toast.success('Resource added successfully');
      queryClient.invalidateQueries([PATHWAYS_V3, pathway.id]);
      toggle(false);
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      type: '',
      shared: null as SelectOptionType | null,
      name: null as string | null,
      url: null as string | null,
      file: null as File | null,
    },
    resolver: yupResolver(
      Yup.object({
        type: Yup.string().nullable().required(),
        name: Yup.string()
          .nullable()
          .when('type', (type: string, schema: AnySchema) => {
            if (WEBSITE_RESOURCE_TYPE === type) {
              return Yup.string().required();
            }

            return schema;
          }),
        url: Yup.string()
          .nullable()
          .when('type', (type: string, schema: AnySchema) => {
            if (WEBSITE_RESOURCE_TYPE === type) {
              return Yup.string().required();
            }

            return schema;
          }),
        file: Yup.mixed()
          .nullable()
          .test('file-required', 'The file is required.', (resource, { parent }) => {
            if (parent.type !== FILE_RESOURCE_TYPE) {
              return true;
            }

            return resource !== null;
          })
          .test('file-size', 'The file you are attempting to upload exceed 6mb.', (resource) => {
            //6MB
            if (resource?.size >= 6 * 1000 * 1000) {
              return false;
            }

            return true;
          }),
        shared: Yup.object()
          .nullable()
          .when('type', (type: string, schema: AnySchema) => {
            if (SHARED_RESOURCE_TYPE === type) {
              return Yup.object().required();
            }

            return schema;
          }),
      })
    ),
  });

  return (
    <Modal>
      <form
        className={'py-5'}
        onSubmit={handleSubmit((values) => {
          resourceMutation.mutate({
            pathwayId: pathway.id,
            type: values.type!,
            id: values.shared ? values.shared.value : null,
            name: values.name,
            url: values.url,
            file: values.file,
          });
        })}
      >
        <Modal.Body>
          <div className={'flex items-center gap-4 sm-field-mb'}>
            <div className={'flex-grow mb-5'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <RadioGroupOnBlock
                    {...field}
                    id={'modal-resource-type-selector'}
                    hideLabel
                    optionChecked={watch('type')}
                    options={RESOURCE_TYPES.filter((type) => {
                      return pathway.group != null ? true : type.value != SHARED_RESOURCE_TYPE;
                    })}
                    handleChange={(value) => {
                      field.onChange(value);

                      setValue('shared', null);
                      setValue('name', null);
                      setValue('url', null);
                      setValue('file', null);
                    }}
                  />
                )}
                name={'type'}
              />
            </div>
          </div>
          {WEBSITE_RESOURCE_TYPE === watch('type') && (
            <>
              <Input
                className={'field-mb'}
                {...register('name')}
                label={'Website Name'}
                id={'modal-resource-name-input'}
              />
              <Input
                className={'field-mb'}
                {...register('url')}
                label={'Website URL'}
                id={'modal-resource-url-input'}
              />
            </>
          )}
          {FILE_RESOURCE_TYPE === watch('type') && (
            <DragAndDropFile
              file={watch('file')}
              setFile={(file) => {
                setValue('file', file as File);
              }}
              error={errors.file?.message}
              text={'Click or drag and drop to add a file'}
            />
          )}
          {SHARED_RESOURCE_TYPE === watch('type') && (
            <Controller
              control={control}
              render={({ field }) => (
                <Selector
                  {...field}
                  label={'Shared resource'}
                  id={'modal-shared-resources-selector'}
                  options={sharedResourcesQuery.data?.data.data.map((option) => ({
                    label: option.name,
                    value: option.id,
                  }))}
                />
              )}
              name={'shared'}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton
            type={'submit'}
            label={resourceMutation.isLoading ? 'Loading...' : 'Add Resource'}
            disabled={resourceMutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default V3ResourceModal;
