import React from 'react';
import { ROLE_LABELS } from '../../../resources/roles';
import { UserType } from '../userManagementPage';
import DefaultTable from '../../../global/table/defaultTable';

const ReviewUserTable = ({ user, multi, users }: { user?: UserType, multi?: boolean, users?: any }): JSX.Element => (
  <>
    <DefaultTable
      id={'user_info'}
      columns={[
        { id: 'name', label: 'Full Name' },
        { id: 'email', label: 'Email' },
        { id: 'role', label: 'Role' },
      ]}
      showCounter={false}
      rows={multi ? users?.map((user: UserType) => {
        return {
          id: `user.${user.id}`,
          cells: [
            { id: 'name', content: <strong>{`${user?.first_name} ${user?.last_name}`}</strong> },
            { id: 'email', content: user?.email },
            { id: 'role', content: ROLE_LABELS[user.role] },
          ]
        }
      }) : [
        {
          id: 'user',
          cells: [
            { id: 'name', content: <strong>{`${user?.first_name} ${user?.last_name}`}</strong> },
            { id: 'email', content: user?.email },
            { id: 'role', content: user && ROLE_LABELS[user.role] },
          ],
        },
      ]}
    /></>
);

export default ReviewUserTable;
