import React from 'react';
import { ProgressProps } from './progress.types';
import {
  ProgressContainer,
  SvgContainer,
  PathElement,
  ProgressNumber,
  ProgressLabel,
  PathBackground,
} from './progress.styled';

const OFFSET = 25;

const Progress = ({ progress = null, label, description }: ProgressProps) => {
  return (
    <div className={'w-full'}>
      <ProgressContainer $isNull={null === progress}>
        <ProgressNumber className={'font-display'}>
          <div className={'m-auto'}>{progress ?? '-'}%</div>
        </ProgressNumber>
        <ProgressLabel>
          <div className={'m-auto'}>{label}</div>
        </ProgressLabel>
        <SvgContainer viewBox="0 0 36 36">
          <PathBackground
            strokeDasharray={'100, 100'}
            strokeDashoffset={OFFSET}
            d={'M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831'}
          />
          <PathElement
            $color={null != progress ? 'primary' : 'muted'}
            strokeDasharray={progress ? `${(progress * 75) / 100}, 100` : '0, 100'}
            d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
          />
        </SvgContainer>
      </ProgressContainer>
      {description && (
        <div className={'flex italic md:-mt-8 p-2 md:p-0'}>
          <div className={'text-center md:text-left mx-auto md:mr-0 md:ml-auto md:w-2/4 md:pl-8'}>{description}</div>
        </div>
      )}
    </div>
  );
};

export default Progress;
