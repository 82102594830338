import React, { useState } from 'react';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { Section } from '../../../common';
import AddButton from '../../components/addButton';
import useModal from '../../../../hooks/useModal';
import Accordion from '../../../../global/accordion/accordion';
import { LinkStyled } from '../../../../global/link/link.styled';
import V3StrategyModal from '../../modals/v3/V3StrategyModal';
import { ActionButton } from '../../../../global/buttonIcon/common';
import DeleteV3StrategyModal from '../../modals/v3/DeleteV3StrategyModal';
import { useMutation, useQueryClient } from 'react-query';
import { downloadV3PathwayResource, shareV3PathwayStrategy } from '../../../../service/api';
import { PATHWAYS_V3 } from '../../../../service/queryKeys';
import Empty from '../../../../global/empty/empty';

const V3StrategiesSection = ({
  pathway,
  isDisabled,
  isFree,
}: {
  pathway: V3Pathway;
  isDisabled: boolean;
  isFree?: boolean;
}) => {
  const [strategyModal, toggleStrategyModal, setStrategyModalProps] = useModal((props: any) => (
    <V3StrategyModal {...props} />
  ));

  const [deleteStrategyModal, toggleDeleteStrategyModal, setDeleteStrategyModalProps] = useModal((props: any) => (
    <DeleteV3StrategyModal {...props} />
  ));

  const queryClient = useQueryClient();
  const shareV3StrategyMutation = useMutation(shareV3PathwayStrategy, {
    onSuccess: () => {
      queryClient.invalidateQueries([PATHWAYS_V3, pathway.id]);
    },
  });

  const [strategyAccordion, setStrategyAccordion] = useState<number | null>(null);

  return (
    <div>
      <div className={'flex justify-between mb-2'}>
        <span className={'font-bold pr-5 py-1'}>Strategies</span>
        {!isFree && (
          <AddButton
            onClick={() => {
              setStrategyModalProps({
                title: 'Add strategy',
                pathwayId: pathway.id,
                preventCloseOnClickOnMask: true,
              });

              toggleStrategyModal(true);
            }}
            label={'strategy'}
            hideLabel
            disabled={isDisabled}
          />
        )}
      </div>
      <Section>
        {pathway.strategies?.length === 0 ? (
          <Empty element={'strategies'} />
        ) : (
          pathway.strategies?.map((strategy, index) => (
            <Accordion
              key={index}
              isOpen={strategyAccordion === index}
              handleOpen={() =>
                strategyAccordion === index ? setStrategyAccordion(null) : setStrategyAccordion(index)
              }
              title={
                <div className={'flex items-center justify-between w-full'}>
                  <b>{strategy.name}</b>
                  {!isFree && (
                    <div className={'flex'}>
                      <ActionButton.Edit
                        className={'m-auto'}
                        onClick={(e) => {
                          e.stopPropagation();

                          setStrategyModalProps({
                            title: 'Edit strategy',
                            description: strategy.name,
                            pathwayId: pathway.id,
                            strategy: strategy,
                            preventCloseOnClickOnMask: true,
                          });

                          toggleStrategyModal(true);
                        }}
                        disabled={isDisabled}
                      />
                      {null != pathway.group && (
                        <ActionButton.Custom
                          buttonLabel={'Share'}
                          icon={'share'}
                          onClick={(e) => {
                            e.stopPropagation();

                            shareV3StrategyMutation.mutate({ pathwayId: pathway.id, strategyId: strategy.id });
                          }}
                          disabled={isDisabled}
                        />
                      )}
                      <ActionButton.Remove
                        className={'m-auto'}
                        onClick={(e) => {
                          e.stopPropagation();

                          setDeleteStrategyModalProps({
                            title: 'Delete strategy',
                            description: strategy.name,
                            pathwayId: pathway.id,
                            strategy: strategy,
                          });

                          toggleDeleteStrategyModal(true);
                        }}
                        disabled={isDisabled}
                      />
                    </div>
                  )}
                </div>
              }
            >
              <div className={'mb-2'}>
                <b className={'font-display'}>Description</b>
                <p>{strategy.description}</p>
              </div>
              <div className={'mb-2'}>
                <b className={'font-display'}>Working steps</b>
                <ul className={'list-decimal list-inside'}>
                  {strategy.steps.map((step, index) => (
                    <li key={`ingredient_list--${index}`}>{step}</li>
                  ))}
                </ul>
              </div>
              <div>
                <b className={'font-display'}>Resources</b>
                <ul className={'list-disc list-inside'}>
                  {strategy.resources.length > 0 ? (
                    strategy.resources.map((resource, index) => {
                      if (resource.type === 'file') {
                        return (
                          <li key={resource.id}>
                            <LinkStyled
                              key={`resource_list--${resource.id}`}
                              href={'#'}
                              onClick={(e) => {
                                e.preventDefault();

                                downloadV3PathwayResource(pathway!.id, resource!.id, resource.name);
                              }}
                            >
                              {resource.name}
                            </LinkStyled>
                          </li>
                        );
                      }

                      return (
                        <li key={resource.id}>
                          <LinkStyled key={`resource_list--${resource.id}`} href={resource.link} target={'_blank'}>
                            {resource.name}
                          </LinkStyled>
                        </li>
                      );
                    })
                  ) : (
                    <i className={'text-muted'}>No resources</i>
                  )}
                </ul>
              </div>
            </Accordion>
          ))
        )}
      </Section>
      {strategyModal}
      {deleteStrategyModal}
    </div>
  );
};

export default V3StrategiesSection;
