import {
  CLEAN_STATE,
  NEXT_PAGE,
  PREVIOUS_PAGE,
  SET_INITIAL_PAGE,
  SET_LINK,
  SET_STATE,
  SET_STEP,
  SUBMIT_FORM
} from './actions';
import {initialState, MAX_PAGE, RegistrationStateType, StepType} from './provider';

const reducer = (state: RegistrationStateType, action: Action): RegistrationStateType => {
  switch (action.type) {
    case SET_STEP: {
      return {
        ...state,
        [action.step]: {
          ...state[action.step],
          ...action.data,
        },
      };
    }
    case SET_STATE: {
      return {
        ...state,
        ...action.data,
      };
    }
    case CLEAN_STATE: {
      return {
        ...initialState,
      };
    }
    case PREVIOUS_PAGE: {
      return {
        ...state,
        currentPage: action.data
          ? action.data
          : state.currentPage >= MAX_PAGE
          ? MAX_PAGE
          : state.currentPage <= state.initialPage
          ? state.initialPage
          : state.currentPage - 1,
      };
    }
    case NEXT_PAGE: {
      return {
        ...state,
        currentPage: action.data ? action.data : state.currentPage >= MAX_PAGE ? MAX_PAGE : state.currentPage + 1,
      };
    }
    case SET_INITIAL_PAGE: {
      return {
        ...initialState,
        step1: { ...state.step1 },
        currentPage: action.data,
        initialPage: action.data,
      };
    }
    case SET_LINK: {
      return {
        ...state,
        link: action.data,
      };
    }
    case SUBMIT_FORM: {
      return {
        ...action.data,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

type Action = {
  type: string;
  data: any;
  step: StepType;
};

export default reducer;
