import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GLOBAL_ICONS, SIZE_ICON } from '../../resources/vars';
import {
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_LABELS,
  ROLE_MAT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_SYS_ADMIN,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
  ROLES,
  RolesType,
  SUBSCRIPTION_TYPE_PAID,
} from '../../resources/roles';
import { ColumnType } from '../../global/table/table.types';
import DashboardLayout from '../../layout/dashboard';
import useModal from '../../hooks/useModal';
import {
  ActiveStateConfirmModal,
  AddToTeamModal,
  AdminStateConfirmModal,
  DevelopmentActivitiesModal,
  UserFormModal,
  ViewSubscriptionInfoModal,
} from './modals';
import Icon from '../../global/icon/icon';
import Button from '../../global/button/button';
import Choice from '../../form/choice/choice';
import Tag from '../../global/tag/tag';
import DefaultTable from '../../global/table/defaultTable';
import Search from '../../form/search/search';
import { ActionButton } from '../../global/buttonIcon/common';
import { ArchivedName } from '../../global/table/components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  enableDisableCoordinatorMentoring,
  enableDisableTeacherMentor,
  getSchoolGroups,
  getUsersList,
  patchManuallyConfirmEmail,
  putUserGreatTeachingLead,
} from '../../service/api';
import CopyToClipboard from 'react-copy-to-clipboard';
import RemoveMatAdminPrivilegesModal from './modals/removeMatAdminPrivilegesModal';
import { Section } from '../common';
import { SCHOOLS_GROUPS, USER_MANAGEMENT_LIST } from '../../service/queryKeys';
import { Layout } from '../../global/table/components/archived';
import { AddButton, JoinButton } from '../../global/button/common';
import UpdateRoleModal from './modals/UpdateRoleModal';
import TransferSubscriptionModal from './modals/TransferSubscriptionModal';
import DeleteUserAccountModal from './modals/delete.userAccountModal';
import SchoolsSections from './sections/SchoolsSections';
import Accordion from '../../global/accordion/accordion';
import { impersonateUser } from '../../service/auth';
import AssignEarlyAccessGroupsModal from './modals/AssignEarlyAccessGroupsModal';
import { Checkbox } from 'pretty-checkbox-react';
import { SchoolGroupsDetailsTable } from '../groups/sections';
import { capitalize } from '../../helpers/misc';
import Selector from '../../form/select/selector';
import EditSchoolModal from './modals/EditSchoolModal';
import JoinSchoolToMatModal from './modals/JoinSchoolToMatModal';
import PreventAutoMATSchoolSubscriptionModal from './modals/PreventAutoMATSchoolSubscriptionModal';
import { Me } from '../../@types/Entity/Me';
import BulkUploadModal from './modals/BulkUploadModal';
import { sendRegistrationLinks } from '../../service/api/adminApi';
import { CREATE_ADMIN, CREATE_USER } from './modals/userFormModal';
import { AssociateCollege } from '../../@types/Entity/AssociateCollege';
import { Region } from '../../@types/Entity/Region';
import { teachersTemplateData } from '../../resources/starterTemplateData';
import VideoAlert from '../../guides/components/videoAlert';
import { emulatedElementType } from "@app/frontend/src/routes";

type RoleType =
  | typeof ROLE_SYS_ADMIN
  | typeof ROLE_MAT_COORDINATOR
  | typeof ROLE_SCHOOL_COORDINATOR
  | typeof ROLE_ASSISTANT_GT_COORDINATOR;

type GroupType = {
  name: string;
  id: string;
  mentee?: boolean;
  owner: { id: string; first_name: string; last_name: string };
  mentor: { id: string; first_name: string; last_name: string };
};

export type DevelopmentActivityProps = {
  term?: string;
  activity: string;
  activity_type: string;
  completed_at: string;
  id: string;
  element: string;
  pathway: string;
  pathway_step: string;
  start_date: string;
  user_id: string;
};

const userTypes = [
  { label: 'Coordinator', value: 'school' },
  { label: 'Group Admin', value: 'mat' },
  { label: 'Teacher', value: 'teacher' },
  { label: 'Individual', value: 'individual' },
  { label: 'Starter', value: 'starter' },
];

type UserColumnType = ColumnType & { forRole?: RoleType[] };

export type UserType = {
  upgraded?: boolean;
  bulk_subscription_id?: string;
  subscription_type?: string;
  id: string;
  first_name: string | '';
  last_name: string | '';
  email: string | '';
  organisation: string;
  role: RolesType;
  active: boolean;
  great_teaching_lead: boolean;
  slots?: number | null;
  schools: {
    id: string;
    name: string;
    slots: string;
    coordinator_name: string;
    coordinator_email: string;
  }[];
  school_id?: number;
  urn?: string;
  associated_coordinator?: string;
  teachers: Record<any, any>;
  token?: string;
  country?: string;
  timezone?: string;
  bulk_subscription: number;
  age_range: string[];
  category_1?: string;
  category_2?: string;
  category_3?: string;
  fsm: number | null;
  sen: number | null;
  ehcp: number | null;
  eal: number | null;
  role_description: string;
  role_type: string;
  role_other_type: string;
  mentoring: boolean | null;
  experience: number | null;
  year: number | null;
  qualifications: string;
  confirmed: boolean | null;
  subjects: Array<string> | null;
  early_access_groups?: Array<string>;
  element?: string;
  current_pathway_step?: string;
  team?: string;
  groups?: Array<GroupType>;
  development_activities?: Array<DevelopmentActivityProps>;
  school_has_mentoring: boolean | null;
  mentor: boolean | null;
  self_study: string | null;
  region?: Region | null;
  school_associate_colleges?: AssociateCollege[];
  associate_colleges?: AssociateCollege[];
  regions?: Region[];
  is_regional_lead?: boolean;
  is_niot?: boolean;
  is_associate_college?: boolean;
  trn?: string;
  is_induction_lead?: boolean;
  is_early_career_teacher?: boolean;
  last_login?: string;
  associate_college_10?: boolean;
  meetings_per_term?: {
    term_one_meetings: number;
    term_two_meetings: number;
    term_three_meetings: number;
  };
  niot_cohort: any;
  current_focus: any;
};

const UserManagementPage = ({ me, isFree, emulatedElement }: {
  me: Me;
  isFree?: boolean,
  emulatedElement?: emulatedElementType
}): JSX.Element => {
  const COLUMNS: UserColumnType[] = [
    { id: 'select', label: '', forRole: [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR] },
    { id: 'first_name', label: 'First name', isSortable: !isFree && true },
    { id: 'last_name', label: 'Last name', isSortable: !isFree && true },
    { id: 'email', label: 'Email', isSortable: !isFree && true },
    { id: 'organisation', label: 'Organisation', isSortable: true, forRole: [ROLE_SYS_ADMIN, ROLE_MAT_COORDINATOR] },
    { id: 'slots', label: 'Max. number of teachers', isSortable: true, forRole: [ROLE_MAT_COORDINATOR] },
    {
      id: 'role',
      label: 'Role',
      isSortable: true,
      forRole: [ROLE_SYS_ADMIN, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR],
    },
    {
      id: 'element',
      label: 'Element',
      forRole: [ROLE_SYS_ADMIN, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR],
    },
    {
      id: 'current_pathway_step',
      label: 'Development cycle step',
      forRole: [ROLE_SYS_ADMIN, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR],
    },
    {
      id: 'team',
      label: 'Team',
      forRole: [ROLE_SYS_ADMIN, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR, ROLE_TEACHER_FREE],
    },
    { id: 'actions', label: 'Actions' },
  ];

  const queryClient = useQueryClient();
  const [isSchoolAccordionOpen, setSchoolAccordionOpen] = useState(false);

  let localStorageDataValue = { searchRoles: [], searchValue: '' };

  if (!me?.impersonated) {
    const searchValue = localStorage.getItem('search-value');
    const searchRoles = localStorage.getItem('search-roles');
    localStorageDataValue = {
      searchRoles: searchRoles ? JSON.parse(searchRoles) : [],
      searchValue: searchValue ? searchValue : '',
    };
    localStorage.removeItem('search-value');
    localStorage.removeItem('search-roles');
  }

  const [show, setShow] = useState('active');
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [searchValue, setSearchValue] = useState(localStorageDataValue.searchValue);
  const [searchRoles, setSearchRoles] = useState<string[]>(localStorageDataValue.searchRoles);
  const filters =
    searchValue != '' || searchRoles.length > 0
      ? searchValue + '/' + searchRoles.map((role: any) => role.value).join(' ')
      : '';


  const [order, setOrder] = useState('');
  const [page, setPage] = useState(1);

  const [selectedUsers, setSelectedUsers] = useState<Array<UserType>>([]);
  const [includeArchivedTeams, setIncludeArchivedTeams] = useState<string>();
  const [schoolGroupsTableOrder, setSchoolGroupsTableOrder] = useState<string>();
  const [isBulkUploadModalOpen, setIsBulkUploadModalOpen] = useState<boolean>(false);

  const [selectAll, setSelectAll] = useState(false);

  const schoolGroupsQuery = useQuery(
    [SCHOOLS_GROUPS, includeArchivedTeams],
    () => getSchoolGroups(includeArchivedTeams),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
      keepPreviousData: true,
      enabled:
        me.great_teaching_lead || me.role === ROLE_SCHOOL_COORDINATOR || me.role === ROLE_ASSISTANT_GT_COORDINATOR,
    }
  );

  const usersQuery = useQuery(
    [USER_MANAGEMENT_LIST, order, filters, show, page],
    () => getUsersList({ order: order, filter: filters, show: show, page: page }),
    {
      select: (data) => data.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    schoolGroupsQuery.refetch();
  }, [schoolGroupsQuery.data]);

  useEffect(() => {
    usersQuery.refetch();
  }, [page]);

  useEffect(() => {
    setPage(1);
  }, [order, filters, show]);

  const updateGreatTeachingLead = useMutation(putUserGreatTeachingLead, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toast.success('User tagged as Great Teaching Lead');
    },
  });

  const manuallyConfirmEmail = useMutation(patchManuallyConfirmEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toast.success("User's account has been confirmed");
    },
  });

  const enableDisableCoordinatorMentoringMutation = useMutation(
    ({ id, action }: { id: string; action: 'enable' | 'disable' }) => enableDisableCoordinatorMentoring(id, action),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
        toast.success(`${'enable' === variables.action ? 'Granted' : 'Removed'} Mentor access`);
      },
    }
  );

  const enableDisableTeacherMentorMutation = useMutation(
    ({ id, action }: { id: string; action: 'enable' | 'disable' }) => enableDisableTeacherMentor(id, action),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
        toast.success(`${'enable' === variables.action ? 'Assigned' : 'Unassigned'} as Mentor`);
      },
    }
  );

  const sendRegistrationLinksMutations = useMutation(sendRegistrationLinks, {
    onSuccess: () => {
      toast.success('Registration links successfully sent');
      setSelectedUsers([]);
      setSelectAll(false);
    },
  });

  const [userFormModal, toggleUserFormModal, setUserFormModalProps] = useModal((props: any) => (
    <UserFormModal {...props} />
  ));
  const [viewModal, toggleViewModal, setViewModalProps] = useModal((props: any) => (
    <ViewSubscriptionInfoModal {...props} />
  ));
  const [activeStateModal, toggleActiveStateModal, setActiveStateModalProps] = useModal((props: any) => (
    <ActiveStateConfirmModal
      {...props}
      onSuccess={() => {
        setSelectedUsers([]);
      }}
    />
  ));
  const [adminStateModal, toggleAdminStateModal, setAdminStateModalProps] = useModal((props: any) => (
    <AdminStateConfirmModal {...props} />
  ));
  const [
    transferSubscriptionModal,
    toggleTransferSubscriptionModal,
    setTransferSubscriptionModalProps,
  ] = useModal((props: any) => <TransferSubscriptionModal {...props} />);
  const [updateRoleModal, toggleUpdateRoleModal, setUpdateRoleModalProps] = useModal((props: any) => (
    <UpdateRoleModal {...props} />
  ));

  const [deleteUserModal, toggleDeleteUserModal, setDeleteUserModalProps] = useModal((props: any) => (
    <DeleteUserAccountModal {...props} />
  ));

  const [editSchoolModal, toggleEditSchoolModal, setEditSchoolModalProps] = useModal(
    (props: any) => <EditSchoolModal {...props} />,
    {
      title: 'Edit school',
    }
  );

  const [
    removeMatAdminPrivilegesModal,
    toggleRemoveMatAdminPrivilegesModal,
    setRemoveMatAdminPrivilegesModalProps,
  ] = useModal((props: any) => <RemoveMatAdminPrivilegesModal {...props} />);

  const [
    assignEarlyAccessGroupsModal,
    toggleAssignEarlyAccessGroupsModal,
    setAssignEarlyAccessGroupsModalProps,
  ] = useModal((props: any) => <AssignEarlyAccessGroupsModal {...props} />, {
    title: 'Assign user to early access groups',
    size: 'lg',
  });

  const [developmentActivitiesModal, toggleDevelopmentActivitiesModal, setDevelopmentActivitiesProps] = useModal(
    (props: any) => <DevelopmentActivitiesModal {...props} />,
    {
      title: 'Development activities',
      size: 'xlg',
      preventCloseOnClickOnMask: true,
    }
  );

  const [addToTeamModal, toggleAddToTeamModal, setAddToTeamProps] = useModal(
    (props: any) => (
      <AddToTeamModal
        {...props}
        onSuccess={() => {
          setSelectedUsers([]);
        }}
      />
    ),
    {
      title: 'Add to Team',
      size: 'lg',
    }
  );

  const [joinSchoolToMatModal, toggleJoinSchoolToMatModal, setJoinSchoolToMatProps] = useModal(
    (props: any) => <JoinSchoolToMatModal {...props} />,
    {
      title: 'Add school to existing group (MAT)',
    }
  );

  const [cancelMatSubscriptionModal, toggleCancelMatSubscriptionModal, setCancelMatSubscriptionProps] = useModal(
    (props: any) => <PreventAutoMATSchoolSubscriptionModal {...props} />,
    {
      title: 'Cancel group (MAT) subscription',
    }
  );

  return (
    <React.Fragment>
      {ROLE_SYS_ADMIN === me.role && assignEarlyAccessGroupsModal}
      {ROLE_SYS_ADMIN === me.role && editSchoolModal}
      {ROLE_SYS_ADMIN === me.role && userFormModal}
      {ROLE_SYS_ADMIN === me.role && transferSubscriptionModal}
      {ROLE_SYS_ADMIN === me.role && joinSchoolToMatModal}
      {ROLE_SYS_ADMIN === me.role && cancelMatSubscriptionModal}
      {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && updateRoleModal}
      {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && developmentActivitiesModal}
      {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && addToTeamModal}
      {viewModal}
      {activeStateModal}
      {[ROLE_MAT_COORDINATOR, ROLE_SYS_ADMIN].includes(me.role) && adminStateModal}
      {ROLE_SYS_ADMIN === me.role && removeMatAdminPrivilegesModal}
      {ROLE_SYS_ADMIN === me.role && deleteUserModal}

      {isBulkUploadModalOpen && <BulkUploadModal toggle={() => setIsBulkUploadModalOpen(false)}/>}

      {/* Content */}
      <DashboardLayout
        title={
          ROLE_SYS_ADMIN === me.role
            ? 'User Management'
            : ROLE_MAT_COORDINATOR === me.role
              ? 'Coordinators'
              : 'Teachers'
        }
        subtitle={
          isFree ? undefined : (
            <Tag
              className={'mt-3'}
              mainColor={'info'}
              content={`${ROLE_LABELS[me.role]}${
                [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role)
                  ? ` - ${me.organisation}`
                  : ''
              }`}
            />
          )
        }
        pageAction={
          ROLE_SYS_ADMIN === me.role && (
            <div className={'flex gap-2'}>
              <AddButton
                label={'Add admin user'}
                onClick={() => {
                  setUserFormModalProps({
                    title: 'Add new admin',
                    createMode: CREATE_ADMIN,
                  });
                  toggleUserFormModal(true);
                }}
              />
              <AddButton
                label={'Add new user'}
                onClick={() => {
                  setUserFormModalProps({
                    title: 'Add new user',
                    createMode: CREATE_USER,
                  });
                  toggleUserFormModal(true);
                }}
              />
              <AddButton label={'Bulk upload'} onClick={() => setIsBulkUploadModalOpen(true)}/>
            </div>
          )
        }
      >
        {[ROLE_MAT_COORDINATOR, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && (
          <Section size={'md'} className={'field-mb'}>
            <div className={'flex items-center gap-5 sm-field-mb'}>
              <h5>Registration link and instructions</h5>
              <CopyToClipboard
                text={
                  [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role)
                    ? `<div>
                    <p>This year, our CPD plans will involve the Great Teaching Toolkit – an online platform we can use to work together to further develop our teaching practice.</p>
                    <p>Here's what you need to do to access the platform:</p>
                    <ul>
                        <li>
                        <strong>Step 1:</strong> Please click on this <a href=${me.link} target={'_blank'}>link</a> to create your account.</li>
                        <li><strong>Step 2:</strong> Once you have created your account, you'll be able to log in <a href="https://toolkit.greatteaching.com/login" target={'_blank'}>here</a>. We recommend saving this email for future reference.</li>
                    </ul>
                </div>`
                    : `<div>
                    <p>As part of our CPD plans for this year, we have access to the Great Teaching Toolkit.</p>
                    <p>You have been assigned as a Coordinator for your school, so here's what you need to do to get started:</p>
                    <ul>
                        <li><strong>Step 1:</strong> Please click on this <a href=${me.link} target={'_blank'}>link</a> to set up your school's account.</li>
                        <li><strong>Step 2:</strong> Once you have created your account, you'll see a pop-up guide that'll show you around the GTT platform. This guide will also show you how to access the link to share with colleagues so that they can all register with your school and get started.</li>
                    </ul>
                </div>`
                }
                onCopy={() => toast.success('Registration instructions have been copied')}
                options={{ format: 'text/html' }}
              >
                <Button id={'stonly_copy_link'} isOutline size={'sm'}>
                  Copy signup instructions
                </Button>
              </CopyToClipboard>
            </div>
            <p>
              {ROLE_MAT_COORDINATOR === me.role &&
                'Share these instructions with school leads so they can correctly register their school.'}
              {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) &&
                'Share these instructions with the relevant teachers at your organisation.'}
            </p>
            {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && (
              <div className={'flex items-center gap-5 border-light border-t pt-3 mt-3'}>
                <p>
                  <strong>Maximum number of teachers:</strong> {me.slots}
                </p>
              </div>
            )}
          </Section>
        )}
        {ROLE_MAT_COORDINATOR === me.role && (
          <Section size={'md'} className={'field-mb'}>
            <Accordion
              title={'Schools'}
              isOpen={isSchoolAccordionOpen}
              handleOpen={() => setSchoolAccordionOpen((isOpen) => !isOpen)}
            >
              <SchoolsSections me={me}/>
            </Accordion>
          </Section>
        )}

        {isFree && (
          <div>
            <VideoAlert
              setVideo={undefined}
              showHideForever={false}
              showHide={false}
              starterTemplate={isFree}
              data={{
                title: '',
                id: 'starter_template_teachers',
                description: (
                  <>
                    <p className={'mb-4'}>
                      This part of the platform can only be seen by a School Coordinator or Assistant Coordinator.
                    </p>
                    <p>
                      Here, you can share a link for your colleagues to enrol, add colleagues to a Great Teaching Team,
                      and see which elements have been chosen as development areas.
                    </p>
                  </>
                ),
              }}
            />
          </div>
        )}

        <Section>
          <div className={'flex flex-wrap justify-between items-center mb-5'}>
            <div className={'lg:flex lg:flex-row row-auto w-full gap-4 mb-8'}>
              <div className={'lg:w-1/2 w-full mb-3 lg:mb-0'}>
                <Search
                  id={'search_user'}
                  label={'Search User / Organisation'}
                  disabled={isFree}
                  hideLabel={false}
                  placeholder={'Search for name, email or organisation'}
                  value={searchValue}
                  onChange={(event) => {
                    setSearchValue(event.target.value);
                  }}
                  onRemove={() => {
                    setSearchValue('');
                  }}
                />
              </div>
              {ROLE_SYS_ADMIN === me.role && (
                <div className={'lg:w-1/4 w-full mb-3 lg:mb-0'}>
                  <Selector
                    isMulti
                    id={'userType'}
                    label={'Select User Roles'}
                    placeholder={'Please select a user type'}
                    value={searchRoles}
                    options={userTypes}
                    onChange={(options: any) => {
                      setSearchRoles(options);
                    }}
                    isClearable={false}
                  />
                </div>
              )}
              <div className={'lg:w-1/4 w-full mb-3 lg:mb-0'}>
                <div className={'float-right'}>
                  <Choice
                    id={'deactivated-accounts'}
                    disabled={isFree}
                    label={'View deactivated accounts'}
                    type={'switch'}
                    className={'pretty-no-margin'}
                    checked={show !== 'active'}
                    onChange={() => {
                      setShow(show === 'active' ? 'deactivated' : 'active');
                      setSelectedUsers([]);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && (
            <>
              <div className={'text-right mb-4'}>
                <Button
                  disabled={0 === selectedUsers.length || show === 'deactivated'}
                  size={'sm'}
                  onClick={() => {
                    setAddToTeamProps({
                      users: [...selectedUsers],
                    });
                    toggleAddToTeamModal(true);
                  }}
                >
                  Add to team
                </Button>
              </div>
              <Choice
                id={'advanced-options'}
                label={'Advanced options'}
                type={'switch'}
                className={'mb-4'}
                checked={showAdvanced}
                onChange={() => setShowAdvanced(!showAdvanced)}
              />
              {showAdvanced && (
                <div className={'flex my-4 gap-4'}>
                  <Button
                    disabled={
                      selectedUsers.length < 2 ||
                      selectedUsers.map((user: any) => user.role).includes(ROLE_SCHOOL_COORDINATOR)
                    }
                    size={'sm'}
                    onClick={() => {
                      setActiveStateModalProps({
                        users: [...selectedUsers],
                        title: show === 'active' ? 'Deactivate accounts' : 'Activate accounts',
                        description: 'Are you sure?',
                        multi: true,
                        active: show === 'active',
                        preventCloseOnClickOnMask: true,
                      });
                      toggleActiveStateModal(true);
                    }}
                  >
                    {' '}
                    {show === 'active' ? 'Dea' : 'A'}ctivate users
                  </Button>
                </div>
              )}
            </>
          )}
          {me.role === ROLE_SYS_ADMIN && (
            <div className={'flex justify-end mb-4'}>
              <Button
                size={'sm'}
                className={'flex items-center'}
                id={'selectTeamButton'}
                onClick={() => {
                  sendRegistrationLinksMutations.mutate(selectedUsers.map((user) => user.id));
                }}
                disabled={selectedUsers.length === 0 || selectedUsers.length > 299}
              >
                Send registration link
              </Button>
            </div>
          )}
          <DefaultTable
            isFree={isFree}
            apiHandled={true}
            setApiSort={setOrder}
            id={'user_management'}
            itemCounter={{
              single: 'user',
              plural: 'users',
            }}
            loading={!isFree ? false : usersQuery.isFetching}
            pagination={{
              currentPage: page,
              onPageChange: ({ selected }: { selected: number }) => setPage(selected + 1),
              total: isFree ? 1 : usersQuery.data?.meta?.total,
              maxPage: isFree ? 1 : usersQuery.data?.meta?.max_page,
            }}
            columns={COLUMNS.filter((col) => {
              if (col.id === 'select' && [ROLE_SCHOOL_COORDINATOR, ROLE_SYS_ADMIN].includes(me.role)) {
                col.label = (
                  <div className={'pl-4'}>
                    <Checkbox
                      style={{ fontSize: 19 }}
                      color={'primary'}
                      checked={selectAll && selectedUsers.length > 0}
                      onChange={() => {
                        if (selectAll) {
                          setSelectedUsers([]);
                          setSelectAll(false);
                        } else {
                          setSelectedUsers(
                            me.role === ROLE_SCHOOL_COORDINATOR
                              ? usersQuery.data!.data.users.filter((user) => user.role != ROLE_SCHOOL_COORDINATOR)
                              : usersQuery.data!.data.users
                          );
                          setSelectAll(true);
                        }
                      }}
                    />
                  </div>
                );
                return col;
              }
              if (!col.forRole || (col.forRole && col.forRole.some((colRole) => colRole === me.role))) {
                return col;
              }
            })}
            filteredBySearch={!!searchValue}
            rows={(isFree ? teachersTemplateData(emulatedElement).data.users : usersQuery.data?.data?.users ?? []).map((user) => {
              return {
                id: `${user.id}`,
                class: !user.active ? 'archived' : '',
                cells: [
                  ...([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SYS_ADMIN].includes(me.role)
                    ? [
                      {
                        id: 'select',
                        content: (
                          <div className={'pl-4'}>
                            <Checkbox
                              color={'primary'}
                              onChange={(event) => {
                                if (user.role != ROLE_SCHOOL_COORDINATOR) {
                                  setSelectAll(false);
                                }
                                if (event.target.checked) {
                                  setSelectedUsers([...selectedUsers, user]);
                                  return;
                                }

                                setSelectedUsers(selectedUsers.filter((selectedUser) => selectedUser.id !== user.id));
                              }}
                              checked={selectedUsers.map((u: any) => u.id).includes(user.id)}
                            />
                          </div>
                        ),
                      },
                    ]
                    : []),
                  {
                    id: 'first_name',
                    content: (
                      <ArchivedName
                        admin={ROLE_SCHOOL_COORDINATOR === user.role}
                        archived={!user.active}
                        title={user.first_name}
                        item={'user'}
                        type={'deactivated'}
                      />
                    ),
                  },
                  {
                    id: 'last_name',
                    content: user.last_name,
                  },
                  {
                    id: 'email',
                    content: user.email,
                  },
                  ...(me.role === ROLE_TEACHER_FREE
                    ? [
                      {
                        id: 'team',
                        content: user.groups?.map((group) => group).join(', '),
                      },
                    ]
                    : []),
                  ...([ROLE_SYS_ADMIN, ROLE_MAT_COORDINATOR].includes(me.role)
                    ? [
                      {
                        id: 'organisation',
                        content: user.organisation,
                      },
                    ]
                    : []),
                  ...([ROLE_SYS_ADMIN, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role)
                    ? [
                      {
                        id: 'role',
                        content: (
                          <Layout
                            title={
                              ROLE_SYS_ADMIN === me.role && user.role === ROLE_TEACHER && !user.bulk_subscription_id
                                ? `Individual (${user.upgraded ? 'u' : 'd'})`
                                : ROLE_LABELS[user.role]
                            }
                            tagged={user.great_teaching_lead}
                            item={'user'}
                          />
                        ),
                      },
                      {
                        id: 'element',
                        content: user.element,
                      },
                      {
                        id: 'current_pathway_step',
                        content: capitalize(user.current_pathway_step ?? ''),
                      },
                      {
                        id: 'team',
                        content: user.groups?.map((group) => group.name).join(', '),
                      },
                    ]
                    : []),
                  ...(me.role === ROLE_MAT_COORDINATOR
                    ? [
                      {
                        id: 'max',
                        content: user.slots,
                      },
                    ]
                    : []),
                  {
                    id: 'actions',
                    class: 'actions',
                    content: (
                      <ul className={'actions-list'}>
                        {ROLE_SYS_ADMIN === me.role && (
                          <li>
                            <ActionButton.Edit
                              archived={!user.active}
                              onClick={() => {
                                setUserFormModalProps({
                                  userToEdit: { ...user },
                                  title: 'Edit this user',
                                  preventCloseOnClickOnMask: true,
                                  description: (
                                    <ArchivedName
                                      tagged={user.great_teaching_lead}
                                      archived={!user.active}
                                      title={`${user.first_name} ${user.last_name}`}
                                      item={'user'}
                                      type={'deactivated'}
                                    />
                                  ),
                                });
                                toggleUserFormModal(true);
                              }}
                            />
                          </li>
                        )}
                        {ROLE_SYS_ADMIN === me.role && (
                          <li>
                            <ActionButton.View
                              disabled={!(ROLE_MAT_COORDINATOR === user.role || ROLE_SCHOOL_COORDINATOR === user.role)}
                              archived={!user.active}
                              onClick={() => {
                                setViewModalProps({
                                  user: { ...user },
                                  title: 'View subscription info',
                                  description: (
                                    <ArchivedName
                                      tagged={user.great_teaching_lead}
                                      archived={!user.active}
                                      title={`${user.first_name} ${user.last_name} - ${
                                        ROLES.filter((role) => role.value === user.role)[0].label
                                      }`}
                                      item={'user'}
                                      type={'deactivated'}
                                    />
                                  ),
                                  preventCloseOnClickOnMask: true,
                                });
                                toggleViewModal(true);
                              }}
                            />
                          </li>
                        )}
                        {ROLE_SYS_ADMIN === me.role && (
                          <li>
                            <ActionButton.Custom
                              buttonLabel={'Manually confirm user account'}
                              icon={'user'}
                              disabled={user.confirmed === true}
                              archived={!user.active}
                              onClick={() => manuallyConfirmEmail.mutate(user.id)}
                            />
                          </li>
                        )}
                        <li>
                          <ActionButton.More
                            disabled={isFree || user.id === me.id}
                            label={
                              <ul className={'more-actions-list'}>
                                {ROLE_SYS_ADMIN === me.role && (
                                  <li>
                                    <button
                                      onClick={() => {
                                        impersonateUser(user.email, searchRoles, searchValue);
                                      }}
                                    >
                                      <span>
                                        <strong>Emulate</strong>
                                      </span>{' '}
                                      <Icon
                                        icon={GLOBAL_ICONS.user}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                )}
                                {ROLE_SYS_ADMIN === me.role && user.role !== ROLE_SYS_ADMIN && (
                                  <li>
                                    <button
                                      onClick={() => {
                                        setAssignEarlyAccessGroupsModalProps({
                                          user,
                                          description: `${user.first_name} ${user.last_name}`,
                                        });

                                        toggleAssignEarlyAccessGroupsModal(true);
                                      }}
                                    >
                                      <span>
                                        <strong>Assign</strong> user to early access groups
                                      </span>{' '}
                                      <Icon
                                        icon={GLOBAL_ICONS.user}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                )}
                                {ROLE_SYS_ADMIN === me.role &&
                                  SUBSCRIPTION_TYPE_PAID === user.subscription_type &&
                                  (ROLE_MAT_COORDINATOR === user.role ||
                                    ROLE_SCHOOL_COORDINATOR === user.role ||
                                    (ROLE_TEACHER === user.role && user.bulk_subscription_id)) && (
                                    <li>
                                      <button
                                        onClick={() => {
                                          setTransferSubscriptionModalProps({
                                            user,
                                            title: 'Transfer subscription',
                                          });
                                          toggleTransferSubscriptionModal(true);
                                        }}
                                      >
                                        <span>
                                          <strong>Transfer</strong> subscription
                                        </span>{' '}
                                        <Icon
                                          icon={GLOBAL_ICONS.calendar}
                                          elementSize={SIZE_ICON['sm']}
                                          color={'primary'}
                                          container={false}
                                          className={'ml-3'}
                                        />
                                      </button>
                                    </li>
                                  )}
                                {ROLE_SYS_ADMIN === me.role &&
                                  SUBSCRIPTION_TYPE_PAID === user.subscription_type &&
                                  ROLE_SCHOOL_COORDINATOR === user.role && (
                                    <li>
                                      <button
                                        onClick={() => {
                                          setJoinSchoolToMatProps({ user });
                                          toggleJoinSchoolToMatModal(true);
                                        }}
                                      >
                                        <span>
                                          <strong>Add school</strong> to existing group (MAT)
                                        </span>{' '}
                                        <Icon
                                          icon={'HouseDoorFill'}
                                          elementSize={SIZE_ICON['sm']}
                                          color={'primary'}
                                          container={false}
                                          className={'ml-3'}
                                        />
                                      </button>
                                    </li>
                                  )}
                                {ROLE_SYS_ADMIN === me.role && ROLE_MAT_COORDINATOR === user.role && (
                                  <li>
                                    <button
                                      onClick={() => {
                                        setCancelMatSubscriptionProps({ user });
                                        toggleCancelMatSubscriptionModal(true);
                                      }}
                                    >
                                      <span>
                                        <strong>Cancel</strong> group (MAT) subscription
                                      </span>{' '}
                                      <Icon
                                        icon={'CalendarXFill'}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                )}
                                {ROLE_SYS_ADMIN === me.role && ROLE_SCHOOL_COORDINATOR === user.role && (
                                  <li>
                                    <button
                                      onClick={() => {
                                        setEditSchoolModalProps({ user });
                                        toggleEditSchoolModal(true);
                                      }}
                                    >
                                      <span>
                                        <strong>Edit</strong> school
                                      </span>{' '}
                                      <Icon
                                        icon={'HouseFill'}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                )}
                                {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) &&
                                  [ROLE_TEACHER, ROLE_ASSISTANT_GT_COORDINATOR].includes(user.role) && (
                                    <li>
                                      <button
                                        onClick={() => {
                                          setUpdateRoleModalProps({
                                            user,
                                            title: 'Change role',
                                          });
                                          toggleUpdateRoleModal(true);
                                        }}
                                      >
                                        <span>
                                          <strong>Change</strong> role
                                        </span>{' '}
                                        <Icon
                                          icon={GLOBAL_ICONS.admin}
                                          elementSize={SIZE_ICON['sm']}
                                          color={'primary'}
                                          container={false}
                                          className={'ml-3'}
                                        />
                                      </button>
                                    </li>
                                  )}
                                <li>
                                  <button
                                    onClick={() => {
                                      updateGreatTeachingLead.mutate({
                                        user_id: user.id,
                                        great_teaching_lead: !user.great_teaching_lead,
                                      });
                                    }}
                                  >
                                    <span>
                                      <strong>{!user.great_teaching_lead ? 'Tag' : 'Untag'} user</strong> as Great
                                      Teaching Lead
                                    </span>
                                    <Icon
                                      icon={GLOBAL_ICONS.tagged}
                                      elementSize={SIZE_ICON['sm']}
                                      color={'primary'}
                                      container={false}
                                      className={'ml-3'}
                                    />
                                  </button>
                                </li>
                                {ROLE_SYS_ADMIN === me.role && ROLE_SCHOOL_COORDINATOR === user.role && (
                                  <li>
                                    <button
                                      onClick={() => {
                                        enableDisableCoordinatorMentoringMutation.mutate({
                                          id: user.id,
                                          action: !user.school_has_mentoring ? 'enable' : 'disable',
                                        });
                                      }}
                                    >
                                      <span>
                                        <strong>{!user.school_has_mentoring ? 'Grant' : 'Remove'} user</strong> Mentor
                                        Access
                                      </span>
                                      <Icon
                                        icon={GLOBAL_ICONS.tagged}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                )}
                                {ROLE_SCHOOL_COORDINATOR === me.role &&
                                  me.school_has_mentoring &&
                                  ROLE_TEACHER === user.role && (
                                    <li>
                                      <button
                                        onClick={() => {
                                          enableDisableTeacherMentorMutation.mutate({
                                            id: user.id,
                                            action: !user.mentor ? 'enable' : 'disable',
                                          });
                                        }}
                                      >
                                        <span>
                                          <strong>{!user.mentor ? 'Assign' : 'Unassign'} user</strong> as Mentor
                                        </span>
                                        <Icon
                                          icon={GLOBAL_ICONS.tagged}
                                          elementSize={SIZE_ICON['sm']}
                                          color={'primary'}
                                          container={false}
                                          className={'ml-3'}
                                        />
                                      </button>
                                    </li>
                                  )}
                                {ROLE_SYS_ADMIN === me.role && ROLE_MAT_COORDINATOR === user.role && (
                                  <li>
                                    <button
                                      onClick={() => {
                                        setRemoveMatAdminPrivilegesModalProps({
                                          user: { ...user },
                                          title: 'Remove Group Admin privileges',
                                          description: 'Are you sure?',
                                        });
                                        toggleRemoveMatAdminPrivilegesModal(true);
                                      }}
                                    >
                                      <span>
                                        <strong>Remove</strong> Group admin privileges
                                      </span>{' '}
                                      <Icon
                                        icon={GLOBAL_ICONS.admin}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                )}
                                {(ROLE_MAT_COORDINATOR === me.role || ROLE_SYS_ADMIN === me.role) &&
                                  ROLE_SCHOOL_COORDINATOR === user.role && (
                                    <li>
                                      <button
                                        onClick={() => {
                                          setAdminStateModalProps({
                                            user: { ...user },
                                            title: 'Change Great Teaching Coordinator',
                                          });
                                          toggleAdminStateModal(true);
                                        }}
                                      >
                                        <span>
                                          <strong>Change</strong> Great Teaching Coordinator
                                        </span>{' '}
                                        <Icon
                                          icon={GLOBAL_ICONS.admin}
                                          elementSize={SIZE_ICON['sm']}
                                          color={'primary'}
                                          container={false}
                                          className={'ml-3'}
                                        />
                                      </button>
                                    </li>
                                  )}
                                {[ROLE_SYS_ADMIN, ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(
                                  me.role
                                ) && (
                                  <li>
                                    <button
                                      onClick={() => {
                                        setActiveStateModalProps({
                                          user: { ...user },
                                          title: user.active ? 'Deactivate account' : 'Activate account',
                                          description: 'Are you sure?',
                                        });
                                        toggleActiveStateModal(true);
                                      }}
                                    >
                                      <span>
                                        <strong>{!user.active ? 'Activate' : 'Deactivate'}</strong> account
                                      </span>
                                      <Icon
                                        icon={GLOBAL_ICONS.deactivated}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                )}
                                {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.role) && (
                                  <li>
                                    <button
                                      onClick={() => {
                                        setDevelopmentActivitiesProps({
                                          development_activities: user.development_activities ?? [],
                                          preventCloseOnClickOnMask: true,
                                        });
                                        toggleDevelopmentActivitiesModal(true);
                                      }}
                                    >
                                      <span>
                                        <strong>Development activities</strong>
                                      </span>
                                      <Icon
                                        icon={GLOBAL_ICONS.plan}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                )}
                                {ROLE_SYS_ADMIN === me.role && (
                                  <li>
                                    <button
                                      onClick={() => {
                                        setDeleteUserModalProps({
                                          user: { ...user },
                                          title: 'Delete account',
                                          description: "Are you sure you'd like to permanently delete this user?",
                                        });
                                        toggleDeleteUserModal(true);
                                      }}
                                    >
                                      <span>
                                        <strong>Delete</strong> account
                                      </span>
                                      <Icon
                                        icon={GLOBAL_ICONS.remove}
                                        elementSize={SIZE_ICON['sm']}
                                        color={'primary'}
                                        container={false}
                                        className={'ml-3'}
                                      />
                                    </button>
                                  </li>
                                )}
                              </ul>
                            }
                            archived={!user.active}
                          />
                        </li>
                      </ul>
                    ),
                  },
                ],
              };
            })}
            noDataMessage={
              <>
                {me.role === ROLE_SYS_ADMIN
                  ? 'Start adding users clicking on "Add user" button.'
                  : 'No users to manage.'}
                {0 < (usersQuery.data?.data?.users?.length ?? 0) && (
                  <p>
                    <strong>You have deactivated accounts.</strong>{' '}
                    <Button
                      onClick={() => setShow(show === 'active' ? 'deactivated' : 'active')}
                      asLink
                      style={{ fontStyle: 'italic', padding: 5 }}
                    >
                      Click here
                    </Button>{' '}
                    to see them.
                  </p>
                )}
              </>
            }
          />
        </Section>
        {(me.great_teaching_lead || me.role === ROLE_SCHOOL_COORDINATOR || me.role === ROLE_ASSISTANT_GT_COORDINATOR) && (
            <Section
              className={'mt-5'}
              headline={'School teams'}
              more={
                0 < (schoolGroupsQuery.data?.length ?? 0)
                  ? `${schoolGroupsQuery.data!.length} group${1 !== schoolGroupsQuery.data!.length ? 's' : ''}`
                  : ''
              }
            >
              <SchoolGroupsDetailsTable
                data={schoolGroupsQuery.data!}
                showDisabled={includeArchivedTeams === 'disabled'}
                setOrder={setSchoolGroupsTableOrder}
                handleShowDisabled={() =>
                  includeArchivedTeams === 'disabled'
                    ? setIncludeArchivedTeams(undefined)
                    : setIncludeArchivedTeams('disabled')
                }
              />
            </Section>
          )}
      </DashboardLayout>
    </React.Fragment>
  );
};

export default UserManagementPage;
