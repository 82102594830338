import React from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { UserType } from '../../users/userManagementPage';
import Input from '../../../form/input/input';
import Button from '../../../global/button/button';
import RadioGroup from '../../../form/choice/radioGroup';
import { useMutation, useQueryClient } from 'react-query';
import { putMe } from '../../../service/api';
import * as Yup from 'yup';
import { Section } from '../../common';
import { ME } from '../../../service/queryKeys';
import { ROLE_OPTIONS } from '../../auth/Registration/sections/step4/TeacherForm';

const TeacherDetailsForm = ({
  user,
}: Record<'user', Pick<UserType, 'role_type' | 'qualifications' | 'role_other_type'>>) => {
  const queryClient = useQueryClient();

  const meMutation = useMutation(putMe, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME);
      toast.success('Your teacher details has been changed');
    },
  });

  const isOtherSelected = ROLE_OPTIONS.filter((option) => option.value !== 'Other').every(
    (option) => option.value !== user.role_type && '' !== user.role_type && null != user.role_type
  );

  return (
    <Section headline={'Teacher details'}>
      <Formik
        initialValues={{
          role_type: isOtherSelected ? 'Other' : user.role_type ?? '',
          role_other_type: isOtherSelected ? user.role_type : '',
          qualifications: user.qualifications ?? '',
        }}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          role_type: Yup.string().trim().required(),
          role_other_type: Yup.string().when('role_type', { is: 'Other', then: Yup.string().trim().required() }),
          qualifications: Yup.string().trim().required(),
        })}
        onSubmit={(values) =>
          meMutation.mutate({
            role_type: values.role_type === 'Other' ? values.role_other_type : values.role_type,
            qualifications: values.qualifications,
          })
        }
      >
        {({ values, setFieldValue, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <div className={'field-mb'}>
              <RadioGroup
                id={'role_type'}
                label={'Which of these best describes your role type?'}
                options={ROLE_OPTIONS}
                isGroupRequired
                optionChecked={values.role_type}
                onChange={(e: any) => {
                  if ('Other' !== e.target.value) {
                    setFieldValue('role_other_type', '');
                  }

                  handleChange(e);
                }}
              />
              {ROLE_OPTIONS.filter((option) => option.value !== 'Other').every(
                (option) => option.value !== values.role_type && '' !== values.role_type && null != values.role_type
              ) && (
                <div className={'animate-fade-in mt-5'}>
                  <Input
                    id={'role_other_type'}
                    label={'Other role type'}
                    placeholder={'Please add other option'}
                    hideLabel
                    value={values.role_other_type}
                    onChange={handleChange}
                    autoFocus
                  />
                </div>
              )}
            </div>
            <div className={'field-mb'}>
              <Input
                id={'qualifications'}
                label={'What teaching or related qualifications do you have?'}
                placeholder={'Please enter your qualification information'}
                required
                value={values.qualifications}
                onChange={handleChange}
              />
            </div>
            <Button type={'submit'} className={'mt-5'} disabled={!isValid || meMutation.isLoading}>
              {!meMutation.isLoading ? 'Update' : 'Loading...'}
            </Button>
          </form>
        )}
      </Formik>
    </Section>
  );
};

export default TeacherDetailsForm;
