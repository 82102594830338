import React from 'react';
import routeBuilder from '../../service/routeBuilder';
import { useQuery } from 'react-query';
import { getMe } from '../../service/api';
import { SectionType } from './pathwaysPage.types';
import DashboardLayout from '../../layout/dashboard';
import ScrollSpy from '../../global/scrollSpy/scrollSpy';
import { RouterStyled } from '../../global/link/link.styled';
import PathwayView from './views/PathwayView';
import { ME } from '../../service/queryKeys';
import { ROLE_TEACHER_FREE } from '../../resources/roles';
import FreePage from '@app/asset/images/Pathways.png';

const PathwaysPage = () => {
  const { data: me } = useQuery(ME, getMe, {
    select: (data) => data.data.data.me,
    placeholderData: {},
    staleTime: Infinity,
  });

  if (me.role === ROLE_TEACHER_FREE) {
    return (
      <DashboardLayout
        isMockedPage={true}
        mockedAlert={
          'This is an example pathway. Pathways are a feature of the paid GTT subscription that allow you to set your development goals and manage your progress toward meeting them.'
        }
      >
        <img src={FreePage} alt={'Pathways'} width={'100%'} height={'auto'} />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout
      title={'Development cycles'}
      pageAction={<RouterStyled to={routeBuilder('pathwaysHistory').generate()}>View pathways history</RouterStyled>}
    >
      <div className={'flex'}>
        <div className={'lg:w-5/6'}>
          <PathwayView me={me} />
        </div>
        <div className={'hidden lg:block lg:w-1/6 pl-10'}>
          <ScrollSpy
            options={
              [
                { value: 'header', label: 'Steps & Log' },
                { value: 'goals', label: 'Goals' },
                { value: 'cycles', label: 'Cycles' },
              ] as { value: SectionType; label: string }[]
            }
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default PathwaysPage;
