import React, { HTMLAttributes } from 'react';
import { TagProps } from './tag.types';
import { TagStyled } from './tag.styled';
import Icon from '../icon/icon';
import { SIZE_ICON } from '../../resources/vars';

const Tag = React.forwardRef<HTMLDivElement, TagProps & HTMLAttributes<HTMLDivElement>>(
  ({ mainColor = 'light', size = 'md', isPill = false, content, icon, ...other }: TagProps, ref): JSX.Element => (
    <TagStyled ref={ref} $size={size} $color={mainColor} $isPill={isPill} {...(other as HTMLAttributes<HTMLDivElement>)}>
      {icon && <Icon icon={icon} data-id={'icon'} elementSize={SIZE_ICON['sm']} container={false} />}
      <span>{content}</span>
    </TagStyled>
  )
);

Tag.displayName = 'Tag';

export default Tag;
