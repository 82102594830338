import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import { FieldArray, Formik } from 'formik';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import Label from '../../../form/common/label';
import { AddButton } from '../../../global/button/common';
import * as Yup from 'yup';
import Selector from '../../../form/select/selector';
import { useMutation, useQueryClient } from 'react-query';
import { PLANS } from '../../../service/queryKeys';
import { DragItem } from '../../pathway/components';
import Datepicker from '../../../form/datepicker/datepicker';
import { postSteps, putSteps } from '../../../service/api';
import { SelectOptionType } from '../../../service/types';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

dayjs.extend(customParseFormat);

/*Steps props, passed from parent component*/
type DevelopmentAdminStepModalProps = {
  plan: any;
  timeframe: any;
  steps?: any;
  update?: boolean;
};

const StepsModal = ({ plan, timeframe, steps, toggle, update }: ModalToggleProps & DevelopmentAdminStepModalProps) => {
  /*Post step mutation*/
  const queryClient = useQueryClient();
  const postStepMutation = useMutation(postSteps, {
    onSuccess: () => {
      queryClient.invalidateQueries(PLANS);
      toggle(false);
    },
  });

  /*Put step mutation*/
  const putStepMutation = useMutation(putSteps, {
    onSuccess: () => {
      queryClient.invalidateQueries(PLANS);
      toggle(false);
    },
  });

  const PATHWAY_STEP_VALUES = [
    { label: 'Identify', value: 'Identify' },
    { label: 'Explore', value: 'Explore' },
    { label: 'Focus', value: 'Focus' },
    { label: 'Prepare', value: 'Prepare' },
    { label: 'Integrate', value: 'Integrate' },
    { label: 'Review', value: 'Review' },
    { label: 'GTT Training', value: 'GTT Training' },
  ];

  return (
    <Modal>
      {/*Initial values is an empty array of steps and plan&timeframe ids from parent component
      If steps object passed from parent component is not empty it will populate the steps array.*/}
      <Formik
        initialValues={{
          plan_id: plan.id,
          timeframe_id: timeframe.id,
          steps: [
            {
              id: uuidv4(),
              pathwayStep: '',
              method: [],
              activityType: [],
              start_date: null,
              end_date: null,
            },
          ],
          ...steps,
        }}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          steps: Yup.array()
            .min(1)
            .of(
              Yup.object().shape({
                pathwayStep: Yup.string().trim().required().max(64),
                method: Yup.array(),
                activityType: Yup.array(),
                start_date: Yup.date().required(),
                end_date: Yup.date().required(),
              })
            ),
        })}
        onSubmit={(values) => {
          /*If update steps, update passed from parent component is true*/
          if (update) {
            putStepMutation
              .mutateAsync({
                ...values,
                steps: values.steps.map((step: any) => ({
                  id: step.id,
                  pathwayStep: step.pathwayStep,
                  method: step.method,
                  activityType: step.activityType,
                  start_date: dayjs(step.start_date as Date).format('YYYY/MM/DD'),
                  end_date: dayjs(step.end_date as Date).format('YYYY/MM/DD'),
                })),
              })
              .then(() => {
                toast.success('Updated steps');
                return;
              });
          } else {
            postStepMutation
              .mutateAsync({
                ...values,
                steps: values.steps.map((step: any) => ({
                  id: step.id,
                  pathwayStep: step.pathwayStep,
                  method: step.method,
                  activityType: step.activityType,
                  start_date: dayjs(step.start_date as Date).format('YYYY/MM/DD'),
                  end_date: dayjs(step.end_date as Date).format('YYYY/MM/DD'),
                })),
              })
              .then(() => {
                values.steps.forEach((step: any) => (step.start_date = new Date(step.start_date)));
                toast.success('Added new steps');
                return;
              });
          }
        }}
      >
        {({ values, handleSubmit, isValid, setFieldValue }) => (
          <DndProvider backend={HTML5Backend}>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <FieldArray
                  name={'steps'}
                  render={(arrayHelpers) => (
                    <div className={'field-mb'}>
                      {!update && (
                        <>
                          <div className={'sm-field-mb'}>
                            <Label
                              required
                              label={'Steps'}
                              id={'ingredients'}
                              hintText={
                                <AddButton
                                  isOutline
                                  mainColor={'secondary'}
                                  label={'Add step'}
                                  onClick={() =>
                                    arrayHelpers.push({
                                      id: uuidv4(),
                                      pathwayStep: '',
                                      method: [],
                                      activityType: [],
                                      start_date: null,
                                      end_date: null,
                                    })
                                  }
                                />
                              }
                            />
                          </div>
                          <p className={'italic text-muted sm-field-mb'}>
                            Break down the development cycle into actionable steps.
                          </p>
                        </>
                      )}
                      {values.steps?.map(
                        (
                          step: {
                            id: string;
                            pathwayStep: string;
                            method: any;
                            activityType: any;
                            start_date: any;
                            end_date: any;
                          },
                          index: number
                        ) => (
                          <DragItem
                            key={step.id}
                            index={index}
                            id={step.id}
                            type={'steps'}
                            removeDisabled={0 === index && values.steps.length <= 1}
                            handleRemove={() => {
                              arrayHelpers.remove(index);
                            }}
                            moveItem={(draggedId, hoverIndex) => {
                              const stepsCopy = [...values.steps];
                              const draggedItem = stepsCopy.find((step) => step.id === draggedId);

                              const itemRemoved = stepsCopy.splice(stepsCopy.indexOf(draggedItem), 1);
                              stepsCopy.splice(hoverIndex, 0, itemRemoved[0]);
                              setFieldValue('steps', stepsCopy);
                            }}
                            findItem={(id) => {
                              const item = values.steps.find((step: { id: string }) => step.id === id);

                              return { id: item.id, index: values.steps.indexOf(item) };
                            }}
                          >
                            <Selector
                              className={'mb-2'}
                              required
                              id={`pathwayStep${index}`}
                              label={`Development cycle step`}
                              placeholder={`Please select a development cycle step`}
                              isCreatableSelect
                              value={
                                values.steps[index].pathwayStep
                                  ? {
                                      label: values.steps[index].pathwayStep,
                                      value: values.steps[index].pathwayStep,
                                    }
                                  : null
                              }
                              options={PATHWAY_STEP_VALUES.filter(
                                (option: any) =>
                                  !timeframe.steps
                                    .map((step: any) => step.pathwayStep)
                                    .concat(values.steps.map((value: any) => value.pathwayStep))
                                    .includes(option.value != 'GTT Training' && option.value)
                              )}
                              onChange={(option: any) =>
                                setFieldValue(`steps[${index}].pathwayStep`, option == null ? null : option.value)
                              }
                              isClearable={false}
                              customComponents={{
                                MenuList: function MenuList(menuListProps) {
                                  return (
                                    <MessageAtMenuList
                                      message={"Don't see your step? Add it by populating the field above."}
                                      {...menuListProps}
                                    />
                                  );
                                },
                              }}
                            />
                            <Selector
                              className={'mb-2'}
                              isMulti
                              id={`method${index}`}
                              label={`Method`}
                              placeholder={`Please select a method`}
                              isCreatableSelect
                              value={values.steps[index].method?.map((value: any) => {
                                {
                                  return {
                                    label: value,
                                    value,
                                  };
                                }
                              })}
                              options={[
                                { label: 'Whole-school session', value: 'Whole-school session' },
                                { label: 'In teams', value: 'In teams' }, //
                                { label: 'Self-directed', value: 'Self-directed' },
                                { label: 'Coaching', value: 'Coaching' },
                              ]}
                              onChange={(options: any) =>
                                setFieldValue(
                                  `steps[${index}].method`,
                                  options == null ? [] : options.map((option: SelectOptionType) => option.value)
                                )
                              }
                              isClearable={false}
                              customComponents={{
                                MenuList: function MenuList(menuListProps) {
                                  return (
                                    <MessageAtMenuList
                                      message={"Don't see your method? Add it by populating the field above."}
                                      {...menuListProps}
                                    />
                                  );
                                },
                              }}
                            />
                            <Selector
                              className={'mb-2'}
                              isMulti
                              isCreatableSelect
                              id={`activityType${index}`}
                              label={`Activity type`}
                              placeholder={`Please select an activity type`}
                              value={values.steps[index].activityType?.map((value: any) => {
                                {
                                  return {
                                    label: value,
                                    value,
                                  };
                                }
                              })}
                              options={[
                                { label: 'Student survey', value: 'Student survey' },
                                { label: 'Video feedback', value: 'Video observation' },
                                { label: 'GTT Course', value: 'GTT Course' },
                                { label: 'Foundation course part 1', value: 'Foundation course part 1' },
                                { label: 'Foundation course part 2', value: 'Foundation course part 2' },
                                { label: 'Kick-off session', value: 'Kick-off session' },
                              ]}
                              onChange={(options: any) =>
                                setFieldValue(
                                  `steps[${index}].activityType`,
                                  options == null ? [] : options.map((option: SelectOptionType) => option.value)
                                )
                              }
                              isClearable={false}
                              customComponents={{
                                MenuList: function MenuList(menuListProps) {
                                  return (
                                    <MessageAtMenuList
                                      message={"Don't see your activity type? Add it by populating the field above."}
                                      {...menuListProps}
                                    />
                                  );
                                },
                              }}
                            />
                            <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
                              {/* Start date */}
                              <div className={'field-mb'}>
                                <Datepicker
                                  minDate={dayjs(timeframe.start_date, 'DD/MM/YYYY').toDate()}
                                  maxDate={dayjs(timeframe.end_date, 'DD/MM/YYYY').toDate()}
                                  required
                                  id={`start_date${index}`}
                                  label={'Start date'}
                                  placeholder={'Please select a start date'}
                                  selected={values.steps[index].start_date}
                                  value={values.steps[index].start_date?.toLocaleDateString()}
                                  onChange={(date) => setFieldValue(`steps[${index}].start_date`, date)}
                                />
                              </div>
                              {/* End date */}
                              <div className={'field-mb'}>
                                <Datepicker
                                  minDate={values.steps[index].start_date}
                                  maxDate={dayjs(timeframe.end_date, 'DD/MM/YYYY').toDate()}
                                  required
                                  id={`end_date${index}`}
                                  label={'End date'}
                                  placeholder={'Please select a end date'}
                                  selected={values.steps[index].end_date}
                                  value={values.steps[index].end_date?.toLocaleDateString()}
                                  onChange={(date) => setFieldValue(`steps[${index}].end_date`, date)}
                                />
                              </div>
                            </div>
                          </DragItem>
                        )
                      )}
                    </div>
                  )}
                />
              </Modal.Body>
              <Modal.Footer>
                <Modal.CancelButton onClick={() => toggle(false)} />
                <Modal.ConfirmButton
                  type={'submit'}
                  label={
                    postStepMutation.isLoading
                      ? 'Loading...'
                      : update
                      ? 'Save'
                      : values.steps.length > 1
                      ? `Add Steps to plan`
                      : `Add Step to plan`
                  }
                  disabled={!isValid}
                />
              </Modal.Footer>
            </form>
          </DndProvider>
        )}
      </Formik>
    </Modal>
  );
};

export default StepsModal;
