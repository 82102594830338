import React from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import VideoSection from '../../dashboard/sections/videoSection';

const HelpModal = ({ videoLink }: { videoLink: string }) => {
  return (
    <Modal>
      <Modal.Body>
        <VideoSection video={videoLink} />
      </Modal.Body>
    </Modal>
  );
};

export default HelpModal;
