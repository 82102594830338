import React from 'react';
import Icon from '../../../global/icon/icon';
import { toast } from 'react-toastify';
import { ActionButton } from '../../../global/buttonIcon/common';
import { GLOBAL_ICONS } from '../../../resources/vars';

type StatusCellProps = {
  isClass?: boolean;
  studentNums?: { current: number; max: number };
  finished?: boolean;
  copyText?: string;
};

const ICON_SIZE = 20;

const StatusCell = ({ isClass = false, studentNums, finished = false, copyText }: StatusCellProps) => (
  <div className={'flex items-center gap-3'}>
    {copyText && (
      <div className={'flex items-center'}>
        <ActionButton.Copy
          text={copyText}
          onCopy={() => toast.success('Survey link has been copied')}
          mainColor={'secondary'}
          size={'sm'}
          label={'Copy link'}
        />
        <sub className={'text-light ml-2'}>/</sub>
      </div>
    )}
    <div>
      {isClass ? (
        studentNums?.current === studentNums?.max ? (
          <Icon icon={GLOBAL_ICONS.completed} color={'success'} container={false} elementSize={ICON_SIZE} />
        ) : (
          <>
            <b>{studentNums?.current}</b>/{studentNums?.max}
          </>
        )
      ) : finished ? (
        <Icon icon={GLOBAL_ICONS.completed} color={'success'} container={false} elementSize={ICON_SIZE} />
      ) : (
        <Icon icon={GLOBAL_ICONS.uncompleted} color={'muted'} container={false} elementSize={ICON_SIZE} />
      )}
    </div>
  </div>
);

StatusCell.displayName = 'StatusCell';

export default StatusCell;
