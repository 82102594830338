import React from 'react';
import { Text } from '@react-pdf/renderer';
import { PdfTextMetricsType } from '../resources/resources';

const PdfTextMetrics = ({
  text,
  customMarginBottom,
  customFontSize,
  customWidth,
  customTextAlign,
  customMarginLeft,
  customFontFamily,
}: PdfTextMetricsType) => {
  return (
    <Text
      style={{
        fontFamily: customFontFamily ? customFontFamily : 'Futura PT Book, sans-serif',
        marginBottom: customMarginBottom ? customMarginBottom : '2px',
        fontSize: customFontSize ? customFontSize : '18px',
        width: customWidth ? customWidth : undefined,
        textAlign: customTextAlign ? customTextAlign : undefined,
        marginLeft: customMarginLeft ? customMarginLeft : undefined,
      }}
    >
      {text}
    </Text>
  );
};

export default PdfTextMetrics;
