import React from 'react';
import classnames from 'classnames';
import { AccordionProps } from './accordion.types';
import Icon from '../icon/icon';
import { Collapse } from '../transitions';
import { GLOBAL_ICONS } from '../../resources/vars';

const Accordion = ({
  title,
  isOpen = false,
  handleOpen,
  isDisabled = false,
  children,
  arrowPosition = 'left',
  tag,
  style,
  tagStyle,
  dimension,
}: AccordionProps) => (
  <div className={'border-2 bg-light bg-opacity-50'}>
    <div
      className={`transition duration-500 ease-in-out shadow bg-white ${classnames({
        'cursor-pointer hover:bg-light': !isDisabled,
      })}`}
    >
      <div
        onClick={() => {
          if (!isDisabled) {
            handleOpen();
          }
        }}
        className={'flex justify-between'}
      >
        {null != tag && (
          <div className={'flex items-center item-container text-white'} style={{ ...style, ...tagStyle }}>
            {tag}
          </div>
        )}
        <div style={dimension && { backgroundColor: dimension.colour }}
             className={`flex items-center item-container w-full ${classnames({ 'cursor-default': isDisabled })}`}>
          {!isDisabled && 'left' === arrowPosition && (
            <Icon
              icon={GLOBAL_ICONS['open']}
              className={`transition duration-500 ease-in-out transform ${classnames({ 'rotate-180': isOpen })} mr-3`}
            />
          )}
          <div className={`text-left flex-grow ${dimension && 'text-white font-bold'}`} style={style}>
            {dimension ? (
              <div className={'flex inline-block'}>
                <img className={'mr-2'} height={24} width={24} src={dimension.icon}/>
                <p>
                  {dimension.number}. {title}
                </p>
              </div>
            ) : (
              <>{title}</>
            )}
          </div>
          {!isDisabled && 'right' === arrowPosition && (
            <Icon
              icon={GLOBAL_ICONS['open']}
              className={`transition duration-500 ease-in-out transform ${classnames({ 'rotate-180': isOpen })}`}
            />
          )}
        </div>
      </div>
    </div>
    <Collapse when={isOpen}>
      <div className={'item-container'}>{children}</div>
    </Collapse>
  </div>
);

export default Accordion;
