import dimension1_icon from '@app/asset/images/mfgt_page/dimension1_icon.png';
import dimension2_icon from '@app/asset/images/mfgt_page/dimension2_icon.png';
import dimension3_icon from '@app/asset/images/mfgt_page/dimension3_icon.png';
import dimension4_icon from '@app/asset/images/mfgt_page/dimension4_icon.png';

//DATA
export const dimensions: Array<DimensionType> = [
  {
    number: 1,
    colour: '#E62214',
    title: 'Understanding the content',
    icon: dimension1_icon,
    description_title: 'Great teachers understand the content they are teaching and how it is learnt',
    description:
      'This means teachers should have deep and fluent knowledge and a flexible understanding of the content they are teaching and how it is learnt, including its inherent dependencies. They should have an explicit repertoire of well-crafted explanations, examples and tasks for each topic they teach.',
    elements: [
      {
        dimensions: ['1'],
        elements: ['1.1'],
        title: 'Deep and fluent knowledge',
        long_description: 'Having deep and fluent knowledge and flexible understanding of the content you are teaching',
        url: ['https://vimeo.com/657417692'],
        slug: 'deep-and-fluent-knowledge',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#ef3d31',
      },
      {
        dimensions: ['1'],
        elements: ['1.2'],
        title: 'Curriculum sequencing',
        long_description:
          'Knowledge of the requirements of curriculum sequencing and dependencies in relation to the content and ideas you are teaching',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'curriculum-sequencing',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#E62214',
      },
      {
        dimensions: ['1'],
        elements: ['1.3'],
        title: 'Relevant curriculum tasks',
        long_description:
          'Knowledge of relevant curriculum tasks, assessments and activities, their diagnostic and didactic potential; being able to generate varied explanations and multiple representations/analogies/examples for the ideas you are teaching',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'relevant-curriculum-tasks',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#E62214',
      },
      {
        dimensions: ['1'],
        elements: ['1.4'],
        title: 'Strategies and misconceptions',
        long_description:
          'Knowledge of common student strategies, misconceptions and sticking points in relation to the content you are teaching',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'strategies-and-misconceptions',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#E62214',
      },
    ],
  },
  {
    number: 2,
    colour: '#068468',
    title: 'Creating a supportive environment',
    icon: dimension2_icon,
    description_title: 'Great teachers create a supportive environment for learning',
    description:
      'A supportive environment is characterised by relationships of trust and respect between students and teachers, and among students. It is one in which students are motivated, supported and challenged and have a positive attitude towards their learning.',
    elements: [
      {
        dimensions: ['2'],
        elements: ['2.1'],
        title: 'Teacher-student relationships',
        long_description:
          'Promoting interactions and relationships with all students that are based on mutual respect, care, empathy and warmth; avoiding negative emotions in interactions with students; being sensitive to the individual needs, emotions, culture and beliefs of students',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'teacher-student-relationships',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#068468',
      },
      {
        dimensions: ['2'],
        elements: ['2.2'],
        title: 'Student-student relationships',
        long_description:
          'Promoting a positive climate of student-student relationships, characterised by respect, trust, cooperation and care',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'student-student-relationships',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#068468',
      },
      {
        dimensions: ['2'],
        elements: ['2.3'],
        title: 'Learner motivation',
        long_description: 'Promoting learner motivation through feelings of competence, autonomy and relatedness',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'learner-motivation',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#068468',
      },
      {
        dimensions: ['2'],
        elements: ['2.4'],
        title: 'Climate of high expectations',
        long_description:
          'Creating a climate of high expectations, with high challenge and high trust, so learners feel it is okay to have a go; encouraging learners to attribute their success or failure to things they can change',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'climate-of-high-expectations',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#068468',
      },
    ],
  },
  {
    number: 3,
    colour: '#0776bd',
    title: 'Maximising opportunity to learn',
    icon: dimension3_icon,
    description_title: 'Great teachers manage the classroom to maximise opportunity to learn',
    description:
      'No model of teaching effectiveness could be complete without classroom management: managing the behaviour and activities of a class of students is what teachers do. Yet is it also controversial. Different teachers have very different styles, values and priorities.',
    elements: [
      {
        dimensions: ['3'],
        elements: ['3.1'],
        title: 'Managing time and resources',
        long_description:
          'Managing time and resources efficiently in the classroom to maximise productivity and minimise wasted time (e.g., starts, transitions); giving clear instructions so students understand what they should be doing; using (and explicitly teaching) routines to make transitions smooth',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'managing-time-and-resources',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#0776bd',
      },
      {
        dimensions: ['3'],
        elements: ['3.2'],
        title: 'Rules and consequences',
        long_description:
          'Ensuring that rules, expectations and consequences for behaviour are explicit, clear and consistently applied',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'rules-and-consequences',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#0776bd',
      },
      {
        dimensions: ['3'],
        elements: ['3.3'],
        title: 'Managing disruptive behaviour',
        long_description:
          'Preventing, anticipating & responding to potentially disruptive incidents; reinforcing positive student behaviours; signalling awareness of what is happening in the classroom and responding appropriately',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'managing-disruptive-behaviour',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#0776bd',
      },
    ],
  },
  {
    number: 4,
    colour: '#5a499e',
    title: 'Activating hard thinking',
    icon: dimension4_icon,
    description_title:
      'Great teachers present content, activities and interactions that activate their students’ thinking',
    description:
      'In many ways, Dimension 4 represents the heart of great teaching: getting students to think hard about the material you want them to learn. It may also be the hardest part of the job to learn, partly because it is rare to get reliable feedback about whether it is working: student learning is invisible, slow and non-linear, so how can we tell if it is happening?',
    elements: [
      {
        dimensions: ['4'],
        elements: ['4.1'],
        title: 'Structuring',
        long_description:
          'Structuring: giving students an appropriate sequence of learning tasks; signalling learning objectives, rationale, overview, key ideas and stages of progress; matching tasks to learners’ needs and readiness; scaffolding and supporting to make tasks accessible to all, but gradually removed so that all students succeed at the required level',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'structuring',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#5a499e',
      },
      {
        dimensions: ['4'],
        elements: ['4.2'],
        title: 'Explaining',
        long_description:
          'Explaining: presenting and communicating new ideas clearly, with concise, appropriate, engaging explanations; connecting new ideas to what has previously been learnt (and re-activating/checking that prior knowledge); using examples (and non-examples) appropriately to help learners understand and build connections; modelling/demonstrating new skills or procedures with appropriate scaffolding and challenge; using worked/part-worked examples',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'explaining',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#5a499e',
      },
      {
        dimensions: ['4'],
        elements: ['4.3'],
        title: 'Questioning',
        long_description:
          'Questioning: using questions and dialogue to promote elaboration and connected, flexible thinking among learners (e.g., ‘Why?’, ‘Compare’, etc.); using questions to elicit student thinking; getting responses from all students; using high-quality assessment to evidence learning; interpreting, communicating and responding to assessment evidence appropriately',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'questioning',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#5a499e',
      },
      {
        dimensions: ['4'],
        elements: ['4.4'],
        title: 'Interacting',
        long_description:
          'Interacting: responding appropriately to feedback from students about their thinking/knowledge/understanding; giving students actionable feedback to guide their learning',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'interacting',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#5a499e',
      },
      {
        dimensions: ['4'],
        elements: ['4.5'],
        title: 'Embedding',
        long_description:
          'Embedding: giving students tasks that embed and reinforce learning; requiring them to practise until learning is fluent and secure; ensuring that once-learnt material is reviewed/revisited to prevent forgetting',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'embedding',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#5a499e',
      },
      {
        dimensions: ['4'],
        elements: ['4.6'],
        title: 'Activating',
        long_description:
          'Activating: helping students to plan, regulate and monitor their own learning; progressing appropriately from structured to more independent learning as students develop knowledge and expertise',
        url: ['https://vimeo.com/762253588/c8aac69cfd'],
        slug: 'activating',
        type: 'element',
        image:
          'https://w7.pngwing.com/pngs/380/764/png-transparent-paper-box-computer-icons-symbol-random-icons-miscellaneous-angle-text-thumbnail.png',
        icon: null,
        draft: false,
        pathway_steps: ['identify'],
        keywords: ['test', 'primary'],
        content: `<p></p><figure><img src="https://randomwordgenerator.com/img/picture-generator/chair-1840011_640.jpg" class="max-w-lg max-h-144 m-auto"></figure><p><strong>Lorem Ipsum</strong> is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>`,
        estimated_time: 2,
        short_description: 'Short description for the card',
        goal: ['Not too sure'],
        parents: ['resourceID1', 'resourceID2'],
        instructions: ['Step one', 'Step two', 'Step three'],
        suggestions: ['Suggestion', 'Comment', 'Question?'],
        children: ['ResourceID1', 'ResourceID2'],
        file: {},
        banner: '#6758a4',
      },
    ],
  },
];

// TYPES
export interface Dimension {
  number: number;
  title: string;
  colour: string;
  description_title: string;
  description: string;
}

export interface DimensionType extends Dimension {
  elements: Array<ElementType>;
  icon: string;
}

export interface ElementType {
  elements: Array<string>;
  title: string;
  url?: Array<string>;
  long_description?: string;
  slug?: string;
  dimensions: Array<string>;
  type: string;
  image: string | null;
  icon: string | null;
  draft: boolean;
  pathway_steps: Array<string>;
  keywords: Array<string>;
  content: string;
  estimated_time: number;
  short_description: string;
  goal: Array<string>;
  parents: Array<string>;
  instructions: Array<string>;
  suggestions: Array<string>;
  children: Array<string>;
  file: any;
  banner: string;
}

// PROP TYPES
export interface ElementCardProps {
  dimension: Dimension;
  element: ElementType;
  setElement?: any;
  setView?: any;
  setDimension?: any;
}
