import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';
import Input from '../../../../form/input/input';
import Label from '../../../../form/common/label';
import { AddButton } from '../../../../global/button/common';
import { DragItem, ListItem } from '../../components';
import * as Yup from 'yup';
import Alert from '../../../../global/alert/alert';
import Selector from '../../../../form/select/selector';
import { DefaultOptionType } from '../../../../resources/types';
import { ActionMeta } from 'react-select';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getV3PathwayStrategiesTemplates, handleV3PathwaysStrategy } from '../../../../service/api';
import { PATHWAYS_V3, V3_TEMPLATE_STRATEGIES } from '../../../../service/queryKeys';
import { v4 as uuidv4 } from 'uuid';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resource } from '../../../../@types/Entity/Resource';
import { Strategy } from '../../../../@types/Entity/Strategy';
import DragAndDropFile from '../../../../form/dragAndDrop/DragAndDropFile';
import { toast } from 'react-toastify';

const PathwaysStrategyModal = ({ pathwayId, strategy, toggle }: ModalToggleProps & V3StrategyModalProps) => {
  const templateStrategiesQuery = useQuery(
    [V3_TEMPLATE_STRATEGIES, pathwayId],
    () => getV3PathwayStrategiesTemplates(pathwayId),
    {
      staleTime: Infinity,
      enabled: !strategy,
    }
  );

  const queryClient = useQueryClient();
  const V3StrategyMutation = useMutation(handleV3PathwaysStrategy, {
    onSuccess: () => {
      toast.success(`Strategy ${strategy ? 'edited' : 'added'}`);
      toggle(false);
      queryClient.invalidateQueries([PATHWAYS_V3, pathwayId]);
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue,
    watch,
  } = useForm({
    defaultValues: strategy
      ? {
          ...strategy,
          urls: strategy.resources
            .filter((resource) => resource.type === 'website')
            .map((resource) => ({
              id: resource.id,
              link: resource.link,
              name: resource.name,
            })),
          steps: strategy.steps.map((step) => ({ step, id: uuidv4() })),
          files: [] as File[],
          saved_files: strategy.resources.filter((resource) => resource.type === 'file'),
          remove_resources: [] as { id: string }[],
          template_files: [] as Resource[],
        }
      : {
          name: '',
          description: '',
          steps: [{ id: uuidv4(), step: '' }],
          urls: [] as { id?: string; link?: string; name?: string }[],
          files: [] as File[],
          saved_files: [] as Resource[],
          remove_resources: [] as { id: string }[],
          template_files: [] as Resource[],
        },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().trim().required().max(64),
        description: Yup.string().trim().required().max(1000),
        steps: Yup.array()
          .min(1)
          .of(
            Yup.object({
              id: Yup.string(),
              step: Yup.string().trim().required(),
            })
          ),
        urls: Yup.array()
          .min(0)
          .of(
            Yup.object().shape({
              name: Yup.string().trim().required('This field is required'),
              link: Yup.string().trim().required('This field is required'),
            })
          ),
      })
    ),
  });

  const stepsFieldArray = useFieldArray({ control, name: 'steps' });
  const urlsFieldArray = useFieldArray({ control, name: 'urls' });
  const savedFilesFieldArray = useFieldArray({ control, name: 'saved_files' });
  const removeResourcesFieldArray = useFieldArray({ control, name: 'remove_resources' });
  const templateFilesFieldArray = useFieldArray({ control, name: 'template_files' });

  return (
    <Modal>
      <DndProvider backend={HTML5Backend}>
        <form
          onSubmit={handleSubmit((data) => {
            V3StrategyMutation.mutate({
              pathwayId: pathwayId,
              id: strategy?.id,
              name: data.name,
              description: data.description,
              steps: data.steps.map((step) => step.step),
              urls: data.urls,
              files: data.files,
              remove_resources: data.remove_resources.map((resource) => resource.id),
              template_files: data.template_files.map((resource) => resource.id),
            });
          })}
        >
          <Modal.Body>
            {!strategy && templateStrategiesQuery?.data && templateStrategiesQuery.data.data.data.length > 0 && (
              <div className={'n-block-container block-container border-b field-mb pb-8'}>
                <Alert title={'Strategy templates'}>
                  <p className={'sm-field-mb'}>
                    You can create your own strategy or select a preexisting strategy in order to populate the fields
                    below.
                  </p>
                  <Selector
                    id={'template'}
                    label={'Strategy template'}
                    hideLabel
                    placeholder={'Select a strategy template'}
                    options={templateStrategiesQuery.data?.data.data.map((strategy) => ({
                      label: strategy.name,
                      value: strategy.id,
                      strategy: strategy,
                    }))}
                    onChange={(option: DefaultOptionType & { strategy: Strategy }, action: ActionMeta<any>) => {
                      if (action.action === 'clear') {
                        return;
                      }

                      const strategy = option.strategy;

                      setValue('name', strategy.name);
                      setValue('description', strategy.description);
                      setValue(
                        'steps',
                        strategy.steps.map((step) => ({ id: uuidv4(), step }))
                      );
                      setValue(
                        'urls',
                        strategy.resources
                          .filter((resource) => resource.type === 'website')
                          .map((resource) => ({
                            name: resource.name,
                            link: resource.link,
                          }))
                      );
                      setValue(
                        'template_files',
                        strategy.resources.filter((resource) => resource.type === 'file')
                      );
                    }}
                  />
                </Alert>
              </div>
            )}
            <div className={'field-mb'}>
              <Input
                {...register('name')}
                value={watch('name')}
                required
                label={'Strategy name'}
                id={`name`}
                placeholder={'Please enter a strategy name'}
                error={errors.name?.message as string}
                maxLength={64}
              />
            </div>
            <div className={'field-mb'}>
              <Input
                {...register('description')}
                value={watch('description')}
                required
                label={'Strategy description'}
                id={`description`}
                placeholder={'Please enter a strategy description'}
                error={errors.description?.message as string}
                maxLength={1000}
                type={'textarea'}
              />
            </div>
            <div className={'field-mb'}>
              <div className={'sm-field-mb'}>
                <Label
                  required
                  label={'Working steps'}
                  id={'ingredients'}
                  hintText={
                    <AddButton
                      isOutline
                      mainColor={'secondary'}
                      label={'Add step'}
                      onClick={() => stepsFieldArray.append({ id: uuidv4(), step: '' })}
                    />
                  }
                />
              </div>
              <p className={'italic text-muted sm-field-mb'}>Break down the strategy into actionable steps.</p>
              {stepsFieldArray.fields.map((step, index) => (
                <DragItem
                  key={step.id}
                  index={index}
                  id={step.id}
                  type={'steps'}
                  removeDisabled={0 === index && stepsFieldArray.fields.length <= 1}
                  handleRemove={() => stepsFieldArray.remove(index)}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = stepsFieldArray.fields.findIndex((step) => step.id === draggedId);

                    stepsFieldArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = stepsFieldArray.fields.findIndex((step) => step.id === id);

                    return { id, index };
                  }}
                >
                  <Input
                    {...register(`steps.${index}.step`)}
                    required
                    label={`Step ${index + 1}`}
                    id={`steps.${index}.step`}
                    placeholder={`Please enter a name for step ${index + 1}`}
                    error={errors.steps?.[index]?.step?.message}
                    hideLabel
                  />
                </DragItem>
              ))}
            </div>
            <div className={'field-mb'}>
              <div className={'sm-field-mb'}>
                <Label
                  label={'Resources'}
                  id={'urls'}
                  hintText={
                    <AddButton
                      isOutline
                      mainColor={'secondary'}
                      label={'Add URL'}
                      onClick={() => urlsFieldArray.append({ name: '', link: '' })}
                    />
                  }
                />
              </div>
              <p className={'italic text-muted sm-field-mb'}>Add links to articles, videos or research.</p>
              {urlsFieldArray.fields.map((url, index) => (
                <ListItem
                  key={`url_${index}`}
                  index={index}
                  removeDisabled={false}
                  handleRemove={() => {
                    urlsFieldArray.remove(index);
                    removeResourcesFieldArray.append({ id: url.id });
                  }}
                >
                  <div className={'flex gap-2'}>
                    <div style={{ maxWidth: 100 }}>
                      <Input
                        {...register(`urls.${index}.name`)}
                        label={`URL #${index + 1}`}
                        id={`urls.${index}.name`}
                        placeholder={'Name'}
                        error={errors.urls?.[index]?.name?.message}
                        hideLabel
                      />
                    </div>
                    <div className={'flex-grow'}>
                      <Input
                        {...register(`urls.${index}.link`)}
                        label={`URL #${index + 1}`}
                        id={`urls.${index}.link`}
                        placeholder={'Please enter a URL'}
                        error={errors.urls?.[index]?.link?.message}
                        hideLabel
                      />
                    </div>
                  </div>
                </ListItem>
              ))}
            </div>
            <DragAndDropFile
              files={watch('files')}
              setFile={(file) => {
                setValue('files', file as File[]);
              }}
              error={errors.files?.message as string}
              text={'Click or drag and drop to add a file'}
            />
            {watch('saved_files').map((file: Resource, index) => (
              <ListItem
                key={`file_${file.id}`}
                index={index}
                removeDisabled={false}
                handleRemove={() => {
                  removeResourcesFieldArray.append({ id: file.id });
                  savedFilesFieldArray.remove(index);
                }}
              >
                <div className={'flex gap-2'}>
                  <div style={{ maxWidth: 100 }}>{file.name}</div>
                </div>
              </ListItem>
            ))}
            {watch('template_files').map((file: Resource, index) => (
              <ListItem
                key={`file_${file.id}`}
                index={index}
                removeDisabled={false}
                handleRemove={() => {
                  templateFilesFieldArray.remove(index);
                }}
              >
                <div className={'flex gap-2'}>
                  <div style={{ maxWidth: 100 }}>{file.name}</div>
                </div>
              </ListItem>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Modal.CancelButton onClick={() => toggle(false)} />
            <Modal.ConfirmButton
              type={'submit'}
              label={V3StrategyMutation.isLoading ? 'Loading...' : strategy ? 'Edit Strategy' : 'Add Strategy'}
              disabled={V3StrategyMutation.isLoading}
            />
          </Modal.Footer>
        </form>
      </DndProvider>
    </Modal>
  );
};

type V3StrategyModalProps = {
  pathwayId: string;
  strategy: Strategy;
  isShared?: boolean;
  team?: { id: string; name: string };
};

export default PathwaysStrategyModal;
