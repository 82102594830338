import React, { useEffect, useRef } from 'react';
import * as typeformEmbed from '@typeform/embed';
import { postTypeformResponse } from '../service/api';

const Typeform = ({ formId, teacherId, className, lastName, toc, id, school }: PropTypes) => {
  const typeformRef = useRef<HTMLDivElement>(null);

  const generateTypeFormRoute = () => {
    let commonRoute = `https://ebe1.typeform.com/to/${formId}/#`;

    //Standard params
    if (teacherId) {
      commonRoute = commonRoute.concat('teacherid=' + teacherId)
    }

    if (toc) {
      commonRoute = commonRoute.concat('&toc=' + toc)
    }

    if (lastName) {
      commonRoute = commonRoute.concat('&lastname=' + lastName)
    }

    if (className) {
      commonRoute = commonRoute.concat('&classname=' + className)
    }

    //School survey params
    if (id) {
      commonRoute = commonRoute.concat('id=' + id);
    }

    if (school) {
      commonRoute = commonRoute.concat('&school=' + school);
    }

    return encodeURI(commonRoute);
  };

  useEffect(() => {
    if (!typeformRef.current) {
      return;
    }

    if (!(formId && teacherId && lastName && toc && className) && !(formId && id && school)) {
      return;
    }

    typeformEmbed.makeWidget(typeformRef!.current, generateTypeFormRoute(), {
      hideFooter: true,
      hideHeaders: true,
      opacity: 100,
      onSubmit: (event) => {
        postTypeformResponse(formId, id ?? (toc as string), event.response_id).then();
      },
    });
  }, [typeformRef, formId]);

  return <div ref={typeformRef} style={{ width: '100%', height: '100%' }} />;
};

export default Typeform;

type PropTypes = {
  formId: string;
  teacherId: string | null;
  className: string | null;
  lastName: string | null;
  toc: string | null;
  id: string | null;
  name: string | null;
  school: string | null
};
