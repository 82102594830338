import React, { useState } from 'react';
import { ModalProps } from '../../../../global/messages/modal/modal.types';
import { MeetingFormValues } from '../MeetingItem';
import Modal from '../../../../global/messages/modal/modal';
import ModalComponents from '../../../../global/messages/modal/modal.components';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { REFERENCE_OPTIONS } from '../../../../service/queryKeys';
import { getReferenceOptions } from '../../../../service/api';
import {
  STUDENT_SURVEY_REFERENCE_TYPE,
  VIDEO_OBSERVATION_REFERENCE_TYPE,
} from '../../../pathway/modals/AddReferenceModal';
import RadioGroupOnBlock from '../../../../form/choice/radioGroupOnBlock';
import Selector from '../../../../form/select/selector';
import { SelectOptionType } from '../../../../service/types';
import { Option } from '../../../../@types/global';

export default function AddFeedbackModal({ toggle, onSubmit }: Props) {
  const [type, setType] = useState<string | null>(null);
  const [empty, setEmpty] = useState<boolean>(true);

  const getSurveyReferenceOptionsQuery = useQuery(
    [REFERENCE_OPTIONS, STUDENT_SURVEY_REFERENCE_TYPE],
    () => getReferenceOptions(STUDENT_SURVEY_REFERENCE_TYPE),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
      placeholderData: [],
    }
  );

  const getVideoReferenceOptionsQuery = useQuery(
    [REFERENCE_OPTIONS, VIDEO_OBSERVATION_REFERENCE_TYPE],
    () => getReferenceOptions(VIDEO_OBSERVATION_REFERENCE_TYPE),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
      placeholderData: [],
    }
  );

  const { handleSubmit, reset, control, watch } = useForm<MeetingFormValues['feedbacks'][number]>({
    defaultValues: {
      id: null,
      instrumentSchoolClass: null,
      video: null,
      element: null,
    },
  });

  const instrumentSchoolClass = watch('instrumentSchoolClass');

  return (
    <Modal open toggle={() => toggle()} title={'Add feedback'} size={'md'}>
      <form onSubmit={handleSubmit((values) => onSubmit(values))}>
        <ModalComponents.Body>
          <div className={'flex items-center gap-4 sm-field-mb'}>
            <div className={'flex-grow mb-5'}>
              <RadioGroupOnBlock
                id={'modal-reference-type-selector'}
                hideLabel
                optionChecked={type}
                options={REFERENCE_TYPES}
                handleChange={(value) => {
                  setType(value);
                  reset();
                }}
              />
            </div>
          </div>
          {STUDENT_SURVEY_REFERENCE_TYPE === type && (
            <>
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  name={'instrumentSchoolClass'}
                  render={({ field: { value, onChange } }) => (
                    <Selector
                      label={'Survey instances'}
                      id={'modal-survey-selector'}
                      options={getSurveyReferenceOptionsQuery.data}
                      value={value}
                      onChange={(value: SelectOptionType) => {
                        onChange(value);
                        setEmpty(false);
                      }}
                    />
                  )}
                />
              </div>
              {null !== instrumentSchoolClass &&
                instrumentSchoolClass.elements &&
                instrumentSchoolClass.elements.length > 0 && (
                  <>
                    <Controller
                      control={control}
                      name={'element'}
                      render={({ field: { value, onChange } }) => (
                        <Selector
                          label={'Element'}
                          id={'modal-element-selector'}
                          options={instrumentSchoolClass.elements}
                          value={value === null ? { label: 'All elements', value: '' } : value}
                          onChange={(value: SelectOptionType) => onChange(value)}
                          isClearable={value !== null}
                        />
                      )}
                    />
                  </>
                )}
            </>
          )}
          {VIDEO_OBSERVATION_REFERENCE_TYPE === type && (
            <>
              <Controller
                control={control}
                name={'video'}
                render={({ field: { value, onChange } }) => (
                  <Selector
                    label={'Video'}
                    id={'modal-video-selector'}
                    options={getVideoReferenceOptionsQuery.data}
                    value={value}
                    onChange={(value: SelectOptionType) => {
                      onChange(value);
                      setEmpty(false);
                    }}
                  />
                )}
              />
            </>
          )}
        </ModalComponents.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton disabled={empty} label={'Add feedback'} type={'submit'} />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

const REFERENCE_TYPES = [
  { label: 'Student survey', value: STUDENT_SURVEY_REFERENCE_TYPE },
  { label: 'Video feedback', value: VIDEO_OBSERVATION_REFERENCE_TYPE },
];

interface Props extends Pick<ModalProps, 'toggle'> {
  onSubmit: (feedback: MeetingFormValues['feedbacks'][number]) => void;
}

export interface InstrumentSchoolClassOption extends Option {
  elements?: Option[];
}
