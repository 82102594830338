import React from 'react';
import { ModalToggleProps } from "../../../../global/messages/modal/modal.types";
import Modal from "../../../../global/messages/modal/modal.components";

const CompleteConfirmationModal =
  ({ toggle, handleConfirmComplete }: ModalToggleProps & { handleConfirmComplete: any }) => {
  return (
    <Modal>
      <Modal.Body>
        <p>Are you sure you want to complete this development cycle? This action cannot be undone.</p>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton
          onClick={() => {
            toggle(false);
          }}
        />
        <Modal.ConfirmButton
          onClick={() =>
            handleConfirmComplete()

          }
        />
      </Modal.Footer>
    </Modal>
  );
};

CompleteConfirmationModal.displayName = 'CompleteConfirmationModal';

export default CompleteConfirmationModal;
