import React from 'react';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import Input from '../../../form/input/input';
import Label from '../../../form/common/label';
import { AddButton } from '../../../global/button/common';
import * as Yup from 'yup';
import Selector from '../../../form/select/selector';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getDevelopmentGoals, getFocusOptions, postOrPutStrategy } from '../../../service/api';
import { ADMIN_GOALS, ADMIN_STRATEGIES, FOCUS_OPTIONS } from '../../../service/queryKeys';
import { DragItem, ListItem } from '../../pathway/components';
import { v4 as uuidv4 } from 'uuid';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Strategy } from '../../../@types/Entity/Strategy';
import { SelectOptionType } from '../../../service/types';
import { ROLE_SYS_ADMIN, RolesType } from '../../../resources/roles';

type DevelopmentAdminStrategyModalProps = {
  strategy: Strategy;
  role: RolesType;
};

const StrategyModal = ({ strategy, role, toggle }: ModalToggleProps & DevelopmentAdminStrategyModalProps) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(postOrPutStrategy, {
    onSuccess: () => {
      queryClient.invalidateQueries(ADMIN_STRATEGIES);
      queryClient.invalidateQueries(ADMIN_GOALS);
      toggle(false);
    },
  });

  const goalsQuery = useQuery([ADMIN_GOALS, undefined, 'school'], () => getDevelopmentGoals(undefined, 'school'), {
    staleTime: Infinity,
    select: (data) =>
      data.data.data.map((group: any) => {
        return {
          label: group.name,
          value: group.id,
        };
      }),
    placeholderData: [],
  });

  const { data: options } = useQuery(FOCUS_OPTIONS, () => getFocusOptions(), {
    select: (data) => data.data.data,
    staleTime: Infinity,
    enabled: ROLE_SYS_ADMIN === role,
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
  } = useForm({
    defaultValues: strategy
      ? {
          ...strategy,
          urls: strategy.resources
            .filter((resource) => resource.type === 'website')
            .map((resource) => ({
              id: resource.id,
              link: resource.link,
              name: resource.name,
            })),
          steps: strategy.steps.map((step) => ({ step, id: uuidv4() })),
          goals: strategy.goals?.map((goal) => ({ label: goal.name, value: goal.id })),
          goal_id: null,
        }
      : {
          name: '',
          description: '',
          steps: [{ id: uuidv4(), step: '' }],
          urls: [] as { id?: string; link?: string; name?: string }[],
          additional_info: '',
          elements: [],
          goals: [],
          goal_id: null,
        },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().trim().required().max(64),
        description: Yup.string().trim().required().max(1000),
        elements: Yup.array().nullable(),
        additional_info: Yup.string().trim(),
        steps: Yup.array()
          .min(1)
          .of(
            Yup.object({
              id: Yup.string(),
              step: Yup.string().trim().required(),
            })
          ),
        urls: Yup.array()
          .min(0)
          .of(
            Yup.object().shape({
              name: Yup.string().trim(),
              link: Yup.string().trim(),
            })
          ),
      })
    ),
  });

  const stepsFieldArray = useFieldArray({ control, name: 'steps' });
  const urlsFieldArray = useFieldArray({ control, name: 'urls' });

  return (
    <Modal>
      <DndProvider backend={HTML5Backend}>
        <form
          onSubmit={handleSubmit((values) => {
            mutation
              .mutateAsync({
                ...values,
                steps: values.steps.map((step) => step.step),
                goals: (values.goals ?? []).map((goal: SelectOptionType) => goal.value),
                elements: role === ROLE_SYS_ADMIN ? (values.elements ?? []).map((element) => element.value) : null,
              })
              .then(() => {
                if (!strategy) {
                  toast.success('Added new strategy');
                  return;
                }
                toast.success('Strategy updated');
              });
          })}
        >
          <Modal.Body>
            {/* Strategy Name */}
            <div className={'field-mb'}>
              <Input
                {...register('name')}
                value={watch('name')}
                required
                maxLength={64}
                id={'name'}
                name={'name'}
                label={'Strategy name'}
                placeholder={'Please enter a strategy name'}
              />
            </div>
            {/* Strategy Description */}
            <div className={'field-mb'}>
              <Input
                {...register('description')}
                value={watch('description')}
                required
                maxLength={1000}
                id={'description'}
                type={'textarea'}
                name={'description'}
                label={'Strategy description'}
                placeholder={'Please enter a strategy description'}
              />
            </div>
            {role === ROLE_SYS_ADMIN && (
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      id="elements"
                      label={'Element/Dimension focus'}
                      placeholder={'Select an Element/Dimension focus'}
                      options={options}
                      errorMessage={errors.elements?.message as string}
                      isMulti
                    />
                  )}
                  name={'elements'}
                />
              </div>
            )}
            {/* Active ingredients */}
            <div className={'field-mb'}>
              <div className={'sm-field-mb'}>
                <Label
                  required
                  label={'Working steps'}
                  id={'ingredients'}
                  hintText={
                    <AddButton
                      isOutline
                      mainColor={'secondary'}
                      label={'Add step'}
                      onClick={() => stepsFieldArray.append({ id: uuidv4(), step: '' })}
                    />
                  }
                />
              </div>
              <p className={'italic text-muted sm-field-mb'}>Break down the strategy into actionable steps.</p>
              {stepsFieldArray.fields.map((step, index) => (
                <DragItem
                  key={step.id}
                  index={index}
                  id={step.id}
                  type={'steps'}
                  removeDisabled={0 === index && stepsFieldArray.fields.length <= 1}
                  handleRemove={() => stepsFieldArray.remove(index)}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = stepsFieldArray.fields.findIndex((step) => step.id === draggedId);

                    stepsFieldArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = stepsFieldArray.fields.findIndex((step) => step.id === id);

                    return { id, index };
                  }}
                >
                  <Input
                    {...register(`steps.${index}.step`)}
                    required
                    id={`steps[${index}].step`}
                    label={`Step ${index + 1}`}
                    hideLabel
                    placeholder={`Please enter a name for step ${index + 1}`}
                  />
                </DragItem>
              ))}
            </div>
            {/* URLs */}
            <div className={'field-mb'}>
              <div className={'sm-field-mb'}>
                <Label
                  label={'Resources'}
                  id={'urls'}
                  hintText={
                    <AddButton
                      isOutline
                      mainColor={'secondary'}
                      label={'Add URL'}
                      onClick={() => urlsFieldArray.append({ name: '', link: '' })}
                    />
                  }
                />
              </div>
              <p className={'italic text-muted sm-field-mb'}>Add links to articles, videos or research.</p>
              {urlsFieldArray.fields.map((url, index) => (
                <ListItem
                  key={`url_${index}`}
                  index={index}
                  removeDisabled={0 === index && urlsFieldArray.fields.length <= 1}
                  handleRemove={() => urlsFieldArray.remove(index)}
                >
                  <div className={'flex gap-2'}>
                    <div style={{ maxWidth: 100 }}>
                      <Input
                        {...register(`urls.${index}.name`)}
                        id={`urls[${index}].name`}
                        label={`URL #${index + 1}`}
                        hideLabel
                        placeholder={'Name'}
                      />
                    </div>
                    <div className={'flex-grow'}>
                      <Input
                        {...register(`urls.${index}.link`)}
                        id={`urls[${index}].link`}
                        label={`URL #${index + 1}`}
                        hideLabel
                        placeholder={'Please enter a URL'}
                      />
                    </div>
                  </div>
                </ListItem>
              ))}
            </div>
            {/* Additional information */}
            <Input
              {...register('additional_info')}
              type={'textarea'}
              id={'additional_info'}
              name={'additional_info'}
              label={'Additional information'}
              placeholder={'Enter any additional information'}
            />

            <div
              className={'bg-gray-100 block-container n-block-container border-light border-t border-b'}
              style={{ marginTop: 25 }}
            >
              <p className={'field-mb'}>Assign this strategy to one or more goals.</p>
              <Controller
                control={control}
                render={({ field }) => (
                  <Selector
                    {...field}
                    isMulti
                    id={'goals'}
                    label={'Goals'}
                    hideLabel
                    placeholder={'Select one or more goals'}
                    options={goalsQuery.data}
                  />
                )}
                name={'goals'}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Modal.CancelButton onClick={() => toggle(false)} />
            <Modal.ConfirmButton
              type={'submit'}
              label={mutation.isLoading ? 'Loading...' : strategy ? 'Update Strategy' : 'Add Strategy'}
              disabled={mutation.isLoading}
            />
          </Modal.Footer>
        </form>
      </DndProvider>
    </Modal>
  );
};

export default StrategyModal;
