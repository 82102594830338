import React from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { AnySchema } from 'yup';
import DragAndDropFile from '../../../form/dragAndDrop/DragAndDropFile';
import { FILE_RESOURCE_TYPE, WEBSITE_RESOURCE_TYPE } from '../../pathway/pathwaysPage.types';

const AddFileModal = ({ toggle, setFile }: ModalToggleProps & { setFile: any }) => {
  return (
    <Modal>
      <Formik
        initialValues={{
          type: FILE_RESOURCE_TYPE,
          name: null,
          url: null,
          file: null,
        }}
        validationSchema={Yup.object({
          type: Yup.string().nullable().required(),
          name: Yup.string()
            .nullable()
            .when('type', (type: string, schema: AnySchema) => {
              if (WEBSITE_RESOURCE_TYPE === type) {
                return Yup.string().required();
              }
              return schema;
            }),
          url: Yup.string()
            .nullable()
            .when('type', (type: string, schema: AnySchema) => {
              if (WEBSITE_RESOURCE_TYPE === type) {
                return Yup.string().required();
              }
              return schema;
            }),
          file: Yup.mixed()
            .nullable()
            .test('file-required', 'The file is required.', (resource, { parent }) => {
              if (parent.type !== FILE_RESOURCE_TYPE) {
                return true;
              }
              return resource !== null;
            })
            .test('file-size', 'The file you are attempting to upload exceed 6mb.', (resource) => {
              //6MB
              if (resource?.size >= 6 * 1000 * 1000) {
                return false;
              }
              return true;
            }),
        })}
        validateOnMount={true}
        onSubmit={(values) => {
          setFile(values);
          toggle(false);
        }}
      >
        {({ values, handleSubmit, setFieldValue, isValid, touched, errors, setFieldTouched }) => (
          <form className={'py-5'} onSubmit={handleSubmit}>
            <Modal.Body>
              <>
                <div className="mt-4 field-mb">
                  <DragAndDropFile
                    file={values.file}
                    setFile={(file) => {
                      setFieldValue('file', file);
                      setFieldTouched('file', true);
                    }}
                    error={touched.file ? errors.file : ''}
                    text={'Click or drag and drop to add a file'}
                  />
                </div>
              </>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'}/>
              <Modal.ConfirmButton type={'submit'} label={'Add file'} disabled={!isValid}/>
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddFileModal;
