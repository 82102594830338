import React from 'react';
import Input from '../../../form/input/input';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { CHECK_SUBSCRIPTION, MAT_SCHOOLS } from '../../../service/queryKeys';
import { createOrUpdateSchools, getMatSchools, postCheckSubscription } from '../../../service/api';
import Alert from '../../../global/alert/alert';
import classnames from 'classnames';
import Tooltip from '../../../global/tooltip/tooltip';
import Button from '../../../global/button/button';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import { toast } from 'react-toastify';
import useModal from '../../../hooks/useModal';
import CopySignupInstructionModal from '../modals/copySignupInstructionsModal';

const SchoolsSections = ({ me }: { me: { email: string; role: string; link?: string } }) => {
  const queryClient = useQueryClient();

  const { data: schools } = useQuery(MAT_SCHOOLS, getMatSchools, {
    select: (data) => data.data.data,
    placeholderData: [],
  });

  const subscription = useQuery(
    [CHECK_SUBSCRIPTION, me.email, me.role],
    () => postCheckSubscription(me.email, me.role),
    {
      select: (data) => data.data.data,
    }
  );

  const updateGreatTeachingLead = useMutation(createOrUpdateSchools, {
    onSuccess: () => {
      queryClient.invalidateQueries(MAT_SCHOOLS);
      toast.success('Schools updated');
    },
  });

  const licensesUsed = () => {
    let licenses = 0;
    schools?.map((school: any) => (licenses += school?.slots));
    return licenses;
  };

  const originalSchools = schools.map((school: any) => school.name);

  const [
    copySignupInstructionsModal,
    toggleCopySignupInstructionsModal,
    setCopySignupInstructionsModal,
  ] = useModal((props: any) => <CopySignupInstructionModal {...props} />);

  return (
    <>
      {copySignupInstructionsModal}
      <Alert className={'mb-5'} type={'info'}>
        <p className={'mb-2'}>Number of teachers included in the subscription: {subscription.data?.max_teachers}</p>
        <p>
          You have currently allocated {licensesUsed()} licences. You have{' '}
          {subscription.data?.max_teachers - licensesUsed()} licences remaining.
        </p>
      </Alert>
      <Formik
        initialValues={{
          schools: [...schools],
        }}
        enableReinitialize={true}
        validationSchema={Yup.object({
          schools: Yup.array()
            .of(
              Yup.object().shape({
                name: Yup.string().trim().max(150).required(),
                slots: Yup.number().min(1).required(),
                coordinator_name: Yup.string().trim().max(32).required(),
                coordinator_lastname: Yup.string().trim().max(32).required(),
                coordinator_email: Yup.string().email().max(180).required(),
              })
            )
            .test('max-slots', '', (value: any) => {
              const total = value.reduce((acc: number, val: any) => acc + (val.slots || 0), 0);

              return total <= (subscription.data?.max_teachers ?? 0);
            }),
        })}
        validateOnMount={true}
        isInitialValid={false}
        onSubmit={(values) => {
          updateGreatTeachingLead.mutate(values.schools);
        }}
      >
        {({ values, setFieldValue, handleSubmit, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            {values.schools.map((school, index) => (
              <div key={index} className={classnames({ 'animate-fade-in': 0 !== index })}>
                <div className={'bg-gray-100 border border-light p-5 mb-5'}>
                  {1 < values.schools.length && (
                    <div className={'flex items-center justify-between w-full mb-5'}>
                      <h2 key={values.schools.length} className={'animate-flip-horizontal'}>
                        # {index + 1}
                      </h2>
                      <Tooltip content={'Remove this school'}>
                        <Button
                          id={`remove-${index}`}
                          style={{ padding: 0 }}
                          mainColor={'danger'}
                          isOutline
                          onClick={() =>
                            setFieldValue(
                              'schools',
                              values.schools.filter((s, i) => i !== index)
                            )
                          }
                          disabled={values.schools.length <= 1 || values.schools[index].id}
                        >
                          <Icon icon={GLOBAL_ICONS.close} container={false} />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                  <div className={'mb-5'}>
                    <Input
                      id={`name-${index}`}
                      label="School Name"
                      placeholder={'Enter the school name'}
                      required
                      value={school.name}
                      onChange={({ target }) => setFieldValue(`schools[${index}].name`, target.value)}
                    />
                  </div>
                  <div className={'mb-5'}>
                    <Input
                      type={'number'}
                      id={`slots-${index}`}
                      min={0}
                      label="Max. number of teachers"
                      placeholder={'Enter the maximum number of teachers'}
                      required
                      value={school.slots}
                      onChange={({ target }) => setFieldValue(`schools[${index}].slots`, parseInt(target.value))}
                    />
                  </div>
                  <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
                    <div className={'mb-5'}>
                      <Input
                        id={`coordinator-name-${index}`}
                        label="School Coordinator First Name"
                        placeholder={'Enter the first name of the School Coordinator'}
                        required
                        value={school.coordinator_name}
                        onChange={({ target }) => setFieldValue(`schools[${index}].coordinator_name`, target.value)}
                        disabled={school.id != null}
                      />
                    </div>
                    <div className={'mb-5'}>
                      <Input
                        id={`coordinator-lastname-${index}`}
                        label="School Coordinator Last Name"
                        placeholder={'Enter the last name of the School Coordinator'}
                        required
                        value={school.coordinator_lastname}
                        onChange={({ target }) => setFieldValue(`schools[${index}].coordinator_lastname`, target.value)}
                        disabled={school.id != null}
                      />
                    </div>
                  </div>
                  <div className={'mb-5'}>
                    <Input
                      type={'email'}
                      id={`coordinator-email-${index}`}
                      label="School Coordinator Email"
                      placeholder={'Enter the email of the School Coordinator'}
                      required
                      value={school.coordinator_email}
                      onChange={({ target }) => setFieldValue(`schools[${index}].coordinator_email`, target.value)}
                      disabled={school.id != null}
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className={'w-full flex bg-gray-100 border border-dashed border-light p-5'}>
              <Button
                id="add"
                className={'m-auto'}
                mainColor={'secondary'}
                isOutline
                size={'sm'}
                onClick={() =>
                  setFieldValue('schools', [
                    ...values.schools,
                    {
                      name: '',
                      slots: '',
                    },
                  ])
                }
              >
                Add school
              </Button>
            </div>
            <div className={'md:grid grid-flow-col'}>
              <Button
                className={'mt-5'}
                id="submit"
                type="submit"
                disabled={!isValid || isSubmitting}
                onClick={() => {
                  const changedSchools = values.schools.map((school: any) => school.name);
                  const newSchools = changedSchools.slice(originalSchools.length);
                  if (newSchools.length > 0 && changedSchools.length > originalSchools.length) {
                    setCopySignupInstructionsModal({
                      newSchools: newSchools,
                      link: me.link,
                    });
                    toggleCopySignupInstructionsModal(true);
                  }
                }}
              >
                {isSubmitting ? 'Loading...' : 'Update'}
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SchoolsSections;
