module.exports = {
    theme_colors: {
        primary: '#ff6500',
        secondary: '#252525',
    },
    system_colors: {
        success: '#087f5d',
        danger: '#cb3b63',
        warning: '#ba5406',
        info: '#1077b0',
        white: '#fff',
        black: '#000',
        light: '#e5e7eb',
        muted: '#4b5563',
        transparent: 'transparent',
    },
    font_family: {
        body: ['Futura PT Book', 'sans-serif'],
        display: ['Futura PT Demi', 'sans-serif']
    },
    border_radius: {
        app: '3px'
    },
    animation: {
        'fade-in': 'fade_in 450ms cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards',
        'fade-right': 'fade_right 450ms cubic-bezier(0.390, 0.575, 0.565, 1.000) forwards',
        'flip-horizontal': 'flip_horizontal 450ms cubic-bezier(0.455, 0.030, 0.515, 0.955) both'
    },
    keyframes: {
        fade_in: {
            '0%': { transform: 'translateY(50px); opacity: 0;' },
            '100%': { transform: 'translateY(0); opacity: 1;' },
        },
        fade_right: {
            '0%': { transform: 'translateX(50px); opacity: 0;' },
            '100%': { transform: 'translateX(0); opacity: 1;' },
        },
        flip_horizontal: {
            '0%': { transform: 'rotateX(0);' },
            '100%': { transform: 'rotateX(-360deg);' },
        },
    }
};
