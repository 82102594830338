import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { SIGNATURE } from '../../../service/queryKeys';
import { getSignature } from '../../../service/api';
import Button from '../../../global/button/button';
import Tooltip from '../../../global/tooltip/tooltip';
import dayjs from 'dayjs';
import { LinkStyled } from '../../../global/link/link.styled';

const ZoomJoin = ({
  name,
  email,
  startTime,
  link,
}: {
  name: string;
  email: string;
  startTime: string;
  link: string;
}) => {
  const { data: zoomSignature } = useQuery(SIGNATURE, () => getSignature(), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: null,
  });

  const webinarStartTime: number = new Date(startTime).getTime();
  const currentTime: number = new Date().getTime();
  const tenMinutesValue = 600000;
  const timeDifference: number = webinarStartTime - currentTime;
  const joinedWebinar = localStorage.getItem('webinar');
  const [showWebinar, setShowWebinar] = useState(!!joinedWebinar);
  const [showText, setShowText] = useState(!!joinedWebinar);
  const [webinarStarted, setWebinarStarted] = useState(timeDifference <= tenMinutesValue);

  const join = async (join: boolean) => {
    join ? localStorage.setItem('webinar', 'Joined') : localStorage.removeItem('webinar');

    const ZoomMtgEmbedded = await import('@zoomus/websdk/embedded');

    setShowWebinar(true);
    setTimeout(() => setShowText(true), 10000);

    const client = ZoomMtgEmbedded.default.createClient();

    const meetingSDKElement = document.getElementById('meetingSDKElement');

    await client.init({
      zoomAppRoot: meetingSDKElement!,
      language: 'en-US',
      customize: {
        video: {
          isResizable: true,
          viewSizes: {
            default: {
              width: meetingSDKElement ? meetingSDKElement.offsetWidth - 40 : 1400,
              height: meetingSDKElement ? meetingSDKElement.offsetHeight - 40 : 720,
            },
            ribbon: {
              width: meetingSDKElement ? meetingSDKElement.offsetWidth - 40 : 1400,
              height: meetingSDKElement ? meetingSDKElement.offsetHeight - 40 : 720,
            },
          },
          popper: {
            disableDraggable: true,
          },
        },
        chat: {
          popper: {
            disableDraggable: false,
            anchorElement: meetingSDKElement,
            placement: 'top',
          },
        },
      },
    });

    join
      ? zoomSignature &&
        (await client.join({
          signature: zoomSignature?.webinar?.signature,
          sdkKey: zoomSignature?.webinar?.sdkKey,
          meetingNumber: zoomSignature?.webinar?.webinar_id,
          password: zoomSignature?.webinar?.passcode,
          userName: name,
          userEmail: email,
        }))
      : await client.leaveMeeting();

    !join &&
      setTimeout(function () {
        window.location.reload();
      });
  };

  return (
    <>
      <div className={`flex ${!showWebinar && 'invisible h-0'}`}>
        <div className=" w-full flex-1 relative mb-8">
          <div style={{ aspectRatio: '16/9' }} className={'ml-4 w-full h-min aspect-video flex'} id="meetingSDKElement">
            {showWebinar && showText && (
              <div className={'m-auto italic text-blue-600 text-xl text-center w-1/2 xl:w-full'}>
                <p className={'mb-2'}>
                  If you have left the webinar and wish to re-join, please click below to reset the page
                </p>
                <div className={'mt-8'}>
                  <Button
                    id={'reset_webinar_settings'}
                    onClick={() => {
                      join(false);
                    }}
                  >
                    Reset
                  </Button>
                </div>
                <p className={'mt-16 mb-2 text-sm'}>
                  If you have problems joining the webinar, please{' '}
                  <LinkStyled
                    id={'join_webinar_zoom_link'}
                    target={'_blank'} href={link}
                    >
                    click here
                  </LinkStyled>{' '}
                  to open zoom in a new tab
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      {!showWebinar && (
        <div className={'flex'}>
          <Tooltip
            disabled={webinarStarted}
            content={`This webinar will start at ${dayjs(startTime).format(
              'HH:mm'
            )}, please come back a few minutes before then to join`}
          >
            <div className={'m-auto'}>
              <Button
                id={'join_webinar_button'}
                disabled={!webinarStarted}
                size={'lg'}
                onClick={() => {
                  join(true);
                }}
              >
                Join the webinar
              </Button>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export default ZoomJoin;
