import React from 'react';
import TagIcon, { TagType } from './TagIcon';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';

const secondsToTimeFormat = (seconds: number) => {
  const h = Math.floor(seconds / 3600)
      .toString()
      .padStart(2, '0'),
    m = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(2, '0'),
    s = Math.floor(seconds % 60)
      .toString()
      .padStart(2, '0');

  if (h === '00') {
    return m + ':' + s;
  }

  return h + ':' + m + ':' + s;
};

const TagOnVideo = ({ tag, onClose }: TagOnVideoType) => {
  return (
    <div
      className={'absolute rounded-lg'}
      style={{
        backgroundColor: '#4f5658',
        top: 0,
        right: 0,
      }}
    >
      <Icon
        className={'ml-auto cursor-pointer'}
        elementSize={20}
        icon={GLOBAL_ICONS.close}
        container={false}
        color={'white'}
        onClick={onClose}
      />
      <div className={'p-5 pt-0'}>
        <div className={'flex justify-between gap-2'}>
          <TagIcon name={tag.icon.name} colour={tag.icon.colour} key={tag.id} />
          <span className={'text-white'}>{tag.name}</span>
          <span className={'text-gray-400'}>{secondsToTimeFormat(tag.time)}</span>
        </div>
        {tag.comment && (<span className={'text-gray-400'}>{tag.comment}</span>)}
      </div>
    </div>
  );
};

type TagOnVideoType = {
  tag: TagType;
  onClose: () => void;
};

export default TagOnVideo;
