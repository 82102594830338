import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import { ROLE_MAT_COORDINATOR, ROLE_SCHOOL_COORDINATOR, transformRoleApiToLinkParam } from '../../../resources/roles';
import { UserType } from '../userManagementPage';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Label from '../../../form/common/label';
import Alert from '../../../global/alert/alert';
import Button from '../../../global/button/button';
import Icon from '../../../global/icon/icon';
import DefaultTable from '../../../global/table/defaultTable';
import Modal from '../../../global/messages/modal/modal.components';
import { GLOBAL_ICONS, SIZE_ICON } from '../../../resources/vars';

const ViewSubscriptionInfoModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => (
  <Modal>
    <Modal.Body>
      <Alert className={'field-mb'}>
        <CopyToClipboard
          text={
            user?.token
              ? `${window.location.hostname}/register?t=${user.token}&r=${transformRoleApiToLinkParam(user.role)}`
              : ''
          }
          onCopy={() => toast.success('Registration link has been copied')}
        >
          <Button asLink mainColor={'info'} size={'sm'} style={{ padding: 0 }} className={'inline-flex gap-2'}>
            Click here <Icon elementSize={SIZE_ICON['sm']} icon={GLOBAL_ICONS['copy']} container={false} />
          </Button>
        </CopyToClipboard>
        <span className={'ml-2'}> to copy the registration link for this school.</span>
      </Alert>
      {ROLE_MAT_COORDINATOR == user?.role && (
        <React.Fragment>
          <DefaultTable
            id={'mat_schools'}
            itemCounter={{ single: 'school', plural: 'schools' }}
            noDataMessage={'This user does not manage any schools.'}
            columns={[
              { id: 'school_name', label: 'School name' },
              { id: 'max_number', label: 'Max. number of teachers' },
              { id: 'coordinator_name', label: 'Coordinator name' },
              { id: 'coordinator_email', label: 'Coordinator email' },
            ]}
            rows={user.schools.map((school: any) => {
              return {
                id: school.id,
                cells: [
                  {
                    id: 'school_name',
                    content: school.name,
                  },
                  {
                    id: 'max_number',
                    content: school.slots,
                  },
                  {
                    id: 'coordinator_name',
                    content: school.coordinator_name,
                  },
                  {
                    id: 'coordinator_email',
                    content: school.coordinator_email,
                  },
                ],
              };
            })}
          />
        </React.Fragment>
      )}
      {ROLE_SCHOOL_COORDINATOR == user?.role && (
        <React.Fragment>
          <div className={'sm-field-mb'}>
            <Label id={'school_name'} label={'School Name'} />
            <div className={'bg-light item-container'}>{user.organisation}</div>
          </div>
          <div className={'field-mb'}>
            <Label id={'max_num'} label={'Max. number of teachers'} />
            <div className={'bg-light item-container'}>{user.slots}</div>
          </div>
          <DefaultTable
            id={'school_teachers'}
            itemCounter={{ single: 'teacher', plural: 'teachers' }}
            noDataMessage={'This user does not manage any teachers.'}
            columns={[
              { id: 'name', label: 'Full Name' },
              { id: 'email', label: 'Email' },
            ]}
            rows={user.teachers.map((teacher: any) => {
              return {
                id: teacher.id,
                cells: [
                  { id: 'name', content: <strong>{`${teacher?.first_name} ${teacher?.last_name}`}</strong> },
                  {
                    id: 'email',
                    content: teacher.email,
                  },
                ],
              };
            })}
          />
        </React.Fragment>
      )}
    </Modal.Body>
    <Modal.Footer>
      <Modal.CancelButton label={'Close'} onClick={() => toggle(false)} />
    </Modal.Footer>
  </Modal>
);

export default ViewSubscriptionInfoModal;
