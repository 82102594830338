import React, { HTMLAttributes } from 'react';
import Tooltip from '../../../global/tooltip/tooltip';

const TagComment = ({
  comment,
  isSession,
  toggleCommentModal,
}: Pick<TagIconType, 'comment' | 'isSession' | 'toggleCommentModal'>) => {
  {
    if (isSession) {
      if (comment) {
        return (
          <button
            onClick={() => {
              if (toggleCommentModal) {
                toggleCommentModal();
              }
            }}
          >
            {comment}
          </button>
        );
      }

      return (
        <button
          onClick={() => {
            if (toggleCommentModal) {
              toggleCommentModal();
            }
          }}
        >
          Add comment
        </button>
      );
    }

    if (comment) {
      return <p>{comment}</p>;
    }

    return null;
  }
};

const TagIcon = ({
  colour,
  tagName,
  name,
  comment,
  className,
  style,
  isSession = false,
  onClick,
  removeTag,
  toggleCommentModal,
  showToolTip = false,
}: TagIconType & HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        height: '28px',
        width: '28px',
      }}
      onClick={onClick}
    >
      <Tooltip
        disabled={!showToolTip}
        content={
          <ul className={'more-actions-list'}>
            <li className={'text-center'}>
              <span className={'font-bold text-lg'}>
                {tagName} - {name}
              </span>
            </li>
            <li>
              <TagComment comment={comment} isSession={isSession} toggleCommentModal={toggleCommentModal} />
            </li>
            {isSession && (
              <li>
                <button
                  className={'text-red-700 font-bold'}
                  onClick={() => {
                    if (removeTag) {
                      removeTag();
                    }
                  }}
                >
                  Remove tag
                </button>
              </li>
            )}
          </ul>
        }
        theme={'light-border'}
      >
        <div
          style={{
            backgroundColor: colour,
            height: '28px',
            width: '28px',
            borderRadius: '4px',
            textAlign: 'center',
          }}
        >
          <span className={'text-white font-black font-mono text-xl'}>{name}</span>
        </div>
      </Tooltip>
    </div>
  );
};
export type TagIconType = {
  colour: string;
  comment?: string;
  name: string;
  tagName?: string;
  isSession?: boolean;
  removeTag?: () => void;
  toggleCommentModal?: () => void;
  showToolTip?: boolean;
};

export const TEMP_ID_PREFIX = 'temp_uuid_';
export type TagType = {
  comment?: string;
  tag_id?: number;
  subtag_id?: number;
  id?: number | string;
  name: string;
  time: number;
  icon: TagIconType;
};

export default TagIcon;
