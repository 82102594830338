import React from 'react';
import { Image, StyleSheet, Text, View, Font } from '@react-pdf/renderer';
import EBE_LOGO_DARK from '@app/asset/images/Great_Teaching_Toolkit_Logo_Dark.png';
import FONT from '@app/asset/fonts/futura-pt-demi/FuturaPT-Demi.ttf';
import FONT2 from '@app/asset/fonts/futura-pt-book/FuturaPT-Book.ttf';

const PDFLayout = ({ children }: any) => children;

const PADDING = '25px';
const FONT_BASE = 12;
const FONT_SM = '7px';

export const MARGIN_LEFT_TEXT = '25px';
export const MARGIN_TOP_PAGE = '25px';

Font.register({ family: 'Futura PT Demi, sans-serif', src: FONT });
Font.register({ family: 'Futura PT Book, sans-serif', src: FONT2 });

export const PDF_STYLES = StyleSheet.create({
  page: {
    // backgroundColor: 'rgba(229, 231, 235, 1)',
    fontSize: `${FONT_BASE}px`,
    colorAdjust: 'exact',
    '-webkit-print-color-adjust': 'exact',
  },
  view: {
    backgroundColor: 'white',
    padding: PADDING,
    margin: `0 ${PADDING}`,
    pageBreakAfter: 'always',
    breakAfter: 'always',
  },
  headline: {
    fontSize: `${FONT_BASE}px`,
    fontFamily: 'Futura PT Demi, sans-serif',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: PADDING,
  },
  logo: {
    width: 100,
    height: 'auto',
  },
  copy: {
    fontSize: FONT_SM,
    marginBottom: '5px',
  },
  imageContainer: {
    display: 'flex',
    width: '100%',
  },
  image: {
    height: 'auto',
    margin: 'auto',
  },
});

const Header = ({ noTimestamp = false }: { noTimestamp?: boolean }) => (
  <View style={PDF_STYLES.header}>
    <View>
      <Image src={EBE_LOGO_DARK} style={PDF_STYLES.logo} />
    </View>
    {!noTimestamp && (
      <View>
        <Text style={PDF_STYLES.copy}>© Evidence Based Education {new Date().getFullYear()}</Text>
        <Text style={PDF_STYLES.copy}>
          Printed on: {new Date().toLocaleDateString()} {new Date().toLocaleTimeString()}
        </Text>
      </View>
    )}
  </View>
);

const Section = ({
  headline,
  additionalText,
  children,
}: {
  headline: string;
  children: React.ReactNode;
  additionalText?: string | null;
}) => (
  <View wrap={false}>
    <Text style={{ ...PDF_STYLES.headline, marginBottom: '5px' }}>{headline}</Text>
    {additionalText && <Text style={{ ...PDF_STYLES.headline, marginTop: '0px' }}>{additionalText}</Text>}
    <View style={PDF_STYLES.view}>
      <View style={PDF_STYLES.imageContainer}>{children}</View>
    </View>
  </View>
);

const Table = ({
  headers,
  rows,
  tableStyles,
}: {
  headers: Array<any>;
  rows: Array<Array<Record<string, any>>>;
  tableStyles?: Record<string, any>;
}) => {
  const styles = StyleSheet.create({
    table: {
      width: '90%',
      margin: MARGIN_LEFT_TEXT,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #e5e7eb',
    },
    header: {
      borderTop: 'none',
      backgroundColor: '#e5e7eb',
      display: 'flex',
      flexDirection: 'row',
    },
    cell: {
      paddingTop: 10,
      paddingBottom: 10,
    },
  });

  return (
    <View style={{ ...styles.table, ...tableStyles }}>
      <View style={styles.header}>
        {headers.map((header) => (
          <Text
            key={Math.random()}
            style={{
              ...PDF_STYLES.headline,
              ...styles.cell,
              textAlign: header.textAlign ?? 'left',
              paddingLeft: (header.textAlign ?? 'left') === 'left' ? '10px' : undefined,
              width: header.width ?? `${100 / headers.length}%`,
              borderRight: '1px solid white',
              fontSize: header.fontSize ?? `${FONT_BASE}px`,
            }}
          >
            {header.value}
          </Text>
        ))}
      </View>
      {rows.map((row) => (
        <View key={Math.random()} style={styles.row} wrap={false}>
          {row.map((cell, index) => (
            <Text
              key={Math.random()}
              style={{
                ...styles.cell,
                textAlign: headers[index].textAlign ?? 'left',
                paddingLeft: (headers[index].textAlign ?? 'left') === 'left' ? '10px' : undefined,
                paddingRight: (headers[index].textAlign ?? 'left') === 'left' ? '10px' : undefined,
                width: headers[index].width ?? `${100 / headers.length}%`,
                borderRight: '1px solid #e5e7eb',
                fontSize: `${(headers[index].fontSize ?? FONT_BASE) - 2}px`,
              }}
            >
              {cell.value}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

PDFLayout.Table = Table;
PDFLayout.Header = Header;
PDFLayout.Section = Section;

export default PDFLayout;
