import React, { useEffect, useState } from 'react';
import ModelPage from './components/mfgtPage';
import ElementPage from './components/elementPage';
import { useLocation } from 'react-router-dom';

const ModelForGreatTeachingPage = () => {
  const location = useLocation();
  const elementString = location.hash.substring(1); // Extracts the string after the hash

  const [view, setView] = useState<string>(elementString ? 'element' : 'model');

  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);
    if (elementString) {
      setView('element');
    } else {
      setView('model');
    }
  }, [location]);

  return (
    <>{view === 'model' ? <ModelPage /> : view === 'element' ? <ElementPage slug={elementString} /> : <>ERROR</>}</>
  );
};

export default ModelForGreatTeachingPage;
