import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../form/input/input';

const EditTitleModal = ({
  toggle,
  videoTitle,
  setTitle,
}: ModalToggleProps & { videoTitle: string; setTitle: (title: string) => void }) => {
  return (
    <Modal>
      <Formik
        initialValues={{
          title: videoTitle,
        }}
        validationSchema={Yup.object({
          title: Yup.string().trim().required().max(200),
        })}
        isInitialValid={false}
        onSubmit={(values) => {
          setTitle(values.title)
          toggle(false)
        }}
      >
        {({ values, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="mb-8">
                <Input id="title" required value={values.title} onChange={handleChange} />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton type="submit" disabled={!isValid} label={'Edit'} />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditTitleModal;
