import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { UserType } from '../userManagementPage';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Modal from '../../../global/messages/modal/modal.components';
import Input from '../../../form/input/input';
import { useMutation, useQueryClient } from 'react-query';
import { toast } from 'react-toastify';
import { removeMatAdminPrivileges } from '../../../service/api';
import { USER_MANAGEMENT_LIST } from '../../../service/queryKeys';

const VALIDATION_SCHEMA = Yup.object().shape({
  first_name: Yup.string().required('Please introduce the First Name'),
  last_name: Yup.string().required('Please introduce the Last Name'),
  email: Yup.string().email('Please introduce a valid email.').required('Please introduce the Email'),
});

const RemoveMatAdminPrivilegesModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();
  const removePrivileges = useMutation(removeMatAdminPrivileges, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toggle(false);
      toast.success('Removed privileges successfully');
    },
  });

  return (
    <Formik
      initialValues={{
        first_name: '',
        last_name: '',
        email: '',
      }}
      validationSchema={VALIDATION_SCHEMA}
      validateOnMount
      onSubmit={(values) => removePrivileges.mutate({ id: user.id, ...values })}
    >
      {({ values, handleChange, handleBlur, errors, isValid, handleSubmit, touched }) => (
        <Modal>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <p className={'sm-field-mb'}>
                Are you sure you want to remove the Group Administrator privileges for this user and deactivate the
                account?
              </p>
              <p className={'sm-field-mb'}>
                If you want to continue, you will need to provide the details of the new Group Administrator.
              </p>
              <div className={'mb-8'}>
                <Input
                  id={'first_name'}
                  label={'First name'}
                  placeholder={'Please enter the first name of the new Group Admin'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.first_name}
                  error={touched.first_name ? (errors.first_name as string) : null}
                  required
                />
              </div>
              <div className={'mb-8'}>
                <Input
                  id={'last_name'}
                  label={'Last name'}
                  placeholder={'Please enter the last name of the new Group Admin'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.last_name}
                  error={touched.last_name ? (errors.last_name as string) : null}
                  required
                />
              </div>
              <div>
                <Input
                  id={'email'}
                  label={'Email'}
                  type={'email'}
                  placeholder={'Please enter the email of the new Group Admin'}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  error={touched.email ? (errors.email as string) : null}
                  required
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                type={'submit'}
                disabled={!isValid || removePrivileges.isLoading}
                label={!removePrivileges.isLoading ? 'Confirm' : 'Loading...'}
              />
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default RemoveMatAdminPrivilegesModal;
