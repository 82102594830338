import React from 'react';
import Empty from '../../../global/empty/empty';
import DefaultTable from '../../../global/table/defaultTable';
import Icon from '../../../global/icon/icon';
import { MoreInfo } from '../../../global/tooltip/common';
import { IconCell, MoreInfoTooltip, StatusCell } from '../components';
import Button from '../../../global/button/button';
import classnames from 'classnames';
import useModal from '../../../hooks/useModal';
import { AllCompleteConfirmModal } from '../modals';
import { SectionType } from '../instrument.types';
import { useMutation, useQueryClient } from 'react-query';
import { deleteInstrument, putInstrument } from '../../../service/api';
import { capitalize } from 'lodash';
import { Section } from '../../common';
import {
  ACTIVE_INSTRUMENTS,
  COMPLETED_INSTRUMENTS_OPTIONS,
  FEEDBACK_PERIOD,
  FEEDBACK_SCHOOL_CLASS_OPTIONS,
  INSTRUMENT_PERIODS,
  TIMEFRAME_OPTIONS,
} from '../../../service/queryKeys';
import { ActionButton } from '../../../global/buttonIcon/common';
import { toast } from 'react-toastify';
import { RowType } from '../../../global/table/table.types';
import DeleteConfirmModal from '../modals/DeleteConfirmModal';
import { DIMENSION_SURVEY_TYPE, ELEMENT_SURVEY_TYPE, MODEL_SURVEY_TYPE, VERSION_C } from './instrumentForm';
import { SCHOOL_ENV_TYPE } from '../SchoolEnvironmentPage';
import { STUDENTS_SURVEYS_TYPE } from '../StudentSurveysPage';

const HOST_NAME = window.location.origin;
const MIN_RESPONSES_TO_COMPLETE = 5;

const InstrumentMonitor = ({ id, type, data, isEarlyAccessStarter = false }: PropType) => {
  const queryClient = useQueryClient();

  const [completeModal, toggleCompleteModal, setCompleteModalProps] = useModal(
    (props: any) => <AllCompleteConfirmModal {...props} />,
    {
      title: 'Are you sure?',
      size: 'sm',
    }
  );

  const [deleteModal, toggleDeleteModal, setDeleteModalProps] = useModal(
    (props: any) => <DeleteConfirmModal {...props} />,
    {
      title: 'Are you sure?',
      size: 'sm',
    }
  );

  const completeInstrumentMutation = useMutation(putInstrument, {
    onSuccess: () => {
      queryClient.invalidateQueries([INSTRUMENT_PERIODS, type], { exact: true });
      queryClient.invalidateQueries([ACTIVE_INSTRUMENTS, type], { exact: true });
      queryClient.invalidateQueries(FEEDBACK_PERIOD);
      queryClient.invalidateQueries(FEEDBACK_SCHOOL_CLASS_OPTIONS);
      queryClient.invalidateQueries([TIMEFRAME_OPTIONS]);
      queryClient.invalidateQueries([COMPLETED_INSTRUMENTS_OPTIONS, SCHOOL_ENV_TYPE]);
    },
  });

  const deleteInstrumentMutation = useMutation(deleteInstrument, {
    onSuccess: () => {
      queryClient.invalidateQueries([ACTIVE_INSTRUMENTS, type], { exact: true });
    },
  });

  const formatStudentSurveysRows = (instrument: InstrumentType) => {
    const rows: RowType[] = [];

    for (const schoolClass of instrument.school_classes) {
      rows.push(
        ...schoolClass.student_surveys.map((survey, i) => {
          const hasPartedSurvey =
            survey.video_option === 'Teacher-led' &&
            survey.survey_version === VERSION_C &&
            !instrument.element &&
            !instrument.dimension;

          return {
            id: `class_${survey.id}`,
            cells: [
              {
                id: 'class',
                content: <IconCell label={schoolClass.name} />,
                rowSpan: schoolClass.student_surveys.length,
                hide: i !== 0,
              },
              {
                id: 'survey_type',
                content: instrument?.element
                  ? ELEMENT_SURVEY_TYPE
                  : instrument?.dimension
                  ? DIMENSION_SURVEY_TYPE
                  : MODEL_SURVEY_TYPE,
                rowSpan: schoolClass.student_surveys.length,
                hide: i !== 0,
              },
              {
                id: 'version',
                content: (
                  <div>
                    {instrument?.element && <p>{instrument.element.title}</p>}
                    {instrument?.dimension && <p>{instrument.dimension.title}</p>}
                    <p>{survey.survey_version}</p>
                    <i>{survey.video_option}</i>
                  </div>
                ),
                rowSpan: hasPartedSurvey ? 3 : 1,
                hide: survey.video_option === 'Teacher-led' && i !== 0,
              },
              {
                id: 'option',
                class: survey.video_option === 'Student-led' ? 'top' : '',
                content: hasPartedSurvey ? (
                  !survey.part ? (
                    'Option 1'
                  ) : (
                    <div>
                      <p>Option 2</p>
                      <i>Split</i>
                    </div>
                  )
                ) : (
                  <i className={'text-muted my-auto'}>—</i>
                ),
                hide: survey.part === 2,
                rowSpan: survey.part === 1 ? 2 : 1,
              },
              {
                id: 'video',
                class: !survey.video ? 'top' : '',
                content: survey.video ? (
                  <div>
                    {survey.part && <p>Part {survey.part}</p>}
                    <div className={'flex items-center gap-3'}>
                      <div className={'flex items-center'}>
                        <ActionButton.Copy
                          text={survey.video?.link}
                          onCopy={() => toast.success('Video link has been copied')}
                          mainColor={'secondary'}
                          size={'sm'}
                          label={'Copy video link'}
                        />
                        <sub className={'text-light ml-2'}>/</sub>
                      </div>
                      <div>
                        <div>({survey.video?.questions} questions)</div>
                        <div className={'text-sm -mt-1'}>{survey.video?.time}</div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <i className={'text-muted my-auto'}>—</i>
                ),
              },
              {
                id: 'survey',
                content: (
                  <ActionButton.Copy
                    text={`${HOST_NAME}${survey.link}`}
                    onCopy={() => toast.success('Survey link has been copied')}
                    mainColor={'secondary'}
                    size={'sm'}
                    label={'Copy survey link'}
                  />
                ),
              },
              {
                id: 'count',
                content: (
                  <StatusCell
                    isClass
                    studentNums={{ current: schoolClass.responses, max: schoolClass.expected_responses }}
                  />
                ),
                rowSpan: schoolClass.student_surveys.length,
                hide: i !== 0,
              },
            ],
          };
        })
      );
    }

    return rows;
  };

  const COLUMNS = [
    { id: 'class', label: 'Survey for' },
    { id: 'version', label: 'Version' },
    { id: 'survey', label: 'Survey Link' },
  ];

  if (!isEarlyAccessStarter) COLUMNS.push({ id: 'count', label: 'Responses' });

  const formatSchoolRows = (instrument: InstrumentType) => {
    const rows: RowType[] = [];

    const CELLS = [
      {
        id: 'class',
        content: <IconCell label={instrument.school} />,
      },
      {
        id: 'version',
        content: (
          <div>
            <p>{instrument.survey_version}</p>
          </div>
        ),
      },
      {
        id: 'survey',
        content: (
          <ActionButton.Copy
            text={`${HOST_NAME}${instrument.link}`}
            onCopy={() => toast.success('Survey link has been copied')}
            mainColor={'secondary'}
            size={'sm'}
            label={'Copy survey link'}
          />
        ),
      },
    ];

    if (!isEarlyAccessStarter)
      CELLS.push({
        id: 'count',
        content: (
          <StatusCell isClass studentNums={{ current: instrument.responses, max: instrument.expected_responses }} />
        ),
      });

    rows.push({
      id: `class_${1}`,
      cells: CELLS,
    });

    return rows;
  };

  return (
    <>
      {deleteModal}
      {completeModal}
      <Section
        id={id}
        className={'field-mb'}
        headline={'Monitor'}
        more={
          <div className={'flex items-center gap-3'}>
            <Icon icon={'CircleFill'} color={data.length === 0 ? 'muted' : 'success'} />
            <p className={'text-muted italic'}>
              {data.length === 0
                ? 'No active surveys'
                : `You have ${data.length} active survey${1 < data.length ? 's' : ''}`}
            </p>
          </div>
        }
      >
        {data.length === 0 ? (
          <Empty element={'active instances'} action={<u>filling the form at top.</u>} />
        ) : (
          <>
            {SCHOOL_ENV_TYPE === type ? (
              <>
                {data.map((instrument: InstrumentType, index: number) => {
                  return (
                    <div key={`monitor_${type}_${index}`} className={classnames({ 'mb-8': index + 1 < data.length })}>
                      <DefaultTable
                        id={`monitor-element--${index}`}
                        showCounter={false}
                        columns={COLUMNS}
                        rows={formatSchoolRows(instrument)}
                      />
                      <div className={'flex justify-end mt-5 gap-2'}>
                        <Button
                          size={'sm'}
                          onClick={() => {
                            setDeleteModalProps({
                              handleSubmit: () => deleteInstrumentMutation.mutateAsync(instrument.id),
                            });
                            toggleDeleteModal(true);
                          }}
                        >
                          Remove
                        </Button>
                        <Button
                          disabled={instrument.responses < MIN_RESPONSES_TO_COMPLETE}
                          size={'sm'}
                          onClick={() => {
                            setCompleteModalProps({
                              handleSubmit: () =>
                                completeInstrumentMutation.mutateAsync({
                                  id: instrument.id,
                                  completed: true,
                                }),
                            });

                            toggleCompleteModal(true);
                          }}
                        >
                          Complete
                        </Button>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                {data.map((instrument, index) => (
                  <>
                    <div className={'flex justify-end mb-3'}>
                      <MoreInfo
                        content={
                          <MoreInfoTooltip
                            instrument={capitalize(instrument.instance_type)}
                            period={instrument.development_period}
                          />
                        }
                      />
                    </div>
                    <div className={classnames({ 'mb-8': index + 1 < data.length })}>
                      <DefaultTable
                        id={'monitor-baseline'}
                        additionalTableClasses={'table--no-hover'}
                        showCounter={false}
                        columns={[
                          { id: 'class', label: 'Survey for' },
                          { id: 'survey_type', label: 'Type' },
                          { id: 'version', label: 'Version', colSpan: 2 },
                          { id: 'video', label: 'Video' },
                          { id: 'survey', label: 'Survey' },
                          { id: 'count', label: 'Responses' },
                        ]}
                        rows={formatStudentSurveysRows(instrument)}
                      />
                      <div className={'flex justify-end mt-5 gap-2'}>
                        <Button
                          size={'sm'}
                          onClick={() => {
                            setDeleteModalProps({
                              handleSubmit: () => deleteInstrumentMutation.mutateAsync(instrument.id),
                            });
                            toggleDeleteModal(true);
                          }}
                        >
                          Remove
                        </Button>
                        <Button
                          disabled={
                            instrument.school_classes.filter(
                              (schoolClass) => schoolClass.responses < MIN_RESPONSES_TO_COMPLETE
                            ).length > 0
                          }
                          size={'sm'}
                          onClick={() => {
                            setCompleteModalProps({
                              handleSubmit: () =>
                                completeInstrumentMutation.mutateAsync({
                                  id: instrument.id,
                                  completed: true,
                                }),
                            });
                            toggleCompleteModal(true);
                          }}
                        >
                          Complete
                        </Button>
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </>
        )}
      </Section>
    </>
  );
};

type PropType = {
  id: SectionType;
  type: typeof STUDENTS_SURVEYS_TYPE | typeof SCHOOL_ENV_TYPE;
  data: InstrumentType[];
  isEarlyAccessStarter?: boolean;
};

export type InstrumentType = {
  id: string;
  element?: {
    title: string;
    value: string;
  };
  dimension?: {
    title: string;
    value: string;
  };
  instance_type: string;
  school_students_type: string;
  development_period?: string;
  school_classes: SchoolClass[];

  // School type properties
  school: string;
  responses: number;
  expected_responses: number;
  survey_version: string;
  link: string;
};

type Survey = {
  id: string;
  link: string;
  survey_version: 'Version D' | 'Version C';
  video_option?: 'Student-led' | 'Teacher-led';
  part?: null | 1 | 2;
  video?: { link: string; questions: number; time: string } | null;
};

type SchoolClass = {
  id: string;
  name: string;
  student_surveys: Survey[];
  responses: number;
  expected_responses: number;
};

export default InstrumentMonitor;
