import React from 'react';
import DashboardLayout from '../../layout/dashboard';
import { Section } from '../common';
import { useQuery } from 'react-query';
import { GLOBAL_NOTIFICATIONS } from '../../service/queryKeys';
import { getNotifications } from '../../service/api';
import GlobalNotificationsTable from './sections/globalNotificationsTable';
import useModal from '../../hooks/useModal';
import AddGlobalNotificationModal from './modals/addGlobalNotification.modal';
import { AddButton } from '../../global/button/common';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import dayjs from "dayjs";
dayjs.extend(customParseFormat)

export type NotificationType = {
  id: string;
  title: string;
  message: string;
  type: 'global' | 'automated';
  start_date: string;
  end_date: string;
  read: boolean;
};

const GlobalNotificationsManagementPage = () => {
  const activeNotificationsQuery = useQuery(
    [GLOBAL_NOTIFICATIONS, 'active'],
    () => getNotifications({ show: 'active' }),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const inactiveNotificationsQuery = useQuery(
    [GLOBAL_NOTIFICATIONS, 'inactive'],
    () => getNotifications({ show: 'inactive' }),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const [modal, toggle, setProps] = useModal((props: any) => <AddGlobalNotificationModal {...props} />);

  const editAction = (notification: NotificationType) => {
    setProps({
      title: 'Edit global notification',
      notification: {
        ...notification,
        start_date: dayjs(notification.start_date, 'DD/MM/YYYY').toDate(),
        end_date: dayjs(notification.end_date, 'DD/MM/YYYY').toDate(),
      },
    });
    toggle(true);
  };

  return (
    <React.Fragment>
      {modal}
      <DashboardLayout
        title={'Global Notification Management'}
        pageAction={
          <AddButton
            label={'Add global notification'}
            onClick={() => {
              setProps({
                title: 'Add new notification',
              });
              toggle(true);
            }}
          />
        }
      >
        <Section
          className={'field-mb'}
          headline={'Active Notifications'}
          more={
            0 < activeNotificationsQuery.data.length
              ? `${activeNotificationsQuery.data.length} notification${
                  1 !== activeNotificationsQuery.data.length ? 's' : ''
                }`
              : ''
          }
        >
          <GlobalNotificationsTable
            data={activeNotificationsQuery.data}
            editAction={(notification) => editAction(notification)}
          />
        </Section>
        <Section
          className={'field-mb'}
          headline={'Inactive Notifications'}
          more={
            0 < inactiveNotificationsQuery.data.length
              ? `${inactiveNotificationsQuery.data.length} notification${
                  1 !== inactiveNotificationsQuery.data.length ? 's' : ''
                }`
              : ''
          }
        >
          <GlobalNotificationsTable
            data={inactiveNotificationsQuery.data}
            editAction={(notification) => editAction(notification)}
          />
        </Section>
      </DashboardLayout>
    </React.Fragment>
  );
};

export default GlobalNotificationsManagementPage;
