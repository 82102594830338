import React, { useEffect, useState } from 'react';
import DashboardLayout from '../../../layout/dashboard';
import { Section } from '../../common';
import Button from '../../../global/button/button';
import NewResource from './newResource';
import Algolia from '../../algolia/components/algolia';
import DraftResources from './draftResources';
import { useLocation } from 'react-router-dom';
import TextResourcePage from '../../algolia/components/textResourcePage';
import useModal from '../../../hooks/useModal';
import GenerateRedirectLinkModal from '../modals/generateRedirectLink';

const ResourceDashboard = () => {
  const [newResource, setNewResource] = useState<boolean>(false);
  const [viewResources, setViewResources] = useState<boolean>(false);
  const [viewDraftResources, setViewDraftResources] = useState<boolean>(false);
  const [editorMode, setEditorMode] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);
    const fragmentString = location.hash.substring(1); // Extracts the string after the hash
    setShowResourceSubpage(fragmentString);
  }, [location]);

  const [showResourceSubpage, setShowResourceSubpage] = useState<any>();

  const [generateRedirectModal, toggleGenerateRedirectModal, setGenerateRedirectModalProps] = useModal((props: any) => (
    <GenerateRedirectLinkModal {...props} />
  ));

  return (
    <>
      {generateRedirectModal}
      {showResourceSubpage ? (
        <TextResourcePage slug={showResourceSubpage} />
      ) : (
        <DashboardLayout title={'Resource dashboard'}>
          <Section className={'mb-4'} headline={'Resource dashboard'}>
            <div className={'flex'}>
              <div className={'m-auto'}>
                <Button
                  isOutline={!newResource}
                  disabled={viewResources || viewDraftResources}
                  onClick={() => setNewResource(!newResource)}
                  className={'m-2'}
                >
                  {newResource ? 'Cancel' : 'Add new resource'}
                </Button>
                <Button
                  disabled={newResource}
                  isOutline={!viewResources}
                  className={'m-2'}
                  onClick={() => {
                    setViewResources(!viewResources);
                    setViewDraftResources(false);
                    setEditorMode(false);
                  }}
                >
                  {viewResources ? 'Hide ' : 'View '} resources
                </Button>
                <Button
                  disabled={newResource}
                  isOutline={!viewDraftResources}
                  className={'m-2'}
                  onClick={() => {
                    setViewDraftResources(!viewDraftResources);
                    setViewResources(false);
                    setEditorMode(false);
                  }}
                >
                  {viewDraftResources ? 'Hide ' : 'View '} unpublished resources
                </Button>
                <Button
                  disabled={!(viewResources || viewDraftResources)}
                  isOutline={!editorMode}
                  onClick={() => {
                    setEditorMode(!editorMode);
                  }}
                  className={'m-2'}
                >
                  {editorMode ? 'Disable ' : 'Enable '}editor mode
                </Button>
              </div>
            </div>
            <div className={'flex'}>
              <Button
                onClick={() => {
                  toggleGenerateRedirectModal(true);
                }}
                className={'m-auto mt-8'}
                size={'sm'}
              >
                Generate Re-direct Link
              </Button>
            </div>
          </Section>
          {newResource && (
            <>
              <Section headline={'Add new resource'}>
                <NewResource editMode={false} cancelResource={setNewResource} />
              </Section>
            </>
          )}
          {viewResources && (
            <>
              <Algolia admin editor={editorMode} />
            </>
          )}
          {viewDraftResources && (
            <>
              <DraftResources editor={editorMode} />
            </>
          )}
        </DashboardLayout>
      )}
    </>
  );
};

export default ResourceDashboard;
