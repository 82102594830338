import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';
import { SizeType } from '../../../resources/types';

const PADDING_SIZE: Record<SizeType, string> = {
  xsm: '',
  sm: 'item-container',
  md: 'block-container',
  lg: 'section-container',
};

type SectionProps = {
  headline?: React.ReactNode | string | null;
  more?: React.ReactNode | string | null;
  size?: SizeType | null;
  background?: 'white' | 'transparent';
  flex?: boolean;
  children: React.ReactNode;
  pathwayPeriods?: boolean;
  containerClassName?: string;
  gray?: boolean;
};

const Section = ({
  headline = null,
  more = null,
  size = 'lg',
  background = 'white',
  flex = false,
  children,
  pathwayPeriods = false,
  containerClassName,
  gray = false,
  ...other
}: SectionProps & HTMLAttributes<HTMLDivElement>) => (
  <div {...other} className={`sm-field-mb ${gray ? 'h-full bg-gray-100' : ''}`}>
    {(headline || more) && (
      <div className={'flex justify-between items-center sm-field-mb'}>
        {null != headline && <div className={'text-muted'}>{headline}</div>}
        {more && more}
      </div>
    )}
    <div
      id={pathwayPeriods ? 'stonly_term_dates' : undefined}
      className={`${containerClassName} bg-${background} ${classnames({
        'flex-1': flex,
        'container-shadow': 'transparent' !== background,
      })} ${size ? `${PADDING_SIZE[size]}` : ''} ${gray ? 'bg-gray-100' : ''}`}
    >
      {children}
    </div>
  </div>
);

export default Section;
