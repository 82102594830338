import React from 'react';
import { capitalize } from '../../../helpers/misc';
import Icon from '../../icon/icon';
import Tag from '../../tag/tag';
import { GLOBAL_ICONS } from '../../../resources/vars';

type ArchivedProps = {
  archived?: boolean;
  tagged?: boolean;
  admin?: boolean;
  title: string;
  item: string;
  customTag?: string;
  type?: 'archived' | 'deactivated' | 'disabled';
};

export const Layout = ({
  admin,
  tagged,
  archived,
  title,
  item,
  customTag,
  type = 'archived',
}: ArchivedProps): React.ReactElement => (
  <div className={'animate-fade-right flex items-center gap-4'}>
    {admin && <Tag content={'Great teaching coordinator'} />}
    {tagged && <Tag content={'Great Teaching Lead'} />}
    {customTag && <Tag content={customTag} />}
    {archived && <Icon icon={GLOBAL_ICONS[type]} container={false} color={'primary'} elementSize={18} />}
    <div className={'leading-4'}>
      <div>{title}</div>
      {archived && <small className={'text-primary'}>{`${capitalize(item)} ${type}`}</small>}
    </div>
  </div>
);

const ArchivedName = ({ admin, archived, tagged = false, title, item, type }: ArchivedProps): React.ReactElement =>
  admin || archived || tagged ? (
    <Layout tagged={tagged} admin={admin} archived={archived} title={title} item={item} type={type} />
  ) : (
    <span>{title}</span>
  );

export default ArchivedName;
