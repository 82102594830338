import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getAcademicYears } from '../../../service/api';
import { ACADEMIC_YEARS } from '../../../service/queryKeys';
import Select, { OptionTypeBase } from 'react-select';
import CPDPassportTable from '../components/cpdPassportTable';
import { Section } from '../../common';
import { ROLE_TEACHER_FREE, RolesType } from '../../../resources/roles';

const CPDPassportSection = ({
  userRole,
  setShow,
  hidden,
}: {
  userRole: RolesType;
  setShow?: any;
  hidden?: boolean | string | undefined;
}) => {
  const [academicYearOption, setAcademicYearOption] = useState<OptionTypeBase | null>(null);

  const [hideActivitiesTable, setHideActivitiesTable] = useState(false);
  const [hidePathwaysTable, setHidePathwaysTable] = useState(false);

  const { data: academicYears, isFetched } = useQuery([ACADEMIC_YEARS], () => getAcademicYears(), {
    staleTime: Infinity,
    select: (data) => data.data,
    placeholderData: [],
  });

  const isFree = userRole === ROLE_TEACHER_FREE;

  useEffect(() => {
    if (isFree && (hideActivitiesTable || hidePathwaysTable)) {
      setShow(true);
    }
  }, [hideActivitiesTable, hidePathwaysTable]);

  return (
    <>
      <Select
        placeholder={'Select Academic Year'}
        isSearchable={true}
        isClearable={!!academicYearOption}
        options={academicYears!.map((academicYear) => ({ label: academicYear.name, value: academicYear.id }))}
        value={academicYearOption ?? { label: 'Select academic year', value: null }}
        onChange={(option) => setAcademicYearOption(option)}
        className={'w-full lg:w-1/3 mb-3'}
      />
      <Section size={null}>
        <CPDPassportTable
          academicYear={academicYearOption?.value}
          userRole={userRole}
          setHideActivitiesTable={setHideActivitiesTable}
          setHidePathwaysTable={setHidePathwaysTable}
          hidden={hidden}
        />
      </Section>
    </>
  );
};

export default CPDPassportSection;
