import React from 'react';
import { TabButtonStyled } from '../../../global/tab/tab.styled';

const PathwaysTabButton = ({ label, disabled = false, ...props }: { label: string, disabled?: boolean }): JSX.Element => {
  return (
    <TabButtonStyled
      {...props}
      disabled={disabled}
      style={{ background: 'white', padding: '0 0 0 10px', textAlign: 'center', width: '100%' }}
    >
      <div className={'align-middle inline-block h-full py-4 w-4/6 '}>{label}</div>
      <div className={'bg-primary text-white h-full'} data-icon={'+'} />
    </TabButtonStyled>
  );
};

export default PathwaysTabButton;
