import React from 'react';
import classnames from 'classnames';
import { LabelStyled } from './field.styled';
import { FieldProps, LabelProps } from './field.types';

interface LabelGroupProps extends FieldProps, LabelProps {}

const Label: React.FC<LabelGroupProps> = ({
  id,
  label,
  hideLabel,
  required,
  hintText,
  description,
}: LabelGroupProps) => {
  const getLabel = (
    <LabelStyled description={description} htmlFor={id} className={classnames({ 'sr-only': hideLabel })}>
      {label}
      {required && <span className={'text-danger'}>*</span>}
    </LabelStyled>
  );

  return (
    <>
      <div className={classnames({ 'flex justify-between items-center': hintText })}>
        {getLabel} {hintText}
      </div>
      <div className={classnames({ 'text-muted mb-2': description })}>{description}</div>
    </>
  );
};

Label.displayName = 'Label';

Label.defaultProps = {
  hideLabel: false,
};

export default Label;
