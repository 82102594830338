import React, { ButtonHTMLAttributes, HTMLAttributes } from 'react';
import { ModalContentProps, ModalButtonProps } from './modal.types';
import Button from '../../button/button';
import { ButtonProps } from '../../button/button.types';

const Modal = ({ children }: ModalContentProps) => <React.Fragment>{children}</React.Fragment>;

// BODY WRAPPER
const Body = ({ children, ...other }: ModalContentProps & HTMLAttributes<HTMLDivElement>) => (
  <div className={'block-container flex-grow'} {...other}>
    {children}
  </div>
);

// FOOTER WRAPPER
const Footer = ({ children, ...other }: ModalContentProps & HTMLAttributes<HTMLDivElement>) => (
  <div className={'block-container border-t flex items-center justify-end gap-5 mt-8'} {...other}>
    {children}
  </div>
);

// CANCEL BUTTON
const CancelButton = ({
  label = 'Cancel',
  ...other
}: ModalButtonProps & ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <Button size={'sm'} mainColor={'secondary'} asLink {...(other as ButtonProps)}>
    {label}
  </Button>
);

// CONFIRM BUTTON
const ConfirmButton = ({
  label = 'Confirm',
  ...other
}: ModalButtonProps & ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element => (
  <Button size={'sm'} {...(other as ButtonProps)}>
    {label}
  </Button>
);

Modal.Body = Body;
Modal.Footer = Footer;
Modal.CancelButton = CancelButton;
Modal.ConfirmButton = ConfirmButton;

export default Modal;
