import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation } from 'react-query';
import { deleteVideo } from '../../../service/api';
import { useNavigate } from 'react-router-dom';
import routeBuilder from '../../../service/routeBuilder';

const EditTitleModal = ({ toggle, videoId }: ModalToggleProps & { videoId: number }) => {
  const navigate = useNavigate();

  const deleteVideoMutation = useMutation(deleteVideo, {
    onSuccess: () => {
      toggle(false);
      navigate(routeBuilder('videoObservation').generate());
    },
    onError: () => {
      navigate(routeBuilder('videoObservation').generate());
    },
  });

  return (
    <Modal>
      <Modal.Body>Are you sure you want delete this video?</Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} />
        <Modal.ConfirmButton
          id={'deleteVideoModal_deleteButton'}
          type="button"
          onClick={() => deleteVideoMutation.mutate(videoId)}
          label={'Delete'}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default EditTitleModal;
