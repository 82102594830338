import React, { MutableRefObject, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { VIDEO_TAGS } from '../../../service/queryKeys';
import { getVideoTags } from '../../../service/api';
import TagSet, { TagSetType } from '../components/TagSet';
import { TagType } from '../components/TagIcon';
import Selector from '../../../form/select/selector';
import { SelectOptionType } from '../../../service/types';
import { Record } from '@styled-icons/bootstrap';

const filterTagsByOption = (tagSets?: TagSetType[], option?: SelectOptionType) => {
  if (null == option || null == tagSets) {
    return tagSets ?? [];
  }

  return tagSets.filter((tagSet) => {
    return null != tagSet.created_by && option.value === tagSet.created_by.id;
  });
};

const FeedbackTab = ({
  videoId,
  videoLength,
  videoElement,
  selectTag,
  currentVideoTime,
  userId,
  onEdit,
}: FeedbackTabType) => {
  const [userSelected, setUserSelected] = useState<SelectOptionType>();
  const [userOptions, setUserOptions] = useState<SelectOptionType[]>();

  const { data: tagSets } = useQuery([VIDEO_TAGS, videoId], () => getVideoTags(videoId!), {
    select: (data) => data.data.data,
    placeholderData: [],
    onSuccess: (data: TagSetType[]) => {
      const options: Record<number | string, SelectOptionType> = {};
      for (const item of data) {
        if (null == item.created_by || options[item.created_by.id] != null) {
          continue;
        }

        options[item.created_by.id] = {
          label: item.created_by.name,
          value: item.created_by.id,
        };
      }

      setUserOptions(Object.values(options));
    },
  });

  const filteredTagSets = filterTagsByOption(tagSets, userSelected);

  const groupedTags = filteredTagSets.reduce(
    (acc: TagSetType, current: TagSetType) => {
      acc.tags.push(...current.tags);
      return acc;
    },
    {
      tags: [],
    }
  );

  useEffect(() => {
    const tag = groupedTags.tags.find((tag) => tag.time <= currentVideoTime && currentVideoTime <= tag.time + 3);
    selectTag(tag);
  }, [currentVideoTime]);

  return (
    <>
      <div className={'flex inline-flex w-full justify-start gap-3 m-3 items-center'}>
        <span className={'whitespace-nowrap'}>Filter by:</span>
        <Selector
          className={'w-5/12'}
          id={'users'}
          options={userOptions}
          value={userSelected}
          onChange={(option: SelectOptionType) => setUserSelected(option)}
          placeholder={'Please select a user'}
          isSearchable={false}
        />
      </div>
      {[groupedTags, ...filteredTagSets].map((tagSet: TagSetType) => (
        <TagSet
          userId={userId}
          tagSet={tagSet}
          key={Math.random()}
          videoLength={videoLength}
          videoElement={videoElement}
          onClick={(tag: TagType) => {
            selectTag(tag);
            videoElement.current.currentTime = tag.time;
            document.getElementById('root')!.scrollTo(0, 0);
          }}
          onEdit={onEdit}
        />
      ))}
    </>
  );
};

type FeedbackTabType = {
  videoId?: string;
  userId: string;
  videoLength: number;
  videoElement: MutableRefObject<any>;
  selectTag: (tag?: TagType) => void;
  currentVideoTime: number;
  onEdit: (tagSet: TagSetType) => void;
};

export default FeedbackTab;
