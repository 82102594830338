import React from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { DateRangePicker } from '../../../form/datepicker/common';
import Input from '../../../form/input/input';
import Alert from '../../../global/alert/alert';
import { useMutation, useQueryClient } from 'react-query';
import { postOrPutDevelopmentPeriods } from '../../../service/api';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { DEVELOPMENT_PERIODS, INSTRUMENT_OPTIONS } from '../../../service/queryKeys';
import { PeriodProps } from '../../pathway/pathwaysPage.types';
import { LinkStyled } from '../../../global/link/link.styled';

const INITIAL_STATE: PeriodProps = {
  name: '',
  start_date: null,
  end_date: null,
};

type DevelopmentPeriodsModalProps = {
  period?: PeriodProps;
  single?: boolean;
};

const DevelopmentPeriodsModal = ({ period, toggle, single }: ModalToggleProps & DevelopmentPeriodsModalProps) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(postOrPutDevelopmentPeriods, {
    onSuccess: () => {
      queryClient.invalidateQueries(DEVELOPMENT_PERIODS);
      queryClient.invalidateQueries(INSTRUMENT_OPTIONS);
      toggle(false);
    },
  });

  return (
    <Modal>
      <Formik
        initialValues={{
          ...INITIAL_STATE,
          ...period,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().trim().required().max(32),
          start_date: Yup.date().required(),
          end_date: Yup.date().required(),
        })}
        validateOnMount={true}
        onSubmit={(values) => {
          mutation
            .mutateAsync({
              ...values,
              start_date: dayjs(values.start_date as Date).format('YYYY/MM/DD'),
              end_date: dayjs(values.end_date as Date).format('YYYY/MM/DD'),
            })
            .then(() => {
              if (values.id) {
                toast.success('Updated Term');
                return;
              }
              toast.success('Added new Term');
            })
            .catch((error) => {
              toast.error(error.response.data.message ?? 'An error has occurred.');
            });
        }}
      >
        {({ values, setFieldValue, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Alert className={'field-mb'}>
                <p>
                  A Term date is a length of time, set by you, during which {single ? 'you' : 'all of your teachers'}{' '}
                  are able to work through a cycle of CPD activities. Term dates cannot span across more than one
                  academic year. For more info on Term dates, click{' '}
                  <LinkStyled
                    href={
                      'https://support.evidencebased.education/en/knowledge/setting-up-academic-years-and-term-dates'
                    }
                    target={'_blank'}
                  >
                    here
                  </LinkStyled>
                  .
                </p>
              </Alert>
              {/* Name */}
              <div className={'field-mb'}>
                <Input
                  required
                  id={'name'}
                  label={'Term name'}
                  placeholder={'Enter a name for this Term date (e.g. "Spring Term 2022")'}
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              {/* Start/End date */}
              <DateRangePicker
                required
                start={values.start_date}
                end={values.end_date}
                onStartChange={(date) => setFieldValue('start_date', date)}
                onEndChange={(date) => setFieldValue('end_date', date)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                disabled={!isValid || mutation.isLoading}
                type={'submit'}
                label={mutation.isLoading ? 'Loading' : period ? 'Update Term date' : 'Add Term date'}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default DevelopmentPeriodsModal;
