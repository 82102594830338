import { DefaultOptionType } from '../../../resources/types';

export const listOfElements: Array<DefaultOptionType> = [
  { label: 'Deep and fluent knowledge', value: '1.1' },
  { label: 'Curriculum sequencing', value: '1.2' },
  { label: 'Relevant curriculum tasks', value: '1.3' },
  { label: 'Strategies and misconceptions', value: '1.4' },
  { label: 'Teacher-student relationships', value: '2.1' },
  { label: 'Student-student relationships', value: '2.2' },
  { label: 'Learner motivation', value: '2.3' },
  { label: 'Climate of high expectations', value: '2.4' },
  { label: 'Managing time and resources', value: '3.1' },
  { label: 'Rules and consequences', value: '3.2' },
  { label: 'Managing disruptive behaviour', value: '3.3' },
  { label: 'Structuring', value: '4.1' },
  { label: 'Explaining', value: '4.2' },
  { label: 'Questioning', value: '4.3' },
  { label: 'Interacting', value: '4.4' },
  { label: 'Embedding', value: '4.5' },
  { label: 'Activating', value: '4.6' },
];
