import React from 'react';
import { LinkStyled } from '../../../global/link/link.styled';
import { GreatTeachingInsetPageInterface } from '../resources/resources';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Icon from '../../../global/icon/icon';
import Tooltip from '../../../global/tooltip/tooltip';
import { toast } from 'react-toastify';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useMutation } from 'react-query';
import { postInsetTracking } from '../../../service/api';

const GreatTeachingInsetPageCard = ({ resource }: GreatTeachingInsetPageInterface) => {
  const postInsetTrackingMutation = useMutation(postInsetTracking, {
    onSuccess: () => {/* Do nothing */},
  });
  return (
    <>
      <div
        id={'resourceCard_'}
        style={{ minHeight: resource?.primary || resource?.additional ? '110px' : '80px' }}
        className={`align-middle flex ${resource?.primary ? 'bg-gray-100' : ''} rounded-md border-2 ${
          resource?.primary ? 'my-4 p-5' : 'my-2 py-2 px-4'
        }`}
      >
        {resource?.logo && (
          <div className={'hidden sm:block md:my-auto md:mr-8 md:ml-0 m-2 w-12 h-12'}>
            <img className={'min-w-12 min-h-12'} src={resource.logo} />
          </div>
        )}
        {resource?.icon && (
          <div className={'hidden sm:block md:my-auto md:mr-6 md:ml-0 m-2 w-12 h-12'}>
            <Icon elementSize={48} icon={GLOBAL_ICONS[resource.icon]} />
          </div>
        )}
        <div className={'w-full flex justify-between'}>
          <div className={'flex items-center justify-between gap-2'}>
            <div className={'flex flex-grow items-center'}>
              <div className={'w-full'}>
                <div className={'flex w-full justify-between mb-2'}>
                  <div className={`${resource?.primary ? 'font-bold' : ''} leading-tight mb-1`}>
                    {resource ? resource.title : 'title'}
                  </div>
                </div>
                <div>
                  <p className={`${resource?.primary ? '' : 'text-sm'}`}>
                    {resource ? resource.description : 'description'}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {resource?.link && (
            <div className={'flex gap-2'}>
              {resource?.fileName ? (
                <Tooltip content={'Download file'}>
                  <LinkStyled
                    download={resource.fileName}
                    href={resource.link}
                    target={'_blank'}
                    id={`download_${resource.id}`}
                    onClick={() => {
                      if (resource.id === 'primary_resource_1') postInsetTrackingMutation.mutateAsync('Primary');
                      else if (resource.id === 'secondary_resource_1')
                        postInsetTrackingMutation.mutateAsync('Secondary');
                    }}
                  >
                    <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                      <Icon elementSize={40} icon={GLOBAL_ICONS['downloadFile']} />
                    </div>
                  </LinkStyled>
                </Tooltip>
              ) : (
                <>
                  <Tooltip content={'Copy link'}>
                    <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                      <LinkStyled id={`copy_${resource.id}`}>
                        <CopyToClipboard
                          text={resource.link}
                          onCopy={() => toast.success('Copied the link')}
                          options={{ format: 'text' }}
                        >
                          <Icon elementSize={40} icon={GLOBAL_ICONS['copy']} />
                        </CopyToClipboard>
                      </LinkStyled>
                    </div>
                  </Tooltip>
                  <Tooltip content={'Open in a new tab'}>
                    <LinkStyled href={resource.link} target={'_blank'} id={`open_${resource.id}`}>
                      <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                        <Icon elementSize={40} icon={GLOBAL_ICONS['view']} />
                      </div>
                    </LinkStyled>
                  </Tooltip>
                  {/*{resource.html && (
                    <Tooltip content={'Open in a new tab'}>
                      <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                        <LinkStyled target={'_blank'}>
                          <div dangerouslySetInnerHTML={{ __html: resource.html }}></div>
                        </LinkStyled>
                      </div>
                    </Tooltip>
                  )}*/}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default GreatTeachingInsetPageCard;
