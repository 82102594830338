import connector from '../connector';
import { ApiResponse } from '../../@types/global';
import { Event } from '../../@types/Entity/Event';

export function addOrUpdateEvent(data: {
  id?: string;
  title: string;
  description: string;
  location: string;
  date: string;
  start_time: string;
  end_time: string;
  schools: 'all' | string[];
  groups: 'all' | string[];
  associate_colleges: 'all' | number[];
}) {
  const values = { ...data };
  delete values.id;

  if (data.id) {
    return connector.put(`/api/events/${data.id}`, values);
  }

  return connector.post('/api/events', values);
}

export function getEvents(showPreviousEvents: boolean) {
  return connector.get<ApiResponse<Event[]>>('/api/events', { params: { show_previous: +showPreviousEvents } });
}

export function getSharedEvents(startDate: string, endDate: string) {
  return connector.get<ApiResponse<Event[]>>('/api/events/shared', {
    params: {
      start_date: startDate,
      end_date: endDate,
    },
  });
}

export function deleteEvent(eventId: string) {
  return connector.delete(`/api/events/${eventId}`);
}
