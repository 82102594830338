import React from 'react';
import routeBuilder from '../../service/routeBuilder';
import DashboardLayout from '../../layout/dashboard';
import DefaultTable from '../../global/table/defaultTable';
import { RouterStyled } from '../../global/link/link.styled';
import Icon from '../../global/icon/icon';
import Tooltip from '../../global/tooltip/tooltip';
import { GLOBAL_ICONS, SIZE_ICON } from '../../resources/vars';
import { useQuery } from 'react-query';
import { getPathwaysHistory } from '../../service/api';
import { Section } from '../common';
import { PATHWAYS_HISTORY } from '../../service/queryKeys';

const COLUMN = [
  { id: 'name', label: 'Development name' },
  { id: 'start_date', label: 'Development start date' },
  { id: 'end_date', label: 'Development end date' },
  { id: 'actions', label: 'Actions' },
];

const PathwaysLogPage = () => {
  const { data } = useQuery(PATHWAYS_HISTORY, getPathwaysHistory, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  return (
    <DashboardLayout title={'Development cycles history'} parent={{ id: 'pathways', label: 'Development cycles' }}>
      <Section>
        <DefaultTable
          apiHandled={true}
          id={'pathways'}
          itemCounter={{
            single: 'pathway',
            plural: 'pathways',
          }}
          columns={COLUMN}
          rows={data?.map((period: any) => {
            return {
              id: period.id,
              cells: [
                { id: 'name', content: period.name },
                { id: 'name', content: period.start_date },
                { id: 'name', content: period.end_date },
                {
                  id: 'name',
                  class: 'actions',
                  content: (
                    <ul className={'actions-list'}>
                      <li>
                        <Tooltip content={'View development cycle'}>
                          <RouterStyled
                            to={routeBuilder('pathways').generate(period.id, period.is_legacy ? 1 : period.version)}
                          >
                            <div data-id={'icon_container'}>
                              <Icon
                                data-id={'icon'}
                                icon={GLOBAL_ICONS['view']}
                                container={false}
                                elementSize={SIZE_ICON['md']}
                              />
                            </div>
                          </RouterStyled>
                        </Tooltip>
                      </li>
                    </ul>
                  ),
                },
              ],
            };
          })}
          noDataMessage={<>There are no terms defined yet.</>}
        />
      </Section>
    </DashboardLayout>
  );
};

export default PathwaysLogPage;
