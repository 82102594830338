import React, { useState } from 'react';
import { LinkStyled, RouterStyled } from '../../../global/link/link.styled';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { GLOBAL_ICONS, SIZE_ICON } from '../../../resources/vars';
import Icon from '../../../global/icon/icon';
import routeBuilder from '../../../service/routeBuilder';
import { ChevronHamburger } from '../../../global/hamburger';
import Tippy from '@tippyjs/react';
import { UserMenuButtonStyled } from '../../common/components/userMenu';
import { Me } from '../../../@types/Entity/Me';
import LogoDark from '@app/asset/images/Great_Teaching_Toolkit_Logo_Dark.png';

const OnboardingHeader = ({ user, version }: { user: Me | undefined; version?: string | undefined }) => {
  const [toggleChevron, setToggleChevron] = useState(false);

  return (
    <header className={'bg-white shadow rounded-b-none rounded-t-md'}>
      <div>
        <div className={'flex items-center justify-between pt-2'}>
          <div className={'flex'}>
            <img
              src={LogoDark}
              width={100}
              alt={'Great Teaching Toolkit'}
              className={`object-scale-down ml-4 my-auto`}
            />
            {version === 'school' && <h2 className={'ml-12 mt-2'}>Quick start guide</h2>}
          </div>
          <div className={'flex items-center my-4'}>
            <Tippy
              trigger={'click'}
              appendTo={'parent'}
              interactive={true}
              interactiveBorder={50}
              arrow={false}
              theme={'light-border'}
              animation={'shift-away'}
              onTrigger={() => setToggleChevron(true)}
              onUntrigger={() => setToggleChevron(false)}
              onClickOutside={() => setToggleChevron(false)}
              content={
                <div className={'text-base'} style={{ margin: '-5px -9px', minWidth: 320 }}>
                  <div className={'flex items-center justify-between hover:bg-gray-100 py-3 z-50 px-4'}>
                    <RouterStyled to={routeBuilder('logout').generate()} className={'block flex-grow'}>
                      Logout
                    </RouterStyled>
                    <Icon
                      icon={GLOBAL_ICONS['logout']}
                      container={false}
                      elementSize={SIZE_ICON['sm']}
                      color={'muted'}
                    />
                  </div>
                </div>
              }
            >
              <UserMenuButtonStyled className={'flex items-center border border-gray-300 hover:bg-light rounded-app'}>
                <div className={'truncate'}>
                  <span className={'hidden md:block'}>{user?.first_name + ' ' + user?.last_name}</span>
                  <span className={'block md:hidden'}>{user?.first_name.charAt(0) + '. ' + user?.last_name}</span>
                </div>
                <div>
                  <ChevronHamburger as={'div'} toggled={toggleChevron} transparent />
                </div>
              </UserMenuButtonStyled>
            </Tippy>
            <div className={'border-l-2 pl-3 mx-3'}>
              <LinkStyled
                id={'stonly_help'}
                href={'https://support.evidencebased.education/en/knowledge'}
                target={'_blank'}
              >
                <ButtonIcon label={'Help'} icon={GLOBAL_ICONS['help']} isFree mainColor={'secondary'} />
              </LinkStyled>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default OnboardingHeader;
