import React, { useRef } from 'react';
import { toast } from 'react-toastify';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import Input from '../../../form/input/input';
import {
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_SYS_ADMIN,
  RolesType,
} from '../../../resources/roles';
import Selector from '../../../form/select/selector';
import { DefaultOptionType } from '../../../resources/types';
import { ActionMeta } from 'react-select';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getDevelopmentGoals,
  getFocusOptions,
  getSchoolGroups,
  getStrategies,
  postOrPutDevelopmentGoal,
} from '../../../service/api';
import * as Yup from 'yup';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';
import { ADMIN_GOALS, ADMIN_STRATEGIES, FOCUS_OPTIONS, SCHOOLS_GROUPS } from '../../../service/queryKeys';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Label from '../../../form/common/label';
import { AddButton } from '../../../global/button/common';
import { Option } from '../../../@types/global';
import Datepicker from '../../../form/datepicker/datepicker';
import dayjs from 'dayjs';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { Goal } from '../../../@types/Entity/Goal';

export const WHOLE_SCHOOL_OPTION = { label: 'Whole school', value: 'whole_school' };

const DevelopmentGoalModal = ({ goal, toggle, role }: ModalToggleProps & { goal?: Goal; role?: RolesType }) => {
  const selectRef = useRef<any>();
  const queryClient = useQueryClient();

  const mutation = useMutation(postOrPutDevelopmentGoal, {
    onSuccess: () => {
      toggle(false);
      queryClient.invalidateQueries(ADMIN_GOALS);
      queryClient.invalidateQueries(ADMIN_STRATEGIES);
    },
  });

  const sysAdminStrategiesQuery = useQuery(
    [ADMIN_STRATEGIES, 'school'],
    () => getStrategies(undefined, undefined, 'school'),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const goalsQuery = useQuery([ADMIN_GOALS, undefined, 'ebe'], () => getDevelopmentGoals(undefined, 'ebe'), {
    staleTime: Infinity,
    select: (data) =>
      data.data.data.map((goal: any) => {
        return { label: goal.name, value: goal.id };
      }),
    placeholderData: [],
    keepPreviousData: true,
    enabled: [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(role as string),
  });

  const schoolGroupsQuery = useQuery([SCHOOLS_GROUPS], () => getSchoolGroups(), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    enabled: [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(role as string),
  });

  const focusOptionsQuery = useQuery(FOCUS_OPTIONS, () => getFocusOptions(), {
    select: (data) => data.data.data,
    staleTime: Infinity,
    enabled: [ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(role as string),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      ...goal,
      element: goal?.element ? goal.element : null,
      strategies: (goal?.strategies ?? []).map((strategy) => ({ label: strategy.name, value: strategy.id })),
      groups: goal?.whole_school_shared
        ? [WHOLE_SCHOOL_OPTION]
        : (goal?.groups ?? []).map((group) => ({
            label: group.name,
            value: group.id,
          })),
      actions: goal?.actions
        ? ((goal?.actions ?? []).map((action) => ({
            ...action,
            tags: action.tags.map((tag) => ({
              label: tag.title,
              value: tag.id,
            })),
          })) as { id?: string; title: string; dueDate: null | string; tags: Option[] }[])
        : [
            {
              title: '',
              dueDate: null,
              tags: [],
            },
          ],
    },
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().max(200).trim().required(),
        groups: Yup.array().test('is-group-required', '', (value) => {
          if (role === ROLE_SYS_ADMIN) {
            return true;
          }

          return value != null && value.length !== 0;
        }),
        actions: Yup.array().of(
          Yup.object().shape({
            title: Yup.string()
              .nullable()
              .test('is-action-title-required', '', (value) => {
                if (role === ROLE_SYS_ADMIN) {
                  return true;
                }

                return value != null && value.trim() !== '';
              }),
            tags: Yup.array(),
            dueDate: Yup.string()
              .nullable()
              .test('is-action-date-required', '', (value) => {
                if (role === ROLE_SYS_ADMIN) {
                  return true;
                }

                return value != null;
              }),
          })
        ),
      })
    ),
  });

  const actionsFieldArray = useFieldArray({ control, name: 'actions' });

  return (
    <Modal>
      <form
        onSubmit={handleSubmit((values) => {
          mutation
            .mutateAsync(
              role === ROLE_SYS_ADMIN
                ? {
                    id: values.id,
                    name: values.name,
                    strategies: values.strategies.map((strategy) => strategy.value),
                  }
                : {
                    ...values,
                    strategies: values.strategies.map((strategy) => strategy.value),
                    element: values.element?.value,
                    whole_school_shared: values.groups[0]?.value === WHOLE_SCHOOL_OPTION.value,
                    groups:
                      values.groups[0]?.value === WHOLE_SCHOOL_OPTION.value
                        ? null
                        : values.groups.map((group) => group.value),
                    actions: values.actions.map((action) => ({
                      ...action,
                      tags: action.tags.map((tagOption: Option) => ({
                        id: tagOption.__isNew__ ? null : tagOption.value,
                        title: tagOption.label,
                      })),
                    })),
                  }
            )
            .then(() => {
              toast.success(values.id ? 'Goal updated' : 'Goal created successfully');
            });
        })}
      >
        <Modal.Body>
          {role === ROLE_SYS_ADMIN && (
            <>
              <div className={'field-mb'}>
                <Input
                  {...register('name')}
                  value={watch('name')}
                  required
                  maxLength={200}
                  id={'name'}
                  label={'Name'}
                  placeholder={'Please enter a goal name'}
                />
              </div>
              <div
                className={'bg-gray-100 block-container n-block-container border-light border-t border-b'}
                style={{ marginTop: 25 }}
              >
                <p className={'field-mb'}>Assign one or more strategies to this goal or add them later.</p>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      isMulti
                      id={'strategies'}
                      label={'Strategies'}
                      placeholder={'Select one or more strategies'}
                      options={sysAdminStrategiesQuery.data.map((strategy: any) => {
                        return {
                          value: strategy.id,
                          label: strategy.name,
                        };
                      })}
                      customComponents={
                        0 !== sysAdminStrategiesQuery.data.length
                          ? null
                          : {
                              MenuList: function MenuList(menuListProps: any) {
                                return (
                                  <MessageAtMenuList
                                    message={"Start adding strategies in 'Development Strategies' section."}
                                    {...menuListProps}
                                  />
                                );
                              },
                            }
                      }
                    />
                  )}
                  name={'strategies'}
                />
              </div>
            </>
          )}
          {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(role as string) && (
            <>
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      id="element"
                      label={'Element/Dimension focus'}
                      placeholder={'Select an Element/Dimension focus'}
                      options={focusOptionsQuery.data}
                      errorMessage={errors.element?.message as string}
                    />
                  )}
                  name={'element'}
                />
              </div>
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      maxLength={200}
                      onFocus={() => {
                        if (watch('name')) {
                          selectRef.current.state.inputValue = watch('name');
                        }
                      }}
                      onBlur={() => {
                        selectRef.current.select.select.blur();
                      }}
                      ref={selectRef}
                      id={'goal'}
                      required
                      isCreatableSelect
                      label={'Name'}
                      value={watch('name') ? { label: watch('name'), value: null } : null}
                      placeholder={'Select or type the name of your school development goal'}
                      options={goalsQuery.data}
                      onChange={(option: DefaultOptionType, action: ActionMeta<any>) => {
                        if (action.action === 'clear') {
                          setValue('name', '');
                        } else {
                          setValue('name', option.label);
                        }
                      }}
                    />
                  )}
                  name={'name'}
                />
              </div>
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      id={'strategies'}
                      label={'Strategy'}
                      placeholder={'Select a strategy'}
                      options={sysAdminStrategiesQuery.data.map((strategy: any) => {
                        return {
                          value: strategy.id,
                          label: strategy.name,
                        };
                      })}
                      onChange={(option: Option) => field.onChange([option])}
                      value={watch('strategies')[0] ?? null}
                    />
                  )}
                  name={'strategies'}
                />
              </div>
              <div className={'field-mb'}>
                <div className={'sm-field-mb'}>
                  <Label
                    label={'Actions'}
                    id={'actions'}
                    hintText={
                      <AddButton
                        isOutline
                        mainColor={'secondary'}
                        label={'Actions'}
                        onClick={() => actionsFieldArray.append({ title: '', tags: [], dueDate: null })}
                      />
                    }
                  />
                </div>
                {actionsFieldArray.fields.map((field, index) => (
                  <div key={Math.random()} className={'mb-4 flex'}>
                    <div className={'flex-1'}>
                      <div className={'flex'}>
                        <div className={'flex-1 border p-2'}>
                          <Input
                            required
                            {...register(`actions.${index}.title`)}
                            id={`action_${field.id}_title`}
                            placeholder={'Action'}
                            error={errors.actions?.[index]?.title?.message}
                          />
                        </div>
                      </div>
                      <div className={'flex'}>
                        <div className={'flex-1 border p-2'}>
                          <Controller
                            control={control}
                            name={`actions.${index}.tags`}
                            render={({ field: { onChange, value } }) => (
                              <Selector
                                id={`action_${field.id}_tags`}
                                placeholder={'Tags'}
                                isMulti
                                isCreatableSelect
                                options={[]}
                                onChange={(value: Option[]) => onChange(value)}
                                value={value}
                                error={errors.actions?.[index]?.tags?.message}
                              />
                            )}
                          />
                        </div>
                        <div className={'flex-1 border p-2'}>
                          <Controller
                            control={control}
                            name={`actions.${index}.dueDate`}
                            render={({ field: { onChange, value } }) => (
                              <Datepicker
                                required
                                id={`action_${field.id}_dueDate`}
                                label={'Due date'}
                                hideLabel
                                placeholder={'Due date'}
                                onChange={(date: Date) => onChange(dayjs(date).format('YYYY-MM-DD'))}
                                selected={null !== value ? dayjs(value, 'YYYY-MM-DD').toDate() : null}
                                error={errors.actions?.[index]?.dueDate?.message}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <ButtonIcon
                      icon={'Trash'}
                      size={'xsm'}
                      isFree
                      mainColor={'muted'}
                      label={'Remove'}
                      onClick={() => actionsFieldArray.remove(index)}
                      disabled={actionsFieldArray.fields.length === 1}
                    />
                  </div>
                ))}
              </div>
              <div className={'field-mb'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      required
                      isMulti
                      id={'groups'}
                      label={'Assigned to'}
                      placeholder={'Select a team'}
                      options={[
                        WHOLE_SCHOOL_OPTION,
                        ...(schoolGroupsQuery.data ?? []).map((team: any) => ({
                          value: team.id,
                          label: team.name,
                        })),
                      ]}
                      onChange={(options: Option[]) => {
                        let values = options;

                        if (options.length > 1) {
                          if (options[options.length - 1].value === WHOLE_SCHOOL_OPTION.value) {
                            values = [options[options.length - 1]];
                          } else {
                            values = options.filter((option) => option.value !== WHOLE_SCHOOL_OPTION.value);
                          }
                        }

                        field.onChange(values);
                      }}
                    />
                  )}
                  name={'groups'}
                />
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} />
          <Modal.ConfirmButton
            type={'submit'}
            label={mutation.isLoading ? 'Loading...' : goal ? 'Save' : 'Add goal'}
            disabled={mutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DevelopmentGoalModal;
