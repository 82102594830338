import React, { useEffect, useState } from 'react';
import Modal from '../global/messages/modal/modal';

const useModal = (Content: any, initialProps = {}): Array<any> => {
  const [isOpen, setOpen] = useState(false);
  const [props, updateProps] = useState<any>(initialProps);
  const [modal, setModal] = useState(<></>);

  const setProps = (newProps: any) => {
    updateProps({
      ...initialProps,
      ...newProps,
    });
  };

  useEffect(() => {
    setModal(
      <Modal
        open={isOpen}
        toggle={() => setOpen(false)}
        title={props.title ?? ''}
        description={props.description ?? ''}
        size={props.size ?? 'md'}
        position={props?.position}
        fullHeight={props?.fullHeight}
        preventCloseOnClickOnMask={props?.preventCloseOnClickOnMask}
        {...props}
      >
        <Content toggle={setOpen} {...props} />
      </Modal>
    );
  }, [props, isOpen]);

  return [modal, setOpen, setProps];
};

export default useModal;
