import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import { transparentize } from 'polished';
import { DEFAULT_TRANSITION_DURATION, DEFAULT_TRANSITION_FUNC } from '../transitions/transitions.types';
import { DEFAULT_THEME } from '../../Theme';
import { SIZE_HEIGHT } from '../../resources/vars';
import { getFocusStyle } from '../../helpers/styles';

export const TabButtonStyled = styled('button')<ThemeProps<DefaultTheme>>`
  appearance: none;
  cursor: pointer;
  height: ${SIZE_HEIGHT['md'] * 1.5}px;
  min-width: ${SIZE_HEIGHT['md'] * 2}px;
  padding: 0 30px;
  background: transparent;
  position: relative;
  transition: all ${DEFAULT_TRANSITION_FUNC} ${DEFAULT_TRANSITION_DURATION}ms;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: ${SIZE_HEIGHT['md'] * 1.5}px;
    left: 0;
    height: 2px;
    width: 100%;
    transform: scaleX(0);
    transform-direction: center;
    background: ${(props) => props.theme.colors.primary};
    transition: all ${DEFAULT_TRANSITION_FUNC} ${DEFAULT_TRANSITION_DURATION}ms;
  }

  &:hover,
  &[data-toggle='true'] {
    background: ${(props) => transparentize(0.35, props.theme.colors.light)};
  }

  &[data-toggle='true'] {
    border-bottom-color: ${(props) => props.theme.colors.primary};
    :after {
      transform: scaleX(1);
    }
  }

  [data-icon] {
    float: right;
    padding: 10px;
    padding-top: 15px;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    &:before {
      content: attr(data-icon);
    }
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:focus {
    ${(props) => getFocusStyle(props.theme.colors.primary)}
  }
`;

TabButtonStyled.defaultProps = {
  theme: DEFAULT_THEME,
};
