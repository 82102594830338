import React from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Input from '../../../form/input/input';
import Button from '../../../global/button/button';
import { useMutation, useQueryClient } from 'react-query';
import { putMe } from '../../../service/api';
import * as Yup from 'yup';
import { Section } from '../../common';
import { ME } from '../../../service/queryKeys';

const SchoolDetailsForm = ({
  school,
}: {
  school : {
    fsm?: number,
    sen?: number,
    ehcp?: number,
    eal?: number,
    category_2: string
  }
}) => {
  const queryClient = useQueryClient();

  const meMutation = useMutation(putMe, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME);
      toast.success('Your school details has been changed');
    },
  });

  return (
    <Section headline={'School details'}>
      <Formik
        initialValues={{
          fsm: school.fsm,
          sen: school.sen,
          ehcp: school.ehcp,
          eal: school.eal,
        }}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          fsm: Yup.number()
            .nullable()
            .max(100, 'Please specify a percentage value between 0 and 100')
            .min(0, 'Please specify a percentage value between 0 and 100')
            .test('is-fsm-required', '', (value) => {
              if (school.category_2 === 'International') {
                return true;
              }

              return value != null;
            }),
          sen: Yup.number()
            .nullable()
            .max(100, 'Please specify a percentage value between 0 and 100')
            .min(0, 'Please specify a percentage value between 0 and 100')
            .required(''),
          ehcp: Yup.number()
            .nullable()
            .max(100, 'Please specify a percentage value between 0 and 100')
            .min(0, 'Please specify a percentage value between 0 and 100')
            .test('is-ehcp-required', '', (value) => {
              if (school.category_2 === 'International') {
                return true;
              }

              return value != null;
            }),
          eal: Yup.number()
            .nullable()
            .max(100, 'Please specify a percentage value between 0 and 100')
            .min(0, 'Please specify a percentage value between 0 and 100')
            .required(''),
        })}
        onSubmit={(values) => meMutation.mutate(values)}
      >
        {({ values, errors, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <p className={'field-mb'}>Please describe the composition of the school in terms of pupil population:</p>
            <div className={'md:grid grid-cols-2 gap-2 field-mb'}>
              <div className={'sm-field-mb'}>
                <Input
                  type={'number'}
                  id="fsm"
                  label="Pupil premium"
                  placeholder={'Please enter a percentage (%)'}
                  value={values.fsm}
                  onChange={handleChange}
                  hintText={'As %'}
                  min={0}
                  max={100}
                  error={errors.fsm}
                  disabled={school.category_2 === 'International'}
                  required={school.category_2 !== 'International'}
                />
              </div>
              <div className={'sm-field-mb'}>
                <Input
                  type={'number'}
                  id="sen"
                  label="SEN"
                  placeholder={'Please enter a percentage (%)'}
                  value={values.sen}
                  onChange={handleChange}
                  hintText={'As %'}
                  min={0}
                  max={100}
                  error={errors.sen}
                  required
                />
              </div>
              <div className={'sm-field-mb'}>
                <Input
                  type={'number'}
                  id="ehcp"
                  label="Education, Health and Care Plan"
                  placeholder={'Please enter a percentage (%)'}
                  value={values.ehcp}
                  onChange={handleChange}
                  hintText={'As %'}
                  min={0}
                  max={100}
                  error={errors.ehcp}
                  disabled={school.category_2 === 'International'}
                  required={school.category_2 !== 'International'}
                />
              </div>
              <div className={'sm-field-mb'}>
                <Input
                  type={'number'}
                  id="eal"
                  label="EAL"
                  placeholder={'Please enter a percentage (%)'}
                  value={values.eal}
                  onChange={handleChange}
                  hintText={'As %'}
                  min={0}
                  max={100}
                  error={errors.eal}
                  required
                />
              </div>
            </div>
            <Button type={'submit'} className={'mt-5'} disabled={!isValid || meMutation.isLoading}>
              {!meMutation.isLoading ? 'Update' : 'Loading...'}
            </Button>
          </form>
        )}
      </Formik>
    </Section>
  );
};

export default SchoolDetailsForm;
