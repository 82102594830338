import React from 'react';
import { Formik } from 'formik';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import Datepicker from '../../../form/datepicker/datepicker';
import Input from '../../../form/input/input';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import { GLOBAL_ICONS } from '../../../resources/vars';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import * as Yup from 'yup';

const TaskModal = ({ toggle, task, cycle }: ModalToggleProps & { task?: any; cycle: any }) => {
  return (
    <Modal>
      <Formik
        initialValues={{
          date: null,
          name: '',
          pie: undefined,
          development_cycle_id: cycle.id,
          ...task,
        }}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          date: Yup.date().required(),
          name: Yup.string().required().max(64),
          pie: Yup.string().nullable(),
        })}
        onSubmit={() => {
          /* Deprecated form */
        }}
      >
        {({ values, handleChange, handleSubmit, setFieldValue, isValid }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              {/* Date */}
              <div className={'field-mb'}>
                <Datepicker
                  required
                  id={'date'}
                  label={'Task date'}
                  placeholder={'Please select a date for this task'}
                  selected={values.date}
                  value={values.date?.toLocaleDateString()}
                  onChange={(date) => setFieldValue('date', date)}
                />
              </div>
              {/* Name / Task */}
              <div className={'field-mb'}>
                <Input
                  required
                  id={'name'}
                  name={'name'}
                  maxLength={64}
                  label={'Task name'}
                  placeholder={'Please type a name for this task'}
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              {/* PIE */}
              <RadioGroupOnBlock
                id={'type'}
                label={'Task type'}
                handleChange={(value) => {
                  if (value === values.pie) {
                    setFieldValue('pie', undefined);
                  } else {
                    setFieldValue('pie', value);
                  }
                }}
                optionChecked={values?.pie}
                options={[
                  { value: 'plan', label: 'Plan', icon: GLOBAL_ICONS['plan'] },
                  { value: 'implement', label: 'Implement', icon: GLOBAL_ICONS['implement'] },
                  { value: 'evaluate', label: 'Evaluate', icon: GLOBAL_ICONS['evaluate'] },
                ]}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton type={'submit'} label={task ? 'Update task' : 'Add task'} disabled={true} />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default TaskModal;
