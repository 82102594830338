import React, { useEffect, useReducer } from 'react';
import { useQuery } from 'react-query';
import { getElementsFeedbackData } from '../../../service/api';
import CustomBarChart, { getColorFomLabel } from '../components/customBarChart';
import Choice from '../../../form/choice/choice';
import { Section } from '../../common';
import { ELEMENT_DATA } from '../../../service/queryKeys';
import { AxiosError } from 'axios';
import Alert from '../../../global/alert/alert';

const ALL_OPTION = 'all';

export const processedElementData = (elementChartData: any, element: string, selectors: string[]) => {
  if (!elementChartData || !elementChartData.data) {
    return {};
  }

  const info: Array<Record<string, any>> = [];
  const keys: Array<{ id: string; name: string }> = [];

  for (const question of elementChartData.questions[element]) {
    const item: Record<string, any> = {
      question: question.title,
      questionDirection: question.direction,
      color: getColorFomLabel(element),
    };

    for (const optionId of selectors) {
      if (!elementChartData.data[element] || !elementChartData.data[element][optionId]) {
        continue;
      }

      if (!keys.some((key) => key.id === optionId)) {
        const option = (elementChartData?.options ?? []).find((i: any) => i.id === optionId)!;
        keys.push(option);
      }

      item[optionId] = elementChartData.data[element][optionId].data[question.title] ?? 0;
      item['ci-' + optionId] = elementChartData.data[element][optionId].ci[question.title] ?? { u: 0, l: 0 };
      item['pse-' + optionId] = elementChartData.data[element][optionId].pse[question.title] ?? 0;
    }

    info.push(item);
  }
  return { info, dataKeys: keys, color: info[0].color };
};

const ElementChartSection = ({
  element,
  timeframeSelections,
  isSchool = false,
}: {
  element: string;
  timeframeSelections?: Array<string>;
  isSchool?: boolean;
}) => {
  const [selectors, setSelector] = useReducer((state: any, action: any) => {
    let values = [...state];

    if (Array.isArray(action.value)) {
      return action.value;
    }

    if (state.includes(action.value)) {
      values = values.filter((value) => value !== action.value && value);
    } else if (values.length <= 3) {
      if (action.value === ALL_OPTION) {
        values = [];
      } else {
        values = values.filter((value) => value !== ALL_OPTION);
      }

      values.push(action.value);
    }

    return [...values];
  }, []);

  const { data: elementChartData, error, isFetching } = useQuery(
    [ELEMENT_DATA, timeframeSelections, element, isSchool],
    () => getElementsFeedbackData(element, timeframeSelections, isSchool),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!elementChartData) {
      return;
    }

    setSelector({
      value: elementChartData.options.map((option) => option.id).filter((option) => option !== ALL_OPTION),
    });
  }, [elementChartData]);

  if (isFetching) {
    return (
      <Alert type="info" className={'field-mb'}>
        Loading...
      </Alert>
    );
  }

  if ((error as AxiosError)?.response?.status === 403) {
    return (
      <Section headline={element} className={'field-mb'}>
        <Alert type="info" className={'field-mb'}>
          {(error as AxiosError)?.response?.data.message}
        </Alert>
      </Section>
    );
  }

  if (isSchool && !elementChartData?.data) {
    return null;
  }

  return (
    <Section headline={element} className={'field-mb'}>
      <div className={'border-b field-mb pb-8'}>
        <div className={'flex flex-col lg:flex-row lg:items-center gap-4'}>
          {elementChartData?.options?.map((option) => (
            <div key={option.id}>
              <Choice
                id={'summary-choices-instrument'}
                label={option.name}
                type={'switch'}
                checked={selectors?.includes(option.id)}
                onChange={() => setSelector({ value: option.id })}
                className={'pretty-no-margin'}
              />
              {isSchool ? <div className={'mt-2 italic text-sm'}>
                  {option.teachers && option.teachers > 0 ?
                    <p>{option.teachers} teachers contributed to this feedback</p> : <></>}
                  {option.responses && option.responses > 0 ?
                    <p>{option.responses} students submitted a response</p> : <></>}
                </div>
                : <></>}
            </div>
          ))}
        </div>
      </div>
      <div className={'overflow-x-auto'}>
        <CustomBarChart
          data={processedElementData(elementChartData, element, selectors)}
          dataKey={'question'}
          id={`elementChartId-${element}`}
        />
      </div>
    </Section>
  );
};

export default ElementChartSection;
