import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { COMPLETED_INSTRUMENTS_OPTIONS, SCHOOL_DATA } from '../../../service/queryKeys';
import { getCompletedInstruments, getSchoolEnvFeedbackData } from '../../../service/api';
import Selector from '../../../form/select/selector';
import Button from '../../../global/button/button';
import { SCHOOL_ENV_TYPE } from '../SchoolEnvironmentPage';
import SchoolEnvCharts from '../components/SchoolEnvCharts';
import useModal from '../../../hooks/useModal';
import HelpModal from '../modals/HelpModal';
import { Document, Page, pdf, Text } from '@react-pdf/renderer';
import PDFLayout, { MARGIN_LEFT_TEXT, MARGIN_TOP_PAGE, PDF_STYLES } from '../../../layout/pdf';
import { saveAs } from 'file-saver';
import PDFChart from '../../feedback/PDFChart';
import { CustomStackedBarChartComponent } from '../../feedback/components/customStackedBarChart';
import Icon from "../../../global/icon/icon";
import { GLOBAL_ICONS } from "../../../resources/vars";
import Tippy from "@tippyjs/react";

const SchoolEnvFeedback = () => {
  const [selectedOption, setOption] = useState<{ label: string; value: string } | null>(null);
  const [loading, setLoading] = useState(false);

  const { data: feedbackData, isFetched } = useQuery(
    [SCHOOL_DATA, selectedOption?.value],
    () => getSchoolEnvFeedbackData(selectedOption?.value),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
    }
  );

  const { data: options } = useQuery(
    [COMPLETED_INSTRUMENTS_OPTIONS, SCHOOL_ENV_TYPE],
    () => getCompletedInstruments(SCHOOL_ENV_TYPE),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
      placeholderData: [],
    }
  );

  const handleDownload = async (name: string) => {
    setLoading(true);

    try {
      const blob = await pdf(
        <Document>
          {Object.entries(feedbackData!.elements).map(([subElement, elements], index1) => {
            return Object.entries(elements).map(([element, data], index2) => (
              <React.Fragment key={Math.random()}>
                {element ? (
                  <Page size="A4" style={PDF_STYLES.page} orientation={'landscape'} key={Math.random()}>
                    {index1 === 0 && index2 === 0 && <PDFLayout.Header/>}
                    {index1 === 0 && index2 === 0 && (
                      <Text style={{ ...PDF_STYLES.headline, marginLeft: MARGIN_LEFT_TEXT, marginBottom: '2px' }}>
                        School environment and leadership survey
                      </Text>
                    )}
                    {index1 === 0 && index2 === 0 && (
                      <Text
                        style={{
                          ...PDF_STYLES.headline,
                          marginBottom: '12px',
                          marginLeft: MARGIN_LEFT_TEXT,
                        }}
                      >
                        {name}
                      </Text>
                    )}
                    <Text
                      style={{
                        ...PDF_STYLES.headline,
                        marginBottom: '2px',
                        marginLeft: MARGIN_LEFT_TEXT,
                        marginTop: index1 === 0 && index2 === 0 ? 0 : MARGIN_TOP_PAGE,
                      }}
                    >
                      {element}
                    </Text>
                    <PDFChart>
                      <CustomStackedBarChartComponent
                        data={data}
                        stacks={feedbackData!.stacks}
                        dataKey={'question'}
                        id={`schoolStackedChart_${subElement}_${element}`}
                        width={850}
                        height={650}
                        isPdf
                      />
                    </PDFChart>
                  </Page>
                ) : (
                  <Page
                    size="A4"
                    style={{ ...PDF_STYLES.page, padding: MARGIN_TOP_PAGE, paddingBottom: 50 }}
                    key={Math.random()}
                  >
                    {data.map((item) => (
                      <PDFLayout.Table
                        tableStyles={{ margin: 0, marginBottom: MARGIN_TOP_PAGE, width: '100%' }}
                        headers={[{ value: item.question }]}
                        rows={item.answers.map((answer: string) => [{ value: answer }])}
                        key={Math.random()}
                      />
                    ))}
                  </Page>
                )}
              </React.Fragment>
            ));
          })}
        </Document>
      ).toBlob();

      saveAs(blob, `school_environment_${name}`);
    } finally {
      setLoading(false);
    }
  };

  const [modal, toggleModal] = useModal((props: any) => <HelpModal {...props} />, {
    title: 'Understanding your data',
    size: 'xlg',
    preventCloseOnClickOnMask: true,
  });

  return (
    <>
      {modal}
      <div className={'flex justify-end mb-2'}>
        <Tippy
          disabled={(options && options.length !== 0 && options[0]['options'].length !== 0)}
          content={<>Select your survey to generate a downloadable PDF</>} theme={'translucent'}
          interactive
          interactiveBorder={10}
          animation={'shift-away'}
          allowHTML
          appendTo={() => document.body}>
          <div>
            <Button
              disabled={!(options && options.length !== 0 && options[0]['options'].length !== 0)}
              onClick={() => handleDownload(selectedOption ? selectedOption.label : options[0]['options'][0].label)}
              addOutline={!(options && options.length !== 0 && options[0]['options'].length !== 0)}>
              {loading ? 'Loading...' : 'Download'}
            </Button>
          </div>
        </Tippy>
      </div>
      <div className={'flex justify-between bg-white p-5 mb-2'}>
        <Selector
          id={'select-school-instrument'}
          className={'w-2/5'}
          placeholder={'Select completed survey'}
          value={
            options && options.length !== 0 && options[0]['options'].length !== 0 && selectedOption == null
              ? options[0]['options'][0]
              : selectedOption
          }
          onChange={(option: { label: string; value: string }) => setOption(option)}
          options={options}
          isClearable={
            selectedOption != null &&
            options &&
            options.length !== 0 &&
            options[0]['options'].length !== 0 &&
            selectedOption.value !== options[0]['options'][0].value
          }
          noOptionsMessage={() => (
            <>
              <div className={'text-info bg-gray-100 flex items-center justify-center gap-5 my-1 py-4 px-2 mb-2'}>
                <Icon icon={GLOBAL_ICONS['info']} container={false} elementSize={20}/>
                <i>You&apos;ll be able to view feedback when at least 5 colleagues have completed a survey.</i>
              </div>
              No options
            </>
          )}
        />
        <div>
          <Button
            asLink
            mainColor={'info'}
            size={'sm'}
            style={{ padding: 0 }}
            onClick={() => {
              toggleModal(true);
            }}
          >
            Understanding your data
          </Button>
        </div>
      </div>
      <SchoolEnvCharts feedbackData={feedbackData} isFetched={isFetched}/>
    </>
  );
};

export default SchoolEnvFeedback;
