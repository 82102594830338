import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { ACADEMIC_YEARS, DEVELOPMENT_PERIODS, ME, ONBOARDING_TRACKING } from '../../../service/queryKeys';
import {
  getAcademicYears,
  getDevelopmentPeriods,
  postOrPutAcademicYear,
  postOrPutDevelopmentPeriods,
} from '../../../service/api';
import OnboardingBodyContent from './onboardingBodyContent';
import OnboardingHeader from './onboardingHeader';
import Button from '../../../global/button/button';
import { OnboardingBodyInterface, onboardingStepInterface } from './resources';
import { toast } from 'react-toastify';
import { updateOnboardingTracking, updateSchoolSize } from '../../../service/api/schoolApi';
import Icon from '../../../global/icon/icon';
import posthog from 'posthog-js';

const OnboardingBody = ({
  steps,
  currentStep,
  setCurrentStep,
  user,
  setOnboarding,
  version,
  setConfetti,
}: OnboardingBodyInterface) => {
  const queryClient = useQueryClient();
  const academicYearsQuery =
    version === 'school'
      ? useQuery([ACADEMIC_YEARS], () => getAcademicYears(), {
          staleTime: Infinity,
          select: (data) => data.data,
          placeholderData: [],
        })
      : undefined;

  const developmentPeriods =
    version === 'school'
      ? useQuery(DEVELOPMENT_PERIODS, getDevelopmentPeriods, {
          staleTime: Infinity,
          select: (data) => data.data.data,
          placeholderData: [],
          keepPreviousData: true,
        })
      : undefined;

  const academicYearMutation = useMutation(postOrPutAcademicYear, {
    onSuccess: () => {
      queryClient.invalidateQueries(ACADEMIC_YEARS);
    },
  });

  const periodMutation = useMutation(postOrPutDevelopmentPeriods, {
    onSuccess: () => {
      queryClient.invalidateQueries(DEVELOPMENT_PERIODS);
    },
  });

  const updateSchoolSizeMutation = useMutation(updateSchoolSize, {
    onSuccess: () => {
      /*Do nothing*/
    },
  });

  const updateOnboardingTrackingMutation = useMutation(updateOnboardingTracking, {
    onSuccess: () => {
      queryClient.invalidateQueries(ONBOARDING_TRACKING);
      queryClient.invalidateQueries(ME);
    },
  });

  const [showSkipButton, setShowSkipButton] = useState<boolean>(false);
  const [showContinueButton, setShowContinueButton] = useState<boolean>(false);
  const [reason, setReason] = useState<undefined | string>(undefined);
  const [copied, setCopied] = useState<boolean>(false);

  const step = steps[currentStep];
  const posthogEventCapture = () => {
    posthog.capture(
      `${version} ${step.id === 'completeTeacher' ? 'has finished Onboarding' : 'has started Onboarding'} ${step.id}`,
      {
        name: user?.first_name + ' ' + user?.last_name,
        email: user?.email,
        role: user?.role,
        organisation: user?.organisation,
      }
    );
  };
  const addTerm = (name: string, start_date: string, end_date: string) => {
    periodMutation
      .mutateAsync({
        name: name,
        start_date: start_date,
        end_date: end_date,
      })
      .then(() => {
        /*Do nothing*/
      })
      .catch((error) => {
        toast.error(error.response.data.message ?? 'An error has occurred.');
      });
  };

  return (
    <>
      {steps.length === 0 ? (
        <></>
      ) : (
        <div className={'w-full bg-white h-full rounded-b-md rounded-t-md'}>
          <div className="w-full">
            <OnboardingHeader version={version} user={user} />
          </div>
          <div className={'pt-6 px-10 pb-10 w-5/6 mx-auto pb-12'} style={{ height: '68%' }}>
            <div className="w-full">
              <p className={'mx-auto w-full text-center text-3xl font-semibold'}>{step.title}</p>
              <p className={'mt-2 mx-auto w-full text-center italic'}>{step.subtitle}</p>
            </div>
            <OnboardingBodyContent
              type={step.id}
              data={['academic_year', 'terms'].includes(step.id) ? academicYearsQuery?.data : undefined}
              user={user}
              setShowSkipButton={setShowSkipButton}
              setShowContinueButton={setShowContinueButton}
              reason={reason}
              setReason={setReason}
              copied={copied}
              setCopied={setCopied}
              version={version}
            />
          </div>
          <div className="w-full grid grid-rows-2 mb-2 gap-2 my-1">
            <div className={'w-full flex mt-2'}>
              {showContinueButton && (
                <Button
                  onClick={() => {
                    posthogEventCapture();
                    if ('welcomeTeacher' === step.id) {
                      setCurrentStep(currentStep + 1);
                    } else if (step.id === 'completeTeacher') {
                      updateOnboardingTrackingMutation.mutateAsync('complete');
                      setConfetti(true);
                      setOnboarding(undefined);
                    } else if (step.id === 'welcome') {
                      if (version === 'school' && academicYearsQuery && academicYearsQuery.data?.length === 0) {
                        academicYearMutation
                          .mutateAsync({
                            name: '2024/25',
                            start_date: '2024/09/01',
                            end_date: '2025/07/31',
                          })
                          .then(() => {
                            updateOnboardingTrackingMutation.mutateAsync(step.id);
                            setCurrentStep(currentStep + 1);
                          })
                          .catch((error: any) => {
                            toast.error(error.response.data.message ?? 'An error has occurred.');
                          });
                      } else {
                        updateOnboardingTrackingMutation.mutateAsync(step.id);
                        setCurrentStep(currentStep + 1);
                      }
                    } else if (step.id === 'academic_year') {
                      if (academicYearsQuery?.data && academicYearsQuery?.data.length > 0) {
                        const startDate = academicYearsQuery?.data[0].startDate;
                        const endDate = academicYearsQuery?.data[0].endDate;
                        if (
                          startDate === '01/09/2024' &&
                          endDate === '31/07/2025' &&
                          developmentPeriods?.data.length === 0
                        ) {
                          addTerm('Term 1', '2024/09/01', '2024/12/31');
                          addTerm('Term 2', '2025/01/01', '2025/04/15');
                          addTerm('Term 3', '2025/04/16', '2025/07/30');
                        }
                        updateOnboardingTrackingMutation.mutateAsync(step.id);
                        setCurrentStep(currentStep + 1);
                      }
                    } else if (step.id === 'implementation_intention' && reason != undefined) {
                      updateSchoolSizeMutation.mutateAsync(reason);
                      updateOnboardingTrackingMutation.mutateAsync(step.id);
                      setCurrentStep(currentStep + 1);
                    } else if (step.id === 'registration') {
                      updateOnboardingTrackingMutation.mutateAsync(step.id);
                      updateOnboardingTrackingMutation.mutateAsync('complete');
                      setCurrentStep(currentStep + 1);
                    } else if (step.id === 'complete') {
                      setConfetti(true);
                      setOnboarding(undefined);
                    } else {
                      updateOnboardingTrackingMutation.mutateAsync(step.id);
                      setCurrentStep(currentStep + 1);
                    }
                  }}
                  className={'mx-auto'}
                  size={'sm'}
                >
                  {step.id === 'complete' ? 'Finish' : step.id === 'completeTeacher' ? 'Begin' : 'Continue'}
                </Button>
              )}
              {showSkipButton && (
                <Button
                  onClick={() => {
                    if (step.id === 'implementation_intention') {
                      updateOnboardingTrackingMutation.mutateAsync(step.id);
                      setCurrentStep(currentStep + 1);
                    } else if (step.id === 'registration') {
                      updateOnboardingTrackingMutation.mutateAsync(step.id);
                      updateOnboardingTrackingMutation.mutateAsync('complete');
                      setCurrentStep(currentStep + 1);
                    }
                  }}
                  className={'mx-auto'}
                  size={'sm'}
                  isOutline
                >
                  Skip step
                </Button>
              )}
            </div>
            <div className={'mx-auto my-auto flex'}>
              {steps.map((step: onboardingStepInterface, index: number) => {
                const distance = Math.abs(currentStep - index);
                const sizes = [20, 17, 16, 15, 15, 15];
                return (
                  <React.Fragment key={index}>
                    <Icon
                      icon={'CircleFill'}
                      elementSize={sizes[distance]}
                      color={currentStep > index ? 'orange' : 'black'}
                      className={'p-1 my-auto'}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OnboardingBody;
