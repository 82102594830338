import moment from 'moment';
import { DefaultOptionType } from './types';

const INITIAL_YEAR = 1950;
const CURRENT_YEAR = moment().year();

export const YEARS: DefaultOptionType[] = [];

for (let i = CURRENT_YEAR; i >= INITIAL_YEAR; i--) {
  YEARS.push({ label: i.toString(), value: i.toString() });
}
