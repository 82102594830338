import React, { useState } from 'react';
import Modal from '../../../global/messages/modal/modal';
import ModalComponents from '../../../global/messages/modal/modal.components';
import { ModalProps } from '../../../global/messages/modal/modal.types';
import { useMutation, useQueryClient } from 'react-query';
import { createOrUpdateUsersFromCsv } from '../../../service/api';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import DragAndDropFile from '../../../form/dragAndDrop/DragAndDropFile';
import { USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import { toast } from 'react-toastify';
import { AxiosError, HttpStatusCode } from 'axios';
import Alert from '../../../global/alert/alert';

const VALIDATION_SCHEMA = Yup.object().shape({
  csvFile: Yup.mixed()
    .required('File is required.')
    .test('file-size', 'The file you are attempting to upload exceed 6mb.', (resource) => {
      //6MB
      return resource?.size < 6 * 1000 * 1000;
    })
    .test('file-type', 'Only CSV is allowed.', (resource) => 'text/csv' === resource?.type)
  ,
});

export default function BulkUploadModal({ toggle }: Props) {
  const queryClient = useQueryClient();

  const [ validationErrors, setValidationErrors ] = useState<ValidationError[]>([]);

  const {
    control,
    handleSubmit,

    formState: {
      errors,
      isSubmitting,
    },
  } = useForm({
    defaultValues: {
      csvFile: null as File | null,
    },
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const createOrUpdateUsersFromCsvMutation = useMutation(
    ({ csvFile }: { csvFile: File }) => createOrUpdateUsersFromCsv(csvFile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ USER_MANAGEMENT_LIST ]);
        toast.success('Users successfully uploaded.');
        toggle();
      },
      onError: (error: AxiosError<ValidationError[]>) => {
        if (HttpStatusCode.BadRequest !== error?.response?.status) {
          return;
        }

        setValidationErrors(error?.response?.data ?? []);
      },
    },
  );

  return (
    <Modal open toggle={() => toggle()}>
      <form onSubmit={handleSubmit((values) => createOrUpdateUsersFromCsvMutation.mutate({ csvFile: values.csvFile! }))}>
        <ModalComponents.Body>
          <Controller
            control={control}
            name={'csvFile'}
            render={({ field: { value, onChange } }) => (
              <DragAndDropFile
                file={value}
                setFile={(file) => onChange(file as File)}
                error={errors.csvFile?.message}
                text={'Click or drag and drop to add a file'}
              />
            )}
          />

          {0 < validationErrors.length && (
            <Alert type={'danger'}>
              <p>
                The file you have uploaded has some error. Please fix them before trying again.
              </p>

              <ul className={'list-disc list-inside mt-8'}>
                {validationErrors.map((error, index) => (
                  <li key={index}>
                    Line {error.line} | {error.message}
                  </li>
                ))}
              </ul>
            </Alert>
          )}
        </ModalComponents.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton
            type={'submit'}
            label={isSubmitting || createOrUpdateUsersFromCsvMutation.isLoading ? 'Loading...' : 'Create users'}
            disabled={isSubmitting || createOrUpdateUsersFromCsvMutation.isLoading}
          />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

type Props = Pick<ModalProps, 'toggle'>;

interface ValidationError {
  line: number;
  message: string;
  invalidValue: any;
}
