import React, { useState } from 'react';
import { Section } from '../../../common';
import Icon from '../../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../../resources/vars';
import Button from '../../../../global/button/button';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { useMutation, useQueryClient } from 'react-query';
import { deleteV3Pathway, postOrPutV3Pathway, putV3PathwayStatus } from '../../../../service/api';
import { PATHWAYS_V3 } from '../../../../service/queryKeys';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import routeBuilder from '../../../../service/routeBuilder';
import { useNavigate } from 'react-router-dom';
import FocusElement from './FocusElement';
import Tooltip from '../../../../global/tooltip/tooltip';
import { dimensions, DimensionType } from '../../../mfgtPage/resources/resources';
import ElementSquare from '../../../mfgtPage/components/elementSquare';
import Modal from '../../../../global/messages/modal/modal';
import ModalComponents from '../../../../global/messages/modal/modal.components';
import CompleteConfirmationModal from '../../modals/v3/CompleteConfirmationModal';
import useModal from '../../../../hooks/useModal';

const V3PathwayItem = ({ pathway, openEditModal, openDeleteModal }: V3PathwayItemType) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const changeV3PathwayStatusMutation = useMutation(putV3PathwayStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([PATHWAYS_V3]);
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(error.response?.data.message ?? 'An error has occurred.');
    },
  });

  const deleteV3PathwayStatusMutation = useMutation(deleteV3Pathway, {
    onSuccess: () => {
      queryClient.invalidateQueries([PATHWAYS_V3]);
    },
    onError: (error: AxiosError<{ message?: string }>) => {
      toast.error(error.response?.data.message ?? 'An error has occurred.');
    },
  });

  const invitationGoal = pathway.goals?.find((goal) => goal.from_invitation);

  const handleCompleteClick = () => {
    if (!pathway.started) {
      return changeV3PathwayStatusMutation
        .mutateAsync({ id: pathway.id, status: 'start' })
        .then(() => navigate(routeBuilder('pathways').generate(pathway.id, 3)));
    }
    setCompleteConfirmationModalProps({ handleConfirmComplete, title: 'Complete your Development cycle' });
    toggleCompleteConfirmationModal(true);
  };

  const handleConfirmComplete = () => {
    changeV3PathwayStatusMutation
      .mutateAsync({ id: pathway.id, status: 'complete' })
      .then(() => toast.success('Development cycle completed successfully'));
  };

  const [
    completeConfirmationModal,
    toggleCompleteConfirmationModal,
    setCompleteConfirmationModalProps,
  ] = useModal((props: any) => <CompleteConfirmationModal {...props} />);

  if ((pathway.group != null || invitationGoal != null) && !pathway.accepted) {
    return (
      <div className={'mb-5'}>
        <div className={'p-5 bg-gray-200'}>
          <div className={'flex justify-center'}>
            <p className={'font-bold'}>
              {pathway.group
                ? `You've been invited to start a development cycle with ${pathway.group.name}`
                : `You've been invited to start a development cycle with your school`}
            </p>
          </div>
          <div className={'flex justify-center gap-2'}>
            <Button
              size={'xsm'}
              mainColor={'success'}
              onClick={(event) => {
                return changeV3PathwayStatusMutation.mutate({ id: pathway.id, status: 'accept' });
              }}
            >
              Accept
            </Button>
            <Button
              size={'xsm'}
              mainColor={'muted'}
              onClick={(event) => {
                return deleteV3PathwayStatusMutation.mutate(pathway.id);
              }}
            >
              Decline
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {completeConfirmationModal}
      <div className={'mb-5'}>
        <div className={'w-full flex justify-end'}>
          {!pathway.goals?.some((goal) => goal.from_invitation) && (
            <Tooltip content={'Edit Development cycle'}>
              <Icon
                icon={GLOBAL_ICONS.edit}
                color={'primary'}
                elementSize={40}
                className={'cursor-pointer'}
                onClick={() => openEditModal()}
              />
            </Tooltip>
          )}
          <Tooltip content={'Archive Development cycle'}>
            <Icon
              icon={pathway.archived ? GLOBAL_ICONS.unarchive : GLOBAL_ICONS.archive}
              color={'primary'}
              elementSize={40}
              className={'cursor-pointer'}
              onClick={() => {
                toast.success(`Development cycle ${pathway.archived ? 'un' : ''}archived successfully`);
                if (pathway.archived) {
                  return changeV3PathwayStatusMutation.mutate({ id: pathway.id, status: 'unarchive' });
                }

                changeV3PathwayStatusMutation.mutate({ id: pathway.id, status: 'archive' });
              }}
            />
          </Tooltip>
          <Tooltip content={'Remove Development cycle'}>
            <Icon
              icon={GLOBAL_ICONS.remove}
              color={'primary'}
              elementSize={40}
              className={'cursor-pointer'}
              onClick={() => openDeleteModal()}
            />
          </Tooltip>
        </div>
        <Section size={'md'} className={'mt-5'}>
          <div>
            <div className={'grid grid-cols-1 lg:grid-cols-3 gap-2 lg:justify-between'}>
              <h4>
                {pathway.group ? 'Team development cycle' : invitationGoal ? 'School development cycle' : pathway.title}
              </h4>
              <div>
                {pathway.focus && (
                  <ElementSquare value={pathway.focus.value} type={pathway.focus.type} title={pathway.focus.label} />
                )}
              </div>
              <div>
                <div className={pathway.started ? 'mx-auto flex' : 'flex w-full h-full '}>
                  <div className={pathway.started ? 'ml-auto' : 'flex w-full my-auto'}>
                    <Button
                      size={'xsm'}
                      className={`${pathway.started ? 'mr-2' : 'mx-auto'} py-4`}
                      mainColor={!pathway.started ? 'success' : 'primary'}
                      isOutline={pathway.started}
                      onClick={handleCompleteClick}
                    >
                      {!pathway.started ? 'Start' : 'Complete'}
                    </Button>
                  </div>
                  {pathway.started && (
                    <div className={'mr-auto'}>
                      <Button
                        size={'xsm'}
                        className={'ml-2'}
                        mainColor={'primary'}
                        onClick={() => {
                          navigate(routeBuilder('pathways').generate(pathway.id, 3));
                        }}
                      >
                        Workspace
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {pathway.group && <p className={'font-bold mt-2'}>{pathway.group.name}</p>}
          </div>
          {pathway.group?.meetingPathway && (
            <div className={'mt-8'}>
              <span className={'font-bold'}>{pathway.group!.meetingPathway?.title}</span>
              <div className={'p-5 rounded bg-gray-100 border-2 lg:w-80'}>
                <span className={'font-bold'}>Activities</span>
                <ul className={'list-disc list-inside'}>
                  {pathway.group!.meetingPathway.activities?.map((activity) => (
                    <li key={Math.random()}>{activity}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </Section>
      </div>
    </>
  );
};

type V3PathwayItemType = {
  pathway: V3Pathway;
  openEditModal: () => void;
  openDeleteModal: () => void;
};

export default V3PathwayItem;
