import styled, { css, DefaultTheme, ThemeProps } from 'styled-components';
import { transparentize } from 'polished';
import { DEFAULT_THEME } from '../../../Theme';
import { DEFAULT_TRANSITION_FUNC } from '../../transitions/transitions.types';
import { SizeType } from '../../../resources/types';
import { PositionType } from './modal.types';

const WIDTH: Record<SizeType, number> = {
  sm: 350,
  md: 600,
  lg: 800,
};

const TIMES = {
  false: 200,
  true: 350,
};

type ModalWrapperStyledProps = {
  $size: SizeType;
  $position: PositionType;
  $fullHeight: boolean;
};

const getWrapperSizeStyle = (props: ModalWrapperStyledProps) => {
  const MARGINS: Record<PositionType, Record<'top' | 'right' | 'bottom' | 'left', 'auto' | string>> = {
    center: {
      top: 'auto',
      right: 'auto',
      bottom: 'auto',
      left: 'auto',
    },
    top: {
      top: props.$fullHeight ? 'auto' : '2rem',
      right: 'auto',
      bottom: props.$fullHeight ? 'auto' : '2rem',
      left: 'auto',
    },
    right: {
      top: '0',
      right: '0',
      bottom: '0',
      left: 'auto',
    },
    bottom: {
      top: 'auto',
      right: 'auto',
      bottom: '0',
      left: 'auto',
    },
    left: {
      top: '0',
      right: 'auto',
      bottom: '0',
      left: '0',
    },
  };

  return css`
    margin-top: ${MARGINS[props.$position]['top']};
    margin-right: ${MARGINS[props.$position]['right']};
    margin-bottom: ${MARGINS[props.$position]['bottom']};
    margin-left: ${MARGINS[props.$position]['left']};

    width: 85%;
    max-width: ${`${WIDTH[props.$size]}px`};
    height: ${props.$fullHeight
      ? 'left' === props.$position || 'right' === props.$position
        ? '100%'
        : '98%'
      : 'min-content'};
    ${props.$fullHeight &&
    `
      display: flex;
      flex-direction: column;
    `}
  `;
};

export const MaskStyled = styled('div')<ThemeProps<DefaultTheme>>`
  position: fixed;
  z-index: 1050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: ${(props) => transparentize(0.35, props.theme.colors.muted)};
  backdrop-filter: blur(1px);
  overflow: auto;

  opacity: 0;
  transition: all ${TIMES['false'] / 2}ms ${DEFAULT_TRANSITION_FUNC};
  transition-delay: ${TIMES['false']}ms;

  &[data-toggle='true'] {
    transition-duration: ${TIMES['false'] + TIMES['false'] / 4}ms;
    transition-delay: 0ms;
    opacity: 1;
    [data-id='content'] {
      opacity: 1;
      transform: translateY(0);
      transition-delay: ${TIMES['true'] - TIMES['false']}ms;
      transition-duration: ${TIMES['true']}ms;
    }
  }
`;

MaskStyled.defaultProps = {
  theme: DEFAULT_THEME,
};

export const ModalWrapperStyled = styled('div')<ModalWrapperStyledProps>`
  ${(props) => getWrapperSizeStyle(props)}

  position: relative;
  box-sizing: border-box;
  background-color: white;
  transform: ${(props) =>
    'center' === props.$position || 'top' === props.$position || 'bottom' === props.$position
      ? `translateY(${'top' === props.$position || 'center' === props.$position ? '-100px' : '100px'})`
      : `translateX(${'left' === props.$position ? '-100px' : '100px'})`};
  opacity: 0;
  transition: all ${TIMES['false']}ms ${DEFAULT_TRANSITION_FUNC};
`;
