import connector from '../connector';

export function completeRegistration(data: {
  body: {
    first_name: string;
    last_name: string;
    password: string;
    role_description?: string;
    subjects?: string[];
    captcha: string;
  };
  params: {
    token: string;
  };
}) {
  return connector.post(`auth/complete-registration/${data.params.token}`, data.body);
}
