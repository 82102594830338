import React from 'react';
import { VideoType } from '../videoObservationPage';
import Icon from '../../../global/icon/icon';
import Placeholder_Image from '@app/asset/images/Placeholder_Image.png';
import routeBuilder from '../../../service/routeBuilder';
import { useNavigate } from 'react-router-dom';
import classnames from 'classnames';
import Tooltip from '../../../global/tooltip/tooltip';

const VideoThumbnail = ({ video, userId }: VideoThumbnailType) => {
  const navigate = useNavigate();

  return (
    <Tooltip
      content={
        video.highlights.shared ? 'A colleague has requested your feedback' : 'A colleague has given you feedback'
      }
      disabled={!video.highlights.shared && !video.highlights.feedback}
    >
      <div
        className={`p-2 w-full rounded cursor-pointer hover:opacity-80 ${classnames({
          'border-2': video.highlights.shared || video.highlights.feedback,
        })}`}
        style={{ borderColor: '#ff6500' }}
        onClick={() => navigate(routeBuilder('videoPlayer').generate(video.id))}
      >
        <img src={video.thumbnail_path ?? Placeholder_Image} alt={video.title} className={'w-full'} />
        <div className={'flex justify-between'}>
          <h5>{video.title}</h5>
          <div className={'flex inline-flex'}>
            {null != video.tags_num && video.tags_num > 0 && (
              <Tooltip content={`${video.tags_num} colleagues have given you feedback`}>
                <span>{video.tags_num}</span>
              </Tooltip>
            )}
            {video.created_by.id === userId && video.shared_with && video.shared_with?.length > 0 && (
              <Tooltip
                content={video.highlights.feedback ? 'A colleague has given you feedback' : 'This video is shared'}
              >
                <Icon
                  icon={video.highlights.feedback ? 'PeopleFill' : 'People'}
                  color={video.highlights.feedback ? 'primary' : undefined}
                  elementSize={21}
                  style={{ padding: 0, paddingTop: 5 }}
                />
              </Tooltip>
            )}
          </div>
        </div>
        <span className={'text-sm'}>
          {video.created_by.name} - {video.created_at}
        </span>
      </div>
    </Tooltip>
  );
};

type VideoThumbnailType = {
  video: VideoType;
  userId: string;
};

export default VideoThumbnail;
