import { IconType } from '../../../global/icon/icon.types';
import { DefaultOptionType } from '../../../resources/types';

export const resourceTypesArray: Array<resourceTypeInterface> = [
  { label: 'Article (pdf)', value: 'article_file', extended: true, type: 'file' },
  { label: 'Article', value: 'article_web', extended: true, type: 'web' },
  { label: 'Blog post', value: 'blog_web', extended: true, type: 'web' },
  { label: 'Book', value: 'book', extended: true, type: 'web' },
  { label: 'Course', value: 'course_url', extended: false, type: 'web' },
  { label: 'Course', value: 'course_info', extended: false, type: 'text' },
  { label: 'Document (download)', value: 'document_download', extended: true, type: 'file' },
  { label: 'Document', value: 'document_web', extended: true, type: 'web' },
  { label: 'Element', value: 'element', extended: true, type: 'text', hidden: true },
  { label: 'Guide', value: 'guide', extended: false, type: 'text' },
  { label: 'Inset pack', value: 'inset_pack', extended: false, type: 'text' },
  { label: 'Podcast', value: 'podcast', extended: true, type: 'web' },
  { label: 'Poster', value: 'poster', extended: false, type: 'web' },
  { label: 'Poster collection', value: 'poster_collection', extended: false, type: 'text' },
  { label: 'Self-study', value: 'self_study', extended: false, type: 'text' },
  { label: 'Template', value: 'template', extended: false, type: 'text' },
  { label: 'Technique', value: 'technique_info', extended: false, type: 'text' },
  { label: 'Technique template', value: 'technique_template', extended: false, type: 'web' },
  { label: 'Video (embed)', value: 'video_embed', extended: false, type: 'web' },
  { label: 'Video (tagged)', value: 'video_tagged', extended: false, type: 'web' },
  { label: 'Video', value: 'video_web', extended: false, type: 'web' },
  { label: 'Webinar recording', value: 'webinar', extended: true, type: 'web' },
];

export interface resourceTypeInterface {
  label: string;
  value: string;
  extended: boolean;
  type: string;
  hidden?: boolean;
}

export interface authorInfoInterface {
  name?: string;
  title?: string;
  avatar?: string;
  organisation?: string;
  organisation_url?: string;
  published_date?: string;
}

export type ResourceType = {
  id?: any;
  type?: any;
  name?: any;
  url?: any;
  file?: any;
};

export type LinkButtonsType = {
  id?: any;
  url?: any;
  icon?: any;
  value?: string;
};

export interface resourceInterface {
  //comes from backend
  id?: string;

  //required
  type: string;
  title: string;
  image_icon?: string;
  icon?: any;

  //optional for all
  short_description?: string;
  estimated_time?: string | number;
  pathway_steps?: Array<string>;
  keywords?: Array<string>;
  goals?: Array<string>;
  parents?: Array<string>;
  draft?: boolean;

  //required for web
  url?: string;

  //optional for text & element
  urls?: Array<string>;

  //required for file, optional for element
  file?: ResourceType | any;

  //required for text & element
  slug?: string;

  //required for element, optional for the rest
  dimension?: Array<string>;
  element?: Array<string>;

  dimensions?: Array<string>;
  elements?: Array<string>;

  //optional for web & file
  author?: authorInfoInterface;

  //optional for text & element
  content?: any;
  description?: string;
  banner?: string;
  instructions?: Array<string>;
  suggestions?: Array<string>;
  children?: Array<string>;

  //other fields
  objectID?: string;
  file_name?: string;

  name?: string;
  link_buttons?: Array<LinkButtonsType>;
  resourceButtons?: Array<LinkButtonsType>;
  subpages?: any;
  pages?: any;
}

export const courses = [
  { label: 'Dimension 1: Understanding the content', value: '1', type: 'dimension' },
  { label: '1.1 Deep and fluent knowledge', value: '1.1', type: 'element' },
  { label: '1.2 Curriculum sequencing', value: '1.2', type: 'element' },
  { label: '1.3 Relevant curriculum tasks', value: '1.3', type: 'element' },
  { label: '1.4 Strategies and misconceptions', value: '1.4', type: 'element' },
  { label: 'Dimension 2: Creating a supportive environment', value: '2', type: 'dimension' },
  { label: '2.1 Teacher-student relationships', value: '2.1', type: 'element' },
  { label: '2.2 Student-student relationships', value: '2.2', type: 'element' },
  { label: '2.3 Learner motivation', value: '2.3', type: 'element' },
  { label: '2.4 Climate of high expectations', value: '2.4', type: 'element' },
  { label: 'Dimension 3: Maximising opportunity to learn', value: '3', type: 'dimension' },
  { label: '3.1 Managing time and resources', value: '3.1', type: 'element' },
  { label: '3.2 Rules and consequences', value: '3.2', type: 'element' },
  { label: '3.3 Managing disruptive behaviour', value: '3.3', type: 'element' },
  { label: 'Dimension 4: Activating hard thinking', value: '4', type: 'dimension' },
  { label: '4.1 Structuring', value: '4.1', type: 'element' },
  { label: '4.2 Explaining', value: '4.2', type: 'element' },
  { label: '4.3 Questioning', value: '4.3', type: 'element' },
  { label: '4.4 Interacting', value: '4.4', type: 'element' },
  { label: '4.5 Embedding', value: '4.5', type: 'element' },
  { label: '4.6 Activating', value: '4.6', type: 'element' },
];

export const DIMENSIONS: Array<DimensionInterface> = [
  {
    dimension_number: '1',
    dimension_name: 'Great teachers understand the content they are teaching and how it is learnt',
  },
  { dimension_number: '2', dimension_name: 'Great teachers create a supportive environment for learning' },
  { dimension_number: '3', dimension_name: 'Great teachers manage the classroom to maximise opportunity to learn' },
  {
    dimension_number: '4',
    dimension_name:
      "Great teachers present content, activities and interactions that activate their students' thinking",
  },
];
export const ELEMENTS: Array<ElementInterface> = [
  {
    dimension_number: '1',
    element_number: '1',
    element_name: 'Deep and fluent knowledge',
    elementId: '1.1',
  },
  {
    dimension_number: '1',
    element_number: '2',
    element_name: 'Curriculum sequencing',
    elementId: '1.2',
  },
  {
    dimension_number: '1',
    element_number: '3',
    element_name: 'Relevant curriculum tasks',
    elementId: '1.3',
  },
  {
    dimension_number: '1',
    element_number: '4',
    element_name: 'Strategies and misconceptions',
    elementId: '1.4',
  },
  {
    dimension_number: '2',
    element_number: '1',
    element_name: 'Teacher-student relationships',
    elementId: '2.1',
  },
  {
    dimension_number: '2',
    element_number: '2',
    element_name: 'Student-student relationships',
    elementId: '2.2',
  },
  {
    dimension_number: '2',
    element_number: '3',
    element_name: 'Learner motivation',
    elementId: '2.3',
  },
  {
    dimension_number: '2',
    element_number: '4',
    element_name: 'Climate of high expectations',
    elementId: '2.4',
  },
  {
    dimension_number: '3',
    element_number: '1',
    element_name: 'Managing time and resources',
    elementId: '3.1',
  },
  {
    dimension_number: '3',
    element_number: '2',
    element_name: 'Rules and consequences',
    elementId: '3.2',
  },
  {
    dimension_number: '3',
    element_number: '3',
    element_name: 'Managing disruptive behaviour',
    elementId: '3.3',
  },
  {
    dimension_number: '4',
    element_number: '1',
    element_name: 'Structuring',
    elementId: '4.1',
  },
  {
    dimension_number: '4',
    element_number: '2',
    element_name: 'Explaining',
    elementId: '4.2',
  },
  {
    dimension_number: '4',
    element_number: '3',
    element_name: 'Questioning',
    elementId: '4.3',
  },
  {
    dimension_number: '4',
    element_number: '4',
    element_name: 'Interacting',
    elementId: '4.4',
  },
  {
    dimension_number: '4',
    element_number: '5',
    element_name: 'Embedding',
    elementId: '4.5',
  },
  {
    dimension_number: '4',
    element_number: '6',
    element_name: 'Activating',
    elementId: '4.6',
  },
];

export interface DimensionInterface {
  dimension_number: string;
  dimension_name: string;
}

export interface ElementInterface {
  dimension_number: string;
  element_number: string;
  element_name: string;
  elementId: string;
}

export const testDataOfSomeIcons: Array<IconType> = [
  'Newspaper',
  'Book',
  'FileEarmarkText',
  'FilePost',
  'Map',
  'BroadcastPin',
  'Mic',
  'Youtube',
  'Camera',
  'PersonSquare',
  'Sliders',
];

export const checkIfContentExists = (content: any) => {
  if (content.length > 132) return true;
  else return false;
};

export type DeleteConfirmModalType = {
  handleSubmit: () => Promise<any>;
};

export const removeEmptyStrings = (array: any) => {
  return array.map((subArray) => subArray.filter((item) => Object.keys(item)[0] != 'initial'));
};

export const subpageFormFieldsOptions: DefaultOptionType[] = [
  {
    value: 'content',
    label: 'Content',
  },
  {
    value: 'imageSlide',
    label: 'Image slide',
  },
  {
    value: 'link',
    label: 'Link',
  },
  {
    value: 'subtitle',
    label: 'Subtitle',
  },
  {
    value: 'title',
    label: 'Title',
  },
  {
    value: 'typeform',
    label: 'Typeform',
  },
  {
    value: 'video',
    label: 'Video',
  },
  {
    value: 'wysiwyg',
    label: 'Wysiwyg',
  },
];

export const capitaliseFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const checkCategory = (type: string) => {
  return resourceTypesArray.find((resourceType: resourceTypeInterface) => resourceType.value === type)?.type;
};

export const addInitialObject = (data: any) => {
  return data.map((item: any) => {
    const updatedData = [...item.data];
    updatedData.push({ initial: '' });
    return { ...item, data: updatedData };
  });
};

export const transformPages = (pages: any) => {
  if (!pages) {
    return [];
  }

  return pages.map((page: any) => {
    const data = page.content
      .flatMap((contentStr: any) => {
        const contentArray = contentStr.split('},{"');
        return contentArray.map((item: string, index: number) => {
          if (contentArray.length > 1) {
            if (index === 0) item = item + '}'
            else if (index + 1 < contentArray.length) item = '{"' + item + '}'
            else item = '{"' + item
          }
          try {
            return JSON.parse(item);
          } catch (error) {
            return null;
          }
        });
      })
      .filter((item: any) => item !== null);
    return { id: page.id, data: data };
  });
};

export const isValidUrl = (string: any) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};

export const filterElements = (dimensions: Array<string>) => {
  const array = ELEMENTS.filter((element: ElementInterface) => dimensions.includes(element.dimension_number));
  return array.map((element: ElementInterface) => {
    return {
      label: `${element.dimension_number}.${element.element_number}. ${element.element_name}`,
      value: `${element.dimension_number}.${element.element_number}`,
    };
  });
};

export const slugify = (text: string) =>
  text
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');

export const updateElements = (dimensions: any, elements: any) => {
  const currentDimensions = dimensions.map((dimension: any) => dimension.value);
  const availableElements = ELEMENTS.filter((element: ElementInterface) =>
    currentDimensions.includes(element.dimension_number)
  ).map((e: any) => e.elementId);
  const updatedElements = elements?.filter((element: any) => availableElements.includes(element));
  return updatedElements;
};
