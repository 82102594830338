import React, { useState } from 'react';
import CustomStackedBarChart from '../../feedback/components/customStackedBarChart';
import { Section } from '../../common';
import Accordion from '../../../global/accordion/accordion';
import DefaultTable from '../../../global/table/defaultTable';
import Alert from '../../../global/alert/alert';

const SchoolEnvCharts = ({
  feedbackData,
  isFetched,
}: {
  isFetched: boolean;
  feedbackData?: {
    elements: Record<string, Record<string, Array<Record<string, any> | { question: string; answers: string[] }>>>;
    stacks: Array<{ color: string; id: number }>;
  };
}) => {
  const [accordionSubElement, setAccordionSubElement] = useState<string | null>();

  if (isFetched && feedbackData?.elements == null) {
    return (
      <Section>
        <Alert className={'mb-5'} type={'info'}>
          To see survey feedback, first generate a survey link and share with your colleagues. When you&apos;re ready,
          click complete to generate your feedback
        </Alert>
      </Section>
    );
  }

  if (!feedbackData) {
    return null;
  }

  return (
    <Section size={null} className={'field-mb'} key={Math.random()}>
      {Object.entries(feedbackData.elements).map(([subElement, elements]) => (
        <Accordion
          key={Math.random()}
          title={subElement}
          isOpen={accordionSubElement === subElement}
          handleOpen={() => setAccordionSubElement((sub) => (sub === subElement ? null : subElement))}
        >
          {Object.entries(elements).map(([element, data]) => (
            <React.Fragment key={Math.random()}>
              {element ? (
                <Section size={'md'} key={Math.random()} headline={element} className={'mb-5'}>
                  <div className={'overflow-x-auto'}>
                    <CustomStackedBarChart
                      data={data}
                      stacks={feedbackData.stacks}
                      dataKey={'question'}
                      id={`schoolStackedChart_${subElement}_${element}`}
                    />
                  </div>
                </Section>
              ) : (
                <React.Fragment key={Math.random()}>
                  {data.map((item) => (
                    <DefaultTable
                      key={Math.random()}
                      id={`schoolAnswersTable_${element}`}
                      additionalContainerClasses={'mb-3'}
                      showCounter={false}
                      apiHandled={true}
                      columns={[{ id: 'question', label: item.question }]}
                      rows={item.answers.map((answer: string) => ({
                        id: Math.random(),
                        cells: [
                          {
                            id: 'question',
                            content: (
                              <div>
                                <p>{answer}</p>
                              </div>
                            ),
                          },
                        ],
                      }))}
                    />
                  ))}
                </React.Fragment>
              )}
            </React.Fragment>
          ))}
        </Accordion>
      ))}
    </Section>
  );
};

export default SchoolEnvCharts;
