import styled, { css, ThemeProps } from 'styled-components';
import { AllColorsType, SizeType } from '../../resources/types';
import { readableColor } from 'polished';

type BadgeCounterStyledProps = {
  $color: AllColorsType;
  $size: SizeType;
  $showCounter: boolean;
  $showPulse: boolean;
};

const getColors = (props: ThemeProps<any> & BadgeCounterStyledProps) => {
  const COMPONENT_COLOR = props.theme.colors[props.$color as string];
  return css`
    background: ${COMPONENT_COLOR};
    color: ${readableColor(`${COMPONENT_COLOR}`)};
  `;
};

export const BadgeStyled = styled('div')`
  display: inline-flex;
  position: relative;
  flex-shrink: 0;
  vertical-align: middle;
`;

export const BadgeCounterStyled = styled('div')<BadgeCounterStyledProps>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  border-radius: 9999px;
  border: 2px solid #ffffff;
  height: ${(props) => (props.$showCounter ? '20px' : '10px')};
  min-width: ${(props) => (props.$showCounter ? '20px' : '10px')};
  ${(props) => props.$showCounter && 'padding: 0 .25rem;'}

  &:before {
    ${(props) => props.$showPulse && `content: '';`}
    position: absolute;
    z-index: -1;
    border-radius: 9999px;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    background: ${(props) => props.theme.colors[props.$color]};
    transform: scale(1);
    animation: animation_ping 1s cubic-bezier(0, 0, 0.2, 1) infinite;
  }

  @keyframes animation_ping {
    75 % {
      opacity: 0.5;
    }
    100% {
      transform: scale(3);
      opacity: 0;
    }
  }

  ${(props) => getColors(props)}
`;
