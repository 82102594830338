import React from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import { UserType } from '../../users/userManagementPage';
import Input from '../../../form/input/input';
import Button from '../../../global/button/button';
import { useMutation, useQueryClient } from 'react-query';
import { putMe } from '../../../service/api';
import * as Yup from 'yup';
import { Section } from '../../common';
import {ME} from "../../../service/queryKeys";
import { refreshImpersonation, refreshToken } from "../../../service/auth";

const AccountDetailsForm = ({ user }: Record<'user', Pick<UserType, 'first_name' | 'last_name' | 'email'>>) => {
  const queryClient = useQueryClient();

  const meMutation = useMutation(putMe, {
    onSuccess: (response) => {
      refreshToken(response.data.data.token)
      toast.success('Your account details has been changed');
    },
  });

  return (
    <Section headline={'Account details'} className={'field-mb'}>
      <Formik
        initialValues={{
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        }}
        validateOnMount={true}
        validationSchema={Yup.object().shape({
          first_name: Yup.string().max(32).trim().required(),
          last_name: Yup.string().max(32).trim().required(),
          email: Yup.string().max(180).trim().required(),
        })}
        onSubmit={(values) => meMutation.mutateAsync(values).then(() => {
          refreshImpersonation(values.email)
          queryClient.invalidateQueries(ME);
        })}
      >
        {({ values, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
              <div className={'field-mb'}>
                <Input
                  id="first_name"
                  label="First Name"
                  placeholder={'Please enter your new first name'}
                  required
                  value={values.first_name}
                  onChange={handleChange}
                />
              </div>
              <div className={'field-mb'}>
                <Input
                  id="last_name"
                  label="Last Name"
                  placeholder={'Please enter your new last name'}
                  required
                  value={values.last_name}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={'field-mb'}>
              <Input
                id="email"
                label="Email"
                placeholder={'Please enter your new email'}
                required
                value={values.email}
                onChange={handleChange}
              />
            </div>
            <Button type={'submit'} className={'mt-5'} disabled={!isValid || meMutation.isLoading}>
              {!meMutation.isLoading ? 'Update' : 'Loading...'}
            </Button>
          </form>
        )}
      </Formik>
    </Section>
  );
};

export default AccountDetailsForm;
