import React from 'react';
import classNames from 'classnames';

interface SubscriptionPricesProps {
  /**
   * Price value to display.
   */
  value: number;

  discountReference?: number;
  /**
   *
   */
  type: 'total' | 'teacher' | 'inclVat';
}

const TYPES = {
  total: {
    title: 'Subscription price',
    icon: '',
  },
  teacher: {
    title: 'Price per teacher/year',
    icon: '',
  },
  inclVat: {
    title: 'Your card will be charged',
    icon: '',
  },
};

function countDecimalPlaces(number: number) {
  if (Number.isInteger(number)) {
    return 0; // Return 0 if the number is an integer
  }

  const decimalPart = number.toString().split('.')[1];
  if (decimalPart) {
    return decimalPart.length;
  } else {
    return 0; // Return 0 if the number has no decimal places
  }
}

function addZero(number: number) {
  const decimalPlaces = countDecimalPlaces(number);
  if (decimalPlaces === 1) {
    return number.toFixed(2);
  } else {
    return number.toString();
  }
}

const SubscriptionPrice: React.FC<SubscriptionPricesProps> = ({
  value,
  type,
  discountReference,
}: SubscriptionPricesProps) => {
  return (
    <div
      className={'bg-primary bg-opacity-25 transition-shadow duration-500 ease-in-out hover:shadow-xl rounded-md p-5'}
    >
      <p
        className={`font-bold ${classNames({ 'mb-2': !discountReference })}${classNames({
          ' my-auto': type === 'inclVat',
        })}`}
      >
        {TYPES[type].title} {type != 'inclVat' && '(ex.VAT)'}
      </p>
      {discountReference && <p className={'mb-2'}>Price before coupon: £ {discountReference}</p>}
      <div className={'flex items-center text-primary'}>
        <div className={'text-xl mr-3'}>£</div>
        <div key={value} className={'animate-flip-horizontal font-display text-5xl'}>
          {addZero(value)}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPrice;
