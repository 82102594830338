import React from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import Modal from '../../../global/messages/modal/modal.components';
import { DateRangePicker } from '../../../form/datepicker/common';
import Input from '../../../form/input/input';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { AcademicYearProps } from '../../pathway/pathwaysPage.types';

type AcademicYearModalProps = {
  academicYear?: AcademicYearProps;
  mutation: any;
  onboarding?: boolean;
};

const AcademicYearModal = ({
  academicYear,
  mutation,
  toggle,
  onboarding = false,
}: ModalToggleProps & AcademicYearModalProps) => {
  return (
    <Modal>
      <Formik
        initialValues={{
          name: '',
          start_date: null,
          end_date: null,
          ...academicYear,
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().trim().required().max(32),
          start_date: Yup.date().required(),
          end_date: Yup.date().required(),
        })}
        validateOnMount={true}
        onSubmit={(values) => {
          mutation
            .mutateAsync({
              ...values,
              start_date: dayjs(values.start_date as Date).format('YYYY/MM/DD'),
              end_date: dayjs(values.end_date as Date).format('YYYY/MM/DD'),
            })
            .then(() => {
              if (academicYear) {
                toast.success('Updated academic year');
                return;
              }
              toast.success('Added new academic year');
            })
            .catch((error: any) => {
              toast.error(error.response.data.message ?? 'An error has occurred.');
            });
        }}
      >
        {({ values, setFieldValue, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              {/* Name */}
              <div className={'field-mb'}>
                <Input
                  required
                  id={'name'}
                  label={'Academic year name'}
                  placeholder={'Enter an academic year name (e.g. "2021-2022")'}
                  value={values.name}
                  onChange={handleChange}
                />
              </div>
              {/* Start/End date */}
              <DateRangePicker
                required
                start={values.start_date}
                end={values.end_date}
                onStartChange={(date) => setFieldValue('start_date', date)}
                onEndChange={(date) => setFieldValue('end_date', date)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                disabled={!isValid || mutation.isLoading}
                type={'submit'}
                label={
                  mutation.isLoading
                    ? 'Loading'
                    : academicYear && !onboarding
                    ? 'Update academic year'
                    : 'Add academic year'
                }
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AcademicYearModal;
