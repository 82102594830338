import React, { useReducer, useState } from 'react';
import { CellType, TableProps } from './table.types';
import { Sort, Filter } from './components';
import { groupBy, cloneDeep, isEmpty } from 'lodash';
import Input from '../../form/input/input';
import Button from '../button/button';
import ReactPaginate from 'react-paginate';

const DefaultTable = ({
  id,
  additionalContainerClasses = '',
  additionalTableClasses = '',
  columns,
  rows = [],
  noDataMessage = 'Table is empty.',
  showCounter = true,
  itemCounter = { single: 'item', plural: 'items' },
  filteredBySearch = false,
  apiHandled = false,
  setApiSort = () => null,
  pagination,
  loading,
  isFree,
}: TableProps) => {
  const [order, setOrder] = useState({
    col: columns[0].id,
    type: 0,
  });

  const [filters, setFilters] = useReducer((state: any, action: any) => {
    return {
      ...state,
      [action.filterBy]: action.value,
    };
  }, {});

  const processedData = () => {
    let data = [...rows];

    if (apiHandled) {
      return data;
    }

    //TODO: REMOVE WHEN REFACTOR INSTRUMENT PAGE TO BE HANDLED BY API
    //FILTERING
    if (!isEmpty(filters)) {
      data = data.filter((instrument) =>
        instrument.cells.every((cell) => {
          return cell.value
            ? cell.value?.toLowerCase().includes(filters[cell.id]?.toLowerCase() ?? '')
            : cell.content
                ?.toString()
                ?.toLowerCase()
                .includes(filters[cell.id]?.toLowerCase() ?? '');
        })
      );
    }

    //SORTING
    if (order.type !== 0) {
      data.sort((a: any, b: any) => {
        const cellA = a.cells.find((cell: any) => cell.id === order.col);
        const cellB = b.cells.find((cell: any) => cell.id === order.col);

        const aContent = cellA?.value ?? cellA.content;
        const bContent = cellB?.value ?? cellB.content;

        return aContent > bContent ? order.type : aContent < bContent ? order.type * -1 : 0;
      });
    }

    if (!columns.some((col) => col.isGrouped)) {
      return data;
    }

    //GROUPING
    return Object.values(groupBy(data, (row) => row.id)).reduce((acc, value) => {
      value.forEach((item: any, index: number) => {
        let e = cloneDeep(item);

        e = {
          id: e.id + '-' + index,
          class: e.class,
          cells: item.cells.reduce((acc: any, cell: any, filterIndex: number) => {
            if (index === 0 || filterIndex !== 0) {
              // if index === 0 && filterIndex === 0
              if (filterIndex === 0) {
                acc.push({
                  ...cell,
                  rowSpan: value.length,
                });
                return acc;
              }

              acc.push({
                ...cell,
              });
            }

            return acc;
          }, []),
        };

        acc.push(e);
      });
      return acc;
    }, []);
  };

  return (
    <>
      <div className={`overflow-x-auto ${additionalContainerClasses}`}>
        <table className={`gtt-table table--default ${additionalTableClasses}`} id={id}>
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={`${id}-col_${col.id}_${index}`} role="cell" className={`th--${col.id}`} colSpan={col.colSpan}>
                  {col.isSortable || col.hasFilter ? (
                    <div className={'flex items-center justify-between'}>
                      <div className={'flex items-center gap-2'}>
                        {col.isSortable && (
                          <div>
                            <Sort
                              title={col.id}
                              onClick={() => {
                                const type = col.id === order.col ? (order.type === 1 ? -1 : order.type + 1) : 1;

                                setOrder({
                                  col: col.id,
                                  type,
                                });

                                if (apiHandled) {
                                  if (type === 0) {
                                    setApiSort('');
                                    return;
                                  }

                                  setApiSort(`${col.id} ${type === 1 ? 'asc' : 'desc'}`);
                                }
                              }}
                              order={order.col === col.id ? order.type : 0}
                              disabled={0 === rows.length}
                            />
                          </div>
                        )}
                        {col.additionalLabel ? (
                          <div className={'flex justify-between items-center sm-field-mb'}>
                            {col.label} &nbsp; {col.additionalLabel}
                          </div>
                        ) : (
                          <div>{col.label}</div>
                        )}
                      </div>
                      {col.hasFilter && (
                        <div>
                          <Filter
                            title={col.id}
                            content={
                              <React.Fragment>
                                <Input
                                  id={col.id}
                                  label={`Filter by ${col.id}`}
                                  hideLabel
                                  placeholder={`Type to filter by ${col.id}`}
                                  value={filters[col.id] || ''}
                                  onChange={({ target }) => {
                                    setFilters({
                                      filterBy: col.id,
                                      value: target.value,
                                    });
                                  }}
                                />
                                <div className={'flex justify-end border-t mt-4 -mx-4 px-4 pt-2'}>
                                  <Button
                                    mainColor={'secondary'}
                                    isOutline
                                    size={'sm'}
                                    disabled={!filters[col.id]}
                                    onClick={() => {
                                      setFilters({
                                        filterBy: col.id,
                                        value: '',
                                      });
                                    }}
                                  >
                                    Clear
                                  </Button>
                                </div>
                              </React.Fragment>
                            }
                            active={filters[col.id]}
                            disabled={0 === rows.length}
                          />
                        </div>
                      )}
                    </div>
                  ) : col.additionalLabel ? (
                    <div className={'flex justify-between items-center sm-field-mb'}>
                      {col.label} &nbsp; {col.additionalLabel}
                    </div>
                  ) : (
                    <div>{col.label}</div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr className={'no-results'}>
                <td colSpan={columns.length} role="cell">
                  loading...
                </td>
              </tr>
            ) : 0 === rows.length ? (
              <tr className={'no-results'}>
                <td colSpan={columns.length} role="cell">
                  {filteredBySearch ? 'Found no data matching your search.' : noDataMessage}
                </td>
              </tr>
            ) : 0 === processedData().length ? (
              <tr className={'no-results'}>
                <td colSpan={columns.length} role="cell">
                  Found no data matching your filters.
                </td>
              </tr>
            ) : (
              processedData().map((row: any, i) => (
                <tr key={`${id}-row_${row.id}_${i}`} role="row" className={row.class ? `row--${row.class}` : ''}>
                  {row.cells.map((cell: CellType, index: number) => {
                    if (cell.hide) {
                      return;
                    }

                    return (
                      <td
                        key={`${id}-row_${row.id}--${cell.id}--${index}`}
                        role="cell"
                        className={cell.class ? `td--${cell.class}` : ''}
                        rowSpan={cell.rowSpan}
                      >
                        {cell.content}
                      </td>
                    );
                  })}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {showCounter && (
        <div className={'flex justify-between items-center mt-5'}>
          {pagination && rows.length > 0 && pagination.total > pagination.maxPage && (
            <ReactPaginate
              previousLabel="Previous"
              nextLabel="Next"
              breakLabel="..."
              containerClassName="pagination"
              pageClassName="page-item"
              nextClassName="page-item"
              breakClassName="page-item"
              previousClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              breakLinkClassName="page-link"
              activeClassName="active"
              pageCount={pagination.total / pagination.maxPage}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={pagination.onPageChange}
              forcePage={pagination.currentPage - 1}
            />
          )}
          <div>
            Showing <b>{rows.length}</b>{' '}
            {pagination && (
              <>
                of <b>{isFree ? rows.length : pagination.total}</b>{' '}
              </>
            )}
            {1 === rows.length ? itemCounter.single : itemCounter.plural}
          </div>
        </div>
      )}
    </>
  );
};

DefaultTable.displayName = 'DefaultTable';

export default DefaultTable;
