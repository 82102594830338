import React, { useState } from 'react';
import { DECIDE_FOCUS_VALUE, DEVELOPMENT_GOAL_VALUE, PathwaysStepperProps, STEPS } from '../pathwaysPage.types';
import { Section } from '../../common';
import Button from '../../../global/button/button';
import Selector from '../../../form/select/selector';
import Icon from '../../../global/icon/icon';
import { LinkStyled } from '../../../global/link/link.styled';
import { useNavigate } from 'react-router-dom';
import routeBuilder from '../../../service/routeBuilder';
import { useQuery } from 'react-query';
import { getPathwaysElements, getThinkificSSO } from '../../../service/api';
import { PATHWAYS_ELEMENTS, THINKIFIC_SSO } from '../../../service/queryKeys';
import useModal from '../../../hooks/useModal';
import GoalActionModal from '../modals/goalActionModal';
import ElementActionModal from '../modals/elementActionModal';

const PathwaysStepper = ({
  steps,
  periodId,
  element,
  mutation,
  disabled,
  goal,
}: PathwaysStepperProps): JSX.Element => {
  const navigate = useNavigate();

  const [elementSelected, setElementSelected] = useState<any>(null);

  const [goalActionModal, toggleGoalActionModal, setGoalActionModalProps] = useModal((props: any) => (
    <GoalActionModal {...props} />
  ));

  const [elementActionModal, toggleElementActionModal, setElementActionModalProps] = useModal((props: any) => (
    <ElementActionModal {...props} />
  ));

  const elementOptions = useQuery(PATHWAYS_ELEMENTS, () => getPathwaysElements(), {
    select: (data) => data.data.data.elements,
    placeholderData: [],
    staleTime: Infinity,
  });

  const thinkificQuery = useQuery([THINKIFIC_SSO, element?.value], () => getThinkificSSO(element?.value), {
    select: (data) => data.data.data.link,
    enabled: element != null,
  });

  const isElementFeedbackDisabled = (element?: string) => {
    if (!element || elementOptions.data.length === 0) {
      return false;
    }

    return elementOptions.data
      .reduce((acc: [], dimension: any) => {
        return [...acc, ...dimension.options];
      }, [])
      .some((e: any) => e.value === element && e.disabled);
  };

  return (
    <Section className={'sm-field-mb'} size={null} background={'transparent'} headline={'Pathway steps'}>
      {goalActionModal}
      {elementActionModal}
      <div className={`md:flex md:flex-wrap -mx-1`}>
        {STEPS.map((step, index) => (
          <div
            key={`step_${index}`}
            className={'w-full md:w-3/6 xl:w-1/5 flex items-center relative py-1 xl:py-0 px-1'}
          >
            <div
              className={`relative flex flex-col w-full h-full bg-white border item-container ${
                !steps[step.value] ? 'opacity-50' : 'shadow-sm'
              }`}
            >
              {!steps[step.value] && (
                <div className={'h-full w-full absolute z-40 top-0 left-0 bg-muted bg-opacity-25'}/>
              )}
              <p className={'font-display sm-field-mb'}>{step.title}</p>
              <p className={'italic text-muted field-mb z-50'}>
                {step.description}

                {step.value === 'element_measure' && isElementFeedbackDisabled(element?.value) && (
                  <>
                    <br/>
                    <br/>
                    There is currently no survey for this element.
                  </>
                )}
                {!disabled && ((step.value === DECIDE_FOCUS_VALUE && element) ||
                  (step.value === DEVELOPMENT_GOAL_VALUE && goal)) && (
                  <Button
                    asLink
                    size={'sm'}
                    mainColor={'secondary'}
                    className={'mx-auto'}
                    disabled={disabled}
                    onClick={() => {
                      switch (step.value) {
                        case 'decide_focus':
                          setElementActionModalProps({
                            title: 'Please specify your element for this development period.',
                            mutation,
                            academicYearId: periodId,
                            element: element ? { label: element.title, value: element.value } : null,
                          });
                          toggleElementActionModal(true);
                          break;
                        case 'development_goal':
                          setGoalActionModalProps({
                            title: 'Please specify your development goal for this development period.',
                            mutation,
                            academicYearId: periodId,
                            goal: goal ? { label: goal.name, value: goal.id } : null,
                          });
                          toggleGoalActionModal(true);
                          break;
                      }
                    }}
                  >
                    Edit
                  </Button>
                )}
              </p>
              <div className={'flex flex-col w-full mt-auto mx-auto'}>
                <React.Fragment>
                  {'decide_focus' === step.value && (
                    <Selector
                      id={step.value}
                      className={'sm-field-mb'}
                      label={step.title}
                      hideLabel
                      placeholder={'...'}
                      onChange={(option: any) => setElementSelected(option)}
                      isDisabled={!steps[step.value] || disabled}
                      isClearable={false}
                      options={elementOptions.isFetched ? elementOptions.data : []}
                    />
                  )}
                  {step.value === 'professional_learning' ? (
                    <React.Fragment>
                      <LinkStyled
                        asButton
                        asDisabledButton={!thinkificQuery.isSuccess || !steps[step.value] || disabled}
                        mainColor={'secondary'}
                        href={!thinkificQuery.isSuccess || !steps[step.value] || disabled ? null : thinkificQuery.data}
                        onClick={(e: any) => {
                          if (!thinkificQuery.isSuccess || !steps[step.value] || disabled) {
                            e.preventDefault();
                            return;
                          }

                          mutation.mutate({ name: step.value, academic_year_id: periodId });
                        }}
                        target={'_blank'}
                        className={'mx-auto'}
                      >
                        Start
                      </LinkStyled>
                    </React.Fragment>
                  ) : (
                    <Button
                      disabled={
                        !steps[step.value] ||
                        disabled ||
                        (step.value === 'element_measure' && isElementFeedbackDisabled(element?.value)) ||
                        (step.value === DECIDE_FOCUS_VALUE && !elementSelected)
                      }
                      isOutline
                      size={'sm'}
                      mainColor={'secondary'}
                      className={'mx-auto'}
                      onClick={() => {
                        switch (step.value) {
                          case 'baseline_measure':
                            navigate(routeBuilder('studentSurveys').generate());
                            break;
                          case 'decide_focus':
                            mutation.mutate({
                              name: step.value,
                              element: elementSelected?.value,
                              academic_year_id: periodId,
                            });

                            break;
                          case 'element_measure':
                            navigate(
                              routeBuilder('studentSurveys').generate({ e: element?.value })
                            );
                            break;
                          case 'development_goal':
                            setGoalActionModalProps({
                              title: 'Please specify your development goal for this development period.',
                              mutation,
                              academicYearId: periodId,
                            });
                            toggleGoalActionModal(true);
                            break;
                        }
                      }}
                    >
                      Start
                    </Button>
                  )}
                </React.Fragment>
              </div>
            </div>
            {index !== 0 && (
              <div
                className={
                  'transform rotate-90 md:rotate-0 absolute inset-x-2/4 -top-5 md:top-auto md:right-auto md:-left-2 z-50'
                }
              >
                <Icon icon={'ArrowRight'} container={false}/>
              </div>
            )}
          </div>
        ))}
      </div>
    </Section>
  );
};

export default PathwaysStepper;
