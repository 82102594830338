export const isLoggedIn = () => null != localStorage.getItem('token');

export const impersonateUser = (user: string, searchRoles: Array<string>, searchValue: string) => {
  localStorage.setItem('switch-user', user);
  localStorage.setItem('search-roles', JSON.stringify(searchRoles));
  localStorage.setItem('search-value', searchValue);
  window.location.reload();
};

export const exitImpersonation = (callback: () => void) => {
  localStorage.removeItem('switch-user');

  callback();

  window.location.reload();
};

export const logout = (reload = true) => {
  localStorage.removeItem('token');
  localStorage.removeItem('switch-user');
  localStorage.removeItem('search-value');
  localStorage.removeItem('search-roles');
  if (reload) {
    window.location.reload();
  }
};

export const login = (token: string) => {
  localStorage.setItem('token', token);
  localStorage.removeItem('search-value');
  localStorage.removeItem('search-roles');
  window.location.reload();
};

export const refreshToken = (token: string) => {
  if (null != localStorage.getItem('switch-user')) {
    return;
  }

  localStorage.setItem('token', token);
};

export const refreshImpersonation = (email: string) => {
  if (null === localStorage.getItem('switch-user')) {
    return;
  }

  localStorage.setItem('switch-user', email);
};
