import React from 'react';
import { ModalToggleProps } from '../../../../global/messages/modal/modal.types';
import Modal from '../../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { deleteV3PathwayGoal } from '../../../../service/api';
import { Goal } from '../../../../@types/Entity/Goal';
import { PATHWAYS_V3 } from '../../../../service/queryKeys';
import { toast } from 'react-toastify';

const DeleteV3GoalModal = ({ toggle, goal, pathwayId }: ModalToggleProps & { goal: Goal; pathwayId: string }) => {
  const queryClient = useQueryClient();

  const deleteV3GoalMutation = useMutation(deleteV3PathwayGoal, {
    onSuccess: () => {
      toast.success('Goal removed');
      queryClient.invalidateQueries([PATHWAYS_V3, pathwayId]);
      toggle(false);
    },
  });

  return (
    <Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          deleteV3GoalMutation.mutate({ pathwayId: pathwayId, goalId: goal.id });
        }}
      >
        <Modal.Body>
          Are you sure you would like to delete this Development goal? This action cannot be undone
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton
            label={deleteV3GoalMutation.isLoading ? 'Loading...' : 'Delete'}
            type={'submit'}
            disabled={deleteV3GoalMutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteV3GoalModal;
