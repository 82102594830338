import React, { InputHTMLAttributes } from 'react';
import { ChoiceProps, FieldProps, LabelProps } from '../common/field.types';
import ErrorFeedback from '../common/error';
import Label from '../common/label';
import Choice from './choice';
import { DefaultOptionType } from '../../resources/types';

export interface ChoiceGroupProps extends Omit<FieldProps, 'icon'>, LabelProps, ChoiceProps {
  /**
   * Radio group options.
   */
  options: DefaultOptionType[];
  /**
   * Option checked.
   */
  optionChecked?: string | boolean | null;
  /**
   * Sets the radio group as required.
   */
  isGroupRequired?: boolean;
  /**
   * Sets the options inline.
   */
  isInline?: boolean;
}

const RadioGroup: React.FC<ChoiceGroupProps & InputHTMLAttributes<any>> = ({
  id,
  label,
  hideLabel,
  error,
  hintText,
  options,
  optionChecked,
  isGroupRequired,
  isInline = false,
  ...other
}: ChoiceGroupProps & InputHTMLAttributes<any>) => {
  return (
    <React.Fragment>
      <Label label={label} hideLabel={hideLabel} id={id} required={isGroupRequired} />
      <div className={isInline ? `grid grid-flow-col grid-cols-${options.length} gap-2` : 'flex flex-col gap-2'}>
        {options.map((option) => (
          <div key={`${id}_${option.value}`}>
            <Choice
              value={option.value}
              name={id}
              id={`${id}_${option.value}`}
              label={option.label}
              type={'radio'}
              required={isGroupRequired}
              checked={optionChecked === option.value}
              className={'pretty-no-margin'}
              {...(other as ChoiceProps)}
            />
          </div>
        ))}
      </div>
      {error && <ErrorFeedback id={id} error={error} />}
    </React.Fragment>
  );
};

RadioGroup.displayName = 'RadioGroup';

RadioGroup.defaultProps = {
  hideLabel: false,
  isGroupRequired: false,
};

export default RadioGroup;
