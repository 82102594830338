import React, { useState } from 'react';
import { NotificationProps } from '../../../global/notification/notification.types';
import Modal from '../../../global/messages/modal/modal.components';
import Tab from '../../../global/tab/tab';
import Notification from '../../../global/notification/notification';
import Empty from '../../../global/empty/empty';
import Button from '../../../global/button/button';
import Tag from '../../../global/tag/tag';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { NOTIFICATIONS } from '../../../service/queryKeys';
import { getNotifications, patchReadNotifications } from '../../../service/api';
import { NotificationType } from '../../globalNotifications/globalNotificationsManagementPage';

const NotificationsAside = () => {
  const [active, setActive] = useState(0);
  const queryClient = useQueryClient();

  const { data: newNotifications } = useQuery(
    [NOTIFICATIONS, 'new'],
    () => getNotifications({ read: 'false', show: 'active' }),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const { data: readNotifications } = useQuery(
    [NOTIFICATIONS, 'read'],
    () => getNotifications({ read: 'true', show: 'active' }),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const readMutation = useMutation(patchReadNotifications, {
    onSuccess: () => {
      queryClient.invalidateQueries([NOTIFICATIONS, 'new']);
      queryClient.invalidateQueries([NOTIFICATIONS, 'read']);
    },
  });

  return (
    <div className={'h-full overflow-y-auto'}>
      <Modal.Body>
        <div className={'relative pb-10'}>
          <Tab
            items={[
              {
                value: 0,
                label: (
                  <div>
                    <b>New</b>{' '}
                    <Tag size={'sm'} mainColor={'secondary'} isPill content={newNotifications.length.toString()} />
                  </div>
                ),
              },
              {
                value: 1,
                label: (
                  <div>
                    <span className={'text-muted'}>Read</span>{' '}
                    <Tag size={'sm'} mainColor={'muted'} isPill content={readNotifications.length.toString()} />
                  </div>
                ),
              },
            ]}
            active={active}
            clickOnTab={(value) => setActive(value as number)}
            style={{ background: 'white', position: 'sticky', zIndex: 1, top: 0 }}
          >
            <Tab.Content id={0} active={active}>
              <div className={'my-10'}>
                <div className={'flex justify-between items-center sm-field-mb'}>
                  <div>
                    Showing <b>{newNotifications.length}</b>{' '}
                    {1 === newNotifications.length ? 'notification' : 'notifications'}
                  </div>
                  <Button
                    disabled={0 === newNotifications.length}
                    asLink
                    size={'sm'}
                    style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
                    onClick={() => {
                      const ids = newNotifications
                        .filter((notification: NotificationType) => notification.type === 'automated')
                        .map((notification: NotificationType) => notification.id);
                      readMutation.mutate({ ids, read: true });
                    }}
                  >
                    Mark all as read
                  </Button>
                </div>
                {0 === newNotifications.length ? (
                  <Empty element={'new notifications'} />
                ) : (
                  newNotifications.map((notification: NotificationType) => (
                    <Notification key={notification.id} {...notification} />
                  ))
                )}
              </div>
            </Tab.Content>
            <Tab.Content id={1} active={active}>
              <div className={'my-10'}>
                <div className={'flex justify-between items-center sm-field-mb'}>
                  <div>
                    Showing <b>{readNotifications.length}</b>{' '}
                    {1 === readNotifications.length ? 'notification' : 'notifications'}
                  </div>
                </div>
                {0 === readNotifications.length ? (
                  <Empty element={'read notifications'} />
                ) : (
                  readNotifications.map((notification: NotificationType) => (
                    <Notification key={notification.id} {...notification} />
                  ))
                )}
              </div>
            </Tab.Content>
          </Tab>
        </div>
      </Modal.Body>
    </div>
  );
};

export default NotificationsAside;
