import React from 'react';
import { JoinButton } from '../../../global/button/common';
import classNames from 'classnames';
import {
  EXPLORE_STEP,
  FOCUS_STEP,
  IDENTIFY_STEP,
  INTEGRATE_STEP,
  PREPARE_STEP,
  REVIEW_STEP,
} from '../views/PathwayV2View';
import useModal from '../../../hooks/useModal';
import JoinGroupModal from '../modals/joinGroup.modal';

const TopPanel = ({ pathwaysId, academicYear, focusArea, team, currentStep }: TopPanelType) => {
  const [joinGroupModal, toggleJoinGroupModal] = useModal(
    (props: any) => <JoinGroupModal {...props} pathwaysId={pathwaysId} />,
    {
      title: 'Select a team to work with during this development cycle',
    }
  );

  return (
    <>
      {joinGroupModal}
      <div className={'bg-white px-5 py-3 mx-2'}>
        <div className={'flex justify-between w-full sm:w-full lg:w-11/12 mb-3'}>
          <div className={`bg-gray-200 p-1 px-2 ${classNames({ 'h-2/3': null == team })}`}>{academicYear}</div>
          {team ? (
            <div className={'bg-gray-200 p-1 px-2 text-right'}>{team}</div>
          ) : (
            <JoinButton
              id={'selectTeamButton'}
              onClick={() => {
                toggleJoinGroupModal(true);
              }}
              label={'Select a team'}
            />
          )}
        </div>
        <div className={'flex w-full justify-between sm:w-full lg:w-11/12 font-bold mb-5 text-2xl'}>
          {currentStep && <div>{stepLabel(currentStep)}</div>}
          {focusArea && <div className={'text-right'}>{focusArea}</div>}
        </div>
        <div className={'text-muted'}>
          Development cycles contain a series of steps that you can take to improve an area of your teaching practice
          throughout the year. Within each step you’ll find some suggested activities and space to note down your
          thoughts and decisions.
        </div>
      </div>
    </>
  );
};

const stepLabel = (step: string) => {
  switch (step) {
    case IDENTIFY_STEP:
      return 'Working on identifying a focus';
    case EXPLORE_STEP:
      return 'Working on exploring';
    case FOCUS_STEP:
      return 'Working on focusing';
    case PREPARE_STEP:
      return 'Working on preparing';
    case INTEGRATE_STEP:
      return 'Working on integrating';
    case REVIEW_STEP:
      return 'Working on reviewing';
    default:
      return '';
  }
};

type TopPanelType = {
  pathwaysId: string;
  academicYear: string;
  focusArea?: string;
  team?: string | null;
  currentStep?: string;
  completionDate?: string;
};

export default TopPanel;
