import React from 'react';
import { UserType } from '../userManagementPage';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { ReviewUserTable } from '../components';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { deleteUserAccount } from '../../../service/api';
import {USER_MANAGEMENT_LIST} from "../../../service/queryKeys";
import {toast} from "react-toastify";

/**
 * Confirmation Modal for Delete User Account by Sys-admim
 * @param user
 * @param toggle
 * @constructor
 * @author Ibrahim Elsanosi
 */
const DeleteUserAccountModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();

  const removeUserAccount = useMutation(deleteUserAccount, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toggle(false);
      toast.success('User deleted successfully');
    },
  });

  return (
    <Modal>
      <Modal.Body>
        <p className={'sm-field-mb'}>
          You are about to delete this user account.
        </p>
        <div>
          <div className={'sm-field-mb'}>
            <ReviewUserTable user={user} />
          </div>
          <p>If you perform this action:</p>
          <ul className={'list-disc ml-5 sm-field-mb'}>
            <li>The user will no longer be able to access the platform.  </li>
            <li>Their association with the course bundle will be removed.  </li>
          </ul>
          <strong>Are you sure you want to continue?</strong>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} />
        <Modal.ConfirmButton
          onClick={() => {
            removeUserAccount.mutate(user.id);
          }}
          label={removeUserAccount.isLoading ? 'Loading...' : 'Delete user'}/>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteUserAccountModal;
