import React from 'react';
import { ColumnType } from '../../../global/table/table.types';
import { Section } from '../../common';
import DefaultTable from '../../../global/table/defaultTable';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getActions, getActionsSteps, postOrPutAction } from '../../../service/api';
import { STEP_TITLES, StepsType } from '../pathwaysPage.types';
import { PathwaysHeader, PathwaysStepper } from './index';
import { CompleteChoice } from '../../../form/choice/common';
import { ACTION_STEPS, ACTIONS, PATHWAYS_PERIOD } from '../../../service/queryKeys';

const COLUMNS: ColumnType[] = [
  { id: 'action', label: 'Pathway step' },
  { id: 'description', label: 'Description' },
  { id: 'classes', label: 'Class(es)' },
  { id: 'start_date', label: 'Start date' },
  { id: 'completion_date', label: 'Completion date' },
  { id: 'status', label: 'Complete' },
];

const PathwaysLog = ({
  periodId,
  data,
  disabled,
  isCompleted,
}: {
  periodId: string;
  data: any;
  disabled?: boolean;
  isCompleted: boolean;
}) => {
  const queryClient = useQueryClient();
  const ActionsQuery = useQuery([ACTIONS, periodId], () => getActions(periodId), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  const ActionsStepsQuery = useQuery([ACTION_STEPS, periodId], () => getActionsSteps(periodId), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  const actionMutation = useMutation(postOrPutAction, {
    onSuccess: () => {
      queryClient.invalidateQueries(ACTIONS);
      queryClient.invalidateQueries(ACTION_STEPS);
      queryClient.invalidateQueries(PATHWAYS_PERIOD);
    },
  });

  const step = () => {
    if (!data) {
      return '';
    }

    if (!data.element) {
      return 'Identifying which element of Great Teaching to focus on';
    }

    if (!data.goal) {
      return 'Exploring my chosen element';
    }

    return 'Working towards my development goal';
  };

  return (
    <>
      <PathwaysHeader
        id={'header'}
        steps={step()}
        isCompleted={isCompleted}
        element={data?.element?.title}
        name={`${data?.name}: ${data?.start_date} - ${data?.end_date}`}
      />
      <PathwaysStepper
        steps={ActionsStepsQuery.data}
        periodId={periodId}
        element={data?.element}
        goal={data?.goal}
        mutation={actionMutation}
        disabled={disabled}
      />
      <Section size={'md'} className={'field-mb'}>
        <DefaultTable
          id={'table--log'}
          additionalContainerClasses={'max-h-96 border'}
          additionalTableClasses={'table--sticky'}
          itemCounter={{ single: 'logged action', plural: 'logged actions' }}
          noDataMessage={"You don't have any logged actions yet."}
          columns={COLUMNS}
          rows={ActionsQuery.data.map((action: any, number: number) => {
            return {
              id: 'log--' + number,
              cells: [
                { id: 'action', content: <b>{STEP_TITLES[action.name as StepsType]}</b> },
                { id: 'description', content: action.description },
                { id: 'classes', content: action.classes ? action.classes : 'N/A' },
                { id: 'start_date', content: action.start_date },
                {
                  id: 'completion_date',
                  content: action.completion_date,
                },
                {
                  id: 'status',
                  class: 'status',
                  content: (
                    <CompleteChoice
                      disabled={disabled}
                      id={'log_5'}
                      checked={action.completion_date != null}
                      onChange={() => {
                        if (action.completion_date) {
                          return;
                        }

                        actionMutation.mutate({
                          id: action.id,
                          completed: true,
                          academic_year_id: periodId,
                        });
                      }}
                    />
                  ),
                },
              ],
            };
          })}
        />
      </Section>
    </>
  );
};

export default PathwaysLog;
