import * as Yup from 'yup';
import React, { useState } from 'react';
import { ROLE_MAT_COORDINATOR } from '../../../../../resources/roles';
import Alert from '../../../../../global/alert/alert';
import { MatInfoType, useRegistrationContext, } from '../../../../../state/registration/provider';
import classnames from 'classnames';
import Tooltip from '../../../../../global/tooltip/tooltip';
import Button from '../../../../../global/button/button';
import Icon from '../../../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../../../resources/vars';
import Input from '../../../../../form/input/input';
import ReCAPTCHA from 'react-google-recaptcha';
import useModal from '../../../../../hooks/useModal';
import SchoolsConfirmationModal from '../../modals/SchoolsConfirmationModal';
import { LinkStyled } from '../../../../../global/link/link.styled';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormObserver from '../../FormObserver';

const MatForm = () => {
  const { step1, step3, step4, skipStep3, previousPage, submitForm } = useRegistrationContext();
  const [step4Values] = useState<MatInfoType>({ ...step4 as MatInfoType })

  const [modal, toggleModal] = useModal((props: any) => <SchoolsConfirmationModal {...props} />);

  const SCHOOL = {
    name: '',
    slots: '' as unknown as number,
    coordinator_name: '',
    coordinator_lastname: '',
    coordinator_email: '',
  };

  const SCHEMA = Yup.object().shape({
    schools: Yup.array()
      .of(
        Yup.object().shape({
          name: Yup.string().trim().max(150).required().label('Name'),
          slots: Yup.number().min(0).required().label('Number of teachers'),
          coordinator_name: Yup.string().trim().max(32).required().label('Coordinator first name'),
          coordinator_lastname: Yup.string().trim().max(32).required().label('Coordinator last name'),
          coordinator_email: Yup.string().email().max(180).required().label('Coordinator email'),
        })
      )
      .test('max-slots', 'Max number of teachers achieved', (value: any) => {
        const total = value.reduce((acc: number, val: any) => acc + (val.slots || 0), 0);

        return total <= (step3?.teachers ?? 0);
      }),
    captcha: Yup.string().required().nullable(),
  });

  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
    watch,
  } = useForm({
    resolver: yupResolver(SCHEMA),
    defaultValues: {
      schools: step4Values?.schools ?? [{ ...SCHOOL }],
      captcha: null as null | string,
    },
  });

  const schoolsFieldArray = useFieldArray({
    control: control,
    name: 'schools',
  });

  return (
    <>
      {modal}
      {step1!.role === ROLE_MAT_COORDINATOR && (
        <Alert className={'mb-5'} type={'info'}>
          Number of teachers included in the subscription: {step3?.teachers}
        </Alert>
      )}
      <p className={'mb-5'}>
        Please list the name of each school and the number of teachers that should have access to the platform.
      </p>

      <form onSubmit={handleSubmit((values) => {
        setIsLoading(true)
        const totalSlots = values.schools.reduce((acc: number, school: any) => acc + school.slots, 0);

        if (totalSlots < (step3?.teachers ?? 0)) {
          setIsLoading(false)
          toggleModal(true)
          return
        }

        submitForm(() => setIsLoading(false))
      })}>
        <FormObserver step={'step4'} values={getValues()}/>
        {schoolsFieldArray.fields.map((school, index) => (
          <div key={index} className={classnames({ 'animate-fade-in': 0 !== index })}>
            <div className={'bg-gray-100 border border-light p-5 mb-5'}>
              {1 < watch('schools').length && (
                <div className={'flex items-center justify-between w-full mb-5'}>
                  <h2 key={Math.random()} className={'animate-flip-horizontal'}>
                    # {index + 1}
                  </h2>
                  <Tooltip content={'Remove this school'}>
                    <Button
                      id={`remove-${index}`}
                      style={{ padding: 0 }}
                      mainColor={'danger'}
                      isOutline
                      onClick={() => schoolsFieldArray.remove(index)}
                      disabled={watch('schools').length <= 1}
                    >
                      <Icon icon={GLOBAL_ICONS.close} container={false}/>
                    </Button>
                  </Tooltip>
                </div>
              )}
              <div className={'mb-5'}>
                <Input
                  {...register(`schools.${index}.name` as const)}
                  id={`name-${index}`}
                  label="School Name"
                  placeholder={'Enter the school name'}
                  required
                  error={errors.schools?.[index]?.name?.message}
                />
              </div>
              <div className={'mb-5'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      type={'number'}
                      id={`slots-${index}`}
                      min={0}
                      label="Max. number of teachers"
                      placeholder={'Enter the maximum number of teachers'}
                      required
                      onChange={({ target }) => {
                        field.onChange(parseInt(target.value))
                      }}
                      error={errors.schools?.[index]?.slots?.message}
                    />
                  )}
                  name={`schools.${index}.slots`}
                />
              </div>
              <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
                <div className={'mb-5'}>
                  <Input
                    {...register(`schools.${index}.coordinator_name` as const)}
                    id={`coordinator-name-${index}`}
                    label="School Coordinator Name"
                    placeholder={'Enter the name of the School Coordinator'}
                    required
                    error={errors.schools?.[index]?.coordinator_name?.message}
                  />
                </div>
                <div className={'mb-5'}>
                  <Input
                    {...register(`schools.${index}.coordinator_lastname` as const)}
                    id={`coordinator-lastname-${index}`}
                    label="School Coordinator Last Name"
                    placeholder={'Enter the last name of the School Coordinator'}
                    required
                    error={errors.schools?.[index]?.coordinator_lastname?.message}
                  />
                </div>
              </div>
              <div className={'mb-5'}>
                <Input
                  {...register(`schools.${index}.coordinator_email` as const)}
                  type={'email'}
                  id={`coordinator-email-${index}`}
                  label="School Coordinator Email"
                  placeholder={'Enter the email of the School Coordinator'}
                  required
                  error={errors.schools?.[index]?.coordinator_email?.message}
                />
              </div>
            </div>
          </div>
        ))}
        <div className={'w-full flex bg-gray-100 border border-dashed border-light p-5'}>
          <Button
            id="add"
            className={'m-auto'}
            mainColor={'secondary'}
            isOutline
            size={'sm'}
            onClick={() => schoolsFieldArray.append({ ...SCHOOL })}
          >
            Add school
          </Button>
        </div>
        <Controller
          control={control}
          render={({ field }) => (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
              onChange={(token) => field.onChange(token)}
              onErrored={() => field.onChange(null)}
              onExpired={() => field.onChange(null)}
            />
          )}
          name={'captcha'}
        />
        <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-5'}>
          <Button className={'mt-5'} type="button" onClick={() => previousPage(skipStep3 ? 2 : 3)}>
            Back
          </Button>
          <Button className={'mt-5'} id="submit" type="submit" disabled={isLoading || isSubmitting}>
            {isSubmitting || isLoading
              ? 'Loading...'
              : `Submit ${watch('schools').length} school${1 !== watch('schools').length ? 's' : ''}`}
          </Button>
        </div>
        <div className={'pt-10'}>
          <Alert type={'info'}>
            Having trouble completing this form? Please <LinkStyled
            href={'mailto:support@evidencebased.education?subject=Subject'}
            target={'_blank'}>contact us here</LinkStyled> to help!
          </Alert>
        </div>
      </form>
    </>
  );
};

export default MatForm;
