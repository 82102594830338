import React from 'react';
import Claire_Badger from '@app/asset/images/case_studies/Claire_Badger.png';
import Claire_Wells from '@app/asset/images/case_studies/Claire_Wells.png';
import David_Jones from '@app/asset/images/case_studies/David_Jones.png';
import Jamie_Kelleher from '@app/asset/images/case_studies/Jamie_Kelleher.png';
import Julia_Armstrong from '@app/asset/images/case_studies/Julia_Armstrong.png';
import Julie_Deville from '@app/asset/images/case_studies/Julie_Deville.jpg';
import Neil_Groves from '@app/asset/images/case_studies/Neil_Groves.png';
import Olivia_Graham from '@app/asset/images/case_studies/Olivia_Graham.png';
import Ollie_Harness from '@app/asset/images/case_studies/Ollie_Harness.png';
import Ruth_Stead from '@app/asset/images/case_studies/Ruth_Stead.png';

export interface CaseStudyInterface {
  id: string;
  person: string;
  title: string;
  organisation: string;
  quote: string;
  image: any;
  link: string;
}

export const case_studies: Array<CaseStudyInterface> = [
  {
    id: '1',
    person: 'Claire Badger',
    title: 'Senior Teacher, Teaching & Learning',
    organisation: 'Godolphin & Latymer School',
    quote: 'The Great Teaching Toolkit and its associated student survey tools can be so powerful.',
    image: Claire_Badger,
    link: 'https://evidencebased.education/using-student-surveys-to-support-professional-development/',
  },
  {
    id: '2',
    person: 'Claire Wells',
    title: 'Assistant Head for Teaching and Learning',
    organisation: 'Farmor’s School',
    quote:
      "It's a good framework for setting out your CPD and where you want your professional development to go. It's been invaluable, really — it's my go-to for teaching and learning!",
    image: Claire_Wells,
    link: 'https://evidencebased.education/the-gtt-is-my-go-to-for-teaching-and-learning/',
  },
  {
    id: '3',
    person: 'David Jones',
    title: 'Assistant Head',
    organisation: 'Wallington County Grammar School',
    quote:
      'Accuracy and validity were two important factors in choosing the student surveys – people can buy into it because they can see how well researched it is.',
    image: David_Jones,
    link: 'https://evidencebased.education/identifying-an-area-of-focus-with-student-surveys/',
  },
  {
    id: '4',
    person: 'Jamie Kelleher',
    title: 'Assistant Headteacher',
    organisation: 'Birchgrove Comprehensive School',
    quote:
      "The GTT has been transformative in both our teaching and learning, and CPD approaches. It's about professional learning that's done with you, not to you.",
    image: Jamie_Kelleher,
    link: 'https://evidencebased.education/transformative-cpd/',
  },
  {
    id: '5',
    person: 'Julia Armstrong',
    title: 'Assistant Head of Senior School',
    organisation: 'Kellett School',
    quote:
      "Everyone can be on a different 'journey' yet still have a shared common understanding of the 'elements' great teaching. This allows for great conversations about what is important in schools!",
    image: Julia_Armstrong,
    link: 'https://evidencebased.education/a-common-language-for-great-teaching/',
  },
  {
    id: '6',
    person: 'Julie Deville',
    title: 'CEO',
    organisation: 'Extol Trust',
    quote:
      'Blown away with the professional dialogue today across Extol Trust regarding our understanding of great teaching… this is our Golden Thread.',
    image: Julie_Deville,
    link: 'https://evidencebased.education/the-golden-thread-great-teaching-toolkit/',
  },
  {
    id: '7',
    person: 'Neil Groves',
    title: 'Head of Humanities',
    organisation: 'Bangkok Prep School',
    quote: 'I would 100% recommend it, absolutely no doubt about it!',
    image: Neil_Groves,
    link: 'https://evidencebased.education/i-would-100-recommend-the-gtt-no-doubt-about-it/',
  },
  {
    id: '8',
    person: 'Olivia Graham',
    title: 'Assistant Headteacher',
    organisation: 'Queen Elizabeth II High School',
    quote:
      'The GTT clears the way through the abundance of research, platforms and overwhelming range of opportunities out there!',
    image: Olivia_Graham,
    link: 'https://evidencebased.education/empowering-staff-with-their-professional-development/',
  },
  {
    id: '9',
    person: 'Ollie Harness',
    title: 'Senior Lecturer',
    organisation: 'Teesside University',
    quote: 'I think what the Great Teaching Toolkit offers is a way for us to enhance our feedback.',
    image: Ollie_Harness,
    link: 'https://evidencebased.education/using-the-great-teaching-toolkit-as-part-of-an-itt-programme/',
  },
  {
    id: '10',
    person: 'Ruth Stead',
    title: 'Assistant Headteacher',
    organisation: 'Netherhall School',
    quote:
      'You can see the correlation between the early career framework and the Great Teaching Toolkit. It all works together, and I think that is what schools need.',
    image: Ruth_Stead,
    link: 'https://evidencebased.education/bespoke-targeted-and-relevant-cpd/',
  },
];
