import React, { useRef, useState } from 'react';
import classnames from 'classnames';
import { capitalize } from "../../../helpers/misc";
import Progress from '../../../global/progress/progress';
import RadarChartSection from '../../feedback/sections/radarChartSection';
import { VERSION_B, VERSION_C_D } from '../../instrument/sections/instrumentForm';
import Icon from '../../../global/icon/icon';
import { MoreInfo } from "../../../global/tooltip/common";
import { LinkStyled } from "../../../global/link/link.styled";
import { useQuery } from "react-query";
import { ME } from "../../../service/queryKeys";
import { getMe } from "../../../service/api";
import { MAT_STATIC_PICTURE_USER } from "../../../resources/roles";
import MAT_static_picture from "@app/asset/images/MAT_static_picture.png";

const PROGRESS_STYLES = {
  minWidth: 350,
};

type TabType = 'gtt_training' | 'identify' | 'explore' | 'integrate';

const TABS: { [key in TabType as string]: string } = {
  gtt_training: 'GTT Training',
  identify: 'Identify',
  explore: 'Explore',
  integrate: 'Integrate',
};

const SchoolSummary = ({ data, schoolId }: { data: any; schoolId?: string | number }) => {

  const { data: meData } = useQuery(ME, getMe, {
    select: (data) => data.data.data.me,
    placeholderData: {},
    staleTime: Infinity,
  });

  const sliderId = 'dashboard_slider';

  const tabId = (tab: TabType) => {
    return `${tab}__${schoolId ?? 'default'}`;
  }

  const container = useRef<HTMLDivElement>(null);
  const [atStart, setAtStart] = useState(true);
  const [atEnd, setAtEnd] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [draggingAt, setDraggingAt] = useState<number | undefined>(undefined);
  const [activeTab, setActiveTab] = useState(tabId('gtt_training'));

  const handleScrollTo = (id: TabType): void => {
    const element = document.getElementById(tabId(id));
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };

  const handleClickOnArrow = (direction: 'left' | 'right'): void => {
    if (container.current) {
      const offset = (direction === 'left' ? -1 : 1) * 200;
      container.current.scrollLeft += offset;
    }
  };

  const scrolling = (): void => {
    if (container.current) {

      const { scrollLeft, clientWidth, scrollWidth } = container.current;

      if (scrollLeft <= 0) {
        setAtStart(true);
      } else {
        setAtStart(false);
      }

      if (scrollLeft + clientWidth >= scrollWidth) {
        setAtEnd(true);
      } else {
        setAtEnd(false);
      }
    }
  };

  const observerCallback = (entries: IntersectionObserverEntry[], observer: IntersectionObserver): void => {
    entries.forEach((entry) => {
      if (entry.isIntersecting && entry.intersectionRatio === 1) {
        setActiveTab(entry.target.id);
      }
    })
  }

  const observeDiv = () => {
    const observer = new IntersectionObserver(observerCallback, {
      root: document.getElementById(sliderId),
      rootMargin: '4000px 0px 4000px 0px',
      threshold: [0.5],
    });

    const gtt_training = document.getElementById(tabId('gtt_training'));
    const identify = document.getElementById(tabId('identify'));
    const explore = document.getElementById(tabId('explore'));
    const integrate = document.getElementById(tabId('integrate'));

    if (gtt_training && identify && explore && integrate) {
      observer.observe(gtt_training);
      observer.observe(identify);
      observer.observe(explore);
      observer.observe(integrate);
    }
  }

  const getNavBtn = (direction: 'left' | 'right', size: 'sm' | 'md' = 'md') => {
    const styles = (disabled: boolean) => {
      return disabled ? 'opacity-25 cursor-auto' : 'opacity-75 hover:opacity-100';
    }
    return (
      <button
        type={'button'}
        aria-label={capitalize(direction)}
        onClick={() => handleClickOnArrow(direction)}
        disabled={direction === 'left' ? atStart : atEnd}
        className={direction === 'left' ? styles(atStart) : styles(atEnd)}
      >
        <Icon
          icon={direction === 'left' ? 'ChevronLeft' : 'ChevronRight'}
          elementSize={size === 'md' ? 75 : 40}
          color={'muted'}
        />
      </button>
    )
  }

  return (
    <>
      <div className={'mb-10'}>
        {meData.email === MAT_STATIC_PICTURE_USER.email && schoolId === MAT_STATIC_PICTURE_USER.schoolId ?
          <img src={MAT_static_picture} alt={'MAT static graph picture'} width={'100%'} height={'auto'}/>
          :
          <RadarChartSection
            surveyVersion={data.has_version_b_instruments ? VERSION_B : VERSION_C_D}
            schoolId={schoolId}
          />
        }
      </div>
      {/* Dials */}
      <div className={'flex xl:hidden justify-between'}>
        <div className={'lg:w-full flex items-center justify-between'}>
          {getNavBtn('left', 'sm')}
          {getNavBtn('right', 'sm')}
        </div>
        <div className={'flex items-center lg:hidden'}>
          <select
            value={activeTab}
            onChange={(event) => handleScrollTo(event.target.value as TabType)}
          >
            {Object.keys(TABS).map((key, index) => (
              <option
                key={`tab_option__${key}`}
                value={key}
              >
                {TABS[key]}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className={'flex items-center'}>
        <div className={'hidden xl:block'}>
          {getNavBtn('left')}
        </div>
        <div className={'relative flex-1 overflow-x-auto'}>
          <div className={'pb-2 flex items-center justify-end'}>
            <MoreInfo
              content={
                <>
                  Check out the progress dials in your Dashboard. These show aggregated data for staff progress
                  on their journey through the GTT. You can find out more about what the dials show{' '}
                  <LinkStyled
                    href={'https://support.evidencebased.education/en/knowledge/what-are-dashboard-progress-dials'}
                    target={'_blank'}
                  >
                    here
                  </LinkStyled>
                </>
              }
            />
          </div>
          <div className={'border'}>
            {/* Tabs */}
            <div className={'hidden lg:flex items-center'}>
              {Object.keys(TABS).map((key, index) => (
                <button
                  key={`tab__${key}`}
                  type={'button'}
                  className={`w-1/4 transition text-sm font-display ${activeTab === tabId(key as TabType) ? 'font-bold bg-gray-100' : 'bg-light hover:bg-gray-100'}
                ${classnames({
                    'border-r border-gray-300': index + 1 < Object.keys(TABS).length,
                  })} py-2`}
                  onClick={() => {
                    handleScrollTo(key as TabType);
                  }}
                >
                  {TABS[key]}
                </button>
              ))}
            </div>
            <div
              ref={container}
              id={sliderId}
              className={'transition overflow-x-scroll md:mx-5 py-10'}
              style={{
                cursor: dragging ? 'grabbing' : 'pointer',
              }}
              onScroll={() => {
                scrolling();
                observeDiv();
              }}
              onMouseDown={(event) => {
                if (container.current) {
                  setDragging(true);
                  setDraggingAt(event.pageX - container.current.offsetLeft);
                }
              }}
              onMouseLeave={() => {
                setDragging(false);
              }}
              onMouseUp={() => {
                setDragging(false);
              }}
              onMouseMove={(event) => {
                if (dragging && draggingAt && container.current) {
                  event.preventDefault();
                  const speed = 0.05; // increment this to move faster
                  const x = event.pageX - container.current.offsetLeft;
                  const move = (x - draggingAt) * speed;
                  container.current.scrollLeft -= move;
                }
              }}
            >
              <div className={'flex items-top gap-2 md:gap-5'}>
                {/* GTT Training */}
                <div id={tabId('gtt_training')} className={'flex items-top bg-gray-100 bg-opacity-20'}>
                  {/* 1. Introduction */}
                  <div style={PROGRESS_STYLES}>
                    <Progress
                      progress={data.onboarding}
                      label="Introduction"
                      description="have completed Section 1 of the Foundation Course"
                    />
                  </div>
                  {/* 2. Foundation */}
                  <div style={PROGRESS_STYLES}>
                    <Progress
                      progress={data.foundation}
                      label="Foundation"
                      description="have completed the Foundation Course"
                    />
                  </div>
                </div>
                {/* Identify */}
                <div id={tabId('identify')} className={'flex items-top bg-gray-100 bg-opacity-20'}>
                  {/* 3. GTT Profile */}
                  <div style={PROGRESS_STYLES}>
                    <Progress progress={data.gtt_profile} label="GTT Profile" description="have created a GTT profile"/>
                  </div>
                  {/* 4. Element Focus */}
                  <div style={PROGRESS_STYLES}>
                    <Progress
                      progress={data.element_focus === null ? 0 : data.element_focus}
                      label="Element Focus"
                      description="have decided upon an element of teaching to improve"
                    />
                  </div>
                </div>
                {/* Explore */}
                <div id={tabId('explore')} className={'flex items-top bg-gray-100 bg-opacity-20'}>
                  {/* 5. Started Course */}
                  <div style={PROGRESS_STYLES}>
                    <Progress
                      progress={data.started_course}
                      label="Started Course"
                      description="have started a teacher course"
                    />
                  </div>
                  {/* 6. Completed Course */}
                  <div style={PROGRESS_STYLES}>
                    <Progress
                      progress={data.completed_course}
                      label="Completed Course"
                      description="have completed a teacher course"
                    />
                  </div>
                </div>
                {/* 7. Development Goal */}
                {/*<div id={tabId('integrate')} style={PROGRESS_STYLES} className={'bg-gray-100 bg-opacity-20'}>*/}
                {/*  <Progress*/}
                {/*    progress={data.goal}*/}
                {/*    label="Development Goal"*/}
                {/*    description="are working through development cycles to reach their goal"*/}
                {/*  />*/}
                {/*</div>*/}
              </div>
            </div>
            <div className={'text-center text-xl text-muted font-display px-2 py-5'}>
              {data.teachers} {data.teachers === 1 ? 'teacher' : 'teachers'} at {data.school} {data.teachers === 1 ? 'is' : 'are'} using
              the Great Teaching Toolkit
            </div>
          </div>
        </div>
        <div className={'hidden xl:block'}>
          {getNavBtn('right')}
        </div>
      </div>
    </>
  );
};

export default SchoolSummary;
