import React from 'react';
import { useQuery } from 'react-query';
import { getMe } from '../../service/api';
import DashboardLayout from '../../layout/dashboard';
import ScrollSpy from '../../global/scrollSpy/scrollSpy';
import {
  ROLE_ASSISTANT_GT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_SYS_ADMIN,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
} from '../../resources/roles';
import SchoolAdminPathwayView from './views/SchoolAdminPathwayView';
import SysAdminPathwayView from './views/SysAdminPathwayView';
import { ME } from '../../service/queryKeys';
import { SectionType } from '../pathway/pathwaysPage.types';
import IndependentTeacherView from './views/IndependentTeacherView';

const OrganisationManagementPage = () => {
  const me = useQuery(ME, getMe, {
    select: (data) => data.data.data.me,
    placeholderData: {},
    staleTime: Infinity,
  });

  return (
    <DashboardLayout
      title={
        me.data.role === ROLE_TEACHER_FREE || (me.data.role === ROLE_TEACHER && me.data.school_user_id == null)
          ? 'Setup'
          : 'School planner'
      }
    >
      <div className={'flex'}>
        <div className={'lg:w-5/6'}>
          {ROLE_SYS_ADMIN === me.data.role && <SysAdminPathwayView />}
          {[ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.data.role) && (
            <SchoolAdminPathwayView me={me.data} />
          )}
          {(ROLE_TEACHER_FREE === me.data.role ||
            (me.data.role === ROLE_TEACHER && me.data.school_user_id == null)) && (
            <IndependentTeacherView me={me.data} />
          )}
        </div>
        <div className={'hidden lg:block lg:w-1/6 pl-10'}>
          <ScrollSpy
            options={
              [
                ...(ROLE_SYS_ADMIN === me.data.role
                  ? [
                      { value: 'strategies', label: 'Strategies' },
                      { value: 'meeting-templates', label: 'Agenda templates' },
                      { value: 'meeting-pathways', label: 'Pathways' },
                    ]
                  : []),
                ...([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(me.data.role)
                  ? [
                      { value: 'academic-years', label: 'Academic years' },
                      { value: 'terms', label: 'Terms' },
                      { value: 'events', label: 'School events' },
                      { value: 'strategies', label: 'Strategies' },
                      { value: 'goals', label: 'Goals' },
                      { value: 'plans', label: 'Plans' },
                    ]
                  : []),
                ...(ROLE_TEACHER_FREE === me.data.role ||
                (me.data.role === ROLE_TEACHER && me.data.school_user_id == null)
                  ? [
                      { value: 'academic-years', label: 'Academic years' },
                      { value: 'terms', label: 'Terms' },
                    ]
                  : []),
              ] as { value: SectionType; label: string }[]
            }
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default OrganisationManagementPage;
