import {
  ExistingStep3Type,
  NO_LINK,
  RegistrationStateType,
  Step1Type,
  Step2Type,
  Step3Type,
  Step4Type,
  StepType,
  TeacherInfoType,
} from './provider';
import { ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER, ROLE_TEACHER_FREE } from '../../resources/roles';
import { GTT_SOURCE_OPTIONS, GTT_SOURCE_OPTIONS_OTHER } from '../../pages/auth/Registration/Step2';
import { ROLE_OPTIONS, ROLE_OPTIONS_OTHER } from '../../pages/auth/Registration/sections/step4/TeacherForm';
import { postRegisterUser } from '../../service/api';
import {logout} from "../../service/auth";

export const SET_STEP = 'SET_STEP';
export const SET_STATE = 'SET_STATE';
export const CLEAN_STATE = 'CLEAN_STATE';
export const NEXT_PAGE = 'NEXT_PAGE';
export const PREVIOUS_PAGE = 'PREVIOUS_PAGE';
export const SET_INITIAL_PAGE = 'SET_INITIAL_PAGE';
export const SET_LINK = 'SET_LINK';
export const SUBMIT_FORM = 'SUBMIT_FORM';

const createActions = (state: RegistrationStateType, dispatch: any) => {
  return {
    setStep: (step: StepType, data: Step1Type | Step2Type | Step3Type | ExistingStep3Type | Step4Type) => {
      dispatch({ type: SET_STEP, step, data });
    },
    setState: (data: any) => {
      dispatch({ type: SET_STATE, data });
    },
    cleanState: () => {
      dispatch({ type: CLEAN_STATE });
    },
    previousPage: (data?: number) => {
      dispatch({ type: PREVIOUS_PAGE, data });
    },
    nextPage: (data?: number) => {
      dispatch({ type: NEXT_PAGE, data });
    },
    setInitialPage: (data: number) => {
      dispatch({ type: SET_INITIAL_PAGE, data });
    },
    setLink: (data: string) => {
      dispatch({ type: SET_LINK, data });
    },
    submitForm: (callback?: () => void, additionalData: any = {}) => {
      const step1Values = { ...state.step1 } as Step1Type;
      const step2Values = { ...state.step2 } as Step2Type;
      let step3Values = { ...state.step3 } as Step3Type;
      const step4Values = { ...state.step4 } as Step4Type;

      // step3 is the only one necessary at this moment
      if (additionalData?.step3) {
        step3Values = { ...step3Values, ...additionalData?.step3 } as Step3Type;
      }

      delete step2Values?.confirm_email;
      delete step2Values?.confirm_password;
      delete step2Values?.blocked;
      delete step2Values?.terms_and_conditions;

      if (state.step1?.role === ROLE_SCHOOL_COORDINATOR) {
        step2Values.gtt_source = GTT_SOURCE_OPTIONS.some(
          (option) => option.value === step2Values?.gtt_source && option.value !== GTT_SOURCE_OPTIONS_OTHER
        )
          ? step2Values?.gtt_source
          : step2Values?.gtt_source_other;

        delete step2Values?.gtt_source_other;
      }

      if (state.step1?.role === ROLE_TEACHER_FREE || state.step1?.role === ROLE_TEACHER) {
        (step4Values as TeacherInfoType).role_type = ROLE_OPTIONS.some(
          (option) => option.value === (step4Values as TeacherInfoType)?.role_type && option.value !== ROLE_OPTIONS_OTHER
        )
          ? (step4Values as TeacherInfoType)?.role_type
          : (step4Values as TeacherInfoType)?.role_other_type;

        delete (step4Values as TeacherInfoType).role_other_type;
      }

      if (state.step1?.role !== ROLE_SCHOOL_COORDINATOR) {
        delete step2Values?.role_description;
        delete step2Values?.age_range;
        delete step2Values?.category_1;
        delete step2Values?.category_2;
        delete step2Values?.category_3;
        delete step2Values?.socioeconomic_composition;
      }

      postRegisterUser({
        ...step1Values,
        ...step2Values,
        subscription: step3Values,
        ...step4Values,
      })
        .then((response) => {
          logout(false)
          dispatch({
            type: SUBMIT_FORM,
            data: {
              ...state,
              link: response.data.data.link ?? NO_LINK,
              currentPage: 5,
            },
          });
        })
        .finally(() => {
          if (callback) {
            callback();
          }
        });
    },
  };
};

export type RegistrationActionType = {
  setStep: (step: StepType, data: Step1Type | Step2Type | Step3Type | ExistingStep3Type | Step4Type) => void;
  setState: (data: any) => void;
  cleanState: () => void;
  previousPage: (data?: number) => void;
  nextPage: (data?: number) => void;
  setInitialPage: (data: number) => void;
  setLink: (data: string) => void;
  submitForm: (callback?: () => void, additionalData?: any) => void;
};

export default createActions;
