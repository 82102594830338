import React, { HTMLAttributes } from 'react';
import { NotificationProps } from './notification.types';
import dayjs from 'dayjs';
import Button from '../button/button';
import classnames from 'classnames';
import Tag from '../tag/tag';
import { useMutation, useQueryClient } from 'react-query';
import { patchReadNotifications } from '../../service/api';
import { NOTIFICATIONS } from '../../service/queryKeys';
import NotificationHandler from '../../pages/dashboard/notificationHandler';

type Ref = HTMLDivElement;

interface Props extends NotificationProps, Omit<HTMLAttributes<Ref>, 'id' | 'title'> {
}

const Notification = React.forwardRef<Ref, Props>(
  ({ id, title, message, read, start_date, type, ...other }: Props, ref) => {
    const queryClient = useQueryClient();

    const readMutation = useMutation(patchReadNotifications, {
      onSuccess: () => {
        queryClient.invalidateQueries([NOTIFICATIONS, 'new']);
        queryClient.invalidateQueries([NOTIFICATIONS, 'read']);
      },
    });

    const getTimeAgo = () => {
      const minutes = dayjs(new Date()).diff(dayjs(start_date, 'DD/MM/YYYY').toDate(), 'minutes');

      if (minutes >= 1440) {
        return `${Math.round(minutes / (60 * 24))} day${Math.round(minutes / (60 * 24)) === 1 ? '' : 's'} ago`;
      } else if (minutes >= 60) {
        return `${Math.round(minutes / 60)} hour${Math.round(minutes / 60) === 1 ? '' : 's'} ago`;
      } else {
        if (minutes <= 1) {
          return `Just now`;
        } else {
          return `${minutes} minutes ago`;
        }
      }
    };

    return (
      <div
        ref={ref}
        className={`flex border p-1 md:py-3 md:px-5 ${classnames({
          'bg-yellow-100 bg-opacity-50': type === 'global',
        })}`}
        {...(other as HTMLAttributes<Ref>)}
      >
        <div className={'flex py-3 px-2'}>
          <div className={'mx-auto'}>
            <div className={'relative w-2 h-2 flex my-2'}>
              {read ? (
                <div className={'absolute inset-0 z-10 w-full h-full bg-light rounded-full'}/>
              ) : (
                <React.Fragment>
                  <div className={'animate-ping absolute inset-0 z-10 w-full h-full bg-red-600 rounded-full'}/>
                  <div className={'absolute inset-0 z-20 w-full h-full bg-red-500 rounded-full'}/>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div className={'flex-1 py-3 px-2'}>
          <div className={'flex items-center justify-between mb-2'}>
            <div className={'font-display font-bold'}>{title}</div>
            {type === 'global' && <Tag content={'System'} mainColor={'warning'}/>}
          </div>
          <div className={'leading-tight border-b mb-2 pb-2'} style={{ whiteSpace: 'pre-line' }}>
            {<NotificationHandler str={message}/>}
          </div>
          <div className={'text-sm flex items-center justify-between'}>
            <div className={'text-muted'}>
              {dayjs(dayjs(start_date, 'DD/MM/YYYY').toDate()).format('dddd DD MMMM YYYY')}
              {' · '}
              {getTimeAgo()}
            </div>
            {type === 'automated' && (
              <Button
                asLink
                size={'sm'}
                mainColor={'secondary'}
                style={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}
                onClick={() =>
                  readMutation.mutate({
                    ids: [id],
                    read: !read,
                  })
                }
              >
                {read ? 'Mark as unread' : 'Mark as read'}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
);

Notification.displayName = 'Notification';

export default Notification;
