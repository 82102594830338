import React from 'react';
import classnames from 'classnames';
import { ListItemProps } from './list.types';
import Icon from '../../../global/icon/icon';
import { ActionButton } from '../../../global/buttonIcon/common';
import { GLOBAL_ICONS } from '../../../resources/vars';
// Added indexNumber due to listItem animate-fade-in class causing overlapping selectors in the steps form (Planning Tool)
const ListItem = ({ draggable, index, children, removeDisabled, handleRemove, indexNumber }: ListItemProps) => (
  <div className={`flex flex-wrap`}>
    <div className={'w-1/6 relative border flex items-center justify-center item-container'}>
      {draggable && (
        <div className={'cursor-pointer absolute -left-2'}>
          <Icon icon={GLOBAL_ICONS['drag']} color={'muted'} container={false} />
        </div>
      )}
      {/*if index number is passed, it will be displayed, if not it'll go with the index*/}
      <div>{indexNumber? indexNumber + 1 : index + 1}</div>
    </div>
    <div className={'w-4/6 border item-container'}>{children}</div>
    <div className={'w-1/6 flex border item-container'}>
      <ActionButton.Remove disabled={removeDisabled} className={'m-auto'} onClick={handleRemove} />
    </div>
  </div>
);

export default ListItem;
