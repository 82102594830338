import React, { ButtonHTMLAttributes } from 'react';
import { ButtonIconStyled } from './buttonIcon.styled';
import { ButtonIconProps } from './buttonIcon.types';
import Tooltip from '../tooltip/tooltip';
import Icon from '../icon/icon';
import { SIZE_ICON } from '../../resources/vars';

type Ref = HTMLButtonElement;

const ButtonIcon = React.forwardRef<Ref, ButtonIconProps & ButtonHTMLAttributes<HTMLButtonElement> & {disableTooltip?: boolean}>(
  (
    {
      icon,
      mainColor = 'primary',
      isOutline = false,
      size = 'md',
      label,
      isCircle = false,
      isFree = false,
      tippyProps = null,
      disableTooltip = false,
      ...other
    }: ButtonIconProps & {disableTooltip?: boolean},
    ref
  ): JSX.Element => (
    <Tooltip  disabled={disableTooltip} content={label} {...tippyProps}>
      <ButtonIconStyled
        ref={ref}
        size={size}
        mainColor={mainColor}
        isOutline={isOutline}
        isCircle={isCircle}
        isFree={isFree}
        {...(other as any)}
      >
        <Icon elementSize={SIZE_ICON[size]} icon={icon} container={false} />
      </ButtonIconStyled>
    </Tooltip>
  )
);

ButtonIcon.displayName = 'ButtonIcon';

export default ButtonIcon;
