import React, { useEffect } from 'react';
import DefaultTable from '../../../global/table/defaultTable';
import Button from '../../../global/button/button';
import { downloadResource, getDevelopmentActivities } from '../../../service/api';
import { useQuery } from 'react-query';
import { DEVELOPMENT_ACTIVITIES } from '../../../service/queryKeys';
import useModal from '../../../hooks/useModal';
import AddDevelopmentActivityModal from '../modals/addDevelopmentActivityModal';
import { LinkStyled } from '../../../global/link/link.styled';
import { ROLE_TEACHER_FREE, RolesType } from '../../../resources/roles';
import { ActionButton } from '../../../global/buttonIcon/common';
import DeleteActivityModal from '../modals/DeleteActivityModal';

export type ActivityType = {
  id: string;
  term: string;
  pathway: null | string;
  pathway_number: number;
  pathway_title: string;
  pathway_step_type: string;
  academic_year: null | string;
  development_period: null | string;
  element?: {
    value: string;
    label: string;
    type: string;
  };
  activity_type: string;
  activity: string | null;
  hours: string | null;
  started: string;
  completed: string | null;
  documents: Array<{ id: string; name: string }>;
  auto_logged: boolean | null;
  editable: boolean | null;
};

const COLUMNS = [
  { id: 'term', label: 'Term' },
  { id: 'pathway', label: 'Development cycle' },
  { id: 'element', label: 'Element' },
  { id: 'activity_type', label: 'Activity type' },
  { id: 'activity', label: 'Activity' },
  { id: 'hours', label: 'Hours' },
  { id: 'started', label: 'Started' },
  { id: 'completed', label: 'Completed' },
  { id: 'status', label: 'Status' },
  { id: 'documents', label: 'Documents' },
  { id: 'actions', label: 'Actions' },
];

const DevelopmentActivitiesTable = ({
  academicYear,
  userRole,
  setHideActivitiesTable,
  hidden,
}: {
  academicYear?: string;
  userRole: RolesType;
  setHideActivitiesTable?: any;
  hidden?: boolean | string | undefined;
}) => {
  const { data: activities, isFetched } = useQuery(
    [DEVELOPMENT_ACTIVITIES, academicYear],
    () => getDevelopmentActivities(academicYear),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
    }
  );

  const [addActivityModal, toggleAddActivityModal, setAddActivityModalProps] = useModal((props: any) => (
    <AddDevelopmentActivityModal {...props} />
  ));

  const [deleteActivityModal, toggleDeleteActivityModal, setDeleteActivityModalProps] = useModal((props: any) => (
    <DeleteActivityModal {...props} />
  ));

  const isFree = userRole === ROLE_TEACHER_FREE;

  useEffect(() => {
    if (hidden != true) {
      if (isFetched && activities.length > 0 && isFree) {
        setHideActivitiesTable(true);
      }
    }
  }, [activities, isFetched]);

  return (
    <div className={'w-full'}>
      {addActivityModal}
      {deleteActivityModal}
      {userRole !== ROLE_TEACHER_FREE && (
        <div className={'flex flex-row-reverse right-0 w-100 mb-2'}>
          <Button
            size={'sm'}
            onClick={() => {
              setAddActivityModalProps({
                editActivity: null,
                preventCloseOnClickOnMask: true,
                title: 'Add a development activity',
              });
              toggleAddActivityModal(true);
            }}
          >
            Add activity
          </Button>
        </div>
      )}
      <DefaultTable
        id={'development-pathways-table'}
        showCounter={false}
        columns={COLUMNS}
        rows={activities.map((activity: ActivityType) => {
          return {
            id: `${activity.id}`,
            cells: [
              { id: 'term', content: activity.term },
              { id: 'pathway', content: activity.pathway_title ?? activity.pathway_number },
              { id: 'element', content: activity.element?.label },
              { id: 'activity_type', content: activity.activity_type },
              { id: 'activity', content: activity.activity },
              { id: 'hours', content: activity.hours },
              { id: 'started', content: activity.started },
              { id: 'completed', content: activity.completed },
              { id: 'status', content: activity.completed ? 'Completed' : 'In Progress' },
              {
                id: 'documents',
                content: (
                  <>
                    {activity.documents.map((document) => (
                      <li key={Math.random()}>
                        <LinkStyled
                          href={'#'}
                          onClick={(e: any) => {
                            e.preventDefault();
                            downloadResource(document.id, document.name);
                          }}
                          target={'_blank'}
                        >
                          {document.name}
                        </LinkStyled>
                      </li>
                    ))}
                  </>
                ),
              },
              {
                id: 'actions',
                content: activity.editable && (
                  <div className={'flex'}>
                    <ActionButton.Edit
                      className={'m-auto'}
                      onClick={() => {
                        setAddActivityModalProps({
                          editActivity: activity,
                          title: 'Edit a development activity',
                          preventCloseOnClickOnMask: true,
                        });
                        toggleAddActivityModal(true);
                      }}
                      disabled={userRole === ROLE_TEACHER_FREE}
                    />
                    <ActionButton.Remove
                      className={'m-auto'}
                      onClick={() => {
                        setDeleteActivityModalProps({
                          activityId: activity.id,
                          title: 'Delete a development activity',
                          preventCloseOnClickOnMask: true,
                        });

                        toggleDeleteActivityModal(true);
                      }}
                    />
                  </div>
                ),
              },
            ],
          };
        })}
      />
    </div>
  );
};

export default DevelopmentActivitiesTable;
