import React from 'react';
import { BadgeType } from './usePassportBadges';

const Badge = ({ name, image, date }: Omit<BadgeType, 'id'>) => {
  return (
    <div className={'flex place-content-center'}>
      <img src={image} alt={name} className={'w-20 lg:w-24 xl:w-32'} />
      <div className={'ml-2 w-40 m-auto'}>
        <div className={'font-bold ' + (null === date ? 'text-gray-400' : '')}>{name}</div>
        <div>{date}</div>
      </div>
    </div>
  );
};

export default Badge;
