import React from 'react';
import { VideoType } from '../videoObservationPage';
import VideoThumbnail from '../components/VideoThumbnail';

const MyVideosTab = ({ videos, userId }: MyVideosTabType) => {
  return (
    <div className={'container grid grid-cols-1 gap-2 md:grid-cols-2 lg:grid-cols-4 mx-auto'}>
      {videos.map((video) => (
        <VideoThumbnail video={video} key={video.id} userId={userId} />
      ))}
    </div>
  );
};

export default MyVideosTab;

type MyVideosTabType = {
  videos: Array<VideoType>;
  userId: string;
};
