import React, { useState } from 'react';
import Select from "react-select";
import Button from "../../../global/button/button";
import { useQuery, useQueryClient } from "react-query";
import { FOCUS_OPTIONS, PATHWAYS_V2_STEP } from "../../../service/queryKeys";
import { getFocusOptions } from "../../../service/api";
import { EXPLORE_STEP } from "../views/PathwayV2View";
import { toast } from "react-toastify";

const SelectFocus = ({
  disabled = false,
  save
}: { disabled?: boolean, save: (data: { focus_element: string, focus_type: string }, callback?: () => void) => void }) => {
  const { data: options } = useQuery(FOCUS_OPTIONS, () => getFocusOptions(), {
    select: (data) => data.data.data,
    staleTime: Infinity,
  });

  const optionsExplained = [
    { label: 'Dimension 1: Understanding the content', value: '1', type: 'dimension' },
    { label: '1.1 Deep and fluent knowledge', value: '1.1', type: 'element' },
    { label: '1.2 Curriculum sequencing', value: '1.2', type: 'element' },
    { label: '1.3 Relevant curriculum tasks', value: '1.3', type: 'element' },
    { label: '1.4 Strategies and misconceptions', value: '1.4', type: 'element' },
    { label: 'Dimension 2: Creating a supportive environment', value: '2', type: 'dimension' },
    { label: '2.1 Teacher-student relationships', value: '2.1', type: 'element' },
    { label: '2.2 Student-student relationships', value: '2.2', type: 'element' },
    { label: '2.3 Learner motivation', value: '2.3', type: 'element' },
    { label: '2.4 Climate of high expectations', value: '2.4', type: 'element' },
    { label: 'Dimension 3: Maximising opportunity to learn', value: '3', type: 'dimension' },
    { label: '3.1 Managing time and resources', value: '3.1', type: 'element' },
    { label: '3.2 Rules and consequences', value: '3.2', type: 'element' },
    { label: '3.3 Managing disruptive behaviour', value: '3.3', type: 'element' },
    { label: 'Dimension 4: Activating hard thinking', value: '4', type: 'dimension' },
    { label: '4.1 Structuring', value: '4.1', type: 'element' },
    { label: '4.2 Explaining', value: '4.2', type: 'element' },
    { label: '4.3 Questioning', value: '4.3', type: 'element' },
    { label: '4.4 Interacting', value: '4.4', type: 'element' },
    { label: '4.5 Embedding', value: '4.5', type: 'element' },
    { label: '4.6 Activating', value: '4.6', type: 'element' }
  ];


  const queryClient = useQueryClient();

  const [focus, setFocus] = useState<{ label: string, value: string, type: string } | null>(null);
  return (
    <div className={'flex'}>
      <div className={'w-32 font-bold  pt-2 mb-2'}>Select element</div>
      <Select
        className={'w-2/6 mr-3'}
        options={optionsExplained}
        value={focus}
        onChange={(value) => setFocus(value)}
        isDisabled={disabled}
      />
      <Button
        id={'identifyCompleteButton'}
        size={"sm"}
        onClick={() => {
          save({ focus_element: focus!.value, focus_type: focus!.type }, () => {
            queryClient.invalidateQueries([PATHWAYS_V2_STEP, EXPLORE_STEP]);
          })
          setFocus(null)
          toast.success(`You have chosen ${focus!.label}`);
        }}
        disabled={disabled || focus == null}
      >
        Select & continue
      </Button>
    </div>
  )
    ;
};

export default SelectFocus;
