import React from 'react';
import { toast } from 'react-toastify';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';

const DeleteConfirmModal = ({ toggle, handleSubmit }: ModalToggleProps & DeleteConfirmModalType) => (
  <Modal>
    <Modal.Body>
      <p>
        Are you sure you want to remove this Feedback Tool?
      </p>
    </Modal.Body>
    <Modal.Footer>
      <Modal.CancelButton
        onClick={() => {
          toggle(false);
        }}
      />
      <Modal.ConfirmButton
        onClick={() => {
          handleSubmit().then(() => {
            toggle(false);
            toast.success('This Feedback Tool has been removed successfully.');
          });
        }}
      />
    </Modal.Footer>
  </Modal>
);

DeleteConfirmModal.displayName = 'DeleteConfirmModal';

type DeleteConfirmModalType = {
  handleSubmit: () => Promise<any>;
};

export default DeleteConfirmModal;
