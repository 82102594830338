import React from 'react';
import { Formik } from 'formik';
import { UserType } from '../userManagementPage';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { useMutation, useQueryClient } from 'react-query';
import { updateSchool } from '../../../service/api';
import Modal from '../../../global/messages/modal/modal.components';
import { USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import * as Yup from 'yup';
import Input from '../../../form/input/input';

const EditSchoolModal = ({ user, toggle }: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(updateSchool, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toggle(false);
    },
  });

  return (
    <Formik
      initialValues={{
        name: user.organisation,
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().trim().required(),
      })}
      validateOnMount
      onSubmit={({ name }) => {
        mutate({ id: user.school_id!, name });
      }}
    >
      {({ isValid, handleSubmit, values, handleChange }) => (
        <Modal>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Input id="name" label={'School name'} required value={values.name} onChange={handleChange} />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                type={'submit'}
                disabled={!isValid || isLoading}
                label={isLoading ? 'Loading...' : 'Confirm'}
              />
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default EditSchoolModal;
