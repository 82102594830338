import styled, {DefaultTheme, ThemeProps} from "styled-components";
import {lighten, readableColor} from "polished";
import {getBaseFieldStyle, getFocusStyle, getMainColor} from "../../helpers/styles";
import {DEFAULT_THEME} from "../../Theme";
import {CLASS_PREFIX} from "../../resources/vars";
import {DEFAULT_TRANSITION_DURATION, DEFAULT_TRANSITION_FUNC} from "../../global/transitions/transitions.types";

export const DatepickerStyled = styled('div')<ThemeProps<DefaultTheme>>`
  .react-datepicker-wrapper {
    width: 100%;
    .${CLASS_PREFIX}--datepicker_input {
      ${(props) => getBaseFieldStyle(props, true, false)}
      &.react-datepicker-ignore-onclickoutside {
        border-color: ${(props) => props.theme.colors.primary};
        ${(props) => getFocusStyle(getMainColor(props))}
      }
    }
  }
  .react-datepicker-popper {
    z-index: 60;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 6px, rgba(0, 0, 0, 0.07) 0px 0px 0px 1px;
    transition: transform ${DEFAULT_TRANSITION_DURATION} ${DEFAULT_TRANSITION_FUNC};
  }
  .react-datepicker {
    &, .react-datepicker__header {
      border-radius: 0;
    }
    .react-datepicker__header,
    .react-datepicker__today-button {
      background-color: ${(props) => props.theme.colors.light};
    }
    .react-datepicker__navigation--previous {
      border-right-color: ${(props) => props.theme.colors.secondary};
      &:hover {
        border-right-color: ${(props) => props.theme.colors.primary};
      }
    }
    .react-datepicker__navigation--next {
      border-left-color: ${(props) => props.theme.colors.secondary};
      &:hover {
        border-left-color: ${(props) => props.theme.colors.primary};
      }
    }
    .react-datepicker__current-month {
      font-family: 'Futura PT Book';
    }
    .react-datepicker__day {
      line-height: 2rem;
      height: 1.7rem;
      border-radius: 0;
    }

    .react-datepicker__day--today {
      background-color: ${(props) => props.theme.colors.light};
      color: ${(props) => readableColor(props.theme.colors.light)};
    }

    .react-datepicker__day {
      &:hover, &.react-datepicker__day--in-range, &.react-datepicker__day--in-selecting-range {
        background-color: ${(props) => lighten(0.3, getMainColor(props))};
        color: ${(props) => readableColor(lighten(0.3, getMainColor(props)))};
      }
      &:hover {
        opacity: .75;
      }
    }

    .react-datepicker__day--selected, .react-datepicker__day--range-start, .react-datepicker__day--range-end {
      background-color: ${(props) => props.theme.colors.primary} !important;
      color: ${(props) => readableColor(props.theme.colors.primary)} !important;
    }

    .react-datepicker__day--keyboard-selected {
      background-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => readableColor(props.theme.colors.primary)};
      border-color: ${(props) => props.theme.colors.primary};
        ${(props) => getFocusStyle(getMainColor(props))}
    }
  }
`;

DatepickerStyled.defaultProps = {
  theme: DEFAULT_THEME,
};
