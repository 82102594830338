import React from 'react';
import {Formik} from 'formik';
import {UserType} from '../userManagementPage';
import {ModalToggleProps} from '../../../global/messages/modal/modal.types';
import {ReviewUserTable} from '../components';
import {useMutation, useQueryClient} from 'react-query';
import {patchUserRole} from '../../../service/api';
import Modal from '../../../global/messages/modal/modal.components';
import {USER_MANAGEMENT_LIST} from '../../../service/queryKeys';
import * as Yup from "yup";
import {SelectOptionType} from "../../../service/types";
import Selector from "../../../form/select/selector";
import {ROLE_ASSISTANT_GT_COORDINATOR, ROLE_LABELS, ROLE_TEACHER, RolesType} from "../../../resources/roles";

const roleList = [ROLE_TEACHER, ROLE_ASSISTANT_GT_COORDINATOR].map((role: string) => ({
  label: ROLE_LABELS[role as RolesType],
  value: role
}));

const UpdateRoleModal = ({user, toggle}: ModalToggleProps & { user: UserType }) => {
  const queryClient = useQueryClient();

  const updateRoleMutation = useMutation(patchUserRole, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toggle(false);
    },
  });

  return (
    <Formik
      initialValues={{
        role: user?.role,
      }}
      validationSchema={Yup.object().shape({
        role: Yup.string().required(),
      })}
      validateOnMount
      onSubmit={({role}) => {
        updateRoleMutation.mutate({user_id: user.id, role})
      }}
    >
      {({isValid, handleSubmit, values, setFieldValue}) => (
        <Modal>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <p className={'sm-field-mb'}>
                You are about to change the role from this user account.
              </p>
              <div className={'sm-field-mb'}>
                <ReviewUserTable user={user}/>
              </div>
              <Selector
                id={'roles'}
                label={'Role'}
                placeholder={'Please select a role'}
                required
                value={roleList.find((role: SelectOptionType) => role.value === values.role)}
                onChange={(option: any) => setFieldValue('role', option.value)}
                options={roleList}
                isClearable={false}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)}/>
              <Modal.ConfirmButton
                type={'submit'}
                disabled={!isValid || updateRoleMutation.isLoading}
                label={updateRoleMutation.isLoading ? 'Loading...' : 'Confirm'}
              />
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default UpdateRoleModal;
