import connector from '../connector';

export function hubspotRegistration(data: {
    first_name: string;
    last_name: string;
    email: string;
    role_description: string;
    school: string;
    role: string;
}) {
  return connector.post('/hubspot/register', data);
}
