import React, { useRef, useState } from 'react';
import DashboardLayout from '../../layout/dashboard';
import { SectionType } from './instrument.types';
import InstrumentMonitor from './sections/instrumentMonitor';
import InstrumentForm, { ELEMENT_SURVEY_TYPE, INITIAL_INSTRUMENT_FORM } from './sections/instrumentForm';
import { useQuery } from 'react-query';
import { getActiveInstruments } from '../../service/api';
import ScrollSpy from '../../global/scrollSpy/scrollSpy';
import { ACTIVE_INSTRUMENTS } from '../../service/queryKeys';

export const BASELINE_TYPE = 'baseline';
export const ELEMENTS_TYPE = 'element';

export const STUDENTS_SURVEYS_TYPE = 'student-surveys';

const StudentSurveysPage = ({ isFree }: { isFree?: boolean }) => {
  const top = useRef<HTMLDivElement | null>(null);
  const elementParam = new URLSearchParams(location.search).get('e');

  const activeInstruments = useQuery(
    [ACTIVE_INSTRUMENTS, STUDENTS_SURVEYS_TYPE],
    () => getActiveInstruments(STUDENTS_SURVEYS_TYPE),
    {
      select: (data) => data.data.data,
      placeholderData: [],
      staleTime: Infinity,
    }
  );

  const [toggleForm, setToggleForm] = useState(true);

  return (
    <>
      <DashboardLayout title={'Student surveys'}>
        <div className={'flex'}>
          <div ref={top} className={'lg:w-5/6'}>
            {/* Form */}
            <InstrumentForm
              id={'form'}
              showToggleButton={0 !== activeInstruments.data.length}
              type={STUDENTS_SURVEYS_TYPE}
              activeInstruments={activeInstruments.data}
              initialState={{
                type: STUDENTS_SURVEYS_TYPE,
                ...INITIAL_INSTRUMENT_FORM,
                survey_type: elementParam ? ELEMENT_SURVEY_TYPE : '',
                element: elementParam,
              }}
              toggle={toggleForm}
              setToggle={setToggleForm}
              isFree={isFree}
            />
            {/* Monitor baseline instance */}
            <InstrumentMonitor id={'monitor'} type={STUDENTS_SURVEYS_TYPE} data={activeInstruments.data} />
          </div>
          <div className={'hidden lg:block lg:w-1/6 pl-10'}>
            <ScrollSpy
              options={
                [
                  { value: 'form', label: 'Form' },
                  { value: 'monitor', label: 'Monitor' },
                ] as { value: SectionType; label: string }[]
              }
            />
          </div>
        </div>
      </DashboardLayout>
    </>
  );
};

export default StudentSurveysPage;
