import React from 'react';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import * as Yup from 'yup';
import Selector from '../../../form/select/selector';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PLANS, SCHOOLS_GROUPS } from "../../../service/queryKeys";
import { getSchoolGroups, postOrPutPlans } from "../../../service/api";

type DevelopmentAdminStrategyModalProps = {
  plan: any;
  update?: boolean;
};

const STRATEGY_SCHEMA = Yup.object().shape({
  teams: Yup.array().min(0),
});

const TeamsModal = ({ toggle, plan, update }: ModalToggleProps & DevelopmentAdminStrategyModalProps) => {

  const queryClient = useQueryClient();

  const schoolGroupsQuery = useQuery(
    [SCHOOLS_GROUPS],
    () => getSchoolGroups(),
    {
      staleTime: Infinity,
      select: (data) => data.data.data.map((team: any) => {
        return {
          label: team.name,
          value: team.id,
        };
      }),
      placeholderData: [],
      keepPreviousData: true
    }
  );

  schoolGroupsQuery.data!.push({
    label: "All teachers",
    value: "Whole_school",
  })

  const mutation = useMutation(postOrPutPlans, {
    onSuccess: () => {
      queryClient.invalidateQueries(PLANS);
      toggle(false);
    },
  });

  return (
    <Modal>
      <Formik
        initialValues={{
          id: plan.id,
          teams: [],
          showToAll: false,
          ...plan
        }}
        validateOnMount={true}
        validationSchema={STRATEGY_SCHEMA}
        onSubmit={(values) => {
          mutation.mutateAsync(values).then(() => {
            toast.success('Assigned to teachers');
          })
        }}
      >
        {({ values, handleChange, handleSubmit, isValid, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Selector
                isMulti
                className={'mb-2'}
                required
                id={`teachers`}
                label={`Teachers`}
                placeholder={`Please select one or more teams or all teachers`}
                value=
                  {values.showToAll ? schoolGroupsQuery.data!.find((t: any) => t.value === 'Whole_school') : values.teams.map((id: string) =>
                    schoolGroupsQuery.data!.find((t: any) => t.value === id)
                  )}
                options={values.showToAll ? [] : schoolGroupsQuery.data}
                onChange={(options: any) => {
                  setFieldValue(
                    `teams`, !!options.some((option: any) => option.value === ('Whole_school')) ? [] :
                      options.map((option: any) => option.value)
                  );
                  setFieldValue('showToAll', !!options.some((option: any) => option.value === ('Whole_school')));
                }}
                isClearable={false}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)}/>
              <Modal.ConfirmButton
                type={'submit'}
                label={mutation.isLoading ? 'Loading...' : update ? 'Save' : 'Assign'}
                disabled={update ? false : values.showToAll ? false : values.teams.length === 0}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default TeamsModal;
