import React from 'react';
import DashboardLayout from '../../layout/dashboard';
import { Section } from '../common';
import GreatTeachingInsetPageCard from './components/card';
import {
  GreatTeachingInsetInterface,
  GTInsetResourcesInterface,
  inset_page_resources,
  additional_resources,
} from './resources/resources';
import GttReviewImage from '@app/asset/images/great_teaching_inset_page/GTT_Review_Image.png';
import GttReviewImageMobile from '@app/asset/images/great_teaching_inset_page/GTT_Review_Image_Mobile.png';
import { LinkStyled } from '../../global/link/link.styled';
import Icon from '../../global/icon/icon';
import { GLOBAL_ICONS } from '../../resources/vars';
import Tooltip from '../../global/tooltip/tooltip';
import { useMutation } from 'react-query';
import { postInsetTracking } from '../../service/api';

const GreatTeachingInsetPage = () => {
  const postInsetTrackingMutation = useMutation(postInsetTracking, {
    onSuccess: () => {/* Do nothing */},
  });

  return (
    <React.Fragment>
      <DashboardLayout title={'Great Teaching INSET'} showOnlyOnSmall={true}>
        <div className={'flex-1 block-with-sub-container'}>
          <div className={'relative text-center mb-4'}>
            <img className={'block md:hidden my-4'} src={GttReviewImageMobile} />
            <img className={'hidden md:block my-4'} src={GttReviewImage} />
            <div className={'hidden md:block absolute bottom-2 left-4 lg:bottom-5 lg:left-7'}>
              <p className={'text-md lg:text-2xl xl:text-3xl 2xl:text-4xl'}>
                <strong>Great Teaching</strong> INSET
              </p>
            </div>
          </div>
          <Section size={'md'} className={'my-5'}>
            <div className={'flex justify-around items-start flex-col xl:flex-row'}>
              <div className={'grid justify-items-center gap-3 xl:w-1/2 w-full my-2 ml-2 p-2'}>
                <div className={'ml-2 mr-4 pr-2'}>
                  <p>
                    This Developing Great Teaching session plan is designed to be used with any group of
                    teachers/teaching assistants and is intended to last around 2 hours, but you could extend this or
                    shorten it to fit your need.
                  </p>
                  <p className={'mt-4'}>
                    It starts with an overview of what the best available research evidence suggests are common
                    characteristics of highly effective teachers (things like curriculum knowledge, strong
                    relationships, managing time and resources, explaining, questioning, feedback) and then engages
                    individual colleagues and groups to reflect on their own practice, deepen their knowledge and
                    understanding in specific elements of teaching, and make plans for further follow-up and development
                    after the session is finished.
                  </p>
                </div>
              </div>
              <div
                className={
                  'grid justify-items-center w-full border-t-4 border-l-0 xl:grid-cols-1 xl:w-1/2 border-gray-100 xl:mt-0 xl:border-t-0 xl:border-l-4 m-2 p-2'
                }
              >
                <div className={'mt-2 ml-2'}>
                  <ul className={'list-decimal ml-8'}>
                    <li>Download the relevant PowerPoint presentation</li>
                    <li className={'my-4'}>
                      Read through the slides and accompanying notes to familiarise yourself with the four parts of the
                      session
                    </li>
                    <li>Download or copy links to the accompanying resources</li>
                  </ul>
                </div>
              </div>
            </div>
          </Section>
          <h4>Resources</h4>
          <div className={'mt-4 grid grid-cols-1 xl:grid-cols-2 gap-16 mb-8'}>
            {inset_page_resources.map((resource: GTInsetResourcesInterface) => (
              <div key={resource.id} className={'bg-white container-shadow section-container h-full'}>
                <div className={'flex mb-8'}>
                  <div className={'w-1/4 my-auto'}>
                    <h5>{resource.title}</h5>
                  </div>
                  <div className={'ml-auto'}>
                    <Tooltip content={'Download all resources'}>
                      <LinkStyled
                        href={resource.file}
                        download={resource.fileName}
                        target={'_blank'}
                        id={`download_all_${resource.id}`}
                        onClick={() => postInsetTrackingMutation.mutateAsync(resource.title)}
                      >
                        <div className="w-12 h-12 bg-transparent rounded-full border-2 flex justify-center items-center text-center">
                          <Icon elementSize={40} icon={GLOBAL_ICONS['download']} />
                        </div>
                      </LinkStyled>
                    </Tooltip>
                  </div>
                </div>
                <div>
                  {resource.resources.map((r: GreatTeachingInsetInterface) => (
                    <GreatTeachingInsetPageCard key={r.id} resource={r} />
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Section>
            <div className={'grid grid-cols-1 xl:grid-cols-2 gap-2 xl:gap-8 xl:m-4'}>
              {additional_resources.map((resource: GreatTeachingInsetInterface) => (
                <GreatTeachingInsetPageCard key={resource.id} resource={resource} />
              ))}
            </div>
          </Section>
        </div>
      </DashboardLayout>
    </React.Fragment>
  );
};

export default GreatTeachingInsetPage;
