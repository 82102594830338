// File obtained from gist https://gist.github.com/kidroca/19e5fe2de8e24aa92a41e94f2d41eda4

/**
 * Converts an svg string to a png image and returns a URI to it
 */
export async function svgToDataURI(svgString: any, renderWidth: any, renderHeight: any) {
    if (!svgString || !renderWidth || !renderHeight) {
        throw new Error('Missing arguments');
    }

    const canvas = document.createElement('canvas');

    canvas.width = renderWidth;
    canvas.height = renderHeight;
    const ctx = canvas.getContext('2d');

    const img = new global.Image();

    return new Promise((resolve, reject) => {
        img.onload = function load() {
            (ctx as any).drawImage(img, 0, 0);
            const url = canvas.toDataURL('image/png', 1.0);
            resolve(url);
        };

        img.onerror = reject;

        img.src = encodeSvgString(svgString);
    });
}

function encodeSvgString(svg: any) {
    const decoded = unescape(encodeURIComponent(svg));
    const b64String = global.btoa(decoded);
    const imgSource = `data:image/svg+xml;base64,${b64String}`;
    return imgSource;
}

export const chartToImage = async (svg: HTMLElement, width: number, height: number) => {
    const svgString = new global.XMLSerializer().serializeToString(svg as any);
    return await svgToDataURI(svgString, width, height);
};

export const svgToImage = async (file: string, width: number, height: number): Promise<any> => {
    const response = await fetch(file)
    const text = await response.text()
    return svgToDataURI(text, width, height);
};
