import React, { useState } from 'react';
import Button from '../../../global/button/button';
import AddNotes from '../components/addNotes';
import AddResources from '../components/addResources';
import StartRelatedCourse from '../components/startRelatedCourse';
import { EXPLORE_STEP } from '../views/PathwayV2View';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PATHWAYS_V2_STEP } from '../../../service/queryKeys';
import { getV2PathwaysStep } from '../../../service/api';
import dayjs from 'dayjs';
import AddReference from '../components/AddReference';
import { VIDEO_OBSERVATION_REFERENCE_TYPE } from '../modals/AddReferenceModal';

const PathwaysExploreTab = ({
  disabled,
  startDisabled,
  completed,
  started,
  team,
  saveStep,
}: {
  disabled: boolean;
  startDisabled: boolean;
  completed: boolean;
  started: boolean;
  team?: { id: string; name: string };
  saveStep: (data?: { completed_at?: string; challenge?: string; notes?: string }, callback?: () => void) => void;
}) => {
  const { id: pathwaysId } = useParams();

  const { data: pathwaysStep } = useQuery(
    [PATHWAYS_V2_STEP, EXPLORE_STEP, pathwaysId],
    () => getV2PathwaysStep(EXPLORE_STEP, pathwaysId!),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
    }
  );

  const [editMode, setEditMode] = useState(!completed);

  disabled = disabled || !editMode;

  return (
    <div>
      <div className={'flex flex-col divide-y'}>
        <div className={'font-bold flex pathways-section gap-2 justify-between'}>
          <span className={'w-5/6'}>
            In this step, you can explore the underlying principles of your chosen element and what this might look like
            in your context. You might choose to do this by completing a GTT course or exploring relevant articles and
            blog posts.
          </span>
          <Button
            id={`explore_${completed ? 'edit' : 'start'}_step`}
            size={'md'}
            onClick={() => {
              if (completed) {
                setEditMode(true);
                return;
              }

              saveStep();
            }}
            disabled={startDisabled || (started ? editMode : !editMode)}
          >
            {completed ? 'Edit' : 'Start'} step
          </Button>
        </div>
        <div className={'pathways-section'}>
          <StartRelatedCourse disabled={disabled} courses={pathwaysStep?.courses ?? []} />
        </div>
        <div className={'pathways-section'}>
          <AddReference
            disabled={disabled}
            step={EXPLORE_STEP}
            options={[VIDEO_OBSERVATION_REFERENCE_TYPE]}
            references={pathwaysStep?.references}
          />
        </div>
        <div className={'pathways-section'}>
          <AddNotes
            disabled={disabled}
            notes={pathwaysStep?.notes}
            save={(notes, callback) => {
              saveStep({ notes }, callback);
            }}
            description={
              'As you explore this element, record what you think are the key components. How do you think this relates to your practice or initial ideas for things you might try out?'
            }
          />
        </div>
        <div className={'pathways-section'}>
          <AddResources
            disabled={disabled}
            step={EXPLORE_STEP}
            resources={pathwaysStep?.resources}
            team={team}
            description={'Add relevant resources (e.g., articles, blog posts, podcasts, meeting notes).'}
          />
        </div>
      </div>
      <div className={'pathways-section w-full text-right'}>
        <Button
          id={'exploreCompleteButton'}
          size={'sm'}
          onClick={() =>
            saveStep({ completed_at: dayjs().format('YYYY/MM/DD') }, () => {
              setEditMode(false);
            })
          }
          disabled={disabled}
        >
          Complete
        </Button>
      </div>
    </div>
  );
};

export default PathwaysExploreTab;
