import React, { ButtonHTMLAttributes } from 'react';
import { ButtonProps } from './button.types';
import { ButtonStyled } from './button.styled';

type Ref = HTMLButtonElement;

const Button = React.forwardRef<Ref, ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>(
  (
    { mainColor = 'primary', isOutline = false, asLink = false, size = 'md', children, addOutline = false, ...other }: ButtonProps,
    ref
  ): JSX.Element => (
    <ButtonStyled
      addOutline={addOutline}
      ref={ref}
      mainColor={mainColor}
      size={size}
      isOutline={isOutline}
      asLink={asLink}
      {...(other as ButtonHTMLAttributes<any>)}
    >
      {children as React.ReactNode}
    </ButtonStyled>
  )
);

Button.displayName = 'Button';

export default Button;
