import React, { ButtonHTMLAttributes } from 'react';
import { ButtonProps } from '../button.types';
import Button from '../button';
import Icon from '../../icon/icon';
import { GLOBAL_ICONS, SIZE_ICON } from '../../../resources/vars';

type JoinButtonProps = {
  label: React.ReactNode;
};

const JoinButton = ({
  label,
  ...other
}: JoinButtonProps & Omit<ButtonProps, 'children'> & ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Button size={'sm'} className={'flex items-center'} {...other}>
    <Icon icon={GLOBAL_ICONS['join']} container={false} elementSize={SIZE_ICON['sm']} />
    <span className={'ml-3'}>{label}</span>
  </Button>
);

export default JoinButton;
