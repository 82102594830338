import React from 'react';
import Vimeo from '@u-wave/react-vimeo';

const VideoSection = ({ video }: { video: string }) => {
  return (
    <>
      <Vimeo video={video} responsive width={'100%'} />
    </>
  );
};

export default VideoSection;
