import styled, { DefaultTheme, ThemeProps } from 'styled-components';
import { DEFAULT_THEME } from '../../Theme';
import { AllColorsType } from '../../resources/types';
import { DEFAULT_TRANSITION_FUNC } from '../transitions/transitions.types';

const SIZE = '100%';
const WIDTH = 2.5;

export const ProgressContainer = styled('div')<{ $isNull: boolean }>`
  position: relative;
  display: flex;
  margin: auto;
  max-width: 250px;
  width: ${SIZE};
  height: ${SIZE};
  ${(props) =>
    props.$isNull &&
    `
    opacity: 0.25;
    user-select: none;
  `}
`;

export const SvgContainer = styled('svg')`
  transform: rotate(180deg);
  display: block;
  margin: 10px auto;
  max-width: 95%;
  max-height: ${SIZE};
`;

export const PathBackground = styled('path')<ThemeProps<DefaultTheme>>`
  fill: none;
  stroke: ${(props) => props.theme.colors.light};
  stroke-width: ${WIDTH + 1};
  stroke-linecap: round;
`;

PathBackground.defaultProps = {
  theme: DEFAULT_THEME,
};

export const PathElement = styled('path')<{ $color: AllColorsType } & ThemeProps<DefaultTheme>>`
  stroke: ${(props) => props.theme.colors[props.$color]};
  fill: none;
  stroke-width: ${WIDTH};
  stroke-linecap: round;
  animation: circle_progress 1s ${DEFAULT_TRANSITION_FUNC} forwards;

  @keyframes circle_progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
`;

PathElement.defaultProps = {
  theme: DEFAULT_THEME,
};

export const ProgressNumber = styled('div')`
  display: flex;
  position: absolute;
  z-index: 1;
  left: 50%;
  bottom: 0;
  right: 0;
  height: 50%;
  width: 50%;
  padding: 25px;
  font-size: 2.25rem;
`;

export const ProgressLabel = styled('div')`
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  top: -15px;
  bottom: 0;
  margin: auto;
  border-radius: 100%;
  width: 50%;
  height: 50%;
  display: flex;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  color: ${(props) => props.theme.colors.muted};
`;

ProgressLabel.defaultProps = {
  theme: DEFAULT_THEME,
};
