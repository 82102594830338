import React from 'react';
import { ThemeProvider, ThemeProps, DefaultTheme } from 'styled-components';
import { theme_colors, system_colors, font_family, border_radius } from '@app/asset/theme/index';

export const DEFAULT_THEME = {
  colors: {
    ...theme_colors,
    ...system_colors,
  },
  fontFamily: {
    ...font_family,
  },
  borderRadius: {
    ...border_radius,
  },
};

export interface ThemingProps extends Partial<ThemeProps<DefaultTheme>> {
  /**
   * DefaultTheme is being used as an interface of props.theme out of the box.
   * By default the interface DefaultTheme is empty so that's why we need to extend it.
   * https://styled-components.com/docs/api#themeprovider
   */
  theme?: DefaultTheme;
  children: any;
}

const Theme: React.FC<ThemingProps> = ({ theme = DEFAULT_THEME, children, ...other }: ThemingProps) => {
  return (
    <ThemeProvider theme={theme} {...other}>
      {children as any}
    </ThemeProvider>
  );
};

Theme.displayName = 'Theme';

export default Theme;
