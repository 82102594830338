import React, { useState } from 'react';
import Empty from '../../../global/empty/empty';
import { Section } from '../../common';
import { useQuery } from 'react-query';
import { PLANS } from '../../../service/queryKeys';
import { getDevelopmentPlans } from '../../../service/api';
import Select from 'react-select';
import StepTab from '../../../global/stepTab/stepTab';

const PlanTeacherDashboard = ({ isCoordinator }: { isCoordinator: boolean }) => {
  const [selectPlan, setSelectPlan] = useState<{ label: string; value: any } | null>(null);
  const [steps, setSteps] = useState<Array<any>>([]);

  const { data } = useQuery(PLANS, () => getDevelopmentPlans(), {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    keepPreviousData: true,
  });

  const options = data?.map((plan: any) => {
    return { label: plan?.name, value: plan };
  });

  if (data?.length > 0) {
    return (
      <div className={'mx-auto pt-12 pb-8'}>
        <Section
          className={'field-mb'}
          id={'plans'}
          headline={
            <div className={'flex inline-flex gap-1'}>
              {isCoordinator
                ? 'Implementation plans shared with colleagues'
                : 'Guidance from your Great Teaching Coordinator'}
            </div>
          }
        >
          <>
            <div className={'flex mb-5'}>
              <Select
                className={'w-52 mr-3'}
                options={options}
                value={selectPlan}
                onChange={(plan: any) => {
                  const steps = [];
                  for (const term of plan.value.developmentPeriods) {
                    for (const step of term.steps) {
                      steps.push(step);
                    }
                  }

                  setSteps(steps);
                  setSelectPlan(plan);
                }}
              />
            </div>
            {selectPlan && steps.length > 0 ? (
              <div className={`grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-2 `}>
                {steps?.map((step: any, index: number) => {
                  return (
                    <React.Fragment key={`child${index}`}>
                      <StepTab step={step} index={index + 1} />
                    </React.Fragment>
                  );
                })}
              </div>
            ) : selectPlan ? (
              <>
                <Empty who={'your school does not'} element={'steps for this plan'} />
              </>
            ) : (
              <></>
            )}
          </>
        </Section>
      </div>
    );
  } else {
    return <></>;
  }
};

export default PlanTeacherDashboard;
