import {HTMLAttributes} from "react";
import styled, {css, ThemeProps} from 'styled-components';
import {AlertProps} from "./alert.types";
import {transparentize} from "polished";

const getColors = (props: AlertProps & ThemeProps<any>) => {
  const COMPONENT_COLOR = props.theme.colors[(props.type as string)];
  return css`
    background: ${transparentize(0.95, COMPONENT_COLOR)};
    border-color: ${COMPONENT_COLOR};
    [data-id='title'] {
      color: ${COMPONENT_COLOR};
    }
    [data-id='icon'] {
      color: white;
      background: ${COMPONENT_COLOR};
      border-radius: 15px;
      box-shadow: 0 10px 15px -3px ${transparentize(0.75, COMPONENT_COLOR)}, 0 4px 6px -2px ${transparentize(0.75, COMPONENT_COLOR)};
    }
    [data-id='content'] {
      margin-left: 60px;
    }
  `;
}

export const AlertStyled = styled.div.attrs((props: HTMLAttributes<HTMLDivElement>) => ({
  role: 'alert',
  ...props
}))<AlertProps & ThemeProps<any>>`
  padding: 15px;
  border-radius: calc(${(props) => props.theme.borderRadius.app} * 2);
  border: 1px solid transparent;
  ${(props) => getColors(props)}
`;
