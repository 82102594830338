import React from 'react';
import Typeform from "../../typeform/typeform";

const SurveyPage = () => {
  const formId = new URLSearchParams(location.search).get('formId') as string;
  const className = new URLSearchParams(location.search).get('className');
  const lastName = new URLSearchParams(location.search).get('lastName');
  const teacherId = new URLSearchParams(location.search).get('teacherId');
  const toc = new URLSearchParams(location.search).get('toc');
  const id = new URLSearchParams(location.search).get('id');
  const name = new URLSearchParams(location.search).get('name');
  const school = new URLSearchParams(location.search).get('school');

  return <Typeform
    formId={formId}
    className={className}
    lastName={lastName}
    teacherId={teacherId}
    toc={toc}
    id={id}
    name={name}
    school={school}
  />;
};

export default SurveyPage;
