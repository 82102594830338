export const THINKIFIC_LINKS = {
  courses: 'https://evidence-based-education.thinkific.com/enrollments',
  coursesFree: 'https://evidence-based-education.thinkific.com/bundles/the-great-teaching-toolkit-sample-courses',
  foundation:
    'https://evidence-based-education.thinkific.com/courses/take/Foundation/lessons/26663010-welcome-to-the-great-teaching-toolkit',
  foundation_free:
    'https://evidence-based-education.thinkific.com/courses/starter-foundation-an-evidence-based-approach-to-great-teaching',
  foundation_free_first_lesson:
    'https://evidence-based-education.thinkific.com/courses/take/starter-foundation-an-evidence-based-approach-to-great-teaching/lessons/28533710-welcome-to-the-great-teaching-toolkit',
  selfStudyEct: 'https://evidence-based-education.thinkific.com/bundles/123855',
  selfStudyEctCourse1:
    'https://evidence-based-education.thinkific.com/courses/take/early-career-teacher-programme-introduction/texts/47988963-introduction',
  selfStudyEctYear2:
    'https://evidence-based-education.thinkific.com/courses/take/year-2-early-career-teacher-programme-introduction/texts/58191192-introduction',
  selfStudyEctCourse2024:
    'https://evidence-based-education.thinkific.com/courses/take/early-career-teacher-programme-introduction-sep24/texts/57626399-introduction',
  selfStudyMentor: 'https://evidence-based-education.thinkific.com/bundles/124787',
  selfStudyMentorCourse1:
    'https://evidence-based-education.thinkific.com/courses/take/ecf-programme-introduction-mentors/texts/47991735-introduction',
  selfStudyMentorCourse2024:
    'https://evidence-based-education.thinkific.com/courses/take/ecf-mentor-programme-introduction-Sept-24/texts/57627146-introduction',
  onboardingCatchup:
    'https://evidence-based-education.thinkific.com/courses/take/ecf-mentor-sessions/lessons/50039123-1-onboarding-session-catch-up',
  mentorsOnboarding:
    'https://evidence-based-education.thinkific.com/courses/take/ecf-programme-introduction-mentors/texts/47991967-onboarding',
  stcpIntroduction:
    'https://evidence-based-education.thinkific.com/courses/take/school-trust-ceo-programme-induction/texts/52048030-welcome',
  stcpIntroductionV2:
    'https://evidence-based-education.thinkific.com/courses/take/school-trust-ceo-programme-stcp-induction-2024/texts/57334182-induction-overview',
  '': '',
};

export const LINKS: Record<LINK_PARAMS, string> = {
  knowledgeBase:
    'https://support.evidencebased.education/en/knowledge/how-should-i-implement-the-gtt-in-my-school-or-college',
  deliveryOptions:
    'https://support.evidencebased.education/en/knowledge/how-should-we-deliver-the-foundation-course-in-our-school-or-college',
  subscribeToGTTLink: 'https://evidencebased.education/great-teaching-toolkit-leaders',
};

type LINK_PARAMS = 'knowledgeBase' | 'deliveryOptions' | 'subscribeToGTTLink';
