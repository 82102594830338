import React from 'react';

const MetricBox = ({ content, title }: { content: any; title: string }) => {
  return (
    <>
      <div className={'w-5/6 border-4 rounded-md mx-auto p-4 flex flex-col'} /*style={{ minHeight: '80px' }}*/>
        <div className={'text-xl font-bold my-2 ml-8'}>{title}</div>
        <div className={'flex-1 w-full h-full'}>{content}</div>
      </div>
    </>
  );
};
export default MetricBox;
