import React, {ButtonHTMLAttributes} from "react";
import {ButtonProps} from "../button.types";
import Button from "../button";
import Icon from "../../icon/icon";
import {GLOBAL_ICONS, SIZE_ICON} from "../../../resources/vars";

type AddButtonProps = {
  label: React.ReactNode;
}

const AddButton = ({label, ...other}: AddButtonProps & Omit<ButtonProps, 'children'> & ButtonHTMLAttributes<HTMLButtonElement>) => (
  <Button size={'sm'} className={'flex items-center'} {...other}>
    <Icon icon={GLOBAL_ICONS['create']} container={false} elementSize={SIZE_ICON['sm']} />
    <span className={'ml-3'}>{label}</span>
  </Button>
)

export default AddButton;
