import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import routeBuilder from '../../../service/routeBuilder';
import V3InfoSection from '../sections/v3/V3InfoSection';
import { getV3Pathway } from '../../../service/api';
import { PATHWAYS_V3 } from '../../../service/queryKeys';
import V3GoalsSection from '../sections/v3/V3GoalsSection';
import V3StrategiesSection from '../sections/v3/V3StrategiesSection';
import V3ResourcesSection from '../sections/v3/V3ResourcesSection';
import V3ActionsSection from '../sections/v3/V3ActionsSection';
import V3TimelineSection from '../sections/v3/V3TimelineSection';
import Icon from '../../../global/icon/icon';
import Tooltip from '../../../global/tooltip/tooltip';
import VideoAlert from '../../../guides/components/videoAlert';
import { GLOBAL_ICONS } from '../../../resources/vars';

const pathwaysV3WorkspaceView = ({ isFree }: { isFree?: boolean }) => {
  const { id: pathwaysId } = useParams();
  const navigate = useNavigate();

  const v3Pathway = useQuery([PATHWAYS_V3, pathwaysId], () => getV3Pathway(pathwaysId!), {
    select: (data) => data.data.data,
    staleTime: Infinity,
    enabled: !!pathwaysId,
    keepPreviousData: false,
  });

  const [showGuides, setShowGuides] = useState<boolean>(false);
  const [showGoals, setShowGoals] = useState<boolean>(true);
  const [showImprovements, setShowImprovements] = useState<boolean>(true);
  const [showActions, setShowActions] = useState<boolean>(true);
  const [showResources, setShowResources] = useState<boolean>(true);
  const [showTimeline, setShowTimeline] = useState<boolean>(true);

  useEffect(() => {
    if (!showGoals && !showImprovements && !showActions && !showResources && !showTimeline) setShowGuides(false);
  }, [showGoals, showImprovements, showActions, showResources, showTimeline]);

  if (v3Pathway.isLoading) {
    return null;
  }

  if (!v3Pathway.isSuccess) {
    navigate(routeBuilder('pathways').generate());
    return null;
  }

  const isDisabled = v3Pathway.data.completed;

  return (
    <>
      <div className="xl:w-2/3  flex lg:pr-10 xl:pr-8 2xl:pr-10">
        <div className={'w-4/5  block-with-sub-container mb-5'}>
          <V3InfoSection pathway={v3Pathway.data} />
        </div>
      </div>
      <Tooltip content={`${showGuides ? 'Hide ' : 'Show '} guides`} placement={'right'}>
        <Icon
          color={showGuides ? 'primary' : 'muted'}
          icon={GLOBAL_ICONS.info}
          className={`${
            showGuides ? '' : ''
          } hover:text-yellow-600 transform transition duration-100 hover:scale-125 cursor-pointer`}
          elementSize={60}
          onClick={() => {
            setShowGuides(!showGuides);
            setShowGoals(true);
            setShowImprovements(true);
            setShowActions(true);
            setShowResources(true);
            setShowTimeline(true);
          }}
        />
      </Tooltip>
      <div className={'block-with-sub-container grid grid-cols-2 gap-10 mb-5'}>
        <div>
          {showGuides && showGoals && (
            <VideoAlert
              classes={'w-full xl:h-52 2xl:h-48'}
              setVideo={setShowGoals}
              data={{
                title: 'Goals',
                id: 'workspace_goals',
                description: (
                  <>
                    <p>
                      We know from the best available evidence that setting goals is part of effective professional
                      development. Use the &quot;+&quot; button to add your specific teaching goal, e.g. &quot;Assess
                      prior knowledge before introducing new information&quot;.
                    </p>
                  </>
                ),
              }}
            />
          )}
          <V3GoalsSection pathway={v3Pathway.data} isDisabled={isFree || isDisabled} isFree={isFree} />
        </div>
        <div>
          {showGuides && showImprovements && (
            <VideoAlert
              classes={'w-full xl:h-52 2xl:h-48'}
              setVideo={setShowImprovements}
              data={{
                title: 'Strategies',
                id: 'workspace_improvements',
                description: (
                  <>
                    <p>
                      Use the &quot;+&quot; to add the details of the strategy you have selected to help you achieve
                      your goal.{' '}
                    </p>
                  </>
                ),
              }}
            />
          )}
          <V3StrategiesSection pathway={v3Pathway.data} isDisabled={isFree || isDisabled} isFree={isFree} />
        </div>
      </div>
      <div className={'block-with-sub-container grid grid-cols-2 gap-10 mb-5'}>
        <div>
          {showGuides && showActions && (
            <VideoAlert
              classes={'w-full xl:h-52 2xl:h-48'}
              setVideo={setShowActions}
              data={{
                title: 'Actions',
                id: 'workspace_actions',
                description: (
                  <>
                    <p>
                      Break down strategies into actionable steps for the week. If you are part of a GT Team working
                      through this development cycle, any actions you are assigned in team meetings will appear here.{' '}
                    </p>
                  </>
                ),
              }}
            />
          )}
          <V3ActionsSection pathway={v3Pathway.data} isDisabled={isFree || isDisabled} isFree={isFree} />
        </div>
        <div>
          {showGuides && showResources && (
            <VideoAlert
              classes={'w-full xl:h-52 2xl:h-48'}
              setVideo={setShowResources}
              data={{
                title: 'Resources',
                id: 'workspace_resources',
                description: (
                  <>
                    <p>
                      Use this section to store relevant resources so you can easily access them later. Use the
                      &quot;+&quot; button to add either web links or upload files.{' '}
                    </p>
                  </>
                ),
              }}
            />
          )}
          <V3ResourcesSection pathway={v3Pathway.data} isDisabled={isFree || isDisabled} isFree={isFree} />
        </div>
      </div>
      <div className={'block-with-sub-container'}>
        <div>
          {showGuides && showTimeline && (
            <VideoAlert
              classes={'w-full xl:h-52 2xl:h-48'}
              setVideo={setShowTimeline}
              data={{
                title: 'Timeline',
                id: 'workspace_timeline',
                description: (
                  <>
                    <p>
                      Your timeline is a record of the actions you&apos;ve taken during this development cycle. Actions
                      will automatically be recorded here but you can also use the &quot;+&quot; button to add your own
                      activities.{' '}
                    </p>
                  </>
                ),
              }}
            />
          )}
          <V3TimelineSection pathway={v3Pathway.data} isDisabled={isFree || isDisabled} isFree={isFree} />
        </div>
      </div>
    </>
  );
};

export default pathwaysV3WorkspaceView;
