import { ModalProps } from '../../../../global/messages/modal/modal.types';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import Modal from '../../../../global/messages/modal/modal';
import ModalComponents from '../../../../global/messages/modal/modal.components';
import React, { useState } from 'react';
import dayjs from 'dayjs';
import Input from '../../../../form/input/input';
import { useMutation, useQueryClient } from 'react-query';
import {
  postMeetingsUpdateIsArchived,
  PostMeetingsUpdateIsArchivedData,
  postOrPutGroup,
  PostOrPutGroupData,
} from '../../../../service/api/groupsApi';
import { FUTURE_MEETINGS, MY_GROUPS, TODAY_MEETINGS } from '../../../../service/queryKeys';
import Datepicker from '../../../../form/datepicker/datepicker';
import ButtonIcon from '../../../../global/buttonIcon/buttonIcon';
import Choice from '../../../../form/choice/choice';
import { ActionButton } from '../../../../global/buttonIcon/common';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ArchiveAllModal from './ArchiveAll.modal';
import AddButton from '../../../pathway/components/addButton';

const EMPTY_MEETING = {
  id: null,
  title: '',
  date: null,
  time: null,
  location: '',
  isArchived: false,
};

const VALIDATION_SCHEMA = Yup.object().shape({
  meetings: Yup.array().of(
    Yup.object().shape({
      title: Yup.string().nullable().required('This field is required.'),
      date: Yup.string().nullable().required('This field is required.'),
      time: Yup.string().nullable().required('This field is required.'),
      location: Yup.string().nullable(),
    })
  ),
});

export default function MeetingsFormModal({ toggle, groupMeetingsToEdit }: Props) {
  const queryClient = useQueryClient();

  const [showArchivedMeetings, setShowArchivedMeetings] = useState(false);
  const [isArchiveAllModalOpen, setIsArchiveAllModalOpen] = useState(false);

  const {
    control,
    formState: { errors, isSubmitting },
    register,
    handleSubmit,
    setValue,
    watch,
  } = useForm<MeetingsFormData>({
    values: groupMeetingsToEdit,
    resolver: yupResolver(VALIDATION_SCHEMA),
  });

  const meetingsField = useFieldArray({
    keyName: 'uniqueId',
    control: control,
    name: 'meetings',
  });

  const activeMeetings = watch('meetings').filter((meeting) => !meeting.isArchived);
  const archivedMeetings = watch('meetings').filter((meeting) => meeting.isArchived);

  const postOrPutGroupMutation = useMutation(({ data }: { data: PostOrPutGroupData }) => postOrPutGroup(data), {
    onSuccess: () => {
      queryClient.invalidateQueries([MY_GROUPS]);
      queryClient.invalidateQueries([TODAY_MEETINGS]);
      queryClient.invalidateQueries([FUTURE_MEETINGS]);

      toggle();
    },
  });

  const postOrPutMeetingMutation = useMutation(
    ({ data }: { data: PostMeetingsUpdateIsArchivedData }) => postMeetingsUpdateIsArchived(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([MY_GROUPS]);
        queryClient.invalidateQueries([TODAY_MEETINGS]);
        queryClient.invalidateQueries([FUTURE_MEETINGS]);
      },
    }
  );

  return (
    <>
      {isArchiveAllModalOpen && (
        <ArchiveAllModal
          onConfirm={() => {
            postOrPutMeetingMutation.mutate(
              {
                data: {
                  ids: activeMeetings.filter((meeting) => null !== meeting.id).map((meeting) => meeting.id!),
                  isArchived: true,
                },
              },
              {
                onSuccess: () => {
                  setIsArchiveAllModalOpen(false);

                  toggle();
                },
              }
            );
          }}
          isLoading={postOrPutMeetingMutation.isLoading}
          toggle={() => setIsArchiveAllModalOpen(false)}
        />
      )}

      <Modal
        preventCloseOnClickOnMask
        open
        toggle={() => toggle()}
        title={0 === groupMeetingsToEdit.meetings.length ? 'Add team meetings' : 'Edit team meetings'}
        size={'lg'}
      >
        <form
          onSubmit={handleSubmit((values) =>
            postOrPutGroupMutation.mutate({
              data: {
                id: values.id,
                meetings: values.meetings.map((meeting) => ({
                  id: meeting.id,
                  title: meeting.title,
                  scheduledAt: `${meeting.date} ${meeting.time}`,
                  location: meeting.location,
                })),
              },
            })
          )}
          noValidate
        >
          <ModalComponents.Body>
            <div className={'flex justify-end mb-2'}>
              <Choice
                id={'archived-meetings'}
                onChange={() => setShowArchivedMeetings((prev) => !prev)}
                checked={showArchivedMeetings}
                label={'View archived meetings'}
                type={'switch'}
              />
            </div>
            <div className="flex justify-between mb-5">
              <AddButton label={'meeting'} onClick={() => meetingsField.prepend(EMPTY_MEETING)} create hideLabel />
              <ButtonIcon
                icon={'Archive'}
                mainColor={'light'}
                label={'Archive all'}
                onClick={() => setIsArchiveAllModalOpen(true)}
              />
            </div>
            <div className={'text-center'}>
              {!showArchivedMeetings && 0 === activeMeetings.length && "This team doesn't have active meetings"}
              {showArchivedMeetings && 0 === archivedMeetings.length && "This team doesn't have archived meetings"}
            </div>
            {meetingsField.fields.map((meetingField, index) => {
              const isArchived = watch(`meetings.${index}.isArchived` as const);

              if ((showArchivedMeetings && !isArchived) || (!showArchivedMeetings && isArchived)) {
                return null;
              }

              return (
                <div key={meetingField.uniqueId} className={'flex'}>
                  <div className={'border-2 p-2'}>
                    <Controller
                      name={`meetings.${index}.date` as const}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Datepicker
                          required
                          id={`meetings.${index}.date`}
                          minDate={new Date()}
                          label={'Date'}
                          hideLabel
                          placeholder={'Date'}
                          onChange={(date: Date) => onChange(null !== date ? dayjs(date).format('YYYY-MM-DD') : null)}
                          selected={null !== value ? dayjs(`${value} 00:00`).toDate() : null}
                          error={errors.meetings?.[index]?.date?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={'border-2 p-2'}>
                    <Input
                      {...register(`meetings.${index}.title` as const)}
                      required
                      id={`meetings.${index}.title`}
                      label={'Name'}
                      hideLabel
                      placeholder={'Title'}
                      error={errors.meetings?.[index]?.title?.message}
                      maxLength={100}
                      hideCharacterCount
                    />
                  </div>
                  <div className={'border-2 p-2'}>
                    <Controller
                      name={`meetings.${index}.time` as const}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Datepicker
                          required
                          id={`meetings.${index}.time`}
                          label={'Time'}
                          hideLabel
                          placeholder={'Time'}
                          onChange={(date: Date) => onChange(null !== date ? dayjs(date).format('HH:mm') : null)}
                          selected={null !== value ? dayjs(`2000-01-01 ${value}`).toDate() : null}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15}
                          dateFormat="HH:mm"
                          error={errors.meetings?.[index]?.time?.message}
                        />
                      )}
                    />
                  </div>
                  <div className={'border-2 p-2'}>
                    <Input
                      {...register(`meetings.${index}.location` as const)}
                      id={`meetings.${index}.location`}
                      label={'Location'}
                      hideLabel
                      placeholder={'Location'}
                      error={errors.meetings?.[index]?.location?.message}
                      maxLength={100}
                      hideCharacterCount
                    />
                  </div>
                  <div className={'border-2 p-2'}>
                    {null !== meetingField.id ? (
                      <Controller
                        name={`meetings.${index as 0}.isArchived` as const}
                        control={control}
                        render={({ field: { value } }) => (
                          <ActionButton.Change
                            archived={value}
                            state={!value}
                            states={{
                              on: { label: 'Enable', icon: 'enable' },
                              off: { label: 'Disable', icon: 'disable' },
                            }}
                            onClick={() =>
                              postOrPutMeetingMutation
                                .mutateAsync({
                                  data: { ids: [meetingField.id!], isArchived: !value },
                                })
                                .then(() => setValue(`meetings.${index as 0}.isArchived` as const, !value))
                            }
                          />
                        )}
                      />
                    ) : (
                      <ActionButton.Remove onClick={() => meetingsField.remove(index)} />
                    )}
                  </div>
                </div>
              );
            })}
          </ModalComponents.Body>
          <ModalComponents.Footer>
            <ModalComponents.CancelButton onClick={() => toggle(false)} />
            <ModalComponents.ConfirmButton
              label={isSubmitting || postOrPutGroupMutation.isLoading ? 'Loading...' : 'Save'}
              type={'submit'}
              disabled={isSubmitting || postOrPutGroupMutation.isLoading}
            />
          </ModalComponents.Footer>
        </form>
      </Modal>
    </>
  );
}

interface Props extends Pick<ModalProps, 'toggle'> {
  groupMeetingsToEdit: MeetingsFormData;
}

export interface MeetingsFormData {
  id: string;
  meetings: {
    id: string | null;
    title: string;
    date: string | null;
    time: string | null;
    location: string;
    isArchived: boolean;
  }[];
}
