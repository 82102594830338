import Primary_Presentation from '../../../resources/pptx_files/Great_Teaching_Inset_Primary.pptx';
import Secondary_Presentation from '../../../resources/pptx_files/Great_Teaching_Inset_Secondary.pptx';
import PowerPointLogo from '@app/asset/images/great_teaching_inset_page/powerpoint_logo.png';
import { SystemIconType } from '../../../resources/types';
import EvidenceReviewCover from '@app/asset/images/great_teaching_inset_page/Evidence_Review_Cover.png';
import ModelForGTCover from '@app/asset/images/great_teaching_inset_page/Model_for_Great_Teaching_Cover.png';
import Great_Teaching_INSET_Primary from '../../../resources/zips/Great_Teaching_INSET_Primary.zip';
import Great_Teaching_INSET_Secondary from '../../../resources/zips/Great_Teaching_INSET_Secondary.zip';

export interface GreatTeachingInsetPageInterface {
  resource?: GreatTeachingInsetInterface;
}

export interface GreatTeachingInsetInterface {
  id: string;
  title: string;
  description: string;
  link?: string;
  fileName?: string;
  logo?: string;
  icon?: SystemIconType;
  primary?: boolean;
  additional?: boolean;
  html?: string;
}

export interface GTInsetResourcesInterface {
  id: string;
  title: string;
  file: string;
  fileName: string;
  resources: Array<GreatTeachingInsetInterface>;
}

export const inset_page_resources: Array<GTInsetResourcesInterface> = [
  {
    id: 'primary_resources',
    title: 'Primary',
    file: Great_Teaching_INSET_Primary,
    fileName: 'Great Teaching INSET (Primary).zip',
    resources: [
      {
        id: 'primary_resource_1',
        title: 'Great Teaching INSET session',
        description: 'PRESENTATION',
        link: Primary_Presentation,
        fileName: 'Great Teaching INSET PRIMARY.pptx',
        logo: PowerPointLogo,
        primary: true,
      },
      {
        id: 'primary_resource_2',
        title: 'GTT Element questions (Primary)',
        description: 'REFLECTION PROMPTS',
        icon: 'checklist',
        link:
          'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/GTT_ELEMENT%20QUESTIONS_Primary.pdf',
        html: `<!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper" id="hs-cta-wrapper-2aee3397-1075-408a-8fb5-e5da0da8a9f6"><span class="hs-cta-node hs-cta-2aee3397-1075-408a-8fb5-e5da0da8a9f6" id="hs-cta-2aee3397-1075-408a-8fb5-e5da0da8a9f6"><!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]--><a href="https://cta-redirect.hubspot.com/cta/redirect/2366135/2aee3397-1075-408a-8fb5-e5da0da8a9f6" target="_blank" rel="noopener"><img class="hs-cta-img" id="hs-cta-img-2aee3397-1075-408a-8fb5-e5da0da8a9f6" style="border-width:0px;" height="20" width="20" src="https://no-cache.hubspot.com/cta/default/2366135/2aee3397-1075-408a-8fb5-e5da0da8a9f6.png"  alt="New call-to-action"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(2366135, '2aee3397-1075-408a-8fb5-e5da0da8a9f6', {"useNewLoader":"true","region":"na1"}); </script></span><!-- end HubSpot Call-to-Action Code -->`,
      },
      {
        id: 'primary_resource_3',
        title: 'Great Teaching INSET overview',
        description: 'GUIDANCE',
        link:
          'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/GTT_INSET%20Guidance.pdf',
        icon: 'infoNoFill',
        html: `<!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper" id="hs-cta-wrapper-c69557ef-4c46-4a5a-a8d1-21a181ff11e5"><span class="hs-cta-node hs-cta-c69557ef-4c46-4a5a-a8d1-21a181ff11e5" id="hs-cta-c69557ef-4c46-4a5a-a8d1-21a181ff11e5"><!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]--><a href="https://cta-redirect.hubspot.com/cta/redirect/2366135/c69557ef-4c46-4a5a-a8d1-21a181ff11e5" target="_blank" rel="noopener"><img class="hs-cta-img" id="hs-cta-img-c69557ef-4c46-4a5a-a8d1-21a181ff11e5" style="border-width:0px;" height="20" width="20" src="https://no-cache.hubspot.com/cta/default/2366135/c69557ef-4c46-4a5a-a8d1-21a181ff11e5.png"  alt="New call-to-action"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(2366135, 'c69557ef-4c46-4a5a-a8d1-21a181ff11e5', {"useNewLoader":"true","region":"na1"}); </script></span><!-- end HubSpot Call-to-Action Code -->`,
      },
    ],
  },
  {
    id: 'secondary_resources',
    title: 'Secondary',
    file: Great_Teaching_INSET_Secondary,
    fileName: 'Great Teaching INSET (Secondary).zip',
    resources: [
      {
        id: 'secondary_resource_1',
        title: 'Great Teaching INSET session',
        description: 'PRESENTATION',
        link: Secondary_Presentation,
        fileName: 'Great Teaching INSET SECONDARY.pptx',
        logo: PowerPointLogo,
        primary: true,
      },
      {
        id: 'secondary_resource_2',
        title: 'GTT Element questions (Secondary)',
        description: 'REFLECTION PROMPTS',
        link:
          'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/GTT_ELEMENT%20QUESTIONS_Secondary.pdf',
        icon: 'checklist',
        html: `<!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper" id="hs-cta-wrapper-2c6e4579-d90c-48c0-b5f7-c7c2b37fcbc9"><span class="hs-cta-node hs-cta-2c6e4579-d90c-48c0-b5f7-c7c2b37fcbc9" id="hs-cta-2c6e4579-d90c-48c0-b5f7-c7c2b37fcbc9"><!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]--><a href="https://cta-redirect.hubspot.com/cta/redirect/2366135/2c6e4579-d90c-48c0-b5f7-c7c2b37fcbc9" target="_blank" rel="noopener"><img class="hs-cta-img" id="hs-cta-img-2c6e4579-d90c-48c0-b5f7-c7c2b37fcbc9" style="border-width:0px;" height="20" width="20" src="https://no-cache.hubspot.com/cta/default/2366135/2c6e4579-d90c-48c0-b5f7-c7c2b37fcbc9.png"  alt="New call-to-action"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(2366135, '2c6e4579-d90c-48c0-b5f7-c7c2b37fcbc9', {"useNewLoader":"true","region":"na1"}); </script></span><!-- end HubSpot Call-to-Action Code -->`,
      },
      {
        id: 'primary_resource_3',
        title: 'Great Teaching INSET overview',
        description: 'GUIDANCE',
        link:
          'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/GTT_INSET%20Guidance.pdf',
        icon: 'infoNoFill',
        html: `<!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper" id="hs-cta-wrapper-107dcfd2-de92-4800-899f-3469f2cb1fd2"><span class="hs-cta-node hs-cta-107dcfd2-de92-4800-899f-3469f2cb1fd2" id="hs-cta-107dcfd2-de92-4800-899f-3469f2cb1fd2"><!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]--><a href="https://cta-redirect.hubspot.com/cta/redirect/2366135/107dcfd2-de92-4800-899f-3469f2cb1fd2" target="_blank" rel="noopener"><img class="hs-cta-img" id="hs-cta-img-107dcfd2-de92-4800-899f-3469f2cb1fd2" style="border-width:0px;" height="20" width="20" src="https://no-cache.hubspot.com/cta/default/2366135/107dcfd2-de92-4800-899f-3469f2cb1fd2.png"  alt="New call-to-action"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(2366135, '107dcfd2-de92-4800-899f-3469f2cb1fd2', {"useNewLoader":"true","region":"na1"}); </script></span><!-- end HubSpot Call-to-Action Code -->`,
      },
    ],
  },
];

export const additional_resources: Array<GreatTeachingInsetInterface> = [
  {
    id: 'additional_resource_1',
    title: 'GTT Evidence Review',
    description: 'REPORT',
    link:
      'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/Great%20Teaching%20Toolkit%20Evidence%20Review.pdf',
    logo: EvidenceReviewCover,
    additional: true,
    html: `<!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper" id="hs-cta-wrapper-d9812a6e-5daf-473e-a4e2-936c68e0a6ea"><span class="hs-cta-node hs-cta-d9812a6e-5daf-473e-a4e2-936c68e0a6ea" id="hs-cta-d9812a6e-5daf-473e-a4e2-936c68e0a6ea"><!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]--><a href="https://cta-redirect.hubspot.com/cta/redirect/2366135/d9812a6e-5daf-473e-a4e2-936c68e0a6ea" target="_blank" rel="noopener"><img class="hs-cta-img" id="hs-cta-img-d9812a6e-5daf-473e-a4e2-936c68e0a6ea" style="border-width:0px;" height="20" width="20" src="https://no-cache.hubspot.com/cta/default/2366135/d9812a6e-5daf-473e-a4e2-936c68e0a6ea.png"  alt="New call-to-action"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(2366135, 'd9812a6e-5daf-473e-a4e2-936c68e0a6ea', {"useNewLoader":"true","region":"na1"}); </script></span><!-- end HubSpot Call-to-Action Code -->`,
  },
  {
    id: 'additional_resource_2',
    title: 'Model for Great Teaching',
    description: 'HANDOUT',
    link:
      'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/GTT_Model_Handout_A4.pdf',
    logo: ModelForGTCover,
    additional: true,
    html: `<!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper" id="hs-cta-wrapper-1fb95ad6-f477-4eda-9f6b-27e6eec5af1b"><span class="hs-cta-node hs-cta-1fb95ad6-f477-4eda-9f6b-27e6eec5af1b" id="hs-cta-1fb95ad6-f477-4eda-9f6b-27e6eec5af1b"><!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]--><a href="https://cta-redirect.hubspot.com/cta/redirect/2366135/1fb95ad6-f477-4eda-9f6b-27e6eec5af1b" target="_blank" rel="noopener"><img class="hs-cta-img" id="hs-cta-img-1fb95ad6-f477-4eda-9f6b-27e6eec5af1b" style="border-width:0px;" height="20" width="20" src="https://no-cache.hubspot.com/cta/default/2366135/1fb95ad6-f477-4eda-9f6b-27e6eec5af1b.png"  alt="New call-to-action"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(2366135, '1fb95ad6-f477-4eda-9f6b-27e6eec5af1b', {"useNewLoader":"true","region":"na1"}); </script></span><!-- end HubSpot Call-to-Action Code -->`,
  },
  {
    id: 'additional_resource_3',
    title: 'Elements of Great Teaching',
    description: 'VIDEOS',
    link: 'https://evidencebased.education/element-videos/',
    icon: 'playButton',
    additional: true,
    html: `<!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper" id="hs-cta-wrapper-38f8fd2c-be7e-468c-ab61-f715d018be12"><span class="hs-cta-node hs-cta-38f8fd2c-be7e-468c-ab61-f715d018be12" id="hs-cta-38f8fd2c-be7e-468c-ab61-f715d018be12"><!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]--><a href="https://cta-redirect.hubspot.com/cta/redirect/2366135/38f8fd2c-be7e-468c-ab61-f715d018be12" target="_blank" rel="noopener"><img class="hs-cta-img" id="hs-cta-img-38f8fd2c-be7e-468c-ab61-f715d018be12" style="border-width:0px;" height="20" width="20" src="https://no-cache.hubspot.com/cta/default/2366135/38f8fd2c-be7e-468c-ab61-f715d018be12.png"  alt="New call-to-action"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(2366135, '38f8fd2c-be7e-468c-ab61-f715d018be12', {"useNewLoader":"true","region":"na1"}); </script></span><!-- end HubSpot Call-to-Action Code -->`,
  },
  {
    id: 'additional_resource_4',
    title: 'GTT Introduction',
    description: 'VIDEO',
    link:
      'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/Great%20Teaching%20Toolkit%20intro%20video.mp4',
    icon: 'playButton',
    additional: true,
    html: `<!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper" id="hs-cta-wrapper-16efec76-211c-41e9-baf2-324b54e33230"><span class="hs-cta-node hs-cta-16efec76-211c-41e9-baf2-324b54e33230" id="hs-cta-16efec76-211c-41e9-baf2-324b54e33230"><!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]--><a href="https://cta-redirect.hubspot.com/cta/redirect/2366135/16efec76-211c-41e9-baf2-324b54e33230" target="_blank" rel="noopener"><img class="hs-cta-img" id="hs-cta-img-16efec76-211c-41e9-baf2-324b54e33230" style="border-width:0px;" height="20" width="20" src="https://no-cache.hubspot.com/cta/default/2366135/16efec76-211c-41e9-baf2-324b54e33230.png"  alt="New call-to-action"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(2366135, '16efec76-211c-41e9-baf2-324b54e33230', {"useNewLoader":"true","region":"na1"}); </script></span><!-- end HubSpot Call-to-Action Code -->`,
  },
  {
    id: 'additional_resource_5',
    title: 'GTT Evidence Review introduction',
    description: 'VIDEO',
    link:
      'https://2366135.fs1.hubspotusercontent-na1.net/hubfs/2366135/Great%20Teaching%20INSET%20files%20DPS/MfGT%20introduction.mp4',
    icon: 'playButton',
    additional: true,
    html: `<!--HubSpot Call-to-Action Code --><span class="hs-cta-wrapper" id="hs-cta-wrapper-e7a5e8dd-2f97-4f85-be34-2849218ef02e"><span class="hs-cta-node hs-cta-e7a5e8dd-2f97-4f85-be34-2849218ef02e" id="hs-cta-e7a5e8dd-2f97-4f85-be34-2849218ef02e"><!--[if lte IE 8]><div id="hs-cta-ie-element"></div><![endif]--><a href="https://cta-redirect.hubspot.com/cta/redirect/2366135/e7a5e8dd-2f97-4f85-be34-2849218ef02e" target="_blank" rel="noopener"><img class="hs-cta-img" id="hs-cta-img-e7a5e8dd-2f97-4f85-be34-2849218ef02e" style="border-width:0px;" height="20" width="20" src="https://no-cache.hubspot.com/cta/default/2366135/e7a5e8dd-2f97-4f85-be34-2849218ef02e.png"  alt="New call-to-action"/></a></span><script charset="utf-8" src="https://js.hscta.net/cta/current.js"></script><script type="text/javascript"> hbspt.cta.load(2366135, 'e7a5e8dd-2f97-4f85-be34-2849218ef02e', {"useNewLoader":"true","region":"na1"}); </script></span><!-- end HubSpot Call-to-Action Code -->`,
  },
];
