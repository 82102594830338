import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../form/input/input';

const AddTagComment = ({
  toggle,
  addComment,
  tagId,
  comment,
}: ModalToggleProps & {
  tagId: number | string;
  addComment: (id: number | string, comment: string) => void;
  comment?: string;
}) => {
  return (
    <Modal>
      <Formik
        initialValues={{
          message: comment ?? '',
        }}
        validationSchema={Yup.object({
          message: Yup.string().max(5000),
        })}
        isInitialValid={false}
        onSubmit={(values) => {
          addComment(tagId, values.message.trim());
          toggle(false);
        }}
      >
        {({ values, handleChange, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="mb-8">
                <Input
                  id="message"
                  type={'textarea'}
                  placeholder="Leave a comment"
                  value={values.message}
                  onChange={handleChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton type="submit" />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddTagComment;
