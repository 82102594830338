import React from 'react';
import * as BSIcon from '@styled-icons/bootstrap';
import { IconProps } from './icon.types';
import { IconStyled } from './icon.styled';
import Tooltip from '../tooltip/tooltip';

const Icon: React.FC<IconProps & { tooltipText?: string }> = React.forwardRef<HTMLDivElement, IconProps>(
  ({ icon, elementSize, container, color, tooltipText, ...other }: IconProps & { tooltipText?: string }, ref) => {
    const DynamicIcon = React.createElement(BSIcon[icon]);
    return (
      <Tooltip content={tooltipText} disabled={!tooltipText}>
        <IconStyled ref={ref} container={container} elementSize={elementSize} color={color} {...(other as any)}>
          {DynamicIcon}
        </IconStyled>
      </Tooltip>
    );
  }
);

Icon.displayName = 'Icon';

Icon.defaultProps = {
  elementSize: 24,
  container: true,
};

export default Icon;
