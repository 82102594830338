import React from 'react';
import { useQuery } from 'react-query';
import { getMe, getNotifications } from '../../service/api';
import ButtonIcon from '../../global/buttonIcon/buttonIcon';
import Tag from '../../global/tag/tag';
import Badge from '../../global/badge/badge';
import { UserMenu } from './index';
import { GLOBAL_ICONS } from '../../resources/vars';
import { ME, NOTIFICATIONS } from '../../service/queryKeys';
import useModal from '../../hooks/useModal';
import NotificationsAside from './components/notificationsAside';
import { ROLE_SYS_ADMIN, ROLE_TEACHER_FREE } from '../../resources/roles';
import { LinkStyled } from '../../global/link/link.styled';
import Button from '../../global/button/button';
import { exitImpersonation } from '../../service/auth';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import routeBuilder from '../../service/routeBuilder';

const HeaderContent = () => {
  const me = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data.data.me,
  });

  const newNotificationsQuery = useQuery(
    [NOTIFICATIONS, 'new'],
    () => getNotifications({ read: 'false', show: 'active' }),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      placeholderData: [],
      refetchOnMount: 'always',
    }
  );

  const [modal, toggle] = useModal((props: any) => <NotificationsAside {...props} />, {
    title: 'Notifications',
    position: 'right',
    fullHeight: true,
  });

  const navigate = useNavigate();

  const isFree = me.data!.role === ROLE_TEACHER_FREE;

  return (
    <>
      {modal}
      <div className={classNames({ 'block-container flex items-center justify-between h-24': me.data?.impersonated })}>
        {me.data?.impersonated && (
          <Button
            size={'sm'}
            className={'flex items-center'}
            onClick={() => {
              exitImpersonation(() => navigate(routeBuilder('userManagement').generate()));
            }}
          >
            Exit impersonation
          </Button>
        )}
        <div className={'block-container flex items-center justify-end'}>
          <div className={'flex items-center'}>
            {me?.data?.organisation && me.data!.role !== ROLE_SYS_ADMIN && (
              <Tag content={me.data!.organisation} className={'mr-3'} />
            )}
            <UserMenu me={me.data!} />
            {!isFree && (
              <div className={'border-l-2 pl-3 mx-3'}>
                <LinkStyled
                  id={'stonly_help'}
                  href={'https://support.evidencebased.education/en/knowledge'}
                  target={'_blank'}
                >
                  <ButtonIcon label={'Help'} icon={GLOBAL_ICONS['help']} isFree mainColor={'secondary'} />
                </LinkStyled>
              </div>
            )}
            <div className={isFree ? 'border-l-2 pl-1 ml-3' : ''}>
              <Badge counter={newNotificationsQuery.data.length}>
                <ButtonIcon
                  label={'Your notifications'}
                  icon={GLOBAL_ICONS['notifications']}
                  mainColor={'secondary'}
                  isFree
                  onClick={() => toggle(true)}
                />
              </Badge>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderContent;
