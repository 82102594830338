import React, { ReactNode, useEffect, useReducer } from 'react';
import CustomRadarChart, { CustomRadarChartDataType } from '../components/customRadarChart';
import { useQuery } from 'react-query';
import { getRadarFeedbackData } from '../../../service/api';
import Choice from '../../../form/choice/choice';
import { RADAR_DATA } from '../../../service/queryKeys';
import Alert from '../../../global/alert/alert';
import { AxiosError } from 'axios';
import { isArray } from 'lodash';
import { validate as isUuid } from 'uuid';
import { MAX_SELECTIONS } from './TimeFrameTable';

export const ALL_OPTION = 'all';

export const processedRadarData = (
  radarData?: any,
  filters?: Array<string>,
  pdfLegend?: any
): CustomRadarChartDataType => {
  const info: Array<Record<string, any>> = [];

  if (!radarData) {
    return {};
  }

  const dimensionFilters = (filters ?? [])
    .map((filter) => radarData.options.find((option: { id: string }) => option.id === filter))
    .filter((filter) => filter?.dimension);

  for (const element of radarData.elements) {
    const item: Record<string, any> = {
      element,
    };

    for (const option of radarData.options) {
      if ((filters ?? []).includes(option.id) && radarData.data[option.id]) {
        const nextDimension = option.dimension === 4 ? 1 : option.dimension + 1;
        if (option.dimension && element === `${nextDimension}.1` && dimensionFilters.length > 1) {
          const optionId = radarData.options.find((o: any) => o.dimension === nextDimension)?.id;
          let lastElement = element;
          if (option.dimension === 1 || option.dimension === 2) {
            lastElement = `${option.dimension}.4`;
          } else if (option.dimension === 3) {
            lastElement = `${option.dimension}.3`;
          } else if (option.dimension === 4) {
            lastElement = `${option.dimension}.6`;
          }

          item[option.id] = optionId ? radarData.data[optionId][element] : radarData.data[option.id][lastElement];
        } else {
          item[option.id] = radarData.data[option.id][element];
        }
      }
    }

    info.push(item);
  }

  const keys = [];
  for (const option of radarData?.options) {
    if ((filters ?? []).includes(option.id)) {
      keys.push(option);
    }
  }

  const colors = keys.map((key, index) => {
    if (index === 0) {
      return '#ee7324';
    }
    if (index === 1) {
      return '#922b21';
    }
    if (index === 2) {
      return '#2980b9';
    }
    if (index === 3) {
      return '#f39c12';
    }
    if (index === 4) {
      return '#95a5a6';
    }

    return '#2c3e50';
  });

  if (pdfLegend) {
    pdfLegend.current = keys;
  }

  return { values: info, keys, colors };
};

const RadarChartSection = ({
  schoolId,
  pdfLegend,
  surveyVersion,
  timeframeSelections,
  isSchool = false,
  alertMessage = null,
  printChoices = true,
  lastInstrument,
  dashboard = false,
  freeAndNoChart,
  isFree,
  setNumberOfTeachersAndResponses,
}: {
  schoolId?: string | number;
  pdfLegend?: any;
  surveyVersion?: string;
  timeframeSelections?: Array<string>;
  isSchool?: boolean;
  alertMessage?: string | ReactNode | null;
  printChoices?: boolean;
  lastInstrument?: boolean;
  dashboard?: boolean;
  freeAndNoChart?: any;
  isFree?: boolean;
  setNumberOfTeachersAndResponses?: any;
}) => {
  const { data: radarData, error, isFetching, isError } = useQuery(
    [RADAR_DATA, timeframeSelections, schoolId, surveyVersion, isSchool, lastInstrument],
    () => getRadarFeedbackData(timeframeSelections, schoolId, surveyVersion, isSchool, lastInstrument),
    {
      staleTime: Infinity,
      select: (data) => data.data.data,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isSchool) {
      if (radarData && radarData.options.length > 0) {
        const tempValue = {
          responses: radarData.options[0].responses ?? 0,
          teachers: radarData.options[0].teachers ?? 0,
        };
        setNumberOfTeachersAndResponses(tempValue);
      } else setNumberOfTeachersAndResponses({ responses: 0, teachers: 0 });
    }
  }, [radarData]);

  const [filters, setFilters] = useReducer((state: Array<string>, action: { value: string | Array<string> }) => {
    let values = [...state];

    if (Array.isArray(action.value)) {
      return action.value;
    }

    if (state?.includes(action.value)) {
      values = values.filter((value) => value !== action.value && value);
    } else {
      if (action.value === ALL_OPTION) {
        values = [];
      } else if (isUuid(action.value)) {
        values = values.filter((value) => value !== ALL_OPTION && isUuid(value));
      } else {
        values = values.filter((value) => value !== ALL_OPTION && !isUuid(value));
      }

      values.push(action.value);
    }

    return values;
  }, []);

  useEffect(() => {
    if (!radarData || radarData.options.length === 0) {
      return;
    }

    if (isSchool) {
      setFilters({
        value: [radarData.options[0].id],
      });
      return;
    }

    const values = radarData.options.reduce<string[]>((acc, curr) => {
      if (curr.id !== ALL_OPTION && acc.length < MAX_SELECTIONS) {
        acc.push(curr.id);
      }

      return acc;
    }, []);

    setFilters({
      value: values,
    });
  }, [radarData]);

  if (isFetching) {
    return (
      <Alert type="info" className={'field-mb'}>
        Loading...
      </Alert>
    );
  }

  if (isError) {
    if (isFree) freeAndNoChart(true);
    return (
      <div className={'w-full'}>
        <Alert type="info" className={'field-mb'}>
          {alertMessage ? alertMessage : (error as AxiosError)?.response?.data.message ?? 'An error has occurred.'}
        </Alert>
      </div>
    );
  }

  const radarDataData = radarData!.data;
  const radarDataEntries = !isArray(radarDataData) ? Object.values(radarDataData)[0] : [];
  const radarDataValues = Object.values(radarDataEntries).filter((value: number) => value !== 0);

  if (isSchool && radarDataData.length === 0) {
    return (
      <div className={'w-full'}>
        <Alert type="info" className={'field-mb'}>
          You&apos;ll receive aggregated student feedback once at least 5 teachers have used the surveys
        </Alert>
      </div>
    );
  }

  return (
    <div className={'w-full'}>
      {radarData && (
        <>
          {printChoices && radarData?.options.length > 1 && (
            <div className={'border-b field-mb pb-8'}>
              <div className={'flex flex-col flex-wrap lg:flex-row lg:items-center gap-4'}>
                {radarData?.options.map((option) => (
                  <>
                    <Choice
                      id={'radar-choices-school'}
                      key={option.id}
                      label={option.name}
                      type={'switch'}
                      checked={filters?.includes(option.id)}
                      onChange={() => setFilters({ value: option.id })}
                      className={'pretty-no-margin'}
                      disabled={
                        (filters.length == 1 && filters?.includes(option.id)) ||
                        (dashboard &&
                          filters.length >= MAX_SELECTIONS &&
                          !filters?.includes(option.id) &&
                          option.id !== ALL_OPTION)
                      }
                    />
                  </>
                ))}
              </div>
            </div>
          )}
          {((filters.length > 0 &&
            filters.some((filter) => !Array.isArray(radarDataData) && radarDataData[filter]?.length == 0)) ||
            radarDataValues.length === 0) && (
            <Alert className={'mb-2'} type={'info'}>
              {alertMessage ??
                "You'll receive aggregated student feedback once at least 5 teachers have used the surveys"}
            </Alert>
          )}
          {filters.length > 0 &&
            filters.some((filter) => !Array.isArray(radarDataData) && radarDataData[filter]?.length !== 0) &&
            radarDataValues.length > 0 && (
              <>
                <CustomRadarChart
                  data={processedRadarData(radarData, filters, pdfLegend)}
                  id={'radarChartId'}
                  dashboard={dashboard}
                />
              </>
            )}
        </>
      )}
    </div>
  );
};
export default RadarChartSection;
