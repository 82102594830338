import React from 'react';
import { Section } from '../../common';
import { AddButton } from '../../../global/button/common';
import DefaultTable from '../../../global/table/defaultTable';
import { ActionButton } from '../../../global/buttonIcon/common';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { deleteDevelopmentPeriod, getDevelopmentPeriods } from '../../../service/api';
import Tooltip from '../../../global/tooltip/tooltip';
import { DEVELOPMENT_PERIODS, INSTRUMENT_OPTIONS } from '../../../service/queryKeys';
import useModal from '../../../hooks/useModal';
import DeletePeriodConfirmModal from '../modals/DeletePeriodConfirmModal';
import { MoreInfo } from '../../../global/tooltip/common';
import { LinkStyled } from '../../../global/link/link.styled';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import dayjs from 'dayjs';
import { AcademicYear } from '../../../@types/Entity/AcademicYear';

dayjs.extend(customParseFormat);

const PathwaysPeriods = ({
  toggle,
  academicYears,
  onboarding = false,
}: {
  toggle: (period?: any) => void;
  academicYears: AcademicYear[];
  onboarding?: boolean;
}) => {
  const { data } = useQuery(DEVELOPMENT_PERIODS, getDevelopmentPeriods, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
    keepPreviousData: true,
  });
  const queryClient = useQueryClient();

  const deletePeriodMutation = useMutation(deleteDevelopmentPeriod, {
    onSuccess: () => {
      queryClient.invalidateQueries(DEVELOPMENT_PERIODS);
      queryClient.invalidateQueries(INSTRUMENT_OPTIONS);
    },
  });

  const [confirmDeleteModal, toggleConfirmDeleteModal, setConfirmDeleteModalProps] = useModal((props: any) => (
    <DeletePeriodConfirmModal {...props} />
  ));

  return (
    <Section
      pathwayPeriods={true}
      id="terms"
      //headline={'Term dates'}
      headline={onboarding ? undefined : <div className={'flex inline-flex gap-1'}>Term dates</div>}
      className={'field-mb '}
      size={onboarding ? 'sm' : undefined}
    >
      {confirmDeleteModal}
      <DefaultTable
        id={'terms'}
        showCounter={false}
        columns={[
          { id: 'name', label: 'Name' },
          { id: 'start_date', label: 'Start date' },
          { id: 'end_date', label: 'End date' },
          { id: 'actions', label: 'Actions' },
        ]}
        rows={
          0 === data?.length
            ? []
            : data?.map((period: any, index: number) => {
                return {
                  id: `period_row--${index}`,
                  cells: [
                    { id: 'name', content: period.name },
                    { id: 'start_date', content: period.start_date },
                    { id: 'end_date', content: period.end_date },
                    {
                      id: 'actions',
                      class: 'actions',
                      content: (
                        <ul className={'actions-list'}>
                          <li>
                            <ActionButton.Edit
                              archived={false}
                              onClick={() =>
                                toggle({
                                  ...period,
                                  start_date: dayjs(period.start_date, 'DD/MM/YYYY').toDate(),
                                  end_date: dayjs(period.end_date, 'DD/MM/YYYY').toDate(),
                                })
                              }
                            />
                          </li>
                          <li>
                            <ActionButton.Remove
                              archived={false}
                              onClick={() => {
                                setConfirmDeleteModalProps({
                                  handleSubmit: () => deletePeriodMutation.mutateAsync(period.id),
                                });

                                toggleConfirmDeleteModal(true);
                              }}
                            />
                          </li>
                        </ul>
                      ),
                    },
                  ],
                };
              })
        }
        noDataMessage={'Start adding Terms clicking on "Add Term" button.'}
      />
      <div className={'flex justify-end mt-8'}>
        <Tooltip
          disabled={academicYears.length !== 0}
          content={'Term dates cannot be created until you have created your academic year above'}
        >
          <div className={'inline-block'}>
            <AddButton onClick={() => toggle()} label={'Term dates'} disabled={academicYears.length === 0} />
          </div>
        </Tooltip>
      </div>
    </Section>
  );
};

export default PathwaysPeriods;
