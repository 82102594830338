import Modal from '../../../global/messages/modal/modal';
import ModalComponents from '../../../global/messages/modal/modal.components';
import React, { useEffect, useState } from 'react';
import { ModalProps } from '../../../global/messages/modal/modal.types';
import { useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  checkMeetingTemplateTitleExists,
  CreateUpdateMeetingTemplateRequestBody,
  postCreateMeetingTemplate,
  putUpdateMeetingTemplate,
} from '../../../service/api/groupsApi';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import DragItem from '../../pathway/components/dragItem';
import Input from '../../../form/input/input';
import ErrorFeedback from '../../../form/common/error';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';
import { debounce } from 'lodash';
import { MeetingTemplate } from '../../../@types/Entity/MeetingTemplate';
import { MEETINGS_TEMPLATES, RESOURCES_BY_TYPE } from '../../../service/queryKeys';
import { toast } from 'react-toastify';
import Selector from '../../../form/select/selector';
import { getResourcesByType } from '../../../service/api';

export default function MeetingTemplateFormModal({ toggle, meetingTemplateToEdit }: Props) {
  const queryClient = useQueryClient();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    resolver: yupResolver(VALIDATION_SCHEMA),
    defaultValues:
      null !== meetingTemplateToEdit
        ? meetingTemplateToEdit
        : {
            id: null,
            title: '',
            prerequisites: [
              {
                title: '',
              },
            ],
            agendaItems: [
              {
                title: '',
              },
            ],
            resources: [
              {
                title: '',
                url: '',
              },
            ],
            celebrateSuccess: [
              {
                instruction: '',
              },
            ],
            shareChallenges: [
              {
                instruction: '',
              },
            ],
            meetingPurpose: '',
            type: '',
          },
  });

  const resourceType = 'template';
  const { data: textResource } = useQuery([RESOURCES_BY_TYPE, resourceType], () => getResourcesByType(), {
    select: (data) => data.data.data,
    placeholderData: null,
  });

  const prerequisitesFieldArray = useFieldArray({
    name: 'prerequisites',
    control: control,
    keyName: 'uniqueId',
  });

  const agendaItemsFieldArray = useFieldArray({
    name: 'agendaItems',
    control: control,
    keyName: 'uniqueId',
  });

  const resourcesFieldArray = useFieldArray({
    name: 'resources',
    control: control,
    keyName: 'uniqueId',
  });

  const celebrateSuccessArray = useFieldArray({
    name: 'celebrateSuccess',
    control: control,
    keyName: 'uniqueId',
  });

  const shareChallengesArray = useFieldArray({
    name: 'shareChallenges',
    control: control,
    keyName: 'uniqueId',
  });

  const createUpdateMeetingTemplateMutation = useMutation(
    ({ data }: { data: CreateUpdateMeetingTemplateRequestBody }) => {
      if (null == meetingTemplateToEdit) {
        return postCreateMeetingTemplate(data);
      }

      return putUpdateMeetingTemplate(meetingTemplateToEdit.id, data);
    },
    {
      onSuccess: () => {
        toast.success(`Template ${null == meetingTemplateToEdit ? 'added' : 'updated'} successfully`);
        queryClient.invalidateQueries([MEETINGS_TEMPLATES]);
        toggle();
      },
    }
  );

  const [listOfUsedSlugs, setListOfUsedSlugs] = useState<any>([]);

  const updateListOfSlugs = (link: string) => {
    const tempArray = listOfUsedSlugs;
    if (!tempArray.includes(link)) tempArray.push(link);
    setListOfUsedSlugs(tempArray);
  };

  return (
    <Modal open toggle={() => toggle()} title={'Add agenda template'} size={'md'}>
      <form onSubmit={handleSubmit((values) => createUpdateMeetingTemplateMutation.mutate({ data: values }))}>
        <ModalComponents.Body>
          <DndProvider backend={HTML5Backend}>
            <div>
              <Input
                {...register('title')}
                id={'template_title'}
                label={'Agenda template name'}
                error={errors.title?.message}
              />
            </div>
            <div className={'mt-10'}>
              <div className={'flex justify-between mb-4'}>
                <div>
                  <h5>Prerequisites</h5>
                </div>
                <div>
                  <ButtonIcon
                    icon={'Plus'}
                    isFree
                    label={'Add prerequisite'}
                    size={'xsm'}
                    onClick={() => prerequisitesFieldArray.append(BASE_PREREQUISITE)}
                  />
                </div>
              </div>
              {prerequisitesFieldArray.fields.map((field, index) => (
                <DragItem
                  key={field.uniqueId}
                  id={field.uniqueId}
                  index={index}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = prerequisitesFieldArray.fields.findIndex(
                      (field) => draggedId === field.uniqueId
                    );
                    prerequisitesFieldArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = prerequisitesFieldArray.fields.findIndex((field) => id === field.uniqueId);
                    return {
                      id: id,
                      index: index,
                    };
                  }}
                  removeDisabled={false}
                  handleRemove={() => prerequisitesFieldArray.remove(index)}
                  type={'prerequisites'}
                >
                  <Input
                    {...register(`prerequisites.${index}.title` as const)}
                    id={`prerequisite-title-${field.uniqueId}`}
                    placeholder={'Title'}
                    error={errors.prerequisites?.[index]?.title?.message}
                  />
                </DragItem>
              ))}
            </div>

            <div className="mt-10">
              <div className={'flex justify-between mb-4'}>
                <div>
                  <h5>Agenda items</h5>
                </div>
                <div>
                  <ButtonIcon
                    icon={'Plus'}
                    isFree
                    label={'Add prerequisite'}
                    size={'xsm'}
                    onClick={() => agendaItemsFieldArray.append(BASE_AGENDA_ITEM)}
                  />
                </div>
              </div>
              {errors.agendaItems?.message && <ErrorFeedback error={errors.agendaItems?.message} />}
              {agendaItemsFieldArray.fields.map((field, index) => (
                <DragItem
                  key={field.uniqueId}
                  id={field.uniqueId}
                  index={index}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = agendaItemsFieldArray.fields.findIndex(
                      (field) => draggedId === field.uniqueId
                    );
                    agendaItemsFieldArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = agendaItemsFieldArray.fields.findIndex((field) => id === field.uniqueId);
                    return {
                      id: id,
                      index: index,
                    };
                  }}
                  removeDisabled={false}
                  handleRemove={() => agendaItemsFieldArray.remove(index)}
                  type={'agendaItems'}
                >
                  <Input
                    {...register(`agendaItems.${index}.title` as const)}
                    id={`agendaItem-title-${field.uniqueId}`}
                    placeholder={'Title'}
                    error={errors.agendaItems?.[index]?.title?.message}
                  />
                </DragItem>
              ))}
            </div>
            <div className="my-10">
              <Selector
                isClearable={false}
                id={'resource'}
                onChange={(event: any) => {
                  if (event.length > 0)
                    event.map((e: any) => {
                      if (!listOfUsedSlugs.includes(e.value))
                        resourcesFieldArray.append({
                          title: e.label,
                          url: e.value,
                        });
                      updateListOfSlugs(e.value);
                    });
                }}
                label={'Select resources'}
                placeholder={'Please select resource for a agenda template'}
                options={textResource?.map((resource: any) => ({ label: resource.title, value: resource.url })) ?? []}
                isMulti
              />
            </div>
            <div className="mt-10">
              <div className={'flex justify-between mb-4'}>
                <div>
                  <h5>Resources</h5>
                </div>
                <div>
                  <ButtonIcon
                    icon={'Plus'}
                    isFree
                    label={'Add prerequisite'}
                    size={'xsm'}
                    onClick={() => resourcesFieldArray.append(BASE_RESOURCE)}
                  />
                </div>
              </div>
              {resourcesFieldArray.fields.map((field, index) => (
                <DragItem
                  key={field.uniqueId}
                  id={field.uniqueId}
                  index={index}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = resourcesFieldArray.fields.findIndex((field) => draggedId === field.uniqueId);
                    resourcesFieldArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = resourcesFieldArray.fields.findIndex((field) => id === field.uniqueId);
                    return {
                      id: id,
                      index: index,
                    };
                  }}
                  removeDisabled={false}
                  handleRemove={() => resourcesFieldArray.remove(index)}
                  type={'resources'}
                >
                  <div className="mb-1">
                    <Input
                      {...register(`resources.${index}.title` as const)}
                      id={`resource-title-${field.uniqueId}`}
                      placeholder={'Title'}
                      error={errors.resources?.[index]?.title?.message}
                    />
                  </div>
                  <div>
                    <Input
                      {...register(`resources.${index}.url` as const)}
                      id={`resource-url-${field.uniqueId}`}
                      placeholder={'URL'}
                      error={errors.resources?.[index]?.url?.message}
                    />
                  </div>
                </DragItem>
              ))}
            </div>
            <div className={'mt-10'}>
              <Input
                {...register('meetingPurpose')}
                id={'template_purpose'}
                label={'Agenda template purpose'}
                error={errors.title?.message}
              />
            </div>
            <div className={'mt-10'}>
              <Input
                {...register('type')}
                id={'template_type'}
                label={'Agenda template type'}
                error={errors.title?.message}
              />
            </div>
            <div className={'mt-10'}>
              <div className={'flex justify-between mb-4'}>
                <div>
                  <h5>Celebrate Success</h5>
                </div>
                <div>
                  <ButtonIcon
                    icon={'Plus'}
                    isFree
                    label={'Add Celebrate Success'}
                    size={'xsm'}
                    onClick={() => celebrateSuccessArray.append(BASE_CELEBRATE_SUCCESS)}
                  />
                </div>
              </div>
              {celebrateSuccessArray.fields.map((field, index) => (
                <DragItem
                  key={field.uniqueId}
                  id={field.uniqueId}
                  index={index}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = celebrateSuccessArray.fields.findIndex(
                      (field) => draggedId === field.uniqueId
                    );
                    celebrateSuccessArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = celebrateSuccessArray.fields.findIndex((field) => id === field.uniqueId);
                    return {
                      id: id,
                      index: index,
                    };
                  }}
                  removeDisabled={false}
                  handleRemove={() => celebrateSuccessArray.remove(index)}
                  type={'celebrateSuccess'}
                >
                  <Input
                    {...register(`celebrateSuccess.${index}.instruction` as const)}
                    id={`celebrateSuccess-instruction-${field.uniqueId}`}
                    placeholder={'Instruction'}
                    error={errors.celebrateSuccess?.[index]?.instruction?.message}
                  />
                </DragItem>
              ))}
            </div>
            <div className={'mt-10'}>
              <div className={'flex justify-between mb-4'}>
                <div>
                  <h5>Share Challenges</h5>
                </div>
                <div>
                  <ButtonIcon
                    icon={'Plus'}
                    isFree
                    label={'Add Share Challenges'}
                    size={'xsm'}
                    onClick={() => shareChallengesArray.append(BASE_SHARE_CHALLENGES)}
                  />
                </div>
              </div>
              {shareChallengesArray.fields.map((field, index) => (
                <DragItem
                  key={field.uniqueId}
                  id={field.uniqueId}
                  index={index}
                  moveItem={(draggedId, hoverIndex) => {
                    const draggedIndex = shareChallengesArray.fields.findIndex((field) => draggedId === field.uniqueId);
                    shareChallengesArray.move(draggedIndex, hoverIndex);
                  }}
                  findItem={(id) => {
                    const index = shareChallengesArray.fields.findIndex((field) => id === field.uniqueId);
                    return {
                      id: id,
                      index: index,
                    };
                  }}
                  removeDisabled={false}
                  handleRemove={() => shareChallengesArray.remove(index)}
                  type={'shareChallenges'}
                >
                  <Input
                    {...register(`shareChallenges.${index}.instruction` as const)}
                    id={`shareChallenges-instruction-${field.uniqueId}`}
                    placeholder={'Instruction'}
                    error={errors.shareChallenges?.[index]?.instruction?.message}
                  />
                </DragItem>
              ))}
            </div>
          </DndProvider>
        </ModalComponents.Body>
        <ModalComponents.Footer>
          <ModalComponents.CancelButton onClick={() => toggle(false)} />
          <ModalComponents.ConfirmButton
            type={'submit'}
            disabled={isSubmitting || createUpdateMeetingTemplateMutation.isLoading}
            label={
              isSubmitting || createUpdateMeetingTemplateMutation.isLoading
                ? 'Loading...'
                : null === meetingTemplateToEdit
                ? 'Add template'
                : 'Edit template'
            }
          />
        </ModalComponents.Footer>
      </form>
    </Modal>
  );
}

const debouncedCheckMeetingTemplateTitleExists = debounce(
  async (title: string, idToIgnore: string | null = null, resolve: (valid: boolean) => void) => {
    try {
      const response = await checkMeetingTemplateTitleExists(title, idToIgnore);

      resolve(false === response.data.exists);
    } catch {
      resolve(false);
    }
  },
  500
);

const VALIDATION_SCHEMA = Yup.object().shape({
  title: Yup.string()
    .nullable()
    .max(255, 'This field has a limit of 255 characters.')
    .required('This field is required.')
    .test({
      message: 'An agenda template with this name already exists.',
      test: async (value, context) =>
        new Promise((resolve) => debouncedCheckMeetingTemplateTitleExists(value!, context.parent.id, resolve)),
    }),
  prerequisites: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .max(255, 'This field has a limit of 255 characters.')
        .nullable()
        .required('This field is required.'),
    })
  ),
  agendaItems: Yup.array()
    .min(1, 'A template must have at least 1 agenda item.')
    .of(
      Yup.object().shape({
        title: Yup.string()
          .max(255, 'This field has a limit of 255 characters.')
          .nullable()
          .required('This field is required.'),
      })
    ),
  resources: Yup.array().of(
    Yup.object().shape({
      title: Yup.string()
        .max(255, 'This field has a limit of 255 characters.')
        .nullable()
        .required('This field is required.'),
      url: Yup.string()
        .max(255, 'This field has a limit of 255 characters.')
        .nullable()
        .required('This field is required.'),
    })
  ),
  celebrateSuccess: Yup.array().of(
    Yup.object().shape({
      instruction: Yup.string().max(255, 'This field has a limit of 255 characters.').nullable(),
    })
  ),
  shareChallenges: Yup.array().of(
    Yup.object().shape({
      instruction: Yup.string().max(255, 'This field has a limit of 255 characters.').nullable(),
    })
  ),
  meetingPurpose: Yup.string().nullable(),
  type: Yup.string().nullable(),
  resource: Yup.string().nullable(),
});

const BASE_PREREQUISITE = {
  title: '',
};

const BASE_AGENDA_ITEM = {
  title: '',
};

const BASE_RESOURCE = {
  title: '',
  url: '',
};

const BASE_CELEBRATE_SUCCESS = {
  instruction: '',
};

const BASE_SHARE_CHALLENGES = {
  instruction: '',
};

interface Props extends Pick<ModalProps, 'toggle'> {
  meetingTemplateToEdit?: MeetingTemplate | null;
}

interface FormValues {
  id: string | null;
  title: string;
  prerequisites: {
    title: string;
  }[];
  agendaItems: {
    title: string;
  }[];
  resources: {
    title: string;
    url: string;
  }[];
  celebrateSuccess: {
    instruction: string;
  }[];
  shareChallenges: {
    instruction: string;
  }[];
  meetingPurpose?: string;
  type?: string;
  resource?: any;
}
