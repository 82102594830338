import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { SUBJECTS, VALIDATE_RESET_PASSWORD_TOKEN } from '../../service/queryKeys';
import { getSubjects, getValidateResetPasswordToken } from '../../service/api';
import AuthLayout from '../../layout/auth';
import Input from '../../form/input/input';
import Button from '../../global/button/button';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { FIRST_NAME_VALIDATION, LAST_NAME_VALIDATION, PASSWORD_VALIDATION } from '../../resources/schemas';
import Selector from '../../form/select/selector';
import MessageAtMenuList from '../../form/select/components/messageAtMenuList';
import ReCAPTCHA from 'react-google-recaptcha';
import routeBuilder from '../../service/routeBuilder';
import { toast } from 'react-toastify';
import { ROLE_TEACHER } from '../../resources/roles';
import { completeRegistration } from '../../service/api/authApi';
import { DefaultOptionType } from '../../resources/types';
import Alert from '../../global/alert/alert';

const token = new URLSearchParams(location.search).get('t');

const CompleteRegistrationPage = () => {
  const navigate = useNavigate();
  const { data, isSuccess, isError } = useQuery(
    [VALIDATE_RESET_PASSWORD_TOKEN],
    () => getValidateResetPasswordToken(token as string),
    {
      select: (data) => data.data.data,
      enabled: token != null,
    }
  );

  if (token == null) {
    navigate(routeBuilder('login').route);
  }

  if (isError) {
    toast.error('Sorry, the link you are using has expired.');
    navigate(routeBuilder('login').route);
  }

  const { data: subjects } = useQuery(SUBJECTS, getSubjects, {
    staleTime: Infinity,
    select: (data) => data.data.data,
  });

  const completeRegistrationMutation = useMutation(completeRegistration, {
    onSuccess: () => {
      navigate(routeBuilder('login').route);
    },
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        first_name: FIRST_NAME_VALIDATION,
        last_name: LAST_NAME_VALIDATION,
        password: PASSWORD_VALIDATION,
        confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
        role_description: Yup.string()
          .trim()
          .test('is-role_description-required', 'Your role is a required field', (value) => {
            if (data?.is_niot || data?.is_regional_lead || data?.is_ceo) {
              return true;
            }

            return value !== '' && value != null;
          }),
        subjects: Yup.array().test('is-subjects-required', 'Subjects is a required field', (value) => {
          if (
            data?.role !== ROLE_TEACHER ||
            data?.is_associate_college ||
            data?.is_regional_lead ||
            data?.is_niot ||
            data?.is_ceo
          ) {
            return true;
          }

          return value != null && value.length !== 0;
        }),
        captcha: Yup.string().required().nullable(),
      })
    ),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      confirm_password: '',
      organisation: '',
      role_description: '',
      subjects: [] as DefaultOptionType[],
      captcha: null as string | null,
    },
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    setValue('first_name', data.first_name);
    setValue('last_name', data.last_name);
    setValue('email', data.email);
    setValue('organisation', data.organisation);
    setValue(
      'role_description',
      data?.role === ROLE_TEACHER && !data.mentor && !data.is_associate_college
        ? 'Early Career Teacher'
        : data?.role_description ?? null
    );
  }, [data]);

  if (isSuccess) {
    return (
      <AuthLayout title={'Complete registration'} niotMode={data?.is_ceo}>
        <form
          className={'py-5'}
          onSubmit={handleSubmit((data) => {
            completeRegistrationMutation.mutate({
              body: {
                first_name: data.first_name!,
                last_name: data.last_name!,
                password: data.password,
                role_description: data.role_description,
                subjects: data.subjects.map((subject) => subject.value),
                captcha: data.captcha!,
              },
              params: {
                token: token!,
              },
            });
          })}
        >
          {!data.is_ceo && (
            <Alert type="info" className={'field-mb'}>
              Thank you for registering with the Great Teaching Toolkit. To get started, please confirm and populate the
              fields below, then click Submit.
            </Alert>
          )}
          <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
            <div className={'field-mb'}>
              <Input
                {...register('first_name')}
                id="first_name"
                label="First Name"
                placeholder={`Please enter your first name`}
                required
                error={errors.first_name?.message}
              />
            </div>
            <div className={'field-mb'}>
              <Input
                {...register('last_name')}
                id="last_name"
                label="Last Name"
                placeholder={`Please enter your last name`}
                required
                error={errors.last_name?.message}
              />
            </div>
          </div>
          <div className={'field-mb'}>
            <Input {...register('email')} id="email" label={'Email'} disabled={true} />
          </div>
          <div className={'mb-8'}>
            <Input
              {...register('password')}
              id={'password'}
              type={'password'}
              label={'Please specify your password'}
              placeholder={'Enter your password'}
              required
              error={errors.password?.message}
            />
          </div>
          <div className={'field-mb'}>
            <Input
              {...register('confirm_password')}
              id="confirm_password"
              type={'password'}
              label="Confirm Password"
              placeholder={'Please repeat your password'}
              required
              error={errors.confirm_password?.message}
            />
          </div>
          {!data.is_ceo && (
            <div className={'field-mb'}>
              <Input {...register('organisation')} id="organisation" label={'Organisation/School'} disabled={true} />
            </div>
          )}
          {!data.is_niot && !data.is_regional_lead && !data.is_ceo && (
            <div className={'mb-8'}>
              <Input
                {...register('role_description')}
                id={'role_description'}
                label={'Your role'}
                placeholder={'Please describe your role'}
                required
                error={errors.role_description?.message}
                disabled={data?.role === ROLE_TEACHER && !data.mentor && !data.is_associate_college}
              />
            </div>
          )}
          {data.role === ROLE_TEACHER &&
            !data.is_associate_college &&
            !data.is_regional_lead &&
            !data.is_niot &&
            !data.is_ceo && (
              <div className={'mb-8'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Selector
                      {...field}
                      id={'subjects'}
                      label={'Please select the subjects you teach. If you teach primary students select “Primary”'}
                      placeholder={'Please select the subjects you teach'}
                      required
                      options={subjects ?? []}
                      isMulti
                      isClearable={false}
                      isCreatableSelect
                      customComponents={{
                        MenuList: function MenuList(menuListProps) {
                          return (
                            <MessageAtMenuList
                              message={"Don't see your subject? Add it by populating the field above."}
                              {...menuListProps}
                            />
                          );
                        },
                      }}
                      error={errors.subjects?.message}
                    />
                  )}
                  name={'subjects'}
                />
              </div>
            )}
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
            onChange={(token) => setValue('captcha', token)}
            onErrored={() => setValue('captcha', null)}
            onExpired={() => setValue('captcha', null)}
          />
          <Button className={'block w-full mt-5'} type={'submit'} disabled={completeRegistrationMutation.isLoading}>
            Confirm
          </Button>
        </form>
      </AuthLayout>
    );
  }

  return <></>;
};

export default CompleteRegistrationPage;
