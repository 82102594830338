import React, { useEffect, useState } from 'react';
import DefaultTable from '../../../global/table/defaultTable';
import { Checkbox } from 'pretty-checkbox-react';
import Selector from '../../../form/select/selector';
import { Section } from '../../common';
import { useQuery } from 'react-query';
import { TIMEFRAME_OPTIONS } from '../../../service/queryKeys';
import { getTimeframeOptions } from '../../../service/api';
import Button from '../../../global/button/button';
import { VERSION_E } from '../../instrument/sections/instrumentForm';
import ErrorFeedback from '../../../form/common/error';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Choice from '../../../form/choice/choice';

export const TIMEFRAME_NO_OPTION = { label: 'Select academic year', value: '' };

export type TimeframeSubOptionType = {
  id: string;
  school_class?: string;
  survey_type?: {
    name: string;
    type: 'element' | 'dimension' | null;
    id: string | null;
  };
  survey_version?: string;
  completed_at?: string;
  //School props
  name?: string;
  start_date?: string;
  end_date?: string;
  survey_versions?: Array<string>;
  elements?: Array<string>;
};

export type TimeframeOptionType = {
  label: string;
  value: string | null;
  start_date?: string;
  end_date?: string;
  data?: Array<TimeframeSubOptionType>;
};

export const MAX_SELECTIONS = 4;

const TimeFrameTable = ({
  option,
  setOption,
  confirmSelections,
  isSchool = false,
  hide = null,
  isFree,
  view,
  setView,
}: TimeFrameTableType) => {
  const { data: options, isFetching: optionsLoading, isLoading, isFetched } = useQuery(
    [TIMEFRAME_OPTIONS, isSchool],
    () => getTimeframeOptions(isSchool, undefined),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
      placeholderData: [],
    }
  );

  const [selections, setSelections] = useState<Array<TimeframeSubOptionType>>([]);
  const [error, setError] = useState<string | null>();

  useEffect(() => {
    if (hide != null) {
      if (isFree) {
        if (!isLoading && isFetched && options.length > 0) {
          hide(false);
        }
      } else hide(true);
    }
  }, [options, isLoading, isFetched]);

  return (
    <>
      <Section size={'md'} className={'mb-5'}>
        <Selector
          className={'w-full sm:w-8/12 md:w-9/12 lg:w-7/12 xl:w-5/12 mb-4'}
          id={'time_frame_selector'}
          value={TIMEFRAME_NO_OPTION}
          isClearable={false}
          isSearchable={false}
          onChange={(option: TimeframeOptionType) => {
            setOption(option);
            setSelections([]);
            setError(null);
          }}
          isLoading={optionsLoading}
          options={options}
          noOptionsMessage={() => (
            <>
              <div className={'text-info bg-gray-100 flex items-center justify-center gap-5 my-1 py-4 px-2 mb-2'}>
                <Icon icon={GLOBAL_ICONS['info']} container={false} elementSize={20}/>
                {isSchool ? (
                  <i>
                    You&apos;ll be able to view feedback when at least 5 staff have obtained feedback from the same
                    survey type/version.
                  </i>
                ) : (
                  <i>You&apos;ll be able to view feedback when at least 5 students have completed a survey.</i>
                )}
              </div>
              No options
            </>
          )}
        />
        <DefaultTable
          id={'time_frame_table'}
          showCounter={false}
          apiHandled={true}
          columns={
            isSchool
              ? [
                { id: 'name', label: 'Term name' },
                { id: 'start_date', label: 'Start date' },
                { id: 'end_date', label: 'End date' },
                { id: 'actions', label: 'Actions' },
              ]
              : [
                { id: 'class_name', label: 'Class' },
                { id: 'survey_type', label: 'Type' },
                { id: 'survey_version', label: 'Version' },
                { id: 'completed_at', label: 'Completed' },
                { id: 'actions', label: 'Actions' },
              ]
          }
          rows={
            option.value === null || option.data == null
              ? []
              : option.data.map((row, index) => {
                const isSelected = selections.some((s) => row.id === s.id);

                return {
                  id: `row_timeframe--${index}`,
                  cells: isSchool
                    ? [
                      {
                        id: 'name',
                        content: row.name,
                      },
                      {
                        id: 'start_date',
                        content: row.start_date,
                      },
                      { id: 'end_date', content: row.end_date },
                      {
                        id: 'actions',
                        class: 'actions',
                        content: (
                          <Checkbox
                            color={'primary-o'}
                            disabled={!isSelected && selections.length >= MAX_SELECTIONS}
                            onChange={() => {
                              const newSelections = isSelected
                                ? selections.filter((s) => s.id !== row.id)
                                : [...selections, row];

                              setSelections(newSelections);
                              setError(null);
                            }}
                            checked={isSelected}
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                              </svg>
                            }
                          />
                        ),
                      },
                    ]
                    : [
                      {
                        id: 'class_name',
                        content: row.school_class,
                      },
                      {
                        id: 'survey_type',
                        content: row.survey_type!.name,
                      },
                      { id: 'survey_version', content: row.survey_version },
                      { id: 'completed_at', content: row.completed_at },
                      {
                        id: 'actions',
                        class: 'actions',
                        content: (
                          <Checkbox
                            color={'primary-o'}
                            disabled={!isSelected && selections.length >= MAX_SELECTIONS}
                            onChange={() => {
                              const newSelections = isSelected
                                ? selections.filter((s) => s.id !== row.id)
                                : [...selections, row];

                              setSelections(newSelections);
                              setError(null);
                            }}
                            checked={isSelected}
                            icon={
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                className="bi bi-check"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
                              </svg>
                            }
                          />
                        ),
                      },
                    ],
                };
              })
          }
          noDataMessage={'Please select an academic year.'}
        />
        <div className={'mt-3 flex justify-end'}>
          <div className={isFree ? 'grid grid-cols-1 grid-rows-2 gap-4' : ''}>
            <Button
              size={'sm'}
              style={{ color: 'white' }}
              disabled={selections.length === 0 || selections.length > MAX_SELECTIONS || error != null}
              type={'button'}
              onClick={() => {
                const versionESelections = selections.filter((selection) => selection.survey_version === VERSION_E);
                isFree && setView('real');
                if (versionESelections.length > 0 && versionESelections.length !== selections.length) {
                  setError("Sorry, you can't compare version E surveys with a different version.");
                  return;
                }
                confirmSelections(selections);
              }}
            >
              Show feedback
            </Button>
            {isFree && (
              <Choice
                id={'deactivated-accounts'}
                label={`${view === 'real' ? 'Show' : 'Hide'} example data`}
                type={'switch'}
                disabled={view === 'initial' || selections.length === 0 || selections.length > MAX_SELECTIONS || (error != null && isFree && view === 'real')}
                className={'pretty-no-margin'}
                checked={view === 'initial' || view === 'example'}
                onChange={() => (view === 'real' ? setView('example') : setView('real'))}
              />
            )}
          </div>
        </div>
        <div className={'flex justify-end'}>{error && <ErrorFeedback id={'time_frame_selector'} error={error}/>}</div>
      </Section>
    </>
  );
};

type TimeFrameTableType = {
  option: TimeframeOptionType;
  setOption: (option: TimeframeOptionType) => void;
  confirmSelections: (selections: Array<TimeframeSubOptionType>) => void;
  isSchool?: boolean;
  hide?: any;
  isFree?: boolean;
  view?: string;
  setView?: any;
};

export default TimeFrameTable;
