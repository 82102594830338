import React, {CSSProperties, MutableRefObject, useRef} from 'react';
import { Transition } from 'react-transition-group';
import { TransitionProps } from 'react-transition-group/Transition';
import {
  TransitionsProps,
  StatusType,
  TransitionStylesType,
  DEFAULT_TRANSITION_DURATION,
  DEFAULT_TRANSITION_FUNC,
} from './transitions.types';

const Collapse: React.FC<TransitionsProps> = ({ when, children, ...other }: TransitionsProps) => {
  const child = useRef() as MutableRefObject<HTMLDivElement>;
  const CHILD_HEIGHT = child.current ? child.current.offsetHeight : '0';

  const DEFAULT_DURATION = DEFAULT_TRANSITION_DURATION;

  const DEFAULT_STYLE: CSSProperties = {
    transition: `height ${DEFAULT_DURATION}ms ${DEFAULT_TRANSITION_FUNC}`,
    height: 0,
    minHeight: 0,
    overflow: 'hidden',
  };

  const TRANSITION_STYLES: TransitionStylesType = {
    entering: {
      height: `${CHILD_HEIGHT}px`,
      overflow: 'hidden',
    },
    entered: {
      height: 'auto',
      overflow: 'visible',
    },
    exiting: {
      height: `${CHILD_HEIGHT}px`,
      overflow: 'hidden',
    },
    exited: {
      height: '0',
      overflow: 'hidden',
    },
  };

  return (
    <Transition
      in={when}
      timeout={{
        appear: DEFAULT_DURATION,
        enter: DEFAULT_DURATION,
        exit: DEFAULT_DURATION / 2,
      }}
      {...(other as TransitionProps)}
    >
      {(state) => (
        <div
          style={{
            ...DEFAULT_STYLE,
            ...TRANSITION_STYLES[state as StatusType],
          }}
        >
          <div ref={child}>{children as any}</div>
        </div>
      )}
    </Transition>
  );
};

Collapse.displayName = 'Collapse';

export default Collapse;
