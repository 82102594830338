import React from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { VIDEO_COMMENTS } from '../../../service/queryKeys';
import { deleteVideoComment, getVideoComments } from '../../../service/api';
import Comment, { CommentType } from '../components/Comment';
import { AddButton } from '../../../global/button/common';
import useModal from '../../../hooks/useModal';
import AddCommentModal from '../modals/AddCommentModal';
import { toast } from 'react-toastify';

const CommentsTab = ({ videoId, userId, parent, isFree }: CommentTabType) => {
  const { data: comments } = useQuery([VIDEO_COMMENTS, videoId], () => getVideoComments(videoId!), {
    select: (data) => data.data.data,
    placeholderData: [],
  });

  const [addCommentModal, toggleAddCommentModal, setAddCommentModalProps] = useModal((props: any) => (
    <AddCommentModal {...props} videoId={videoId} />
  ));

  const queryClient = useQueryClient();
  const deleteCommentMutation = useMutation(deleteVideoComment, {
    onSuccess: () => {
      toast.success('Video deleted successfully');
      queryClient.invalidateQueries(VIDEO_COMMENTS);
    },
  });

  return (
    <>
      {addCommentModal}
      <AddButton
        id={parent && `${parent}_addCommentButton`}
        label={'Add comment'}
        disabled={isFree}
        onClick={() => {
          setAddCommentModalProps({ title: 'Add comment', parent: parent ? parent : undefined });
          toggleAddCommentModal(true);
        }}
      />
      {comments?.map((comment: CommentType) => (
        <Comment
          key={comment.id}
          userId={userId}
          comment={comment}
          onEdit={(id, message) => {
            setAddCommentModalProps({ id, message, title: 'Edit comment' });
            toggleAddCommentModal(true);
          }}
          onDelete={(id) => {
            deleteCommentMutation.mutate({ id, videoId: videoId! });
          }}
        />
      ))}
    </>
  );
};

type CommentTabType = {
  videoId?: string;
  userId: string;
  parent?: string;
  isFree?: boolean;
};

export default CommentsTab;
