import React, { useState } from 'react';
import { ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR } from '../../../resources/roles';
import { UserType } from '../userManagementPage';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { ReviewUserTable } from '../components';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { putUsersStatus, putUserStatus } from '../../../service/api';
import { DASHBOARD, USER_MANAGEMENT_LIST } from "../../../service/queryKeys";
import { toast } from "react-toastify";

const ActiveStateConfirmModal = ({
  user,
  toggle,
  multi,
  users,
  active,
  onSuccess
}: ModalToggleProps & { user?: UserType, multi?: boolean, users?: any, active?: boolean; onSuccess: () => void }) => {
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false)

  const updateUserStatus = useMutation(putUserStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      queryClient.invalidateQueries(DASHBOARD);
      setIsLoading(false)
      toggle(false);
    },
  });

  const updateUsersStatus = useMutation(putUsersStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      queryClient.invalidateQueries(DASHBOARD);
      onSuccess();
      setIsLoading(false)
      toggle(false);
    },
  });

  const CONTENT = (): Record<'on' | 'off', { description: string; button: string; actions: string[] }> => {
    return {
      on: {
        description: 'You are about to deactivate/disable this user account.',
        button: 'Deactivate user',
        actions: [
          'The user will no longer be able to access the platform.',
          'Their association with the course bundle will be removed.',
          ...([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(user?.role as string)
            ? [`The number of active users associated to ${user?.organisation} will be reduced by 1.`]
            : []),
        ],
      },
      off: {
        description: 'You are about to reactivate this user account.',
        button: 'Activate user',
        actions: [
          'The user will be able to access the platform again.',
          'Their association with the course bundle will be enabled again.',
          ...([ROLE_SCHOOL_COORDINATOR, ROLE_ASSISTANT_GT_COORDINATOR].includes(user?.role as string)
            ? [`The number of active users associated to ${user?.organisation} will be increased by 1.`]
            : []),
        ],
      },
    };
  };

  return (
    <Modal>
      <Modal.Body>
        <div>
          {multi ?
            <>
              <p className={'sm-field-mb'}>You are about to {active ? 'de' : 're'}activate these accounts.</p>
              <div className={'sm-field-mb'}>
                <ReviewUserTable multi={true} users={users}/>
              </div>
              <p>If you perform this action:</p>
              <ul className={'list-disc ml-5 sm-field-mb'}>
                <li key={`action--1`}>These users
                  will {active ? 'no longer be able to access the platform' : 'be able to access the platform again'}.
                </li>
                <li key={`action--2`}>Their association with the course bundle will
                  be {active ? 'removed' : 'enabled again'}.
                </li>
              </ul>
              <strong>Are you sure you want to continue?</strong>
            </> :
            <>
              <p className={'sm-field-mb'}>{user && CONTENT()[user.active ? 'on' : 'off'].description}</p>
              <div className={'sm-field-mb'}>
                <ReviewUserTable user={user}/>
              </div>
              <p>If you perform this action:</p>
              <ul className={'list-disc ml-5 sm-field-mb'}>
                {user &&
                  CONTENT()[user.active ? 'on' : 'off'].actions.map((action: string, index: number) => (
                    <li key={`action--${index}`}>{action}</li>
                  ))}
              </ul>
              <strong>Are you sure you want to continue?</strong>
            </>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)}/>
        <Modal.ConfirmButton
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true)
            multi ?
              (updateUsersStatus.mutate({ teacher_ids: users.map((user: any) => user.id), status: !active }))
              :
              (updateUserStatus.mutate({ user_id: user?.id, status: !user?.active }))
            toast.success(`User${multi ? 's are ' : ' is '} being ${(multi && !active) || (user && !user?.active) ? '' : 'de'}activated, please wait a moment`)
          }}
          label={isLoading ? `${active ? 'Dea' : 'A'}ctivating...` : multi ? `${active ? 'Dea' : 'A'}ctivate users` : user && CONTENT()[user.active ? 'on' : 'off'].button}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default ActiveStateConfirmModal;
