import React from 'react';
import { DEFAULT_THEME } from '../../../Theme';
import Icon from '../../../global/icon/icon';

const ProgressBar = ({ progress, loading, cancel }: ProgressBarType) => {
  return (
    <>
      {progress === 100 && loading ? (
        <span>The video is being processed</span>
      ) : (
        <>
          <span>{progress}% complete </span>
          <span style={{ color: 'red' }}>Do not close this window</span>
        </>
      )}

      <div className={'inline-flex w-full'}>
        <div
          style={{
            height: 20,
            width: '100%',
            backgroundColor: '#e0e0de',
            borderRadius: 50,
          }}
        >
          <div
            style={{
              height: '100%',
              width: `${progress}%`,
              backgroundColor: DEFAULT_THEME.colors.primary,
              borderRadius: 'inherit',
              textAlign: 'center',
            }}
          >
            <span
              style={{
                padding: 5,
                color: 'white',
                fontWeight: 'bold',
              }}
            />
          </div>
        </div>
        <Icon
          onClick={() => cancel()}
          icon={'X'}
          color={'red'}
          elementSize={25}
          className={'cursor-pointer'}
          style={{ padding: 0, margin: 0 }}
        />
      </div>
    </>
  );
};

type ProgressBarType = {
  progress: number;
  loading: boolean;
  cancel: () => void;
};

export default ProgressBar;
