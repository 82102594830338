import connector from '../connector';
import Axios, { CancelTokenSource } from 'axios';

export async function postUploadVideo(data: {
  title: string;
  video: File;
  handleUploadProgress: (loaded: number, total: number) => void;
  source: CancelTokenSource;
}) {
  const { video, title, handleUploadProgress, source } = data;

  const response = await connector.post('api/veo/video', { title, mime_type: video.type });

  return Axios.put(response.data.data.url, video, {
    headers: {
      'Content-Type': video.type,
      'x-ms-blob-type': 'BlockBlob',
    },
    onUploadProgress: (e: ProgressEvent) => handleUploadProgress(e.loaded, e.total),
    cancelToken: source.token,
  });
}

export function getVideos(shared: boolean, search: string) {
  const params: { search: string; shared?: string } = { search };

  if (shared) {
    params.shared = 'true';
  }

  return connector.get('/api/veo/videos', { params });
}

export function getVideo(id: string) {
  return connector.get(`/api/veo/videos/${id}`);
}

export function getVideoComments(id: string) {
  return connector.get(`/api/veo/videos/${id}/comments`);
}

export function addOrEditVideoComment({ id, videoId, message }: { id?: number; videoId: string; message: string }) {
  if (id) {
    return connector.put(`/api/veo/videos/${videoId}/comments/${id}`, { message });
  }

  return connector.post(`/api/veo/videos/${videoId}/comments`, { message });
}

export function deleteVideoComment({ id, videoId }: { id: number; videoId: string; }) {
  return connector.delete(`/api/veo/videos/${videoId}/comments/${id}`);
}

export function deleteVideo(id: number) {
  return connector.delete(`/api/veo/videos/${id}`);
}

export function getVideoTags(id: string) {
  return connector.get(`/api/veo/videos/${id}/tags`);
}

export function getVideoShareOptions() {
  return connector.get('/api/veo/videos/share/options');
}

export function getVideoSharedWithOptions(videoId: number) {
  return connector.get(`/api/veo/videos/${videoId}/share/options`);
}

export function putShareVideos({
  videoId,
  individuals,
  groups,
  element
}: {
  videoId: number;
  individuals: Array<number>;
  groups: Array<number>;
  element: string
}) {
  return connector.put(`/api/veo/videos/${videoId}/share`, { individuals, groups, element });
}

export function getTagSets() {
  return connector.get('/api/veo/tag-sets');
}

export function getTagSet(tagSetId: number) {
  return connector.get(`/api/veo/tag-sets/${tagSetId}`);
}

export function postFeedbackSession(data: any) {
  return connector.post(`/api/veo/videos/${data.videoId}/feedback-session/${data.tagSetId}`, data);
}
