import React from 'react';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { Section } from '../../../common';
import AddButton from '../../components/addButton';
import useModal from '../../../../hooks/useModal';
import V3GoalModal from '../../modals/v3/V3GoalModal';
import DeleteV3GoalModal from '../../modals/v3/DeleteV3GoalModal';
import { ActionButton } from '../../../../global/buttonIcon/common';
import Empty from '../../../../global/empty/empty';

export const GOAL_STATUS = {
  notStarted: 'not_started',
  behind: 'behind',
  archived: 'archived',
  inProgress: 'in_progress',
};

export const GOAL_STATUS_COLORS = {
  [GOAL_STATUS.notStarted]: 'gray',
  [GOAL_STATUS.behind]: 'red',
  [GOAL_STATUS.archived]: 'green',
  [GOAL_STATUS.inProgress]: 'yellow',
};

const V3GoalsSection = ({
  pathway,
  isDisabled,
  isFree,
}: {
  pathway: V3Pathway;
  isDisabled: boolean;
  isFree?: boolean;
}) => {
  const [goalModal, toggleGoalModal, setGoalModalProps] = useModal((props: any) => <V3GoalModal {...props} />);
  const [deleteGoalModal, toggleDeleteGoalModal, setDeleteGoalModalProps] = useModal((props: any) => (
    <DeleteV3GoalModal {...props} />
  ));

  const firstAvailableNumber = () => {
    let number = 0;

    for (const goal of pathway.goals ?? []) {
      if (goal.number == null) {
        continue;
      }

      if (goal.number - number > 1) {
        return number + 1;
      }

      number++;
    }

    return number + 1;
  };

  return (
    <div>
      <div className={'flex justify-between mb-2'}>
        <span className={'font-bold pr-5 py-1'}>Goals</span>
        {!isFree && (
          <AddButton
            onClick={() => {
              setGoalModalProps({
                title: 'Create goal',
                goal: {
                  number: firstAvailableNumber(),
                },
                pathwayId: pathway.id,
                preventCloseOnClickOnMask: true,
              });
              toggleGoalModal(true);
            }}
            label={'goal'}
            create
            hideLabel
            disabled={isDisabled}
          />
        )}
      </div>
      <Section>
        {pathway.goals?.length === 0 ? (
          <Empty element={'goals'} />
        ) : (
          pathway.goals?.map((goal) => (
            <div key={goal.id} className={'flex gap-3 mb-4'}>
              {/*<div*/}
              {/*  className={`w-4 h-4 bg-${GOAL_STATUS_COLORS[goal.status]}-400 mt-1 cursor-pointer`}*/}
              {/*  onClick={() => {*/}
              {/*    if (isDisabled) {*/}
              {/*      return;*/}
              {/*    }*/}

              {/*    setGoalModalProps({*/}
              {/*      title: 'Edit status',*/}
              {/*      description: goal.name,*/}
              {/*      goal: goal,*/}
              {/*      pathwayId: pathway.id,*/}
              {/*      onlyStatus: true,*/}
              {/*    });*/}
              {/*    toggleGoalModal(true);*/}
              {/*  }}*/}
              {/*/>*/}
              <div className={'w-full'}>
                <div className={'flex justify-between'}>
                  <p className={'font-bold'}>
                    {goal.number && `${goal.number}.`} {goal.name}
                  </p>
                  {!isFree && !goal.from_invitation && (
                    <div className={'flex'}>
                      <ActionButton.Edit
                        className={'m-auto'}
                        onClick={(e) => {
                          setGoalModalProps({
                            title: 'Edit goal',
                            description: goal.name,
                            goal: goal,
                            pathwayId: pathway.id,
                            preventCloseOnClickOnMask: true,
                          });
                          toggleGoalModal(true);
                        }}
                        disabled={isDisabled}
                      />
                      <ActionButton.Remove
                        className={'m-auto'}
                        onClick={(e) => {
                          setDeleteGoalModalProps({
                            title: 'Delete goal',
                            description: goal.name,
                            goal: goal,
                            pathwayId: pathway.id,
                          });
                          toggleDeleteGoalModal(true);
                        }}
                        disabled={isDisabled}
                      />
                    </div>
                  )}
                </div>
                <p>{goal.description}</p>
              </div>
            </div>
          ))
        )}
      </Section>
      {goalModal}
      {deleteGoalModal}
    </div>
  );
};

export default V3GoalsSection;
