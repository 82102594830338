import React, { useState } from 'react';
import Button from '../../../../../../global/button/button';
import Slide from './slide';
import '../../../../style/style.css';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useMutation, useQueryClient } from 'react-query';
import { updateProgressSelfStudy } from '../../../../../../service/api';
import { DRAFT_RESOURCES, RESOURCES_BY_SLUG } from '../../../../../../service/queryKeys';
import { toast } from 'react-toastify';
import {
  darkThemeBorderColor,
  darkThemeColor,
  darkThemeTextColor,
  lightThemeBorderColor,
  lightThemeColor,
  lightThemeTextColor,
} from '../resources/resources';
import Icon from '../../../../../../global/icon/icon';
import Tooltip from '../../../../../../global/tooltip/tooltip';

const Slider = ({
  pages,
  banner,
  progress,
  theme,
  fullPage,
}: {
  pages: any;
  banner?: string;
  progress?: number;
  theme?: string;
  fullPage?: boolean;
}) => {
  const trackFirstPage = (arr: any) => {
    if (arr) {
      for (let i = 0; i < arr?.length; i++) {
        if (arr[i].pageTrackings === null) {
          return i + 1;
        }
      }
    }
    return null;
  };

  const [currentPage, setCurrentPage] = useState(
    progress ? ([0, 100].includes(progress) ? 1 : trackFirstPage(pages) ?? pages?.length) : 1
  );
  const [direction, setDirection] = useState('next');

  const queryClient = useQueryClient();

  const updateProgress = useMutation(updateProgressSelfStudy, {
    onSuccess: () => {
      queryClient.invalidateQueries(DRAFT_RESOURCES);
      queryClient.invalidateQueries(RESOURCES_BY_SLUG);
    },
  });

  const handlePageChange = (desiredPage: number) => {
    if (desiredPage < currentPage) {
      setDirection('prev');
    } else if (desiredPage > currentPage) {
      setDirection('next');
    }
    setCurrentPage(desiredPage);
  };

  const buttonStyle = {
    fontSize: '12px',
    letterSpacing: '1px',
    fontWeight: 600,
    borderRadius: '12px',
    padding: '6px',
  };
  return (
    <>
      <div className={'rounded-md'}>
        <div
          style={{
            height: fullPage ? '500px' : '400px',
            backgroundColor: banner ?? '#FFFFFF',
            borderColor: theme === 'light' ? lightThemeBorderColor : darkThemeBorderColor,
          }}
          className={'slider-container pr-1 pt-1 border-2 rounded-md'}
        >
          <TransitionGroup component={null}>
            {pages?.map((page: any, index: number) =>
              index + 1 === currentPage ? (
                <CSSTransition
                  key={`${index}-${direction}`}
                  timeout={500}
                  classNames={direction === 'next' ? `slide-next` : 'slide-prev'}
                >
                  <Slide key={page} page={page} />
                </CSSTransition>
              ) : null
            )}
          </TransitionGroup>
        </div>

        <div
          className="w-full sm:flex grid grid-rows-3 py-1.5 sm:px-10 md:justify-between gap-2 sm:gap-0"
          style={{ backgroundColor: theme === 'light' ? lightThemeColor : darkThemeColor }}
        >
          <div className="flex w-full lg:w-1/3">
            <Button
              style={buttonStyle}
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
              size="xsm"
              className={`mx-auto customButtonHover ${currentPage === 1 ? 'disabledButtonHover ' : ''}`}
              addOutline={theme === 'light'}
              isOutline={theme === 'light'}
            >
              Back
            </Button>
          </div>

          <div className={'sm:w-4/5 md:w-full flex'}>
            <p
              style={{ color: theme === 'light' ? lightThemeTextColor : darkThemeTextColor }}
              className={'m-auto text-semibold '}
            >
              Page {currentPage} of {pages?.length}
            </p>
          </div>

          <div className="w-full lg:w-1/3 flex">
            <Button
              color={theme === 'light' ? 'primary' : 'secondary'}
              addOutline={theme === 'light'}
              isOutline={theme === 'light'}
              style={buttonStyle}
              disabled={
                currentPage === pages?.length &&
                pages[currentPage - 1]?.pageTrackings &&
                pages[currentPage - 1].pageTrackings.completed
              }
              onClick={() => {
                if (pages[currentPage - 1]?.pageTrackings && pages[currentPage - 1]?.pageTrackings.completed === true) {
                  /*Do nothing*/
                } else updateProgress.mutate(pages[currentPage - 1]?.id);
                if (currentPage != pages?.length) handlePageChange(currentPage + 1);
                else toast.success('Yaay! You completed the course!');
              }}
              size="xsm"
              className={`mx-auto customButtonHover ${
                currentPage === pages?.length &&
                pages[currentPage - 1]?.pageTrackings &&
                pages[currentPage - 1].pageTrackings.completed
                  ? 'disabledButtonHover '
                  : ''
              }`}
            >
              {currentPage === pages?.length &&
              pages[currentPage - 1]?.pageTrackings &&
              pages[currentPage - 1].pageTrackings.completed
                ? 'Completed'
                : currentPage === pages?.length
                ? 'Finish'
                : 'Next'}
            </Button>
          </div>
          {pages[currentPage - 1]?.pageTrackings && pages[currentPage - 1].pageTrackings.completed && (
            <Tooltip content={'Page completed'}>
              <Icon
                style={{
                  color: theme === 'light' ? lightThemeTextColor : darkThemeTextColor,
                  borderColor: theme === 'light' ? lightThemeBorderColor : darkThemeTextColor,
                }}
                icon={'Check2Circle'}
                color={theme === 'light' ? 'green' : 'white'}
                elementSize={30}
                className={'p-0 ml-auto my-auto'}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </>
  );
};
export default Slider;
