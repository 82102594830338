import React, { InputHTMLAttributes } from 'react';
import { FieldProps, LabelProps } from '../common/field.types';
import Input from '../input/input';
import ButtonIcon from '../../global/buttonIcon/buttonIcon';
import { GLOBAL_ICONS } from '../../resources/vars';

interface SearchProps extends FieldProps, LabelProps {
  /**
   * Function triggered when click on remove button.
   */
  onRemove: () => void;
}

const Search = ({ id, label, hideLabel, onRemove, ...other }: SearchProps & InputHTMLAttributes<HTMLInputElement>) => (
  <Input
    icon={'Search'}
    type={'search'}
    id={id}
    label={label}
    hideLabel={hideLabel}
    component={
      <ButtonIcon
        onClick={onRemove}
        label={'Clear'}
        icon={GLOBAL_ICONS.close}
        isOutline
        isFree
        size={'sm'}
        className={'my-auto mr-1'}
        disabled={'value' in other && !other.value}
      />
    }
    {...(other as any)}
  />
);

export default Search;
