import React, { useState } from 'react';

import Selector from "../../../form/select/selector";
import Button from "../../../global/button/button";
import { useQuery } from "react-query";
import { THINKIFIC_SSO } from "../../../service/queryKeys";
import { getThinkificSSO } from "../../../service/api";
import { THINKIFIC_LINKS } from "../../../service/links";

const StartRelatedCourse = ({
  disabled = false,
  courses
}: { disabled?: boolean, courses: Array<CourseOption> }) => {
  const [option, setOption] = useState<CourseOption|null>()

  const thinkificSSO = useQuery(THINKIFIC_SSO, () => getThinkificSSO(), {
    select: (data: any) => data.data.data.link,
    refetchInterval: 120000, //2 min
    refetchIntervalInBackground: true
  });

  return (
    <div className={'flex align-middle gap-5'}>
    <span className={'font-bold py-1'}>
      Start with a related course:
    </span>
      <Selector
        className={'lg:w-2/6 w-full'}
        id={'related-courses-selector'}
        options={courses}
        isDisabled={disabled}
        onChange={(option: CourseOption) => setOption(option)}
        value={option}
      />
      <Button
        size={"sm"}
        onClick={() => {
          if (!option || !thinkificSSO.isFetched) {
            return;
          }

          window.open(
            thinkificSSO.data + option.link,
            '_blank'
          );

          setOption(null)
        }}
        disabled={disabled || null == option}
      >
        Start
      </Button>
    </div>
  )
};

type CourseOption = { label: string, value: string, link: string }

export default StartRelatedCourse;
