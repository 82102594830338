import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { Section } from "../../common";
import NotesModal from "./notesModal";
import SchoolDialsModal from "./schoolDialsModal";

const SchoolMoreInfoModal = ({
  user,
  moreInfo,
  userName
}: ModalToggleProps & { user: any, moreInfo: any, userName: any }) => {
  return (
    <>
      <React.Fragment>
        {/* Content */}
        <Section>
          {moreInfo === 'notes' &&
              <NotesModal school_id={user.id} user={user} userName={`${userName.first_name} ${userName.last_name}`}
                          title={'Notes'} notes={user.notes}/>
          }
          {moreInfo === 'dials' &&
              <SchoolDialsModal user={user}/>
          }
        </Section>
      </React.Fragment>
    </>
  );
};

export default SchoolMoreInfoModal;
