import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import routeBuilder, { PathType } from '../service/routeBuilder';
import { RouterStyled } from '../global/link/link.styled';
import { HeaderContent, FooterContent, AsideContent } from '../pages/common/index';
import Icon from '../global/icon/icon';
import Alert from '../global/alert/alert';
import PHProvider from '../posthog/PostHogProvider';
import PostHogPageView from '../posthog/pageview';

export type DashboardLayoutType = {
  /**
   * Page title.
   */
  title?: string | JSX.Element;
  /**
   * Page subtitle.
   */
  subtitle?: React.ReactNode;
  /**
   * Sets a global action into the page.
   */
  pageAction?: React.ReactNode;
  /**
   * Sets a parent page in the breadcrumb.
   */
  parent?: { id: PathType; label: string };
  /**
   * Page content.
   */
  children: React.ReactNode;

  isMockedPage?: boolean;
  mockedAlert?: string;
  hideTitle?: boolean;
  showOnlyOnSmall?: boolean;
};

const MIN_WIDTH = 50;

const DashboardLayout = ({
  title,
  subtitle,
  pageAction,
  parent,
  children,
  isMockedPage,
  mockedAlert,
  hideTitle = false,
  showOnlyOnSmall,
}: DashboardLayoutType): JSX.Element => {
  const location = useLocation();

  return (
    <div className={'flex flex-1'}>
      <AsideContent version={'dark'} responsiveWidth={MIN_WIDTH}/>
      <ContentContainer className={'flex flex-col flex-1'}>
        <header className={'bg-white shadow'}>
          <HeaderContent/>
        </header>
        <main className={'flex-1 block-container'}>
          {isMockedPage && mockedAlert && (
            <Alert type="info" className={'field-mb'}>
              {mockedAlert}
            </Alert>
          )}
          {!isMockedPage && '/' !== location.pathname && (
            <div className={'text-sm text-muted field-mb'}>
              <ul className={'flex items-center'}>
                <li>
                  <RouterStyled to={routeBuilder('home').generate()}>Dashboard</RouterStyled>
                </li>
                {parent && (
                  <li className={'flex items-center'}>
                    <Icon icon={'ChevronDoubleRight'}/>
                    <RouterStyled to={routeBuilder(parent.id).generate()}>{parent.label}</RouterStyled>
                  </li>
                )}
                <li className={'flex items-center'}>
                  <Icon icon={'ChevronDoubleRight'}/>
                  {title}
                </li>
              </ul>
            </div>
          )}
          {!isMockedPage && !hideTitle && (
            <section>
              <div className={`flex justify-between items-center mb-10 ${showOnlyOnSmall ? 'md:hidden' : ''}`}>
                <div>
                  <h2>{title}</h2>
                  {subtitle}
                </div>
                {pageAction && pageAction}
              </div>
            </section>
          )}
          <section>{children}</section>
        </main>
        {!isMockedPage && (
          <footer className={'bg-white'}>
            <FooterContent showLogo={false} dark={false}/>
          </footer>
        )}
      </ContentContainer>
    </div>
  );
};

const ContentContainer = styled('div')`
    margin-left: ${MIN_WIDTH}px;
    width: calc(100% - ${MIN_WIDTH}px);
    @media (min-width: 768px) {
        margin-left: 0;
        width: 75%;
    }
    @media (min-width: 1440px) {
        width: 85%;
    }
`;

export default DashboardLayout;
