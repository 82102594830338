import React, { useState } from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { useQuery } from 'react-query';
import { getTagSet, getTagSets } from '../../../service/api';
import { VIDEO_TAGSETS } from '../../../service/queryKeys';
import Button from '../../../global/button/button';
import { TagType } from '../components/TagIcon';

const TagSetsModal = ({
  toggle,
  setTags,
  setTagSetId,
}: ModalToggleProps & { setTagSetId: (id: number) => void; setTags: (tags: TagType[]) => void }) => {
  const [isLoading, setLoading] = useState(false);

  const { data: tagSets } = useQuery(VIDEO_TAGSETS, getTagSets, {
    select: (data) => data.data.data,
    placeholderData: [],
    staleTime: Infinity,
  });

  //temporarily sorting the dimensions in the tag sets modal
  tagSets.sort((a: { id: number; name: string }, b: { id: number; name: string }) => {
    const nameA = a.name.toUpperCase().trim();
    const nameB = b.name.toUpperCase().trim();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  return (
    <Modal>
      <Modal.Body>
        <div className="container grid grid-cols-1 gap-2">
          {tagSets.map((tagSet: { id: number; name: string }) => (
            <Button
              id={`tagSet_${tagSet.id}`}
              key={Math.random()}
              disabled={isLoading}
              onClick={async () => {
                try {
                  setLoading(true);
                  const response = await getTagSet(tagSet.id);
                  setTagSetId(tagSet.id);
                  setTags(response.data.data);
                  setLoading(false);
                  toggle(false);
                } catch (e) {
                  setLoading(false);
                }
              }}
            >
              {tagSet.name}
            </Button>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} />
      </Modal.Footer>
    </Modal>
  );
};

export default TagSetsModal;
