import React, { useState } from 'react';
import { UserType } from '../userManagementPage';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { DefaultOptionType } from '../../../resources/types';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';
import Selector from '../../../form/select/selector';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { SCHOOLS_GROUPS, USER_MANAGEMENT_LIST } from '../../../service/queryKeys';
import { getSchoolGroups, postTeacherMembersGroups } from '../../../service/api';
import { toast } from 'react-toastify';

const AddToTeamModal = ({
  users,
  toggle,
  onSuccess,
}: ModalToggleProps & { users: Array<UserType>; onSuccess: () => void }) => {
  const [teams, setTeams] = useState<Array<DefaultOptionType>>([]);
  const queryClient = useQueryClient();

  const { data: teamOptions } = useQuery(
    [ SCHOOLS_GROUPS ],
    () => getSchoolGroups(),
    {
      staleTime: Infinity,
      select: (data) => data.data.data.map((group: any) => ({ label: group.name, value: group.id })),
      placeholderData: [],
    },
  );

  const addUsersToTeamsMigration = useMutation(postTeacherMembersGroups, {
    onSuccess: () => {
      queryClient.invalidateQueries([USER_MANAGEMENT_LIST]);
      toast.success('Users successfully added to the team(s)');
      onSuccess()
      toggle(false);
    },
    onError: () => {
      toast.error('An error has occurred');
    },
  });

  return (
    <Modal>
      <Modal.Body>
        <Selector
          id={'teams'}
          label={'Teams'}
          placeholder={'Please select the teams'}
          required
          options={teamOptions}
          isMulti
          value={teams}
          onChange={(options: Array<DefaultOptionType>) => setTeams(options)}
          isCreatableSelect
          customComponents={{
            MenuList: function MenuList(menuListProps) {
              return (
                <MessageAtMenuList
                  message={"Don't see your team? Add it by populating the field above."}
                  {...menuListProps}
                />
              );
            },
          }}
          isClearable={false}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} label={'Close'} />
        <Modal.ConfirmButton
          label={
            addUsersToTeamsMigration.isLoading
              ? 'Loading...'
              : `Add user${1 < users.length ? 's' : ''} to team${1 < teams.length ? 's' : ''}`
          }
          disabled={teams.length === 0 || addUsersToTeamsMigration.isLoading}
          onClick={() => addUsersToTeamsMigration.mutate({ userIds: users.map((user) => user.id), groups: teams })}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default AddToTeamModal;
