import React from 'react';
import { ColumnType } from '../../../global/table/table.types';
import useModal from '../../../hooks/useModal';
import DefaultTable from '../../../global/table/defaultTable';
import Button from '../../../global/button/button';
import { ConfirmLeaveGroupModal } from '../modals';
import { useMutation, useQueryClient } from 'react-query';
import { postTeacherMemberGroup } from '../../../service/api';
import { JOINED_GROUPS, ME, MY_GROUPS, PATHWAYS_V2, SCHOOLS_GROUPS } from '../../../service/queryKeys';
import { Group } from "../../../@types/Entity/Group";

const COLUMNS: ColumnType[] = [
  { id: 'name', label: 'Team name', isSortable: true },
  { id: 'actions', label: 'Actions' },
];

const MyGroupsDetailsTable = ({ data, setOrder }: { data: Group[]; setOrder?: (s: string) => void }) => {
  const [modal, toggle, setProps] = useModal((props: any) => <ConfirmLeaveGroupModal {...props} />);

  const queryClient = useQueryClient();

  const mutation = useMutation(postTeacherMemberGroup, {
    onSuccess: () => {
      toggle(false);
      queryClient.invalidateQueries(PATHWAYS_V2);
      queryClient.invalidateQueries(SCHOOLS_GROUPS);
      queryClient.invalidateQueries(JOINED_GROUPS);
      queryClient.invalidateQueries(MY_GROUPS);
      queryClient.invalidateQueries(ME);
    },
  });

  return (
    <React.Fragment>
      {modal}
      <DefaultTable
        id={'my_groups'}
        apiHandled={setOrder != null}
        setApiSort={setOrder}
        showCounter={false}
        columns={COLUMNS}
        rows={
          0 === data?.length
            ? []
            : data.map((group, index) => {
                return {
                  id: `row_myGroup--${index}`,
                  cells: [
                    { id: 'name', content: group.name },
                    {
                      id: 'actions',
                      class: 'actions',
                      content: (
                        <ul className={'actions-list'}>
                          <li>
                            {group.joined ? (
                              <Button
                                asLink
                                size={'sm'}
                                mainColor={'danger'}
                                onClick={() => {
                                  setProps({
                                    groupId: group.id,
                                    title: 'Leave team',
                                    description: group.name,
                                    size: 'sm',
                                  });
                                  toggle(true);
                                }}
                              >
                                Leave
                              </Button>
                            ) : (
                              <Button
                                asLink
                                size={'sm'}
                                mainColor={'danger'}
                                onClick={() => {
                                  mutation.mutate({ groupId: group.id });
                                }}
                              >
                                Join
                              </Button>
                            )}
                          </li>
                        </ul>
                      ),
                    },
                  ],
                };
              })
        }
        noDataMessage={'You are not included in any teams yet.'}
      />
    </React.Fragment>
  );
};

export default MyGroupsDetailsTable;
