import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import Theme from '@app/component/src/Theme';
import Icon from '@app/component/src/global/icon/icon';
import { ToastStyled } from '@app/component/src/global/messages/toast/toast.styled';
import { QueryClient, QueryClientProvider } from 'react-query';
import CookieConsent from 'react-cookie-consent';
import { RegistrationProvider } from '@app/component/src/state/registration/provider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      // refetchOnMount: false,
    },
  },
});

ReactDOM.render(
  //<React.StrictMode>
  <Theme>
    <QueryClientProvider client={queryClient}>
      <RegistrationProvider>
        <Router>
          <Routes />
        </Router>
      </RegistrationProvider>
    </QueryClientProvider>
    <ToastStyled closeButton={<Icon icon={'X'} container={false} />} />
    <CookieConsent
      style={{
        background: '#fff9d7',
        fontSize: '15px',
        color: '#999999',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        left: '0',
        position: 'fixed',
        width: '100%',
        zIndex: '999',
      }}
      buttonStyle={{
        background: '#999999',
        color: '#ffffff',
        border: '0',
        borderRadius: '0px',
        boxShadow: 'none',
        cursor: 'pointer',
        flex: '0 0 auto',
        padding: '5px 10px',
        margin: '15px',
        fontSize: '13px',
      }}
      buttonText={'Accept'}
      disableStyles={true}
    >
      We use cookies on this website. By using this website, you consent to this use as described in our{' '}
      <a
        href="https://evidencebased.education/privacy-policy"
        style={{ color: '#666666', fontWeight: 'bold' }}
        target={'_blank'}
      >
        Privacy Policy
      </a>
    </CookieConsent>
  </Theme>,
  //</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
