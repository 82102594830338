import React, { useEffect, useState } from 'react';
import { SelectOptionType } from '../../../service/types';
import { Step1Type, useRegistrationContext } from '../../../state/registration/provider';
import { ROLES } from '../../../resources/roles';
import * as Yup from 'yup';
import Button from '../../../global/button/button';
import Selector from '../../../form/select/selector';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormObserver from './FormObserver';

const Step1 = () => {
  const { step1, nextPage } = useRegistrationContext();
  const [step1Values] = useState<Step1Type>({ ...(step1 as Step1Type) });

  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    getValues,
  } = useForm({
    resolver: yupResolver(Yup.object().shape({
      role: Yup.string().required().nullable().label('Role'),
    })),
    defaultValues: { role: step1Values?.role ?? null },
  });

  return (
    <form
      className={'py-5'}
      onSubmit={handleSubmit(() => {
        // Values are handled in the FormObserver component inside the form
        nextPage();
      })}
    >
      <FormObserver step={'step1'} values={getValues()}/>
      <Controller
        control={control}
        render={({ field }) => (
          <Selector
            {...field}
            className={'field-mb'}
            id={'roles'}
            label={'Role'}
            options={ROLES}
            value={ROLES.find((role: SelectOptionType) => role.value === field.value)}
            onChange={(option: any) => field.onChange(option.value)}
            placeholder={'Please select a role'}
            required
            isSearchable={false}
            isClearable={false}
            error={errors.role?.message}
          />
        )}
        name={'role'}
      />
      <Button
        id="registrationForm_page1_submit"
        type="submit"
        className={'block w-full mt-5'}
      >
        {!isSubmitting ? 'Continue' : 'Loading...'}
      </Button>
    </form>
  );
};

export default Step1;
