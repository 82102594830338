import React, { useState } from 'react';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { Section } from '../../../common';
import dayjs from 'dayjs';
import classnames from 'classnames';
import Icon from '../../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../../resources/vars';
import { ActionButton } from '../../../../global/buttonIcon/common';
import AddButton from '../../components/addButton';
import useModal from '../../../../hooks/useModal';
import V3PathwayLogModal from '../../modals/v3/V3PathwayLogModal';
import DeleteV3PathwayLogModal from '../../modals/v3/DeleteV3PathwayLogModal';
import { PathwayLog } from '../../../../@types/Entity/PathwayLog';
import { convertFromRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { processedElementData } from '../../../feedback/sections/elementChartSection';
import { processedSummaryData } from '../../../feedback/sections/summaryChartSection';
import CustomBarChart from '../../../feedback/components/customBarChart';
import routeBuilder from '../../../../service/routeBuilder';
import { downloadV3PathwayResource } from '../../../../service/api';
import { LinkStyled } from '../../../../global/link/link.styled';

const V3TimelineSection = ({
  pathway,
  isDisabled,
  isFree,
}: {
  pathway: V3Pathway;
  isDisabled: boolean;
  isFree?: boolean;
}) => {
  const [openId, setOpenId] = useState<string | null>();

  const [timelineModal, toggleTimelineModal, setTimelineModalProps] = useModal((props: any) => (
    <V3PathwayLogModal {...props} />
  ));

  const [deleteTimelineModal, toggleDeleteTimelineModal, setDeleteTimelineModalProps] = useModal((props: any) => (
    <DeleteV3PathwayLogModal {...props} />
  ));

  return (
    <div>
      <div className={'flex justify-between mb-2'}>
        <span className={'font-bold pr-5 py-1'}>Timeline</span>
        {!isFree && (
          <AddButton
            onClick={() => {
              setTimelineModalProps({
                title: 'Add timeline',
                pathway: pathway,
                preventCloseOnClickOnMask: true,
              });

              toggleTimelineModal(true);
            }}
            label={'to timeline'}
            hideLabel
            disabled={isDisabled}
          />
        )}
      </div>
      <Section>
        <ol className="relative border-l border-black ml-10">
          {pathway.logs.map((log, index) => {
            const month =
              index === 0 ||
              (null != pathway.logs[index - 1] &&
                dayjs(log.date).format('MMM') != dayjs(pathway.logs[index - 1].date).format('MMM'))
                ? dayjs(log.date).format('MMM')
                : null;

            return (
              <React.Fragment key={log.id}>
                {month && (
                  <li className="ml-4">
                    <time className="absolute -left-11 mb-1 text-sm font-normal leading-none">
                      {month.toUpperCase()}
                    </time>
                    <div className="absolute w-3 h-3 border-t border-black mt-1.5 -left-3" />
                    <br />
                  </li>
                )}
                <li className="mb-10 ml-4">
                  <time className="absolute -left-10 mb-1">
                    <div className={'w-8 text-center'}>
                      <p className={'text-xs'}>{dayjs(log.date).format('ddd').toUpperCase()}</p>
                      <p className={'text-lg font-bold'} style={{ marginTop: '-5px' }}>
                        {dayjs(log.date).format('DD')}
                      </p>
                    </div>
                  </time>
                  <div className="absolute w-3 h-3 bg-black rounded-full mt-1.5 -left-1.5" />
                  <TimeLineElement
                    isFree={isFree}
                    pathway={pathway}
                    log={log}
                    isOpen={log.id === openId}
                    handleOpen={() => setOpenId((state) => (state === log.id ? null : log.id))}
                    handleDelete={() => {
                      setDeleteTimelineModalProps({
                        title: 'Delete timeline',
                        description: log.title,
                        pathway: pathway,
                        log: log,
                      });

                      toggleDeleteTimelineModal(true);
                    }}
                    handleEdit={() => {
                      setTimelineModalProps({
                        title: 'Add timeline',
                        description: log.title,
                        pathway: pathway,
                        log: log,
                        preventCloseOnClickOnMask: true,
                      });

                      toggleTimelineModal(true);
                    }}
                    isDisabled={isDisabled}
                  />
                </li>
              </React.Fragment>
            );
          })}
        </ol>
      </Section>
      {timelineModal}
      {deleteTimelineModal}
    </div>
  );
};

const TimeLineElement = ({
  pathway,
  log,
  isOpen = false,
  handleOpen,
  handleDelete,
  handleEdit,
  isDisabled,
  isFree,
}: TimeLineElementType) => (
  <div className={'border border-black rounded-md ml-5'}>
    <div
      className={classnames('rounded-md', { 'border-red-100': !isOpen, 'border-primary': isOpen })}
      style={{
        borderLeftWidth: '12px',
      }}
    >
      <div className={'cursor-pointer'}>
        <div onClick={handleOpen} className={'flex justify-between'}>
          <div className={'flex items-center item-container w-full'}>
            <h3 className="flex-grow text-lg font-semibold">
              {log.title}
              {log.file && (
                <>
                  {' - '}
                  <LinkStyled
                    href={'#'}
                    target={'_blank'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      downloadV3PathwayResource(pathway!.id, log.file!.id, log.file!.name);
                    }}
                  >
                    {log.file.name}
                  </LinkStyled>
                </>
              )}
            </h3>
            {log.notes && !isFree && (
              <ActionButton.Edit
                className={'m-auto'}
                onClick={(e) => {
                  e.stopPropagation();

                  handleEdit();
                }}
                disabled={isDisabled}
              />
            )}
            {(log.type === 'custom' || ['goal_added', 'goal_status_changed'].includes(log.action)) && !isFree && (
              <ActionButton.Remove
                className={'m-auto'}
                onClick={(e) => {
                  e.stopPropagation();

                  handleDelete();
                }}
                disabled={isDisabled}
              />
            )}
            {log.isCompleted && (
              <div className="flex italic justify-end gap-8">
                Completed
                <Icon icon={'CheckCircle'} color={'success'} elementSize={35} />
              </div>
            )}
            <Icon
              icon={GLOBAL_ICONS['open']}
              className={`transition duration-500 ease-in-out transform ${classnames({ 'rotate-180': isOpen })}`}
            />
          </div>
        </div>
        {isOpen && (
          <div className={'flex items-center item-container w-full'}>
            {log.notes && (
              <Editor
                toolbarHidden={true}
                toolbar={{
                  options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
                  inline: {
                    options: ['bold', 'italic', 'underline'],
                  },
                }}
                readOnly={true}
                editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(log.notes)))}
              />
            )}
            {log.reference?.chart && (
              <CustomBarChart
                data={
                  log.reference?.chart.element
                    ? processedElementData(log.reference?.chart.data, log.reference?.chart.element, [
                        log.reference?.chart.data.options[0].id,
                      ])
                    : processedSummaryData(
                        log.reference?.chart.data,
                        [log.reference?.chart.data.options[0].id],
                        log.reference?.chart.survey_version
                      )
                }
                dataKey={log.reference?.chart.element ? 'question' : 'element'}
                id={`summary_${log.reference.id}`}
              />
            )}
            {log.reference?.video && (
              <img
                src={log.reference.video.thumbnail_path}
                alt={log.reference.video.title}
                className={'w-full cursor-pointer'}
                onClick={() => {
                  window.open(
                    `${window.location.origin}${routeBuilder('videoPlayer').generate(log.reference!.video!.id)}`,
                    '_blank'
                  );
                }}
              />
            )}
          </div>
        )}
      </div>
    </div>
  </div>
);

type TimeLineElementType = {
  isOpen: boolean;
  handleOpen: () => void;
  handleDelete: () => void;
  handleEdit: () => void;
  log: PathwayLog;
  pathway: V3Pathway;
  isDisabled: boolean;
  isFree?: boolean;
};

export default V3TimelineSection;
