import connector from '../connector';

export function getMats() {
  return connector.get<{ data: Array<{ id: string; name: string; organisation: string }> }>('/api/admin/mats');
}

export function addSchoolToMat({ schoolId, matId }: { schoolId: number; matId: string }) {
  return connector.post(`/api/admin/mats/${matId}/schools/${schoolId}`);
}

export function getMatsSchools(matId: string) {
  return connector.get<{ data: Array<{ id: number; name: string; preventAutoDowngrade: boolean }> }>(
    `/api/admin/mats/${matId}/schools`
  );
}

export function preventMatSchoolCancellation({ matId, schoolIds }: { matId: string; schoolIds: number[] }) {
  return connector.post(`/api/admin/mats/${matId}/prevent-subscription-cancellation`, { school_ids: schoolIds });
}

export function enableDisableCoordinatorMentoring(id: string, action: 'enable' | 'disable') {
  return connector.post(`/api/admin/users/${id}/${action}-mentoring`);
}

export function enableDisableTeacherMentor(id: string, action: 'enable' | 'disable') {
  return connector.post(`/api/admin/users/${id}/${action}-mentor`);
}

export function createOrUpdateUsersFromCsv(csvFile: File): Promise<void> {
  const filesFormData = new FormData();

  filesFormData.append('csv', csvFile);

  return connector
    .post(`/api/admin/users-csv`, filesFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
  ;
}
