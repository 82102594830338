import React from 'react';
import { Formik } from 'formik';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Input from '../../../form/input/input';
import Selector from '../../../form/select/selector';
import * as Yup from 'yup';
import { SelectOptionType } from '../../../service/types';
import { YEAR_GROUPS } from '../../../resources/yearGroups';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';
import Modal from '../../../global/messages/modal/modal.components';
import { getCheckSchoolClassNameIsValid, getSubjects } from '../../../service/api';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import { SUBJECTS } from '../../../service/queryKeys';
import Alert from "../../../global/alert/alert";

const getCheckSchoolClassNameIsValidDebounced = debounce(async (value, idToIgnore, resolve) => {
  try {
    const response = await getCheckSchoolClassNameIsValid(value as string, idToIgnore);
    resolve(response.data.data.valid);
  } catch (error) {
    resolve(false);
  }
}, 500);

const SCHEMA = Yup.object().shape({
  name: Yup.string()
    .required()
    .max(32)
    .trim()
    .test(
      'exists',
      'You already have a class with this name, please specify a different class name.',
      (value, input) =>
        new Promise((resolve) => {
          if (null == value || '' === value) {
            return true;
          }

          getCheckSchoolClassNameIsValidDebounced(value, input.parent?.id ?? null, resolve);
        })
    ),
  subject: Yup.string().required().max(32).trim(),
  year_group: Yup.string().required().max(32).trim(),
  additional_info: Yup.string().trim(),
});
const ClassFormModal = ({
  classToEdit,
  toggle,
  mutation,
}: ModalToggleProps & { classToEdit: Record<any, any>; mutation: any }) => {
  const { data: subjects } = useQuery(SUBJECTS, getSubjects, {
    staleTime: Infinity,
    select: (data) => data.data.data,
    placeholderData: [],
  });

  return (
    <Formik
      initialValues={{
        id: undefined,
        name: '',
        subject: '',
        year_group: '',
        additional_info: '',
        ...classToEdit,
      }}
      enableReinitialize
      validationSchema={SCHEMA}
      isInitialValid={null != classToEdit?.id}
      onSubmit={(values) => {
        mutation.mutateAsync(values).then(() => toggle(false));
      }}
    >
      {({ values, errors, handleChange, handleSubmit, isValid, setFieldValue }) => (
        <Modal>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <Alert className={'mb-8'}>
                Any classes you create will only appear in your own GTT account.
              </Alert>
              <div className="mb-8">
                <Input
                  id="name"
                  label="Class Name"
                  placeholder="Please enter a unique name for the class - for example: 7X/MA1- 20/21"
                  required
                  value={values.name}
                  onChange={handleChange}
                  error={errors.name}
                />
              </div>
              <div className="mb-8">
                <Selector
                  id="subject"
                  label="Class Subject"
                  placeholder="Please select a subject"
                  required
                  options={subjects}
                  isClearable={false}
                  value={values.subject ? { label: values.subject, value: values.subject } : null}
                  onChange={(option: SelectOptionType | null) => setFieldValue('subject', option ? option.value : null)}
                  isCreatableSelect
                  customComponents={{
                    MenuList: function MenuList(menuListProps) {
                      return (
                        <MessageAtMenuList
                          message={"Don't see your subject? Add it by populating the field above."}
                          {...menuListProps}
                        />
                      );
                    },
                  }}
                />
              </div>
              <div className="mb-8">
                <Selector
                  id="year_group"
                  label="Year Group"
                  placeholder="Please select a year group"
                  required
                  options={YEAR_GROUPS}
                  isClearable={false}
                  value={values.year_group ? { label: values.year_group, value: values.year_group } : null}
                  onChange={(option: SelectOptionType | null) =>
                    setFieldValue('year_group', option ? option.value : null)
                  }
                  isCreatableSelect
                  customComponents={{
                    MenuList: function MenuList(menuListProps) {
                      return (
                        <MessageAtMenuList
                          message={"Don't see your year group? Add it by populating the field above."}
                          {...menuListProps}
                        />
                      );
                    },
                  }}
                />
              </div>
              <div className="mb-8">
                <Input
                  id="additional_info"
                  label="Any other relevant information about this class"
                  placeholder="E.g. ability grouping/setting/tracking, single sex class"
                  value={values.additional_info}
                  onChange={handleChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)}/>
              <Modal.ConfirmButton
                type="submit"
                disabled={!isValid || mutation.isLoading}
                label={values.id ? 'Edit class' : 'Add class'}
              />
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </Formik>
  );
};

export default ClassFormModal;
