import React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { deleteActivity } from '../../../service/api';
import { DEVELOPMENT_ACTIVITIES } from '../../../service/queryKeys';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';

const DeleteV3GoalModal = ({ toggle, activityId }: ModalToggleProps & { activityId: string }) => {
  const queryClient = useQueryClient();

  const deleteActivityMutation = useMutation(deleteActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries([DEVELOPMENT_ACTIVITIES]);
      toggle(false);
    },
  });

  return (
    <Modal>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          deleteActivityMutation.mutate(activityId);
        }}
      >
        <Modal.Body>
          Are you sure you would like to delete this Development goal? This action can not be undone
        </Modal.Body>
        <Modal.Footer>
          <Modal.CancelButton onClick={() => toggle(false)} label={'Go back'} />
          <Modal.ConfirmButton
            label={deleteActivityMutation.isLoading ? 'Loading...' : 'Delete'}
            type={'submit'}
            disabled={deleteActivityMutation.isLoading}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteV3GoalModal;
