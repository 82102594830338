import React from 'react';
import Tooltip from '../tooltip';

type SliceTextProps = {
  value: string;
  maxLength?: number;
};

const SliceText = ({ value, maxLength = value.length }: SliceTextProps): JSX.Element => {
  if (maxLength < value.length) {
    return (
      <Tooltip content={value} theme={'light-border'}>
        <span>{value.slice(0, maxLength)}...</span>
      </Tooltip>
    );
  }
  return <>{value}</>;
};

export default SliceText;
