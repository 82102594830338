import React, { useState } from 'react';
import { Formik } from 'formik';
import { toast } from 'react-toastify';
import Button from '../../../global/button/button';
import Input from '../../../form/input/input';
import Collapse from '../../../global/transitions/collapse';
import { useMutation, useQueryClient } from 'react-query';
import { putMe } from '../../../service/api';
import * as Yup from 'yup';
import { Section } from '../../common';
import { ME } from '../../../service/queryKeys';

const PasswordUpdateForm = ({ showFormInitially = false, setDone }: { showFormInitially?: boolean; setDone?: any }) => {
  const queryClient = useQueryClient();

  const [showForm, setShowForm] = useState(showFormInitially);

  const meMutation = useMutation(putMe, {
    onSuccess: () => {
      queryClient.invalidateQueries(ME);
      toast.success('Your password has been changed');
      setDone(true);
    },
  });

  return (
    <Section headline={'Password details'}>
      {!showForm && <Button onClick={() => setShowForm(!showForm)}>Change password</Button>}
      <Collapse when={showForm}>
        <Formik
          initialValues={{
            password: '',
            repeat_password: '',
          }}
          validateOnMount={true}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .matches(
                /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,255}$/,
                'Passwords must be a minimum of 8 characters in length and contain at least one letter and one number'
              )
              .required(''),
            repeat_password: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
          })}
          onSubmit={(values) => meMutation.mutate({ password: values.password })}
        >
          {({ values, isValid, handleChange, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
                <div className={'field-mb'}>
                  <Input
                    id="password"
                    label="Password"
                    placeholder={'Please enter your new password'}
                    required
                    value={values.password}
                    onChange={handleChange}
                    type={'password'}
                  />
                </div>
                <div className={'field-mb'}>
                  <Input
                    id="repeat_password"
                    label="Repeat Password"
                    placeholder={'Please repeat your new password'}
                    required
                    value={values.repeat_password}
                    onChange={handleChange}
                    type={'password'}
                  />
                </div>
              </div>
              <Button
                type={'submit'}
                className={'mt-5'}
                disabled={
                  values.repeat_password.length < 1 || values.password.length < 1 || !isValid || meMutation.isLoading
                }
              >
                {!meMutation.isLoading ? 'Update' : 'Loading...'}
              </Button>
            </form>
          )}
        </Formik>
      </Collapse>
    </Section>
  );
};

export default PasswordUpdateForm;
