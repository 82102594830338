import React, { useState } from 'react';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import { GLOBAL_ICONS } from '../../../resources/vars';
import Modal from '../../../global/messages/modal/modal.components';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import { logout } from '../../../service/auth';
import { ROUTE_ROLE_TEACHER } from '../../../resources/roles';

const UpgradeAccountModal = ({ toggle }: ModalToggleProps) => {
  const [option, setOption] = useState<string | null>(null);

  return (
    <Modal>
      <Modal.Body>
        <RadioGroupOnBlock
          required
          id={'upgrade_account_options'}
          label={'Options'}
          options={[
            { value: ROUTE_ROLE_TEACHER, label: 'GTT Starter -> Individual (£299 ex VAT)', icon: GLOBAL_ICONS.surveys },
          ]}
          handleChange={(value: string) => setOption(value)}
          optionChecked={option}
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.CancelButton onClick={() => toggle(false)} />
        <Modal.ConfirmButton
          disabled={option == null}
          type={'button'}
          label={'Proceed'}
          onClick={() => {
            window.location.href = `/register?r=${ROUTE_ROLE_TEACHER}&u=true`;
          }}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default UpgradeAccountModal;
