import React from 'react';
import { VideoType } from '../videoObservationPage';
import VideoThumbnail from '../components/VideoThumbnail';
import { Record } from '@styled-icons/bootstrap';

const SharedVideosTab = ({ videos, userId }: SharedVideosTabType) => {
  const groupVideos = (videos: Array<VideoType>) => {
    const groups: Record<string,
      {
        name: string;
        videos: Array<VideoType>;
      }> = {};

    for (const video of videos) {
      if (!video.shared_with || 0 === video.shared_with.length) {
        continue;
      }

      for (const sharedWith of video.shared_with) {
        const id = `${sharedWith.type}-${sharedWith.id === userId ? video.created_by.id : sharedWith.id}`;

        if (!groups[id]) {
          groups[id] = {
            name: sharedWith.id === userId ? video.created_by.name : sharedWith.name,
            videos: [video],
          };
          continue;
        }

        groups[id].videos.push(video);
      }
    }

    return Object.entries(groups);
  };

  return (
    <div>
      {groupVideos(videos).length > 0 ?
        groupVideos(videos).map(([id, group]) => (
          <div key={id} className={'border-b-2 p-2'}>
            <h3>{group.name}</h3>
            <div className={'mt-2 container grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-4 gap-10 mx-auto'}>
              {group.videos.map((video) => (
                <VideoThumbnail video={video} key={video.id} userId={userId}/>
              ))}
            </div>
          </div>
        )) :
        <p className={'text-md italic text-center'}>
          No shared videos
        </p>
      }
    </div>
  );
};

export default SharedVideosTab;

type SharedVideosTabType = {
  videos: Array<VideoType>;
  userId: string;
};
