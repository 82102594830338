import React from 'react';
import { V3Pathway } from '../../../../@types/Entity/V3Pathway';
import { Section } from '../../../common';
import AddButton from '../../components/addButton';
import dayjs from 'dayjs';
import Choice from '../../../../form/choice/choice';
import useModal from '../../../../hooks/useModal';
import V3ActionsModal from '../../modals/v3/V3ActionsModal';
import { useMutation, useQueryClient } from 'react-query';
import { completeV3PathwayAction } from '../../../../service/api';
import { PATHWAYS_V3 } from '../../../../service/queryKeys';
import Button from '../../../../global/button/button';
import Empty from '../../../../global/empty/empty';
import { toast } from 'react-toastify';

const V3ActionsSection = ({
  pathway,
  isDisabled,
  isFree,
}: {
  pathway: V3Pathway;
  isDisabled: boolean;
  isFree?: boolean;
}) => {
  const queryClient = useQueryClient();

  const V3ActionsMutation = useMutation(completeV3PathwayAction, {
    onSuccess: () => {
      toast.success('Action completed');
      queryClient.invalidateQueries([PATHWAYS_V3, pathway.id]);
    },
  });

  const [actionsModal, toggleActionModal, setActionModalProps] = useModal((props: any) => (
    <V3ActionsModal {...props} />
  ));

  return (
    <div>
      <div className={'flex justify-between mb-2'}>
        <span className={'font-bold pr-5 py-1'}>Actions</span>
        {!isFree && (
          <AddButton
            onClick={() => {
              setActionModalProps({
                title: 'Create actions',
                pathway: pathway,
                preventCloseOnClickOnMask: true,
              });

              toggleActionModal(true);
            }}
            label={'action'}
            create
            hideLabel
            disabled={isDisabled}
          />
        )}
      </div>
      <Section>
        {pathway.actions.length === 0 ? (
          <Empty element={'actions'} />
        ) : (
          pathway.actions?.map((action, index: number) => (
            <div className={'mb-6 border-2 border-gray-100 p-2'} key={index}>
              <div className={'flex gap-3 w-full justify-between'} key={action.id}>
                <div className={'flex gap-8'}>
                  <div className={'text-center'} style={{ minWidth: '25px' }}>
                    <p className={'text-xs'}>{dayjs(action.dueDate).format('ddd').toUpperCase()}</p>
                    <p className={'text-lg font-bold'} style={{ marginTop: '-5px' }}>
                      {dayjs(action.dueDate).format('DD')}
                    </p>
                  </div>
                  <p className={'text-2xl'}>{action.title}</p>
                </div>
                <div className={'flex gap-10'}>
                  {action.fromTeam && (
                    <span
                      style={{ fontSize: 10 }}
                      className={
                        'mt-1 mb-4 bg-gray-100 text-gray-800 px-1 max-h-4 my-1  rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500'
                      }
                    >
                      TEAM
                    </span>
                  )}
                  {!isFree && (
                    <Choice
                      type={'checkbox'}
                      id={`completed_checkbox_${action.id}`}
                      name={`completed_checkbox_${action.id}`}
                      label={'complete'}
                      hideLabel
                      onChange={() => {
                        V3ActionsMutation.mutate({ pathwayId: pathway.id, actionId: action.id });
                      }}
                      className={'pretty-no-margin'}
                      icon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-check"
                          viewBox="0 0 16 16"
                        >
                          <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                        </svg>
                      }
                      animation={'tada'}
                      disabled={isDisabled}
                    />
                  )}
                </div>
              </div>
              <p className={'ml-14'}>
                {action.tags.map((tag: any, index: number) => (
                  <span
                    key={index}
                    style={{ backgroundColor: '#f6c09d', fontSize: 12 }}
                    className={
                      'uppercase mx-0.5 text-gray-800 px-1 max-h-4 rounded dark:bg-gray-700 dark:text-gray-400 border border-yellow-600 text-yellow-900'
                    }
                  >
                    {tag.title}
                  </span>
                ))}
              </p>
            </div>
          ))
        )}
      </Section>
      {actionsModal}
    </div>
  );
};

export default V3ActionsSection;
