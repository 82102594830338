import React from 'react';
import WysiwygEditor from './WysiwigEditor';

const AddNotes = ({
  disabled = false,
  notes,
  save,
  description,
}: {
  disabled?: boolean;
  notes?: string;
  description?: string;
  save: (notes: string, callback?: () => void) => void;
}) => {
  return <WysiwygEditor disabled={disabled} save={save} value={notes} label={'Notes'} description={description} />;
};

export default AddNotes;
