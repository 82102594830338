import React, { useEffect, useState } from 'react';
import TopPanel from '../components/topPanel';
import Tab from '../../../global/tab/tab';
import { PathwaysIdentifyTab } from '../sections';
import { PathwaysTabButton } from '../components';
import PathwaysExploreTab from '../sections/pathwaysExploreTab';
import PathwaysFocusTab from '../sections/pathwaysFocusTab';
import PathwaysPrepareTab from '../sections/pathwaysPrepareTab';
import PathwaysIntegrateTab from '../sections/pathwaysIntegrateTab';
import PathwaysReviewTab from '../sections/pathwaysReviewTab';
import { useQuery } from 'react-query';
import { ME, PATHWAYS_V2 } from '../../../service/queryKeys';
import { getMe, getV2Pathways } from '../../../service/api';
import { useParams } from 'react-router-dom';
import Alert from '../../../global/alert/alert';
import { isLoggedIn } from '../../../service/auth';
import { ROLE_ASSISTANT_GT_COORDINATOR, ROLE_SCHOOL_COORDINATOR, ROLE_TEACHER } from '../../../resources/roles';
import SideTab from '../../../typeform/SideTab';

export const IDENTIFY_STEP = 'identify';
export const EXPLORE_STEP = 'explore';
export const FOCUS_STEP = 'focus';
export const PREPARE_STEP = 'prepare';
export const INTEGRATE_STEP = 'integrate';
export const REVIEW_STEP = 'review';

const PathwayV2View = () => {
  const [active, setActive] = useState(IDENTIFY_STEP);

  const { id: pathwaysId } = useParams();

  const { data: user } = useQuery(ME, getMe, {
    staleTime: Infinity,
    select: (data) => data.data,
    enabled: isLoggedIn(),
  });

  const userRole = user?.data.me.role;

  const { data: pathwaysV2, isFetching, isError, isFetched } = useQuery(
    [PATHWAYS_V2, pathwaysId],
    () => getV2Pathways(pathwaysId!),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
    }
  );

  useEffect(() => {
    if (pathwaysV2?.current_step != null) {
      setActive(pathwaysV2?.current_step);
    }
  }, [pathwaysV2]);

  if (!isFetched && isFetching) {
    return <>Loading...</>;
  }

  if (!pathwaysV2 || isError) {
    switch (userRole) {
      case ROLE_TEACHER:
        if (user?.data.me.school_user_id != null) {
          return (
            <Alert type={'warning'}>
              Before using Development cycles please ask your coordinator to set up your academic year.
            </Alert>
          );
        }
        return (
          <Alert type={'warning'}>
            Before using Development cycles please set up your academic year via the Setup page.
          </Alert>
        );
      case ROLE_SCHOOL_COORDINATOR:
      case ROLE_ASSISTANT_GT_COORDINATOR:
        return (
          <Alert type={'warning'}>
            Before using Development cycles please set up your academic year via the School planner page.
          </Alert>
        );
    }
    return <>Something happened.</>;
  }

  const isDisabled = (step: string) => {
    return true;
  };

  const isStartDisabled = (step: string) => {
    return true;
  };

  const isTopPanelDisabled = (step: string) => {
    return (
      pathwaysV2.current_step != null &&
      pathwaysV2.current_step !== step &&
      (pathwaysV2.steps[step] == null || pathwaysV2.steps[step].completed_at == null)
    );
  };

  return (
    <>
      <TopPanel
        pathwaysId={pathwaysV2.id}
        academicYear={pathwaysV2.academic_year}
        focusArea={pathwaysV2.focus_area}
        team={pathwaysV2.team?.name}
        currentStep={pathwaysV2.current_step}
        completionDate={pathwaysV2.completed_at}
      />
      <div className={'my-5'}>
        <SideTab formId={'aagZfHE5'} />
        <Tab
          items={[
            {
              value: IDENTIFY_STEP,
              content: <PathwaysTabButton disabled={isTopPanelDisabled(IDENTIFY_STEP)} label={'Identify'} />,
            },
            {
              value: EXPLORE_STEP,
              content: <PathwaysTabButton disabled={isTopPanelDisabled(EXPLORE_STEP)} label={'Explore'} />,
            },
            {
              value: FOCUS_STEP,
              content: <PathwaysTabButton disabled={isTopPanelDisabled(FOCUS_STEP)} label={'Focus'} />,
            },
            {
              value: PREPARE_STEP,
              content: <PathwaysTabButton disabled={isTopPanelDisabled(PREPARE_STEP)} label={'Prepare'} />,
            },
            {
              value: INTEGRATE_STEP,
              content: <PathwaysTabButton disabled={isTopPanelDisabled(INTEGRATE_STEP)} label={'Integrate'} />,
            },
            {
              value: REVIEW_STEP,
              content: <PathwaysTabButton disabled={isTopPanelDisabled(REVIEW_STEP)} label={'Review'} />,
            },
          ]}
          active={active}
          clickOnTab={(value: string) => setActive(value)}
          className={'border-b-2 flex justify-between flex-wrap '}
          optionsClassName={'w-5/12 lg:w-2/12 m-1 lg:m-0 px-2'}
        >
          <div className={'bg-white mt-3 p-3 mx-2'}>
            <Tab.Content id={IDENTIFY_STEP} active={active}>
              <PathwaysIdentifyTab
                disabled={isDisabled(IDENTIFY_STEP)}
                startDisabled={isStartDisabled(IDENTIFY_STEP)}
                team={pathwaysV2.team}
                completed={null != pathwaysV2.steps[IDENTIFY_STEP]?.completed_at}
                started={null != pathwaysV2.steps[IDENTIFY_STEP]?.start_date}
                saveStep={(data, callback) => null}
              />
            </Tab.Content>
            <Tab.Content id={EXPLORE_STEP} active={active}>
              <PathwaysExploreTab
                disabled={isDisabled(EXPLORE_STEP)}
                startDisabled={isStartDisabled(EXPLORE_STEP)}
                team={pathwaysV2.team}
                completed={null != pathwaysV2.steps[EXPLORE_STEP]?.completed_at}
                started={null != pathwaysV2.steps[EXPLORE_STEP]?.start_date}
                saveStep={(data, callback) => null}
              />
            </Tab.Content>
            <Tab.Content id={FOCUS_STEP} active={active}>
              <PathwaysFocusTab
                disabled={isDisabled(FOCUS_STEP)}
                startDisabled={isStartDisabled(FOCUS_STEP)}
                team={pathwaysV2.team}
                completed={null != pathwaysV2.steps[FOCUS_STEP]?.completed_at}
                started={null != pathwaysV2.steps[FOCUS_STEP]?.start_date}
                saveStep={(data, callback) => null}
              />
            </Tab.Content>
            <Tab.Content id={PREPARE_STEP} active={active}>
              <PathwaysPrepareTab
                disabled={isDisabled(PREPARE_STEP)}
                startDisabled={isStartDisabled(PREPARE_STEP)}
                team={pathwaysV2.team}
                completed={null != pathwaysV2.steps[PREPARE_STEP]?.completed_at}
                started={null != pathwaysV2.steps[PREPARE_STEP]?.start_date}
                saveStep={(data, callback) => null}
              />
            </Tab.Content>
            <Tab.Content id={INTEGRATE_STEP} active={active}>
              <PathwaysIntegrateTab
                disabled={isDisabled(INTEGRATE_STEP)}
                startDisabled={isStartDisabled(INTEGRATE_STEP)}
                completed={null != pathwaysV2.steps[INTEGRATE_STEP]?.completed_at}
                started={null != pathwaysV2.steps[INTEGRATE_STEP]?.start_date}
                saveStep={(data, callback) => null}
                team={pathwaysV2.team}
              />
            </Tab.Content>
            <Tab.Content id={REVIEW_STEP} active={active}>
              <PathwaysReviewTab
                disabled={isDisabled(REVIEW_STEP)}
                startDisabled={isStartDisabled(REVIEW_STEP)}
                completed={null != pathwaysV2.steps[REVIEW_STEP]?.completed_at}
                started={null != pathwaysV2.steps[REVIEW_STEP]?.start_date}
                saveStep={(data, callback) => null}
              />
            </Tab.Content>
          </div>
        </Tab>
      </div>
    </>
  );
};

export default PathwayV2View;
