import React, { useEffect } from 'react';
import { useRegistrationContext } from '../../../state/registration/provider';
import { ROLE_MAT_COORDINATOR, ROLE_TEACHER, ROLE_TEACHER_FREE } from '../../../resources/roles';
import MatForm from './sections/step4/MatForm';
import TeacherForm from './sections/step4/TeacherForm';

const Step4 = () => {
  const { step1 } = useRegistrationContext();

  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);
  }, []);

  switch (step1?.role) {
    case ROLE_MAT_COORDINATOR:
      return <MatForm />;
    case ROLE_TEACHER:
    case ROLE_TEACHER_FREE:
      return <TeacherForm />;
    default:
      throw new Error('No role defined');
  }
};

export default Step4;
