import React, { useState } from 'react';
import Button from '../../../global/button/button';
import GenerateFeedback from '../components/generateFeedback';
import AddNotes from '../components/addNotes';
import { Checkbox } from 'pretty-checkbox-react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { PATHWAYS_V2_STEP } from '../../../service/queryKeys';
import { REVIEW_STEP } from '../views/PathwayV2View';
import { getV2PathwaysStep } from '../../../service/api';
import WysiwygEditor, { Wysiwyg } from '../components/WysiwigEditor';
import dayjs from 'dayjs';
import useModal from '../../../hooks/useModal';
import CompletePathwaysModal from '../modals/CompletePathwaysModal';
import { toast } from 'react-toastify';
import AddReference from '../components/AddReference';
import { STUDENT_SURVEY_REFERENCE_TYPE, VIDEO_OBSERVATION_REFERENCE_TYPE } from '../modals/AddReferenceModal';

const WHAT_IS_NEXT_CONTINUE = 'continue';
const WHAT_IS_NEXT_COMPLETE = 'complete';

const PathwaysIntegrateTab = ({
  disabled,
  startDisabled,
  completed,
  saveStep,
  started,
}: {
  disabled: boolean;
  startDisabled: boolean;
  completed: boolean;
  started: boolean;
  saveStep: (
    data?: {
      strategy_feedback?: string;
      challenge_feedback?: string;
      completed_at?: string;
      notes?: string;
    },
    callback?: () => void
  ) => void;
}): JSX.Element => {
  const [whatIsNext, setWhatIsNext] = useState<string | null>();

  const [completePathwaysModal, toggleCompletePathwaysModal] = useModal(
    (props: any) => <CompletePathwaysModal {...props} />,
    {
      title: 'Development cycles completion',
    }
  );

  const { id: pathwaysId } = useParams();

  const { data: pathwaysStep } = useQuery(
    [PATHWAYS_V2_STEP, REVIEW_STEP, pathwaysId],
    () => getV2PathwaysStep(REVIEW_STEP, pathwaysId!),
    {
      select: (data) => data.data.data,
      staleTime: Infinity,
    }
  );

  const [editMode, setEditMode] = useState(!completed);

  disabled = disabled || !editMode;

  return (
    <div>
      {completePathwaysModal}
      <div className={'flex flex-col divide-y'}>
        <div className={'font-bold flex pathways-section gap-2 justify-between'}>
          <span className={'w-5/6'}>
            In this step, you can review the impact your selected strategy is having in your classroom. To help you with
            this, you might gather more feedback – perhaps using a student survey you used previously to see how
            responses have changed. When you&apos;re ready, decide whether to complete this development cycle and move
            on to a new area of Great Teaching, or to continue with this cycle, spending more time integrating or trying
            a different development strategy.
          </span>
          <Button
            id={`review_${completed ? 'edit' : 'start'}_step`}
            size={'md'}
            onClick={() => {
              if (completed) {
                setEditMode(true);
                return;
              }

              saveStep();
            }}
            disabled={startDisabled || (started ? editMode : !editMode)}
          >
            {completed ? 'Edit' : 'Start'} step
          </Button>
        </div>
        <div className={'pathways-section'}>
          <span className={'font-bold py-1 mr-5'}>Development focus:</span>
          <Wysiwyg readOnly={true} value={pathwaysStep?.challenge} />
        </div>
        <div className={'pathways-section'}>
          <GenerateFeedback disabled={disabled} />
        </div>
        <div className={'pathways-section'}>
          <AddReference
            disabled={disabled}
            step={REVIEW_STEP}
            options={[STUDENT_SURVEY_REFERENCE_TYPE, VIDEO_OBSERVATION_REFERENCE_TYPE]}
            references={pathwaysStep?.references}
          />
        </div>
        <div className={'pathways-section'}>
          <AddNotes
            disabled={disabled}
            notes={pathwaysStep?.notes}
            save={(notes, callback) => {
              saveStep({ notes }, callback);
            }}
            description={
              'What are the main messages from your feedback? Based on the feedback, what impact has the strategy had on your practice?'
            }
          />
        </div>
        <div className={'pathways-section'}>
          <WysiwygEditor
            disabled={disabled}
            save={(feedback, callback) => {
              saveStep({ strategy_feedback: feedback }, callback);
            }}
            value={pathwaysStep?.strategy_feedback}
            label={'Has your development strategy had the desired impact on your practice?'}
            buttonLabel={'Response'}
          />
        </div>
      </div>
      <div className={'pathways-section'}>
        <div className={'font-bold py-1 mr-5'}>What will you do next?:</div>
        <div>
          <Checkbox
            color={'primary'}
            disabled={disabled}
            value={WHAT_IS_NEXT_CONTINUE}
            checked={whatIsNext === WHAT_IS_NEXT_CONTINUE}
            onChange={(event) => {
              setWhatIsNext(event.target.value);
            }}
          >
            <span className={'font-bold'}>Continue</span> to add steps to this development cycle
          </Checkbox>
        </div>
        <div>
          <Checkbox
            color={'primary'}
            disabled={disabled}
            value={WHAT_IS_NEXT_COMPLETE}
            checked={whatIsNext === WHAT_IS_NEXT_COMPLETE}
            onChange={(event) => {
              setWhatIsNext(event.target.value);
            }}
          >
            <span className={'font-bold'}>Complete</span> this development cycle and start a new one (your work will be
            saved and this development cycle will reset)
          </Checkbox>
        </div>
        <div className={'mt-5 text-right'}>
          <Button
            id={`review${whatIsNext === WHAT_IS_NEXT_COMPLETE ? 'Complete' : 'Continue'}Button`}
            size={'sm'}
            onClick={() => {
              if (whatIsNext === WHAT_IS_NEXT_CONTINUE) {
                saveStep({ completed_at: dayjs().format('YYYY/MM/DD') }, () => {
                  setEditMode(false);
                  setWhatIsNext(null);
                });
                toast.success(
                  `Edit previous steps in this development cycle, then head back to Review when you're ready to complete.`,
                  { autoClose: 8000 }
                );
                return;
              }

              toggleCompletePathwaysModal(true);
            }}
            disabled={disabled || whatIsNext == null}
          >
            {whatIsNext === WHAT_IS_NEXT_COMPLETE ? 'Complete and Reset' : 'Save and continue'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PathwaysIntegrateTab;
