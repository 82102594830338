import React from 'react';
import classnames from 'classnames';
import Icon from '../../icon/icon';
import Tooltip from '../../tooltip/tooltip';
import { IconProps } from '../../icon/icon.types';

const GLOBAL_PROPS: Partial<IconProps> = {
  container: false,
  elementSize: 12,
};

type SortProps = {
  title: string;
  onClick(): void;
  order: number;
  disabled: boolean;
};

const ASC = -1;
const DESC = 1;

const Sort = ({ title, onClick, order = 0, disabled }: SortProps) => {
  return (
    <Tooltip content={`Sort by ${title}`}>
      <button
        disabled={disabled}
        className={`${classnames({ 'opacity-25 cursor-default': disabled })} py-1 px-2`}
        onClick={onClick}
      >
        <Icon icon={order === ASC ? 'CaretUpFill' : 'CaretUp'} color={'secondary'} {...GLOBAL_PROPS} />
        <Icon icon={order === DESC ? 'CaretDownFill' : 'CaretDown'} color={'secondary'} {...GLOBAL_PROPS} />
      </button>
    </Tooltip>
  );
};

Sort.displayName = 'table.sort';

export default Sort;
