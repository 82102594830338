import React from 'react';
import Modal from '../../../global/messages/modal/modal.components';
import { Formik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Selector, { YES_OR_NO_SELECTOR_OPTIONS } from '../../../form/select/selector';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  ACADEMIC_YEARS_WITH_PERIODS,
  ALL_PATHWAYS,
  DEVELOPMENT_ACTIVITIES,
  FOCUS_OPTIONS,
} from '../../../service/queryKeys';
import { getAllV2Pathways, getFocusOptions } from '../../../service/api';

import { components } from 'react-select';
import { getAcademicYears, postOrPutActivity } from '../../../service/api';
import Input from '../../../form/input/input';
import Datepicker from '../../../form/datepicker/datepicker';
import { toast } from 'react-toastify';
import { ActivityType } from '../components/developmentActivitiesTable';
import moment from 'moment';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';
import DragAndDropFile from '../../../form/dragAndDrop/DragAndDropFile';
import Label from '../../../form/common/label';
import DefaultTable from '../../../global/table/defaultTable';
import ButtonIcon from '../../../global/buttonIcon/buttonIcon';

export const PATHWAYS_STEP_TYPES = [
  { label: 'Identify', value: 'identify' },
  { label: 'Explore', value: 'explore' },
  { label: 'Focus', value: 'focus' },
  { label: 'Prepare', value: 'prepare' },
  { label: 'Integrate', value: 'integrate' },
  { label: 'Review', value: 'review' },
];

export const ACTIVITY_TYPES_OPTIONS = [
  { label: 'Action research', value: 'Action research' },
  { label: 'Classroom observation', value: 'Classroom observation' },
  { label: 'Coaching', value: 'Coaching' },
  { label: 'Conference', value: 'Conference' },
  { label: 'Mentoring', value: 'Mentoring' },
  { label: 'Professional reading', value: 'Professional reading' },
  { label: 'Qualification programme', value: 'Qualification programme' },
  { label: 'Team activity', value: 'Team activity' },
  { label: 'Training course', value: 'Training course' },
  { label: 'Whole-school session', value: 'Whole-school session' },
];

export const DEVELOPMENT_ACTIVITY_SCHEMA = Yup.object().shape({
  id: Yup.string().trim().nullable(),
  term: Yup.string().trim().nullable(),
  academic_year_id: Yup.string().nullable(),
  development_period_id: Yup.string().nullable(),
  is_pathway_activity: Yup.boolean().nullable(),
  pathway: Yup.string()
    .nullable()
    .when('is_pathway_activity', {
      is: (flag: boolean | null) => flag === true,
      then: Yup.string().required('Pathway is required'),
    }),
  pathway_step: Yup.string()
    .nullable()
    .when('is_pathway_activity', {
      is: (flag: boolean | null) => flag === true,
      then: Yup.string().required('Pathway step is required'),
    }),
  element: Yup.object({ label: Yup.string(), value: Yup.string() }).nullable(),
  activity: Yup.string().trim().required().max(500),
  hours: Yup.number().nullable(),
  started: Yup.date().required(),
  completed: Yup.date().nullable(),
  new_documents: Yup.array().test('file-size', 'The files you are attempting to upload exceed 6mb. Please remove some files from your upload to reduce the total upload size.', (documents) => {
    if ((documents ?? []).length === 0) {
      return true;
    }

    //6MB
    const maxSize = 6 * 1000 * 1000;
    let totalSize = 0;

    for (const doc of documents!) {
      totalSize += doc.size;
    }

    if (totalSize >= maxSize) {
      return false
    }

    return true
  }),
});

export const EDITABLE_FIELDS = {
  'Pathway step started': ['activity', 'hours'],
  'Pathway step completed': ['activity', 'hours'],
  'Development Strategy created': ['activity', 'hours'],
  'Action plan created': ['activity', 'hours'],
  'Continue with pathway': ['activity', 'hours'],
  'Video Observation': ['pathway', 'pathway_step', 'activity'],
  'GTT Course': ['pathway', 'pathway_step', 'activity', 'hours'],
};

export const isEditableField = (activity: FormikValues, field: string): boolean => {
  if (!activity.auto_logged) {
    return true;
  }

  switch (activity.activity_type) {
    case 'Pathway step started':
      return EDITABLE_FIELDS['Pathway step started'].some((editable) => editable === field);
    case 'Pathway step completed':
      return EDITABLE_FIELDS['Pathway step completed'].some((editable) => editable === field);
    case 'Development Strategy created':
      return EDITABLE_FIELDS['Development Strategy created'].some((editable) => editable === field);
    case 'Action plan created':
      return EDITABLE_FIELDS['Action plan created'].some((editable) => editable === field);
    case 'Continue with pathway':
      return EDITABLE_FIELDS['Continue with pathway'].some((editable) => editable === field);
    case 'GTT Course':
      return EDITABLE_FIELDS['GTT Course'].some((editable) => editable === field);
    case 'Video Observation':
      return EDITABLE_FIELDS['Video Observation'].some((editable) => editable === field);
    default:
      return false;
  }
};

const AddDevelopmentActivityModal = ({
  toggle,
  editActivity = null,
}: ModalToggleProps & { editActivity?: ActivityType | null }) => {
  const queryClient = useQueryClient();

  const pathwaysOptions = useQuery([ALL_PATHWAYS, null, 'v2'], () => getAllV2Pathways(undefined, 'v2'), {
    staleTime: Infinity,
    select: (data) =>
      data.data.data.map((pathway: any) => {
        return { label: 'Development cycle ' + pathway.number, value: pathway.id, pathway: pathway };
      }),
    placeholderData: [],
  });

  const handleActivityMutation = useMutation(postOrPutActivity, {
    onSuccess: () => {
      queryClient.invalidateQueries([DEVELOPMENT_ACTIVITIES]);
      toggle(false);
    },
  });

  const { data: elementOptions } = useQuery(FOCUS_OPTIONS, () => getFocusOptions(), {
    select: (data) => {
      const options = data.data.data;

      return [...options, { label: 'N/A', value: null }];
    },
    placeholderData: [],
    staleTime: Infinity,
  });

  const periodsWithAcademicYear = useQuery(
    [ ACADEMIC_YEARS_WITH_PERIODS ],
    () => getAcademicYears(),
    {
      staleTime: Infinity,
      select: (data) => {
        const developmentPeriods: Array<any> = [];

        data.data.map((academicYear: any) => {
          academicYear.development_periods.map((developmentPeriod: any) => {
            developmentPeriods.push({
              label: academicYear.name + ' - ' + developmentPeriod.name,
              value: academicYear.id + '_' + developmentPeriod.id,
              academic_year_id: academicYear.id,
              development_period_id: developmentPeriod.id,
            });
          });
        });

        return developmentPeriods;
      },
      placeholderData: [],
    },
  );

  return (
    <Modal>
      <Formik
        initialValues={{
          id: editActivity?.id ?? null,
          term:
            editActivity?.academic_year && editActivity?.development_period
              ? editActivity?.academic_year + '_' + editActivity.development_period
              : '',
          is_pathway_activity: editActivity?.pathway_number ? true : null,
          academic_year_id: editActivity?.academic_year ?? null,
          development_period_id: editActivity?.development_period ?? null,
          pathway: editActivity?.pathway ?? null,
          pathway_step: editActivity?.pathway_step_type ?? null,
          element: editActivity?.element ?? null,
          activity_type: editActivity?.activity_type ?? null,
          activity: editActivity?.activity ?? '',
          hours: editActivity?.hours ?? '',
          started: editActivity?.started ? moment(editActivity?.started, 'DD/MM/YYYY').toDate() : null,
          completed: editActivity?.completed ? moment(editActivity?.completed, 'DD/MM/YYYY').toDate() : null,
          auto_logged: editActivity?.auto_logged ?? false,
          documents: editActivity?.documents ?? [],
          new_documents: [],
        }}
        validationSchema={DEVELOPMENT_ACTIVITY_SCHEMA}
        onSubmit={(values) => {
          handleActivityMutation
            .mutateAsync(values)
            .then(() => toast.success(values.id ? 'Activity updated' : 'Activity created successfully'));
        }}
      >
        {({ values, errors, handleSubmit, isValid, setFieldValue, touched }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              {isEditableField(values, 'term') && (
                <div className="mb-8">
                  <Selector
                    id={'term'}
                    label={'Term'}
                    placeholder={'Please select a term...'}
                    value={periodsWithAcademicYear?.data?.find((option: any) => option.value === values.term)}
                    options={periodsWithAcademicYear.data}
                    onChange={(option: any) => {
                      setFieldValue('term', option.value);
                      setFieldValue('academic_year_id', option.academic_year_id);
                      setFieldValue('development_period_id', option.development_period_id);
                    }}
                    isClearable={false}
                    error={touched.term ? errors.term : null}
                  />
                </div>
              )}
              {isEditableField(values, 'is_pathway_activity') && (
                <div className="mb-8">
                  <Selector
                    id={'is_pathway_activity'}
                    label={'Would you like to associate this activity with a Development cycle?'}
                    placeholder={'Please select...'}
                    value={YES_OR_NO_SELECTOR_OPTIONS.find((option) => option.value === values.is_pathway_activity)}
                    options={YES_OR_NO_SELECTOR_OPTIONS}
                    onChange={(option: any) => setFieldValue('is_pathway_activity', option.value)}
                    isClearable={false}
                    error={errors.is_pathway_activity}
                  />
                </div>
              )}
              {values.is_pathway_activity && (
                <>
                  {isEditableField(values, 'pathway') && (
                    <div className="mb-8">
                      <Selector
                        id={'pathway'}
                        label={'Development cycle'}
                        placeholder={'Please select...'}
                        value={pathwaysOptions?.data?.find((option: any) => option.value === values.pathway)}
                        options={pathwaysOptions.data}
                        onChange={(option: any) => setFieldValue('pathway', option.value)}
                        isClearable={false}
                        required
                        error={touched.pathway ? errors.pathway : null}
                        customComponents={{
                          Option: function Option(optionProps: any) {
                            return (
                              <components.Option {...optionProps}>
                                <div className={'flex justify-between'}>
                                  <div>{optionProps.children}</div>
                                  <div>{optionProps?.data?.pathway?.academic_year}</div>
                                </div>
                              </components.Option>
                            );
                          },
                        }}
                      />
                    </div>
                  )}
                  {isEditableField(values, 'pathway_step') && (
                    <div className="mb-8">
                      <Selector
                        id={'pathway_step'}
                        label={'Step'}
                        placeholder={'Please select...'}
                        value={PATHWAYS_STEP_TYPES?.find((option: any) => option.value === values.pathway_step)}
                        options={PATHWAYS_STEP_TYPES}
                        onChange={(option: any) => setFieldValue('pathway_step', option.value)}
                        isClearable={false}
                        required
                        error={touched.pathway_step ? errors.pathway_step : null}
                      />
                    </div>
                  )}
                </>
              )}
              {isEditableField(values, 'element') && (
                <div className="mb-8">
                  <Selector
                    id={'element'}
                    label={'Element'}
                    placeholder={'Please select...'}
                    options={elementOptions}
                    onChange={(option: any) => setFieldValue('element', option)}
                    value={values.element}
                    isClearable={false}
                    error={touched.element ? errors.element : null}
                  />
                </div>
              )}
              {isEditableField(values, 'activity_type') && (
                <div className="mb-8">
                  <Selector
                    id={'activity_type'}
                    label={'Activity type'}
                    placeholder={'Please select...'}
                    isCreatableSelect
                    options={ACTIVITY_TYPES_OPTIONS}
                    onChange={(option: any) => setFieldValue('activity_type', option.value)}
                    value={
                      values.activity_type
                        ? ACTIVITY_TYPES_OPTIONS.find((type) => type.value === values.activity_type)
                        : null
                    }
                    isClearable={false}
                    error={touched.activity_type ? errors.activity_type : null}
                    customComponents={{
                      MenuList: function MenuList(menuListProps) {
                        return (
                          <MessageAtMenuList
                            message={"Don't see your activity type? Add it by populating the field above."}
                            {...menuListProps}
                          />
                        );
                      },
                    }}
                  />
                </div>
              )}
              <div className="mb-8">
                <Input
                  required
                  id={'activity'}
                  label={'Activity'}
                  type={'textarea'}
                  value={values.activity}
                  onChange={(event: any) => setFieldValue('activity', event.target.value)}
                  error={touched.activity ? errors.activity : null}
                />
              </div>
              {isEditableField(values, 'hours') && (
                <div className="mb-8">
                  <Input
                    id={'hours'}
                    label={'Hours'}
                    type={'number'}
                    value={values.hours}
                    onChange={(event: any) => setFieldValue('hours', event.target.value)}
                    error={touched.hours ? errors.hours : null}
                  />
                </div>
              )}
              {isEditableField(values, 'started') && (
                <div className="mb-8">
                  <Datepicker
                    required
                    id={'started'}
                    label={'Started'}
                    placeholder={'Please select a date'}
                    selected={values.started}
                    onChange={(date) => setFieldValue('started', date)}
                  />
                </div>
              )}
              {isEditableField(values, 'completed') && (
                <div className="mb-8">
                  <Datepicker
                    id={'completed'}
                    label={'Completed'}
                    placeholder={'Please select a date'}
                    selected={values.completed}
                    onChange={(date) => setFieldValue('completed', date)}
                  />
                </div>
              )}
              {isEditableField(values, 'documents') && (
                <>
                  <Label id={'documents'} label={'Documents'} />
                  <DragAndDropFile
                    files={values.new_documents as Array<File>}
                    setFile={(files) => {
                      setFieldValue('new_documents', files);
                    }}
                    text={'Click or drag and drop to add files'}
                    error={errors.new_documents ? errors.new_documents as string : null}
                  />
                  {(values.documents ?? []).length > 0 && (
                    <DefaultTable
                      id={'current_documents'}
                      columns={[
                        { id: 'document', label: 'Document' },
                        { id: 'actions', label: 'Actions' },
                      ]}
                      showCounter={false}
                      rows={values.documents.map((document) => ({
                        id: 'current_documents_rows',
                        cells: [
                          { id: 'document', content: <strong>{document.name}</strong> },
                          {
                            id: 'actions',
                            content: (
                              <ButtonIcon
                                onClick={() => {
                                  setFieldValue(
                                    'documents',
                                    values.documents.filter((doc) => doc.id !== document.id)
                                  );
                                }}
                                icon={'Trash'}
                                label={'Remove'}
                                mainColor={'danger'}
                              />
                            ),
                          },
                        ],
                      }))}
                    />
                  )}
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                type="submit"
                disabled={!isValid || handleActivityMutation.isLoading}
                label={
                  handleActivityMutation.isLoading ? 'Loading...' : editActivity?.id ? 'Edit Activity' : 'Add Activity'
                }
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddDevelopmentActivityModal;
