import React from 'react';
import { useQuery } from 'react-query';
import { DASHBOARD } from '../../../service/queryKeys';
import { getDashboard } from '../../../service/api';
import { SchoolSummary } from './index';
import { Section } from '../../common';
import Alert from '../../../global/alert/alert';

const MatSchoolSummary = ({ schoolId }: { schoolId?: string }) => {
  const { data, isFetched } = useQuery([DASHBOARD, schoolId], () => getDashboard(schoolId), {
    select: (data) => data.data.data,
    staleTime: Infinity,
  });

  if (!isFetched) {
    return <></>;
  }

  return (
    <Section
      className={'field-mb'}
      headline={
        <div>
          <div className={'text-sm'}>
            {data?.academic_year ? (
              `${data.academic_year.start_year}-${data.academic_year.end_year}`
            ) : (
              <i>No academic year set</i>
            )}
          </div>
        </div>
      }
    >
      {data ? (
        <SchoolSummary data={data} schoolId={schoolId} />
      ) : (
        <Alert type="info">This school doesn&apos;t have a great teaching coordinator yet</Alert>
      )}
    </Section>
  );
};

export default MatSchoolSummary;
