import React from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { addOrEditVideoComment } from '../../../service/api';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { VIDEO_COMMENTS } from '../../../service/queryKeys';
import Input from '../../../form/input/input';

const AddCommentModal = ({
  toggle,
  videoId,
  id,
  message,
  parent = undefined,
}: ModalToggleProps & { videoId: string; id?: number; message?: string; parent?: string }) => {
  const queryClient = useQueryClient();
  const addCommentMutation = useMutation(addOrEditVideoComment, {
    onSuccess: () => {
      toggle(false);
      queryClient.invalidateQueries(VIDEO_COMMENTS);
    },
  });

  return (
    <Modal>
      <Formik
        initialValues={{
          message: message ?? '',
        }}
        validationSchema={Yup.object({
          message: Yup.string().required().max(5000),
        })}
        isInitialValid={false}
        onSubmit={(values) => {
          addCommentMutation.mutate({ message: values.message, videoId, id });
        }}
      >
        {({ values, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="mb-8">
                <Input
                  id="message"
                  type={'textarea'}
                  placeholder="Leave a comment"
                  required
                  value={values.message}
                  onChange={handleChange}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                id={parent && `${parent}_addCommentModal_addButton`}
                type="submit"
                disabled={!isValid || addCommentMutation.isLoading}
                label={addCommentMutation.isLoading ? 'Loading...' : id ? 'Edit' : 'Add'}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddCommentModal;
