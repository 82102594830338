import React from 'react';
import { toast } from 'react-toastify';
import { FieldArray, Formik } from 'formik';
import { DefaultOptionType } from '../../../resources/types';
import { CycleTaskProps, StrategyProps } from '../pathwaysPage.types';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import Input from '../../../form/input/input';
import Selector from '../../../form/select/selector';
import Label from '../../../form/common/label';
import { DateRangePicker } from '../../../form/datepicker/common';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import { useQuery } from 'react-query';
import { getSchoolClasses } from '../../../service/api';
import { SCHOOL_CLASSES } from '../../../service/queryKeys';
import { AddButton } from '../../../global/button/common';
import { ActionButton } from '../../../global/buttonIcon/common';
import Datepicker from '../../../form/datepicker/datepicker';
import { SelectOptionType } from '../../../service/types';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';

const TASK_TYPES_OPTIONS = [
  {
    label: 'Plan',
    value: 'plan',
  },
  {
    label: 'Implement',
    value: 'implement',
  },
  {
    label: 'Evaluate',
    value: 'evaluate',
  },
  {
    label: 'Meeting',
    value: 'meeting',
  },
];

const DevelopmentCyclesModal = ({
  toggle,
  strategiesOptions,
  cycle,
}: ModalToggleProps & DevelopmentCyclesModalProps) => {
  const SCHEMA = Yup.object().shape({
    start_date: Yup.date().required(),
    end_date: Yup.date().required(),
    strategies: Yup.array().min(1),
    school_classes: Yup.array(),
    tasks: Yup.array()
      .min(1)
      .of(
        Yup.object().shape({
          date: Yup.string().trim().max(150).required(),
          name: Yup.string().trim().max(64).required(),
          type: Yup.string().trim().required(),
        })
      ),
    notes: Yup.string(),
  });

  const schoolClassesQuery = useQuery(SCHOOL_CLASSES, getSchoolClasses, {
    staleTime: Infinity,
    select: (data) => data.data.data.filter((schoolClass: any) => !schoolClass.archived),
    placeholderData: [],
  });

  return (
    <Modal>
      <Formik
        initialValues={{
          start_date: null,
          end_date: null,
          strategies: [],
          school_classes: [],
          tasks: [{ id: null, date: null, name: '', type: '' }],
          notes: '',
          ...cycle,
        }}
        validationSchema={SCHEMA}
        validateOnMount={true}
        onSubmit={() => {
          /* Deprecated form */
        }}
      >
        {({ values, setFieldValue, handleChange, handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              {/* Start/End date */}
              <DateRangePicker
                required
                start={values.start_date}
                end={values.end_date}
                onStartChange={(date) => setFieldValue('start_date', date)}
                onEndChange={(date) => setFieldValue('end_date', date)}
              />
              {/* Strategy/Strategies to focus on */}
              <div className={'field-mb'}>
                <Selector
                  required
                  isMulti
                  isSearchable={false}
                  id={'strategies'}
                  label={'Strategies to focus on'}
                  placeholder={'Please select one or more strategies'}
                  value={values.strategies.map((strategyId: string) => {
                    const strategy: any = strategiesOptions.find((s: any) => s.id === strategyId);
                    return {
                      value: strategy.id,
                      label: strategy.name,
                    };
                  })}
                  options={
                    strategiesOptions?.map((option: any) => {
                      return {
                        value: option.id,
                        label: option.name,
                      } as DefaultOptionType;
                    }) || []
                  }
                  onChange={(options: DefaultOptionType[]) => {
                    setFieldValue('strategies', options == null ? [] : options.map((option) => option.value));
                  }}
                  customComponents={{
                    MenuList: function MenuList(menuListProps) {
                      return (
                        <MessageAtMenuList
                          message={'To choose a strategy, please add one or more strategies in the Prepare step.'}
                          {...menuListProps}
                        />
                      );
                    },
                  }}
                />
              </div>
              <div className={'field-mb'}>
                <Selector
                  isMulti
                  isSearchable={false}
                  id={'school-classes'}
                  label={'Classes'}
                  placeholder={'Please select one or more classes'}
                  value={
                    schoolClassesQuery.isFetched
                      ? values.school_classes.map((schoolClassId: string) => {
                          const schoolClass: any = schoolClassesQuery.data.find((s: any) => s.id === schoolClassId);
                          return {
                            value: schoolClass.id,
                            label: schoolClass.name,
                          };
                        })
                      : []
                  }
                  options={
                    schoolClassesQuery.data.map((option: any) => {
                      return {
                        value: option.id,
                        label: option.name,
                      } as DefaultOptionType;
                    }) || []
                  }
                  onChange={(options: DefaultOptionType[]) => {
                    setFieldValue('school_classes', options == null ? [] : options.map((option) => option.value));
                  }}
                />
              </div>
              <FieldArray
                name={'tasks'}
                render={(arrayHelpers) => (
                  <div className={'field-mb'}>
                    <div className={'sm-field-mb'}>
                      <Label
                        required
                        label={'Tasks'}
                        id={'tasks'}
                        hintText={
                          <AddButton
                            isOutline
                            mainColor={'secondary'}
                            label={'Add task'}
                            onClick={() => arrayHelpers.push({ date: null, name: '', type: '' })}
                          />
                        }
                      />
                    </div>
                    {values.tasks.map((task: CycleTaskProps, index: number) => (
                      <div className={'flex flex-wrap'} key={Math.random()}>
                        <div className={'w-3/12 relative border flex items-center justify-center item-container'}>
                          <Datepicker
                            id={'start_date'}
                            placeholder={'Date'}
                            selected={values.tasks[index].date ? dayjs(values.tasks[index].date).toDate() : null}
                            minDate={new Date()}
                            value={
                              values.tasks[index].date
                                ? dayjs(values.tasks[index].date).format('DD/MM/YYYY')
                                : undefined
                            }
                            onChange={(date: Date) => {
                              setFieldValue(`tasks[${index}].date`, dayjs(date).format('YYYY/MM/DD'));
                            }}
                          />
                        </div>
                        <div className={'w-5/12 border item-container'}>
                          <Input
                            required
                            id={`tasks[${index}].name`}
                            label={`Task ${index + 1}`}
                            hideLabel
                            placeholder={'Name'}
                            onChange={handleChange}
                            value={values.tasks[index].name}
                          />
                        </div>
                        <div className={'w-3/12 border item-container'}>
                          <Selector
                            id={`tasks[${index}].type`}
                            isClearable={false}
                            placeholder={'Type'}
                            options={TASK_TYPES_OPTIONS}
                            value={TASK_TYPES_OPTIONS.find((option) => option.value === values.tasks[index].type)}
                            onChange={(option: SelectOptionType) =>
                              setFieldValue(`tasks[${index}].type`, option!.value)
                            }
                          />
                        </div>
                        <div className={'w-1/12 flex border item-container'}>
                          <ActionButton.Remove
                            disabled={0 === index && values.tasks.length <= 1}
                            className={'m-auto'}
                            onClick={() => arrayHelpers.remove(index)}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              />
              {/* Notes */}
              <Input
                type={'textarea'}
                id={'notes'}
                name={'notes'}
                label={'Notes'}
                placeholder={'Enter any notes about this cycle'}
                value={values.notes || ''}
                onChange={handleChange}
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton type={'submit'} label={cycle ? 'Update cycle' : 'Add cycle'} disabled={true} />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

type DevelopmentCyclesModalProps = {
  strategiesOptions: StrategyProps[];
  mutation?: any;
  periodId: string;
  cycle?: any;
  strategies: any;
};

export default DevelopmentCyclesModal;
