import { Section } from '../../common';
import DefaultTable from '../../../global/table/defaultTable';
import { ActionButton } from '../../../global/buttonIcon/common';
import { AddButton } from '../../../global/button/common';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { getMeetingsPathways } from '../../../service/api/groupsApi';
import { MEETINGS_PATHWAYS } from '../../../service/queryKeys';
import { MeetingPathway } from '../../../@types/Entity/MeetingPathway';
import MeetingPathwayFormModal from '../modals/MeetingPathwayFormModal';

export default function MeetingPathways() {
  const [meetingPathwayFormModalData, setMeetingPathwayFormModalData] = useState({
    isOpen: false,
    meetingPathwayToEdit: null as MeetingPathway | null,
  });

  const meetingsPathwaysQuery = useQuery([MEETINGS_PATHWAYS], () => getMeetingsPathways());

  if (!meetingsPathwaysQuery.isSuccess) {
    return null;
  }

  return (
    <>
      {meetingPathwayFormModalData.isOpen && (
        <MeetingPathwayFormModal
          toggle={() => setMeetingPathwayFormModalData({ isOpen: false, meetingPathwayToEdit: null })}
          meetingPathwayToEdit={meetingPathwayFormModalData.meetingPathwayToEdit}
        />
      )}

      <Section headline={'Pathways'} className={'field-mb'}>
        <DefaultTable
          id="pathways"
          showCounter={false}
          columns={[
            { id: 'title', label: 'Name' },
            { id: 'meetingTemplates', label: 'Templates' },
            { id: 'actions', label: 'Actions' },
          ]}
          rows={
            0 < meetingsPathwaysQuery.data.data.length
              ? meetingsPathwaysQuery.data.data.map((meetingPathway, index) => ({
                  id: `academic_year_row--${index}`,
                  cells: [
                    { id: 'title', content: meetingPathway.title },
                    {
                      id: 'meetingTemplates',
                      content: (
                        <ul>
                          {meetingPathway.meetingTemplates.map((meetingTemplate) => (
                            <li key={meetingTemplate.id}>{meetingTemplate.title}</li>
                          ))}
                        </ul>
                      ),
                    },
                    {
                      id: 'actions',
                      class: 'actions',
                      content: (
                        <ul className={'actions-list'}>
                          <li>
                            <ActionButton.Edit
                              archived={false}
                              onClick={() =>
                                setMeetingPathwayFormModalData({ isOpen: true, meetingPathwayToEdit: meetingPathway })
                              }
                            />
                          </li>
                        </ul>
                      ),
                    },
                  ],
                }))
              : []
          }
          noDataMessage={'Start adding Terms clicking on "Add academic year" button.'}
        />
        <div className={'flex justify-end mt-8'}>
          <AddButton
            id={'add-meetingPathway'}
            key={'add-meetingPathway-button'}
            onClick={() => setMeetingPathwayFormModalData({ isOpen: true, meetingPathwayToEdit: null })}
            label={['Pathway']}
          />
        </div>
      </Section>
    </>
  );
}
