import React, { useRef, useState } from 'react';
import { ModalToggleProps } from '../../../global/messages/modal/modal.types';
import Modal from '../../../global/messages/modal/modal.components';
import { useMutation, useQueryClient } from 'react-query';
import { postUploadVideo } from '../../../service/api';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Input from '../../../form/input/input';
import DragAndDropFile from '../../../form/dragAndDrop/DragAndDropFile';
import ProgressBar from '../components/ProgressBar';
import { VIDEO_LIBRARY } from '../../../service/queryKeys';
import Axios from 'axios';

const SUPPORTED_VIDEO_FORMATS = [
  'video/x-flv',
  'video/mp4',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
];

const DeleteUserAccountModal = ({ toggle }: ModalToggleProps) => {
  const [percentageProgress, setPercentageProgress] = useState(0);
  const cancelTokenRef = useRef(Axios.CancelToken.source());

  const queryClient = useQueryClient();
  const uploadVideoMutation = useMutation(postUploadVideo, {
    onSuccess: () => {
      toggle(false);
      toast.success('Video uploaded successfully');
      setTimeout(() => queryClient.invalidateQueries(VIDEO_LIBRARY), 3000);
    },
  });

  return (
    <Modal>
      <Formik
        initialValues={{
          title: '',
          video: null,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required(),
          video: Yup.mixed()
            .required()
            .test('format-not-supported', 'This file is not supported', (value) => {
              return SUPPORTED_VIDEO_FORMATS.includes(value?.type);
            }),
        })}
        isInitialValid={false}
        onSubmit={(values) => {
          uploadVideoMutation.mutate({
            title: values.title,
            video: values.video!,
            handleUploadProgress: (loaded: number, total: number) => {
              const percent = Math.floor((loaded * 100) / total);

              if (percent <= 100) {
                setPercentageProgress(percent);
              }
            },
            source: cancelTokenRef.current,
          });
        }}
      >
        {({ values, errors, handleChange, handleSubmit, isValid, setFieldValue, touched, setFieldTouched }) => (
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="mb-8">
                <Input
                  id="title"
                  label="Title"
                  placeholder="Please enter a title"
                  required
                  value={values.title}
                  onChange={handleChange}
                />
              </div>
              <DragAndDropFile
                file={values.video}
                setFile={(file) => {
                  setFieldValue('video', file);
                  setFieldTouched('video', true);
                }}
                error={touched.video ? errors.video : ''}
              />
              {(uploadVideoMutation.isLoading || uploadVideoMutation.isSuccess) && (
                <ProgressBar
                  progress={percentageProgress}
                  loading={uploadVideoMutation.isLoading}
                  cancel={() => {
                    cancelTokenRef.current.cancel();
                    cancelTokenRef.current = Axios.CancelToken.source();
                  }}
                />
              )}
            </Modal.Body>
            <Modal.Footer>
              <Modal.CancelButton onClick={() => toggle(false)} />
              <Modal.ConfirmButton
                id={'uploadVideoModal_uploadVideoButton'}
                type="submit"
                disabled={!isValid || uploadVideoMutation.isLoading}
                label={uploadVideoMutation.isLoading ? 'Loading...' : 'Upload video'}
              />
            </Modal.Footer>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default DeleteUserAccountModal;
