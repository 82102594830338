import { css, ThemeProps } from 'styled-components';
import { lighten, readableColor, transparentize } from 'polished';
import { DEFAULT_THEME } from '../Theme';
import { SIZE_HEIGHT } from '../resources/vars';
import { SizeType } from '../resources/types';

const FIELD_ATTRIBUTES = (props: any): any => {
  return {
    inputHeight: `${SIZE_HEIGHT['md']}px`,
    paddingX: `15px`,
    fontFamily: `ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
    fontSize: `0.95rem`,
    color: props.theme.colors.dark,
    placeholderColor: props.theme.colors.muted,
    background: props.theme.colors.white,
    border: `1px solid ${props.theme.colors.secondary}`,
    borderRadius: props.theme.borderRadius.app,
    transition: `box-shadow ease-in-out 450ms, border-color ease-in-out 450ms`,
  };
};

/**
 * Returns the main color of the component.
 * @param props
 */
export function getMainColor(props: any): string {
  return props.theme.colors[props.mainColor as string] || props.mainColor || props.theme.colors.primary;
}

/**
 * Returns styles for focused elements.
 * @param color Box-shadow color.
 * @param isInset Sets the shadow as inset.
 */
export function getFocusStyle(color: any, isInset = false): any {
  return css`
    outline: none;
    box-shadow: ${isInset && 'inset'} 0 0 0 5px ${transparentize(0.7, color)};
  `;
}

/**
 * Returns the base styling for inputs, and textareas.
 * @param props Theme props.
 * @param hasIcon If input field has icon.
 * @param hasComponent If input field has component to the right.
 * @param isTextarea If field is textarea.
 */
export function getBaseFieldStyle(
  props: ThemeProps<any>,
  hasIcon = false,
  hasComponent = false,
  isTextarea = false
): any {
  return css`
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    min-height: 27px;
    height: ${(props) => (isTextarea ? '75px' : FIELD_ATTRIBUTES(props).inputHeight)};
    box-sizing: border-box;
    vertical-align: middle;
    font-family: ${(props) => FIELD_ATTRIBUTES(props).fontFamily};
    font-size: ${(props) => FIELD_ATTRIBUTES(props).fontSize};
    line-height: 1.5;
    padding: ${isTextarea ? FIELD_ATTRIBUTES(props).paddingX : '0'}
      ${hasComponent ? FIELD_ATTRIBUTES(props).inputHeight : FIELD_ATTRIBUTES(props).paddingX} 0
      ${hasIcon ? FIELD_ATTRIBUTES(props).inputHeight : FIELD_ATTRIBUTES(props).paddingX};
    box-shadow: none;
    background-color: ${(props) => FIELD_ATTRIBUTES(props).background};
    color: ${(props) => FIELD_ATTRIBUTES(props).color};
    border: ${(props) => FIELD_ATTRIBUTES(props).border};
    border-radius: ${(props) => FIELD_ATTRIBUTES(props).borderRadius};
    transition: ${(props) => FIELD_ATTRIBUTES(props).transition};
    &::placeholder {
      color: ${(props) => FIELD_ATTRIBUTES(props).placeholderColor};
    }
  `;
}

/**
 * Styles the select based on react-select documentation.
 * https://react-select.com/styles#using-classnames
 * @param props Component props.
 */
export function getBaseSelectorStyle(props: any): any {
  const PREFIX = props.classNamePrefix;
  return css`
    .${PREFIX}__control {
      min-height: ${(props) => FIELD_ATTRIBUTES(props).inputHeight};
      background-color: ${(props) => FIELD_ATTRIBUTES(props).background};
      color: ${(props) => FIELD_ATTRIBUTES(props).color};
      border: ${(props) => FIELD_ATTRIBUTES(props).border};
      border-radius: ${(props) => FIELD_ATTRIBUTES(props).borderRadius};
      transition: ${(props) => FIELD_ATTRIBUTES(props).transition};
      font-family: ${(props) => FIELD_ATTRIBUTES(props).fontFamily};
      font-size: ${(props) => FIELD_ATTRIBUTES(props).fontSize};
      .${PREFIX}__clear-indicator {
        padding: 0;
      }
      .${PREFIX}__value-container {
        padding: 0 ${(props) => FIELD_ATTRIBUTES(props).paddingX};
        .${PREFIX}__placeholder {
          line-height: normal;
          color: ${(props) => FIELD_ATTRIBUTES(props).placeholderColor};
        }
        .${PREFIX}__multi-value {
          background-color: ${props.theme.colors.primary};
          color: ${(props) => readableColor(props.theme.colors.primary)};
          .${PREFIX}__multi-value__remove {
            background-color: ${(props) => lighten(0.2, props.theme.colors.primary)};
            color: ${(props) => readableColor(lighten(0.2, props.theme.colors.primary))};
            &:hover {
              background-color: ${(props) => lighten(0.1, props.theme.colors.primary)};
              color: ${props.theme.colors.danger};
            }
          }
        }
      }
    }
    .${PREFIX}__menu {
      background: ${props.theme.colors.white};
      z-index: 60;
      .${PREFIX}__menu-list {
        .${PREFIX}__option {
          &.${PREFIX}__option--is-focused:not(.${PREFIX}__option--is-selected) {
            background-color: ${props.theme.colors.light};
          }
          &.${PREFIX}__option--is-selected {
            background-color: ${lighten(0.2, props.theme.colors.primary)};
            color: ${readableColor(lighten(0.2, props.theme.colors.primary))};
          }
          &.${PREFIX}__option--is-disabled {
            font-style: italic;
            background-color: ${props.theme.colors.white} !important;
            color: ${props.theme.colors.muted};
          }
        }
      }
    }
    &:hover {
      .${PREFIX}__control {
        border-color: ${props.theme.colors.primary};
      }
    }
    .${PREFIX}__control--is-focused {
      border-color: ${props.theme.colors.primary};
      ${(props) => getFocusStyle(props.theme.colors.primary)}
    }
  `;
}

/**
 * Styles the CardComponent from @chargebee/chargebee-js-react-wrapper.
 */
export function getBaseChargebeeStyle(): any {
  const props = {
    theme: DEFAULT_THEME,
  };
  return {
    base: {
      color: FIELD_ATTRIBUTES(props).color,
      fontFamily: FIELD_ATTRIBUTES(props).fontFamily,
      fontSize: FIELD_ATTRIBUTES(props).fontSize,
      fontSmoothing: 'antialiased',
      '::placeholder': {
        color: FIELD_ATTRIBUTES(props).placeholderColor,
      },
    },
    invalid: {
      color: props.theme.colors.danger,
    },
  };
}

/**
 * Set base styles for buttons.
 * @param props
 */
export const getBaseButtonStyles = (props: any) => {
  return css`
    appearance: none;
    line-height: 100%;
    letter-spacing: 2px;
    font-weight: 700;
    border: 2px solid transparent;
    box-shadow: none;
    transition: background ease-in-out 0.1s, box-shadow ease-in-out 0.1s;
    border-radius: ${props.theme.borderRadius.app};

    &:focus,
    &[aria-pressed='true'] {
      ${getFocusStyle(getMainColor(props))}
    }
  `;
};

export const getButtonSize = (size: SizeType, height?: number) => {
  if ('xsm' === size) {
    return css`
      font-size: 0.90rem;
      padding: ${height ? (height * 6) / 100 : 6}px 12px;
      height: ${height ?? '100'}%;
    `;
  } else if ('sm' === size) {
    return css`
      font-size: 0.95rem;
      padding: ${height ? (height * 12) / 100 : 12}px 24px;
      height: ${height ?? '100'}%;
    `;
  } else if ('md' === size) {
    return css`
      font-size: 1rem;
      padding: ${height ? (height * 20) / 100 : 20}px 28px;
      height: ${height ?? '100'}%;
    `;
  } else if ('lg' === size) {
    return css`
      font-size: 1.15rem;
      padding: ${height ? (height * 24) / 100 : 24}px 30px;
      height: ${height ?? '100%'}%;
    `;
  }
};

/**
 * Styles disabled buttons.
 */
export function getButtonDisabledStyle(props: any): any {
  return css`
    background: ${props.theme.colors.light};
    color: ${props.theme.colors.muted};
    border-color: ${props.addOutline ? props.theme.colors.muted : 'transparent'};
    text-decoration: none;
  `;
}

/**
 * Styles buttons as link, or icon button as free.
 */
export function getButtonTextStyle(props: any): any {
  return css`
    background: transparent;
    color: ${getMainColor(props)};
    text-decoration: underline;
    &:hover,
    &:active {
      text-decoration: none;
      background: ${props.theme.colors.light};
      color: ${readableColor(props.theme.colors.light)};
    }
  `;
}

/**
 * Styles outlined buttons.
 */
export function getButtonOutlineStyle(props: any): any {
  const COMPONENT_COLOR = getMainColor(props);
  return css`
    background: transparent;
    border-color: ${COMPONENT_COLOR};
    color: ${COMPONENT_COLOR};
    &:hover,
    &:active {
      background: ${COMPONENT_COLOR};
      color: ${readableColor(COMPONENT_COLOR)};
    }
  `;
}

/**
 * Styles filled buttons.
 */
export function getButtonFillStyle(props: any): any {
  const COMPONENT_COLOR = getMainColor(props);
  return css`
    background: ${lighten(0.2, COMPONENT_COLOR)};
    color: ${readableColor(lighten(0.2, COMPONENT_COLOR))};
    &:hover,
    &:active {
      background: ${COMPONENT_COLOR};
      color: ${readableColor(COMPONENT_COLOR)};
    }
  `;
}

/**
 * Remove any styles for buttons.
 */
export function getTransparentButtonStyle(noBorder = true): any {
  return css`
    appearance: none;
    ${noBorder && 'border: none;'}
    background: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
  `;
}
