import React, { useEffect, useState } from 'react';
import AddButton from './addButton';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Button from '../../../global/button/button';
import classNames from 'classnames';
import { useQueryClient } from 'react-query';
import { PATHWAYS_V2_STEP } from '../../../service/queryKeys';
import { EXPLORE_STEP, PREPARE_STEP, REVIEW_STEP } from '../views/PathwayV2View';

export const Wysiwyg = ({
  readOnly = false,
  disabled = false,
  value,
  save,
  label,
  buttonLabel,
  description,
}: {
  readOnly?: boolean;
  buttonLabel?: string;
  label?: string;
  disabled?: boolean;
  value?: string;
  description?: string;
  save?: (notes: string, callback?: () => void) => void;
}) => {
  const [state, setState] = useState<EditorState>(
    value ? EditorState.createWithContent(convertFromRaw(JSON.parse(value))) : EditorState.createEmpty()
  );

  const queryClient = useQueryClient();

  const [editMode, setEditMode] = useState(!value);

  const [notesFocus, setNotesFocus] = useState({ in: false, out: false })

  useEffect(() => {
    setState(value ? EditorState.createWithContent(convertFromRaw(JSON.parse(value))) : EditorState.createEmpty());

    setEditMode(!value);
  }, [value]);

  return (
    <div className={!readOnly && !notesFocus.in && notesFocus.out && editMode ? 'border-2 border-yellow-600 p-2' : ''}>
      <div className={'mb-2'}
           onMouseEnter={() => {
             setNotesFocus({ in: true, out: false });
           }}
           onMouseLeave={() => {
             setTimeout(() => setNotesFocus({ in: false, out: true }), 10);
           }}>
        {!readOnly && (
          <>
            <div className={'flex justify-between'}>
              <span className={'font-bold'}>{label}</span>
              <Button
                mainColor={!notesFocus.in && notesFocus.out && editMode ? 'warning' : undefined}
                className={!notesFocus.in && notesFocus.out && editMode ? 'animate-pulse ' : ''}
                size={'sm'}
                disabled={disabled}
                style={{ textTransform: "none", color: 'white' }}
                onClick={() => {
                  if (save && editMode) {
                    save(JSON.stringify(convertToRaw(state.getCurrentContent())), () => {
                      setEditMode(false)

                      queryClient.invalidateQueries([PATHWAYS_V2_STEP, PREPARE_STEP]);
                      queryClient.invalidateQueries([PATHWAYS_V2_STEP, REVIEW_STEP]);
                    });
                    return;
                  }

                  if (!editMode) {
                    setEditMode(true);
                  }
                }}
              >
                {`${!editMode ? 'Edit' : 'Save'} ${buttonLabel ? buttonLabel : label}`}
              </Button>
            </div>
            <i className="text-sm">{description}</i>
          </>
        )}
        <div style={{ opacity: (disabled || !editMode) ? 0.4 : 1 }}>
          <Editor
            toolbarHidden={readOnly || disabled}
            toolbar={{
              options: ['inline', 'blockType', 'list', 'textAlign', 'link'],
              inline: {
                options: ['bold', 'italic', 'underline'],
              },
            }}
            readOnly={disabled || readOnly || !editMode}
            editorState={readOnly && value ? EditorState.createWithContent(convertFromRaw(JSON.parse(value))) : state}
            wrapperClassName={`pathways-section-content ${classNames({ 'border border-gray-400': readOnly })}`}
            editorClassName={'p-3'}
            onEditorStateChange={setState}
          />
        </div>
      </div>
    </div>
  );
};

const WysiwygEditor = ({
  label,
  buttonLabel,
  disabled = false,
  value,
  save,
  description,
}: {
  buttonLabel?: string;
  label: string;
  disabled?: boolean;
  value?: string;
  description?: string;
  save: (value: string, callback?: () => void) => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);

  if (null != value || isEditing) {
    return (
      <Wysiwyg
        disabled={disabled}
        save={save}
        value={value}
        label={label}
        buttonLabel={buttonLabel}
        description={description}
      />
    );
  }

  return (
    <>
      <div className={'flex'}>
        <span className={'font-bold pr-5 py-1'}> {label}</span>
        <AddButton disabled={disabled} onClick={() => setIsEditing(true)} />
      </div>
      <i className="text-sm">{description}</i>
    </>
  );
};

export default WysiwygEditor;
