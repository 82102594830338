import React, { useEffect, useState } from 'react';
import CommunityPost, { CommunityPostType } from './components/communityPost';
import classNames from 'classnames';
import { Section } from '../common';
import { useQuery } from 'react-query';
import { POSTS } from '../../service/queryKeys';
import { getPosts } from '../../service/api';
import posthog from 'posthog-js';

export const COMMUNITY_STYLE = {
  height: '100%',
  maxWidth: '100%',
  aspectRatio: '16/9',
};

const CommunityNewsfeed = ({
  width,
  editorMode,
  adminMode,
  setEditPost,
  setCancelEdit,
}: {
  width?: 'lg';
  editorMode?: boolean;
  adminMode?: boolean;
  setEditPost?: any;
  setCancelEdit?: any;
}) => {
  const { data: posts } = useQuery(POSTS, getPosts, {
    select: (data) => data.data.data,
    placeholderData: [],
    keepPreviousData: true,
  });

  const posthogCommunityTitle = posthog.getFeatureFlagPayload('dashboard_test_key');

  const [communityTitle, setCommunityTitle] = useState('Community resource');
  const [featureFlag, setFeatureFlag] = useState<any>(undefined);

  useEffect(() => {
    posthog.onFeatureFlags(function () {
      if (posthog.getFeatureFlag('dashboard_test_key') === 'control') setFeatureFlag('control');
      else if (posthog.getFeatureFlag('dashboard_test_key') === 'Community') setFeatureFlag('community');
      else setFeatureFlag(undefined);
    });
  }, []);

  useEffect(() => {
    if (featureFlag && posthogCommunityTitle) setCommunityTitle(posthogCommunityTitle.key);
  }, [featureFlag]);

  return (
    <Section
      headline={communityTitle ?? 'Community resource'}
      more={null}
      className={
        'w-full flex flex-col ' +
        classNames({ 'lg:w-1/2 ': width !== 'lg' || !adminMode }) +
        classNames({ 'mx-auto w-full xl:w-2/3 2xl:w-1/2 min-h-screen': adminMode })
      }
      size={null}
      flex
      containerClassName={'rounded-md'}
    >
      <div style={COMMUNITY_STYLE} className={`overflow-auto ${classNames({ 'flex flex-col': false })}`}>
        <div className="bg-gray-200 w-full pb-2">
          <div className="grid grid-cols-12 gap-5">
            <div className="col-span-12">
              <div className={'bg-gray-200 last:pb-3'}>
                {posts.map(
                  (post: CommunityPostType) =>
                    (!post.draft || adminMode) && (
                      <CommunityPost
                        key={post.id}
                        post={post}
                        editorMode={editorMode}
                        adminMode={adminMode}
                        setEditPost={setEditPost}
                        setCancelEdit={setCancelEdit}
                      />
                    )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};
export default CommunityNewsfeed;
